import {
    Component,
    ElementRef,
    inject,
    Renderer2,
    OnInit,
    ViewChild
} from '@angular/core';

import {
    itemLabelsGenerator,
    util
} from 'app/ajs-upgraded-providers';

import { NgForm } from '@angular/forms';

import _ from 'lodash';

import {
    AlertDialogService,
    ConfirmDialogService,
} from 'src/app/dialogs';

import { TranslateService } from "@ngx-translate/core";
import { MatSnackBar } from '@angular/material/snack-bar';

import {
    ConfigurationManagerService,
    ModuleEvents,
    ToolbarEventsService
} from 'src/app/core';

import { SelectValueName } from 'src/app/models';
import { v4 as generateUuid } from 'uuid';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';


@Component({
    selector: 'app-settings-label-panel',
    templateUrl: './settings-label-panel.component.html',
    styleUrls: ['./settings-label-panel.component.scss'],
    providers: [OnDestroyService]
})
export class SettingsLabelPanelComponent implements OnInit {
    private readonly _snackBar = inject(MatSnackBar);
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly itemLabelsGeneratorService = inject(itemLabelsGenerator);
    private readonly onDestroyService = inject(OnDestroyService);
    private readonly renderer = inject(Renderer2);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly translate = inject(TranslateService);
    private readonly util = inject(util);
    protected readonly configurationManager = inject(ConfigurationManagerService);

    @ViewChild('labelPanelForm', { static: true }) labelPanelForm!: NgForm;
    @ViewChild('importLabelPanelId', { static: true }) importLabelPanelElement!: ElementRef<HTMLInputElement>;
    @ViewChild('editorPreview', { static: true }) editorPreviewElement!: ElementRef;

    colors: SelectValueName[] = [];
    contents: SelectValueName[] = [];
    filterList = '';
    filterToggle = false;
    fontFamily: string[] = [];
    fontSize: string[] = [];
    fontStyle: SelectValueName[] = [];
    fontWeight: SelectValueName[] = [];
    isNewEntryInProgress = false;
    isProcessing = false;
    labelPanelList: any[] = [];
    pagination: number[] = [];
    pristineLabelPanel: any = null;
    selectedLabelPanel: any = null;
    template: any = null;
    textAnchor: SelectValueName[] = [];
    textDecoration: SelectValueName[] = [];
    tooltipMenuDelete = '';
    tooltipMenuDuplicate = '';
    types: SelectValueName[] = [];


    constructor(
    ) {
        this.labelPanelForm = new NgForm([], []);
    }

    setModuleTitle() {
        this.toolbarEventsService.moduleTitle.next('LABEL_PANEL');
    }

    ngOnInit(): void {
        this.createToolbarButtons();
        this.setModuleTitle();
        this.toolbarEventsService.events.pipe(this.onDestroyService.takeUntilDestroy).subscribe(e => this.callbackToToolbarClick(e));
        this.types = [
            { value: 'text', name: this.translate.instant('SETTINGS.LABEL_PANEL.TEXT') },
            { value: 'svg', name: this.translate.instant('SETTINGS.LABEL_PANEL.BARCODE') }
        ];
        this.contents = [
            { value: 'name',              name: this.translate.instant('SETTINGS.LABEL_PANEL.CONTENTS.NAME')              },
            { value: 'order_name',        name: this.translate.instant('SETTINGS.LABEL_PANEL.CONTENTS.ORDER_NAME')        },
            { value: 'print_date',        name: this.translate.instant('SETTINGS.LABEL_PANEL.CONTENTS.PRINT_DATE')        },
            { value: 'promotion_name',    name: this.translate.instant('SETTINGS.LABEL_PANEL.CONTENTS.PROMOTION_NAME')    },
            { value: 'promotion_to_date', name: this.translate.instant('SETTINGS.LABEL_PANEL.CONTENTS.PROMOTION_TO_DATE') },
            { value: 'original_price',    name: this.translate.instant('SETTINGS.LABEL_PANEL.CONTENTS.ORIGINAL_PRICE')    },
            { value: 'discounted_price',  name: this.translate.instant('SETTINGS.LABEL_PANEL.CONTENTS.DISCOUNTED_PRICE')  },
            { value: 'description',       name: this.translate.instant('SETTINGS.LABEL_PANEL.CONTENTS.DESCRIPTION')       },
            //{ value: 'barcode',           name: this.translate.instant('SETTINGS.LABEL_PANEL.CONTENTS.BARCODE')           }
        ];
        //this.fontFamily = ['serif', 'sans-serif', 'cursive', 'fantasy', 'monospace'];
        this.fontFamily = ['monospace'];
        this.fontSize = Array.from({ length: 100 }, (_, i) => ++i).map((v: number) => v + 'px');
        this.pagination = Array.from({ length: 50 }, (_, i) => ++i);
        this.fontStyle = [
            { value: 'normal', name: this.translate.instant('SETTINGS.LABEL_PANEL.NORMAL') },
            { value: 'italic', name: this.translate.instant('SETTINGS.LABEL_PANEL.ITALIC') }
        ];
        this.fontWeight = [
            { value:'normal', name: this.translate.instant('SETTINGS.LABEL_PANEL.NORMAL') },
            { value:'bold',   name: this.translate.instant('SETTINGS.LABEL_PANEL.BOLD')   }
        ];
        this.colors = [
            { value: 'black',    name: this.translate.instant('SETTINGS.LABEL_PANEL.COLORS.BLACK')    },
            { value: 'gray',     name: this.translate.instant('SETTINGS.LABEL_PANEL.COLORS.GRAY')     },
            { value: 'darkgray', name: this.translate.instant('SETTINGS.LABEL_PANEL.COLORS.DARKGRAY') },
            { value: 'blue',     name: this.translate.instant('SETTINGS.LABEL_PANEL.COLORS.BLUE')     },
            { value: 'green',    name: this.translate.instant('SETTINGS.LABEL_PANEL.COLORS.GREEN')    },
            { value: 'violet',   name: this.translate.instant('SETTINGS.LABEL_PANEL.COLORS.VIOLET')   },
            { value: 'purple',   name: this.translate.instant('SETTINGS.LABEL_PANEL.COLORS.PURPLE')   },
            { value: 'brown',    name: this.translate.instant('SETTINGS.LABEL_PANEL.COLORS.BROWN')    },
            { value: 'red',      name: this.translate.instant('SETTINGS.LABEL_PANEL.COLORS.RED')      },
            { value: 'orange',   name: this.translate.instant('SETTINGS.LABEL_PANEL.COLORS.ORANGE')   }
        ];
        this.textDecoration = [
            { value: 'null',               name: this.translate.instant('SETTINGS.LABEL_PANEL.NONE')               },
            { value: 'underline',          name: this.translate.instant('SETTINGS.LABEL_PANEL.UNDERLINE')          },
            { value: 'line-through',       name: this.translate.instant('SETTINGS.LABEL_PANEL.LINE_THROUGH')       },
            { value: 'overline',           name: this.translate.instant('SETTINGS.LABEL_PANEL.OVERLINE')           },
            { value: 'underline overline', name: this.translate.instant('SETTINGS.LABEL_PANEL.UNDERLINE_OVERLINE') }
        ];
        this.textAnchor = [
            { value: 'start',  name: this.translate.instant('SETTINGS.LABEL_PANEL.START')  },
            { value: 'middle', name: this.translate.instant('SETTINGS.LABEL_PANEL.MIDDLE') },
            { value: 'end',    name: this.translate.instant('SETTINGS.LABEL_PANEL.END')    }
        ];
        this.tooltipMenuDelete = this.translate.instant('SETTINGS.LABEL_PANEL.MENU.DELETE');
        this.tooltipMenuDuplicate = this.translate.instant('SETTINGS.LABEL_PANEL.MENU.DUPLICATE');
        this.reload();
    }

    createToolbarButtons() {
        this.toolbarEventsService.buttons$.next({
            barButtons: [],
            panelButtons: [
                {
                    isIt: () => true,
                    name: 'export',
                    icon: () => 'download',
                    label: () => this.translate.instant('SETTINGS.LABEL_PANEL.EXPORT'),
                    click: () => this.toolbarEventsService.events.next({ type: 'export' })
                },
                {
                    isIt: () => true,
                    name: 'import',
                    icon: () => 'publish',
                    label: () => this.translate.instant('SETTINGS.LABEL_PANEL.IMPORT'),
                    click: () => this.toolbarEventsService.events.next({ type: 'import' })
                }
            ]
        });
    }

    callbackToToolbarClick(event: Partial<ModuleEvents> & { id?: number }) {
        if ('type' in event && event.type === 'export') this.exportLabelPanel();
        else if ('type' in event && event.type === 'import') this.importLabelPanelElement?.nativeElement.click();
    }

    changeTextAnchor = (item: any, anchor: any) => {
        if(item && anchor) {
            item.params['text-anchor'] = anchor.value;
            this.generatePreview();
        }
    };

    isAnchorSelected = (item: any, anchor: any) => item.params['text-anchor'] === anchor.value;

    private reload = () => {
        const templates = this.configurationManager.getPreference('promotions.label_templates');

        if (templates) {
            try {
                this.labelPanelList = JSON.parse(templates);
            } catch (e) {
                this.labelPanelList = [];
            }
        }

        if(this.labelPanelList === null || this.labelPanelList === undefined) {
            this.labelPanelList = this.itemLabelsGeneratorService.getDefaultTemplates();
        }

        if(this.labelPanelList && this.labelPanelList.length > 0) {
            this.template = this.labelPanelList[0];
        }
        //this.selectFirstAvailableLabel();
    };

    private joinStyle(objStyle: any, extraStyle: any) {
        let css = '';

        if (objStyle) {
            Object.entries(objStyle).forEach(([key,value]) => {
                if (value !== 'null') {
                    css += `${_.kebabCase(key)}:${value};`;
                }
            });
        }

        if (extraStyle && extraStyle !== '') {
            css += extraStyle;
        }

        return css;
    };

    updateStyle(item: any) {
        if (item && item.style) {
            item.params.style = this.joinStyle(item.style, item.params.extraStyle);
        }

        this.generatePreview();
    };

    changeFontStyle = (item: any) => {
        if (item?.style?.fontStyle) {
            switch (item.style.fontStyle) {
                case 'normal': item.style.fontStyle = 'italic'; break;
                case 'italic': item.style.fontStyle = 'normal'; break;
            }
            this.updateStyle(item);
        }
    };

    changeFontWeight = (item: any) => {
        if (item?.style?.fontWeight) {
            switch (item.style.fontWeight) {
                case 'normal': item.style.fontWeight = 'bold'; break;
                case 'bold': item.style.fontWeight = 'normal'; break;
            }
            this.updateStyle(item);
        }
    };

    increaseY = (item: any) => {
        item.params.y += 1;
        this.generatePreview();
    };

    decreaseY = (item: any) => {
        item.params.y -= 1;
        this.generatePreview();
    };

    increaseX = (item: any) => {
        item.params.x += 1;
        this.generatePreview();
    };

    decreaseX = (item: any) => {
        item.params.x -= 1;
        this.generatePreview();
    };

    selectFirstAvailableLabel = () => {
        if (this.labelPanelList && this.labelPanelList.length > 0) {
            this.selectLabelPanel(this.labelPanelList[0]);
        } else {
            this.addNewLabelPanel();
        }
    };

    isSelected = (template: any) => template.id === this.selectedLabelPanel?.id;

    changeType = (item: any) => {
        if(item) {
            if (item.type === 'text') {
                item.content.type = 'name';
                if(!item.style) {
                    item.style = {
                        fontFamily: 'monospace',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        textDecoration: 'null',
                        fill: 'black',
                        multiFont: []
                    };
                }
                if(!item.params['text-anchor']) {
                    item.params['text-anchor'] = 'start';
                }
            }
            if (item.type === 'svg') {
                item.content.type = 'barcode';
                if(!item.content.options) {
                    item.content.options = {
                        height: 40,
                        width: 1,
                        margin: 0
                    };
                }
            }
            this.updateStyle(item);
        }
    };

    unsaveLabelPanel = async (template: any) => {
        const confirm = await this.confirmDialogService.openDialog({data: {
            messageLabel: 'SETTINGS.LABEL_PANEL.EDITS_NOT_SAVED',
            confirmLabel: 'SETTINGS.LABEL_PANEL.CONFIRM_LABEL',
            cancelLabel: 'SETTINGS.LABEL_PANEL.CANCEL_LABEL'
        }});

        if (!confirm) {
            return;
        }

        const index = this.labelPanelList.findIndex((model) => model.id === this.selectedLabelPanel.id);

        if(index !== -1) {
            this.labelPanelList.splice(index, 1);
        }

        this.labelPanelForm.form.markAsPristine();
        this.isNewEntryInProgress = false;
        this.selectLabelPanel(template);
    };

    selectLabelPanel = (template: any, isFormDirty = false) => {
        if (isFormDirty) {
            this.unsaveLabelPanel(template);
        } else {
            const label = structuredClone(template);
            label.data.content.forEach((el: any) => {
                if (el.params.style) {
                    el.style = this.splitStyle(el.params.style);
                    el.style.multiFont = [];
                    if (!el.style.fill) {
                        el.style.fill = 'black';
                    }
                    if (!el.style.textDecoration) {
                        el.style.textDecoration = 'null';
                    }
                    if (!el.style.fontWeight) {
                        el.style.fontWeight = 'normal';
                    } else {
                        if(el.style.fontWeight === 'bold') {
                            el.style.multiFont[0] = el.style.fontWeight;
                        }
                    }
                    if (!el.style.fontSize) {
                        el.style.fontSize = '14px';
                    }
                    if (!el.style.fontStyle) {
                        el.style.fontStyle = 'normal';
                    } else {
                        if(el.style.fontWeight === 'italic') {
                            el.style.multiFont[1] = el.style.fontWeight;
                        }
                    }
                    if (!el.params['text-anchor']) {
                        el.params['text-anchor'] = 'start';
                    }
                }
                if (typeof el.content === 'string') {
                    const c = el.content;
                    el.content = { type: c };
                }
            });

            this.pristineLabelPanel = label;
            this.selectedLabelPanel = label;
            this.generatePreview();
        }
    };

    private addNewLabelPanelProceed = () => {
        const newTemplate = _.head(this.itemLabelsGeneratorService.getDefaultTemplates());

        _.assign(newTemplate, {
            name: this.translate.instant('SETTINGS.LABEL_PANEL.NEW_TEMPLATE'),
            id: generateUuid()
        });
        this.filterList = '';
        const newLen = this.labelPanelList.push(newTemplate);
        this.labelPanelList = [...this.labelPanelList];
        this.template = newTemplate;
        this.selectLabelPanel(this.labelPanelList[newLen - 1], false);
        this.labelPanelForm.form.markAsDirty();
        this.isNewEntryInProgress = true;
    };

    unsaveWhenAddNew = async () => {
        const confirm = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'SETTINGS.LABEL_PANEL.EDITS_NOT_SAVED',
                confirmLabel: 'SETTINGS.LABEL_PANEL.CONFIRM_LABEL',
                cancelLabel: 'SETTINGS.LABEL_PANEL.CANCEL_LABEL'
        }});

        if (confirm) {
            this.labelPanelForm.form.markAsPristine();
            this.addNewLabelPanelProceed();
        }
    };

    addNewLabelPanel = (isFormDirty = false) => {
        if(this.labelPanelList.length < 20) {
            if(isFormDirty) {
                this.unsaveWhenAddNew();
            }
            if(!this.labelPanelForm.form.dirty && !this.isNewEntryInProgress) {
                this.addNewLabelPanelProceed();
            }
        } else {
            this.alertDialogService.openDialog({
                data: {
                    messageLabel: 'SETTINGS.LABEL_PANEL.INSERTION_NOT_ALLOWED',
                    confirmLabel: 'SETTINGS.LABEL_PANEL.ALERT_CONFIRM_LABEL'
                }
            });
        }
    };

    private cleanJson = (templateToClean: any) => {
        const cleanTemplate = structuredClone(templateToClean);

        cleanTemplate.data.content.forEach((el: any) => {
            delete el.style;

            if(el.params['text-anchor'] === 'start') {
                delete el.params['text-anchor'];
            }
            if (el.type === 'text') {
                if(el.content.options) {
                    delete el.content.options;
                }
            }
        });

        return cleanTemplate;
    };

    generatePreview = () => {
        const mockPromotion = {
            name: 'Promozione di prova',
            from_date: '2020-01-01',
            to_date: '2020-12-31',
            start_time: '00:00:00',
            end_time: '23:59:59',
            base_pricelist: 1,
            items: [
                {
                    id: 85,
                    name: 'Prodotto di prova',
                    order_name: 'Descrizione breve',
                    description: 'Descrizione lunga',
                    price1: 5.00,
                    discounted_price: 2.00
                }
            ]
        };

        this.template = this.cleanJson(this.selectedLabelPanel);

        const previewData = this.itemLabelsGeneratorService.generateLabel(this.template.data, mockPromotion, 1, _.head(mockPromotion.items));

        // $('#editorPreview').html(previewData);
        // $('#editorPreview > svg').css({
        //     border: '1px solid',
        //     height: this.template.data.height,
        //     width: this.template.data.width
        // });
        console.log("this.template.data.height", this.template.data.height);
        try {
            const editorPreview = this.renderer.selectRootElement('#editorPreview');
            this.renderer.setProperty(editorPreview, 'innerHTML', previewData);
            //this.renderer.setStyle(editorPreview, 'border', '1px solid');
            this.renderer.setStyle(editorPreview, 'height', this.template.data.height);
            this.renderer.setStyle(editorPreview, 'width', this.template.data.width);
        } catch(e) {
            setTimeout(() => this.generatePreview(), 0);
        }
    };

    private splitStyle = (style: string) => {
        let obj: {
            fontSize?: string;
            fontFamily?: string;
            fontWeight?: string;
            fontStyle?: string;
            fill?: string;
            textDecoration?: string;
        } = {};
        // style = "font-size: 14px; fill: red; text-decoration: end;"

        if (style) {
            const splitBySemicolon = style.split(';');
            splitBySemicolon.forEach((val) => {
                val = val.trim();
                if (val !== '') {
                    const splitByColon = val.split(':');
                    if(splitByColon && splitByColon.length >= 2) {
                        const propertyName = _.camelCase(splitByColon[0].trim()) as keyof typeof obj;
                        const propertyValue = splitByColon[1].trim();
                        const propertiesAdmitted = [
                            'fontSize',
                            'fontFamily',
                            'fontWeight',
                            'fontStyle',
                            'fill',
                            'textDecoration'
                        ];

                        if(propertiesAdmitted.includes(propertyName)) {
                           obj[propertyName] = propertyValue;
                        }
                    }
                }
            });
        }

        return obj;
    };

    saveLabelPanel = async () => {
        // cerco tramite id il template selezionato
        const i = _.findIndex(this.labelPanelList, {id: this.selectedLabelPanel.id});

        if (i >= 0) {
            const confirm = await this.confirmDialogService.openDialog({data: {
                messageLabel: 'SETTINGS.SETTINGS.SAVE_CONFIRM',
                confirmLabel: 'SETTINGS.LABEL_PANEL.CONFIRM_LABEL',
                cancelLabel: 'SETTINGS.LABEL_PANEL.CANCEL_LABEL'
            }});

            if (confirm) {
                const templateToSave = structuredClone(this.selectedLabelPanel);
                this.labelPanelList[i] = this.cleanJson(templateToSave);
                this.configurationManager.setShopPreference('promotions.label_templates', JSON.stringify(this.labelPanelList));
                this.labelPanelForm.form.markAsPristine();
                this.isNewEntryInProgress = false;
                this.reload();
                const labelSuccess = this.translate.instant('SETTINGS.SETTINGS.SAVE_SUCCESSFUL');
                this._snackBar.open(labelSuccess, undefined, { duration: 10000, horizontalPosition: 'left', verticalPosition: 'bottom' });
            }
        } else {
            console.debug('ID not found in saveLabel');
        }
    };

    deleteLabelPanel = async () => {
        const confirm = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'SETTINGS.LABEL_PANEL.DELETE_CONFIRM',
                confirmLabel: 'SETTINGS.LABEL_PANEL.CONFIRM_LABEL',
                cancelLabel: 'SETTINGS.LABEL_PANEL.CANCEL_LABEL'
            }
        });

        if (confirm) {
            // cerco tramite id il template selezionato
            const i = _.findIndex(this.labelPanelList, {id: this.selectedLabelPanel.id});

            if (i >= 0) {
                this.labelPanelList.splice(i, 1);
                this.configurationManager.setShopPreference('promotions.label_templates', JSON.stringify(this.labelPanelList));
                this.reload();
            } else {
                console.debug('ID not found in deleleLabel');
            }

        }
    };

    duplicateItem = (item: any, i: number) => {
        if(item) {
            const copy = structuredClone(item);
            copy.params.y = 10;
            if(copy.style) {
                copy.style.fill = 'red';
            }
            this.selectedLabelPanel.data.content.splice(i, 0, copy);
            this.updateStyle(this.selectedLabelPanel.data.content[i]);
            this.selectedLabelPanel = structuredClone(this.selectedLabelPanel);
        }
    };

    deleteItem = async (i: number) => {
        const confirm = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'SETTINGS.LABEL_PANEL.MENU.DELETE_CONFIRM',
                confirmLabel: 'SETTINGS.LABEL_PANEL.CONFIRM_LABEL',
                cancelLabel: 'SETTINGS.LABEL_PANEL.CANCEL_LABEL'
            }
        });

        if (confirm) {
            this.selectedLabelPanel.data.content.splice(i, 1);
            this.generatePreview();
        }
    };

    exportLabelPanel = () => {
        if(this.selectedLabelPanel) {
            const templateToDownload = structuredClone(this.selectedLabelPanel);
            delete templateToDownload.id;
            delete templateToDownload.created_at;
            delete templateToDownload.updated_at;
            delete templateToDownload.deleted_at;
            this.util.downloadFile(JSON.stringify(templateToDownload, null, 2), `${templateToDownload.name}.tilbylabel`, 'text/json');
        }
    };

    private importLabelPanelProceed = ($event: Event) => {
        const target = $event.target as HTMLInputElement;
        const files = target.files;
        const uploadedFile = files?.[0];
        if(uploadedFile) {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                const labelPanelToImport: any = JSON.parse(event.target.result);
                delete labelPanelToImport.id;
                delete labelPanelToImport.created_at;
                delete labelPanelToImport.updated_at;
                delete labelPanelToImport.deleted_at;
                labelPanelToImport.id = generateUuid();

                const newLen = this.labelPanelList.push(labelPanelToImport);
                this.labelPanelList = [...this.labelPanelList];

                this.configurationManager.setShopPreference('promotions.label_templates', JSON.stringify(this.labelPanelList));
                this.template = labelPanelToImport;
                this.labelPanelForm.form.markAsPristine();
                this.isNewEntryInProgress = false;
                this.selectLabelPanel(this.labelPanelList[newLen - 1], false);

                const labelSuccess = this.translate.instant('SETTINGS.SETTINGS.SAVE_SUCCESSFUL');
                this._snackBar.open(labelSuccess, undefined, { duration: 3000, horizontalPosition: 'left', verticalPosition: 'bottom' });
            };
            reader.onloadstart = () => { this.isProcessing = true; };
            reader.onloadend = () => { this.isProcessing = false; };
            reader.onerror = () => {
                this.selectFirstAvailableLabel();
                this.isProcessing = false;
                const labelSuccess = this.translate.instant('SETTINGS.SETTINGS.ERROR_IMPORT');
                this._snackBar.open(labelSuccess, undefined, { duration: 5000, horizontalPosition: 'left', verticalPosition: 'bottom' });
            };
            reader.readAsText(uploadedFile);
        }
    };

    async importLabelPanel($event: Event, isFormDirty: boolean) {
        if(isFormDirty || this.isNewEntryInProgress) {
            const confirm = await this.confirmDialogService.openDialog({
                data: {
                    messageLabel: 'SETTINGS.MODEL_DOCUMENT.EDITS_NOT_SAVED',
                    confirmLabel: 'SETTINGS.MODEL_DOCUMENT.CONFIRM_LABEL',
                    cancelLabel: 'SETTINGS.MODEL_DOCUMENT.CANCEL_LABEL'
                }
            });

            if (!confirm) {
                return;
            }

            if (this.isNewEntryInProgress) {
                this.labelPanelList.pop();
                this.labelPanelList = [...this.labelPanelList];
                this.labelPanelForm.form.markAsPristine();
                this.isNewEntryInProgress = false;
            }
            this.filterList = '';
        }

        this.importLabelPanelProceed($event);
    }

    filterLabelPanel() {
        this.filterToggle = !this.filterToggle;
        if (this.filterToggle) {
            setTimeout(() => this.renderer.selectRootElement("input[name=search]").focus(), 0);
        } else {
            this.filterList = '';
        }
    }
}

import moment from 'moment-timezone';
import { BookingShifts } from 'tilby-models';

export class BookingUtils {
    public static getSuggestedShift(bookingShifts: BookingShifts[], bookingStart: moment.Moment | Date | string, bookingTable: { room_id?: number }): BookingShifts | undefined {
        if(!Array.isArray(bookingShifts)) {
            return;
        }

        if(typeof bookingStart === 'string' || bookingStart instanceof Date) {
            bookingStart = moment(bookingStart);
        }

        return bookingShifts.find((shift) => {
            if(!shift.weekdays_period!.includes(bookingStart.locale('en').format('dd') as any)) {
                return;
            }

            if(shift.apply_to_tables_only && !bookingTable?.room_id) {
                return;
            }

            if(bookingTable?.room_id && shift.room_restrictions!.some((roomData) => (roomData.room_id === bookingTable.room_id))) {
                return;
            }

            const shiftStHour = shift.start_time.split(':').map((n) => parseInt(n));
            const shiftEdHour = shift.end_time.split(':').map((n) => parseInt(n));
            const shiftStDate = shift.start_period ? moment(shift.start_period).startOf('day') : moment(-Infinity);
            const shiftEdDate = shift.end_period ? moment(shift.end_period).endOf('day') : moment(+Infinity);

            if(!bookingStart.isBetween(shiftStDate, shiftEdDate, undefined, '[)')) {
                return;
            }

            let shiftStart = moment(bookingStart).set({ 'hour': shiftStHour[0], 'minute': shiftStHour[1], 'second': shiftStHour[2] });
            let shiftEnd = moment(bookingStart).set({ 'hour': shiftEdHour[0], 'minute': shiftEdHour[1], 'second': shiftEdHour[2] });

            if(shift.start_time > shift.end_time) {
                shiftEnd.add(1, 'day');
            }

            return bookingStart.isBetween(shiftStart, shiftEnd, undefined, '[)');
        });
    }
}
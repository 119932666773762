<div class="tw-grid tw-gap-2">
    <div class="row tw-flex tw-gap-2">
        <button mat-button class="keypad-button tw-h-16 tw-text-3xl" (click)="onClick('7')">7</button>
        <button mat-button class="keypad-button tw-h-16 tw-text-3xl" (click)="onClick('8')">8</button>
        <button mat-button class="keypad-button tw-h-16 tw-text-3xl" (click)="onClick('9')">9</button>
    </div>

    <div class="row tw-flex tw-gap-2">
        <button mat-button class="keypad-button tw-h-16 tw-text-3xl" (click)="onClick('4')">4</button>
        <button mat-button class="keypad-button tw-h-16 tw-text-3xl" (click)="onClick('5')">5</button>
        <button mat-button class="keypad-button tw-h-16 tw-text-3xl" (click)="onClick('6')">6</button>
    </div>

    <div class="row tw-flex tw-gap-2">
        <button mat-button class="keypad-button tw-h-16 tw-text-3xl" (click)="onClick('1')">1</button>
        <button mat-button class="keypad-button tw-h-16 tw-text-3xl" (click)="onClick('2')">2</button>
        <button mat-button class="keypad-button tw-h-16 tw-text-3xl" (click)="onClick('3')">3</button>
    </div>
    
    <div class="row tw-flex tw-gap-2">
        <button mat-button class="keypad-button tw-h-16 tw-text-3xl tw-w-11/12" (click)="onClick('0')">0</button>
        <button mat-button class="keypad-button tw-h-16 tw-text-3xl tw-w-1/3" (click)="onClick('del')">
            <mat-icon class="tw-text-red-600">backspace</mat-icon>
        </button>
    </div>
</div>

import angular from 'angular';

angular.module('application').component('showcasePhotogrid', {
    bindings: {
        itemsSet: '<',
        priceList: '<',
        onItemPress: '<',
        onItemTap: '<', 
        getStockStatus: '<',
        gridCols: '<',
    },
    template: require('./showcase-photogrid.html'),
    controller: [function() {
        this.$onChanges = (changes) => {
            if(changes.priceList) {
                this.priceIdentifier = `price${changes.priceList.currentValue}`;
            }
        };
    }]
});
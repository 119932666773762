import angular from 'angular';

import { TranslateService } from '@ngx-translate/core';

import {
    EntityManagerService,
    EnvironmentInfoService
} from 'src/app/core';

import {
    paymentMethodTypes
} from 'src/app/core/constants';

import {
    DigitalPaymentHandler,
    DigitalPaymentHandlerResult
} from 'src/app/shared/model/digital-payments.model';

import {
    Sales
} from 'tilby-models';

type DigitalPaymentHandlerRecord = {
    handler: string,
    isOnlineOnly: boolean,
    hasPrintFailRollback: boolean,
    platformCheck: boolean,
    allowsRefund?: boolean
};

type DigitalPaymentOptions = {
    sale: Sales,
}

export class DigitalPaymentsManager {
    private digitalPaymentHandlers: Record<number, DigitalPaymentHandlerRecord>;

    constructor(
        private $translate: TranslateService,
        private $injector: any,
        private entityManagerService: EntityManagerService,
        private environmentInfoService: EnvironmentInfoService
    ) {
        this.digitalPaymentHandlers = {
            11: {
                handler: 'ingenico',
                isOnlineOnly: true,
                hasPrintFailRollback: false,
                platformCheck: (this.environmentInfoService.isMobileApp() && this.environmentInfoService.isAndroid())
            },
            13: {
                handler: 'sumup',
                isOnlineOnly: true,
                hasPrintFailRollback: false,
                platformCheck: (this.environmentInfoService.isMobileApp() && this.environmentInfoService.isAndroid())
            },
            14: {
                handler: 'ingenico17',
                isOnlineOnly: true,
                hasPrintFailRollback: false,
                platformCheck: this.environmentInfoService.canUseTcpSockets()
            },
            18: {
                handler: 'satispay',
                isOnlineOnly: true,
                hasPrintFailRollback: true,
                platformCheck: true
            },
            19: {
                handler: 'cashmatic',
                isOnlineOnly: false,
                hasPrintFailRollback: false,
                platformCheck: this.environmentInfoService.canUseTcpSockets(),
                allowsRefund: true
            },
            20: {
                handler: 'eatsready',
                isOnlineOnly: true,
                hasPrintFailRollback: true,
                platformCheck: true
            },
            21: {
                handler: 'cashlogy',
                isOnlineOnly: false,
                hasPrintFailRollback: false,
                platformCheck: this.environmentInfoService.canUseTcpSockets()
            },
            22: {
                handler: 'bedzzle',
                isOnlineOnly: true,
                hasPrintFailRollback: true,
                platformCheck: true
            },
            23: {
                handler: 'beddy',
                isOnlineOnly: true,
                hasPrintFailRollback: true,
                platformCheck: true
            },
            27: {
                handler: 'verifoneOcius',
                isOnlineOnly: true,
                hasPrintFailRollback: false,
                platformCheck: this.environmentInfoService.canUseTcpSockets(),
                allowsRefund: true
            },
            28: {
                handler: 'leanPMS',
                isOnlineOnly: true,
                hasPrintFailRollback: true,
                platformCheck: true
            },
            29: {
                handler: 'spiaggeIt',
                isOnlineOnly: true,
                hasPrintFailRollback: true,
                platformCheck: true
            },
            30: {
                handler: 'NexiMobilePos',
                isOnlineOnly: true,
                hasPrintFailRollback: false,
                platformCheck: this.environmentInfoService.isMobileApp() && this.environmentInfoService.isAndroid()
            },
            31: {
                handler: 'ZvtPos',
                isOnlineOnly: true,
                hasPrintFailRollback: false,
                platformCheck: this.environmentInfoService.canUseTcpSockets()
            },
            32: {
                handler: 'CimaCashdrawer',
                isOnlineOnly: false,
                hasPrintFailRollback: false,
                platformCheck: true
            },
            34: {
                handler: 'Coverflex',
                isOnlineOnly: true,
                hasPrintFailRollback: false,
                platformCheck: this.environmentInfoService.canUseTcpSockets()
            },
            35: {
                handler: 'NexoPOS',
                isOnlineOnly: true,
                hasPrintFailRollback: false,
                platformCheck: this.environmentInfoService.isAndroid()
            },
            36: {
                handler: 'ericsoftPMS',
                isOnlineOnly: false,
                hasPrintFailRollback: true,
                platformCheck: true
            },
            37: {
                handler: 'RedsysPOS',
                isOnlineOnly: true,
                hasPrintFailRollback: false,
                platformCheck: this.environmentInfoService.isElectronApp() && this.environmentInfoService.isWindows()
            },
            38: {
                handler: 'VneCashdrawer',
                isOnlineOnly: false,
                hasPrintFailRollback: true,
                platformCheck: true
            },
            39: {
                handler: 'campgestPMS',
                isOnlineOnly: true,
                hasPrintFailRollback: true,
                platformCheck: true
            },
            40: {
                handler: 'GloryCashdrawer',
                isOnlineOnly: false,
                hasPrintFailRollback: true,
                platformCheck: this.environmentInfoService.isMobileApp() || this.environmentInfoService.isElectronApp()
            },
            41: {
                handler: 'wellbyPMS',
                isOnlineOnly: true,
                hasPrintFailRollback: true,
                platformCheck: true
            },
            42: {
                handler: 'ZPayBridge',
                isOnlineOnly: true,
                hasPrintFailRollback: false,
                platformCheck: true
            }
        };
    }

    /**
     * Retrieves the IDs of the cash drawer methods.
     *
     * @return {number[]} An array of IDs of the cash drawer methods.
     */
    public getCahdrawerMethods() {
        const cashMethods = paymentMethodTypes.filter((type) => type.id !== 1 && type.class === 'cash').map(type => type.id);
        const digitalPaymentsIds = new Set(Object.keys(this.digitalPaymentHandlers).map(Number));

        return cashMethods.filter((id) => digitalPaymentsIds.has(id));
    }

    /**
     * Checks if a payment method is digital.
     *
     * @param {number} methodTypeId - The ID of the payment method type.
     * @return {boolean} True if the payment method is digital, false otherwise.
     */
    public isPaymentDigital(methodTypeId: number) {
        return this.digitalPaymentHandlers.hasOwnProperty(methodTypeId);
    }

    /**
     * Checks if a payment method is online only.
     *
     * @param {number} methodTypeId - The ID of the payment method type.
     * @return {boolean} True if the payment method is online only, false otherwise.
     */
    public isPaymentOnlineOnly(methodTypeId: number) {
        return !!this.digitalPaymentHandlers[methodTypeId]?.isOnlineOnly;
    }

    /**
     * Checks if the digital payment can be used in the current environment.
     *
     * @param {number} methodTypeId - The ID of the payment method type.
     * @return {boolean} True if the digital payment can be used in the current environment, false otherwise.
     */
    public isPaymentDigitalEnvironmentAllowed(methodTypeId: number) {
        return !!this.digitalPaymentHandlers[methodTypeId]?.platformCheck;
    }

    /**
     * Checks if a payment method allows refunds.
     *
     * @param {number} methodTypeId - The ID of the payment method type.
     * @return {boolean} True if the payment method allows refunds, false otherwise.
     */
    public paymentAllowsRefund(methodTypeId: number) {
        return !!this.digitalPaymentHandlers[methodTypeId]?.allowsRefund;
    }

    /**
     * Checks if a payment method has a rollback feature in case of print failure.
     *
     * @param {number} methodTypeId - The ID of the payment method type.
     * @return {boolean} True if the payment method has a rollback feature, false otherwise.
     */
    public paymentHasPrintFailRollback(methodTypeId: number) {
        return !!this.digitalPaymentHandlers[methodTypeId]?.hasPrintFailRollback;
    }

    /**
     * Initiates a digital payment or refund based on the provided amount and payment method.
     *
     * @param {number} amount - The amount to be paid or refunded.
     * @param {number} paymentMethodId - The ID of the payment method to use.
     * @param {DigitalPaymentOptions} options - Additional options for the payment.
     *
     * @return {object|object[]} An object or array of objects containing payment data and metadata.
     */
    public async digitalPayment(amount: number, paymentMethodId: number, options: DigitalPaymentOptions) {
        //Get payment method information
        const paymentMethod = await this.entityManagerService.paymentMethods.fetchOneOffline(paymentMethodId);

        if (!paymentMethod) {
            throw this.$translate.instant('DIGITAL_PAYMENTS.MANAGER.NO_CARDS_METHODS');
        }

        const paymentOptions = {
            ...options,
            paymentMethod: paymentMethod
        }

        //Get payment method type
        const paymentMethodType = paymentMethodTypes.find((type) => type.id === paymentMethod.payment_method_type_id);

        if(!paymentMethodType) {
            throw this.$translate.instant('DIGITAL_PAYMENTS.MANAGER.NO_CARDS_METHODS');
        }

        if(!this.isPaymentDigitalEnvironmentAllowed(paymentMethodType.id)) {
            throw this.$translate.instant('CASHREGISTER.PAYMENTS.METHOD_NOT_AVAILABLE_ON_THIS_DEVICE');
        }

        //Get payment handler
        const handlerName = this.digitalPaymentHandlers[paymentMethodType.id]?.handler;

        if(!handlerName) {
            throw this.$translate.instant('DIGITAL_PAYMENTS.MANAGER.NOT_FOUND');
        }

        //Get payment handler and determine if we are doing a payment or a refund
        const paymentHandler = this.$injector.get(handlerName) as DigitalPaymentHandler;
        const targetFunction: 'payment' | 'refund' = amount >= 0 ? 'payment' : 'refund';

        let paymentData: DigitalPaymentHandlerResult | undefined = {};

        //Stop if we don't have a payment function for this method
        if(typeof paymentHandler[targetFunction] !== 'function' && amount >= 0) {
            throw this.$translate.instant('DIGITAL_PAYMENTS.MANAGER.METHOD_NOT_SUPPORTED');
        }

        //Execute the payment/refund function if available
        if(typeof paymentHandler[targetFunction] === 'function') {
            paymentData = await paymentHandler[targetFunction]!(Math.abs(amount), paymentOptions);
        }

        const paymentMethodMeta = {
            payment_method_id: paymentMethod.id,
            payment_method_name: paymentMethod.name,
            payment_method_type_id: paymentMethodType.id,
            payment_method_type_name: paymentMethodType.name,
        };

        if(Array.isArray(paymentData)) {
            return paymentData.map((pData) => ({
                ...paymentMethodMeta,
                ...(pData || {}),
            }));
        }

        return {
            ...paymentMethodMeta,
            ...(paymentData || {}),
        };
    }
}

DigitalPaymentsManager.$inject = ["$translate", "$injector", "entityManager", "environmentInfo"];

angular.module('digitalPayments').factory('DigitalPaymentsManager', DigitalPaymentsManager);
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";

import {
    Component,
    Injectable,
    OnInit,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren,
    ViewContainerRef,
    inject
} from "@angular/core";

import {
    FormsModule,
    NgForm,
    NgModel,
    ReactiveFormsModule
} from "@angular/forms";

import { MatButtonModule } from "@angular/material/button";
import { MatOptionModule } from "@angular/material/core";

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
    MatDialogRef
} from "@angular/material/dialog";

import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTabsModule } from "@angular/material/tabs";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogContentComponent,
} from "@tilby/tilby-ui-lib/components/tilby-dialog";

import { TilbyGesturesDirective } from "@tilby/tilby-ui-lib/directives/tilby-gestures";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";

import { lastValueFrom } from "rxjs";

import {
    ConfigurationManagerService,
    EntityManagerService,
    ScreenOrientationService
} from "src/app/core";

import { ActiveSaleService } from "src/app/features";
import { keyBy, MathUtils } from "src/app/shared/utils";

import {
    Categories,
    CategoriesVariations,
    Components,
    Departments,
    Items,
    ItemsComponents,
    ItemsVariations,
    SalesItems,
    SalesItemsIngredients,
    SalesItemsVariations
} from "tilby-models";

type SaleItemManagerInput = {
    rowItem: SalesItems;
};

type SaleItemManagerOutput = {
    rowItem: SalesItems;
    unbundledRowItem?: SalesItems;
};

type IngredientsForm = (
    Components & { $type: 'component' } |
    ItemsComponents & { $type: 'ingredient' }
) & { quantity: number };

type VariationsForm = (ItemsVariations | CategoriesVariations) & { variation_value_id?: number };

@Component({
    selector: 'app-sale-item-manager-dialog',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatDialogModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatOptionModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatTabsModule,
        ReactiveFormsModule,
        ScrollingModule,
        TilbyCurrencyPipe,
        TilbyDialogContentComponent,
        TilbyGesturesDirective,
        TranslateModule,
    ],
    templateUrl: './sale-item-manager-dialog.component.html',
    styleUrls: ['./sale-item-manager-dialog.component.scss']
})
export class SaleItemManagerDialogComponent implements OnInit {
    private readonly data: SaleItemManagerInput = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly screenOrientationService = inject(ScreenOrientationService);
    private readonly translateService = inject(TranslateService);
    private readonly tilbyCurrencyPipe = inject(TilbyCurrencyPipe);
    private readonly activeSaleService = inject(ActiveSaleService);
    
    @ViewChild('optionsOne', { read: TemplateRef }) optionsOne!: TemplateRef<any>;
    @ViewChild('optionsTwo', { read: TemplateRef }) optionsTwo!: TemplateRef<any>;
    @ViewChild('rowItemManagerForm') rowItemManagerForm?: NgForm;
    @ViewChild('formContainerOptionsOne', { read: ViewContainerRef }) formContainerOptionsOne!: ViewContainerRef;
    @ViewChild('formContainerOptionsTwo', { read: ViewContainerRef }) formContainerOptionsTwo!: ViewContainerRef;
    @ViewChildren(NgModel) ngModels!: QueryList<NgModel>;
    
    protected isMobilePortrait = this.screenOrientationService.isMobilePortrait;
    protected screenHeight = screen.height;

    protected components: Components[] = [];
    protected item?: Items;
    protected title = '';
    protected category?: Categories;
    protected ingredients: IngredientsForm[] = [];
    protected variations: VariationsForm[] = [];
    protected formVariations: VariationsForm[] = this.variations;
    protected departments: Departments[] = [];
    protected applyAll: boolean = true;

    private ingredientsRemovalPrice = this.configurationManagerService.getPreference("orders.ingredients_removal_affects_price");
    private disableRequiredVariations = this.configurationManagerService.getPreference("cashregister.disable_required_variations");
    private ordersIngredientsRemovalDisabled = this.configurationManagerService.getPreference("orders.ingredients_removal_disabled");
    protected saleHasTable = !!this.activeSaleService.currentSale.room_id;
    protected halfPortionEnabled = this.configurationManagerService.getPreference("orders.half_portion_enable");
    protected disableChangePrice = !this.configurationManagerService.isUserPermitted("cashregister.use_price_changes");

    protected rowItem = structuredClone(this.data.rowItem);
    protected newQuantity: number = 1;
    protected unbundledQuantity?: number;
    protected exits: { name: string; value: string | number | null }[] = [];
    protected filterText: string = '';

    private compileItemInfo(item?: Items) {
        if (!item) {
            return;
        }

        //Set title
        this.title = `${this.translateService.instant("DIALOG.SALE_ITEM_MANAGER.TITLE")} ${item.name}`;

        //Initialize variations (if the item does not use combinations)
        if (!this.rowItem.combination_id) {
            this.variations = structuredClone([...(item.variations || []), ...(this.category?.variations || [])]);

            //Put even indexes first, then odd
            this.formVariations = [...this.variations.filter((v, i) => i % 2 === 0), ...this.variations.filter((v, i) => i % 2 === 1)];

            for (const variation of this.variations) {
                variation.variation_values?.sort((a, b) => (a.index ?? 0) - (b.index ?? 0));

                if (this.disableRequiredVariations) {
                    variation.required = false;
                }

                const found = variation.variation_values?.find((value) => value.default_value === true);

                if (found) {
                    variation.variation_value_id = found.id;
                }
            }
        }

        const itemComponentsMap = keyBy(item?.components || [], (c) => c.id);

        //Initialize Ingredients
        const itemIngredients = [...(item.components || [])].sort((a, b) => a.name!.toLowerCase().localeCompare(b.name!.toLowerCase()));

        for (const ingredient of itemIngredients) {
            Object.assign(ingredient, {
                $type: 'ingredient',
                quantity: 1
            });
        }

        //Initialize components
        const availableComponents = this.components
            .filter(component => !itemComponentsMap[component.id!] && (!component.categories?.length || component.categories.some(category => category.id === item.category_id)))
            .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
            .map(c => structuredClone(c));

        for (let component of availableComponents) {
            Object.assign(component, {
                $type: 'component',
                quantity: 0
            });
        }

        this.ingredients = [...itemIngredients, ...availableComponents] as IngredientsForm[];
    }

    async ngOnInit() {
        await this.loadInfo();
        this.compileItemInfo(this.item);

        if (this.rowItem.quantity) {
            this.newQuantity = this.rowItem.quantity;

            const ingredientsMap = keyBy(this.ingredients, i => i.id);
            const variationsMap = keyBy(this.variations, v => v.id);

            //Map existing variations
            for (const variation of this.rowItem.variations || []) {
                const found = variationsMap[variation.variation_id];

                if (found) {
                    found.variation_value_id = variation.variation_value_id;
                }
            }

            //Map existing ingredient variations
            for (const ingredient of this.rowItem.ingredients || []) {
                const found = ingredientsMap[ingredient.ingredient_id];
                const ingredientQuantity = ingredient.quantity || 0;

                if (found) {
                    switch (ingredient.type) {
                        case 'removed':
                            found.quantity = -1;
                            break;
                        case 'added':
                            found.quantity = (found.$type === 'ingredient') ? (ingredientQuantity + 1) : ingredientQuantity;
                            break;
                    }
                }
            }
        } else {
            this.newQuantity = 1;
        }

        const exits = parseInt(this.configurationManagerService.getPreference('orders.exits') || '5') || 5;

        this.exits = [{
            name: this.translateService.instant("ORDERS.ORDER_ITEM_MANAGER.NO_EXIT"),
            value: null
        }];

        for (let exit = 1; exit <= exits; exit++) {
            this.exits.push({
                name: `${this.translateService.instant("ORDERS.ORDER_ITEM_MANAGER.EXIT_LABEL")} ${exit}`,
                value: exit
            });
        }
    }

    ngAfterViewInit() {
        this.formContainerOptionsOne.createEmbeddedView(this.optionsOne);
        this.formContainerOptionsTwo.createEmbeddedView(this.optionsTwo);
    
        setTimeout(() => {
            this.ngModels.forEach(control => {
                if (control.name && !this.rowItemManagerForm?.controls[control.name]) {
                    this.rowItemManagerForm?.addControl(control);
                }
            });
        });
    }

    async loadInfo() {
        this.components = await this.entityManagerService.components.fetchCollectionOffline();
        this.departments = await this.entityManagerService.departments.fetchCollectionOffline().then(deps => deps.sort((a, b) => a.name.localeCompare(b.name)));
        this.item = (this.data.rowItem.item_id ? await this.entityManagerService.items.fetchOneOffline(this.data.rowItem.item_id) : undefined);
        this.category = (this.data.rowItem.category_id ? await this.entityManagerService.categories.fetchOneOffline(this.data.rowItem.category_id) : undefined);
    }

    parseNewComponents(): SalesItemsIngredients[] {
        return this.ingredients
            .filter((ingredient) => (
                (ingredient.$type === 'component' && ingredient.quantity !== 0) ||
                (ingredient.$type === 'ingredient' && ingredient.quantity !== 1)
            ))
            .map((ingredient) => {
                const quantity = (ingredient.$type === 'ingredient' && ingredient.quantity > 1)
                    ? (ingredient.quantity - 1)
                    : Math.abs(ingredient.quantity);

                return {
                    type: ingredient.quantity === -1 ? 'removed' : 'added',
                    name: ingredient.name,
                    ingredient_id: ingredient.id!,
                    price_difference: ingredient.price_difference,
                    quantity: quantity
                }
            });
    }

    parseNewVariations() {
        const newVariations: SalesItemsVariations[] = [];

        if (this.rowItem.combination_id) {
            return newVariations;
        }

        for (const variation of this.variations.filter(v => v.variation_value_id)) {
            const found = variation.variation_values?.find((element) => element.id === variation.variation_value_id);

            if (!found) {
                continue;
            }

            newVariations.push({
                name: variation.name,
                value: found.value!,
                price_difference: found.price_difference,
                linked_item_uuid: found.linked_item_uuid,
                linked_item_sku: found.linked_item_sku,
                variation_id: variation.id!,
                variation_value_id: variation.variation_value_id!
            });
        }

        return newVariations;
    };

    getPriceDifference(ingredient: IngredientsForm) {
        if (!ingredient.price_difference || (!this.ingredientsRemovalPrice && ingredient.$type === 'ingredient')) {
            return '';
        } else {
            return (ingredient.price_difference >= 0 ? '+' : '') + this.tilbyCurrencyPipe.transform((ingredient.quantity > 1) ? ingredient.price_difference * ingredient.quantity : ingredient.price_difference);
        }
    };

    incrementIngredientQuantity(ingredient: IngredientsForm) {
        if (ingredient.quantity <= -1) {
            switch (ingredient.$type) {
                case 'ingredient':
                    ingredient.quantity = 1;
                    break;
                case 'component':
                    ingredient.quantity = 0;
                    break;
            }
        } else {
            ingredient.quantity++;
        }
    };

    decrementIngredientQuantity(ingredient: IngredientsForm) {
        switch (ingredient.$type) {
            case 'ingredient':
                if (ingredient.quantity <= 1) {
                    ingredient.quantity = -1;
                } else {
                    ingredient.quantity--;
                }
                break;
            case 'component':
                if (this.ordersIngredientsRemovalDisabled) {
                    if (ingredient.quantity > 0) {
                        ingredient.quantity--;
                    }
                } else {
                    if (ingredient.quantity <= 0) {
                        ingredient.quantity = -1;
                    } else {
                        ingredient.quantity--;
                    }
                }
                break;
        }
    };

    answer() {
        const selectedDepartment = this.departments.find((department) => department.id === this.rowItem.department_id);

        if (!selectedDepartment) {
            return;
        }

        const unbundledQuantity = this.unbundledQuantity || 0;
        const newQuantity = this.newQuantity;

        const rowItem: SalesItems = {
            ...this.rowItem,
            department: selectedDepartment,
            department_id: selectedDepartment.id!,
            department_name: selectedDepartment.name!,
            half_portion: !!this.rowItem.half_portion,
            ingredients: this.parseNewComponents(),
            quantity: newQuantity,
            request_weighing: false,
            vat_perc: selectedDepartment.vat?.value!,
            variations: this.parseNewVariations()
        };

        let unbundledRowItem: SalesItems | undefined;

        if (unbundledQuantity && unbundledQuantity < newQuantity) {
            unbundledRowItem = {
                ...structuredClone(rowItem),
                quantity: unbundledQuantity,
            };

            rowItem.quantity = MathUtils.round(newQuantity - unbundledQuantity);
        }

        const output: SaleItemManagerOutput = { rowItem, unbundledRowItem };

        this.matDialogRef.close(output);
    };

    filteredIngredients() {
        const filterText = this.filterText.toLowerCase();

        return this.ingredients.filter((ingredient) => ingredient.name!.toLowerCase().includes(filterText));
    }

    async deleteItem() {
        this.applyAll = true;
        this.newQuantity = 0;
        this.answer();
    }
}

@Injectable({
    providedIn: 'root'
})
export class SaleItemManagerDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(data: SaleItemManagerInput): Promise<SaleItemManagerOutput> {
        const config: NonNullableConfigData<any> = { ...this.switchMobileDesktopDimensions({ height: '90vh', width: '90%' }), disableClose: true, data };

        return lastValueFrom(this.dialogRef.open(SaleItemManagerDialogComponent, config).afterClosed());
    }
}

import {
    Component,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output
} from '@angular/core';

import { DomSanitizer } from "@angular/platform-browser";

import {mobileCheck} from 'src/utilities';

import {
    ConnectionService,
    ThemeModeService,
    ToolbarEventsService
} from 'src/app/core';

import {OpenDialogsService} from 'src/app/dialogs';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';

@Component({
    selector: 'app-left-toolbar',
    templateUrl: './left-toolbar.component.html',
    styleUrls: ['./left-toolbar.component.scss'],
    host: {class: 'tw-flex tw-items-center'}
})
export class LeftToolbarComponent implements OnInit {
    private readonly connectionService = inject(ConnectionService);
    private readonly domSanitizer = inject(DomSanitizer);
    private readonly onDestroyService = inject(OnDestroyService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    protected readonly themeModeService = inject(ThemeModeService);

    protected isOffline = this.connectionService.isOffline;

    @Input() menuIcon = false;
    @Output() menuClickChange = new EventEmitter<void>();

    protected isMobile = mobileCheck();
    protected logoLight = this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/tilby/Tilby_a.svg');
    protected logoDark = this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/tilby/Tilby_a-dark.svg');
    protected moduleTitle?: string;

    constructor(
    ) {
       this.toolbarEventsService.moduleTitle.pipe(this.onDestroyService.takeUntilDestroy).subscribe(moduleTitle => {this.moduleTitle = "TOPBAR.TITLE."+moduleTitle;})
    }

    ngOnInit(): void {
    }

    menuClick($event: MouseEvent) {
        $event.stopPropagation();
        this.menuClickChange.emit();
    }

    openOfflineDialog() {
        return this.openDialogsService.openOfflineDialog();
    }
}

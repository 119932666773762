import angular from 'angular';
import _ from 'lodash';

angular.module('application').factory('demoGreetingDialog', ["$mdDialog", function($mdDialog) {
    demoGreetingDialogController.$inject = ["$scope", "$rootScope", "$mdDialog"];
    function demoGreetingDialogController($scope, $rootScope, $mdDialog) {
        var operators = [{
            name: "Roberto",
            email: "assistenza@scloby.com",
            imageName: "roberto"
        }, {
            name: "Simone",
            email: "assistenza@scloby.com",
            imageName: "simone"
        }];

        $scope.randomOp = operators[_.random(0, 1)];

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        if ($rootScope.userActiveSession.first_name) {
            $scope.userName = $rootScope.userActiveSession.first_name;
        } else {
            $scope.userName = $rootScope.userActiveSession.username;
        }
    }

    var demoGreetingDialog = {
        show: function() {
            return $mdDialog.show({
                controller: demoGreetingDialogController,
                template: require('./demo-greeting-dialog.html')
            });
        }
    };

    return demoGreetingDialog;
}]);

import angular from 'angular';
import { EricsoftApi, EricsoftPMSApiService } from 'app/modules/application/service/ericsoft-pms-api/ericsoft-pms-api';
import { BedzzleCommonFolio } from 'app/modules/digital-payments/service/bedzzle/bedzzle-common';
import moment from 'moment-timezone';
import { MathUtils } from 'src/app/shared/utils';

class EricsoftPaymentsController {
    //PMS configuration parameters
    private ipAddress = '';
    private port = 5000;
    private username = '';
    private password = '';
    private propertyId = '';
    private apiHandler?: EricsoftApi;

    //UI params
    message = '';
    amountToPay: number;
    fetchingFolios = false;
    folios: BedzzleCommonFolio[] = [];
    needsSetup = false;
    operationInProgress = false;
    searchText = '';
    selectedFolio: BedzzleCommonFolio | null = null;
    showAmount: boolean;
    showBalance: boolean;
    visibleFolios: BedzzleCommonFolio[] = [];

    constructor(
        private readonly $mdDialog: any,
        private readonly $translate: any,
        private readonly $filter: any,
        private readonly checkManager: any,
        private readonly ericsoftPMSApi: EricsoftPMSApiService,
        options: any
    ) {
        this.amountToPay = MathUtils.round(options.amount);
        this.showAmount = !(this.checkManager.getPreference('pms_payments.hide_total_amount'));
        this.showBalance = !(this.checkManager.getPreference('pms_payments.hide_balance'));
    }

    canSetupShop() {
        return this.needsSetup && this.ipAddress && this.username && this.password && this.propertyId;
    }

    async setupShop() {
        this.operationInProgress = true;

        try {
            //Setup API manager (handles saving of parameters into the shop_preferences)
            await this.ericsoftPMSApi.setupApi({
                ip_address: this.ipAddress,
                port: this.port,
                username: this.username,
                password: this.password,
                property_id: this.propertyId
            });

            this.needsSetup = false;
            this.fetchFolios();
        } finally {
            this.operationInProgress = false;
        }
    };

    $onInit() {
        //Get current API config
        ({
            ip_address: this.ipAddress,
            port: this.port,
            username: this.username,
            password: this.password,
            property_id: this.propertyId
        } = this.ericsoftPMSApi.getCurrentConfig());

        this.fetchFolios();
    }

    async fetchFolios() {
        this.fetchingFolios = true;

        try {
            this.apiHandler = this.ericsoftPMSApi.getApiInstance();

            try {
                const roomFolios = await this.apiHandler.getRoomFolios();
                const miscSalesFolios = await this.apiHandler.getMiscSalesFolios().catch(() => []);

                this.folios = [...miscSalesFolios, ...roomFolios.sort((a, b) => a.keyDoorCode! > b.keyDoorCode! ? 1 : -1)];
                this.visibleFolios = this.folios;
            } catch (error: any) {
                if (error?.status == -1) {
                    this.message = this.$translate.instant('CASHREGISTER.ERICSOFT_PMS_PAYMENTS.OFFLINE');
                } else {
                    this.message = error?.data?.error?.msg || '';
                }

                throw error;
            }
        } catch (error) {
            this.needsSetup = true;
            return;
        } finally {
            this.fetchingFolios = false;
        }
    };

    performSearch() {
        //Reset visibleFolios
        this.visibleFolios = this.folios;

        //Split searchText by spaces and filter by each word
        this.searchText.split(/\s*/g).forEach((word) => {
            this.visibleFolios = this.$filter('filter')(this.visibleFolios, word);
        });

        //If the selectedFolio is not in the visibleFolios, unselect it
        if (!this.visibleFolios.find((folio) => folio === this.selectedFolio)) {
            this.selectedFolio = null;
        }
    };

    cancel() {
        this.$mdDialog.cancel('CANCELED');
    }

    isMiscSale(folio: BedzzleCommonFolio) {
        return !folio.stayId;
    }

    deleteMessage() {
        this.message = '';
    }

    selectFolio(folio: BedzzleCommonFolio) {
        this.selectedFolio = folio;
    }

    parseEricsoftDate(date: string) {
        return moment(date, 'YYYY-MM-DD').format('L');
    }

    confirm() {
        if (this.selectedFolio) {
            this.$mdDialog.hide(this.selectedFolio);
        }
    };
}

export class EricsoftPMSPaymentsDialog {
    constructor(
        private readonly $mdDialog: any,
    ) {
    }

    public show(options: any) {
        return this.$mdDialog.show({
            controller: EricsoftPaymentsController,
            controllerAs: '$ctrl',
            template: require('./ericsoft-pms-payments.html'),
            clickOutsideToClose: true,
            locals: {
                options: options
            }
        });
    }
}

EricsoftPaymentsController.$inject = ['$mdDialog', '$translate', '$filter', 'checkManager', 'ericsoftPMSApi', 'options'];
EricsoftPMSPaymentsDialog.$inject = ["$mdDialog"];

angular.module('cashregister').factory('ericsoftPayments', EricsoftPMSPaymentsDialog);
import angular from 'angular';
import _ from 'lodash';

angular.module('kiosk').controller('KioskDocumentSelectionCtrl', KioskDocumentSelectionCtrl);
KioskDocumentSelectionCtrl.$inject = ["$scope", "$state", "kioskSettings", "kioskUtils", "printers", "checkManager", "documentPrintersManager", "migrationHelper"];

function KioskDocumentSelectionCtrl($scope, $state, kioskSettings, kioskUtils, printers, checkManager, documentPrintersManager, migrationHelper) {
    const ActiveSale = migrationHelper.getActiveSale();
    let printerToUse;
    let printerId = kioskSettings['document_selection.default_printer_id'] || checkManager.getPreference('fiscalprinter.def.id');

    if(printerId) {
        printerToUse = _.find(printers, { id: _.toInteger(printerId) });
    }

    if(!printerToUse) {
        printerToUse = kioskUtils.getAvailablePrinters(printers)[0];
    }

    $scope.next = function(documentType) {
        var documentsToUse;

        switch(documentType) {
            case 'receipt':
                documentsToUse = printerToUse.type === 'receipt' ? ['generic_receipt'] : ['fiscal_receipt'];
                break;
            case 'invoice':
                documentsToUse = printerToUse.type === 'receipt' ? ['generic_invoice'] : ['e_invoice', 'invoice'];
                break;
            default:
                break;
        }
        $scope.$parent.enableTimeouts();
        documentPrintersManager.getPrinterDocumentData(printerToUse.id, documentsToUse).then(function(documentData) {
            ActiveSale.setPrinterDocumentData(documentData);
            $state.go(kioskUtils.getNextModule());
        });
    };

    if(kioskSettings['document_selection.enabled'] === false) {
        if(kioskUtils.compareStatesOrder() > 0) {
            $scope.next('receipt');
        } else {
            kioskUtils.skipModule();
        }
    } else {
        var buttonTextColor = kioskSettings['general.base_button_text_color'] || '#fff';
        var buttonBackgroundColor = kioskSettings['general.base_button_bgcolor'] || '#666';
        var baseTextColor = kioskSettings['general.base_text_color'] || '#000';
        var zoomLevel = kioskSettings['general.zoom_level'] || 1.5;

        _.assign($scope, {
            headerParams: {
                bannerUrl: kioskSettings['document_selection.banner_url'],
                bannerBgColor: kioskSettings['general.header_background_color'],
                bannerHeight: kioskSettings['general.header_height'],
                logoUrl: _.toString(kioskSettings['general.media_url_logo']),
                zoomLevel: zoomLevel,
            },
            customerAssistantEnabled: kioskSettings['general.customer_assistant'],
            title: kioskUtils.getLabelText(kioskSettings, 'document_selection.title_label', 'KIOSK.DOCUMENT_SELECTION.TITLE'),
            totalLabel: kioskUtils.getLabelText(kioskSettings, 'cart.total_label', 'KIOSK.TOTAL'),
            backButtonLabel: kioskUtils.getLabelText(kioskSettings, 'general.back_button_label', 'KIOSK.BACK'),
            nextButtonLabel: kioskUtils.getLabelText(kioskSettings, 'general.next_button_label', 'KIOSK.NEXT'),
            iconStyle: { color: buttonTextColor },
            buttonStyle: {
                color: buttonTextColor,
                'background-color': buttonBackgroundColor
            },
            invoiceImage: kioskSettings['document_selection.invoice_image_url'] || 'assets/images/kiosk/document_invoice.jpg',
            receiptImage: kioskSettings['document_selection.receipt_image_url'] || 'assets/images/kiosk/document_receipt.jpg',
            documentSelectionStyle: {
                'zoom': zoomLevel,
                color: baseTextColor
            },
            backgroundUrl: kioskSettings['document_selection.background_url']
        });

        if($scope.backgroundUrl) {
            $scope.mediaType = kioskUtils.getMediaType($scope.backgroundUrl);
        }

        $scope.back = function() {
            $scope.$parent.enableTimeouts();
            $state.go(kioskUtils.getPreviousModule());
        };

        $scope.canPrintReceipt = function() {
            switch(printerToUse.type) {
                case 'rt': case 'fiscal':
                    return printerToUse.print_fiscal_receipt;
                case 'receipt':
                    return printerToUse.print_generic_receipt;
                default:
                    return false;
            }
        };

        $scope.canPrintInvoice = function() {
            switch(printerToUse.type) {
                case 'rt': case 'fiscal':
                    return printerToUse.print_e_invoice || printerToUse.print_invoice;
                case 'receipt':
                    return printerToUse.print_generic_invoice;
                default:
                    return false;
            }
        };

        $scope.help = () => {
            // @toDO
            console.debug('AIUTO Da completare');
        };
    }
}
import angular from 'angular';
import * as async from 'async';
import _ from 'lodash';
import moment from 'moment-timezone';
import { stringToLines } from 'src/app/shared/string-utils';

angular.module('printers').factory('DtrRTDriver', ["$q", "$window", "$timeout", "fiscalUtils", "util", "errorsLogger", "checkManager", function($q, $window, $timeout, fiscalUtils, util, errorsLogger, checkManager) {
    /**
     *  Tilby DTR Driver
     *
     *  Usage:
     *  DtrRTDriver.setup() and after call public methods:
     *
     *  - autoConfigurePrinter
     *  - printFiscalReceipt
     *  - printCourtesyReceipt
     *  - printNonFiscal
     *  - openCashDrawer
     *  - dailyClosing
     *  - dailyRead
     *  - readDgfeBetween (read/print)
     *  - printFiscalMemoryBetween
     *  - printFreeNonFiscal
     */

    var scope = {};
    var defaultPrinterColumns = 32;

    var errorsTable = {
        48: 'FISCAL_CLOSING_NEEDED',
        96: 'RT_ALREADY_VOID',
        224: 'PAPER_END'
    };

	var checkQueueType = function(type) {
		if(_.includes(['auto', 'manual'], checkManager.getPreference('cashregister.queue.mode'))) {
			var queueType = [];

			try {
				queueType = JSON.parse(checkManager.getPreference('cashregister.queue.print_type'));
			} catch(e) {}

			return _.includes(queueType, type);
		} else {
			return false;
		}
    };

    var byteArrayToString = function(array) {
        return _.map(array, function(val) { return String.fromCharCode(val); }).join('');
    };

    /**
     *  cleanUpSpecialChars
     */
    function cleanUpSpecialChars(str) {
        return str.replace("€", "E").replace("/", "-").replace(/TOTALE/i, 'TOT   ').replace(/[^\x00-\x7F]/g, "*");
    }

    function getPriceChangeCommand(priceChange, amount, department) {
        if(!_.isNil(amount)) {
            var commandType;

            switch(priceChange.type) {
                case 'discount_fix':
                case 'discount_perc':
                case 'gift': //TODO: figure out how to handle gifts for xml7
                    commandType = _.isNil(department) ? 4 : 3;
                break;
                case 'surcharge_fix':
                case 'surcharge_perc':
                    commandType = _.isNil(department) ? 8 : 7;
                break;
            }

            if(!_.isNil(commandType)) {
                return [Math.round(Math.abs(amount) * 100), 'H', commandType, 'M'];
            }
        }
    }

    function getPaymentCode(method_type_id, unclaimed) {
        switch(method_type_id) {
            case 1: case 19: case 21: case 32: case 38: case 39: case 40:
                return 1; //Cash
            case 3:
                return 2; //Cheque
            case 4: case 5: case 8: case 11: case 13: case 14: case 15: case 17: case 18: case 27: case 30: case 31: case 35: case 37:
                return 3; //Credit / Credit card
            case 6: case 20: case 34:
                return 5; //Ticket
            case 2:
                return 6; //Unclaimed
            case 10:
                return 10;
            case 22: case 23: case 24: case 28: case 29: case 36: case 41:
                return 7;
            case 25:
                return 8;
            case 26:
                return 11;
            case 16: case 42:
                return (unclaimed ? 5 : 3); //(Ticket if unclaimed, digital if otherwise)
            default:
                return 1;
        }
    }

    var getPacket = function (command) {
        var i;
        var packet = [];

        //DATA
        for(i = 0; i < command.length; i++) {
            packet.push(command.charCodeAt(i));
        }

        var buffer = new ArrayBuffer(packet.length);
        var bufView = new Uint8Array(buffer);

        for (i = 0; i < packet.length; i++) {
            bufView[i] = packet[i];
        }

        return buffer;
    };

    var closeSocket = function (socket, callback) {
        if(_.isInteger(_.get(socket, ['info', 'socketId']))) {
            $window.chrome.sockets.tcp.disconnect(socket.info.socketId, function() {
                errorsLogger.debug("[DEBUG] Socket disconnected");

                $window.chrome.sockets.tcp.close(socket.info.socketId);

                if(_.isFunction(callback)) {
                    callback();
                }
            });
        }
    };

    var parseStatusResponse = function(response) {
        if(_.isString(response)) {
            return {
                printer_serial: response.slice(2, 13),
                receipt_open: response.slice(14, 15) === '1',
                nf_document_open: response.slice(16, 17) === '1',
                keyboard_locked: response.slice(18, 19) === '1',
                error_code: _.toInteger(response.slice(20, 23)),
                paper_end: response.slice(24, 25) === '1',
                date_time: moment(response.slice(26, 30) + response.slice(31, 37), 'HHmmDDMMYY'),
                receipt_number: _.toInteger(response.slice(38, 42)),
                daily_closing_number: _.toInteger(response.slice(43, 47))
            };
        } else {
            return {};
        }
    };

    function sendCommands(printerSocket, commands, options) {
        var d = $q.defer();

        if(!_.isObject(options)) {
            options = {};
        }

        if(_.isObject(printerSocket)) {
            var reqTimeout;
            var idleTimeout;
            var responseStream;
            var responseCheck;
            var currentResponse;
            var currentCallback;
            var currentCommand;
            var currentCommandType;
            var waitMode;
            var checkMode;
            var timeout = options.timeout || 20000;

            var getCommandAnswerRegex = function(commandType) {
                var pattern;

                switch(commandType) {
                    case '2X': //Printer status
                    case '3X': //FW version
                    case '?': //Receipt status
                    case '1?': //Dgfe status
                    case '2?': //RT status
                        pattern = ['^', _.escapeRegExp(commandType), '(.*', _.escapeRegExp(commandType), ')$'];
                    break;
                    case '9w': case '10w': case '11w': case '12w': //Dgfe read commands (response is sent asynchronously, so consider only the echo and use wait mode to receive the actual answer)
                    default: //Commands without an answer: consider only the echo
                        pattern = ['^', _.escapeRegExp(currentCommand), '(.*)'];
                }

                return new RegExp(pattern.join(''));
            };

            var listen = function(info) {
                if (info.socketId === printerSocket.info.socketId) {
                    responseStream += byteArrayToString(new Uint8Array(info.data));
                    var answered = waitMode || getCommandAnswerRegex(checkMode ? '2X' : currentCommandType).test(responseStream);

                    if(answered) {
                        $timeout.cancel(reqTimeout);
                        $timeout.cancel(idleTimeout);

                        if(checkMode) {
                            responseCheck = _.replace(responseStream, '2X', '');
                            errorsLogger.debug("[DTR Driver] Check: [" + responseCheck + ']');

                            if(currentCommandType === '2X') {
                                currentResponse = responseCheck;
                            }

                            var errorCode = parseStatusResponse(responseCheck).error_code;

                            if(errorCode && !options.ignoreErrorStatuses) {
                                currentCallback(errorsTable[errorCode] || 'DTR.ERROR_' + errorCode);
                            } else {
                                currentCallback(null, currentResponse);
                            }
                        } else {
                            if(waitMode) {
                                idleTimeout = $timeout(commandCompleted, options.waitIdle, false);
                            } else {
                                if(options.waitIdle) {
                                    waitMode = true;
                                    idleTimeout = $timeout(commandCompleted, options.waitIdle, false);
                                } else {
                                    commandCompleted();
                                }
                            }
                        }
                    }
                }
            };

            $window.chrome.sockets.tcp.onReceive.addListener(listen);

            var timeoutFunc = function() {
                currentCallback("REQ_TIMEOUT");
            };

            var sendPacket = function(packet) {
                errorsLogger.debug("[DTR Driver] Command: [" + packet + ']');
                $window.chrome.sockets.tcp.send(printerSocket.info.socketId, getPacket(packet), _.noop);
            };

            var commandCompleted = function() {
                //Get response from response stream
                currentResponse = _.replace(responseStream, currentCommand, '');
                responseStream = '';
                errorsLogger.debug("[DTR Driver] Response: [" + currentResponse + ']');

                //Switch to check mode to make sure the printer has not returned an error after the last command
                checkMode = true;
                reqTimeout = $timeout(timeoutFunc, timeout, false);
                sendPacket('2X');
            };

            async.mapSeries(commands, function(command, cb) {
                //Get current command and command type
                if(_.isArray(command)) {
                    currentCommand = command.join('');
                    currentCommandType = _.last(command);
                } else {
                    currentCommand = command;
                    currentCommandType = command;
                }

                //Init command processing status
                waitMode = false;
                checkMode = currentCommand === '2X'; //Set check mode to true if the command is '2X', as it doesn't make sense to check the printer again after a check command
                currentCallback = cb;
                responseStream = '';

                //Set timeout and send command
                reqTimeout = $timeout(timeoutFunc, timeout, false);
                sendPacket(currentCommand);
            }, function(err, results) {
                $window.chrome.sockets.tcp.onReceive.removeListener(listen);

                if(err) {
                    d.reject(err);
                } else {
                    d.resolve(results);
                }
            });
        } else {
            d.reject('INVALID_SOCKET');
        }

        return d.promise;
    }

    function isAvailable(printer) {
        var d = $q.defer();

        if(_.get($window, "chrome.sockets.tcp")) {
            $window.chrome.sockets.tcp.create(function(socketInfo) {
                var printerSocket = {
                    printer: printer,
                    info: socketInfo
                };

                var timeoutConn = function () {
                    $window.chrome.sockets.tcp.getInfo(socketInfo.socketId, function(info) {
                        if (!info.connected) {
                            d.reject('CONNECTION_ERROR');
                        }
                    });
                };

                var timeoutHandle = $timeout(timeoutConn, 10000);

                $window.chrome.sockets.tcp.connect(socketInfo.socketId, printer.ip_address, printer.port || 1126, function(result) {
                    $timeout.cancel(timeoutHandle);
                    errorsLogger.debug("Socket connected to " + printer.ip_address);

                    if(result === 0) {
                        sendCommands(printerSocket, ['K', '2X', '3X'], { ignoreErrorStatuses: true }).then(function(responses) {
                            var printerStatus = parseStatusResponse(responses[1]);
                            var printerVersion = _.split(responses[2], 'H');

                            _.assign(printerSocket, {
                                printerSerial: printerStatus.printer_serial,
                                printerFirmware: printerVersion[0]
                            });

                            d.resolve(printerSocket);
                        }, function(error) {
                            closeSocket(printerSocket);
                            d.reject(error);
                        });
                    } else {
                        closeSocket(printerSocket);
                        d.reject('CONNECTION_ERROR');
                    }
                });
            });
        } else {
            d.reject('UNSUPPORTED_ENVIRONMENT');
        }

        return d.promise;
    }

    function lineToCommand(line, columns) {
        if(_.isEmpty(line)) {
            return ['" "', '@', '"', '"', '@'];
        } else {
            return ['"', _.truncate(cleanUpSpecialChars(line), { length: columns, omission: '' }), '"', '@', '"', '"', '@'];
        }
    }

    /**
     * linesToCommands
     */
    function linesToCommands(lines, columns) {
        if (!_.isArray(lines)) {
            if (_.isString(lines)) {
                lines = lines.split("\n");
            }
        }

        if(_.isNil(columns)) {
            columns = defaultPrinterColumns;
        }

        return _.concat('j', _.map(lines, function(line) {
            return lineToCommand(line, columns);
        }), 'J');
    }

    /**
     *  saleToReceiptCommands
     */
    function saleToReceiptCommands(sale, printerSocket, options) {
        var commands = [];
        var isRefunding;
        var columns = scope.printer.columns || defaultPrinterColumns;

        var addTextLine = function(text) {
            commands.push(lineToCommand(text, columns));
        };

        const isRefundVoid = fiscalUtils.isRefundVoidSale(sale);

        if (isRefundVoid) {
			var docDataItem = _.find(sale.sale_items, function (si) {
				return si.reference_sequential_number && si.reference_date;
			});

			var docData = {};

			if (docDataItem) {
				docData = {
					reference_sequential_number: docDataItem.reference_sequential_number,
					reference_date: docDataItem.reference_date
				};
			} else {
				return 'MISSING_REFERENCE_DOCUMENT';
			}

			if (_.every(sale.sale_items, docData)) { //If all of the items are from the same document
				//Check if we are doing a void or a refund
				var hasVoid = false;
                var hasRefund = false;

				_.forEach(sale.sale_items, function (si) {
					if (si.refund_cause_id === 6) {
						hasVoid = true;
					} else {
						hasRefund = true;
					}
				});

				if (hasRefund && hasVoid) {
					return 'MIXED_REFUND_CAUSES';
				} else {
					var docSeqNum = fiscalUtils.parseRTDocumentSequentialNumber(docData.reference_sequential_number);
                    var documentDate = moment(docData.reference_date).format('DDMMYYYY');

					if (hasVoid) {
                        commands.push(['"', _.padStart(docSeqNum.daily_closing_num, 4, '0'), '-', _.padStart(docSeqNum.document_sequential_number, 4, '0'), '-', documentDate, '"', '105M']);

                        return commands;
                    } else {
                        commands.push(['"', _.padStart(docSeqNum.daily_closing_num, 4, '0'), '-', _.padStart(docSeqNum.document_sequential_number, 4, '0'), '-', documentDate, '-', printerSocket.printerSerial, '"', '104M']);

                        isRefunding = true;
                    }

				}
			} else {
				return 'ITEMS_FROM_DIFFERENT_DOCUMENTS';
			}
        } else {
            if(_.some(sale.sale_items, function(saleItem) { return saleItem.quantity < 0; })) {
                return 'REFUNDS_NOT_ALLOWED_IN_SALES';
            }

            // Sale Name
            var printName = (scope.options.print_name === false) ? false : true;

            if(printName) {
                for(let row of fiscalUtils.getFiscalReceiptHeaderLines(sale)) {
                    addTextLine(`# ${row}`);
                }
            }

            //Add lottery code if available
            if(sale.lottery_code) {
                commands.push(['"', sale.lottery_code, '"', 'L']);
            }
        }

        var printDetails = (options.print_details === false && !isRefundVoid) ? false : true;

        // Sale items
        if(printDetails || isRefunding) {
            _.forEach(fiscalUtils.extractSaleItems(sale), function(si) {
                var siName = _.truncate(cleanUpSpecialChars(si.name || si.department_name), { length: columns });
                var departmentCode = _.toString(si.department_id);

                commands.push(['"', siName, '"',  Math.abs(si.quantity).toFixed(3).replace('.',','), '*', Math.round(si.price * 100), 'H', departmentCode, 'R']);

                if(!isRefunding) {
                    // Check printNotes
                    var printNotes = (scope.options.print_notes === false) ? false :true;

                    // Item barcode (if print_notes)
                    if (printNotes && si.barcode) {
                        if (si.barcode.toLowerCase().indexOf('p') < 0 && si.barcode.toLowerCase().indexOf('q') < 0) {
                            addTextLine('#' + si.barcode.trim());
                        }
                    }

                    // Notes
                    if (printNotes && si.notes) {
                        var notesLines = si.notes.split("\n");
                        _.forEach(notesLines, function(nl) {
                            if (nl.trim()) {
                                addTextLine('# ' + cleanUpSpecialChars(nl.trim()));
                            }
                        });
                    }

                    // Refund cause
                    if (si.quantity < 0 && si.refund_cause_description) {
                        addTextLine('# ' + cleanUpSpecialChars(si.refund_cause_description));
                    }

                    // Discount / Surcharges
                    // - Sort discount/surcharges by index
                    if (si.quantity > 0) {
                        // Discount/Surcharges
                        var partialPrice = fiscalUtils.roundDecimals(si.price * si.quantity);

                        _(si.price_changes).sortBy('index').forEach(function(pc) {
                            var pcAmount = fiscalUtils.getPriceChangeAmount(pc, partialPrice);

                            if(!_.isNil(pcAmount)) {
                                partialPrice = fiscalUtils.roundDecimals(partialPrice + pcAmount);
                                var pcCommand = getPriceChangeCommand(pc, pcAmount, si.department_id);

                                if(pcCommand) {
                                    commands.push(pcCommand);
                                }
                            }
                        });
                    }
                }
            });
        } else { // Hide details
            _(fiscalUtils.extractDepartments(sale)).forIn(function(depTotal, idx) {
                commands.push([Math.round(depTotal.amount * 100), 'H', _.toString(depTotal.id), 'R']);
            });
        }

        if(!isRefunding) {
            commands.push('=');

            // Apply discount/surcharges on subtotal
            if (!isRefundVoid && printDetails) {
                var partialPrice = fiscalUtils.roundDecimals(sale.amount);

                _(sale.price_changes).sortBy('index').forEach(function(pc) {
                    var pcAmount = fiscalUtils.getPriceChangeAmount(pc, partialPrice);

                    if(!_.isNil(pcAmount)) {
                        partialPrice = fiscalUtils.roundDecimals(partialPrice + pcAmount);
                        var pcCommand = getPriceChangeCommand(pc, pcAmount);

                        if(pcCommand) {
                            commands.push(pcCommand);
                        }
                    }
                });
            }

            _(fiscalUtils.extractPayments(sale)).forEach(function(p) {
                commands.push([Math.round(p.amount * 100),  'H', getPaymentCode(p.method_type_id, p.unclaimed), 'T']);
            });

            // Ticket change
            var ticketChange = (scope.options.ticket_change === false) ? false : true;

            if (ticketChange && sale.change > 0 && sale.change_type === 'ticket') {
                var ticketChangeAmount = util.round(sale.change);

                addTextLine("######### RESTO TICKET #########");
                addTextLine("                                ");
                addTextLine(" QUESTO COUPON VALE: " + ticketChangeAmount + " EURO");
                addTextLine("                                ");
                addTextLine(" Presenta alla cassa prima del  ");
                addTextLine(" pagamento questo coupon per    ");
                addTextLine(" avere riconosciuto il credito. ");
                addTextLine(" Valido 30 giorni dalla data di ");
                addTextLine(" emissione.                     ");
                addTextLine(" Non convertibile in denaro.    ");
                addTextLine("                                ");
                addTextLine("################################");

                var ticketBarcode = _.toString(1212000000000 + Math.round(ticketChangeAmount * 100));
                commands.push(['"', ticketBarcode, '"', '1Z']);
            }

            // Add Tail (tail for this sale + general tail)
            var tail = options.tail || '';

            if (scope.options.tail) {
                tail += "\n" + scope.options.tail;
            }

            if (tail) {
                var tails = tail.split("\n");

                _.forEach(tails, function(tRow) {
                    addTextLine(tRow);
                });
            }

            var printCustomerDetail = (scope.options.print_customer_detail === false) ? false : true;

            // Customer
            if (printCustomerDetail && sale.sale_customer) {
                var saleCustomer = sale.sale_customer;
                var customerInfo = fiscalUtils.getCustomerInfo(saleCustomer);

                _.forEach(customerInfo, addTextLine);
            }

            // Print Seller
            var printSeller = (scope.options.print_seller === false) ? false : true;

            if (printSeller && sale.seller_name) {
                var sellerPrefix = scope.options.seller_prefix || "Ti ha servito";
                addTextLine(sellerPrefix + " " + sale.seller_name.trim().split(" ")[0]);
            }

            // Payment tail */
            if (options.tail && options.tail.indexOf('FIRMA') > -1) {

                // Add Payment Tail
                var pTail = options.tail.split("\n");
                if (pTail.length) {
                    _.forEach(pTail, function(tRow) {
                        addTextLine(tRow);
                    });
                }
            }

            if(!isRefundVoid && checkQueueType('tail')) {
                _.forEach(fiscalUtils.getQueueCouponRows(sale, columns, { doubleWidth: true }), function(row) {
                    addTextLine(row.text);
                });
            }

            _.times(7, function() {
                addTextLine();
            });

            // Barcode
            /* var printBarcodeId = true;

            if (scope.options.print_barcode_id === false) {
                printBarcodeId = false;
            }

            if (printBarcodeId && _.isInteger(sale.id)) {
                var saleIdBarcode = cleanUpSpecialChars(sale.id.toString());

                commands.push(['"', saleIdBarcode, '"', '3Z']);
            } */
        } else {
            commands.push('1T');
        }

        return commands;
    }

    /**
	 * @description saleToNonFiscalCommands
	 */
	function saleToNonFiscalCommands(sale, options) {
        var commands = ['j'];
        const savePaper = !!checkManager.getPreference('cashregister.save_paper_on_prebill');
        const isRefundVoid = fiscalUtils.isRefundVoidSale(sale);
		var printName = (savePaper || scope.options.print_name === false) ? false : true;
        var printerColumns = scope.options.columns || defaultPrinterColumns;

		var addLine = function (line) {
            commands.push(lineToCommand(line, printerColumns));
        };

        if(printName) {
            for(let row of fiscalUtils.getFiscalReceiptHeaderLines(sale)) {
                addLine(row);
            }
        }

		var printDetails = (options.print_details === false) ? false : true;

		// Sale items
		if (printDetails) {
			_.forEach(fiscalUtils.extractSaleItems(sale), function(si) {
				var rowDescription = si.quantity + "x " + (si.name || si.department_name);
				var rowPrice = fiscalUtils.decimalToString(si.price * si.quantity);
				var row = _.padEnd(_.truncate(rowDescription, { length: (printerColumns - rowPrice.length - 2) }), printerColumns - rowPrice.length, " ") + rowPrice;

				addLine(row);

				// Notes
				var printNotes = (scope.options.print_notes === false) ? false : true;

				if (printNotes && si.notes) {
					var notesLines = stringToLines(si.notes, printerColumns);

					_.forEach(notesLines, function (nl) {
						if (nl.trim()) {
							addLine(nl.trim());
						}
					});
				}

				// Discount/Surcharges
                var partialPrice = fiscalUtils.roundDecimals(si.price * si.quantity);

                _(si.price_changes).sortBy('index').forEach(function(pc) {
                    var pcAmount = fiscalUtils.getPriceChangeAmount(pc, partialPrice);
                    partialPrice = fiscalUtils.roundDecimals(partialPrice + pcAmount);

                    if(!_.isNil(pcAmount)) {
                        var rowDescription = pc.description;
                        var rowAmount = (_.startsWith(pc.type, 'surcharge') ? '+' : '') + pcAmount.toFixed(2).replace(".", ",");
                        var row = _.padEnd(_.truncate(rowDescription, { length: (printerColumns - rowPrice.length - 2) }), printerColumns - rowAmount.length, " ") + rowAmount;

                        addLine(row);
                    }
                });
			});
		}

		// Sub-total and his discount/surcharges
		var subTotDescr = "SUBTOT";
		var subTotAmount = fiscalUtils.decimalToString(sale.amount);

        addLine(_.padEnd(subTotDescr, printerColumns - subTotAmount.length, " ") + subTotAmount);

		// Apply discount/surcharges on subtotal
		if (!isRefundVoid && printDetails) {
			var partialPrice = fiscalUtils.roundDecimals(sale.amount);

			_(sale.price_changes).sortBy('index').forEach(function(pc) {
				var pcAmount = fiscalUtils.getPriceChangeAmount(pc, partialPrice);
				partialPrice = fiscalUtils.roundDecimals(partialPrice + pcAmount);

				if(!_.isNil(pcAmount)) {
					var rowDescription = pc.description;
					var rowAmount = (_.startsWith(pc.type, 'surcharge') ? '+' : '') + pcAmount.toFixed(2).replace(".", ",");
					var row = _.padEnd(rowDescription, printerColumns - rowAmount.length, " ") + rowAmount;

					addLine(row);
				}
			});
		}

		var totDescription = 'TOT EURO';
		var totAmount = fiscalUtils.decimalToString(sale.final_amount);
		var totRow = _.padEnd(totDescription, printerColumns - totAmount.length, " ") + totAmount;

		addLine(totRow);

		/*
			No data about:
			- payments
			- customerDetail
		*/

		// Add Tail (tail for this sale + general tail)
		var tailRows = [];

		if (_.isString(options.tail)) {
			tailRows.push('');

			_.forEach(stringToLines(options.tail, printerColumns), function(line) {
				tailRows.push(line);
			});
		}

		if (_.isString(scope.options.tail)) {
			tailRows.push('');

			_.forEach(stringToLines(scope.options.tail, printerColumns), function(line) {
				tailRows.push(line);
			});

			tailRows.push('');
		}

		_.forEach(tailRows, function (row) {
			addLine(row);
		});

        if(!savePaper) {
            addLine("");
            addLine(_.pad("RITIRARE LO SCONTRINO", printerColumns, ' '));
            addLine(_.pad("FISCALE ALLA CASSA", printerColumns, ' '));
            addLine("");
        }

        commands.push('a');

		// Open cash drawer
		var openCashdrawer = (options.can_open_cash_drawer === false) ? false : true;

		if (openCashdrawer) {
			commands.push('J');
        }

        return commands;
	}

    /**
     * saleToCourtesyReceiptCommands
     */
    function saleToCourtesyReceiptCommands(sale) {
        var lines = [];

        lines.push("SCONTRINO DI CORTESIA");
        lines.push("");
        lines.push(sale.name);
        lines.push("");

        // Items
        _.forEach(sale.sale_items, function(si) {
            lines.push(si.quantity + "x " + cleanUpSpecialChars(si.name || si.department_name));
        });

        return linesToCommands(lines);
    }

    /**
     *  configurationToCommands
     *  @param like autoConfigurePrinter parameters
     */
    function configurationToCommands(printerSocket, options) {
        return fiscalUtils.getPrinterConfigurationResources().then(function(resources) {
            var commands = ['K'];

            _.forIn(options, function(option, o) {
                if (!_.isUndefined(option)) {
                    // Headers (maxHeaderLines x 48 chars)
                    var matches = o.match(/header\d+$/);

                    if (matches) {
                        var lineMatch = _.head(matches).match(/\d+$/);
                        var lineNum = _.toInteger(lineMatch[0]);

                        if (lineNum <= 9) {
                            var lineText = _.truncate(cleanUpSpecialChars(option), { length: scope.printer.columns || defaultPrinterColumns, omission: '' });
                            commands.push(['"', lineText, '"', lineNum, lineNum === 1 ? '1' : '0', 'D5']);
                        }
                    }
                }
            });

            return commands;
        });
    }

    var lastReceiptInfo = function(printerSocket) {
        var d = $q.defer();
        var result = {};

        sendCommands(printerSocket, ['2X']).then(function(responses) {
            var printerStatus = parseStatusResponse(_.head(responses));

            _.assign(result, {
                printer_serial: printerSocket.printerSerial,
                sequential_number: (printerStatus.daily_closing_number * 10000) + _.toInteger(printerStatus.receipt_number),
                date: printerStatus.date_time.toISOString()
            });

            getDgfeData(printerSocket, 'READ_RECEIPT', { receiptNumber: _.padStart(printerStatus.receipt_number, 4, '0'), date: printerStatus.date_time.format('DDMMYY') }).then(function(lines) {
                _.assign(result, {
                    document_content: lines.join('\n')
                });
            }).finally(function() {
                d.resolve(result);
            });
        }, d.reject);

        return d.promise;
    };

    var getDgfeData = function (printerSocket, mode, options) {
        var d = $q.defer();
        var dgfeCommand;

        switch(mode) {
            case 'READ_RECEIPT':
                dgfeCommand = ['"', options.date, options.receiptNumber, options.receiptNumber, '"', '10w'];
            break;
            case 'READ_RANGE':
                dgfeCommand = ['"', options.dateFrom, options.dateTo, '"', '9w'];
            break;
            case 'PRINT_RANGE':
                dgfeCommand = ['"', options.dateFrom, options.dateTo, '"', '5w'];
            break;
            default:
            break;
        }

        if(dgfeCommand) {
            sendCommands(printerSocket, [dgfeCommand], { waitIdle: 2000 }).then(function(responses) {

                if(mode === 'PRINT_RANGE') {
                    d.resolve(responses);
                } else {
                    var dgfeData = responses.shift();
                    var dgfeRows = _(dgfeData).replace(/\r\n/g, '').split('H' + _.last(dgfeCommand));

                    d.resolve(dgfeRows);
                }
            }, d.reject);
        } else {
            d.reject('INVALID_MODE');
        }

        return d.promise;
    };

    function getQueueCouponNonFiscal(sale) {
        return _.map(fiscalUtils.getQueueCouponRows(sale, scope.printer.columns || defaultPrinterColumns, { doubleWidth: true }), function(row) {
            return lineToCommand(row.text, scope.printer.columns || defaultPrinterColumns);
		});
	}

    /**
     *  Public methods
     */
    return {
        /**
         * @desc setup DTR printer
         *
         * @param printer, the tilby fiscal printer (must be a DTR obviously)
         * @param options.print_notes: boolean (default true), if true prints the non-fiscal lines above each sale_item (for ex. variations, or combinations)
         * @param options.print_name: boolean (default true), if true prints the sale.name on top
         * @param options.print_barcode_id: boolean (default true), if true, prints a barcode on bottom that contains the sale.id
         * @param options.ticket_change: boolean (default true), if true enables ticket change
         * @param options.print_customer_detail: boolean (default true), if true print TAX_CODE, FIRST_NAME, LAST_NAME, COMPANY_NAME, VAT_CODE, FIDELITY
         * @param options.print_seller: boolean (default true), if true print seller
         * @param options.seller_prefix: string, customize seller phrase
         * @param options.tail: string, general tail for the receipts
         *
         * @param printer.id: integer, the printer id
         * @param printer.name: string, the printer name
         * @param printer.driver: string, must be 'dtr in this case'
         * @param printer.connection_type: string, can be 'ts' (for TCP socket mode)
         * @param printer.ip_address: string, printer IP
         */
        setup: function(printer, options) {
            if (!printer) {
                throw "Printer is undefined";
            } else if (printer.driver !== 'dtr') {
                throw "Wrong driver";
            }  else if (!printer.connection_type) {
                throw "Missing connection_type";
            }  else if (!printer.ip_address) {
                throw "Missing ip_address";
            }

            _.assign(scope, {
                printer: printer,
                options: options
            });
        },

        getPrinterStatus: function() {
            var d = $q.defer();
            var result = {};

			isAvailable(scope.printer).then(function(printerSocket) {
                var commands = [
                    '2X', //Main Status
                    '2?', //RT Status
                    '1?', //DGFE Status
                ];

                sendCommands(printerSocket, commands).then(function(responses) {
                    var fpStatus = _.fill(Array(5), '0');

                    var mainStatus = parseStatusResponse(responses[0]);
                    var rtStatus = responses[1].split('H');
                    var dgfeStatus = responses[2].split('H');

                    //Paper Status (0: OK, 5: End)
                    fpStatus[0] = mainStatus.paper_end ? '5' : '0';

                    //DGFE Status
                    if(dgfeStatus[1] === '1') {
                        fpStatus[1] = '5';
                    } else if(dgfeStatus[0] === '1') {
                        fpStatus[1] = '1';
                    }

                    //RT Status
                    if(rtStatus[2] === '0') {
                        _.assign(result, { rtMainStatus: '01', rtSubStatus: '05' });
                    } else if(rtStatus[3] === '0') {
                        _.assign(result, { rtMainStatus: '01', rtSubStatus: '06' });
                    } else if(rtStatus[4] === '0') {
                        _.assign(result, { rtMainStatus: '01', rtSubStatus: '07' });
                    } else {
                        _.assign(result, { rtMainStatus: '02', rtSubStatus: '08' });
                    }

                    _.assign(result, {
                        fpStatus: fpStatus.join(''),
                        cpuRel: printerSocket.printerFirmware,
                        rtType: 'M',
                        printer_serial: printerSocket.printerSerial
                    });

                    closeSocket(printerSocket, function() { d.resolve(result); });
                }, function(error) {
                    closeSocket(printerSocket, function() { d.reject(error); });
                });
			}, d.reject);

            return d.promise;
		},

        /**
         *  @desc automagically configure DTR
         *
         *  ( /printers )
         *  @param printer, the printer you want to configure
         *  @param printer.invoice_prefix (from printer)
         *  @param printer.printer_number (from printer)
         *
         *  ( /shop_preferences )
         *
         *  @param options.header1 (max 48 chars - receipt + invoice)
         *  @param options.header2 (max 48 chars - receipt + invoice))
         *  @param options.header3 (max 48 chars - receipt + invoice))
         *  @param options.header4 (max 48 chars - receipt + invoice))
         *  @param options.header5 (max 48 chars - receipt + invoice))
         *  @param options.header6 (max 48 chars - receipt + invoice))
         *
         *  @param options.header7 (max 48 chars - invoice only)
         *  @param options.header8 (max 48 chars - invoice only)
         *  @param options.header9 (max 48 chars - invoice only)
         *  @param options.header10 (max 48 chars - invoice only)
         *  @param options.header11 (max 48 chars - invoice only)
         *  @param options.header12 (max 48 chars - invoice only)
         *  @param options.header13 (max 48 chars - invoice only)
         *
         *  @param options.invoice_footer1 (max 48 chars)
         *  @param options.invoice_footer2 (max 48 chars)
         *  @param options.invoice_footer3 (max 48 chars)
         *  @param options.invoice_footer4 (max 48 chars)
         *  @param options.invoice_footer5 (max 48 chars)
         *  @param options.invoice_footer6 (max 48 chars)
         *
         *  @param options.display_message
         *
         */
        autoConfigurePrinter: function(printer, options) {
            var d = $q.defer();

            // Configuring via TCP SOCKET
            isAvailable(printer).then(function(printerSocket) {
                console.log("Autoconfiguring " + scope.printer.name + " " + scope.printer.ip_address + " via tcp...");

                configurationToCommands(printerSocket, options).then(function(commands) {
                    sendCommands(printerSocket, commands).then(function(responses) {
                        closeSocket(printerSocket, function() { d.resolve('OK'); });
                    }, function(error) {
                        closeSocket(printerSocket, function() { d.reject(error); });
                    });
                }, function(error){
                    closeSocket(printerSocket, function() { d.reject(error); });
                });
            });

            return d.promise;
        },

        /**
         * @desc print a commercial document (also known as 'documento commerciale', for italians)
         *
         * @param sale - the tilby sale you want to print
         * @param options.can_open_cash_drawer - true if user has permission, false otherwise
         * @param options.tail: string, print this string at the end of receipt
         * @param options.print_details: if false, print only department totals and hide single items and discount details
         *
         * @return successFunction with printed sale or errorFunction with errors
         */
        printFiscalReceipt: function(sale, options, successFunction, errorFunction) {
            isAvailable(scope.printer).then(function(printerSocket) {
                const isRefundVoid = fiscalUtils.isRefundVoidSale(sale);

                var printAdditionalDocuments = function() {
                    async.waterfall([function(callback) {
                        if(!isRefundVoid && checkQueueType('non_fiscal')) {
                            sendCommands(printerSocket, getQueueCouponNonFiscal(sale)).then(function() {
                                callback();
                            }, function() {
                                callback();
                            });
                        } else {
                            callback();
                        }
                    }], function(err, results) {
                        closeSocket(printerSocket);
                    });
                };

                // Build protocol commands
                var commands = saleToReceiptCommands(sale, printerSocket, options);

                if (!_.isArray(commands)) {
                    closeSocket(printerSocket, function() { errorFunction(commands); });
                } else {
                    // Open cash drawer
                    var openCashdrawer = (options.can_open_cash_drawer === false) ? false :true;

                    if (openCashdrawer) {
                        commands.push('a');
                    }

                    var documentType = 'commercial_doc';

                    if (isRefundVoid) {
                        //We only need to check a single sale item, since the cause check has been done in the saleToReceiptCommands function
                        var saleItemToCheck = _.head(sale.sale_items);

                        switch(saleItemToCheck.refund_cause_id) {
                            case 6:
                                documentType = "void_doc";
                                break;
                            default:
                                documentType = "refund_doc";
                        }
                    }
                    errorsLogger.debug("Print fiscal receipt on a " + scope.printer.name + " " + scope.printer.ip_address + " via tcp...");

                    // Print Fiscal Receipt
                    sendCommands(printerSocket, commands).then(function(message) {
                        lastReceiptInfo(printerSocket).then(function(documentData) {
                            _.assign(documentData, {
                                document_type: documentType
                            });

                            successFunction([documentData]);
                            printAdditionalDocuments();
                        }, function(error) {
                            successFunction([]);
                            printAdditionalDocuments();
                        });
                    }, function(error) {
                        closeSocket(printerSocket, function() { errorFunction(error); });
                    });
                }
            }, errorFunction);
        },

        /**
         * @desc print a courtesy receipt (also known as 'scontrino di cortesia', for italians)
         *
         * @param sale - the tilby sale you want to print
         *
         * @return successFunction with printed sale or errorFunction with errors
         */
        printCourtesyReceipt: function(sale, options, successFunction, errorFunction) {
            isAvailable(scope.printer).then(function(printerSocket) {
                // Build protocol commands
                var commands = saleToCourtesyReceiptCommands(sale);

                errorsLogger.debug("Print courtesy receipt on a " + scope.printer.name + " " + scope.printer.ip_address + " via tcp...");

                sendCommands(printerSocket, commands).then(function(responses) {
                    closeSocket(printerSocket, function() { successFunction('OK'); });
                }, function(error) {
                    closeSocket(printerSocket, function() { errorFunction(error); });
                });
            }, errorFunction);
        },

        /**
         * @desc print a non fiscal receipt (also known as 'preconto', for italians)
         *
         * @param sale - the tilby sale you want to print
         *
         * @return successFunction with printed sale or errorFunction with errors
         */
        printNonFiscal: function(sale, options, successFunction, errorFunction) {
            isAvailable(scope.printer).then(function(printerSocket) {
                // Build protocol commands
                var commands = saleToNonFiscalCommands(sale, options);
                errorsLogger.debug("Print non fiscal receipt on a " + scope.printer.name + " " + scope.printer.ip_address + " via tcp...");

                sendCommands(printerSocket, commands).then(function(responses) {
                    closeSocket(printerSocket, function() { successFunction('OK'); });
                }, function(error) {
                    closeSocket(printerSocket, function() { errorFunction(error); });
                });
            }, errorFunction);
        },

        /**
         * @desc open cash drawer
         *
         * @return successFunction if drawer is correctly opened or errorFunction with errors
         */
        openCashDrawer: function(successFunction, errorFunction) {
            var commands = ['a'];

            isAvailable(scope.printer).then(function(printerSocket) {
                errorsLogger.debug("Opening cash drawer on a " + scope.printer.name + " " + scope.printer.ip_address + " via tcp...");

                sendCommands(printerSocket, commands).then(function(responses) {
                    closeSocket(printerSocket, function() { successFunction('OK'); });
                }, function(error) {
                    closeSocket(printerSocket, function() { errorFunction(error); });
                });
            }, errorFunction);
        },

        /**
		 * @desc readDgfeBetween
		 *
		 * @param mode can be 'print' or 'read' ('read' mode works only in webservice mode)
		 * @param from: start date, must be string in format DDMMAA
		 * @param to: end date, must be string in format DDMMAA
		 *
		 * @return promise
		 */
		readDgfeBetween: function(mode, from, to) {
            var d = $q.defer();

            isAvailable(scope.printer).then(function(printerSocket) {
                getDgfeData(printerSocket, mode === 'print' ? 'PRINT_RANGE' : 'READ_RANGE', { dateFrom: from, dateTo: to }).then(function(result) {
                    closeSocket(printerSocket, function() { d.resolve(result.join('\n')); });
                }, function(error) {
                    closeSocket(printerSocket, function() { d.reject(error); });
                });
            }, d.reject);

            return d.promise;
		},

        /**
		 * @desc printFiscalMemoryBeetween
		 *
		 * @param corrispettivi: boolean, if true prints 'corrispettivi'
		 * @param from: start date, must be string in format DDMMAA
		 * @param to: end date, must be string in format DDMMAA
		 *
		 * @return promise
		 */
		printFiscalMemoryBetween: function(corrispettivi, from, to) {
            var d = $q.defer();
            var commands = [['"', from, to, '"', '2w']];
            //TOOD: save closing info and vat

            isAvailable(scope.printer).then(function(printerSocket) {
                errorsLogger.debug("Fiscal memory read on a " + scope.printer.name + " " + scope.printer.ip_address + " via tcp...");

                sendCommands(printerSocket, commands).then(function(responses) {
                    closeSocket(printerSocket, function() { d.resolve({}); });
                }, function(error) {
                    closeSocket(printerSocket, function() { d.reject(error); });
                });
            }, d.reject);

            return d.promise;
		},

        /**
         * @desc do a daily closing
         *
         * @return successFunction  (with the closing receipt in ws mode, or 'OK' in bt mode) if close is correctly done or errorFunction with errors
         */
        dailyClosing: function(successFunction, errorFunction) {
            var commands = ['8F', '2F', '1F'];
            //TOOD: save closing info and vat

            isAvailable(scope.printer).then(function(printerSocket) {
                errorsLogger.debug("Daily closing on a " + scope.printer.name + " " + scope.printer.ip_address + " via tcp...");
                var result = {};

                sendCommands(printerSocket, commands).then(function(responses) {
                    closeSocket(printerSocket, function() { successFunction(result); });
                }, function(error) {
                    closeSocket(printerSocket, function() { errorFunction(error); });
                });
            }, errorFunction);
        },

        /**
         * @desc do a daily read
         *
         * @return promise
         */
        dailyRead: function() {
            var d = $q.defer();
            var commands = ['1f'];

            isAvailable(scope.printer).then(function(printerSocket) {
                errorsLogger.debug("Daily read on a " + scope.printer.name + " " + scope.printer.ip_address + " via tcp...");

                sendCommands(printerSocket, commands).then(function(responses) {
                    closeSocket(printerSocket, function() { d.resolve({}); });
                }, function(error) {
                    closeSocket(printerSocket, function() { d.reject(error); });
                });
            }, d.reject);

            return d.promise;
        },

        /**
         * @description printFreeNonFiscal
         */
        printFreeNonFiscal: (lines, options) => new Promise((resolve, reject) => {
            isAvailable(scope.printer).then(function(printerSocket) {
                let freeCommands = linesToCommands(lines);

                errorsLogger.debug(`Print free non-fiscal on a ${scope.printer.name} ${scope.printer.ip_address} via tcp...`);

                sendCommands(printerSocket, freeCommands).then(function(responses) {
                    closeSocket(printerSocket, function() { resolve(); });
                }, function(error) {
                    closeSocket(printerSocket, function() { reject(error); });
                });
            }, reject);
        }),

        /**
         * isReachable
         */
        isReachable: function(successFunction, errorFunction) {
            isAvailable(scope.printer).then(function(printerSocket) {
                closeSocket(printerSocket, function() { successFunction(); });
            }, errorFunction);
        }
    };
}]);

import {
    CustomerFe,
    GridPanel,
    ItemData,
    TableData
} from "src/app/shared/model/model";

import {Subject} from "rxjs";
import {Sales} from "tilby-models";
import {Feature} from "../../../models";
import { GeneralInputButton } from "@tilby/tilby-ui-lib/models";

type FeatureKeys = {[prop in Feature]?:string[]}

export interface SearchBarXQuery extends FeatureKeys{
    customers:Array<keyof CustomerFe>;
    history_sales:Array<keyof Sales|'sale_customer.first_name'|'sale_customer.last_name'|'sale_customer.company_name'|'sale_documents.printer_name'|'sale_items.name'>;
}

export type ModuleName = 'customers' | 'suppliers' | 'dashboard';
export type Themes = `${ModuleName}Theme`;
export type ModuleEvents = GeneralEvents|RoomsEvents|TableEvents|CashregisterEvents|BookingEvents|PromotionsEvents;
export type ModuleSubjects = {
    [prop in ModuleName]: Subject<Partial<ModuleEvents>>;
}
export interface RoomsEvents {
    new_room: true;
    edit_room:true;
    delete_room:true;
    edit_room_tables:true;
    zoom_in:true;
    zoom_out:true;
}
export interface PromotionsEvents {
    open_template_editor: true;
}
export interface TableEvents {
    new_table:true;
    edit_table:true;
    move_table:true;
    duplicate_table:true;
    delete_table:true;
    summary_of_occupied_tables:true;
}

export interface CashregisterEvents {
    add_cash_movement:true;
    cashregister_open_pms_lean:true;
    cashregister_open_stock:true;
    cashregister_open_tray:true;
    cashregister_daily_closing:true;
    cashregister_change_pricelist:true;
    cashregister_settings:true;
    cashregister_select_pricelist: true;
}

export interface BookingEvents {
    bookings_settings:true;
    add_edit_booking:true;
    booking_download:true;
    view_week:true;
    view_list:true;
    view_resource:true;
}

export interface ToolbarButtons {
    barButtons:GeneralInputButton[];
    panelButtons:GeneralInputButton[];
}
export class Module implements ModuleSubjects {
    customers = new Subject<Partial<ModuleEvents>>();
    dashboard = new Subject<Partial<ModuleEvents>>();
    suppliers = new Subject<Partial<ModuleEvents>>();
}

export interface GeneralInput {
    title: string
    theme: Themes;
    buttons: GeneralInputButton[];
    buttonsbuttonsPanel: GeneralInputButton[];
}

export interface GridInput {
    title: string
    theme: Themes;
    tableData: TableData;
}

export interface GridEventsOutput {
    search: string;
    searchBarcode: string;
    edit: ItemData[]|boolean;
    delete: boolean;
    export: boolean;
    save: boolean;
    openToolPanel: GridPanel;
    advFiltersSwitch: boolean;
    movColumnsSwitch: boolean;
    type: string;
}

export type GeneralEvents =
    GridEvents &
    ToolbarEvents;

export interface ToolbarEvents {
    btnCss?: ButtonCss;
}

export interface ButtonCss {
    type: string;
    button: string;
    cssClass: string;
}


export type GridEvents =
    GridEventsOutput &
    GridInput;

import _ from 'lodash';

import {
    Component,
    inject,
    Renderer2,
    OnInit,
} from '@angular/core';

import { ChannelsFields, ChannelsFormGroup } from './settings-channels-form';
import { ChannelsFormService } from './services';
import { errorsLogger } from "app/ajs-upgraded-providers";

import {
    EntityManagerService,
    ModuleEvents,
    ToolbarEventsService
} from 'src/app/core';

import {
    AlertDialogService,
    ConfirmDialogService,
} from 'src/app/dialogs';

import { TranslateService } from "@ngx-translate/core";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Channels, PaymentMethods } from 'tilby-models';
import { CustomFormGroup } from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';

@Component({
  selector: 'app-settings-channels',
  templateUrl: './settings-channels.component.html',
  styleUrls: ['./settings-channels.component.scss'],
  providers: [OnDestroyService]
})
export class SettingsChannelsComponent implements OnInit {
    private readonly _snackBar = inject(MatSnackBar);
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly channelFormService = inject(ChannelsFormService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly errorsLoggerService = inject(errorsLogger);
    private readonly onDestroyService = inject(OnDestroyService);
    private readonly renderer = inject(Renderer2);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly translate = inject(TranslateService);

    public channelForm?: CustomFormGroup<ChannelsFormGroup>;
    public channelsList: Array<ChannelsFields> = [];
    public filterList = '';
    public filterToggle = false;
    public firstEntry = false;
    public isNewEntryInProgress = false;
    public isProcessing = false;
    public paymentMethods: Array<PaymentMethods> = [];
    public pristineChannel: ChannelsFields = { id: '', name: '' };
    public selected = false;
    public selectedChannel: ChannelsFields = { id: '', name: '' };

    ngOnInit(): void {
        this.createToolbarButtons();
        this.setModuleTitle();
        this.toolbarEventsService.events.pipe(this.onDestroyService.takeUntilDestroy).subscribe(e => this.callbackToToolbarClick(e));
        this.getPaymentMethods().then(paymentMethods => this.paymentMethods = paymentMethods);
        this.getChannels().then(channels => this.channelsList = channels);
    }

    private parseChannelData(ch: any) {
        ch._imgUrl = ch.image_url || "assets/images/channels/" + ch.id + ".png";
        return ch;
    }

    private async getChannels(): Promise<Array<ChannelsFields>> {
        let channels: Array<Channels> | undefined = undefined;
        try {
            channels = await this.entityManagerService.channels.fetchCollectionOffline();
            if (channels && Array.isArray(channels)) {
                // use filter to remove _.reject(channels, { id: 'pos' })
                channels = channels.filter((channel) => channel.id !== 'pos').map((channel) => this.parseChannelData(channel));
            }
        } catch (err) {
            this.errorsLoggerService.err(err);
        }

        return channels && Array.isArray(channels) ? channels : [];
    };

    private async getPaymentMethods(): Promise<Array<PaymentMethods>> {
        let paymentMethodsResult: Array<PaymentMethods> | undefined = undefined;
        try {
            paymentMethodsResult = await this.entityManagerService.paymentMethods.fetchCollectionOffline();
            if (paymentMethodsResult && Array.isArray(paymentMethodsResult)) {
                const paymentMethodsResultShort = _.reject(paymentMethodsResult, function(payment) {
                    return _.includes([11, 12, 13, 14, 15, 16, 18, 19, 20], payment.payment_method_type_id);
                });

                paymentMethodsResult = _.sortBy(paymentMethodsResultShort, ['name']);
            }
        } catch (err) {
            this.errorsLoggerService.err(err);
        }

        return paymentMethodsResult && Array.isArray(paymentMethodsResult) ? paymentMethodsResult : [];
    }

    createToolbarButtons() {
        this.toolbarEventsService.buttons$.next({
            barButtons: [],
            panelButtons: []
        });
    }

    callbackToToolbarClick(event: Partial<ModuleEvents> & { id?: number }) {}

    setModuleTitle() {
        this.toolbarEventsService.moduleTitle.next('CHANNELS');
    }

    unsaveChannel = async (channel: ChannelsFields) => {
        const confirm = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'SETTINGS.CHANNELS_MANAGEMENT.EDITS_NOT_SAVED',
                confirmLabel: 'SETTINGS.CHANNELS_MANAGEMENT.CONFIRM_LABEL',
                cancelLabel: 'SETTINGS.CHANNELS_MANAGEMENT.CANCEL_LABEL'
            }
        });

        if (confirm) {
            this.channelForm?.markAsPristine();
            this.selectChannel(channel, false);
        }
    };

    selectChannel = (channel: ChannelsFields, isFormDirty = false) => {
        if(isFormDirty) {
            this.unsaveChannel(channel);
        } else {
            const _channel = structuredClone(channel);
            this.pristineChannel = _channel;
            this.selectedChannel = _channel;
            if(!this.selected) {
                this.channelForm = this.channelFormService.createForm(_channel, this.paymentMethods);
            } else {
                this.channelFormService.updateForm(_channel);
            }
        }
        this.selected = true;
    };

    unsaveWhenAddNew = async () => {
        const confirm = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'SETTINGS.CHANNELS_MANAGEMENT.EDITS_NOT_SAVED',
                confirmLabel: 'SETTINGS.CHANNELS_MANAGEMENT.CONFIRM_LABEL',
                cancelLabel: 'SETTINGS.CHANNELS_MANAGEMENT.CANCEL_LABEL'
            }
        });

        if (confirm) {
            this.channelForm?.markAsPristine();
            this.addNewChannelProceed();
        }
    }

    addNewChannels = (isFormDirty = false) => {
        if(isFormDirty) {
            this.unsaveWhenAddNew();
        }
        if(!this.channelForm?.dirty && !this.isNewEntryInProgress) {
            this.addNewChannelProceed();
        }
    };

    private addNewChannelProceed = () => {
        const newChannel: ChannelsFields = {
            isNew: true,
            name: this.translate.instant('SETTINGS.CHANNELS_MANAGEMENT.NEW_CHANNEL'),
            id: '',
        };
        this.filterList = '';
        this.filterToggle = false;
        const newLen = this.channelsList.push(newChannel);
        this.channelsList = [...this.channelsList];
        this.selectChannel(this.channelsList[newLen - 1], false);
        this.isNewEntryInProgress = true;
        this.channelForm?.markAsDirty();
    }

    isSelected = (channel: ChannelsFields) => channel.id === this.selectedChannel.id;

    lockProcessing = () => {
        this.isProcessing = true;
    };

    unlockProcessing = () => {
        this.isProcessing = false;
        this.isNewEntryInProgress = false;
    };

    save = () => {
        this.lockProcessing();
        const _channelForm = this.channelForm?.value.generalForm;
        _channelForm.enabled = _channelForm.enabled ? true : false;
        _channelForm.allow_automatic_print = _channelForm.allow_automatic_print ? true : false;
        _channelForm.force_paid = _channelForm.force_paid ? true : false;
        _channelForm.default_pricelist = _channelForm.default_pricelist && _channelForm.default_pricelist !== -1 ? _channelForm.default_pricelist : null;
        _channelForm.add_to_mail_receipts = _channelForm.add_to_mail_receipts ? true : false;
        _channelForm.add_to_new_items = _channelForm.add_to_new_items ? true : false;
        const cloneSelectedChannel = structuredClone(this.selectedChannel);
        const channelToSave: ChannelsFields = { ...cloneSelectedChannel, ..._channelForm };

        const saveFunction = cloneSelectedChannel.isNew ? 'postOneOnline' : 'putOneOnline';
        const successSave = (result: ChannelsFields) => {
            let channelToSelect: ChannelsFields | undefined = undefined;
            if(cloneSelectedChannel.isNew) {
                const lastIndex = this.channelsList.length - 1;
                channelToSelect = this.channelsList[lastIndex];
            } else {
                channelToSelect = this.channelsList.find((channel: ChannelsFields) => channel.id === result.id);
            }
            if(channelToSelect) {
                result.default_payment_method_id = result.default_payment_method_id ? result.default_payment_method_id : -1;
                Object.assign(channelToSelect, result);
                delete channelToSelect.isNew;
                this.selectChannel(channelToSelect, false);
            }
            this.channelForm?.markAsPristine();
            this.channelFormService.disableID();
            this.isProcessing = false;
            this.isNewEntryInProgress = false;
            const labelSuccess = this.translate.instant('SETTINGS.CHANNELS_MANAGEMENT.SAVE_SUCCESSFUL');
            this._snackBar.open(labelSuccess, undefined, { duration: 10000, horizontalPosition: 'left', verticalPosition: 'bottom' });
        };
        const errorSave = () => {
            this.alertDialogService.openDialog({
                data: {
                    messageLabel: 'SETTINGS.CHANNELS_MANAGEMENT.SAVE_FAILED',
                    confirmLabel: 'SETTINGS.CHANNELS_MANAGEMENT.ALERT_CONFIRM_LABEL'
                }
            });
            this.isProcessing = false;
        };

        this.entityManagerService.channels[saveFunction](channelToSave)
        .then(successSave, errorSave)
        .catch((err: any) => {
            this.errorsLoggerService.err(err);
            this.isProcessing = false;
        });
    };

    delete = async () => {
        const confirm = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'SETTINGS.CHANNELS_MANAGEMENT.DELETE_CONFIRM',
                confirmLabel: 'SETTINGS.CHANNELS_MANAGEMENT.CONFIRM_LABEL',
                cancelLabel: 'SETTINGS.CHANNELS_MANAGEMENT.CANCEL_LABEL'
            }
        });

        if (confirm) {
            this.lockProcessing();
            const _selectedChannel = this.selectedChannel;
            try {
                if(_selectedChannel.id) {
                    await this.entityManagerService.channels.deleteOneOnline(_selectedChannel.id);
                    const indexOfchannelToDelete = this.channelsList.findIndex(channel => channel.id === _selectedChannel.id);

                    if (indexOfchannelToDelete !== -1) {
                        this.channelsList.splice(indexOfchannelToDelete, 1);
                    }
                } else {
                    this.channelsList.pop();
                }
                this.channelsList = [...this.channelsList];
                this.channelForm?.markAsPristine();
                this.isNewEntryInProgress = false;
                this.filterList = '';
                this.selected = false;
                if(this.firstEntry) {
                    this.firstEntry = false;
                }
            } catch (err) {
                this.errorsLoggerService.err(err);

                this.alertDialogService.openDialog({
                    data: {
                        messageLabel: 'SETTINGS.CHANNELS_MANAGEMENT.DELETE_FAILED',
                        confirmLabel: 'SETTINGS.CHANNELS_MANAGEMENT.ALERT_CONFIRM_LABEL'
                    }
                });
            } finally {
                this.unlockProcessing();
            }
        }
    };

    filterChannels = () => {
        this.filterToggle = !this.filterToggle;
        if(this.filterToggle) {
            setTimeout(() => this.renderer.selectRootElement("input[name=search]").focus(), 0);
        } else {
            this.filterList = '';
        }
    };

}

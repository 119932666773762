import { Component , signal, ChangeDetectorRef, inject, Input, ViewChild, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullCalendarComponent, FullCalendarModule } from '@fullcalendar/angular';
import { EventClickArg, EventApi } from '@fullcalendar/core';
import { groupBy, mobileCheck } from "@tilby/tilby-ui-lib/utilities";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BookingShifts, Bookings, Rooms } from 'tilby-models';
import { BookingsDataInput, BookingsStateService } from '../../services/bookings.state.service';
import { TilbyDatePipe } from '@tilby/tilby-ui-lib/pipes/tilby-date';
import { ConfigurationManagerService, EntityManagerService, StorageManagerService } from 'src/app/core';
import { Calendar } from '@fullcalendar/core';
import fcTimeGrid from '@fullcalendar/timegrid';
import fcList from '@fullcalendar/list';
import fcInteraction from '@fullcalendar/interaction';
import fcResourceTimeline from '@fullcalendar/resource-timeline';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import moment from 'moment-timezone';
import { $state } from 'app/ajs-upgraded-providers';
import { AddEditNewBookingDialogService } from 'src/app/dialogs/bookings/add-edit-new-booking-dialog/add-edit-new-booking-dialog.component';
import { Subject } from 'rxjs';

const { licenseKeys } = require('app/tilby.properties.json');

@Component({
    selector: 'app-booking-week',
    templateUrl: './booking-week.component.html',
    styleUrls: ['./booking-week.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FullCalendarModule,
        TranslateModule
    ],
})
export class BookingWeekComponent {
    @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
    bookings: Bookings[] = [];
    @Input() rooms: Rooms[] = [];

    bookingsDataInput!: BookingsDataInput;
    isMobile = mobileCheck();

    private readonly changeDetector = inject(ChangeDetectorRef);
    private readonly translateService = inject(TranslateService);
    private readonly bookingsStateService = inject(BookingsStateService);
    private readonly checkManager = inject(ConfigurationManagerService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly tilbyDatePipe = inject(TilbyDatePipe);
    private readonly state = inject($state);
    private readonly addEditNewBookingDialogService = inject(AddEditNewBookingDialogService);

    calendarVisible = signal(true);
    calendarOptions = signal<any>({});
    currentEvents = signal<EventApi[]>([]);

    daysOfWeekMap = { 'Su': 0, 'Mo': 1, 'Tu': 2, 'We': 3, 'Th': 4, 'Fr': 5, 'Sa': 6 };
    bookingsByShift: Record<number, any> = {};
    bookingShifts: BookingShifts[] = [];
    shiftTotals: Record<number, any> = {};
    slotDuration = this.checkManager.getPreference('bookings.slot_duration' as any) || 30;

    calendar!: Calendar;
    calendarApi!: Calendar;
    today!: Date;

    private onDestroy$ = new Subject<void>();

    constructor(){
        effect(() => {
            if (this.bookingsStateService.selectBookingsData()) {
                const updatedDate = this.bookingsStateService.selectBookingsData().date;

                if(this.bookingsStateService.isBookingOnline(updatedDate) === false) {
                    const range = this.bookingsStateService.weekDaysRange;
                    this.bookingsStateService.getBookingsToday(updatedDate, 0, range);
                }

                this.bookingsDataInput = this.bookingsStateService.selectBookingsData();
                let calendarApi = this.calendarComponent.getApi();
                if(calendarApi) calendarApi.gotoDate(this.bookingsDataInput.date);
            }
        }, { allowSignalWrites: true });

        effect(() => {
            if(this.bookingsStateService.bookingData()) {
                this.calendarApi.removeAllEvents();
                this.bookings = this.bookingsStateService.bookingData();
                this.calendarComponent.getApi().refetchEvents();
            }
        }, { allowSignalWrites: true });

        effect(() => {
            if (this.bookingsStateService.isRefreshData()) {
                this.bookings = this.bookingsStateService.bookingData();
                const calendarApi = this.calendarComponent.getApi();
                if (calendarApi) {
                    calendarApi.refetchEvents();
                }
                this.bookingsStateService.isRefreshData.set(false);
            }
        }, { allowSignalWrites: true });
    }

    ngOnInit() {
        const todayString = this.tilbyDatePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
        this.today = TilbyDatePipe.date({outputFormat: 'date', date: todayString || ''});

        this.calendarOptions.set({
            plugins: [fcTimeGrid, fcInteraction, fcResourceTimeline, fcList, momentTimezonePlugin],
            schedulerLicenseKey: licenseKeys.fullCalendar,
            initialView: 'timeGridWeek',
            events: this.fetchEvents.bind(this),
            weekends: true,
            editable: true,
            selectable: false,
            selectMirror: true,
            dayMaxEvents: true,
            eventClick: this.handleEventClick.bind(this),
            eventsSet: this.handleEvents.bind(this),
            eventDidMount: this.handleEventMount.bind(this),
            resources: this.fetchResources.bind(this),
            allDayText: this.translateService.instant('BOOKINGS.SHOWCASE.CALENDAR_ALL_DAY_TEXT'),
            nowIndicator: true,
            slotDuration: this.slotDuration * 60000,
            locale: moment.locale(),
            timeZone: this.configurationManagerService.getPreference('general.timezone'),
            headerToolbar: false,
            height: '100%',
            firstDay: 1,
            views: {
                resourceTimeline: {
                    selectable: true,
                    resourceGroupField: 'room',
                    resourceAreaHeaderContent: this.translateService.instant('BOOKINGS.SHOWCASE.RESOURCES'),
                    titleFormat: {
                        day: 'numeric',
                        weekday: 'long',
                        month: 'long',
                        year: 'numeric'
                    }
                }
            },
            allDayContent: this.translateService.instant('BOOKINGS.SHOWCASE.CALENDAR_ALL_DAY_TEXT'),
        });

        if(this.state.params.customer) this.addEditNewBookingDialogService.openDialog({data: {bookings: this.bookings, bookingShifts: this.bookingShifts, rooms: this.rooms, options: {customer: this.state.params.customer}}});
    }

    ngAfterViewInit() {
        StorageManagerService.storageUpdates$.subscribe(() => this.bookingsStateService.getBookings());

        this.calendarApi = this.calendarComponent.getApi();
    }

    async fetchEvents(fetchInfo: any, successCallback: any, failureCallback: any) {        
        let events = [];

        const bookingShifts = await this.entityManagerService.bookingShifts.fetchCollectionOffline();

        events = bookingShifts.map((shift) => {
            let daysOfWeek: number[] = [];

            shift.weekdays_period!.forEach((day) => {
                daysOfWeek.push(this.daysOfWeekMap[day]);
            });

            let shiftTotal = {
                seats: 0,
                tables: 0
            };

            let shiftRoomsBList: Record<string, boolean> = {};

            //@ts-ignore
            shift.room_restrictions.forEach((room) => {
                shiftRoomsBList[room.room_id] = true;
            });

            this.rooms.forEach((room) => {
                //@ts-ignore
                if(!shiftRoomsBList[room.id]) {
                    //@ts-ignore
                    shiftTotal.tables += room.tables?.length;
                    shiftTotal.seats += room.tables?.reduce((sum, table) => sum + (table.covers || 0), 0) || 0;
                }
            });

            if(shift.instore_seats_limit != null) {
                shiftTotal.seats = shift.instore_seats_limit;
            }

            //@ts-ignore
            this.shiftTotals[shift.id] = shiftTotal;

            return {
                id: shift.id,
                groupId: shift.id?.toString(),
                title: shift.name,
                startTime: shift.start_time,
                endTime: shift.end_time,
                startRecur: moment(shift.start_period),
                endRecur: moment(shift.end_period).add(1, 'day'),
                daysOfWeek: daysOfWeek
            };
        });

        successCallback(events);
    }

    fetchResources(fetchInfo: any, successCallback: any, failureCallback: any) {
        let resources = [];

        resources.push({
            id: 0,
            title: this.translateService.instant('BOOKINGS.SHOWCASE.NO_RESOURCE')
        });

        this.rooms.forEach((room) => {
            if(room.tables)
                room.tables.forEach((table) => {
                    if(!table.name.startsWith('ph_')) {
                        resources.push({
                            id: table.id,
                            room: room.name,
                            title: table.name
                        });
                    }
                });
        });

        successCallback(resources);
    };

    async handleEventMount(info: any) {
        this.bookingsByShift = groupBy(this.bookings, (booking) => booking.shift_id);

        let shiftId = +info.event.id
        let shiftBookings = this.bookingsByShift[shiftId];


        const shiftStartDate = this.tilbyDatePipe.transform(info.event.start, 'yyyy-MM-dd HH:mm:ss');
        let shiftStart = TilbyDatePipe.date({outputFormat: 'date', date: shiftStartDate || ''});

        const shiftEndDate = this.tilbyDatePipe.transform(info.event.end, 'yyyy-MM-dd HH:mm:ss');
        let shiftEnd = TilbyDatePipe.date({outputFormat: 'date', date: shiftEndDate || ''});

        let busyTables: any = {};
        let busySeats = 0;

        if(shiftBookings) {
            shiftBookings.forEach((booking: any) => {

                const bookingStart = this.tilbyDatePipe.transform(booking.booked_for, 'yyyy-MM-dd HH:mm:ss');
                let bookingStartDate = TilbyDatePipe.date({outputFormat: 'date', date: bookingStart || ''});

                if(this.checkDateBetween(bookingStartDate, shiftStart, shiftEnd)) {
                    booking.tables.forEach((table: any) => {
                        busyTables[table.table_id] = true;
                    });

                    busySeats += booking.people;
                }
            });
        }

        let totalSeats = this.shiftTotals[shiftId].seats;
        let totalTables = this.shiftTotals[shiftId].tables;

        let tablesRow = this.translateService.instant('BOOKINGS.SHOWCASE.TABLES') + (Object.entries(busyTables).length) + (totalTables ? (" | " + totalTables + " [" + (Math.round(Object.entries(busyTables).length/totalTables) * 100) + "%]") : '');
        let peoplesRow = this.translateService.instant('BOOKINGS.SHOWCASE.SEATS') + (busySeats) + (totalSeats ? (" | " + totalSeats + " [" + (Math.round((busySeats/totalSeats) * 100)) + "%]") : '');

        const container = info.el.querySelector('.fc-event-main');

        if (container) {
            const tableEl = document.createElement('div');
            tableEl.textContent = tablesRow;

            const peopleEl = document.createElement('div');
            peopleEl.textContent = peoplesRow;

            container.appendChild(tableEl);
            container.appendChild(peopleEl);
        }
    };

    checkDateBetween(date: Date, startDate: Date, endDate: Date): boolean {
        const dateValue = date.getTime();
        const startValue = startDate.getTime();
        const endValue = endDate.getTime();

        return dateValue >= startValue && dateValue < endValue;
    }

    handleEventClick(clickInfo: EventClickArg) {
        const dateClick = this.tilbyDatePipe.transform(clickInfo.event.start as Date, 'yyyy-MM-dd');
        const date = TilbyDatePipe.date({outputFormat: 'date', date: dateClick || ''});
        const dateUTC = this.bookingsStateService.convertToUTC(date);

        this.bookingsStateService.selectBookingsData.set({
            date: dateUTC ?? this.bookingsDataInput.date,
            type: 2
        });
    }

    handleEvents(events: EventApi[]) {
        this.currentEvents.set(events);
        this.changeDetector.detectChanges(); // workaround for pressionChangedAfterItHasBeenCheckedError
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}

import { Component, Injectable, inject, signal } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { lastValueFrom } from "rxjs";

import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogContentComponent,
    TilbyDialogToolbarComponent
} from "@tilby/tilby-ui-lib/components/tilby-dialog";

import {
    BookingShifts,
    Bookings,
    BookingsTables,
    Rooms
} from "tilby-models";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { MatListModule } from "@angular/material/list";
import { CommonModule } from "@angular/common";
import { ConfigurationManagerService, EntityManagerService } from "src/app/core";
import { isArray } from "angular";

import {
    $state,
    saleUtils,
    util
} from "app/ajs-upgraded-providers";

import { addSelectCustomerDialog } from 'app/ajs-upgraded-providers';
import { groupBy, mobileCheck, subscribeInComponent,  } from '@tilby/tilby-ui-lib/utilities';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { TilbyDatetimePickerComponent } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { MatDividerModule } from "@angular/material/divider";
import { BookingUtils } from "src/app/shared/booking-utils.service";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { GeneralInputButton } from "@tilby/tilby-ui-lib/models";
import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";
import {
    AlertDialogService,
    ConfirmDialogService
} from "src/app/dialogs";
import { BookingsStateService } from "src/app/features";
import { MatSnackBar } from "@angular/material/snack-bar";

const moment = require('moment-timezone');

export type BookingDialogData = { bookingShifts: BookingShifts[], bookings: Bookings[], rooms: Rooms[], options?: any};

@Component({
    selector: "app-edit-add-new-booking-dialog",
    standalone: true,
    imports: [
        CommonModule,
        TilbyDialogContentComponent,
        TilbyDialogToolbarComponent,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatIconModule,
        MatListModule,
        MatDatepickerModule,
        MatDividerModule,
        MatProgressBarModule,
        ReactiveFormsModule,
        FormsModule,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        CdkVirtualScrollViewport,
        TranslateModule,
        TilbyDatetimePickerComponent
    ],
    templateUrl: "./add-edit-new-booking-dialog.component.html",
    styleUrls: ["./add-edit-new-booking-dialog.component.scss"],
})
export class AddNewBookingDialogComponent {
    private readonly util = inject(util);
    private readonly state = inject($state);
    private readonly saleUtils = inject(saleUtils);
    private readonly _snackBar = inject(MatSnackBar);
    private readonly formBuilder = inject(FormBuilder);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly tilbyDatePipe = inject(TilbyDatePipe);
    private readonly translateService = inject(TranslateService);
    private readonly entityManager = inject(EntityManagerService);
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly bookingsStateService = inject(BookingsStateService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    protected readonly data: BookingDialogData = inject(MAT_DIALOG_DATA);
    private readonly addSelectCustomerDialog = inject(addSelectCustomerDialog);
    private readonly configurationManagerService = inject(ConfigurationManagerService);


    rooms: Rooms[] = this.data.rooms;
    options: any = {...this.data.options};
    bookings: Bookings[] = this.data.bookings;
    bookingShifts: any[] = this.data.bookingShifts;
    bookingTable: { room_id?: number | undefined; } = {};

    tables: any[] = [];
    tablesById: any[] = [];
    isMobile = mobileCheck();
    availableTables: any[] = [];
    tablesOccupations: any = {};

    booking = this.options.booking ? {...this.options.booking} :{
        duration: this.configurationManagerService.getPreference('bookings.slot_duration' as any) || 30,
        people: 1,
        status: 'confirmed'
    };

    bookingForm = this.formBuilder.group({
        status: [this.booking.status, [Validators.required]],
        customer: [this.booking._customerName, [!this.booking.pms_reservation_id ? Validators.required : () => null]],
        pms_reservation_id: [this.booking.pms_reservation_name + ' (' + this.booking.pms_reservation_id + ')'],
        bookedFor: [this.booking.booked_for],
        notes: [this.booking.notes]
    })

    tablesSelected: any[] = [];

    sendingBooking = false;
    dialogTitle = ""
    customerByPmsReservationId = "";

    bookingStatuses: { id: string; name: string; }[] = [];
    bookingShiftsById: { [key: string]: BookingShifts; } = {};
    statuses: string[] = ['provisional', 'confirmed', 'arrived', 'seated', 'departed', 'noshow'];

    constructor() {
        subscribeInComponent(
            this.bookingForm.controls['bookedFor'].valueChanges,
            () => {
                this.onBookedForChange();
            }
        )

        this.rooms.forEach((room) => {
            if(isArray(room.tables)) {
                room.tables.forEach((table) => {
                    if(!table.name.startsWith('ph_')) {
                        this.tables.push({
                            room_name: room.name,
                            room_id: room.id,
                            table_name: table.name,
                            table_id: table.id,
                            covers: table.covers,
                            type: table.order_type,
                            selected: false,
                            available: true
                        });
                    }
                });
            }
        });

        this.tablesById = Object.assign({}, ...this.tables.map(table => ({ [table.table_id]: table })));

        this.bookings.forEach((booking) => {
            if(booking.id !== this.booking.id && booking.tables) {
                booking.tables.forEach((table: any) => {
                    let dateStart = moment(booking.booked_for);

                    let tableOccupation = {
                        start: dateStart,
                        end: moment(dateStart).add(booking.duration, 'minutes')
                    };

                    if(isArray(this.tablesOccupations[table.table_id])) {
                        this.tablesOccupations[table.table_id].push(tableOccupation);
                    } else {
                        this.tablesOccupations[table.table_id] = [tableOccupation];
                    }
                });
            }
        });

        if(this.booking.pms_reservation_id) {
            this.customerByPmsReservationId = `${this.booking.pms_reservation_name} (${this.booking.pms_reservation_id})`;
        }


        if(this.booking.id) {
            this.dialogTitle = this.translateService.instant('BOOKINGS.NEW_BOOKING_DIALOG.TITLE_EDIT');

            this.bookingForm.controls['bookedFor'].setValue(this.booking.booked_for);

            this.booking = {
                ...this.booking,
                _bookedFor: this.booking.booked_for,
                _customerName: this.util.getCustomerCaption(this.booking.booking_customer)
            };
        } else {
            this.dialogTitle = this.translateService.instant('BOOKINGS.NEW_BOOKING_DIALOG.TITLE_ADD');

            if(this.options.tableId) {
                this.booking.tables = [{
                    table_id: this.options.tableId
                }];

                this.bookingTable = this.tablesById[this.options.tableId];
            }

            const dateEvent = this.tilbyDatePipe.transform(this.options.dateStart, 'yyyy-MM-dd HH:mm:ss');
            const dateStart  = TilbyDatePipe.date({outputFormat: 'date', date: dateEvent || ''});

            if(this.options.dateStart) {
                this.booking._bookedFor = dateStart;
                let dateEnd;

                if(this.options.dateEnd) {
                    const dateEvent = this.tilbyDatePipe.transform(this.options.dateEnd, 'yyyy-MM-dd HH:mm:ss');
                    dateEnd = TilbyDatePipe.date({outputFormat: 'date', date: dateEvent || ''});

                    const diffInMilliseconds = dateEnd.getTime() - dateStart.getTime();
                    const diffInMinutes = diffInMilliseconds / (1000 * 60);

                    this.booking.duration = diffInMinutes;
                } else
                    dateEnd = dateStart.setMinutes(this.booking.duration);


                let suggestedShift = BookingUtils.getSuggestedShift(this.bookingShifts, dateStart, this.bookingTable);

                if(suggestedShift) {
                    this.booking.shift_id = suggestedShift.id;

                    if(!this.options.dateEnd) {
                        this.booking.duration = suggestedShift.default_duration;
                    }
                }
            }

            if (this.options.customer) {
                this.booking = {
                    ...this.booking,
                    customer_id: this.options.customer.id,
                    booking_customer: this.options.customer,
                    _customerName: this.util.getCustomerCaption(this.options.customer)
                };
            }
        }

        this.booking.tables && this.booking.tables.forEach((table: any) => {
            if (!this.tablesById[table.table_id]) {
                this.tablesById[table.table_id] = {};
            }
            this.tablesById[table.table_id].selected = true;
        });

        this.bookingShiftsById = this.bookingShifts.reduce((acc, shift) => {
            acc[shift.id] = shift;
            return acc;
        }, {});

        this.statuses.forEach((status) => {
            this.bookingStatuses.push({
                id: status,
                name: this.translateService.instant('BOOKINGS.STATUSES.' + status.toUpperCase())
            });
        });

        this.orderTablesByAvailability();

        //this is usefull to set the time to the current date get in the bookingsStateService
        const originalDate = this.bookingsStateService.selectBookingsData()?.date;
        const currentDate = new Date();
        originalDate.setHours(currentDate.getHours());
        originalDate.setMinutes(currentDate.getMinutes());
        originalDate.setSeconds(currentDate.getSeconds());
        originalDate.setMilliseconds(currentDate.getMilliseconds());
        
        if(!this.booking._bookedFor) {
            this.booking._bookedFor = originalDate.toISOString();
        }
    }

    ngAfterViewInit() {
        this.setBackgroundColor();
    }

    orderTablesByAvailability() {
        let bookingStart = moment(this.booking._bookedFor);
        let bookingEnd = moment(bookingStart).add(this.booking.duration, 'minutes');

        let tablesByFitType = groupBy(this.tables, (table) => {
            if(this.tablesOccupations[table.table_id]) {
                table.available = this.tablesOccupations[table.table_id].every((occupation: any) => {
                    return !this.util.checkDateRangeOverlap(bookingStart, bookingEnd, occupation.start, occupation.end);
                });

                if(!table.available) {
                    return 'unavailable';
                }
            }

            if(table.covers === this.booking.people) {
                return 'fit_exactly';
            } else if(table.covers > this.booking.people) {
                return 'fit';
            } else {
                return 'dont_fit';
            }
        });

        this.availableTables = [
            ...(tablesByFitType.fit_exactly || []),
            ...(tablesByFitType.fit || []).sort((a, b) => a.covers - b.covers),
            ...(tablesByFitType.dont_fit || []).sort((a, b) => b.covers - a.covers),
            ...(tablesByFitType.unavailable || []).sort((a, b) => b.covers - a.covers)
        ];

        this.availableTables.sort((a, b) => b.selected - a.selected);
    };

    onShiftChanged() {
        let bookingShift = this.bookingShiftsById[this.booking.shift_id];

        if(bookingShift && bookingShift.room_restrictions) {
            let roomRestrictions: { [key: string]: boolean } = {};

            bookingShift.room_restrictions.forEach((roomRestr) => {
                roomRestrictions[roomRestr.room_id] = true;
            });

            this.availableTables = this.tables.filter((table: any) => {
                return !roomRestrictions[table.room_id];
            });
        } else {
            this.availableTables = this.tables;
        }

        this.orderTablesByAvailability();
    };

    onBookedForChange() {
        const dateEvent = this.tilbyDatePipe.transform(this.booking._bookedFor, 'yyyy-MM-dd HH:mm:ss');
        const bookedFor = TilbyDatePipe.date({outputFormat: 'date', date: dateEvent || ''});

        let bookingStart = bookedFor;
        let suggestedShift = BookingUtils.getSuggestedShift(this.bookingShifts, bookingStart, this.bookingTable);

        this.booking.shift_id = suggestedShift?.id;
        this.onShiftChanged();
    };

    addEditCustomer(event?: any) {
        if (event.target && event.target.disabled !== undefined) {
            event.target.disabled = true;
        }

        this.addSelectCustomerDialog.show({ customer_id: this.booking.customer_id })
        .then((customer: any) => {
            if(customer)
                Object.assign(this.booking, {
                    customer_id: customer.id,
                    _customerName: this.util.getCustomerCaption(customer),
                    booking_customer: {
                        id: customer.id,
                        first_name: customer.first_name,
                        last_name: customer.last_name,
                        company_name: customer.company_name
                    }
                });
        })
        .finally(() => {
            event.target.disabled = false;
        });
    };


    cancel() {
        this.matDialogRef.close();
    };

    async confirm() {
        if (this.sendingBooking) {
            return;
        }

        if (this.bookingForm.invalid) {
            this.bookingForm.markAllAsTouched();
            return;
        }

        const timezone = this.configurationManagerService.getPreference('general.timezone');
        this.bookingForm.controls.bookedFor.setValue(moment.tz(this.bookingForm.controls.bookedFor.value, timezone).format());

        this.sendingBooking = true;

        try {
            Object.assign(this.booking, {
                tables: this.availableTables.filter((table) => table.selected).map((table) => ({ table_id: table.table_id })),
                booked_for: this.booking._bookedFor,
                external_id: ''
            });

            const saveFunction = this.booking.id ? 'putOneOnline' : 'postOneOnline';
            const { _bookedFor, booking_customer, _customerName, ...bookingToSave } = structuredClone(this.booking);

            const booking = await this.entityManager.bookings[saveFunction](bookingToSave);

            try {
                this.matDialogRef.close({ booking });
            } catch (err) {
            }

            const tableCreationTriggers = ['seated', 'arrived'];

            if (booking.tables?.length === 0 || !tableCreationTriggers.includes(booking.status) || tableCreationTriggers.includes(this.options?.booking?.status)) return;

            if (booking && booking.booking_customer && booking.booking_customer.id) {
                const customer = await this.entityManager.customers.fetchOneOffline(booking.booking_customer.id);
                const sales = await this.entityManager.sales.fetchCollectionOffline();

                const targetTable = booking.tables && booking.tables.find((value: BookingsTables, index: number, obj: BookingsTables[]) => {
                    return value.table_id !== undefined && (this.tablesById[Number(value.table_id)].type === 'multiple' || !sales.some((sale) => sale.table_id === Number(value.table_id)));
                });

                if (!targetTable) {
                    this.alertDialogService.openDialog({data: {messageLabel:'BOOKINGS.NEW_BOOKING_DIALOG.NO_FREE_TABLE'}});
                    return;
                }

                const saleCustomer = Object.assign(structuredClone(customer || {}), {
                    customer_id: customer && customer.id,
                    id: null
                });

                const tableId = Number(targetTable?.table_id);
                const tableInfo = (tableId && this.tablesById[tableId]) || {};

                let sale = await this.saleUtils.getSaleTemplate();
                sale = { ...sale, "name": this.translateService.instant('TABLES_NEW.DIALOGS.SALE.NAME_TEMPLATE'), "order_type": "normal", "table_id": tableInfo.table_id, "table_name": tableInfo.table_name, "room_id": tableInfo.room_id, "room_name": tableInfo.room_name, "covers": booking.people, "booking_id": booking.id };
                sale.sale_customer = saleCustomer;

                this.entityManager.sales.postOneOfflineFirst(sale).then((postedSale) => {
                    this.matDialogRef.close({booking});

                    const snackBarRef = this._snackBar.open(this.translateService.instant('BOOKINGS.NEW_BOOKING_DIALOG.ORDER_CREATED'), 'Ok', {
                        horizontalPosition: 'start',
                        verticalPosition: 'bottom',
                        duration: 3000,
                    });

                    snackBarRef.onAction().subscribe(() => {
                        this.state.go('app.new.cashregister.content.showcase', {
                            action: 'open-sale-id',
                            saleType: postedSale.order_type,
                            id: postedSale.id
                        });
                    });
                });
            }
        } catch (error) {
        } finally {
            this.sendingBooking = false;
        }
    };

    async deleteBooking() {
        const answer = await this.confirmDialogService.openDialog({ data: { messageLabel: this.translateService.instant('BOOKINGS.NEW_BOOKING_DIALOG.DELETE_CONFIRM') } });
        
        if(!answer) {
            return;
        }

        this.sendingBooking = true;

        try {
            await this.entityManager.bookings.deleteOneOnline(this.booking.id);
        } catch (error) {
        } finally {
            this.sendingBooking = false;
            this.matDialogRef.close({ action: 'delete' });
        }
    };

    selectTable(table: any) {
        table.selected = !table.selected;
        if(table.selected) {
            this.tablesSelected.push(table);
        } else {
            const index = this.tablesSelected.indexOf(table);
            if(index > -1) {
                this.tablesSelected.splice(index, 1);
            }
        }
    };

    increaseDuration(event: any) {
        event.preventDefault();
        this.booking.duration = +this.booking.duration + 10;
        this.orderTablesByAvailability();
    };

    decreaseDuration(event: any) {
        event.preventDefault();

        if(this.booking.duration >= 20) {
            this.booking.duration -= 10;
            this.orderTablesByAvailability();
        } else if(this.booking.duration > 10) {
            this.booking.duration = 10;
            this.orderTablesByAvailability();
        }
    };

    increasePeople(event: any) {
        event.preventDefault();
        this.booking.people++;
        this.orderTablesByAvailability();
    };

    decreasePeople(event: any) {
        event.preventDefault();
        if(this.booking.people > 1) {
            this.booking.people--;
            this.orderTablesByAvailability();
        }
    };

    setBackgroundColor() {
        setTimeout(() => {
            const statusSelect = document.querySelectorAll('.status-select');

            if (statusSelect.length > 0) {
                statusSelect.forEach((statusEl, index) => {
                    const status = statusEl.getElementsByClassName('mat-mdc-text-field-wrapper')[0];


                    if(status.textContent === this.translateService.instant('BOOKINGS.STATUSES.CONFIRMED')) {
                        status.setAttribute('style', 'background-color: green !important');
                        const child = Array.from(status.getElementsByClassName('mat-mdc-select-min-line') as HTMLCollectionOf<HTMLElement>)[0];
                        child.setAttribute('style', 'color: white !important;');
                    } else if(status.textContent === this.translateService.instant('BOOKINGS.STATUSES.PROVISIONAL')) {

                        status.setAttribute('style', 'background-color: orange !important');
                        const child = Array.from(status.getElementsByClassName('mat-mdc-select-min-line') as HTMLCollectionOf<HTMLElement>)[0];
                        child.setAttribute('style', 'color: black !important;');

                    } else if(status.textContent === this.translateService.instant('BOOKINGS.STATUSES.ARRIVED')) {
                        status.setAttribute('style', 'background-color: red !important');
                        const child = Array.from(status.getElementsByClassName('mat-mdc-select-min-line') as HTMLCollectionOf<HTMLElement>)[0];
                        child.setAttribute('style', 'color: white !important;');
                    } else {
                        status.setAttribute('style', 'background-color: gray !important');
                        const child = Array.from(status.getElementsByClassName('mat-mdc-select-min-line') as HTMLCollectionOf<HTMLElement>)[0];
                        child.setAttribute('style', 'color: black !important;');
                    }
                })
            }
        }, 0);
    }

    changeBackgroundColor(event: any) {
        const el = document.getElementById(event.source.id);

        if(el) {
            const wrapper = el.closest('.mat-mdc-text-field-wrapper');

            if(event.value === 'confirmed') {
                if(wrapper) {
                    wrapper.setAttribute('style', 'background-color: green !important');
                    const child = Array.from(wrapper.getElementsByClassName('mat-mdc-select-min-line') as HTMLCollectionOf<HTMLElement>)[0];
                    child.setAttribute('style', 'color: white !important;');
                }
            } else if(event.value === 'provisional') {
                if(wrapper) {
                    wrapper.setAttribute('style', 'background-color: orange !important');
                    const child = Array.from(wrapper.getElementsByClassName('mat-mdc-select-min-line') as HTMLCollectionOf<HTMLElement>)[0];
                    child.setAttribute('style', 'color: black !important;');
                }
            } else if(event.value === 'arrived') {
                if(wrapper) {
                    wrapper.setAttribute('style', 'background-color: red !important');
                    const child = Array.from(wrapper.getElementsByClassName('mat-mdc-select-min-line') as HTMLCollectionOf<HTMLElement>)[0];
                    child.setAttribute('style', 'color: white !important;');
                }
            } else {
                if(wrapper) {
                    wrapper.setAttribute('style', 'background-color: gray !important');
                    const child = Array.from(wrapper.getElementsByClassName('mat-mdc-select-min-line') as HTMLCollectionOf<HTMLElement>)[0];
                    child.setAttribute('style', 'color: black !important;');
                }
            }
        }
    }

    protected customActions: GeneralInputButton[] = [
        {
            name: "",
            click: () => this.deleteBooking(),
            isIt: () => !!this.booking.id,
            icon: signal("delete"),
            isDisable: () => this.sendingBooking
        },
        {
            name: "",
            click: () => this.confirm(),
            isIt: () => true,
            icon: signal("check"),
            isDisable: () => this.sendingBooking
        }
    ];
}

@Injectable({
    providedIn: "root",
})
export class AddEditNewBookingDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(config?: NonNullableConfigData<BookingDialogData>) {
        const data: BookingDialogData = config?.data||{ bookingShifts: [], bookings: [], rooms: [], options: { booking: {} } };
        const configEdited: NonNullableConfigData<BookingDialogData> = {
            ...config,
            ...this.switchMobileDesktopDimensions( { width: '900px', height: (data.options && data.options.booking && data.options.booking.pms_reservation_id) ? '600px' : '510px' } ),
            disableClose: true,
            data,
        };
        return lastValueFrom(
            this.dialogRef.open(AddNewBookingDialogComponent, configEdited).afterClosed()
        );
    }
}


import { CommonModule } from "@angular/common";
import { MatListModule } from "@angular/material/list";
import { TranslateModule } from "@ngx-translate/core";
import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";

import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule
} from "@angular/material/dialog";

import {
    ChangeDetectionStrategy,
    Component,
    Injectable,
    inject
} from "@angular/core";

import {
    Sales,
    SaleTransactions,
} from "tilby-models";

import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogContentComponent,
    TilbyDialogToolbarComponent
} from "@tilby/tilby-ui-lib/components/tilby-dialog";

import { lastValueFrom } from "rxjs";
import { MatDividerModule } from "@angular/material/divider";

import { VariationSale } from "src/app/shared/model/cashregister.model";
import { SaleTransactionUtilsService } from "src/app/features";
import { compareObjects } from "src/app/shared/utils";

export type HistoricalChangesDialogData = {
    title: string,
    transactions: SaleTransactions[],
    sale: Sales
};

@Component({
    selector: "app-edit-history-dialog",
    standalone: true,
    imports: [
        CommonModule,
        MatListModule,
        TranslateModule,
        TilbyDatePipe,
        MatIconModule,
        MatButtonModule,
        MatDividerModule,
        MatDialogModule,
        TilbyDialogContentComponent,
        TilbyDialogToolbarComponent
    ],
    templateUrl: "./historical-changes-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoricalChangesDialogComponent {
    protected readonly data: HistoricalChangesDialogData = inject(MAT_DIALOG_DATA);
    private readonly saleTransactionUtilsService = inject(SaleTransactionUtilsService);

    protected variationSales: Partial<VariationSale>[] = [];

    ngOnInit() {
        const variationSales = this.saleTransactionUtilsService.buildVariationSalesFromTransactions(this.data.sale, this.data.transactions, {
            includeAllTransactions: true,
            mergeTransactions: false,
            includeDeletedComponents: true
        });

        const result: Partial<VariationSale>[] = [];
        
        for(const [index, variationSale] of variationSales.entries()) {
            const previous = variationSales[index - 1];

            if(previous) {
                const { variation_items, variation, printed, skip_printing, ...rest } = compareObjects(variationSale, previous);

                result.push({
                    ...rest,
                    printed: variationSale.printed,
                    skip_printing: variationSale.skip_printing,
                    seller_name: variationSale.seller_name,
                    variation: variationSale.variation,
                    variation_items: variationSale.variation_items
                });
            } else {
                result.push(variationSale);
            }
        }

        this.variationSales = result;
    }
}

@Injectable({
    providedIn: "root",
})
export class HistoricalChangesDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(config: NonNullableConfigData<HistoricalChangesDialogData>) {
        const data: HistoricalChangesDialogData = config.data;

        return lastValueFrom(
            this.dialogRef.open(HistoricalChangesDialogComponent, {
                ...config,
                ...this.switchMobileDesktopDimensions({width: "800px"}),
                disableClose: true,
                data,
            }).afterClosed()
        );
    }
}
import angular from 'angular';

angular.module('application').component('keypadPin', {
    bindings: {
        maxlength: '<',
        field: '=',
        onDigitChange: '&'
    },
    template: require('./keypad-pin.html'),
    controller: ["$element", "$attrs", "$timeout", function($element, $attrs, $timeout) {
        this.$onInit = function() {
            this.field = '';
        };

        this.pressKey = function(key) {            
            if (key === 'del') {
                this.field = '';
            } else {
                if (this.maxlength && (this.field.length < this.maxlength )) {
                    this.field += key;
                } else if (!this.maxlength){
                    this.field += key;
                }
            }
            
            this.onDigitChange({pin: this.field });
        };
    }]
});

import angular from 'angular';
import * as async from 'async';
import _ from 'lodash';
import moment from 'moment-timezone';
import { stringToLines } from 'src/app/shared/string-utils';

function AxonRTDriver($q, $window, $translate, $timeout, fiscalUtils, util, waitDialog, errorsLogger, checkManager, salePrintingUtils) {
    /**
     *  Tilby Axon Driver
     *  
     *  Usage:
     *  AxonRTDriver.setup() and after call public methods:
     *  
     *  - autoConfigurePrinter
     *  - printFiscalReceipt
     *  - printCourtesyReceipt
     *  - printNonFiscal
     *  - openCashDrawer
     *  - dailyClosing
     *  - dailyRead
     *  - readDgfe (read/print)
     *  - readDgfeBetween (read/print)
     *  - printFiscalMemoryBetween
     *  
     *  - printFreeNonFiscal
     *  
     */
    
    var scope = {};

    var errorsTable = {
        16: 'FISCAL_CLOSING_NEEDED',
        26: 'BUSY',
        51: 'COVER_OPEN',
        81: 'DATA_READ_FINISHED',
        82: 'DATA_READ_FINISHED',
        116: 'RT_ALREADY_VOID',
        117: 'RT_ALREADY_VOID',
        118: 'RT_MODE_DISABLED',
        120: 'FISCAL_CLOSING_NEEDED',
        123: 'RT_ALREADY_VOID'
    };

	var checkQueueType = function(type) {
		if(_.includes(['auto', 'manual'], checkManager.getPreference('cashregister.queue.mode'))) {
			var queueType = [];
	
			try {
				queueType = JSON.parse(checkManager.getPreference('cashregister.queue.print_type'));
			} catch(e) {}
	
			return _.includes(queueType, type);
		} else {
			return false;
		}
    };

    var byteArrayToString = function(array) {
        return _.map(array, function(val) { return String.fromCharCode(val); }).join('');
    };

    /**
     *  cleanUpSpecialChars
     */
    function cleanUpSpecialChars(str) {
        return str.replace("€", "E").replace("/", "-").replace(/TOTALE/i, 'T0TALE').replace(/[^\x00-\x7F]/g, "*");
    }

    function dateToString(date) {
        return moment(date).format('DD/MM/YYYY HH:mm');
    }

    function getPaymentType(method_type_id, unclaimed) {
        if(fiscalUtils.isCashPayment(method_type_id)) {
            return {id: 0, code: 'PC'};
        } else if(fiscalUtils.isMethodUnclaimed(method_type_id, unclaimed)) {
            return {id: 2, code: 'NR'};
        } else {
            return {id: 1, code: 'PE'};
        }
    }

    var getPacket = function (command) {
        var i;
        var packetData = [];

        //DATA
        for(i = 0; i < command.length; i++) {
            packetData.push(command.charCodeAt(i));
        }

        //Checksum
        /*packetData.push(0x2F);

        var dataSum = _.sum(packetData) % 100;
        var checkSum = [_.floor(dataSum / 10) + 0x30, dataSum % 10 + 0x30];

        var packet = _.concat(packetData, checkSum);*/
        var packet = packetData;

        var buffer = new ArrayBuffer(packet.length);
        var bufView = new Uint8Array(buffer);

        for (i = 0; i < packet.length; i++) {
            bufView[i] = packet[i];
        }

        return buffer;
    };

    var closeSocket = function (socket, callback) {
        if(_.isInteger(_.get(socket, ['info', 'socketId']))) {
            $window.chrome.sockets.tcp.disconnect(socket.info.socketId, function() {
                errorsLogger.debug("[DEBUG] Socket disconnected");
    
                $window.chrome.sockets.tcp.close(socket.info.socketId);
    
                if(_.isFunction(callback)) {
                    $timeout(function() { callback();}, 1000);
                }
            });
        }
    };

    var getDeviceStatus = function(deviceStatus, fiscalStatus) {
        deviceStatus = parseInt(deviceStatus || 0, 16);
        fiscalStatus = parseInt(fiscalStatus || 0, 16);

        var getBoolean = function(val) { return val ? true : false; };

        return {
            deviceStatus: {
                busy: getBoolean(deviceStatus & 1),
                fatalError: getBoolean(deviceStatus & 2),
                paperEnd: getBoolean(deviceStatus & 4),
                batteryWarning: getBoolean(deviceStatus & 8),
                printerOffline: getBoolean(deviceStatus & 16),
                fiscalFileFull: getBoolean(deviceStatus & 32),
                printerTimeout: getBoolean(deviceStatus & 64),
                cutterError: getBoolean(deviceStatus & 128),
            },
            fiscalStatus: {
                drawerOpen: getBoolean(fiscalStatus & 1),
                dayOpen: getBoolean(fiscalStatus & 2),
                transactionOpen: getBoolean(fiscalStatus & 4),
                reserved: getBoolean(fiscalStatus & 8),
                transactionInPayment: getBoolean(fiscalStatus & 16),
                cashInOpen: getBoolean(fiscalStatus & 32),
                cashOutOpen: getBoolean(fiscalStatus & 64),
                EJReportOpen: getBoolean(fiscalStatus & 128),
            }
        };
    };

    function sendCommands(printerSocket, commands, options) {
        var d = $q.defer();

        if(!_.isObject(options)) {
            options = {};
        }

        if(_.isObject(printerSocket)) {
            var reqTimeout = null;
            var response = [];
            var currentCallback = null;
            var waitMode = false;
            var timeout = options.timeout || 10000;

            var listen = function(info) {
                if (info.socketId === printerSocket.info.socketId) {
                    $timeout.cancel(reqTimeout);
                    var data = new Uint8Array(info.data);
                    var dataString;

                    if(waitMode) {
                        dataString = byteArrayToString(data);
                        response.push(dataString);

                        if(_.includes(dataString, options.waitString)) {
                            currentCallback();
                        } else {
                            reqTimeout = $timeout(timeoutFunc, timeout, false);
                        }
                    } else {
                        dataString = byteArrayToString(data).split('/');
                        var replyCode = parseInt(dataString[0], 16);
                        _.assign(printerSocket, getDeviceStatus(dataString[1], dataString[2]));

                        if(!replyCode) {
                            response.push(dataString.slice(3, dataString.length - 1));
    
                            if(options.waitString) {
                                waitMode = true;
                                reqTimeout = $timeout(timeoutFunc, timeout, false);
                            } else {
                                currentCallback();
                            }
                        } else {
                            currentCallback(errorsTable[replyCode] || 'AXON.ERROR_' + replyCode);
                        }
                    }
                }
            };
            
            $window.chrome.sockets.tcp.onReceive.addListener(listen);

            var timeoutFunc = function() {
                currentCallback("REQ_TIMEOUT");
            };

            async.eachSeries(commands, function(command, cb) {
                if(_.isArray(command)) {
                    command = command.join('/');
                }

                reqTimeout = $timeout(timeoutFunc, timeout, false);
                currentCallback = cb;

                $window.chrome.sockets.tcp.send(printerSocket.info.socketId, getPacket(command), _.noop);
            }, function(err, results) {
                $window.chrome.sockets.tcp.onReceive.removeListener(listen);

                if(err) {
                    d.reject(err);
                } else {
                    d.resolve(response);
                }
            });
        } else {
            d.reject('INVALID_SOCKET');
        }

        return d.promise;
    }

    function isAvailable(printer) {
        var d = $q.defer();

        if(_.get($window, "chrome.sockets.tcp")) {
            $window.chrome.sockets.tcp.create(function(socketInfo) {
                var printerSocket = {
                    printer: printer,
                    info: socketInfo
                };
    
                var timeoutConn = function () {
                    $window.chrome.sockets.tcp.getInfo(socketInfo.socketId, function(info) {
                        if (!info.connected) {
                            d.reject('CONNECTION_ERROR');
                        }
                    });
                };
    
                var timeoutHandle = $timeout(timeoutConn, 10000);
        
                $window.chrome.sockets.tcp.connect(socketInfo.socketId, printer.ip_address, printer.port || 9101, function(result) {
                    $timeout.cancel(timeoutHandle);
                    errorsLogger.debug("Socket connected to " + printer.ip_address);
    
                    if(result === 0) {
                        sendCommands(printerSocket, ['a', 'v']).then(function(responses) {
                            var printerInfo = responses.shift();
                            var printerFwInfo = responses.shift();

                            _.assign(printerSocket, {
                                printerSerial: [printerInfo[2], printerInfo[0]].join(' '),
                                printerFirmware: _.trim(printerFwInfo[0]),
                                capabilities: {}
                            });

                            switch(printerInfo[2]) {
                                case 'TN':
                                    _.assign(printerSocket.capabilities, {
                                        saleColumns: 30,
                                        saleExtraColumns: 30,
                                        textColumns: 48,
                                        discountColumns: 20,
                                        discountExtraColumns:30,
                                        canCut: true
                                    });
                                break;
                                case 'PD':
                                    _.assign(printerSocket.capabilities, {
                                        saleColumns: 20,
                                        saleExtraColumns: 30,
                                        textColumns: 32,
                                        discountColumns: 15,
                                        discountExtraColumns: 30,
                                        canCut: false
                                    });
                                break;
                                case 'CA':
                                    _.assign(printerSocket.capabilities, {
                                        saleColumns: 20,
                                        saleExtraColumns: 30,
                                        textColumns: 32,
                                        discountColumns: 15,
                                        discountExtraColumns: 30,
                                        canCut: false
                                    });
                                break;
                                case 'VR':
                                default:
                                    _.assign(printerSocket.capabilities, {
                                        saleColumns: 20,
                                        saleExtraColumns: 30,
                                        textColumns: 32,
                                        discountColumns: 15,
                                        discountExtraColumns: 30,
                                        canCut: false
                                    });
                                break;
                            }

                            _.assign(printerSocket.capabilities, {
                                payments: _.toInteger(printerFwInfo[5]),
                                headerLines: _.toInteger(printerFwInfo[8])
                            });

                            d.resolve(printerSocket);
                        }, function(error) {
                            closeSocket(printerSocket);
                            d.reject(error);
                        });
                    } else {
                        closeSocket(printerSocket);
                        d.reject('CONNECTION_ERROR');
                    }
                });
            });
        } else {
            d.reject('UNSUPPORTED_ENVIRONMENT');
        }

        return d.promise;
    }

    /**
     * linesToCommands
     */
    function linesToCommands(lines, columns) {
        if (!_.isArray(lines)) {
            if (_.isString(lines)) {
                lines = lines.split("\n");
            }
        }

        var commands = [];

        _.forEach(lines, function(l) {
            l = _.truncate(cleanUpSpecialChars(l), { length: columns, omission: '' });

            commands.push(['7', '1', '1', l]);
        });

        commands.push("m");
        return commands;
    }
    
    /**     
     *  saleToReceiptCommands
     */
    function saleToReceiptCommands(sale, options, capabilities) {
        var commands = []; //TODO: clear printer before sending sale commands
        var isRefunding;

        var addTextLine = function(text) {
            var textToAdd = _.truncate(text, { length: capabilities.textColumns, omission: '' });
            commands.push(['7', '1', '1', textToAdd]);
        };

        const isRefundVoid = fiscalUtils.isRefundVoidSale(sale);

        if (isRefundVoid) {
			var docDataItem = _.find(sale.sale_items, function (si) {
				return si.reference_sequential_number && si.reference_date;
			});

			var docData = {};

			if (docDataItem) {
				docData = {
					reference_sequential_number: docDataItem.reference_sequential_number,
					reference_date: docDataItem.reference_date
				};
			} else {
				return 'MISSING_REFERENCE_DOCUMENT';
			}

			if (_.every(sale.sale_items, docData)) { //If all of the items are from the same document
				//Check if we are doing a void or a refund
				var hasVoid = false;
                var hasRefund = false;

				_.forEach(sale.sale_items, function (si) {
					if (si.refund_cause_id === 6) {
						hasVoid = true;
					} else {
						hasRefund = true;
					}
				});

				if (hasRefund && hasVoid) {
					return 'MIXED_REFUND_CAUSES';
				} else {
					var docSeqNum = fiscalUtils.parseRTDocumentSequentialNumber(docData.reference_sequential_number);
                    var documentDate = moment(docData.reference_date).format('DDMMYYYY');                    

					if (hasVoid) {
                        commands.push(['+', '1', documentDate, docSeqNum.daily_closing_num, docSeqNum.document_sequential_number]);

                        return commands;
                    } else {
                        commands.push(['-', documentDate, docSeqNum.daily_closing_num, docSeqNum.document_sequential_number, '']);
                        isRefunding = true;
                    }
                    
				}
			} else {
				return 'ITEMS_FROM_DIFFERENT_DOCUMENTS';
			}
        } else {
            if(_.some(sale.sale_items, function(saleItem) { return saleItem.quantity < 0; })) {
                return 'REFUNDS_NOT_ALLOWED_IN_SALES';
            }

            commands.push(['>', '', '', '']);

            // Sale Name
            const printName = (scope.options.print_name === false) ? false : true;

            if(printName) {
                for(let row of fiscalUtils.getFiscalReceiptHeaderLines(sale)) {
                    addTextLine(row);
                }
            }

            //Add lottery code if available
            if(sale.lottery_code) {
                commands.push(['I', sale.lottery_code, '0']);
            }
        }

        var printDetails = (options.print_details === false && !isRefundVoid) ? false : true;

        // Sale items
        if(printDetails || isRefunding) {
            if(_.find(sale.sale_items, { price: 0 })) {
                return 'Omaggi non disponibili per questa stampante';
            }

            _.forEach(fiscalUtils.extractSaleItems(sale), function(si) {
                var siName = _.truncate(cleanUpSpecialChars(si.name || si.department_name), { length: capabilities.saleColumns });
                var departmentCode = _.toString(si.department_id);

                commands.push(['3', isRefunding ? 'N' : 'S', siName, '', Math.abs((si.quantity).toFixed(3)), (si.price).toFixed(2), departmentCode, '', '', '']);
                
                if(!isRefunding) {
                    // Check printNotes
                    var printNotes = (scope.options.print_notes === false) ? false :true;

                    // Item barcode (if print_notes)
                    if (printNotes && si.barcode) {
                        if (si.barcode.toLowerCase().indexOf('p') < 0 && si.barcode.toLowerCase().indexOf('q') < 0) {
                            addTextLine('#' + si.barcode.trim());
                        }
                    }

                    // Notes
                    if (printNotes && si.notes) {
                        var notesLines = si.notes.split("\n");
                        _.forEach(notesLines, function(nl) {
                            if (nl.trim()) {
                                addTextLine('# ' + cleanUpSpecialChars(nl.trim()));
                            }
                        });
                    }

                    // Refund cause
                    if (si.quantity < 0 && si.refund_cause_description) {
                        addTextLine('# ' + cleanUpSpecialChars(si.refund_cause_description));
                    }

                    // Discount / Surcharges            
                    // - Sort discount/surcharges by index
                    if (si.quantity > 0) {
                        // Discount/Surcharges
                        var partialPrice = fiscalUtils.roundDecimals(si.price * si.quantity);

                        _(si.price_changes).sortBy('index').forEach(function(pc) {
                            var pcAmount = fiscalUtils.getPriceChangeAmount(pc, partialPrice);

                            if(!_.isNil(pcAmount)) {
                                partialPrice = fiscalUtils.roundDecimals(partialPrice + pcAmount);
                                var adjustmentType = pcAmount < 0 ? '0' : '1';
                                var pcDesc = _.truncate(pc.description, { length: capabilities.discountColumns });

                                commands.push(['4', Math.abs(pcAmount).toFixed(2) , pcDesc, '', adjustmentType, '0', '1']);
                            }
                        });

                        if(si.type === 'gift') {
                            addTextLine("# Omaggio");
						}
                    }
                }
            });
        } else { // Hide details
            var departmentTotals = fiscalUtils.extractDepartments(sale);

            _.forIn(departmentTotals, function(depTotal, idx) {
                var departmentCode = _.toString(depTotal.id);
                var depName = _.truncate(cleanUpSpecialChars(depTotal.name), { length: capabilities.saleColumns, omission: '' });

                commands.push(['3', 'S', depName, '', '1', (depTotal.amount).toFixed(2), departmentCode, '', '', '']);
            });
        }
        
        if(!isRefunding) {
            // Apply discount/surcharges on subtotal
            if (!isRefundVoid && printDetails) {
                var partialPrice = fiscalUtils.roundDecimals(sale.amount);

                _(sale.price_changes).sortBy('index').forEach(function(pc) {
                    var pcAmount = fiscalUtils.getPriceChangeAmount(pc, partialPrice);

                    if(!_.isNil(pcAmount)) {
                        partialPrice = fiscalUtils.roundDecimals(partialPrice + pcAmount);
                        var adjustmentType = pcAmount < 0 ? '0' : '1';
                        var pcDesc = _.truncate(pc.description, { length: capabilities.discountColumns });

                        commands.push(['4', Math.abs(pcAmount).toFixed(2) , pcDesc, '', adjustmentType, '1', '1']);
                    }
                });
            }

            commands.push(['}']);

            _(fiscalUtils.extractPayments(sale)).forEach(function(p) {
                var paymentName = _.truncate(cleanUpSpecialChars(p.method_name), { length: 20, omission: '' });                    
                var paymentType = getPaymentType(p.method_type_id, p.unclaimed);

                commands.push(['5', _.toString(p.method_id), (p.amount).toFixed(2), paymentName, '', '', paymentType.code]);
            });

            // Ticket change
            var ticketChange = (scope.options.ticket_change === false) ? false : true;

            if (ticketChange && sale.change > 0 && sale.change_type === 'ticket') {    
                var ticketChangeAmount = util.round(sale.change);
    
                addTextLine("############## RESTO TICKET ###############");
                addTextLine("                                          ");
                addTextLine(" QUESTO COUPON VALE:           " + ticketChangeAmount + " EURO");
                addTextLine("                                          ");
                addTextLine(" Presenta alla cassa prima del pagamento  ");
                addTextLine(" questo coupon per avere riconosciuto il  ");
                addTextLine(" credito.                                 ");
                addTextLine(" Valido 30 giorni dalla data di emissione.");
                addTextLine(" Non convertibile in denaro.              ");
                addTextLine("                                          ");
                addTextLine("###########################################");

                var ticketBarcode = _.toString(1212000000000 + Math.round(ticketChangeAmount * 100));
                commands.push(['[', '50', '1', '2', '69', _.toString(ticketBarcode.length), ticketBarcode]);
            }
    
            // Add Tail (tail for this sale + general tail)
            var tail = options.tail || '';
    
            if (scope.options.tail) {
                tail += "\n" + scope.options.tail;
            }
    
            if (tail) {
                //var tails = tail.match(/.{1,24}/g);
                var tails = tail.split("\n");
                //var tails = tail.match(/.{1,24}/g);
                _.forEach(tails, function(tRow) {
                    addTextLine(tRow);
                });
            }
    
            var printCustomerDetail = (scope.options.print_customer_detail === false) ? false : true;
    
            // CF
            /*if (printCustomerDetail && ((sale.sale_customer && sale.sale_customer.tax_code) || sale.customer_tax_code)) {
    
                var taxCode = sale.customer_tax_code;
                if (sale.sale_customer && sale.sale_customer.tax_code) {
                    taxCode = sale.sale_customer.tax_code;
                }
    
                commands.push("=\"/?C/(" + taxCode.toUpperCase() + ")");
            }*/
    
            // Customer
            if (printCustomerDetail && sale.sale_customer) {
                var saleCustomer = sale.sale_customer;
                var customerInfo = fiscalUtils.getCustomerInfo(saleCustomer);

                _.forEach(customerInfo, addTextLine);
            }
    
            // Print Seller
            var printSeller = (scope.options.print_seller === false) ? false : true;
    
            if (printSeller && sale.seller_name) {
                var sellerPrefix = scope.options.seller_prefix || "Ti ha servito";
                addTextLine(sellerPrefix + " " + sale.seller_name.trim().split(" ")[0]);
            }
    
            // Payment tail */
            if (options.tail && options.tail.indexOf('FIRMA') > -1) {
    
                // Add Payment Tail 
                var pTail = options.tail.split("\n");
                if (pTail.length) {
                    _.forEach(pTail, function(tRow) {
                        addTextLine(tRow);
                    });
                }
            }

            if(!isRefundVoid && checkQueueType('tail')) {
                _.forEach(fiscalUtils.getQueueCouponRows(sale, capabilities.textColumns, { doubleWidth: true }), function(row) {
                    commands.push(['7', '1', row.doubleHeight ? '4' : '2', cleanUpSpecialChars(row.text)]);
                });
            }

            // Barcode
            var printBarcodeId = true;
    
            if (scope.options.print_barcode_id === false) {
                printBarcodeId = false;
            }

            if (printBarcodeId && _.isInteger(sale.id)) {
                var saleIdBarcode = cleanUpSpecialChars(sale.id.toString());
                commands.push(['[', '50', '1', '2', '69', _.toString(saleIdBarcode.length), saleIdBarcode]);
            }

            if(capabilities.canCut) {
                commands.push(['7', '1', '6', '']);
            }
        } else {
            commands.push(['5', '1', '0', '', '', '']);
        }

        return commands;
    }

    /**
	 * @description saleToNonFiscalCommands
	 */
	function saleToNonFiscalCommands(sale, options, capabilities) {
        const savePaper = !!checkManager.getPreference('cashregister.save_paper_on_prebill');
        const isRefundVoid = fiscalUtils.isRefundVoidSale(sale);
		const printerColumns = scope.printer.columns || capabilities.textColumns;

        // Prepare tail text
        let tail = '';

        if (options.tail && typeof options.tail === 'string') {
            tail += options.tail;
        }

        if (scope.options.tail && typeof scope.options.tail === 'string') {
            tail += '\n' + scope.options.tail;
        }

        // Use SalePrintingUtils service to generate document lines
        const documentLines = salePrintingUtils.saleToNonFiscalDocument(sale, {
            printerColumns: printerColumns,
            tail: tail
        });

        const commands = [['7', '1', '20', '']];

        const addLine = function (line) {
            const l = cleanUpSpecialChars(line).slice(0, 48);
            commands.push(['7', '1', '1', l]);
        };

        const printName = !(savePaper || scope.options.print_name === false);

        if(printName) {
            for(let row of fiscalUtils.getFiscalReceiptHeaderLines(sale)) {
                addLine(row);
            }
        }

        // Process document lines
        for (const line of documentLines) {
            switch (line.type) {
                case 'text':
                    addLine(line.align === 'center' ? _.pad(line.content, printerColumns, ' ') : line.content);
                    break;
                case 'qrcode':
                    // TODO: implement qrcode
                    break;
            }
        }

        commands.push('m');

        // Open cash drawer if allowed
        var openCashdrawer = (options.can_open_cash_drawer === false) ? false : true;

        if (openCashdrawer) {
			commands.push(['q', '1']);
        }

        return commands;
	}

    /**  
     * saleToCourtesyReceiptCommands
     */
    function saleToCourtesyReceiptCommands(sale, capabilities) {
        var lines = [];

        lines.push("SCONTRINO DI CORTESIA");
        lines.push("");
        lines.push(sale.name);
        lines.push("");

        // Items
        _.forEach(sale.sale_items, function(si) {
            lines.push(si.quantity + "x " + cleanUpSpecialChars(si.name || si.department_name));
        });

        return _.concat([['7','1','20','']], linesToCommands(lines, capabilities.textColumns));
    }

    /**     
     *  configurationToCommands
     *  @param like autoConfigurePrinter parameters
     */
    function configurationToCommands(printerSocket, options) {
        return fiscalUtils.getPrinterConfigurationResources().then(function(resources) {
            return sendCommands(printerSocket, ['e']).then(function(results) {
                var commands = [];

                // Vat/Departments setup
                var vatsToConfigure = _(resources.departments).map('vat.value').uniq().orderBy().map(function(val) {return val.toFixed(2);} ).value();

                //Parse current vats map to
                var currentVatsMap = {};

                if(vatsToConfigure > 5) {
                    throw 'VATS_LIMIT_EXCEEDED';
                }

                //Check if vat configuration is actually needed to avoid unnecessary writes, as the number of times we can configure the vats is limited on the Axon printers
                _.forEach(results.shift(), function(val, idx) {
                    if(_.isNil(currentVatsMap[val])) {
                        currentVatsMap[val] = idx + 1;
                    }
                });

                var needsVatConfigure = _.some(vatsToConfigure, function(vat) { return !currentVatsMap[vat]; });
                var vatsMap;
    
                if(needsVatConfigure) {
                    _.times(5, function(idx) {
                        if(_.isNil(vatsToConfigure[idx])) {
                            vatsToConfigure[idx] = '0.00';
                        }
                    });

                    vatsMap = {};
    
                    _.forEach(vatsToConfigure, function(val, idx) {
                        if(_.isNil(vatsMap[val])) {
                            vatsMap[val] = idx + 1;
                        }
                    });

                    commands.push(_.concat(['b'], vatsToConfigure));
                } else {
                    vatsMap = currentVatsMap;
                }

                _.forEach(resources.departments, function(department) {
                    var depName = _.truncate(cleanUpSpecialChars(department.name), { length: 30 });
                    var nature = department.vat.value === 0 ? _.toInteger((department.vat.code || 'N4').replace('N', '')) : 0;

                    commands.push(['D', _.toString(department.printer_code), depName, vatsMap[department.vat.value.toFixed(2)], "0.00", "999999.99", "0011000", "1", "0.00", _.toString(nature)]);
                });

                // Payment methods
                _.forEach(resources.paymentMethods, function(paymentMethod) {
                    var methodName = _.truncate(paymentMethod.name, { length: 25, omission: '' });
                    var methodShortName = _.toUpper(_.truncate(paymentMethod.name, { length: 4, omission: '' }));
                    var paymentTypeCode = getPaymentType(paymentMethod.payment_method_type_id, paymentMethod.unclaimed);

                    var options = ['1','1','0','0','1','1','0', '1'];

                    commands.push(['E', _.toString(paymentMethod.id), methodName, methodShortName, '1.00', '1', paymentTypeCode.id, options.join('')]);
                });

                // Display message
                if (options.display_message) {
                    var displayMessage = _.truncate(cleanUpSpecialChars(options.display_message), {length: 70, omission: ''});

                    commands.push(['<', '1', '0', displayMessage, '10']);
                } else {
                    commands.push(['<', '0', '1', '', '10']);
                }

                //Header Setup
                var headerLines = ['H'];

                _.forIn(options, function(option, o) {
                    if (!_.isUndefined(option)) {
                        // Headers (maxHeaderLines x 48 chars)
                        var matches = o.match(/header\d+$/);

                        if (matches) {
                            var lineMatch = _.head(matches).match(/\d+$/);
                            var lineNum = _.toInteger(lineMatch[0]);
                            var lineText = _.truncate(cleanUpSpecialChars(option), { length: printerSocket.capabilities.textColumns, omission: '' });

                            if (lineNum <= 8) {
                                headerLines.push('0'); //Double Height for first line
                                headerLines.push(lineText); //Text
                            }
                        }
                    }
                });

                commands.push(headerLines);

                return commands;
            });
        });
    }

    var lastReceiptInfo = function(printerSocket) {
        var d = $q.defer();
        var result = {};

        sendCommands(printerSocket, ['t', 'i', 'X']).then(function(receiptsData) {
            var dateResponse = receiptsData.shift();
            var date = moment([dateResponse[0], dateResponse[1]].join(' '), 'DDMMYY HHmmss').toISOString();
            var dailyClosingNumber = receiptsData.shift()[2];
            var receiptNumber = receiptsData.shift()[1];

            _.assign(result, {
                printer_serial: printerSocket.printerSerial,
                sequential_number: ((_.toInteger(dailyClosingNumber) + 1) * 10000) + _.toInteger(receiptNumber),
                date: date
            });

            getDgfeData(printerSocket, 'READ_RECEIPT', { receiptNumber: receiptNumber }).then(function(lines) {
                _.assign(result, {
                    document_content: lines.join('\n')
                });
            }).finally(function() {
                d.resolve(result);
            });
        }, d.reject); 

        return d.promise;
    };

    var getDgfeData = function (printerSocket, mode, options) {
        var d = $q.defer();
        var dgfeCommand;

        switch(mode) {
            case 'READ_RECEIPT':
                dgfeCommand = ['@', '1', '0', '', options.receiptNumber, options.receiptNumber, '', '', '0'];
            break;
            case 'READ_RANGE':
                dgfeCommand = ['@', '3', '', '', '','', options.dateFrom, options.dateTo, '0'];
            break;
            case 'PRINT_RANGE':
                dgfeCommand = ['@', '3', '', '', '','', options.dateFrom, options.dateTo, '1'];
            break;
            default:
            break;
        }

        if(dgfeCommand) {
            sendCommands(printerSocket, [dgfeCommand]).then(function(responses) {
                var dgfeRows = [];
    
                if(mode === 'PRINT_RANGE') {
                    d.resolve(responses);
                } else {
                    async.until(function () { return false; }, function(rowDone) {
                        sendCommands(printerSocket, ['*']).then(function(rowResp) {
                            dgfeRows.push(rowResp.shift().join('/'));

                            rowDone();
                        }, rowDone);
                    }, function (err, results) {
                        if (err === 'DATA_READ_FINISHED') {
                            d.resolve(dgfeRows);
                        } else {
                            d.reject(err);
                        }
                    });
                }
        
            }, d.reject);
        } else {
            d.reject('INVALID_MODE');
        }

        return d.promise;
    };

    var getAEXml = function(printerSocket) {
        var d = $q.defer();
        var lastClosureCommand = ['i'];
        var dailyClosingNumber;

        async.retry({ times: 7, interval: 0, errorFilter: function(err) { return _.includes(['REQ_TIMEOUT', 'BUSY'], err); } }, function(cb) {
            var errHandler = function(err) {
                if(err === 'BUSY') {
                    $timeout(function() { cb(err); }, 15000, false);
                } else {
                    cb(err);
                }
            };

            sendCommands(printerSocket, lastClosureCommand, { timeout: 15000 }).then(function(results) {
                dailyClosingNumber = results.shift()[2];
                var xmlCommand = [['1', '98', '3', dailyClosingNumber, dailyClosingNumber, '0']];

                sendCommands(printerSocket, xmlCommand, { waitString: '<<ENDE>>', timeout: 15000 }).then(function(results) {
                    results.shift();
                    var commandOutput = results.join('');
                    var AEXmlMatch = commandOutput.match(/<\?xml[\s\S]*<\/p:DatiCorrispettivi>/);

                    if(AEXmlMatch) {
                        cb(null, AEXmlMatch[0]);
                    } else {
                        cb('CANNOT_PARSE_FILE');
                    }
                }, errHandler);
            }, errHandler);
        }, function(err, res) {
            if(err) {
                d.reject(err);
            } else {
                var parser = new DOMParser();
                var xml = parser.parseFromString(res,"text/xml");
                var RTData = xml.getElementsByTagName("DatiRT");

                if(!_.isEmpty(RTData)) {
                    var parsedDate = _.get(xml.getElementsByTagName("DataOraRilevazione"), [0, 'textContent']);
                    var date = new Date(parsedDate);

                    d.resolve({
                        document_content: res,
                        sequential_number: _.toInteger(dailyClosingNumber),
                        date: _.isDate(date) ? date.toISOString() : new Date().toISOString()
                    });
                } else {
                    d.reject("CANNOT_PARSE_FILE");
                }
            }
        });

        return d.promise;
    };

    function getQueueCouponNonFiscal(sale, capabilities) {
        var commands = [['7','1','20','']];

        _.forEach(fiscalUtils.getQueueCouponRows(sale, capabilities.textColumns, { doubleWidth: true }), function(row) {
			commands.push(['7', '1', row.doubleHeight ? '4' : '2', cleanUpSpecialChars(row.text)]);
		});

        commands.push("m");

        return commands;
	}

    /**     
     *  Public methods     
     */
    return {
        /**
         * @desc setup RCH Print!F printer
         *
         * @param printer, the tilby fiscal printer (must be an RCH Print!F obviously)
         * @param options.print_notes: boolean (default true), if true prints the non-fiscal lines above each sale_item (for ex. variations, or combinations)
         * @param options.print_name: boolean (default true), if true prints the sale.name on top
         * @param options.print_barcode_id: boolean (default true), if true, prints a barcode on bottom that contains the sale.id
         * @param options.ticket_change: boolean (default true), if true enables ticket change
         * @param options.print_customer_detail: boolean (default true), if true print TAX_CODE, FIRST_NAME, LAST_NAME, COMPANY_NAME, VAT_CODE, FIDELITY
         * @param options.print_seller: boolean (default true), if true print seller
         * @param options.seller_prefix: string, customize seller phrase
         * @param options.tail: string, general tail for the receipts
         * 
         * @param printer.id: integer, the printer id
         * @param printer.name: string, the printer name
         * @param printer.driver: string, must be 'rch in this case'
         * @param printer.connection_type: string, can be 'ws' (for webservice mode) or 'bt' (for bluetooth mode)
         * @param printer.ip_address: string, printer IP (if using webservice mode)
         * @param printer.mac_address_bt: string, printer MAC ADDRESS BLUETOOTH (if using bluetooth mode)         
         */
        setup: function(printer, options) {
            if (!printer) {
                throw "Printer is undefined";
            } else if (printer.driver !== 'axon') {
                throw "Wrong driver";
            }  else if (!printer.connection_type) {
                throw "Missing connection_type";
            }  else if (!printer.ip_address) {
                throw "Missing ip_address";
            }

            _.assign(scope, {
                printer: printer,
                options: options
            });
        },

        getPrinterStatus: function() {
            var d = $q.defer();
            var result = {};

			isAvailable(scope.printer).then(function(printerSocket) {
                //TODO: add periodic check and inactive period
                var commands = [
                    [',', '10'], //RT Status
                    //[',', '13'], //RT Daily Closing Status
                ];

                sendCommands(printerSocket, commands).then(function(responses) {
                    var rtStatus = responses.shift();
                    //var rtDCStatus = responses.shift();
                    var fpStatus = _.fill(Array(5), '0');

                    fpStatus[0] = printerSocket.deviceStatus.paperEnd ? '3' : '0';

                    if(rtStatus[0] === '0') {
                        _.assign(result, { rtMainStatus: '01', rtSubStatus: '05' });
                    } else {
                        _.assign(result, { rtMainStatus: '02', rtSubStatus: '08' });
                    }

                    _.assign(result, {
                        fpStatus: fpStatus.join(''),
                        cpuRel: printerSocket.printerFirmware,
                        rtType: 'M',
                        //rtFileToSend: rtDCStatus[0],
                        printer_serial: printerSocket.printerSerial
                    });

                    closeSocket(printerSocket, function() { d.resolve(result); });
                }, function(error) {
                    closeSocket(printerSocket, function() { d.reject(error); });
                });
			}, d.reject);

            return d.promise;
		},

        /**
         *  @desc automagically configure RCH Print!F
         *
         *  ( /printers )
         *  @param printer, the printer you want to configure
         *  @param printer.invoice_prefix (from printer)
         *  @param printer.printer_number (from printer)
         *
         *  ( /shop_preferences )
         *  
         *  @param options.header1 (max 48 chars - receipt + invoice)
         *  @param options.header2 (max 48 chars - receipt + invoice))
         *  @param options.header3 (max 48 chars - receipt + invoice))
         *  @param options.header4 (max 48 chars - receipt + invoice))
         *  @param options.header5 (max 48 chars - receipt + invoice))
         *  @param options.header6 (max 48 chars - receipt + invoice))
         *
         *  @param options.header7 (max 48 chars - invoice only)
         *  @param options.header8 (max 48 chars - invoice only)
         *  @param options.header9 (max 48 chars - invoice only)
         *  @param options.header10 (max 48 chars - invoice only)
         *  @param options.header11 (max 48 chars - invoice only)
         *  @param options.header12 (max 48 chars - invoice only)
         *  @param options.header13 (max 48 chars - invoice only)
         *  
         *  @param options.invoice_footer1 (max 48 chars)
         *  @param options.invoice_footer2 (max 48 chars)
         *  @param options.invoice_footer3 (max 48 chars)
         *  @param options.invoice_footer4 (max 48 chars)
         *  @param options.invoice_footer5 (max 48 chars)
         *  @param options.invoice_footer6 (max 48 chars)
         *              
         *  @param options.display_message
         * 
         */
        autoConfigurePrinter: function(printer, options) {
            var d = $q.defer();

            // Configuring via TCP SOCKET
            isAvailable(printer).then(function(printerSocket) {
                console.log("Autoconfiguring " + scope.printer.name + " " + scope.printer.ip_address + " via tcp...");

                configurationToCommands(printerSocket, options).then(function(commands) {
                    sendCommands(printerSocket, commands).then(function(responses) {
                        closeSocket(printerSocket, function() { d.resolve('OK'); });
                    }, function(error) {
                        closeSocket(printerSocket, function() { d.reject(error); });
                    });
                }, function(error){
                    closeSocket(printerSocket, function() { d.reject(error); });
                });
            });

            return d.promise;
        },

        /**
         * @desc print a commercial document (also known as 'documento commerciale', for italians)
         *
         * @param sale - the tilby sale you want to print
         * @param options.can_open_cash_drawer - true if user has permission, false otherwise
         * @param options.tail: string, print this string at the end of receipt
         * @param options.print_details: if false, print only department totals and hide single items and discount details
         *
         * @return successFunction with printed sale or errorFunction with errors
         */
        printFiscalReceipt: function(sale, options, successFunction, errorFunction) {
            isAvailable(scope.printer).then(function(printerSocket) {
                const isRefundVoid = fiscalUtils.isRefundVoidSale(sale);

                var printAdditionalDocuments = function() {
                    async.waterfall([function(callback) {
                        if(!isRefundVoid && checkQueueType('non_fiscal')) {
                            sendCommands(printerSocket, getQueueCouponNonFiscal(sale, printerSocket.capabilities)).then(function() {
                                callback();
                            }, function() {
                                callback();
                            });
                        } else {
                            callback();
                        }
                    }], function(err, results) {
                        closeSocket(printerSocket);
                    });
                };

                // Build protocol commands
                var commands = saleToReceiptCommands(sale, options, printerSocket.capabilities);
    
                if (!_.isArray(commands)) {
                    closeSocket(printerSocket, function() { errorFunction(commands); });
                } else {
                    // Open cash drawer
                    var openCashdrawer = (options.can_open_cash_drawer === false) ? false :true;
        
                    if (openCashdrawer) {
                        commands.push(['q', '1']);
                    }
    
                    var documentType = 'commercial_doc';
        
                    if (isRefundVoid) {
                        //We only need to check a single sale item, since the cause check has been done in the saleToReceiptCommands function
                        var saleItemToCheck = _.head(sale.sale_items);
    
                        switch(saleItemToCheck.refund_cause_id) {
                            case 6:
                                documentType = "void_doc";
                                break;
                            default:
                                documentType = "refund_doc";
                        }
                    }
                    errorsLogger.debug("Print fiscal receipt on a " + scope.printer.name + " " + scope.printer.ip_address + " via tcp...");
    
                    // Print Fiscal Receipt
                    sendCommands(printerSocket, commands).then(function(message) {
                        lastReceiptInfo(printerSocket).then(function(documentData) {
                            _.assign(documentData, {
                                document_type: documentType
                            });

                            successFunction([documentData]);
                            printAdditionalDocuments();
                        }, function(error) {
                            successFunction([]);
                            printAdditionalDocuments();
                        });
                    }, function(error) {
                        closeSocket(printerSocket, function() { errorFunction(error); });
                    });
                }
            }, errorFunction);
        },

        /**
         * @desc print a courtesy receipt (also known as 'scontrino di cortesia', for italians)
         *
         * @param sale - the tilby sale you want to print
         *
         * @return successFunction with printed sale or errorFunction with errors
         */
        printCourtesyReceipt: function(sale, options, successFunction, errorFunction) {
            isAvailable(scope.printer).then(function(printerSocket) {
                // Build protocol commands
                var commands = saleToCourtesyReceiptCommands(sale, printerSocket.capabilities);

                errorsLogger.debug("Print courtesy receipt on a " + scope.printer.name + " " + scope.printer.ip_address + " via tcp...");

                sendCommands(printerSocket, commands).then(function(responses) {
                    closeSocket(printerSocket, function() { successFunction('OK'); });
                }, function(error) {
                    closeSocket(printerSocket, function() { errorFunction(error); });
                });
            }, errorFunction);
        },

        /**
         * @desc print a non fiscal receipt (also known as 'preconto', for italians)
         *
         * @param sale - the tilby sale you want to print         
         *
         * @return successFunction with printed sale or errorFunction with errors
         */
        printNonFiscal: function(sale, options, successFunction, errorFunction) {
            isAvailable(scope.printer).then(function(printerSocket) {
                // Build protocol commands
                var commands = saleToNonFiscalCommands(sale, options, printerSocket.capabilities);
                errorsLogger.debug("Print non fiscal receipt on a " + scope.printer.name + " " + scope.printer.ip_address + " via tcp...");

                sendCommands(printerSocket, commands).then(function(responses) {
                    closeSocket(printerSocket, function() { successFunction('OK'); });
                }, function(error) {
                    closeSocket(printerSocket, function() { errorFunction(error); });
                });
            }, errorFunction);
        },

        /**
         * @desc open cash drawer
         * 
         * @return successFunction if drawer is correctly opened or errorFunction with errors
         */
        openCashDrawer: function(successFunction, errorFunction) {
            var commands = [['q', '1']];

            isAvailable(scope.printer).then(function(printerSocket) {
                errorsLogger.debug("Opening cash drawer on a " + scope.printer.name + " " + scope.printer.ip_address + " via tcp...");

                sendCommands(printerSocket, commands).then(function(responses) {
                    closeSocket(printerSocket, function() { successFunction('OK'); });
                }, function(error) {
                    closeSocket(printerSocket, function() { errorFunction(error); });
                });
            }, errorFunction);
        },

        /**
		 * @desc readDgfeBetween
		 *
		 * @param mode can be 'print' or 'read' ('read' mode works only in webservice mode)
		 * @param from: start date, must be string in format DDMMAA
		 * @param to: end date, must be string in format DDMMAA
		 *
		 * @return promise
		 */
		readDgfeBetween: function(mode, from, to) {
            var d = $q.defer();

            isAvailable(scope.printer).then(function(printerSocket) {
                getDgfeData(printerSocket, mode === 'print' ? 'PRINT_RANGE' : 'READ_RANGE', { dateFrom: from, dateTo: to}).then(function(result) {
                    closeSocket(printerSocket, function() { d.resolve(result.join('\n')); });
                }, function(error) {
                    closeSocket(printerSocket, function() { d.reject(error); });
                });
            }, d.reject);

            return d.promise;
		},

        /**
		 * @desc printFiscalMemoryBeetween
		 *
		 * @param corrispettivi: boolean, if true prints 'corrispettivi'
		 * @param from: start date, must be string in format DDMMAA
		 * @param to: end date, must be string in format DDMMAA
		 *
		 * @return promise
		 */
		printFiscalMemoryBetween: function(corrispettivi, from, to) {
            var d = $q.defer();
            var commands = [['k', from, to, corrispettivi ? '1' : '0']];
            //TOOD: save closing info and vat

            isAvailable(scope.printer).then(function(printerSocket) {
                errorsLogger.debug("Fiscal memory read on a " + scope.printer.name + " " + scope.printer.ip_address + " via tcp...");

                sendCommands(printerSocket, commands).then(function(responses) {
                    closeSocket(printerSocket, function() { d.resolve({}); });
                }, function(error) {
                    closeSocket(printerSocket, function() { d.reject(error); });
                });
            }, d.reject);

            return d.promise;
		},

        /**
         * @desc do a daily closing
         * 
         * @return successFunction  (with the closing receipt in ws mode, or 'OK' in bt mode) if close is correctly done or errorFunction with errors
         */
        dailyClosing: function(successFunction, errorFunction) {
            var commands = [['x', '7', '', '','','','']];
            //TOOD: save closing info and vat

            isAvailable(scope.printer).then(function(printerSocket) {
                errorsLogger.debug("Daily closing on a " + scope.printer.name + " " + scope.printer.ip_address + " via tcp...");
                var result = {};
                
                sendCommands(printerSocket, commands).then(function(responses) {
                    var waitPromise = getAEXml(printerSocket).then(function(dailyClosingData) {
                        _.assign(result, dailyClosingData, {
                            printer_serial: printerSocket.printerSerial
                        });
                    });

                    waitPromise.finally(function() {
                        closeSocket(printerSocket, function() { successFunction(result); });
                    });

                    waitDialog.show({ message: $translate.instant('PRINTERS.SENDING_AE_XML'), timeout: 110, promise: waitPromise });
                }, function(error) {
                    closeSocket(printerSocket, function() { errorFunction(error); });
                });
            }, errorFunction);
        },

        /**
         * @desc do a daily read
         * 
         * @return promise
         */
        dailyRead: function() {
            var d = $q.defer();
            var commands = [['x', '1', '', '','','','']];

            isAvailable(scope.printer).then(function(printerSocket) {
                errorsLogger.debug("Daily read on a " + scope.printer.name + " " + scope.printer.ip_address + " via tcp...");
                
                sendCommands(printerSocket, commands).then(function(responses) {
                    closeSocket(printerSocket, function() { d.resolve({}); });
                }, function(error) {
                    closeSocket(printerSocket, function() { d.reject(error); });
                });
            }, d.reject);

            return d.promise;
        },

        /**
         * @description printFreeNonFiscal
         */
        printFreeNonFiscal: (lines, options) => new Promise((resolve, reject) => {
            isAvailable(scope.printer).then(function(printerSocket) {
                var freeCommands = linesToCommands(lines, printerSocket.capabilities.textColumns);

                errorsLogger.debug(`Print free non-fiscal on a ${scope.printer.name} ${scope.printer.ip_address} via tcp...`);

                sendCommands(printerSocket, freeCommands).then(function(responses) {
                    closeSocket(printerSocket, function() { resolve(); });
                }, function(error) {
                    closeSocket(printerSocket, function() { reject(error); });
                });
            }, reject);
        }),

        /**
         * isReachable
         */
        isReachable: function(successFunction, errorFunction) {
            isAvailable(scope.printer).then(function(printerSocket) {
                closeSocket(printerSocket, function() { successFunction(); });
            }, errorFunction);
        }
    };
}

AxonRTDriver.$inject = ["$q", "$window", "$translate", "$timeout", "fiscalUtils", "util", "waitDialog", "errorsLogger", "checkManager", "salePrintingUtils"];

angular.module('printers').factory('AxonRTDriver', AxonRTDriver);
import {
    Component,
    Injectable,
    WritableSignal,
    inject,
    signal
} from '@angular/core';

import {
    CommonModule
} from '@angular/common';

import {
    CustomForm,
    CustomFormControl,
    CustomFormControlProps,
    CustomFormGroup,
    KeyValueIconImage,
    TilbyMagicFormComponent
} from "@tilby/tilby-ui-lib/components/tilby-magic-form";

import {
    MatButtonModule
} from "@angular/material/button";

import {
    MatDialog,
    MatDialogRef
} from "@angular/material/dialog";

import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogContentComponent,
    TilbyDialogProgressBarComponent,
    TilbyDialogToolbarComponent
} from '@tilby/tilby-ui-lib/components/tilby-dialog';

import {
    lastValueFrom
} from 'rxjs';

import {
    Validators
} from '@angular/forms';

import {
    TilbyDatePipe
} from '@tilby/tilby-ui-lib/pipes/tilby-date';

import {
    CashMovements,
    PaymentMethods,
    Printers
} from 'tilby-models';

import {
    FiscalProviders,
    RootScope,
    addSelectCustomerDialog,
    documentPrinter
} from 'app/ajs-upgraded-providers';

import {
    TilbyCurrencyPipe
} from '@tilby/tilby-ui-lib/pipes/tilby-currency';

import {
    ConfigurationManagerService,
    EntityManagerService,
    UserActiveSessionManagerService
} from 'src/app/core';

import {
    TranslateService
} from '@ngx-translate/core';

import {
    AlertDialogService
} from 'src/app/dialogs';

type TypesChoices = {
    income: 'CASHREGISTER.ADD_CASH_MOVEMENT_DIALOG.INCOMING',
    outcome: 'CASHREGISTER.ADD_CASH_MOVEMENT_DIALOG.OUTCOMING'
}

type AddCashMovementForm = CustomForm<AddCashMovementFormValue>;

type AddCashMovementFormValue = {
    customerName: string,
    customerUuid: string,
    date: string,
    account?: 'cash' | 'other',
    type: keyof TypesChoices,
    amount: number,
    paymentMethodId?: number,
    cardCircuitName?: string,
    description: string,
    printNote?: string,
};

@Component({
    selector: 'app-add-cash-movement-dialog',
    standalone: true,
    imports: [
        CommonModule,
        TilbyDialogContentComponent,
        TilbyDialogToolbarComponent,
        TilbyDialogProgressBarComponent,
        TilbyMagicFormComponent,
        MatButtonModule
    ],
    templateUrl: './add-cash-movement-dialog.component.html',
    styleUrls: ['./add-cash-movement-dialog.component.scss'],
    providers: []
})
class AddCashMovementDialogComponent {
    private readonly $rootScope = inject(RootScope);
    private readonly addSelectCustomerDialog = inject(addSelectCustomerDialog); // OLD DIALOG UPGRADED
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly documentPrinter = inject(documentPrinter);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly fiscalProviders = inject(FiscalProviders);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly tilbyCurrencyPipe = inject(TilbyCurrencyPipe);
    private readonly tilbyDatePipe = inject(TilbyDatePipe);
    private readonly translate = inject(TranslateService);
    private readonly userActiveSessionManagerService = inject(UserActiveSessionManagerService);

    private _defaultPrinter?: Printers;
    private _paymentMethods?: PaymentMethods[];

    private _enableMovementAccounts = true;
    private _printNote = false;
    private _showPrintNote = true;

    protected _operationInProgress: WritableSignal<boolean> = signal(false);

    private _typesChoices = {
        income: 'CASHREGISTER.ADD_CASH_MOVEMENT_DIALOG.INCOMING',
        outcome: 'CASHREGISTER.ADD_CASH_MOVEMENT_DIALOG.OUTCOMING'
    };

    private createForm() {
        const accountsChoices: KeyValueIconImage<string>[] = ['CASHREGISTER', 'OUT_CASHREGISTER'].map((text, i) => ({ key: `CASHREGISTER.ADD_CASH_MOVEMENT_DIALOG.${text}`, value: i ? 'other' : 'cash' }));
        const typesChoices: KeyValueIconImage<string>[] = Object.entries(this._typesChoices).map(([key, value]) => ({ key: value, value: key }));
        const paymentMethodChoices: KeyValueIconImage<number>[] = this._paymentMethods?.map((pMethod) => ({ key: pMethod.name, value: pMethod.id, disableTranslate: true })) || [];

        this._form = new CustomFormGroup<AddCashMovementForm>({
            customerUuid: new CustomFormControl(
                undefined,
                {},
                { ...new CustomFormControlProps(), inputType: 'hidden' }
            ),
            date: new CustomFormControl(
                { value: TilbyDatePipe.date(), disabled: false },
                { validators: [Validators.required] },
                { ...new CustomFormControlProps(), label: "CASHREGISTER.ADD_CASH_MOVEMENT_DIALOG.DATE_AND_TIME", inputType: 'datetime', class: "tw-w-5/12" }
            ),
            ...(this._enableMovementAccounts && {
                account: new CustomFormControl(
                    { value: 'cash', disabled: false },
                    { validators: [Validators.required] },
                    { ...new CustomFormControlProps(), label: "CASHREGISTER.ADD_CASH_MOVEMENT_DIALOG.ACCOUNT", inputType: 'select', inputChoices: accountsChoices, class: "tw-w-5/12" })
            }),
            type: new CustomFormControl(
                { value: undefined, disabled: false },
                { validators: [Validators.required] },
                { ...new CustomFormControlProps(), label: "CASHREGISTER.ADD_CASH_MOVEMENT_DIALOG.TYPE", inputType: 'select', inputChoices: typesChoices, class: "tw-w-5/12" }
            ),
            customerName: new CustomFormControl(
                undefined,
                {},
                { ...new CustomFormControlProps(), label: "CASHREGISTER.ADD_CASH_MOVEMENT_DIALOG.CUSTOMER", inputType: 'text', readonly: true, customActions: { suffix: { icon: "face", callback: () => this.openCustomerDialog() } }, class: "tw-w-5/12" }
            ),
            amount: new CustomFormControl(
                { value: undefined, disabled: false },
                { validators: [Validators.required, Validators.min(0)] },
                { ...new CustomFormControlProps(), label: "CASHREGISTER.ADD_CASH_MOVEMENT_DIALOG.AMOUNT", inputType: 'number', class: "tw-w-5/12", inputConstraint: { min: 0 } }
            ),
            paymentMethodId: new CustomFormControl(
                { value: undefined, disabled: false },
                {},
                { ...new CustomFormControlProps(), label: "CASHREGISTER.ADD_CASH_MOVEMENT_DIALOG.METHOD", inputType: 'hidden', inputChoices: paymentMethodChoices, class: "tw-w-5/12" }
            ),
            cardCircuitName: new CustomFormControl(
                { value: undefined, disabled: false },
                {},
                { ...new CustomFormControlProps(), label: "CASHREGISTER.ADD_CASH_MOVEMENT_DIALOG.CARD_CIRCUIT", inputType: 'hidden', class: "tw-w-5/12" }
            ),
            description: new CustomFormControl({
                value: undefined,
                disabled: false
            }, {}, {
                ...new CustomFormControlProps(),
                label: "CASHREGISTER.ADD_CASH_MOVEMENT_DIALOG.DESCRIPTION",
                class: "tw-w-full"
            }),
            ...(this._showPrintNote && {
                printNote: new CustomFormControl(
                    { value: this._printNote, disabled: false },
                    {},
                    { ...new CustomFormControlProps(), label: "CASHREGISTER.ADD_CASH_MOVEMENT_DIALOG.PRINT_NOTE", inputType: 'slideToggle', class: "tw-w-full" })
            }),
        });

        this.account?.valueChanges.subscribe(account => {
            this.paymentMethodId!.customProps.inputType = (account === 'other') ? 'select' : 'hidden';
            this.cardCircuitName!.customProps.inputType = (account === 'other') ? 'text' : 'hidden';
        });
    }

    protected _form?: CustomFormGroup<AddCashMovementForm>;

    async ngOnInit() {
        this._defaultPrinter = await this.getDefaultPrinter();
        this._paymentMethods = await this.entityManagerService.paymentMethods.fetchCollectionOffline({ payment_method_type_id_in: [3, 4, 5, 6, 8, 11, 12, 13, 14] });

        if (this._defaultPrinter?.type === 'receipt' && this._defaultPrinter.fiscal_provider) {
            this._showPrintNote = false;
            this._printNote = true;
            this._enableMovementAccounts = false;
        }

        this.createForm();
    }

    async openCustomerDialog() {
        try {
            const customer = await this.addSelectCustomerDialog.show();
            if (customer) {
                let customerName = '';
                if (customer.first_name && customer.last_name) {
                    customerName = `${customer.first_name} ${customer.last_name}`;
                } else {
                    customerName = customer.company_name;
                }
                this._form?.controls.customerName.setValue(customerName);
                this._form?.controls.customerUuid.setValue(customer.uuid);
            }
        } catch (error) {
            console.error(error);
        }
    }

    public async confirm() {
        if (!this._form?.valid || this._operationInProgress()) {
            return;
        }

        const { customerUuid: customer_uuid, date, account = 'cash', type, amount, paymentMethodId, cardCircuitName: card_circuit_name, description, printNote: print_note } = this._form.value;

        this._operationInProgress.set(true);

        const dataToSend: CashMovements = {
            customer_uuid,
            date: date as any as Date,
            account,
            type: type!,
            amount: type === 'outcome' ? -(amount || 0) : (amount || 0),
            ...(paymentMethodId && { payment_method_name: this._paymentMethods?.find(pm => pm.id == paymentMethodId)?.name }),
            card_circuit_name,
            description
        };

        let fiscalInformation;

        try {
            //Send to fiscal provider if necessary
            if (this._defaultPrinter?.type === 'receipt' && this._defaultPrinter.fiscal_provider) {
                let fiscalProvider = this.fiscalProviders.getFiscalProvider(this._defaultPrinter.fiscal_provider);

                if (typeof fiscalProvider?.sendCashMovement == 'function') {
                    try {
                        let fiscalTail = await fiscalProvider.sendCashMovement(dataToSend.amount);
                        fiscalInformation = fiscalTail;
                    } catch (error) {
                        if (fiscalProvider.getProviderError) {
                            throw fiscalProvider.getProviderError(error);
                        }

                        throw error;
                    }
                }
            }

            //Print cash movement receipt
            if (print_note) {
                let noteDate = this.tilbyDatePipe.transform(dataToSend.date, 'dd/MM/yyyy HH:mm');
                const { first_name, last_name } = this.userActiveSessionManagerService.getSession() || {};

                let linesToPrint = [
                    this.translate.instant('CASHREGISTER.ADD_CASH_MOVEMENT_DIALOG.MOVEMENT_TYPE', { value: this.translate.instant('CASHREGISTER.ADD_CASH_MOVEMENT_DIALOG.MOVEMENT_TYPE_' + (dataToSend.account === 'cash' ? 'CASH' : 'OTHER')) }),
                    this.translate.instant('CASHREGISTER.ADD_CASH_MOVEMENT_DIALOG.MOVEMENT_DATE', { value: noteDate }),
                    this.translate.instant('CASHREGISTER.ADD_CASH_MOVEMENT_DIALOG.OPERATOR', { first: first_name, second: last_name }),
                    this.translate.instant(this._typesChoices[dataToSend.type as keyof TypesChoices]) + ": " + this.tilbyCurrencyPipe.transform(Math.abs(dataToSend.amount), '', 2) + " EURO"
                ];

                if (dataToSend.account === 'other') {
                    if (dataToSend.payment_method_name) {
                        linesToPrint.push(this.translate.instant('CASHREGISTER.ADD_CASH_MOVEMENT_DIALOG.PAYMENT_TYPE_PRINTER', { value: dataToSend.payment_method_name }));
                    }
                    if (dataToSend.card_circuit_name) {
                        linesToPrint.push(this.translate.instant('CASHREGISTER.ADD_CASH_MOVEMENT_DIALOG.CIRCUIT_PRINTER', { value: dataToSend.card_circuit_name }));
                    }
                }

                if (dataToSend.description) {
                    linesToPrint.push(this.translate.instant('CASHREGISTER.ADD_CASH_MOVEMENT_DIALOG.DESCRIPTION_PRINTER', { value: dataToSend.description }));
                }

                let noteToPrint = linesToPrint.join('\n');

                if (typeof fiscalInformation == 'string') {
                    noteToPrint += ' \n'.repeat(5) + fiscalInformation;
                }

                try {
                    await this.documentPrinter.printFreeNonFiscal(noteToPrint, this._defaultPrinter?.id);
                } catch (err) {
                    await this.alertDialogService.openDialog({ data: { messageLabel: 'CASHREGISTER.ADD_CASH_MOVEMENT_DIALOG.NOTE_NOT_PRINTED' } });
                }
            }

            let res = await this.entityManagerService.cashMovements.postOneOfflineFirst(dataToSend);

            this.$rootScope.$broadcast('cashMovements:created', res);
            this.matDialogRef.close();
        } catch (err) {
            await this.alertDialogService.openDialog({ data: { messageLabel: `${err}` } });
        } finally {
            this._operationInProgress.set(false);
        }
    }

    // FUNTIONS INSIDE RESOLVE ENTITIES
    private async getDefaultPrinter() {
        const defaultPrinterId = parseInt(`${this.configurationManagerService.getPreference('fiscalprinter.def.id') || ''}`);

        if (defaultPrinterId) {
            return this.entityManagerService.printers.fetchOneOffline(defaultPrinterId);
        }
    }

    // GET FORM CONTROLS
    get account() {
        return this._form?.controls.account;
    }

    get paymentMethodId() {
        return this._form?.controls.paymentMethodId;
    }

    get cardCircuitName() {
        return this._form?.controls.cardCircuitName;
    }
}


@Injectable({
    providedIn: 'root'
})
export class AddCashMovementDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog() {
        const config: NonNullableConfigData<any> = {
            ...this.switchMobileDesktopDimensions({ width: '800px' }),
            disableClose: true,
            data: {}
        };

        return lastValueFrom(this.dialogRef.open(AddCashMovementDialogComponent, config).afterClosed());
    }
}

import angular from 'angular';
import _ from 'lodash';
import moment from 'moment-timezone';

angular.module('core').factory('sessionManager', sessionManager); 

sessionManager.$inject = ["$rootScope", "$translate", "$timeout", "errorsLogger", "restManager", "checkManager", "oauth", "environmentInfo", "loginView", "connection", "entityManager", "alertDialog", "confirmDialog", "userSessionsManager", "userActiveSession"];

function sessionManager($rootScope, $translate, $timeout, errorsLogger, restManager, checkManager, oauth, environmentInfo, loginView, connection, entityManager, alertDialog, confirmDialog, userSessionsManager, userActiveSession) {

    let sessionUpdateTimeout; //Used by $timeout for updateLastActiveSession
    let sessionUpdatePromise = Promise.resolve();

    const saveSessionData = async function(session) {
        await entityManager.userSessions.saveOne(session);

        if(session.shop) {
            await entityManager.shopSession.saveOne(session.shop);
        }
    };

    const logoutUser = async function(userId, accessToken) {
        if (!checkManager.getSetting("skip_invalidate_token")) {
            await oauth.logout(userId, accessToken);
        }
    };

    const logoutMultipleUsers = async function(users) {
        if (!checkManager.getSetting("skip_invalidate_token")) {
            await oauth.logoutAll(users);
        }
    };

    const setSessionData = function(session) {
        userActiveSession.setSession(session);
        restManager.setUser(session.oauthdata);

        try {
            errorsLogger.setContext(session);
        } catch(error) {
            errorsLogger.err(`[sessionManager] Cannot set context`);
        }
    };

    /**
     * Set the user session with all data in case of right login conditions
     * @return {object}     Promise returned for the result of validation
     */
    const setUserSession = async function(session, oauthData) {
        if (!session.id && session.user_id) {
            session.id = _.toInteger(session.user_id);
            delete session.user_id;
        }

        session.active = 1;
        session.oauthdata = oauthData;

        setSessionData(session);

        await saveSessionData(session);
    };

    /**
     * Function that allow runtime setting up for the informations for user and shop session at startup
     * @param  {object}     accessToken runtime object containing al the data for asking user and shop data.
     * @return {object}     Promise returned for the result of validation
     */
    const manageSessionsMultiLogin = async function(accessToken) {
        try {
            let session = await restManager.getUserSession(accessToken.access_token); //Get new session
            let shop = await entityManager.shopSession.getCollection(); //Get current shop session (if exists)
            let oldUser = await entityManager.userSessions.getOne(session.id); //Get current user session (if exists)

            if(_.isEmpty(shop)) {
                if(!_.isEmpty(oldUser)) { //Weird case, do a complete logout for safety reasons 
                    await sessionManager.deepLogoutForAccessTokenLogin();
                }

                await setUserSession(session, accessToken);
            } else {
                let currentShop = _.head(shop);

                if(currentShop.id === _.get(session, ["shop", "id"])) {
                    //Backup shop runtime information
                    _.defaults(session.shop, currentShop);
                    
                    if(_.isEmpty(oldUser)) {
                        await setUserSession(session, accessToken);
                        await entityManager.loadSettings(['userPreferences', 'shopPreferences'], true);
                    } else {
                        await logoutUser(oldUser.id, oldUser.oauthdata.access_token);
                        await setUserSession(session, accessToken);
                    }
                } else {
                    let answer;

                    try {
                        answer = await confirmDialog.show($translate.instant('SESSION_MANAGER.SHOP_SESSION_EXISTS', { currentShop: currentShop.name, newShop: session.shop.name }));
                    } catch(error) {}

                    if(answer) {
                        await sessionManager.deepLogoutForAccessTokenLogin();
                        await setUserSession(session, accessToken);
                    } else {
                        //using oauth directly because we are logging out a wrong login attempt, so the token is discarted regardless
                        await oauth.logout(session.id, accessToken.access_token);
                        sessionManager.login();
                    }
                }
            }
        } catch(error) {
            if (_.get(error, ["data", "error", "message"]) === "Invalid shop") {
                await alertDialog.show($translate.instant('SESSION_MANAGER.INVALID_SHOP'));
                sessionManager.logoutCloseSessionsDeep(false);
            } else {
                throw error;
            }
        }
    };

    const deleteActiveUserSessionAndData = async function() {
        //Wait for current session update to finish
        try {
            await sessionUpdatePromise;
        } catch(e) {}

        $timeout.cancel(sessionUpdateTimeout);

        //Get current user
        let user = await entityManager.userSessions.getActiveSession();

        //Cleanup current user related data from indexedDB
        await entityManager.userSettings.deleteCollectionByIndexOffline(user.id, 'user_id');
        await entityManager.userPreferences.deleteCollectionByIndexOffline(user.id, 'user_id');
        await entityManager.userSessions.deleteOne(user.id);

        //And from local storage
        window.localStorage.removeItem(`initEntity::userSettings::${user.id}`);
        window.localStorage.removeItem(`initEntity::userPreferences::${user.id}`);
        window.localStorage.removeItem(`lastOnline::user::${user.id}`);

        //Return the sessions on the device
        return entityManager.userSessions.getCollection();
    };

    const sessionManager = {
        login: async function(forceNew) {
            if(connection.isOnline()) {
                try {
                    let accessToken = await oauth.checkAccess();
                    await manageSessionsMultiLogin(accessToken);
                } catch(error) {
                    let sessions = await entityManager.userSessions.getCollection();
                    $rootScope.stopWatchdog();
                    $rootScope.hideAppLoader();

                    if(_.isEmpty(sessions) || forceNew) {
                        try {
                            let code = await loginView.show();
                            $rootScope.showAppLoader();
    
                            let accessToken = await oauth.askAccessToken(code);
                            await manageSessionsMultiLogin(accessToken);

                            $rootScope.resetWatchdog();
                        } catch(error) {
                            if(_.isEmpty(sessions)) {
                                if(environmentInfo.isElectronApp()) {
                                    window.close(); //If the login phase is rejected in electron, close the app
                                } else {
                                    await alertDialog.show($translate.instant('SESSION_MANAGER.LOGIN_FAILED'));
                                    return sessionManager.login();
                                }
                            } else {
                                return sessionManager.login();
                            }
                        }
                    } else {
                        return sessionManager.switchUser();
                    }
                }
            } else {
                $rootScope.stopWatchdog();

                await alertDialog.show($translate.instant('SESSION_MANAGER.NO_CONNECTION'));
                $rootScope.resetWatchdog();
                return sessionManager.login(forceNew);
            }
        },
        isLoggedIn: async function() {
            let success = await entityManager.userSessions.getActiveSession();

            if (success) {
                if(success.is_screen_locked) {
                    throw 'SCREEN_LOCKED';
                } else {
                    setSessionData(success);
                    return success;
                }
            } else {
                errorsLogger.info("[ sessionManager.isLoggedIn ] no active session");
                throw 'NO_ACTIVE_SESSION';
            }
        },
        hasShopSession: async function() {
            let sessions = await entityManager.shopSession.getCollection();
   
            if(!_.isEmpty(sessions)) {
                return _.head(sessions);
            } else {
                throw 'NO_SHOP_SESSION';
            }
        },
        refreshTokenActiveSession: async function() {
            let userSession = await entityManager.userSessions.getActiveSession();

            if(userSession) {
                let newOauthData = await oauth.refreshToken(userSession.oauthdata);
                userSession.oauthdata = newOauthData;
                await entityManager.userSessions.saveOne(userSession);

                return newOauthData;
            } else {
                errorsLogger.err("[ sessionManager.refreshTokenActiveSession ] no active session");
                throw 'NO_ACTIVE_SESSION';
            }
        },
        updateLastActiveSession: function() {
            const activeSession = userActiveSession.getSession();

            if (connection.isOnline() && _.isObject(activeSession)) {
                const currentDate = moment().toISOString();

                window.localStorage.setItem(`lastOnline::shop`, currentDate);
                window.localStorage.setItem(`lastOnline::user::${activeSession.id}`, currentDate);
            }

            sessionUpdateTimeout = $timeout(sessionManager.updateLastActiveSession, 60000, false);
        },
        checkLastActiveSession: function() {
            const activeSession = userActiveSession.getSession();
            const lastValidDate = moment().subtract(3, 'days');
            const checkExpiration = (refDate) => refDate.isBefore(lastValidDate);

            let sessionStatus = {
                userExpired: false,
                shopExpired: false
            };

            let shopLastOnline = window.localStorage.getItem('lastOnline::shop');
            let userLastOnline = window.localStorage.getItem(`lastOnline::user::${activeSession.id}`);

            if (userLastOnline) {
                userLastOnline = moment(userLastOnline);

                Object.assign(sessionStatus, {
                    userLastOnline: userLastOnline.toISOString(),
                    userExpired: checkExpiration(userLastOnline)
                });
            }

            if (shopLastOnline) {
                shopLastOnline = moment(shopLastOnline);

                Object.assign(sessionStatus, {
                    shopLastOnline: shopLastOnline.toISOString(),
                    shopExpired: checkExpiration(shopLastOnline)
                });
            }

            return sessionStatus;
        },
        deactivateActiveSessions: async function() {
            let userActiveSessions = await entityManager.userSessions.getAllActiveSessions();

            for(let user of userActiveSessions) {
                user.active = 0;
            }

            await entityManager.userSessions.saveCollection(userActiveSessions);
        },
        activeOrSwitchUserSession: async function(userId) {
            let userActiveSessions = await entityManager.userSessions.getCollection();

            for(let user of userActiveSessions) {
                user.active = user.id === userId ? 1 : 0;
                delete user.is_screen_locked;
            }

            await entityManager.userSessions.saveCollection(userActiveSessions);
        },
        lockScreen: async function() {
            let session = await entityManager.userSessions.getActiveSession();
            
            if(!session.is_screen_locked) {
                session.is_screen_locked = true;
                await saveSessionData(session);
                sessionManager.switchUser();
            }
        },
        switchUser: async function() {
            $rootScope.hideAppLoader();

            const user = await userSessionsManager.show();

            try {
                if(user) {  //Already logged user
                    await sessionManager.activeOrSwitchUserSession(user.id);
                    const activeSession = userActiveSession.getSession();

                    if(!activeSession || user.id !== activeSession.id) {
                        await $rootScope.restartApplication();
                    } else {
                        $rootScope.$broadcast("unlock-screen");
                    }
                } else { //New User
                    let session = await entityManager.userSessions.getActiveSession();
    
                    if(session) {
                        let answer = await confirmDialog.show($translate.instant('SESSION_MANAGER.NEW_LOGIN_CONFIRM'));
    
                        if(!answer) {
                            throw 'CANCELED';
                        }
                    }
    
                    if (connection.isOnline()) {
                        await sessionManager.deactivateActiveSessions();
                        await sessionManager.login(true);
                        
                        await $rootScope.restartApplication();
                    } else {
                        await alertDialog.show($translate.instant('SESSION_MANAGER.NEW_LOGIN_NO_CONNECTION'));

                        throw 'OFFLINE';
                    }
                }
            } catch(error) {
                return sessionManager.switchUser();
            }
        },
        logoutActiveUserSession: async function(askConfirm) {
            let doLogout;

            if (askConfirm) {
                doLogout = await confirmDialog.show($translate.instant('SESSION_MANAGER.LOGOUT_CONFIRM'));
            } else {
                doLogout = true;
            }

            if(doLogout) {
                try {
                    const isPinRequired = checkManager.getPreference('users.enable_user_logout_pin');

                    if (isPinRequired) {
                        await userSessionsManager.show({ disableNewUser: true, canDismiss: true, selectActiveUser: true });
                    }

                    let otherSessions = await deleteActiveUserSessionAndData();
                    await logoutUser();

                    userActiveSession.unsetSession();

                    if (!_.isEmpty(otherSessions)) {
                        sessionManager.switchUser();
                    } else {
                        $rootScope.restartApplication();
                    }
                } catch(error) {
                    errorsLogger.err("[ sessionManager.logoutActiveUserSession ] Cannot delete current user session");
                }
            }
        },
        logoutCloseSessionsDeep: async function(confirm) {
            let performLogout;

            if(confirm) {
                if (connection.isOnline()) {
                    performLogout = await confirmDialog.show($translate.instant('SESSION_MANAGER.DEEP_LOGOUT_CONFIRM'));

                    if(performLogout) {
                        try {
                            await entityManager.syncAll();
                        } catch(error) {
                            performLogout = await confirmDialog.show($translate.instant('SESSION_MANAGER.DEEP_LOGOUT_SYNC_ERROR_CONFIRM'));
                        }
                    }
                } else {
                    performLogout = await confirmDialog.show($translate.instant('SESSION_MANAGER.DEEP_LOGOUT_OFFLINE_CONFIRM'));
                }
            } else {
                performLogout = true;
            }

            if(performLogout) {
                const isPinRequired = checkManager.getPreference('users.enable_user_logout_pin');

                if (isPinRequired) {
                    await userSessionsManager.show({ disableNewUser: true, canDismiss: true, selectActiveUser: true });
                }

                try {
                    await sessionUpdatePromise;
                } catch(error) {}
    
                $timeout.cancel(sessionUpdateTimeout);
                $rootScope.showAppLoader();
    
                try {
                    let userSessions = await entityManager.userSessions.getCollection();
    
                    await logoutMultipleUsers(userSessions);
                    await entityManager.shopSession.destroyStorage();
                    $rootScope.restartApplication();
                } catch(error) {
                    $rootScope.hideAppLoader();
                    alertDialog.show($translate.instant('SESSION_MANAGER.LOGOUT_CRITICAL_ERROR'), { blocking: true });
                }
            }
        },
        checkAccessTokenFromUrl: function() {
            return oauth.checkAccessTokenFromUrl();
        },
        loginWithAccessToken: function(access_token) {
            return manageSessionsMultiLogin({ access_token: access_token });
        },
        deepLogoutForAccessTokenLogin: async function() {
            try {
                await entityManager.shopSession.destroyStorage();
                userActiveSession.unsetSession();
            } catch(error) {
                alertDialog.show($translate.instant('SESSION_MANAGER.LOGOUT_CRITICAL_ERROR'), { blocking: true });

                throw error;
            }
        }
    };

    return sessionManager;
}
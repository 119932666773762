import angular from 'angular';
import _ from 'lodash';
import { REFUND_CAUSES as refundCauses } from 'src/app/core/constants';

angular.module('history').directive('historySale', () => ({
    restrict: 'E',
    replace: true,
    scope: {
        sale: "="
    },
    template: require('./history-sale.html'),
    controller: historySaleController
}));

historySaleController.$inject = ["$scope", "$filter", "$rootScope", "$mdSidenav", "$translate", "radioListSelector", "saleDocumentsViewer", "checkManager", "entityManager", "documentPrinter", "FiscalPrinters", "printerErrorFiscal", "refundItemsSelector", "alertDialog", "waitDialog", "HistoryUtils", "util", "FiscalProviders"];

function historySaleController($scope, $filter, $rootScope, $mdSidenav, $translate, radioListSelector, saleDocumentsViewer, checkManager, entityManager, documentPrinter, FiscalPrinters, printerErrorFiscal, refundItemsSelector, alertDialog, waitDialog, HistoryUtils, util, FiscalProviders) {
    Object.assign($scope, {
        printInProgress: false
    });

    $scope.closeSidenav = function() {
        $mdSidenav('historySale').close();
    };

    $scope.getCustomerCaption = () => $scope.sale.customer_tax_code || util.getCustomerCaption($scope.sale.sale_customer);

    $scope.isEInvoice = () => $scope.sale?.e_invoice?.invoice_progressive ? true : false;

    $scope.priceChangeDescription = (price_change) => {
        if (!price_change.description) {
            switch(price_change.type) {
                case 'discount_fix':
                    return $translate.instant('HISTORY.SALE.DISCOUNT');
                case 'surcharge_fix':
                    return $translate.instant('HISTORY.SALE.SURCHARGE');
                case 'discount_perc':
                    return $translate.instant('HISTORY.SALE.DISCOUNT_PERCENTAGE', { value: price_change.value });
                case 'surcharge_perc':
                    return $translate.instant('HISTORY.SALE.SURCHARGE_PERCENTAGE', { value: price_change.value });
                case 'gift':
                    return $translate.instant('HISTORY.SALE.GIFT');
                default:
                    break;
            }
        } else {
            return _.endsWith(price_change.type, '_perc') ? `${price_change.description} (${price_change.value}%)` : price_change.description;
        }
    };

    const createSale = async (targetSale, saleType, refundCause, saleCustomer, options) => {
        let refSeqNum;
        let refDate;

        if(!_.isObject(options)) {
            options = {};
        }

        if(!_.isEmpty(targetSale.sale_documents)) {
            refSeqNum = _.head(targetSale.sale_documents).sequential_number;
            refDate = _.head(targetSale.sale_documents).date;
        }

        let saleItems = _.chain(targetSale.sale_items)
            .cloneDeep()
            .reject((saleItem) => ['refund', 'gift'].includes(saleItem.type))
        .value();

        if(!['void_doc', 'e_credit_note'].includes(saleType)) {
            saleItems = await refundItemsSelector.show(saleItems);
        }

        for(let saleItem of saleItems) {
            Object.assign(saleItem, {
                quantity: -saleItem.quantity,
                type: "refund",
                refund_cause_id: refundCause.id,
                refund_cause_description: $translate.instant(refundCause.translation_id),
                reference_sequential_number: refSeqNum,
                reference_date: refDate
            });
        }

        let salePayments = targetSale.payments.map((payment) => ({
            amount: -payment.amount,
            date: new Date().toISOString(),
            payment_method_id: payment.payment_method_id,
            payment_method_name: payment.payment_method_name,
            payment_method_type_id: payment.payment_method_type_id,
            payment_method_type_name: payment.payment_method_type_name,
            payment_condition_code: payment.payment_condition_code,
            payment_data: [33].includes(payment.payment_method_type_id) ? payment.payment_data : null
        }));

        return HistoryUtils.createSale({
            e_invoice: options.addInvoice ? targetSale.e_invoice : null,
            name: targetSale.name,
            sale_items: saleItems,
            sale_customer: saleCustomer,
            payments: salePayments,
            overrides: {
                sale_parent_id: targetSale.id,
                sale_parent_uuid: targetSale.uuid
            }
        }, {
            saleType: saleType,
            returnOnly: options.returnOnly
        });
    };

    const closeVoidSale = (sale) => {
        Object.assign(sale, {
            closed_at: new Date().toISOString(),
            status: 'closed'
        });

        entityManager.sales.postOneOfflineFirst(sale);
    };

    const voidRTCommercialDocument = async (targetSale, rtPrinters, printerSerial, refundCause, saleCustomer) => {
        let printPromise = new Promise(async (resolve, reject) => {
            try {
                //Query all RT printers to find which one printed the document
                let targetPrinter;

                for(let printer of rtPrinters) {
                    try {
                        let status = await FiscalPrinters.getPrinterStatus(printer);

                        if(status.printer_serial === printerSerial) {
                            targetPrinter = printer;
                            break;
                        }
                    } catch(err) {
                        //Nothing to do
                    }
                }

                if(!targetPrinter) {
                    throw 'SALE.PRINTER_NOT_AVAILABLE';
                }

                let sale = await createSale(targetSale, 'void_doc', refundCause, saleCustomer, { returnOnly: true });
                $rootScope.$broadcast("wait-dialog:update-state", { message: $translate.instant('HISTORY.SALE.DOCUMENT_CANCEL_IN_PROGRESS') });

                try {
                    await documentPrinter.printDocument(sale, { printer: targetPrinter, document_type: { id: 'fiscal_receipt' } });
                    closeVoidSale(sale);
                    resolve();
                } catch(err) {
                    reject({ printError: err, printerId: targetPrinter.id });
                }
            } catch(err) {
                reject(err);
            }
        });

        try {
            await waitDialog.show({ message: $translate.instant('HISTORY.SALE.SEARCHING_PRINTER'), promise: printPromise });
            $rootScope.$broadcast('history:document-void');
        } catch(error) {
            //Show eventual errors after the waitDialog has been closed
            if(_.isString(error)) {
                alertDialog.show($translate.instant(`HISTORY.${error}`));
            } else if(error?.printError) {
                printerErrorFiscal.show(error.printError, { printerId: error.printerId });
            }
        }
    };

    const voidFiscalProviderDocument = async (targetSale, fiscalProviderDocument, printers, refundCause, saleCustomer) => {
        let fiscalProviderName = fiscalProviderDocument.meta?.fiscal_provider;

        //Use the default printer or find a printer with the fiscal provider that emitted the sale
        let fiscalProviderPrinter = _.find(printers, { id: _.toInteger(checkManager.getPreference('fiscalprinter.def.id')), fiscal_provider: fiscalProviderName }) || _.find(printers, { type: 'receipt', fiscal_provider: fiscalProviderName });

        if(!fiscalProviderPrinter) {
            throw 'SALE.PRINTER_NOT_AVAILABLE';
        }

        let fiscalProvider = FiscalProviders.getFiscalProvider(fiscalProviderName);

        if(!fiscalProvider) {
            throw 'SALE.INVALID_FISCAL_PROVIDER';
        }

        if(refundCause.id === 6) { //VOID
            if(!_.isFunction(fiscalProvider.voidFiscalDocument)) {
                throw 'SALE.CANNOT_CANCEL_DOCUMENT';
            }

            const nonFiscalDocument = targetSale.sale_documents?.find((saleDocument) => !['fiscal_provider', 'attachment'].includes(saleDocument.document_type));

            if(_.isEmpty(nonFiscalDocument)) {
                throw 'SALE.CANNOT_CANCEL_DOCUMENT';
            }

            let receiptDocuments = await entityManager.nonfiscalDocuments.fetchCollectionOffline({ type: nonFiscalDocument.document_type });

            if(_.isEmpty(receiptDocuments)) {
                throw 'SALE.CANNOT_CANCEL_DOCUMENT';
            }

            let voidSale = await createSale(targetSale, 'void_doc', refundCause, saleCustomer, { returnOnly: true });

            let documentConfig = {
                document_template: receiptDocuments[0],
                document_type: { id: nonFiscalDocument.document_type },
                printer: fiscalProviderPrinter,
                reference_fiscal_document: fiscalProviderDocument
            };

            let voidPromise = new Promise(async (resolve, reject) => {
                try {
                    await documentPrinter.printDocument(voidSale, documentConfig);
                    closeVoidSale(voidSale);
                    resolve();
                } catch(error) {
                    reject({ printError: error, printerId: fiscalProviderPrinter.id });
                }
            });

            try {
                await waitDialog.show({ message: $translate.instant('HISTORY.SALE.DOCUMENT_CANCEL_IN_PROGRESS'), promise: voidPromise });
                $rootScope.$broadcast('history:document-void');
            } catch(error) {
                //Show eventual errors after the waitDialog has been closed
                if(error?.printError) {
                    printerErrorFiscal.show(error.printError, { printerId: error.printerId });
                }
            }
        } else {
            //TODO: stub implementation: this needs to be finished as soon as we have a fiscal provider that allows refunds
            if(!_.isFunction(fiscalProvider.refundFiscalDocument)) {
                throw 'SALE.CANNOT_CANCEL_DOCUMENT';
            }

            createSale(targetSale, 'refund_doc', refundCause, saleCustomer);
        }
    };

    $scope.refundVoid = async () => {
        if(!_.isObject($scope.sale)) {
            return;
        }

        try {
            if(!checkManager.isModuleEnabled('cashregister')) {
                throw 'CASHREGISTER_MODULE_NOT_ENABLED';
            }

            let targetSale = _.cloneDeep($scope.sale);
            let saleCustomer = _.isEmpty(targetSale.sale_customer) ? targetSale.customer_tax_code : _.cloneDeep(targetSale.sale_customer);

            //Ask refund cause to the user
            let refCauses = _.cloneDeep(refundCauses);

            for(let cause of refCauses) {
                cause.name = $translate.instant(cause.translation_id);
            }

            let refundCause = await radioListSelector.show(refCauses, { label: $translate.instant('HISTORY.SALE.REASON') });

            //Determine refund/void actions based on the current document

            //Simply return a e_credit_note if we are dealing with an e-invoice
            if($scope.isEInvoice()) {
                return createSale(targetSale, 'e_credit_note', refundCause, saleCustomer, { addInvoice: true });
            }

            let printers = await entityManager.printers.fetchCollectionOffline();

            //Use dedicated routine if we are dealing with a fiscal provider
            let fiscalProviderDocument = _.find(targetSale.sale_documents, { document_type: 'fiscal_provider' });

            if(fiscalProviderDocument) {
                await voidFiscalProviderDocument(targetSale, fiscalProviderDocument, printers, refundCause, saleCustomer);
                return;
            }

            //Deal with all other cases
            let rtPrinters = _.filter(printers, { type: 'rt' });
            let hasRTPrinter = !_.isEmpty(rtPrinters);
            let docType = targetSale.sale_documents[0]?.document_type;

            switch(docType) {
                case 'commercial_doc':
                    let printerSerial = targetSale.sale_documents[0]?.printer_serial;

                    //We cannot void a commercial document if we don't have the printer serial or a configured RT printer
                    if(refundCause.id === 6 && (!hasRTPrinter || _.isEmpty(printerSerial))) {
                        throw 'SALE.CANNOT_CANCEL_DOCUMENT';
                    }

                    switch(refundCause.id) {
                        case 6: //VOID
                            if(_.every(targetSale.sale_items, { price: 0 })) {
                                throw 'SALE.CANNOT_CANCEL_ZERO_RECEIPT';
                            }

                            return voidRTCommercialDocument(targetSale, rtPrinters, printerSerial, refundCause, saleCustomer);
                        default: //Refund
                            return createSale(targetSale, 'refund_doc', refundCause, saleCustomer);
                    }
                break;
                case 'fiscal_receipt': case 'invoice': case 'receipt_invoice': case 'summary_invoice': case 'summary_e_rc': case 'summary_e_nrc': case 'shipping_invoice':
                    if(hasRTPrinter && !checkManager.getPreference("history.override_credit_note_check")) {
                        throw 'SALE.CANNOT_CANCEL_DOCUMENT';
                    }

                    return createSale(targetSale, 'credit_note', refundCause, saleCustomer);
                case 'generic_receipt': case 'generic_invoice': case 'generic_document':
                    return createSale(targetSale, docType, refundCause, saleCustomer);
                default:
                break;
            }
        } catch(err) {
            if(_.isString(err)) {
                let translateOptions;

                if(err === 'SALE.CANNOT_CANCEL_ZERO_RECEIPT') {
                    translateOptions = { value: $filter('sclCurrency')(0, undefined, 0) };
                }

                alertDialog.show($translate.instant(`HISTORY.${err}`, translateOptions));
            }
        }
    };

    $scope.getRowPrice = (sale_item) => $filter('sclCurrency')(sale_item.price * sale_item.quantity, $scope.sale.$currency, $scope.sale.$digits);

    $scope.showSaleDocuments = function() {
        saleDocumentsViewer.openDialog({
            data: {
                sale: $scope.sale
            }
        });
    };

    $scope.isCreditNote = () => $scope.sale.final_amount < 0;

    $scope.printCourtesyReceipt = async () => {
        if($scope.printInProgress) {
            return;
        }

        try {
            if(!checkManager.isModuleEnabled('cashregister')) {
                throw 'NO_CASHREGISTER_MODULE';
            }

            let printerDefId = _.toInteger(checkManager.getPreference('fiscalprinter.def.id'));

            if (!printerDefId) {
                throw 'NO_DEFAULT_PRINTER';
            }

            $scope.printInProgress = true;

            try {
                await FiscalPrinters.printCourtesyReceipt($scope.sale, printerDefId);
            } catch(error) { //Catches printing error
                printerErrorFiscal.show(error, { printerId: printerDefId });
            } finally {
                $scope.printInProgress = false;
            }
        } catch(err) { //Catches configuration/permission errors
            alertDialog.show($translate.instant(`HISTORY.SALE.${err}`));
        }
    };
}
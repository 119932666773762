<div class="tw-flex tw-flex-row tw-p-4">
    <div class="tw-flex tw-flex-col tw-w-[50%]">
        @if (promotionItemsSearchForm) {
            <tilby-magic-form [form]="promotionItemsSearchForm"></tilby-magic-form>
        }
        <!-- START TABLE ADD-->
        <div class="tw-flex tw-flex-col tw-h-[500px] tw-overflow-x-hidden">
            <div class="tw-flex tw-flex-col">
                <div class="tw-flex tw-flex-row tw-justify-end">
                    <button mat-button (click)="addAll()">
                        {{'PROMOTIONS.DETAILS.ADD_ALL' | translate | uppercase}}
                        <mat-icon iconPositionEnd>arrow_forward</mat-icon>
                    </button>
                </div>
            </div>
            <div class="tw-flex tw-flex-col">
                <table #tableAdd mat-table [dataSource]="dataSourceAdd" matSort class="table-add">

                    <!--ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{column.header}}</th>
                        <td mat-cell *matCellDef="let row"> {{column.cell(row)}} </td>
                    </ng-container-->

                    @for (column of columns; track column) {
                        <ng-container [matColumnDef]="column.columnDef">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{column.header}}</th>
                            <td mat-cell *matCellDef="let el">
                                {{column.cell(el)}}
                            </td>
                        </ng-container>
                    }

                    <!-- Action ADD -->
                    <ng-container matColumnDef="action" stickyEnd>
                        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button (click)="addRow(row)">
                                <mat-icon>arrow_forward</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsAdd; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsAdd;"></tr>

                    <!--tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data found</td>
                    </tr-->
                </table>

                <!--mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of items"></mat-paginator-->
            </div>
        </div>
        <!-- END TABLE ADD-->
    </div>
    <div class="tw-flex tw-flex-col tw-w-[50%]">
         <!-- START TABLE REMOVE-->
         <div class="tw-flex tw-flex-col tw-h-[500px] tw-overflow-x-hidden">
            <div class="tw-flex tw-flex-col tw-sticky tw-top-0">
                <div class="tw-flex tw-flex-row">
                    <div class="tw-flex tw-flex-row">
                        <button mat-button (click)="removeAll()">
                            <mat-icon>arrow_back</mat-icon>
                            {{'PROMOTIONS.DETAILS.REMOVE_ALL' | translate | uppercase}}
                        </button>
                    </div>
                </div>
            </div>
            <div class="tw-flex tw-flex-col">
                <table #tableRemove mat-table [dataSource]="dataSourceRemove" matSort class="table-remove">

                    <!--ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{column.header}}</th>
                        <td mat-cell *matCellDef="let row"> {{column.cell(row)}} </td>
                    </ng-container-->

                    @for (column of columns; track column) {
                        <ng-container [matColumnDef]="column.columnDef">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{column.header}}</th>
                            @switch (column.columnDef) {
                                @case ('quantity') {
                                    <td mat-cell *matCellDef="let el" class="mat-column-quantity">
                                        <mat-form-field appearance="fill" class="tw-flex tw-mb-0">
                                            <input matInput type="number" min="0" class="tw-text-right"
                                            (ngModelChange)="column.changeCell($event, el)"
                                            [ngModel]="column.cell(el)"
                                            />
                                        </mat-form-field>
                                    </td>
                                }
                                @case ('discountedPrice') {
                                    @if ($promotion_type() === 'discount_perc') {
                                        <td mat-cell *matCellDef="let el" class="tw-text-green-600">
                                            {{column.cell(el)}}
                                        </td>
                                    } @else {
                                        <td mat-cell *matCellDef="let el">
                                            <mat-form-field appearance="fill" class="tw-flex tw-mb-0">
                                                <input matInput type="number" min="0" step="0.01" class="tw-text-right tw-text-green-600"
                                                (ngModelChange)="column.changeCell($event, el)"
                                                [ngModel]="column.cell(el)"
                                                />

                                            </mat-form-field>
                                        </td>
                                    }
                                }
                                @default {
                                    <td mat-cell *matCellDef="let el">
                                        {{column.cell(el)}}
                                    </td>
                                }
                            }
                        </ng-container>
                    }

                    <!-- Action REMOVE -->
                    <ng-container matColumnDef="action" stickyEnd>
                        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                        <td mat-cell *matCellDef="let row">
                            <button mat-icon-button (click)="removeRow(row)">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsRemove; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsRemove;"></tr>

                    <!--tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" colspan="4">No data found</td>
                    </tr-->
                </table>

                <!--mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of items"></mat-paginator-->
            </div>
        </div>
        <!-- END TABLE REMOVE-->
    </div>
</div>

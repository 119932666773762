import {
    Subject
} from "rxjs";

import {
    binaryFind
} from "./utils";

/**
 * Represents a task with a priority.
 * @template T
 */
type Task<T> = {
    priority: number,
    task: T
}

/**
 * A class that manages a priority queue of tasks.
 * @template T
 */
export class PriorityTaskQueue<T> {
    private tasks: Task<T>[] = [];
    private stopped = false;
    private isProcessing = false;

    private queueFinished = new Subject<void>();
    public queueFinished$ = this.queueFinished.asObservable();

    /**
     * Creates a new PriorityTaskQueue instance.
     * @param {function(T): Promise<void>} taskDispatcher - A function that dispatches tasks.
     */
    constructor(
        private taskDispatcher: (task: T) => Promise<void>
    ) {
    }

    /**
     * Adds a task to the queue with a given priority.
     * @param {T} task - The task to be added.
     * @param {number} priority - The priority of the task.
     */
    public add(task: T, priority: number) {
        const result = binaryFind(this.tasks, t => t.priority - priority);

        if(result) {
            // insert the task at the index
            this.tasks.splice(result.index, 0, { task, priority });
        } else {
            // insert the task at the end
            this.tasks.push({ task, priority });
        }

        this.process();
    }
    
    /**
     * Adds multiple tasks to the queue at once.
     * @param {Array<{ task: T, priority: number }>} tasks - An array of tasks with their priorities.
     * Sorts the queue by priority after adding the tasks.
     * If the queue is not stopped, starts processing the tasks.
     */
    public bulkAdd(tasks: { task: T, priority: number }[]) {
        if(tasks.length === 0) {
            return;
        }

        for(const t of tasks) {
            this.tasks.push({ task: t.task, priority: t.priority });
        }

        this.tasks.sort((a, b) => a.priority - b.priority);

        this.process();
    }

    public reinitialize(tasks?: { task: T, priority: number }[]) {
        this.tasks = [];
        this.stopped = false;

        if(tasks) {
            this.bulkAdd(tasks);
        }
    }

    /**
     * Removes a task from the queue.
     * @param {T} task - The task to be removed.
     */
    public remove(task: T) {
        // Find the task and remove it
        const index = this.tasks.findIndex(t => t.task === task);

        if(index === -1) {
            return;
        }

        this.tasks.splice(index, 1);
    }

    /**
     * Clears the task queue.
     */
    public clear() {
        this.tasks = [];
    }

    /**
     * Stops the task queue from processing tasks.
     */
    public stop() {
        this.stopped = true;
    }

    /**
     * Checks if the task queue is stopped.
     * @returns {boolean} True if the task queue is stopped, false otherwise.
     */
    public isStopped() {
        return this.stopped;
    }
    
    /**
     * Resumes the task queue.
     */
    public resume() {
        this.stopped = false;

        this.process();
    }

    /**
     * Checks if the task queue is empty.
     * @returns {boolean} True if the task queue is empty, false otherwise.
     */
    public isEmpty() {
        return this.tasks.length === 0;
    }

    /**
     * Processes tasks in the queue.
     */
    private async process() {
        if(this.stopped || this.isProcessing || this.isEmpty()) {
            return;
        }

        this.isProcessing = true;

        while(!this.isEmpty() && !this.stopped) {
            const task = this.tasks.shift();

            if(task) {
                try {
                    await this.taskDispatcher(task.task);
                } catch (err) {
                    //Nothing to do
                }
            }
        }

        if(this.isEmpty()) {
            this.queueFinished.next();
        }

        this.isProcessing = false;
    }
}
import angular from 'angular';

angular.module('application').factory('satispayApi', ["restManager", function(restManager) {
    var satispayApi = {
        setupShop: function(code) {
            return restManager.post('satispay/setup', { code: code });
        },
        getAcceptedPayments: function() {
            return restManager.getList('satispay/payments');
        },
        getPendingPayments: function() {
            return restManager.getList('satispay/payments', { status: 'PENDING' });
        },
        acceptPayment: function(payment) {
            return restManager.put('satispay/payments', payment.id, { action: 'ACCEPT' });
        },
        cancelPayment: function(payment) {
            return restManager.put('satispay/payments', payment.id, { action: 'CANCEL' });
        },
        rollbackPayment: function(payment) {
            return restManager.post('satispay/payments',  { flow: 'REFUND', amount_unit: payment.amount_unit, currency: payment.currency, parent_payment_uid: payment.id });
        }
    };

    return satispayApi;
}]);
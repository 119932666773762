<offscreen-fragment>
  <transport-container *ngFor="let customRendering of customRenderings; trackBy:trackCustomRendering"
    [inPlaceOf]="customRendering.containerEl"
    [reportEl]="customRendering.reportNewContainerEl"
    [elTag]="customRendering.elTag"
    [elClasses]="customRendering.elClasses"
    [elStyle]="customRendering.elStyle"
    [elAttrs]="customRendering.elAttrs"
    [template]="templateMap[customRendering.generatorName]!"
    [renderProps]="customRendering.renderProps"
  ></transport-container>
</offscreen-fragment>

import angular from 'angular';
import * as async from 'async';
import _ from 'lodash';

angular.module('history').factory('eInvoiceSetup', ["$mdDialog", "$http", "$translate", "$rootScope", "$timeout", "restManager", "checkManager", "taxRegimes", function($mdDialog, $http, $translate, $rootScope, $timeout, restManager, checkManager, taxRegimes) {

    eInvoiceSetupController.$inject = ["$scope", "$mdDialog", "preferencesData", "billingData"];
    function eInvoiceSetupController($scope, $mdDialog, preferencesData, billingData) {
        $scope.pristineData = preferencesData;
        $scope.preferencesData = _.cloneDeep(preferencesData);
        $scope.taxRegimes = _.cloneDeep(taxRegimes);
        $scope.billingData = billingData;
        $scope.digitalHubCredentials = {};
        $scope.ftpParams = {};

        var requestCode;

        $scope.step = 'mode-select';

        var makeRandom = function(length) {
            var result = _.map(Array(length), function() {
                return _.toString(_.random(0, 9));
            });

            return result.join('');
        };

        $scope.cancel = function() {
            return $mdDialog.cancel();
        };

        $scope.deleteMessage = function() {
            $scope.errorMessage = null;
        };

        $scope.getTitle = function() {
            switch($scope.step) {
                case 'merchant-setup':
                    return $translate.instant('HISTORY.E_INVOICE_SETUP.MERCHANT_SETUP_TITLE');
                case 'mode-select':
                    return $translate.instant('HISTORY.E_INVOICE_SETUP.MODE_SELECT_TITLE');
                case 'digitalhub-managed-setup':
                    return $translate.instant('HISTORY.E_INVOICE_SETUP.SMS_CONFIRM.TITLE');
                case 'digitalhub-owned-setup':
                    return $translate.instant('HISTORY.E_INVOICE_SETUP.DIGITAL_HUB_OWNED.TITLE');
                case 'ftp-setup':
                    return $translate.instant('HISTORY.E_INVOICE_SETUP.FTP.TITLE');
                case 'sms-confirm':
            }
        };

        $scope.back = function() {
            $scope.errorMessage = null;

            switch($scope.step) {
                case 'merchant-setup':
                    $scope.step = 'mode-select';
                    break;
                case 'digitalhub-managed-setup':
                case 'digitalhub-owned-setup':
                case 'ftp-setup':
                    $scope.step = 'merchant-setup';
                break;
                case 'sms-confirm':
                    $scope.step = 'digitalhub-managed-setup';
                break;
            }
        };

        var showErrorMessage = function(messageCode, otherMessage) {
            var errMsg = $translate.instant('HISTORY.E_INVOICE_SETUP.ERRORS.' + messageCode);

            if(!_.isEmpty(otherMessage)) {
                errMsg += ':\n' + otherMessage;
            }

            $scope.errorMessage = errMsg;
        };

        var performSetup = function() {
            var reqBody = {};

            switch($scope.sendMode) {
                case 'digitalHubManaged':
                    _.assign(reqBody, { send_mode: 'digitalhub' });
                break;
                case 'digitalHubOwned':
                    _.assign(reqBody, { send_mode: 'digitalhub' });
                    _.assign(reqBody, $scope.digitalHubCredentials);
                break;
                case 'ftp':
                    _.assign(reqBody, { send_mode: 'ftp' });
                    _.assign(reqBody, $scope.ftpParams);
                break;
                case 'download':
                    _.assign(reqBody, { send_mode: 'download' });
                break;
            }

            $scope.isWorking = true;

            restManager.post('e_invoice_setup', reqBody).then(function(result) {
                $mdDialog.hide(result);
            }, function(error) {
                var err;

                if(_.get(error, 'status') === -1) {
                    err = 'OFFLINE';
                } else if(_.get(error, ['data', 'error', 'message', 'error_code'])) {
                    err = error.data.error.message.error_code;
                } else {
                    err = 'UNKNOWN_ERROR';
                }

                showErrorMessage(err, error.data.error.message.response);
            }).finally(function() {
                $scope.isWorking = false;
            });
        };

        $scope.confirm = function() {
            $scope.errorMessage = null;

            switch($scope.step) {
                case 'merchant-setup':
                    var changedPrefs = {};
            
                    var savePreferences = function() {
                        $scope.isWorking = true;

                        async.eachOfSeries(changedPrefs, function(val, key, cb) {
                            if(!_.startsWith(key, '_')) {
                                checkManager.setShopPreferenceSync(key, val).then(function() { cb(); }, cb);
                            } else {
                                cb();
                            }
                        }, function(err, res) {
                            if(err) {

                            } else {
                                angular.copy($scope.preferencesData, $scope.pristineData);
                                $scope.isWorking = false;
                                
                                switch($scope.sendMode) {
                                    case 'digitalHubOwned':
                                        $scope.step = 'digitalhub-owned-setup';
                                    break;
                                    case 'ftp':
                                        $scope.step = 'ftp-setup';
                                    break;
                                    case 'download':
                                        performSetup();
                                    break;
                                }
                            }
                        });
                    };
            
                    var getChangedPreferences = function(current, previous) {
                        _.forIn(current, function(val, key) {
                            if(!_.isEqual(val, previous[key])) {
                                _.set(changedPrefs, [key], val);
                            }
                        });
                    };
            
                    getChangedPreferences($scope.preferencesData, $scope.pristineData);
                    savePreferences();
        
                    $scope.preferencesDataReset = _.cloneDeep($scope.preferencesData);
                break;
                case 'mode-select':
                    $scope.step = 'merchant-setup';
                break;
                case 'digitalhub-owned-setup':
                    performSetup();
                break;
                case 'digitalhub-managed-setup':            
                    var spaces = _.repeat(' ', _.random(0, 10));
        
                    requestCode = makeRandom(5);

                    var smsRequest = {
                        phone: "39" + $scope.preferencesData['e_invoice.merchant.phone'],
                        text: $translate.instant('SETTINGS.AUTH_REQUEST_DIALOG.SMS_TEXT', {spaces: spaces, value: requestCode})
                    };

                    $scope.isWorking = true;

                    restManager.post('tools/send_sms', smsRequest).then(function(smsResult){
                        $scope.step = 'sms-confirm';
                        $scope.smsToken = '';
                    }, function(error) {
                        $scope.errorMessage = 'CANNOT_SEND_MESSAGE';
                    }).finally(function() {
                        $scope.isWorking = false;
                    });
                break;
                case 'ftp-setup':
                    performSetup();
                break;
                case 'sms-confirm':
                    $scope.isWorking = true;
                    $scope.errorMessage = null;

                    $timeout(function() {
                        if($scope.smsToken === requestCode) {
                            performSetup();
                        } else {
                            showErrorMessage('OTP_CHECK_FAILED');
                            $scope.isWorking = false;
                        }
                    }, 1000);
                break;
            }
        };

        $scope.isConfirmDisabled = function() {
            switch($scope.step) {
                case 'merchant-setup':
                    return _.get($scope, ['eInvoiceMerchantForm', '$invalid']);
                case 'mode-select':
                    return _.isEmpty($scope.sendMode);
                case 'digitalhub-managed-setup':
                    return true;
                case 'digitalhub-owned-setup':
                    return _.get($scope, ['eInvoiceDigitalHubForm', '$invalid']);
                case 'ftp-setup':
                    return _.get($scope, ['eInvoiceFtpForm', '$invalid']);
                case 'sms-confirm':
                    return _.get($scope, ['authRequestDialogForm', '$invalid']);
            }
        };

        $scope.isConfirmHidden = function() {
            switch($scope.step) {
                case 'digitalhub-managed-setup':
                    return true;
            }
        };

        $scope.getSendModePrice = function(sendMode) {
            return $translate.instant(billingData.reseller_scloby_id ? 'HISTORY.E_INVOICE_SETUP.RESELLER_PRICE_INFO' : 'HISTORY.E_INVOICE_SETUP.' + sendMode + '_PRICE');
        };

        $scope.compileMerchant = function() {
            var vatCode = $scope.preferencesData['e_invoice.merchant.vat_code'];
            
            $http.get('https://x.scloby.com/fe_to_scloby.php', {
                params: {
                    ambiente: $rootScope.userActiveSession.shop.name,
                    piva: vatCode || undefined
                }
            }).then(function(success) {
                var dataToAssign = success.data;
                if(!_.isEmpty(dataToAssign) && !dataToAssign.error) {

                    _.assign($scope.preferencesData, {
                        'e_invoice.merchant.tax_code': dataToAssign.azienda_cf,
                        'e_invoice.merchant.company_name': dataToAssign.azienda_denominazione,
                        'e_invoice.merchant.vat_code': dataToAssign.azienda_piva,
                        'e_invoice.merchant.country': 'IT',
                    });

                    _.assign($scope.preferencesData, {
                        'e_invoice.merchant.head_office.street': dataToAssign.azienda_indirizzo,
                        'e_invoice.merchant.head_office.zip': dataToAssign.azienda_indirizzo_cap,
                        'e_invoice.merchant.head_office.city': dataToAssign.azienda_indirizzo_citta,
                        'e_invoice.merchant.head_office.prov': dataToAssign.azienda_indirizzo_provincia,
                        'e_invoice.merchant.head_office.country': 'IT'
                    });
                }
            });
        };
    }

    var eInvoiceSetup = {
        show: function() {
            return $mdDialog.show({
                controller: eInvoiceSetupController,
                template: require('./e-invoice-setup.html'),
                resolve: {
                    preferencesData: function() {
                        return checkManager.getShopPreferences('e_invoice');
                    },
                    billingData: function() {
                        return restManager.getOne('billing_data').catch(function(error) {
                            if(_.get(error, ['data', 'error', 'message', 'owner_data'])) {
                                throw 'OWNER_ACCOUNT_REQUIRED';
                            } else {
                                throw 'UNKNOWN_ERROR';
                            }
                        });
                    }
                }
            });
        }
    };

    return eInvoiceSetup;
}]);

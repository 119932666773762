import angular from 'angular';
import { ShippingInvoiceService } from 'app/modules/printers/service/shipping-invoice/shipping-invoice';
import _ from 'lodash';
import moment from 'moment-timezone';
import {
    ConfigurationManagerService,
    EntityManagerService,
    UserActiveSessionManagerService
} from 'src/app/core';
import { Items, Sales } from 'tilby-models';

export class EInvoiceSendService {
    constructor(
        private configurationManagerService: ConfigurationManagerService,
        private restManager: any,
        private userActiveSession: UserActiveSessionManagerService,
        private entityManager: EntityManagerService,
        private saleUtils: any,
        private shippingInvoice: ShippingInvoiceService
    ) {
    }

    private getErrorCode(error: any) {
        if (typeof error == 'string') {
            return error;
        }

        if (error?.status == -1) {
            return 'E_INVOICE_OFFLINE';
        }

        return error?.data?.error_code || 'UNKNOWN_ERROR';
    }

    private setShippingCause(sale: Sales, shippingData: any, printerDocumentData: any) {
        const shippingDataOptions = shippingData.options;
        let totalWeight = 0;

        const saleItems = sale.sale_items || [];

        for (let saleItem of saleItems) {
            const originalItem = (saleItem.item_id ? printerDocumentData.itemsMap?.[saleItem.item_id] || {} : {}) as Items;

            if (saleItem.item_id) {
                totalWeight += (originalItem.weight || 0) * saleItem.quantity;
            }
        }

        const totalWeightStr = totalWeight >= 1000 ? (totalWeight / 1000).toFixed(2).replace('.', ',') + "Kg" : _.toInteger(totalWeight) + "g";

        const cause = [
            "",
            "DOCUMENTO DI TRASPORTO:",
            "",
            "",
            `Causale Trasporto: ${shippingDataOptions.shippingMeta.cause}`,
            `Pagamento: ${shippingDataOptions.shippingMeta.payment}`,
            `Trasporto: ${shippingDataOptions.shippingMeta.transport}`,
            `Aspetto: ${shippingDataOptions.shippingMeta.appearance}`,
            `N.Colli: ${shippingDataOptions.shippingMeta.package_count}`,
            `Peso: ${totalWeightStr}`,
            `Data/ora trasporto: ${moment(shippingDataOptions.shippingMeta.transport_date).format("DD/MM/YYYY HH:mm")}`,
            `Porto: ${shippingDataOptions.shippingMeta.port}`,
            `Vettore: ${shippingDataOptions.shippingMeta.carrier}`
        ].join('\n');

        if (!sale.e_invoice) {
            sale.e_invoice = {};
        }

        if (!sale.e_invoice.invoice_cause) {
            sale.e_invoice.invoice_cause = "";
        }

        sale.e_invoice.invoice_cause = sale.e_invoice.invoice_cause + cause;
    }

    public async sendEInvoice(sale: Sales, printerDocumentData: any): Promise<Sales> {
        const saveFunction = sale.id ? 'putOneOnline' : 'postOneOnline';
        const documentType = printerDocumentData.document_type.id;
        const printDDT = !!(printerDocumentData.options.printDDT);
        const printOnlyDepartments = !!(printerDocumentData.options.printOnlyDepartments);
        const savePaper = !!this.configurationManagerService.getPreference('cashregister.save_paper_on_e_invoice');

        const clonedSale = structuredClone(sale);

        try {
            if (printDDT) {
                const shippingData = await this.shippingInvoice.getData(clonedSale, printerDocumentData);
                this.setShippingCause(clonedSale, shippingData, printerDocumentData);
            }

            Object.assign(clonedSale, {
                lastupdate_at: 'NOW',
                lastupdate_by: this.userActiveSession.getSession()?.id
            });

            await this.saleUtils.calculateSaleItemsCosts(clonedSale);
            const savedSale = await this.entityManager.sales[saveFunction](clonedSale);
            const resultSale = await this.restManager.getOne('send_e_invoice', savedSale.id, { document_type: documentType, print_ddt: printDDT, print_only_departments: printOnlyDepartments, printer_id: printerDocumentData.printer?.id, save_paper: savePaper})

            return resultSale;
        } catch (err: any) {
            throw this.getErrorCode(err);
        }
    }
}

EInvoiceSendService.$inject = ["checkManager", "restManager", "userActiveSession", "entityManager", "saleUtils", "shippingInvoice"];

angular.module('cashregister').service('eInvoiceSend', EInvoiceSendService);
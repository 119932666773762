import _ from 'lodash';
import moment from 'moment-timezone';

import {
    Component,
    ElementRef,
    inject,
    Renderer2,
    OnInit,
    ViewChild,
} from '@angular/core';

import { NgForm } from '@angular/forms';

import {
    ConfirmDialogService
} from 'src/app/dialogs';

import { TranslateService } from "@ngx-translate/core";
import { util } from 'app/ajs-upgraded-providers';
import { MatSnackBar } from '@angular/material/snack-bar';

import {
    CustomizedNonFiscalDocument,
    NonfiscalDocumentsRulesPlus,
    SelectValueName
} from 'src/app/models';

import {
    EntityManagerService,
    ModuleEvents,
    ToolbarEventsService
} from 'src/app/core';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';

@Component({
  selector: 'app-settings-model-document',
  templateUrl: './settings-model-document.component.html',
  styleUrls: ['./settings-model-document.component.scss'],
  providers: [OnDestroyService]
})
export class SettingsModelDocumentComponent implements OnInit {
    private readonly _snackBar = inject(MatSnackBar);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly onDestroyService = inject(OnDestroyService);
    private readonly renderer = inject(Renderer2);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly translate = inject(TranslateService);
    private readonly util = inject(util);

    @ViewChild('modelDocumentForm', {static: true}) modelDocumentForm!: NgForm;
    @ViewChild('importModelDocumentId', {static: true}) importModelDocumentElement!: ElementRef<HTMLInputElement>;

    public aligns: SelectValueName[] = [];
    public barcode_fields_id: SelectValueName[] = [];
    public barcode_font: SelectValueName[] = [];
    public barcode_height: number[] = [];
    public barcode_hri: SelectValueName[] = [];
    public barcode_type: SelectValueName[] = [];
    public barcode_width: number[] = [];
    public dateTimeFormats: SelectValueName[] = [];
    public documentType: SelectValueName[] = [];
    public filterList = '';
    public filterToggle = false;
    public fontSize: number[] = [];
    public isNewEntryInProgress = false;
    public isProcessing = false;
    public length: number[] = [];
    public marginBottom: number[] = [];
    public marginTop: number[] = [];
    public modelDocumentList: CustomizedNonFiscalDocument[] = [];
    public pristineModelDocument: CustomizedNonFiscalDocument = {};
    public sale_fields: SelectValueName[] = [];
    public saleContentFields: SelectValueName[] = [];
    public saleTotalsFields: SelectValueName[] = [];
    public selected = false;
    public selectedModelDocument: CustomizedNonFiscalDocument = {};
    public separatorRows: number[] = [];
    public shop_preferences: SelectValueName[] = [];
    public shop_settings: SelectValueName[] = [];
    public skipEmpty: SelectValueName[] = [];
    public tooltipButtonOrderDown = '';
    public tooltipButtonOrderUp = '';
    public tooltipFontWeight = '';
    public tooltipMenuDelete = '';
    public tooltipMenuDuplicate = '';
    public types: SelectValueName[] = [];
    public uploadedFile: any = null;

    constructor(
    ) {
        this.modelDocumentForm = new NgForm([],[]);
    }

    createToolbarButtons() {
        this.toolbarEventsService.buttons$.next({
            barButtons: [],
            panelButtons: [
                {
                    isIt: () => true,
                    name: 'export',
                    icon: () => 'download',
                    label: () => this.translate.instant('SETTINGS.MODEL_DOCUMENT.EXPORT'),
                    click: () => this.toolbarEventsService.events.next({type: 'export'})
                },
                {
                    isIt: () => true,
                    name: 'import',
                    icon: () => 'publish',
                    label: () => this.translate.instant('SETTINGS.MODEL_DOCUMENT.IMPORT'),
                    click: () => this.toolbarEventsService.events.next({type: 'import'})
                }
            ]
        });
    }

    callbackToToolbarClick(event: Partial<ModuleEvents> & { id?: number }) {
        if ('type' in event && event.type === 'export') this.exportModelDocument();
        else if ('type' in event && event.type === 'import') this.importModelDocumentElement?.nativeElement.click();
    }

    private importModelDocumentProceed = ($event: Event) => {
        const target = $event.target as HTMLInputElement;
        const files = target.files;
        const uploadedFile = files?.[0];
        if(uploadedFile) {
            const reader = new FileReader();
            reader.onload = (event: any) => {
                const modelDocumentToImport: CustomizedNonFiscalDocument = JSON.parse(event.target.result);
                delete modelDocumentToImport.id;
                delete modelDocumentToImport.created_at;
                delete modelDocumentToImport.updated_at;
                delete modelDocumentToImport.deleted_at;
                if (modelDocumentToImport.rules && modelDocumentToImport.rules.length > 0) {
                    modelDocumentToImport.rules.forEach((el: NonfiscalDocumentsRulesPlus) => {
                        delete el.expansionPanel;
                        delete el.id;
                        delete el.created_at;
                        delete el.updated_at;
                        delete el.deleted_at;
                    });
                }
                this.modelDocumentList.push(modelDocumentToImport);
                this.modelDocumentList = [...this.modelDocumentList];
                this.entityManagerService.nonfiscalDocuments.postOneOnline(modelDocumentToImport).then((result: CustomizedNonFiscalDocument) => {
                    const lastIndex = this.modelDocumentList.length - 1;
                    let target: CustomizedNonFiscalDocument = this.modelDocumentList[lastIndex];
                    Object.assign(target, result);
                    this.modelDocumentForm.form.markAsPristine();
                    this.selectModelDocument(target);
                    const labelSuccess = this.translate.instant('SETTINGS.SETTINGS.SAVE_SUCCESSFUL');
                    this._snackBar.open(labelSuccess, undefined, { duration: 3000, horizontalPosition: 'left', verticalPosition: 'bottom' });
                });
            };
            reader.onloadstart = () => { this.isProcessing = true; };
            reader.onloadend = () => { this.isProcessing = false; };
            reader.onerror = () => {
                this.selectFirstAvailableModelDocument();
                this.isProcessing = false;
                const labelSuccess = this.translate.instant('SETTINGS.SETTINGS.ERROR_IMPORT');
                this._snackBar.open(labelSuccess, undefined, { duration: 5000, horizontalPosition: 'left', verticalPosition: 'bottom' });
            };
            reader.readAsText(uploadedFile);
        }
    };

    async importModelDocument($event: Event, isFormDirty: boolean) {
        if(isFormDirty || this.isNewEntryInProgress) {
            const confirm = await this.confirmDialogService.openDialog({
                data: {
                    messageLabel: 'SETTINGS.MODEL_DOCUMENT.EDITS_NOT_SAVED',
                    confirmLabel: 'SETTINGS.MODEL_DOCUMENT.CONFIRM_LABEL',
                    cancelLabel: 'SETTINGS.MODEL_DOCUMENT.CANCEL_LABEL'
                }
            });

            if (!confirm) {
                return;
            }

            if (this.isNewEntryInProgress) {
                this.modelDocumentList.pop();
                this.modelDocumentList = [...this.modelDocumentList];
                this.modelDocumentForm.form.markAsPristine();
                this.isNewEntryInProgress = false;
            }
        }

        this.importModelDocumentProceed($event);
    }

    setModuleTitle() {
        this.toolbarEventsService.moduleTitle.next('MODEL_DOCUMENT');
    }

    ngOnInit(): void {
        this.createToolbarButtons();
        this.setModuleTitle();
        this.toolbarEventsService.events.pipe(this.onDestroyService.takeUntilDestroy).subscribe(e => this.callbackToToolbarClick(e));
        this.entityManagerService.nonfiscalDocuments.fetchCollectionOffline().then((res: CustomizedNonFiscalDocument[]) => {
            if( Array.isArray(res) ) {
                this.modelDocumentList = res;
            }
        });
        this.documentType = [
            { value: 'generic_receipt',  name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.DOCUMENT_TYPE.GENERIC_RECEIPT')  },
            { value: 'generic_invoice',  name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.DOCUMENT_TYPE.GENERIC_INVOICE')  },
            { value: 'generic_document', name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.DOCUMENT_TYPE.GENERIC_DOCUMENT') },
            { value: 'credit_note',      name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.DOCUMENT_TYPE.CREDIT_NOTE')      },
            { value: 'preliminary_receipt',      name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.DOCUMENT_TYPE.PRELIMINARY_RECEIPT')      },
            { value: 'expense_report',      name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.DOCUMENT_TYPE.EXPENSE_REPORT')      },
        ];
        this.types = [
            { value:'logo',               name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.TYPES.LOGO')                },
            { value:'text',               name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.TYPES.TEXT')                },
            { value:'shop_preference',    name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.TYPES.SHOP_PREFERENCE')     },
            { value:'shop_setting',       name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.TYPES.SHOP_SETTING')        },
            { value:'sale_field',         name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.TYPES.SALE_FIELD')          },
            { value:'date_time',          name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.TYPES.DATE_TIME')           },
            { value:'progressive',        name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.TYPES.PROGRESSIVE')         },
            { value:'sale_content',       name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.TYPES.SALE_CONTENT')        },
            { value:'sale_totals',        name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.TYPES.SALE_TOTALS')         },
            { value:'sale_payments',      name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.TYPES.SALE_PAYMENTS')       },
            { value:'sale_vats',          name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.TYPES.SALE_VATS')           },
            { value:'sale_price_changes', name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.TYPES.SALE_PRINCE_CHANGES') },
            { value:'document_tail',      name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.TYPES.DOCUMENT_TAIL')       },
            { value:'printer_tail',       name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.TYPES.PRINTER_TAIL')        },
            { value:'separator',          name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.TYPES.SEPARATOR')           },
            { value:'cut',                name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.TYPES.CUT')                 },
            { value:'cashdrawer',         name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.TYPES.CASHDRAWER')          },
            { value:'document_title',     name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.TYPES.DOCUMENT_TITLE')      },
            { value:'barcode_fixed',      name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.TYPES.BARCODE_FIXED')       },
            { value:'barcode_sale_field', name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.TYPES.BARCODE_SALE_FIELD')  }
        ];
        this.shop_preferences = [
            { value: 'general.shopname',                          name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.SHOPNAME')                                  },
            { value: 'general.phone',                             name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.PHONE')                                     },
            { value: 'general.address_city',                      name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.ADDRESS_CITY')                              },
            { value: 'general.address_prov',                      name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.ADDRESS_PROV')                              },
            { value: 'general.address_street',                    name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.ADDRESS_STREET')                            },
            { value: 'general.address_zip',                       name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.ADDRESS_ZIP')                               },
            { value: 'general.currency',                          name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.CURRENCY')                                  },
            { value: 'general.timezone',                          name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.TIMEZONE')                                  },
            { value: 'general.email',                             name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.EMAIL')                                     },
            { value: 'general.facebook',                          name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.FACEBOOK')                                  },
            { value: 'general.instagram',                         name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.INSTAGRAM')                                 },
            { value: 'general.messenger',                         name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.MESSENGER')                                 },
            { value: 'general.snapchat',                          name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.SNAPCHAT')                                  },
            { value: 'general.telegram',                          name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.TELEGRAM')                                  },
            { value: 'general.tiktok',                            name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.TIKTOK')                                    },
            { value: 'general.twitter',                           name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.TWITTER')                                   },
            { value: 'general.wechat',                            name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.WECHAT')                                    },
            { value: 'general.whatsapp',                          name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.WHATSAPP')                                  },
            { value: 'general.website',                           name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.WEBSITE')                                   },
            { value: 'fiscalprinter.printer_header_1',            name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.PRINTER_HEADER_1')                          },
            { value: 'fiscalprinter.printer_header_2',            name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.PRINTER_HEADER_2')                          },
            { value: 'fiscalprinter.printer_header_3',            name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.PRINTER_HEADER_3')                          },
            { value: 'fiscalprinter.printer_header_4',            name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.PRINTER_HEADER_4')                          },
            { value: 'fiscalprinter.printer_header_5',            name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.PRINTER_HEADER_5')                          },
            { value: 'fiscalprinter.printer_header_6',            name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.PRINTER_HEADER_6')                          },
            { value: 'fiscalprinter.printer_header_7',            name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.PRINTER_HEADER_7')                          },
            { value: 'fiscalprinter.printer_header_8',            name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.PRINTER_HEADER_8')                          },
            { value: 'fiscalprinter.printer_header_9',            name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.PRINTER_HEADER_9')                          },
            { value: 'fiscalprinter.printer_header_10',           name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.PRINTER_HEADER_10')                         },
            { value: 'fiscalprinter.printer_header_11',           name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.PRINTER_HEADER_11')                         },
            { value: 'fiscalprinter.printer_header_12',           name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.PRINTER_HEADER_12')                         },
            { value: 'fiscalprinter.printer_header_13',           name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.PRINTER_HEADER_13')                         },
            { value: 'cashregister.queue.max_value',              name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.QUEUE_MAX_VALUE')                           },
            { value: 'daily_goal',                                name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.DAILY_GOAL')                                },
            { value: 'day_start_time_24',                         name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.DAY_START_TIME_24')                         },
            { value: 'e_invoice.bank_iban',                       name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.E_INVOICE_BANK_IBAN')                       },
            { value: 'e_invoice.bank_merchant_name',              name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.E_INVOICE_BANK_MERCHANT_NAME')              },
            { value: 'e_invoice.bank_name',                       name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.E_INVOICE_BANK_NAME')                       },
            { value: 'e_invoice.invoice_prefix',                  name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.E_INVOICE_INVOICE_PREFIX')                  },
            { value: 'e_invoice.merchant.company_name',           name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.E_INVOICE_MERCHANT_COMPANY_NAME')           },
            { value: 'e_invoice.merchant.country',                name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.E_INVOICE_MERCHANT_COUNTRY')                },
            { value: 'e_invoice.merchant.email',                  name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.E_INVOICE_MERCHANT_EMAIL')                  },
            { value: 'e_invoice.merchant.head_office.city',       name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.E_INVOICE_MERCHANT_HEAD_OFFICE_CITY')       },
            { value: 'e_invoice.merchant.head_office.country',    name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.E_INVOICE_MERCHANT_HEAD_OFFICE_COUNTRY')    },
            { value: 'e_invoice.merchant.head_office.number',     name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.E_INVOICE_MERCHANT_HEAD_OFFICE_NUMBER')     },
            { value: 'e_invoice.merchant.head_office.prov',       name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.E_INVOICE_MERCHANT_HEAD_OFFICE_PROV')       },
            { value: 'e_invoice.merchant.head_office.street',     name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.E_INVOICE_MERCHANT_HEAD_OFFICE_STREET')     },
            { value: 'e_invoice.merchant.head_office.zip',        name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.E_INVOICE_MERCHANT_HEAD_OFFICE_ZIP')        },
            { value: 'e_invoice.merchant.tax_agent.company_name', name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.E_INVOICE_MERCHANT_TAX_AGENT_COMPANY_NAME') },
            { value: 'e_invoice.merchant.tax_agent.vat_code',     name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.E_INVOICE_MERCHANT_TAX_AGENT_VAT_CODE')     },
            { value: 'e_invoice.merchant.tax_code',               name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.E_INVOICE_MERCHANT_TAX_CODE')               },
            { value: 'e_invoice.merchant.tax_regime',             name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.E_INVOICE_MERCHANT_TAX_REGIME')             },
            { value: 'e_invoice.merchant.vat_code',               name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.E_INVOICE_MERCHANT_VAT_CODE')               },
            { value: 'e_invoice.progressive',                     name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.E_INVOICE_PROGRESSIVE')                     },
            { value: 'fidelity.current_message',                  name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.FIDELITY_CURRENT_MESSAGE')                  },
            { value: 'fidelity.fidelity_label',                   name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.FIDELITY_LABEL')                            },
            { value: 'fidelity.prevtotal_message',                name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.FIDELITY_PREVTOTAL_MESSAGE')                },
            { value: 'fidelity_prefix',                           name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.FIDELITY_PREFIX')                           },
            { value: 'fiscalprinter.display_message',             name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.FISCAL_PRINTER_DISPLAY_MESSAGE')            },
            { value: 'general.receipt_url',                       name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.RECEIPT_URL')                               },
            { value: 'general.ui_language',                       name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_PREFERENCES.UI_LANGUAGE')                               }
        ];
        this.shop_settings = [
            { value: 'application.settings.currency',                    name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_SETTING.CURRENCY')             },
            { value: 'application.settings.orders.delete_order_reasons', name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_SETTING.DELETE_ORDER_REASONS') },
            { value: 'application.settings.shipping_invoice.carriers',   name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_SETTING.CARRIERS')             },
            { value: 'application.settings.shipping_invoice.tail',       name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SHOP_SETTING.TAIL')                 }
        ];
        this.sale_fields = [
            { value: 'id',                name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.ID')                },
            { value: 'uuid',              name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.UUID')              },
            { value: 'name',              name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.NAME')              },
            { value: 'channel',           name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.CHANNEL')           },
            { value: 'sale_number',       name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_NUMBER')       },
            { value: 'notes',             name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.NOTES')             },
            { value: 'sale_parent_id',    name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_PARENT_ID')    },
            { value: 'sale_parent_uuid',  name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_PARENT_UUID')  },
            { value: 'order_id',          name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.ORDER_ID')          },
            { value: 'order_uuid',        name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.ORDER_UUID')        },
            { value: 'scloby_shop_id',    name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SCLOBY_SHOP_ID')    },
            { value: 'assigned_id',       name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.ASSIGNED_ID')       },
            { value: 'assigned_name',     name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.ASSIGNED_NAME')     },
            { value: 'seller_id',         name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SELLER_ID')         },
            { value: 'seller_name',       name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SELLER_NAME')       },
            { value: 'customer_tax_code', name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.CUSTOMER_TAX_CODE') },
            { value: 'open_at',           name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.OPEN_AT')           },
            { value: 'closed_at',         name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.CLOSED_AT')         },
            { value: 'lastupdate_at',     name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.LASTUPDATE_AT')     },
            { value: 'lastupdate_by',     name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.LASTUPDATE_BY')     },
            { value: 'status',            name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.STATUS')            },
            { value: 'amount',            name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.AMOUNT')            },
            { value: 'change',            name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.CHANGE')            },
            { value: 'change_type',       name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.CHANGE_TYPE')       },
            { value: 'final_amount',      name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.FINAL_AMOUNT')      },
            { value: 'final_net_amount',  name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.FINAL_NET_AMOUNT')  },
            { value: 'currency',          name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.CURRENCY')          },
            { value: 'created_at',        name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.CREATED_AT')        },
            { value: 'updated_at',        name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.UPDATED_AT')        },
            { value: 'deleted_at',        name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.DELETED_AT')        },
            { value: 'sale_customer.first_name',                                    name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.FIRST_NAME')             },
            { value: 'sale_customer.last_name',                                     name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.LAST_NAME')              },
            { value: 'sale_customer.first_name sale_customer.last_name',            name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.ALL_NAME')               },
            { value: 'sale_customer.company_name',                                  name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.COMPANY_NAME')           },
            { value: 'sale_customer.tax_code',                                      name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.TAX_CODE')               },
            { value: 'sale_customer.vat_code',                                      name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.VAT_CODE')               },
            { value: 'sale_customer.sdi_code',                                      name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.SDI_CODE')               },
            { value: 'sale_customer.phone',                                         name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.PHONE')                  },
            { value: 'sale_customer.mobile',                                        name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.MOBILE_PHONE')           },
            { value: 'sale_customer.email',                                         name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.EMAIL')                  },
            { value: 'sale_customer.email_pec',                                     name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.EMAIL_PEC')              },
            { value: 'sale_customer.fidelity',                                      name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.FIDELITY')               },
            { value: 'sale_customer.billing_street',                                name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.BILLING_STREET')         },
            { value: 'sale_customer.billing_number',                                name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.BILLING_NUMBER')         },
            { value: 'sale_customer.billing_street sale_customer.billing_number',   name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.BILLING_STREET_NUMBER')  },
            { value: 'sale_customer.billing_city',                                  name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.BILLING_CITY')           },
            { value: 'sale_customer.billing_zip',                                   name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.BILLING_ZIP')            },
            { value: 'sale_customer.billing_zip sale_customer.billing_city',        name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.BILLING_ZIP_CITY')       },
            { value: 'sale_customer.billing_country',                               name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.BILLING_COUNTRY')        },
            { value: 'sale_customer.billing_prov',                                  name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.BILLING_PROVINCE')       },
            { value: 'sale_customer.shipping_street',                               name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.SHIPPING_STREET')        },
            { value: 'sale_customer.shipping_number',                               name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.SHIPPING_NUMBER')        },
            { value: 'sale_customer.shipping_street sale_customer.shipping_number', name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.SHIPPING_STREET_NUMBER') },
            { value: 'sale_customer.shipping_city',                                 name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.SHIPPING_CITY')          },
            { value: 'sale_customer.shipping_zip',                                  name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.SHIPPING_ZIP')           },
            { value: 'sale_customer.shipping_zip sale_customer.shipping_city',      name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.SHIPPING_ZIP_CITY')      },
            { value: 'sale_customer.shipping_country',                              name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.SHIPPING_COUNTRY')       },
            { value: 'sale_customer.shipping_prov',                                 name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SALE_CUSTOMER.SHIPPING_PROVINCE')      },
            { value: 'secondary_currency',                                          name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SECONDARY_CURRENCY')                   },
            { value: 'secondary_exchange_rate',                                     name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SECONDARY_EXCHANGE_RATE')              },
            { value: 'secondary_final_amount',                                      name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SECONDARY_FINAL_AMOUNT')               }
        ];
        this.dateTimeFormats = [
            { value: 'L',      name: moment().format('L')      },
            { value: 'L_LT',   name: moment().format('L LT')   },
            { value: 'L_LTS',  name: moment().format('L LTS')  },
            { value: 'l',      name: moment().format('l')      },
            { value: 'l_LT',   name: moment().format('l LT')   },
            { value: 'l_LTS',  name: moment().format('l LTS')  },
            { value: 'LL',     name: moment().format('LL')     },
            { value: 'LLL',    name: moment().format('LLL')    },
            { value: 'LL_LTS', name: moment().format('LL LTS') },
            { value: 'll',     name: moment().format('ll')     },
            { value: 'lll',    name: moment().format('lll')    },
            { value: 'll_LTS', name: moment().format('ll LTS') },
            { value: 'LLLL',   name: moment().format('LLLL')   },
            { value: 'llll',   name: moment().format('llll')   },
            { value: 'LT',     name: moment().format('LT')     },
            { value: 'LTS',    name: moment().format('LTS')    }
        ];
        this.saleContentFields = [
            { value: 'name',           name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_CONTENT_FIELDS.NAME')           },
            { value: 'quantity',       name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_CONTENT_FIELDS.QUANTITY')       },
            { value: 'net_price',      name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_CONTENT_FIELDS.NET_PRICE')      },
            { value: 'price',          name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_CONTENT_FIELDS.PRICE')          },
            { value: 'vat',            name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_CONTENT_FIELDS.VAT')            },
            { value: 'vat_code',       name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_CONTENT_FIELDS.VAT_CODE')       },
            { value: 'unit_net_price', name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_CONTENT_FIELDS.UNIT_NET_PRICE') },
            { value: 'unit_price',     name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_CONTENT_FIELDS.UNIT_PRICE')     },
            { value: 'unit_vat',       name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_CONTENT_FIELDS.UNIT_VAT')       },
            { value: 'sku',            name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_CONTENT_FIELDS.SKU')            },
            { value: 'code',           name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_CONTENT_FIELDS.CODE')           },
            { value: 'barcode',        name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_CONTENT_FIELDS.BARCODE')        },
            { value: 'notes',          name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_CONTENT_FIELDS.NOTES')          },
            { value: 'option_1',       name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_CONTENT_FIELDS.OPTION_1')       },
            { value: 'option_2',       name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_CONTENT_FIELDS.OPTION_2')       },
            { value: 'option_3',       name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_CONTENT_FIELDS.OPTION_3')       },
            { value: 'option_4',       name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_CONTENT_FIELDS.OPTION_4')       },
            { value: 'description',    name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_CONTENT_FIELDS.DESCRIPTION')    },
            { value: 'weight',         name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_CONTENT_FIELDS.WEIGHT')         },
            { value: 'unit_weight',    name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_CONTENT_FIELDS.UNIT_WEIGHT')    },
            { value: 'unit',           name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_CONTENT_FIELDS.UNIT')           },
            { value: 'vat_perc',       name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_CONTENT_FIELDS.VAT_PERC')       },
            { value: 'reference_text', name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_CONTENT_FIELDS.REFERENCE_TEXT') }
        ];
        this.saleTotalsFields = [
            { value: 'amount',           name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_TOTALS_FIELDS.AMOUNT')           },
            { value: 'final_amount',     name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_TOTALS_FIELDS.FINAL_AMOUNT')     },
            { value: 'final_net_amount', name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_TOTALS_FIELDS.FINAL_NET_AMOUNT') },
            { value: 'secondary_final_amount', name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_FIELDS.SECONDARY_FINAL_AMOUNT') },
            { value: 'vat_amount',       name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_TOTALS_FIELDS.VAT_AMOUNT')       },
            { value: 'change',           name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_TOTALS_FIELDS.CHANGE')           },
            { value: 'weight',           name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_TOTALS_FIELDS.WEIGHT')           },
            { value: 'price_changes',    name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SALE_TOTALS_FIELDS.PRINCE_CHANGES')   }
        ];
        this.skipEmpty = [
            { value: true,  name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SKIP_EMPTY.TRUE')   },
            { value: false, name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.SKIP_EMPTY.FALSE')  }
        ];
        this.aligns = [
            { value: 'left',   name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.ALIGN.LEFT')   },
            { value: 'center', name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.ALIGN.CENTER') },
            { value: 'right',  name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.ALIGN.RIGHT')  }
        ];
        this.fontSize = Array.from({ length:10 },(_,i) => ++i);
        this.marginTop = Array.from({ length:10 },(_,i) => i++);
        this.marginBottom = Array.from({ length:10 },(_,i) => i++);
        this.separatorRows = Array.from({ length:10 },(_,i) => ++i);
        this.length = Array.from({ length:50 },(_,i) => ++i);

        this.tooltipFontWeight = this.translate.instant('SETTINGS.MODEL_DOCUMENT.BOLD');
        this.tooltipMenuDelete = this.translate.instant('SETTINGS.MODEL_DOCUMENT.MENU.DELETE');
        this.tooltipMenuDuplicate = this.translate.instant('SETTINGS.MODEL_DOCUMENT.MENU.DUPLICATE');
        this.tooltipButtonOrderUp = this.translate.instant('SETTINGS.MODEL_DOCUMENT.ORDER_UP');
        this.tooltipButtonOrderDown = this.translate.instant('SETTINGS.MODEL_DOCUMENT.ORDER_DOWN');

        //barcode_type: 'CODE39' | 'EAN13' | 'EAN8' | 'EAN128' | 'UPC' | 'QRCODE' (default: 'CODE39')
        this.barcode_type = [
            { value:'CODE39', name: 'CODE39' },
            { value:'EAN13',  name: 'EAN13'  },
            { value:'EAN8',   name: 'EAN8'   },
            { value:'EAN128', name: 'EAN128' },
            { value:'UPC',    name: 'UPC'    },
            { value:'QRCODE', name: 'QRCODE' }
        ];

        // field_id: 'id' ID vendita | 'external_id' ID esterno | 'booking_id' ID prenotazione
        this.barcode_fields_id = [
            { value:'id',          name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.BARCODE_SALE_FIELS.FIELD_ID')    },
            { value:'external_id', name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.BARCODE_SALE_FIELS.EXTERNAL_ID') },
            { value:'booking_id',  name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.BARCODE_SALE_FIELS.BOOKING_ID')  }
        ];

        //barcode_hri: 'NONE' | 'ABOVE' | 'BELOW' | 'BOTH' (default: 'BELOW')
        this.barcode_hri = [
            { value:'NONE',  name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.BARCODE_HRI.NONE')  },
            { value:'BELOW', name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.BARCODE_HRI.BELOW') },
            { value:'ABOVE', name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.BARCODE_HRI.ABOVE') },
            { value:'BOTH',  name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.BARCODE_HRI.BOTH')  }
        ];

        //barcode_font: 'FONT_A' | 'FONT_B' | 'FONT_C' | 'FONT_SPECIAL_A' | 'FONT_SPECIAL_B' (default: 'FONT_A')
        this.barcode_font = [
            { value:'FONT_A',         name: 'FONT_A'         },
            { value:'FONT_B',         name: 'FONT_B'         },
            { value:'FONT_C',         name: 'FONT_C'         },
            { value:'FONT_SPECIAL_A', name: 'FONT_SPECIAL_A' },
            { value:'FONT_SPECIAL_B', name: 'FONT_SPECIAL_B' }
        ];

        this.barcode_width = Array.from({ length:5 },(_,i) => ++i);
        this.barcode_height = Array.from({ length:255 },(_,i) => ++i);
    }

    getDetails(item: NonfiscalDocumentsRulesPlus): string {
        const type: SelectValueName | undefined = this.types.find((el: SelectValueName) => el.value === item.type);
        let details: string = type ? type.name : 'Error: type not found';
        let option: SelectValueName | undefined;
        switch(item.type) {
            case 'shop_preference': {
                option = this.shop_preferences.find((el: SelectValueName) => el.value === item.options.preference_id);
                break;
            }
            case 'shop_setting': {
                option = this.shop_settings.find((el: SelectValueName) => el.value === item.options.setting_id);
                break;
            }
            case 'sale_field': {
                option = this.sale_fields.find((el: SelectValueName) => el.value === item.options.field_id);
                break;
            }
            case 'barcode_fixed': {
                option = item.options.barcode_value ? { value: item.options.barcode_value, name: item.options.barcode_value } : undefined;
                break;
            }
            case 'barcode_sale_field': {
                option = this.barcode_fields_id.find((el: SelectValueName) => el.value === item.options.field_id);
                break;
            }
        }
        return details += option ? ` - ${option.name}` : '';
    }

    private closeAllPanel = () => {
        if(this.selectedModelDocument.rules && this.selectedModelDocument.rules.length >0) {
            this.selectedModelDocument.rules.forEach((el: NonfiscalDocumentsRulesPlus) => {
                el.expansionPanel = false;
            });
        }
    }

    changeType = (item: NonfiscalDocumentsRulesPlus) => {
        item.options = {};
        if(item.type === 'barcode_fixed') {
            item.options.barcode_value = null;
        }
        if(item.type === 'barcode_sale_field') {
            item.options.field_id = 'id';
        }
        if(item.type === 'barcode_fixed' || item.type === 'barcode_sale_field') {
            item.options.barcode_type = 'CODE39';
            item.options.barcode_hri = 'BELOW';
            item.options.barcode_font = 'FONT_A';
            item.options.width = 1;
            item.options.height = 66;
        }
    }

    changeFontWeight = (item: NonfiscalDocumentsRulesPlus) => {
        if (item) {
            item.bold = ! item.bold;
        }
    }

    downIndex = (item: NonfiscalDocumentsRulesPlus, currentIndex :number) => {
        const nextIndex = currentIndex + 1;
        // const newHash = 'anchor' + nextIndex;
        const nextItem = this.selectedModelDocument.rules![nextIndex];
        nextItem.index = currentIndex;
        nextItem.expansionPanel = false;
        item.index = nextIndex;
        const copyItem = structuredClone(item);
        this.selectedModelDocument.rules![currentIndex] = structuredClone(nextItem);
        this.selectedModelDocument.rules![nextIndex] = copyItem;
        this.modelDocumentForm.form.markAsDirty();
    }

    upIndex = (item: NonfiscalDocumentsRulesPlus, currentIndex: number) => {
        const prevIndex = currentIndex - 1;
        //const newHash = 'anchor' + prevIndex;
        const prevItem = this.selectedModelDocument.rules![prevIndex];
        prevItem.index = currentIndex;
        prevItem.expansionPanel = false;
        item.index = prevIndex;
        const copyItem = structuredClone(item);
        this.selectedModelDocument.rules![currentIndex] = structuredClone(prevItem);
        this.selectedModelDocument.rules![prevIndex] = copyItem;
        this.modelDocumentForm.form.markAsDirty();
    }

    selectFirstAvailableModelDocument = () => {
        if(this.modelDocumentList.length > 0) {
            this.selectModelDocument(this.modelDocumentList[0]);
        } else {
            this.addNewModelDocument();
        }
    };

    isSelected = (template: CustomizedNonFiscalDocument) => template.id === this.selectedModelDocument.id;

    unsaveModelDocument = async (template: CustomizedNonFiscalDocument) => {
        const confirm = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'SETTINGS.MODEL_DOCUMENT.EDITS_NOT_SAVED',
                confirmLabel: 'SETTINGS.MODEL_DOCUMENT.CONFIRM_LABEL',
                cancelLabel: 'SETTINGS.MODEL_DOCUMENT.CANCEL_LABEL'
            }
        });

        if (confirm) {
            const index = this.modelDocumentList.findIndex((model) => model === this.selectedModelDocument);

            if(index !== -1) {
                _.pullAt(this.modelDocumentList, index);
            }

            this.modelDocumentForm.form.markAsPristine();
            this.isNewEntryInProgress = false;
            this.selectModelDocument(template);
        }
    }

    selectModelDocument = (template: CustomizedNonFiscalDocument, isFormDirty = false) => {
        if(isFormDirty) {
            this.unsaveModelDocument(template);
        } else {
            const modelDocument = structuredClone(template);
            modelDocument.rules = _.orderBy(modelDocument.rules, ['index'], ['asc'] );
            this.reorderIndex(modelDocument.rules, 0);

            this.pristineModelDocument = modelDocument;
            this.selectedModelDocument = modelDocument;
            // add expansionPanel property in rules used in expansion Panel
            if(this.selectedModelDocument.rules && this.selectedModelDocument.rules.length > 0) {
                this.selectedModelDocument.rules.forEach((el: NonfiscalDocumentsRulesPlus) => {
                    el.expansionPanel = false;
                });
            }
        }
        this.selected = true;
    }

    handleExpansionPanel = (item: any) => {
        if (!item.expansionPanel) {
            this.closeAllPanel();
        }
        item.expansionPanel = ! item.expansionPanel;
    }

    isShown = (item: any) => {
        let flag = true;
        switch (item.type) {
            case 'cut':
            case 'cashdrawer':
                flag = false;
                break;
        }
        return flag;
    }

    unsaveWhenAddNew = async () => {
        const confirm = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'SETTINGS.MODEL_DOCUMENT.EDITS_NOT_SAVED',
                confirmLabel: 'SETTINGS.MODEL_DOCUMENT.CONFIRM_LABEL',
                cancelLabel: 'SETTINGS.MODEL_DOCUMENT.CANCEL_LABEL'
            }
        });

        if (confirm) {
            this.modelDocumentForm.form.markAsPristine();
            this.addNewModelDocumentProceed();
        }
    }

    addNewModelDocument = (isFormDirty = false) => {
        if(isFormDirty) {
            this.unsaveWhenAddNew();
        }
        if(!this.modelDocumentForm.form.dirty && !this.isNewEntryInProgress) {
            this.addNewModelDocumentProceed();
        }
    }

    private addNewModelDocumentProceed = () => {
        const newModelDocument: CustomizedNonFiscalDocument = {
            name: this.translate.instant('SETTINGS.MODEL_DOCUMENT.NEW_TEMPLATE'),
            type: 'generic_receipt',
            has_progressive: false,
            progressive_per_printer: false,
            progressive_prefix: '',
            daily_progressive: false,
            reset_progressive_on_closing: false,
            rules: []
        };
        this.filterList = '';
        this.modelDocumentList.push(newModelDocument);
        this.modelDocumentList = [...this.modelDocumentList];
        this.selectModelDocument(newModelDocument);
        this.modelDocumentForm.form.markAsDirty();
        this.isNewEntryInProgress = true;
    }

    addNewBlock = () => {
        if(this.selectedModelDocument.rules) {
            const rulesLen = this.selectedModelDocument.rules.length;
            const lastIndex = rulesLen - 1;
            const newIndex = rulesLen === 0 ? 0 : this.selectedModelDocument.rules[lastIndex].index! + 1;
            const newRule: NonfiscalDocumentsRulesPlus = {
                index: newIndex,
                type: 'logo',
                align: 'left',
                font_size: 1,
                bold: false,
                margin_top: 0,
                margin_bottom: 0,
                options: {},
                expansionPanel: true
            };
            this.closeAllPanel();
            this.selectedModelDocument.rules.push(newRule);
            this.modelDocumentForm.form.markAsDirty();
        }
    }

    addNewField = (item: any) => {
        let fields = [];
        let lastIndex = 0;
        if(item.options.fields && item.options.fields.length > 0) {
            fields = structuredClone(item.options.fields);
            fields = _.orderBy(fields, ['index'], ['asc'] );
            lastIndex = _.last(fields).index;
        }
        const newElement: {
            index: number;
            field: any;
            align: string;
            pattern?: string;
            prefix?: string;
            currency?: boolean;
            length?: string;
        } = {
            index: lastIndex + 1,
            field: null,
            align: 'left'
        };
        switch (item.type) {
            case 'sale_content':
                newElement.pattern = '%s';
                break;
            case 'sale_totals':
                newElement.prefix = '';
                newElement.currency = false;
                break;
        }
        fields.push(newElement);
        item.options.fields = fields;
    }

    private reorderIndex = (source: any[], startIndex: number) => {
        // reorder Index Fields
        if(source && source.length > 0) {
            let initIndex = startIndex;
            source.forEach(el => {
                el.index = initIndex++;
            });
        }
    }

    removeField = (item: NonfiscalDocumentsRulesPlus, field: any) => {
        _.pull(item.options.fields, field);
        // reorder Index Fields
        this.reorderIndex(item.options.fields, 1);
    }

    private cleanRules = () => {
        if (this.selectedModelDocument.rules && this.selectedModelDocument.rules.length > 0) {
            this.selectedModelDocument.rules.forEach((el: NonfiscalDocumentsRulesPlus) => {
                delete el.expansionPanel;
            });
        }
    }

    saveModelDocument = async () => {
        const _selectedModelDocument = this.selectedModelDocument

        const confirm = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'SETTINGS.SETTINGS.SAVE_CONFIRM',
                confirmLabel: 'SETTINGS.MODEL_DOCUMENT.CONFIRM_LABEL',
                cancelLabel: 'SETTINGS.MODEL_DOCUMENT.CANCEL_LABEL'
            }
        });

        if (confirm) {
            this.isProcessing = true;
            // delete expansionPanel property in rules
            this.cleanRules();

            const documentId = _selectedModelDocument.id;

            try {
                let target, result: CustomizedNonFiscalDocument;

                if (documentId) {
                    //PUT
                    result = await this.entityManagerService.nonfiscalDocuments.putOneOnline(_selectedModelDocument);
                    const found = this.modelDocumentList.find(el => el.id === documentId);

                    if (!found) {
                        // da chiedere a Luca
                        return;
                    }

                    target = found;
                } else {
                    // POST
                    result = await this.entityManagerService.nonfiscalDocuments.postOneOnline(_selectedModelDocument);

                    const lastIndex = this.modelDocumentList.length - 1;
                    target = this.modelDocumentList[lastIndex];
                }

                Object.assign(target, result);
                this.modelDocumentForm.form.markAsPristine();
                this.isNewEntryInProgress = false;
                this.selectModelDocument(target);
                const labelSuccess = this.translate.instant('SETTINGS.SETTINGS.SAVE_SUCCESSFUL');
                this._snackBar.open(labelSuccess, undefined, { duration: 3000, horizontalPosition: 'left', verticalPosition: 'bottom' });
            } finally {
                this.isProcessing = false;
            }
        }
    }

    deleteModelDocument = async () => {
        const _selectedModelDocument = this.selectedModelDocument;

        const confirm = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'SETTINGS.MODEL_DOCUMENT.MENU.DELETE_CONFIRM',
                confirmLabel: 'SETTINGS.MODEL_DOCUMENT.CONFIRM_LABEL',
                cancelLabel: 'SETTINGS.MODEL_DOCUMENT.CANCEL_LABEL'
            }
        });

        if (confirm) {
            this.isProcessing = true;

            try {
                if (_selectedModelDocument.id) {
                    await this.entityManagerService.nonfiscalDocuments.deleteOneOnline(_selectedModelDocument.id);
                    const modelDocumentToDelete = this.modelDocumentList.find(item => item.id === _selectedModelDocument.id);

                    if (modelDocumentToDelete) {
                        this.modelDocumentList.splice(this.modelDocumentList.indexOf(modelDocumentToDelete), 1);
                    }
                } else {
                    this.modelDocumentList.pop();
                }

                this.modelDocumentList = [...this.modelDocumentList];
                this.modelDocumentForm.form.markAsPristine();
                this.isNewEntryInProgress = false;
                this.filterList = '';
                this.selected = false;
                //this.selectFirstAvailableModelDocument();
            } finally {
                this.isProcessing = false;
            }
        }
    }

    duplicateItem = (item: NonfiscalDocumentsRulesPlus) => {
        if (item) {
            this.closeAllPanel();
            const copy = structuredClone(item);
            delete copy.id;
            delete copy.created_at;
            delete copy.updated_at;
            delete copy.deleted_at;
            copy.expansionPanel = true;
            if(this.selectedModelDocument.rules) {
                const i = _.findIndex(this.selectedModelDocument.rules, ['index' , item.index]);
                if(i !== -1) {
                    this.selectedModelDocument.rules.splice(i + 1, 0, copy);
                    this.modelDocumentForm.form.markAsDirty();
                    this.reorderIndex(this.selectedModelDocument.rules, 0);
                }
            }
        }
    }

    deleteItem = async (item: NonfiscalDocumentsRulesPlus) => {
        const _selectedModelDocument = this.selectedModelDocument;

        const confirm = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'SETTINGS.MODEL_DOCUMENT.MENU.DELETE_CONFIRM',
                confirmLabel: 'SETTINGS.MODEL_DOCUMENT.CONFIRM_LABEL',
                cancelLabel: 'SETTINGS.MODEL_DOCUMENT.CANCEL_LABEL'
            }
        });

        if (confirm) {
            if (_selectedModelDocument.rules && _selectedModelDocument.rules.length > 0) {
                const i = _.findIndex(_selectedModelDocument.rules, ['index' , item.index]);

                if(i !== -1) {
                    _selectedModelDocument.rules.splice(i, 1);
                    this.modelDocumentForm.form.markAsDirty();
                    this.reorderIndex(_selectedModelDocument.rules, 0);
                }
            }
        }
    }

    exportModelDocument = () => {
        if(this.selectedModelDocument) {
            const templateToDownload: CustomizedNonFiscalDocument = structuredClone(this.selectedModelDocument);
            delete templateToDownload.id;
            delete templateToDownload.created_at;
            delete templateToDownload.updated_at;
            delete templateToDownload.deleted_at;
            if (templateToDownload.rules && templateToDownload.rules.length > 0) {
                templateToDownload.rules.forEach((el: NonfiscalDocumentsRulesPlus) => {
                    delete el.expansionPanel;
                    delete el.id;
                    delete el.created_at;
                    delete el.updated_at;
                    delete el.deleted_at;
                });
            }
            this.util.downloadFile(JSON.stringify(templateToDownload, null, 2), `${templateToDownload.name}.tilbytemplate`, 'text/json');
        }
    }

    fnClickMenu($event: any) {
        $event.stopPropagation();
    }

    filterModelDocument() {
        this.filterToggle = !this.filterToggle;
        if(this.filterToggle) {
            setTimeout(() => this.renderer.selectRootElement("input[name=search]").focus(), 0);
        } else {
            this.filterList = '';
        }
    }
}

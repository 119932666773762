/**
 * Reflows a string into multiple lines, with the given number of characters per line.
 * @param str The string of array of strings to reflow
 * @param charsPerLine The number of characters per line.
 * @returns The array of reflowed lines.
 */
export const stringToLines = (str: string | string[], charsPerLine?: number): string[] => {
    const stringLines = typeof str === 'string' ? str.split('\n') : str;

    if (!charsPerLine) {
        return stringLines;
    }

    const result = [];

    for (let row of stringLines) {
        const linesWrapped = row.match(new RegExp(`.{1,${charsPerLine}}`, 'g')) || [];

        for (let line of linesWrapped) {
            result.push(line);
        }
    }

    return result;
}

/**
 * Removes fields from an object that have a given prefix.
 *
 * @param {Record<string, any>} obj - The object to remove fields from.
 * @param {string} prefix - The prefix to check for in the field names.
 * @return {object} - The object with the fields removed.
 */
export const removeFieldsWithPrefix = (obj: Record<string, any>, prefix: string): object => {
    for (const key in obj) {
        if (typeof obj[key] === 'object') {
            removeFieldsWithPrefix(obj[key], prefix);
        } else if (key.startsWith(prefix)) {
            delete obj[key];
        }
    }
    return obj;
}

const escapeReplaceMap: Record<string, string> = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
};

/**
 * Escapes the HTML entities in a string.
 * Reimplementation of lodash's _.escape
 *
 * @param {string} str - The string to escape.
 * @return {string} - The escaped string.
 */
export const escape = (str: string): string => {
    return str.replace(/[&<>"']/g, (char) => escapeReplaceMap[char] || char);
}

/**
 * Pads a string to the specified length with the specified character.
 * The string is padded to the center
 *
 * @param {string} string The string to be padded
 * @param {number} [length=0] The length of the resulting string
 * @param {string} [char=' '] The character used to pad the string
 * @returns {string} The padded string
 */
export const padCenter = (string: string, length: number = 0, char: string = ' '): string => {
    const totalPadding = length - string.length;

    if (totalPadding <= 0) {
        return string;
    }

    return `${char.repeat(Math.floor(totalPadding / 2))}${string}${char.repeat(Math.ceil(totalPadding / 2))}`;
}

/**
 * Converts the first character of the string to lowercase
 *
 * @param {string} str The string to be modified
 * @returns {string} The modified string
 */
export const lowerFirst = (str: string) => str.charAt(0).toLowerCase() + str.slice(1);

/**
 * Converts the first character of the string to uppercase
 *
 * @param {string} str The string to be modified
 * @returns {string} The modified string
 */
export const upperFirst = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);


/**
 * Converts a string to camel case.
 *
 * This function takes a string and converts it to camel case. The string can contain
 * spaces, dashes or underscores, which are removed and replaced with upper case
 * letters.
 *
 * @param {string} str The string to be converted
 * @returns {string} The converted string
 */
export const camelCase = (str: string) => {
    return str
        .replace(/-/g, ' ')
        .replace(/_/g, ' ')
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => index === 0 ? word.toLowerCase() : word.toUpperCase())
        .replace(/\s+/g, '');
};
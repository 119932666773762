import angular from 'angular';
import _ from 'lodash';

angular.module('dashboard').factory('dashboardReportGenerator', dashboardReportGenerator);

dashboardReportGenerator.$inject = ["$rootScope", "$translate", "$filter", "entityManager", "util"];

function dashboardReportGenerator($rootScope, $translate, $filter, entityManager, util) {
    const defaultPrinterColumns = 42;
    const sumAmounts = (vats) => _.sumBy(vats, 'amount');
    const sumDiscounts = (price_changes) => _.sumBy(price_changes, (change) => ['discount_fix', 'discount_perc'].includes(change.type) ? change.amount : 0);
    const sumSurcharges = (price_changes) => _.sumBy(price_changes, (change) => ['surcharge_fix', 'surcharge_perc'].includes(change.type) ? change.amount : 0);

    const exempts = {
        0: "ES N4",
        10: "EE N1",
        11: "NS N2",
        12: "NI N3",
        13: "RM N5",
        14: "AL N6",
        15: "VI"
    };

    const getVatValue = (vats, vatName) => (exempts[vatName] || `${vats[vatName].value}%` || "");

    const getPrinterReport = async (printer, options) => {
        if(!_.isObject(options)) {
            options = {};
        }

        let vats = options.vats || (await entityManager.vat.fetchCollectionOffline());
        let printerColumns = options.printerColumns || defaultPrinterColumns;
        let printerReport = [];

        let fiscalReceiptsTotal = !_.isEmpty(printer.fiscal_receipts?.vats) ? _(printer.fiscal_receipts.vats).map('amount').compact().sum() : 0;
        let invoicesTotal = !_.isEmpty(printer.invoices?.vats) ? _(printer.invoices.vats).map('amount').compact().sum() : 0;
        let fiscalReceiptsCount = _.toInteger(printer.fiscal_receipts?.count);
        let invoicesCount = _.toInteger(printer.invoices?.count);
        let grandTotal = _.chain([fiscalReceiptsTotal, invoicesTotal]).sum().thru($filter('sclCurrency')).padEnd(10, " ").value();
        let saleAverage = (fiscalReceiptsCount + invoicesCount) ? (util.round(fiscalReceiptsTotal + invoicesTotal) / (fiscalReceiptsCount + invoicesCount)) : 0;

        printerReport.push(_.padEnd($translate.instant('DASHBOARD.EXPORTED_DOCUMENT.TOTAL'), printerColumns - grandTotal.length, ' ') + grandTotal);
        
        if(saleAverage) {
            let saleAverageStr = _.chain(saleAverage).thru($filter('sclCurrency')).padEnd(10, ' ').value();
            printerReport.push(_.padEnd($translate.instant('DASHBOARD.EXPORTED_DOCUMENT.AVERAGE'), printerColumns - saleAverageStr.length, ' ') + saleAverageStr);
        }

        printerReport.push("");

        if(!_.isEmpty(printer.fiscal_receipts?.vats)) {
            let fiscalReceiptsLabel = $translate.instant('DASHBOARD.EXPORTED_DOCUMENT.FISCAL_RECEIPTS');
            let fiscalReceiptsTotalStr = _.padEnd($filter('sclCurrency')(fiscalReceiptsTotal), 10, ' ');

            printerReport.push(_.padEnd(`${fiscalReceiptsLabel} (${fiscalReceiptsCount})`, printerColumns - fiscalReceiptsTotalStr.length, " ") + fiscalReceiptsTotalStr);

            for(let vat of printer.fiscal_receipts.vats) {
                let label = "-- " + getVatValue(vats, vat.name);
                let value1 = _.padEnd($filter('sclCurrency')(vat.amount || 0), 10, " ");

                printerReport.push(_.padEnd(label,printerColumns - value1.length , " ") + value1);
            }
            
            let labelReceipts = $translate.instant('DASHBOARD.EXPORTED_DOCUMENT.UNCLAIMED_VAT');
            let valueReceipts = _.padEnd($filter('sclCurrency')(printer.fiscal_receipts.unclaimed || 0), 10, " ");
            
            printerReport.push(_.padEnd(labelReceipts,printerColumns - valueReceipts.length , " ") + valueReceipts);
            printerReport.push("");
        }

        if(!_.isEmpty(printer.invoices?.vats)) {
            let invoicesLabel = $translate.instant('DASHBOARD.EXPORTED_DOCUMENT.INVOICES');
            let invoicesTotalStr = _.padEnd($filter('sclCurrency')(invoicesTotal), 10, ' ');

            printerReport.push(_.padEnd(`${invoicesLabel} (${invoicesCount})`, printerColumns - invoicesTotalStr.length, " ") + invoicesTotalStr);

            for(let vat of printer.invoices.vats) {
                let label = "-- " + getVatValue(vats, vat.name);
                let value1 = _.padEnd($filter('sclCurrency')(vat.amount || 0), 10, " ");

                printerReport.push(_.padEnd(label,printerColumns - value1.length , " ") + value1);
            }

            let labelNRinvoices = $translate.instant('DASHBOARD.EXPORTED_DOCUMENT.UNCLAIMED_VAT');
            let valueNRinvoices = _.padEnd($filter('sclCurrency')(printer.invoices.unclaimed || 0), 10, " ");

            printerReport.push(_.padEnd(labelNRinvoices,printerColumns - valueNRinvoices.length , " ") + valueNRinvoices);
            printerReport.push("");
        }

        if(!_.isEmpty(printer.summary_rc_invoices?.vats)) {
            let summaryRCinvoicesLabel = $translate.instant('DASHBOARD.EXPORTED_DOCUMENT.SUMMARY_RC_INVOICES');
            printerReport.push(_.padEnd(summaryRCinvoicesLabel, printerColumns - summaryRCinvoicesLabel.length, " ") );

            for(let vat of printer.summary_rc_invoices.vats) {
                let label = "-- " + getVatValue(vats, vat.name);
                let value1 = _.padEnd($filter('sclCurrency')(vat.amount || 0), 10, " ");
                
                printerReport.push(_.padEnd(label,printerColumns - value1.length , " ") + value1);
            }

            printerReport.push("");
        }

        if(!_.isEmpty(printer.summary_nrc_invoices?.vats)) {
            let summaryNRCinvoicesLabel = $translate.instant('DASHBOARD.EXPORTED_DOCUMENT.SUMMARY_NRC_INVOICES');
            let value1 = _.padEnd($filter('sclCurrency')(sumAmounts(printer.summary_nrc_invoices.vats) || 0), 10, " ");

            printerReport.push(_.padEnd(summaryNRCinvoicesLabel,printerColumns - value1.length , " ") + value1);
            printerReport.push("");
        }

        if(!_.isEmpty(printer.refunds)) {
            let refundsLabel = $translate.instant('DASHBOARD.EXPORTED_DOCUMENT.REFUNDS');
            let value1 = _.padEnd($filter('sclCurrency')(Math.abs(printer.refunds.amount || 0)), 10, " ");

            printerReport.push(_.padEnd(refundsLabel, printerColumns - value1.length , " ") + value1);
            printerReport.push("");
        }

        if(!_.isEmpty(printer.voids)) {
            let voidsLabel = $translate.instant('DASHBOARD.EXPORTED_DOCUMENT.VOIDS');
            let value1 = _.padEnd($filter('sclCurrency')(Math.abs(printer.voids.amount || 0)), 10, " ");

            printerReport.push(_.padEnd(voidsLabel, printerColumns - value1.length , " ") + value1);
            printerReport.push("");
        }

        if(!_.isEmpty(printer.departments)) {
            printerReport.push($translate.instant('DASHBOARD.EXPORTED_DOCUMENT.DEPARTMENTS'));
            
            _.forEach(printer.departments, (data) => {
                let label = "- " + data.name;
                let value = _.padEnd($filter('sclCurrency')(data.amount || 0), 10, " ");
    
                printerReport.push(_.padEnd(label, printerColumns - value.length, " ") + value);
            });
    
            printerReport.push("");
        }

        if(!_.isEmpty(printer.channels)) {
            printerReport.push($translate.instant('DASHBOARD.EXPORTED_DOCUMENT.CHANNELS'));

            _.forIn(printer.channels, (channel, channelName) => {
                let label = "- " + channelName;
                let value = _.padEnd($filter('sclCurrency')(channel.amount || 0), 10, " ");

                printerReport.push(_.padEnd(label, printerColumns - value.length, " ") + value);
            });

            printerReport.push("");
        }

        let claimeds = _.filter(printer.payments, { unclaimed: 0 });

        if(!_.isEmpty(claimeds)) {
            printerReport.push(_.padEnd($translate.instant('DASHBOARD.EXPORTED_DOCUMENT.CLAIMED'), printerColumns, " "));

            for(let claimed of claimeds) {
                let label = "- " +  claimed.name;
                let value = _.padEnd($filter('sclCurrency')(claimed.amount || 0), 10, " ");
                printerReport.push(_.padEnd(label, printerColumns - value.length, " ") + value);
            }

            printerReport.push("");
        }

        let unclaimeds = _.filter(printer.payments, { unclaimed: 1 });

        if(!_.isEmpty(unclaimeds)) {
            printerReport.push(_.padEnd($translate.instant('DASHBOARD.EXPORTED_DOCUMENT.UNCLAIMED'), printerColumns, " "));

            for(let unclaimed of unclaimeds) {
                let label = "- " + unclaimed.name;
                let value = _.padEnd($filter('sclCurrency')(unclaimed.amount || 0), 10, " ");
                printerReport.push(_.padEnd(label, printerColumns - value.length, " ") + value);
            }

            printerReport.push("");
        }

        if(printer.cash_movements?.sales || printer.cash_movements?.tickets) {
            printerReport.push(_.padEnd($translate.instant('DASHBOARD.EXPORTED_DOCUMENT.DRAWER'), printerColumns, " "));
    
            if(printer.cash_movements?.sales) {
                let label = $translate.instant('DASHBOARD.EXPORTED_DOCUMENT.CASH');
                let value = _.padEnd($filter('sclCurrency')(printer.cash_movements.sales || 0), 10, " ");
    
                printerReport.push(_.padEnd(label, printerColumns - value.length, " ") + value);
                printerReport.push("");
            }
    
            if(printer.cash_movements?.tickets) {
                printerReport.push(_.padEnd($translate.instant('DASHBOARD.EXPORTED_DOCUMENT.TICKET'), printerColumns, " "));
    
                _.forEach(printer.cash_movements.tickets, (data) => {
                    let label = "-- " +  data.name;
                    let value = _.padEnd($filter('sclCurrency')(data.amount || 0) + " (" + data.count + ")", 10, " ");

                    printerReport.push(_.padEnd(label, printerColumns - value.length, " ") + value);
                });

                printerReport.push("");
            }
        }

        if(_.get(printer, ["other_changes_amount"], 0)) {
            let value = _.padEnd($filter('sclCurrency')(_.get(printer, ["other_changes_amount"], 0)), 10, " ");
            printerReport.push(_.padEnd($translate.instant('DASHBOARD.EXPORTED_DOCUMENT.OTHER_CHANGES'), printerColumns - value.length, " ") + value);

            printerReport.push("");        
        }

        let extraData = [
            {name: $translate.instant('DASHBOARD.EXPORTED_DOCUMENT.DISCOUNTS'), amount: $filter('sclCurrency')(sumDiscounts(printer.price_changes))},
            {name: $translate.instant('DASHBOARD.EXPORTED_DOCUMENT.SURCHARGES'), amount: $filter('sclCurrency')(sumSurcharges(printer.price_changes))},
            {name: $translate.instant('DASHBOARD.EXPORTED_DOCUMENT.ORDERS'), amount: _.get(printer, ["orders", "count"], 0)}
        ];

        for(let data of extraData) {
            let value = _.padEnd(_.toString(data.amount), 10, " ");
            printerReport.push(_.padEnd(data.name, printerColumns - value.length, " ") + value);
        }

        return printerReport;
    };

    const generateReport = async (printers, todayData, options) => {
        if(!_.isObject(options)) {
            options = {};
        }

        let text = [];
        let vats = await entityManager.vat.fetchCollectionOffline();
        let printerColumns = options.printerColumns || defaultPrinterColumns;

        text.push(_.padEnd($translate.instant('DASHBOARD.EXPORTED_DOCUMENT.TITLE'), printerColumns, ' ')); 
        text.push(_.padStart($filter('sclDate')(new Date()), ' ')); 
        text.push("");
        text.push("");

        for(let printer of printers) {
            text.push(_.padEnd('** ' + printer.name.toUpperCase() + ' **', printerColumns, ' ')); 
            text.push("");

            let printerReport = await getPrinterReport(printer, { vats, printerColumns });

            text.push(...printerReport);

            text.push("");
            text.push(_.pad("", printerColumns, "-"));
            text.push("");
        }

        if(!_.isEmpty(todayData.today.credit_notes)) {
            let creditNotesLabel = $translate.instant('DASHBOARD.EXPORTED_DOCUMENT.CREDIT_NOTES');
            let creditNotesValue = _.padEnd($filter('sclCurrency')(todayData.today.credit_notes.amount || 0), 10, " ");

            text.push( _.padEnd(creditNotesLabel,printerColumns - creditNotesValue.length , " ") + creditNotesValue );
            text.push("");
        }

        if(!_.isEmpty(todayData.today.cash_movements)) {
            text.push($translate.instant('DASHBOARD.EXPORTED_DOCUMENT.DRAWER_TOTAL'));
            let cashDrawerIncomeLabel = $translate.instant('DASHBOARD.EXPORTED_DOCUMENT.DEPOSIT');
            let cashDrawerIncomeValue = _.padEnd($filter('sclCurrency')(todayData.today.cash_movements.total?.income?.amount || 0), 10, " ");

            text.push( _.padEnd(cashDrawerIncomeLabel,printerColumns - cashDrawerIncomeValue.length , " ") + cashDrawerIncomeValue);

            let cashDrawerOutcomeLabel = $translate.instant('DASHBOARD.EXPORTED_DOCUMENT.WITHDRAWAL');
            let cashDrawerOutcomeValue = _.padEnd($filter('sclCurrency')(todayData.today.cash_movements.total?.outcome?.amount || 0), 10, " ");

            text.push( _.padEnd(cashDrawerOutcomeLabel,printerColumns - cashDrawerOutcomeValue.length , " ") + cashDrawerOutcomeValue);
            text.push("");
        }

        $rootScope.$broadcast('dashboardReport:reportCreated');

        return text.join("\n");
    };

    return {
        generateReport,
        getPrinterReport,
        getVatValue,
        sumAmounts,
        sumDiscounts,
        sumSurcharges
    };
}
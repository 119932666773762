<form *ngIf="customersForm" autocomplete="off" [formGroup]="customersForm" #customerForm (ngSubmit)="submitForm()"
      class="form tw-p-5 tw-flex tw-flex-col tw-gap-5">
    <ng-container formGroupName="generalForm">
        <section [id]="'client_data'">
            <mat-label class="tw-text-xl" translate>CUSTOMERS.DETAILS.CLIENT_DATA</mat-label>
            <section class="tw-pt-5 tw-grid sm:tw-flex tw-rounded-lg tw-items-center tw-gap-x-12 tw-flex-wrap">
                <mat-form-field color="primary" class="items">
                    <mat-label translate>CUSTOMERS.DETAILS.COMPANY_NAME</mat-label>
                    <input matInput formControlName="company_name" [maxlength]="validators?.companyNameMaxLength || 255"
                           [required]=" !(first_name?.value?.length || last_name?.value?.length)">
                    <mat-hint align="end">{{company_name?.value?.length || '0'}}
                        / {{validators?.companyNameMaxLength}}</mat-hint>
                    <mat-error *ngIf="company_name?.hasError('required')" translate>NG_MESSAGES.REQUIRED_FIELD
                    </mat-error>
                    <mat-error *ngIf="company_name?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                        (max. {{validators?.companyNameMaxLength}})
                    </mat-error>
                </mat-form-field>
                <mat-form-field color="primary" class="items" *ngIf="!!customTypes?.pop()">
                    <mat-label translate>CUSTOMERS.DETAILS.CATEGORY</mat-label>
                    <mat-select formControlName="custom_type">
                        <mat-option *ngFor="let type of customTypes" [value]="type">{{type}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field color="primary" class="items">
                    <mat-label translate>CUSTOMERS.DETAILS.NAME</mat-label>
                    <input matInput formControlName="first_name" [required]="customersForm.controls.generalForm.invalid || !company_name?.value?.length">
                    <mat-hint align="end">{{first_name?.value?.length || '0'}}
                        / {{validators?.firstNameMaxLength}}</mat-hint>
                    <mat-error *ngIf="first_name?.hasError('required')" translate>NG_MESSAGES.REQUIRED_FIELD</mat-error>
                    <mat-error *ngIf="first_name?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                        (max. {{validators?.firstNameMaxLength}})
                    </mat-error>
                </mat-form-field>
                <mat-form-field color="primary" class="items">
                    <mat-label translate>CUSTOMERS.DETAILS.SURNAME</mat-label>
                    <input matInput formControlName="last_name" [required]="customersForm.controls.generalForm.invalid || !company_name?.value?.length">
                    <mat-hint align="end">{{last_name?.value?.length || '0'}}
                        / {{validators?.lastNameMaxLength}}</mat-hint>
                    <mat-error *ngIf="last_name?.hasError('required')" translate>NG_MESSAGES.REQUIRED_FIELD</mat-error>
                    <mat-error *ngIf="last_name?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                        (max. {{validators?.lastNameMaxLength}})
                    </mat-error>
                </mat-form-field>
                <mat-form-field color="primary" class="items sm:tw-w-44">
                    <mat-label translate>CUSTOMERS.DETAILS.BIRTHDAY</mat-label>
                    <input matInput [matDatepicker]="$any(picker)" formControlName="birthdate">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="birthdate?.invalid">{{'NG_MESSAGES.INVALID_VALUE' | translate}}</mat-error>
                </mat-form-field>
                <mat-form-field color="primary" class="items">
                    <mat-label translate>CUSTOMERS.DETAILS.TAX_CODE</mat-label>
                    <input matInput formControlName="tax_code">
                    <mat-hint align="end">{{tax_code.customProps.hint ? tax_code.customProps.hint() : ''}}</mat-hint>
                    @if(tax_code.hasError('pattern')) {
                        <mat-error>{{'NG_MESSAGES.INVALID_VALUE' | translate}}</mat-error>
                    } @else if(tax_code.hasError('maxlength')) {
                        <mat-error>{{'NG_MESSAGES.NAME_TOO_LONG' | translate}} (max. {{validators?.taxCodeMaxLength}})</mat-error>
                    }
                </mat-form-field>
<!--                <div layout="row" flex="33" flex-sm="50" flex-xs="100"-->
<!--                     class="tw-flex">-->
                    <mat-form-field color="primary" class="items">
                        <mat-label translate>CUSTOMERS.DETAILS.VAT_CODE</mat-label>
                        <input matInput formControlName="vat_code" (input)="vatValidateStatus = 'default'">
                        <mat-error *ngIf="vat_code?.invalid">{{'NG_MESSAGES.INVALID_VALUE' | translate}}</mat-error>
                    </mat-form-field>
                    <button
                        mat-fab
                        type="button"
                        *ngIf="(vat_code?.value?.length || 0)>1"
                        (click)="searchVat(vat_code?.value)"
                        [color]="vatValidateStatus === 'invalid'?'warn':'primary'"
                        [ngClass]="{'vat-found': vatValidateStatus === 'found'}"
                    >
                        <mat-icon>search</mat-icon>
                    </button>
<!--                </div>-->
                @if((userCountry | uppercase) === 'IT' || userCountry == null) {
                    <mat-form-field color="primary" class="items">
                        <mat-label translate>CUSTOMERS.DETAILS.SDI_CODE</mat-label>
                        <input matInput formControlName="sdi_code">
                        <mat-hint align="end">{{sdi_code?.value?.length || '0'}} / {{validators?.sdiCodeMaxLength}}</mat-hint>
                        @if(sdi_code?.hasError('minlength')) {
                            <mat-error>{{'NG_MESSAGES.NAME_TOO_SHORT' | translate}} (min. {{validators?.sdiCodeMinLength}})</mat-error>
                        } @else if(sdi_code?.hasError('maxlength')) {
                            <mat-error>{{'NG_MESSAGES.NAME_TOO_LONG' | translate}} (max. {{validators?.sdiCodeMaxLength}})</mat-error>
                        }
                    </mat-form-field>
                    <mat-form-field color="primary" class="items">
                        <mat-label translate>CUSTOMERS.DETAILS.LOTTERY_CODE</mat-label>
                        <input matInput formControlName="lottery_code">
                        <mat-hint align="end">{{lottery_code?.value?.length || '0'}} / {{validators?.lotteryCodeMaxLength}}</mat-hint>
                        @if(lottery_code?.hasError('pattern')) {
                            <mat-error>{{'NG_MESSAGES.INVALID_VALUE' | translate}}</mat-error>
                        } @else if(lottery_code?.hasError('minlength')) {
                            <mat-error>{{'NG_MESSAGES.NAME_TOO_SHORT' | translate}} (min. {{validators?.lotteryCodeMinLength}})</mat-error>
                        } @else if(lottery_code?.hasError('maxlength')) {
                            <mat-error>{{'NG_MESSAGES.NAME_TOO_LONG' | translate}} (max. {{validators?.lotteryCodeMaxLength}})</mat-error>
                        }
                    </mat-form-field>
                }
                <mat-form-field color="primary" class="example-full-width">
                    <mat-label>Paese</mat-label>
                    <input matInput formControlName="country" [matAutocomplete]="$any(autoPaese)" type="text">
                    <mat-error
                        *ngIf="country?.hasError('matchRequired')">{{'NG_MESSAGES.INVALID_VALUE' | translate}}</mat-error>
                    <mat-autocomplete autoActiveFirstOption #autoPaese="matAutocomplete">
                        <mat-option *ngFor="let country of (country?.valueChanges|async|filteredCountry)"
                                    [value]="country.name">
                            {{country.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <mat-form-field *ngIf="(custom_type?.customProps?.inputChoices) as choises">
                    <mat-label [translate]="'CUSTOMERS.DETAILS.CUSTOM_TYPE'"></mat-label>
                    @if(isCustomerTypeIncludes){
                        <mat-select [formControlName]="'custom_type'">
                            <!--                <mat-option>None</mat-option>-->
                            <mat-option *ngFor="let choise of $any(choises)" [value]="choise?.value">{{choise.key}}</mat-option>
                        </mat-select>
                    } @else if(!isCustomerTypeIncludes){
                        <input matInput [formControlName]="'custom_type'" type="text" />
                    }
                </mat-form-field>

                <mat-form-field color="primary" class="items">
                    <mat-label translate>CUSTOMERS.DETAILS.NOTE</mat-label>
                    <input matInput formControlName="notes">
                </mat-form-field>
            </section>
        </section>
        <section id="gender" class="tw-pb-5 tw-flex tw-rounded-lg tw-items-center tw-gap-x-12 tw-flex-wrap">
            <mat-label class="tw-text-xl" translate>CUSTOMERS.DETAILS.GENDER</mat-label>
            <mat-radio-group aria-label="Select an option" color="primary" formControlName="gender"
                             class="tw-flex tw-gap-3">
                <mat-radio-button value="U">{{'CUSTOMERS.DETAILS.FORM.UNDEFINED' | translate}}</mat-radio-button>
                <mat-radio-button value="F">{{'CUSTOMERS.DETAILS.FEMALE' | translate}}</mat-radio-button>
                <mat-radio-button value="M">{{'CUSTOMERS.DETAILS.MALE' | translate}}</mat-radio-button>
            </mat-radio-group>
        </section>
        <section [id]="'contacts'">
            <mat-label class="tw-text-xl" translate>CUSTOMERS.DETAILS.CONTACTS</mat-label>
            <section class="tw-py-5 tw-grid sm:tw-flex tw-rounded-lg tw-items-center tw-gap-x-12 tw-flex-wrap">

                <mat-form-field color="primary" class="items">
                    <mat-label translate>CUSTOMERS.DETAILS.HOME_PHONE</mat-label>
                    <input matInput formControlName="phone">
                    <mat-hint align="end">{{phone?.value?.length || '0'}} / {{validators?.phoneMaxLength}}</mat-hint>
                    <mat-error *ngIf="phone?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                        (max. {{validators?.phoneMaxLength}})
                    </mat-error>
                </mat-form-field>
                <mat-form-field color="primary" class="items">
                    <mat-label translate>CUSTOMERS.DETAILS.CELLULAR</mat-label>
                    <input matInput formControlName="mobile">
                    <mat-hint align="end">{{mobile?.value?.length || '0'}} / {{validators?.mobileMaxLength}}</mat-hint>
                    <mat-error *ngIf="mobile?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}(max. {{validators?.mobileMaxLength}})</mat-error>
                </mat-form-field>

                <mat-form-field color="primary" class="items">
                    <mat-label translate>CUSTOMERS.DETAILS.EMAIL</mat-label>
                    <input matInput formControlName="email" type="email">
                    <mat-hint align="end">{{email?.value?.length || '0'}} / {{validators?.emailMaxLength}}</mat-hint>
                    <mat-error *ngIf="email?.invalid">{{'NG_MESSAGES.INVALID_VALUE' | translate}}</mat-error>
                    <mat-error *ngIf="email?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}(max. {{validators?.emailMaxLength}})</mat-error>
                </mat-form-field>
                <mat-form-field color="primary" class="items">
                    <mat-label translate>CUSTOMERS.DETAILS.PEC</mat-label>
                    <input matInput formControlName="email_pec">
                    <mat-hint align="end">{{email_pec?.value?.length || '0'}} / {{validators?.emailPecMaxLength}}</mat-hint>
                    <mat-error *ngIf="email_pec?.invalid">{{'NG_MESSAGES.INVALID_VALUE' | translate}}</mat-error>
                    <mat-error *ngIf="email_pec?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}(max. {{validators?.emailPecMaxLength}})</mat-error>
                </mat-form-field>
                <mat-form-field color="primary" class="items">
                    <mat-label translate>CUSTOMERS.DETAILS.FACEBOOK</mat-label>
                    <input matInput formControlName="facebook">
                    <mat-hint align="end">{{facebook?.value?.length || '0'}}
                        / {{validators?.socialMaxLength}}</mat-hint>
                    <mat-error *ngIf="facebook?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                        (max. {{validators?.socialMaxLength}})
                    </mat-error>
                </mat-form-field>
                <mat-form-field color="primary" class="items">
                    <mat-label translate>CUSTOMERS.DETAILS.TWITTER</mat-label>
                    <input matInput formControlName="twitter">
                    <mat-hint align="end">{{twitter?.value?.length || '0'}} / {{validators?.socialMaxLength}}</mat-hint>
                    <mat-error *ngIf="twitter?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                        (max. {{validators?.socialMaxLength}})
                    </mat-error>
                </mat-form-field>
                <mat-form-field color="primary" class="items">
                    <mat-label translate>CUSTOMERS.DETAILS.LINKEDIN</mat-label>
                    <input matInput formControlName="linkedin">
                    <mat-hint align="end">{{linkedin?.value?.length || '0'}}
                        / {{validators?.socialMaxLength}}</mat-hint>
                    <mat-error *ngIf="linkedin?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                        (max. {{validators?.socialMaxLength}})
                    </mat-error>
                </mat-form-field>
                <mat-form-field color="primary" class="items">
                    <mat-label translate>CUSTOMERS.DETAILS.INSTAGRAM</mat-label>
                    <input matInput formControlName="instagram">
                    <mat-hint align="end">{{instagram?.value?.length || '0'}}
                        / {{validators?.socialMaxLength}}</mat-hint>
                    <mat-error *ngIf="instagram?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                        (max. {{validators?.socialMaxLength}})
                    </mat-error>
                </mat-form-field>
            </section>
        </section>
        <section [id]="'fidelity'">
            <mat-label class="tw-text-xl" translate>CUSTOMERS.DETAILS.FIDELITY</mat-label>
            <section class="tw-py-5 tw-grid sm:tw-flex tw-rounded-lg tw-items-center tw-gap-x-12 tw-flex-wrap">
                <div class="tw-flex tw-items-center">
                    <mat-form-field color="primary" class="items">
                        <mat-label translate>{{fidelityLabel}}</mat-label>
                        <input matInput formControlName="fidelity">
                        <mat-hint align="end">{{fidelity?.value?.length || ''}}
                            / {{validators?.fidelityMaxLength}}</mat-hint>
                        <mat-error *ngIf="fidelity?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                            (max. {{validators?.fidelityMaxLength}})
                        </mat-error>
                    </mat-form-field>
                    <!--                    [onBarcodeScanned]="onFidelityScanned"-->
                    <app-barcode-scanner
                        (onBarcodeScannedChange)="onFidelityScanned($event)"
                    ></app-barcode-scanner>
                </div>

                <mat-form-field color="primary" class="items">
                    <mat-label translate>CUSTOMERS.DETAILS.DISCOUNT_PERCENTAGE</mat-label>
                    <input matInput type="number" formControlName="discount_perc">
                    <mat-error
                        *ngIf="discount_perc?.hasError('min')">{{'CUSTOMERS.DETAILS.PERCENTAGE_UNDERFLOW_ERROR' | translate}}</mat-error>
                    <mat-error
                        *ngIf="discount_perc?.hasError('max')">{{'CUSTOMERS.DETAILS.PERCENTAGE_OVERFLOW_ERROR' | translate}}</mat-error>
                </mat-form-field>
            </section>
        </section>
        <!--TODO CUSTOMFIELDS TRY -->
        <section *ngIf="customFields?.length !== 0" [id]="'custom_fields'">
            <mat-label class="tw-text-xl" translate>CUSTOMERS.DETAILS.CUSTOM_FIELDS</mat-label>
            <section class="tw-py-5 tw-grid sm:tw-flex tw-rounded-lg tw-items-center tw-gap-x-12 tw-flex-wrap">
                <mat-form-field color="primary" class="items"
                                *ngFor="let field of customFields; trackBy:fieldByIdx">
                    <mat-label translate>{{field.name}}</mat-label>
                    <input matInput type="text" [formControlName]="'custom_'+field.idx" [name]="'custom_'+field.idx">
                </mat-form-field>
            </section>
        </section>
    </ng-container>
    <ng-container formGroupName="addressesForm">
        <ng-container formGroupName="billingForm">
            <section [id]="'invoice_address'">
                <mat-label class="tw-text-xl" translate>CUSTOMERS.DETAILS.INVOICE_ADDRESS</mat-label>
                <section class="tw-py-5 tw-grid sm:tw-flex tw-rounded-lg tw-items-center tw-gap-x-12 tw-flex-wrap">
                    <mat-form-field color="primary" class="items">
                        <mat-label translate>CUSTOMERS.DETAILS.STREET_NAME</mat-label>
                        <input matInput formControlName="billing_street">
                        <mat-hint align="end">{{billing_street?.value?.length || '0'}}
                            / {{validators?.streetMaxLength}}</mat-hint>
                        <mat-error
                            *ngIf="billing_street?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                            (max. {{validators?.streetMaxLength}})
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field color="primary" class="items">
                        <mat-label translate>CUSTOMERS.DETAILS.STREET_NUMBER</mat-label>
                        <input matInput formControlName="billing_number">
                        <mat-hint align="end">{{billing_number?.value?.length || '0'}}
                            / {{validators?.numberMaxLength}}</mat-hint>
                        <mat-error
                            *ngIf="billing_number?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                            (max. {{validators?.numberMaxLength}})
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field color="primary" class="items">
                        <mat-label translate>CUSTOMERS.DETAILS.ZIP_CODE</mat-label>
                        <input matInput formControlName="billing_zip">
                        <mat-hint align="end">{{billing_zip?.value?.length || '0'}} /
                            {{validators?.zipMaxLength}}</mat-hint>
                        <mat-error
                            *ngIf="billing_zip?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                            (max. {{validators?.zipMaxLength}})
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field color="primary" class="items">
                        <mat-label translate>CUSTOMERS.DETAILS.CITY</mat-label>
                        <input matInput formControlName="billing_city">
                        <mat-hint align="end">{{billing_city?.value?.length || '0'}}
                            / {{validators?.cityMaxLength}}</mat-hint>
                        <mat-error
                            *ngIf="billing_city?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                            (max. {{validators?.cityMaxLength}})
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field color="primary" class="items">
                        <mat-label translate>CUSTOMERS.DETAILS.COUNTY</mat-label>
                        <input matInput formControlName="billing_prov">
                        <!--(blur)="billing_prov.setValue(billing_prov.value.toUpperCase())"-->
                        <mat-hint align="end">{{billing_prov?.value?.length || '0'}}
                            / {{validators?.provMaxLength}}</mat-hint>
                        <mat-error
                            *ngIf="billing_prov?.hasError('pattern')">{{'NG_MESSAGES.INVALID_VALUE' | translate}}</mat-error>
                        <mat-error
                            *ngIf="billing_prov?.hasError('minlength')">{{'NG_MESSAGES.NAME_TOO_SHORT' | translate}}
                            (max. {{validators?.provMinLength}})
                        </mat-error>
                        <mat-error
                            *ngIf="billing_prov?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                            (max. {{validators?.provMaxLength}})
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field color="primary" class="example-full-width">
                        <mat-label translate>CUSTOMERS.DETAILS.COUNTRY</mat-label>
                        <input matInput formControlName="billing_country" [matAutocomplete]="$any(autoNazione)"
                               type="text">
                        <mat-error
                            *ngIf="billing_country?.hasError('matchRequired')">{{'NG_MESSAGES.INVALID_VALUE' | translate}}</mat-error>
                        <mat-autocomplete autoActiveFirstOption #autoNazione="matAutocomplete">
                            <mat-option *ngFor="let country of (billing_country?.valueChanges|async|filteredCountry)"
                                        [value]="country.name">
                                {{country.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </section>

            </section>
        </ng-container>

        <ng-container>
            <section [id]="'shipping_address'">
                <mat-label class="tw-text-xl" translate>CUSTOMERS.DETAILS.SHIPPING_ADDRESS</mat-label>
                <section class="tw-py-5 tw-grid sm:tw-flex tw-rounded-lg tw-items-center tw-gap-x-12 tw-flex-wrap">
                    <mat-form-field color="accent" class="items">
                        <mat-select formControlName="currentShippingAddress"
                                    [placeholder]="'CUSTOMERS.DETAILS.SHIPPING_ADDRESS' | translate"
                                    (selectionChange)="currentSlot=$event.value">
                            <mat-option *ngFor="let val of shippingAddressRange"
                                        [value]="val.toString()">
                                {{val}}{{getShippingAddress(val)}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <ng-container *ngFor="let n of shippingAddressRange">
                        <ng-container *ngIf="currentSlot == n &&  currentAddress(n) as val"
                                      [formGroupName]="val.shippingForm">
                            <mat-form-field color="primary" class="items">
                                <mat-label translate>CUSTOMERS.DETAILS.STREET_NAME</mat-label>
                                <input matInput [formControlName]="val.shipping_street">
                                <mat-hint align="end">{{shipping_street(n)?.value?.length || '0'}}
                                    / {{validators?.streetMaxLength}}</mat-hint>
                                <mat-error
                                    *ngIf="shipping_street(n)?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                                    (max. {{validators?.streetMaxLength}})
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field color="primary" class="items">
                                <mat-label translate>CUSTOMERS.DETAILS.STREET_NUMBER</mat-label>
                                <input matInput [formControlName]="val.shipping_number">
                                <mat-hint align="end">{{shipping_number(n)?.value?.length || '0'}}
                                    / {{validators?.numberMaxLength}}</mat-hint>
                                <mat-error
                                    *ngIf="shipping_number(n)?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                                    (max. {{validators?.numberMaxLength}})
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field color="primary" class="items">
                                <mat-label translate>CUSTOMERS.DETAILS.ZIP_CODE</mat-label>
                                <input matInput [formControlName]="val.shipping_zip">
                                <mat-hint align="end">{{shipping_zip(n)?.value?.length || '0'}}
                                    / {{validators?.zipMaxLength}}</mat-hint>
                                <mat-error
                                    *ngIf="shipping_zip(n)?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                                    (max. {{validators?.zipMaxLength}})
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field color="primary" class="items">
                                <mat-label translate>CUSTOMERS.DETAILS.CITY</mat-label>
                                <input matInput [formControlName]="val.shipping_city">
                                <mat-hint align="end">{{shipping_city(n)?.value?.length || '0'}}
                                    / {{validators?.cityMaxLength}}</mat-hint>
                                <mat-error
                                    *ngIf="shipping_city(n)?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                                    (max. {{validators?.cityMaxLength}})
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field color="primary" class="items">
                                <mat-label translate>CUSTOMERS.DETAILS.COUNTY</mat-label>
                                <input matInput [formControlName]="val.shipping_prov">
                                <!-- (blur)="shipping_prov(n).setValue(shipping_prov(n).value.toUpperCase())"-->
                                <mat-hint align="end">{{shipping_prov(n)?.value?.length || '0'}}
                                    / {{validators?.provMaxLength}}</mat-hint>
                                <mat-error
                                    *ngIf="shipping_prov(n)?.hasError('pattern')">{{'NG_MESSAGES.INVALID_VALUE' | translate}}</mat-error>
                                <mat-error
                                    *ngIf="shipping_prov(n)?.hasError('minlength')">{{'NG_MESSAGES.NAME_TOO_SHORT' | translate}}
                                    (max. {{validators?.provMinLength}})
                                </mat-error>
                                <mat-error
                                    *ngIf="shipping_prov(n)?.hasError('maxlength')">{{'NG_MESSAGES.NAME_TOO_LONG' | translate}}
                                    (max. {{validators?.provMaxLength}})
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field color="primary" class="example-full-width">
                                <mat-label>Nazione</mat-label>
                                <input matInput [formControlName]="val.shipping_country"
                                       [matAutocomplete]="$any(autoShippingNazione)"
                                       type="text">
                                <mat-error
                                    *ngIf="shipping_country(n)?.hasError('matchRequired')">{{'NG_MESSAGES.INVALID_VALUE' | translate}}</mat-error>
                                <mat-autocomplete autoActiveFirstOption #autoShippingNazione="matAutocomplete">
                                    <mat-option
                                        *ngFor="let country of (shipping_country(n)?.valueChanges|async|filteredCountry)"
                                        [value]="country.name">
                                        {{country.name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                        </ng-container>
                    </ng-container>
                </section>
            </section>
        </ng-container>
    </ng-container>
    <ng-container formGroupName="optionsForm">
        <section [id]="'options'">
            <mat-label class="tw-text-xl" translate>CUSTOMERS.DETAILS.OPTIONS</mat-label>
            <section class="tw-py-5 tw-grid sm:tw-flex tw-rounded-lg tw-items-center tw-gap-x-12 tw-flex-wrap">
                <section class="tw-grid sm:tw-flex tw-items-center tw-gap-x-12 tw-flex-wrap">
                    <mat-form-field color="primary" class="items">
                        <mat-label translate>CUSTOMERS.DETAILS.PAYMENT_LIMIT</mat-label>
                        <mat-select multiple formControlName="payments_allowed">
                            <mat-option *ngFor="let pm of paymentMethods" [value]="pm.id">{{pm.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field color="primary" class="items">
                        <mat-label translate>CUSTOMERS.DETAILS.DEFAULT_PRICELIST</mat-label>
                        <mat-select formControlName="default_pricelist">
                            <mat-option *ngFor="let pl of priceLists" [value]="pl.id">{{pl.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field color="primary" class="items">
                        <mat-label translate>CUSTOMERS.DETAILS.CREATED_IN</mat-label>
                        <input matInput formControlName="get_source">
                    </mat-form-field>
                    <mat-form-field color="primary" class="items">
                        <mat-label translate>CUSTOMERS.DETAILS.EXTERNAL_ID</mat-label>
                        <input matInput formControlName="external_id">
                    </mat-form-field>
                    <mat-form-field color="primary" class="items">
                        <mat-label translate>CUSTOMERS.DETAILS.PAYMENT_TOKEN</mat-label>
                        <input matInput formControlName="payment_token">
                    </mat-form-field>
                </section>

                <section class="tw-grid sm:tw-flex tw-gap-x-12 tw-flex-wrap tw-items-center">
                    <mat-checkbox color="primary" formControlName="send_commercials"><span
                        class="tw-whitespace-normal">{{'CUSTOMERS.DETAILS.SEND_COMMERCIALS' | translate}}</span>
                    </mat-checkbox>
                    <mat-checkbox color="primary" formControlName="disable_mail_receipts"><span
                        class="tw-whitespace-normal">{{'CUSTOMERS.DETAILS.DISABLE_MAIL' | translate}}</span>
                    </mat-checkbox>
                </section>
            </section>
        </section>

    </ng-container>
</form>


import angular from 'angular';
import { EntityManagerService } from 'src/app/core';

angular.module('bookings', ['application']).config(["$stateProvider", "$translatePartialLoaderProvider", function($stateProvider: any, $translatePartialLoaderProvider: any) {
    $translatePartialLoaderProvider.addPart('bookings');

    $stateProvider.state('app.new.bookings', {
        url: '/bookings',
        redirectTo: 'app.new.bookings.showcase',
    });

    $stateProvider.state('app.new.bookings.showcase', {
        url: '/showcase',
        template: '<bookings-showcase [rooms]="$resolve.rooms" [bookings]="$resolve.bookings" [booking-shifts]="$resolve.bookingShifts"></bookings-showcase>',
        resolve: {
            rooms: ["entityManager", (entityManager: EntityManagerService) => entityManager.rooms.fetchCollectionOffline()],
            bookings: ["entityManager", (entityManager:EntityManagerService) => entityManager.bookings.fetchCollectionOffline()],
            bookingShifts: ["entityManager", (entityManager:EntityManagerService) => entityManager.bookingShifts.fetchCollectionOffline()]
        }
    });

    $stateProvider.state('app.new.bookings.showcase.customer', {
        url: '/customer',
        params: {
            customer: null
        },
        template: '<bookings-showcase [rooms]="$resolve.rooms" [bookings]="$resolve.bookings" [bookingShifts]="$resolve.bookingShifts"></bookings-showcase>',
        resolve: {
            rooms: ["entityManager", (entityManager: EntityManagerService) => entityManager.rooms.fetchCollectionOffline()],
            bookings: ["entityManager", (entityManager:EntityManagerService) => entityManager.bookings.fetchCollectionOffline()],
            bookingShifts: ["entityManager", (entityManager:EntityManagerService) => entityManager.bookingShifts.fetchCollectionOffline()],
        }
    });
}]);

import angular from "angular";
import { CashdrawerPaymentDialog } from "../dialog/cashdrawer-payment-dialog/cashdrawer-payment-dialog";
import { GloryCashdrawerDriver } from "./glory-cashdrawer-driver";
import { DigitalPaymentHandler } from "src/app/shared/model/digital-payments.model";
import { DocumentPrintHook, DocumentPrinterOptions } from "src/app/shared/model/document-printer.model";
import { Sales } from "tilby-models";


class GloryCashdrawer implements DigitalPaymentHandler, DocumentPrintHook {
    constructor(
        private $translate: any,
        private CashdrawerPaymentDialog: CashdrawerPaymentDialog,
        private GloryCashdrawerDriver: GloryCashdrawerDriver,
    ) {
    }

    public async payment(amount: number, options: any) {
        let paymentMethod = options.paymentMethod;

        if (!paymentMethod.schema_name) {
            throw this.$translate.instant('DIGITAL_PAYMENTS.COMMON.BUNDLE_NAME_NOT_FOUND');
        }

        return this.CashdrawerPaymentDialog.show(amount, options.paymentMethod, this.GloryCashdrawerDriver, "Cassetto Glory");
    }

    public isEnabled(): boolean {
        return true;
    }

    public async printFailHook(sale: Sales, printerDocumentData: DocumentPrinterOptions): Promise<string | undefined> {
        const payment = sale.payments?.find((payment) => payment.payment_method_type_id == 40);

        if(!payment) {
            return;
        }

        // TODO: implement
    }
}

GloryCashdrawer.$inject = ["$translate", "CashdrawerPaymentDialog", "GloryCashdrawerDriver"];

angular.module('digitalPayments').service('GloryCashdrawer', GloryCashdrawer);

<mat-tab-group animationDuration="0ms" mat-stretch-tabs class="tw-w-full tw-h-full" [class.tw-hidden]="!showTabs()" (selectedTabChange)="selectedTabChanged($event)">
    <mat-tab label="{{'PROMOTIONS.DETAILS.TABS_LABEL.GENERAL' | translate}}">
        @if (promotionForm) {
            <tilby-magic-form [form]="promotionForm"></tilby-magic-form>
        }
    </mat-tab>
    <mat-tab label="{{'PROMOTIONS.DETAILS.TABS_LABEL.ARTICLES' | translate}}">
        @if (promotionForm) {
        <promotions-items [promotion]="promotion" [suppliers]="suppliers"
            [categories]="categories" [channels]="channels" [chainShops]="chainShops" (formDetailsMarkAsDirty)="updatePromotionFormMarkAsDirty($event)" />
        }
    </mat-tab>
</mat-tab-group>
@if (!showTabs()) {
     <promotions-items [promotion]="promotion" [suppliers]="suppliers"
        [categories]="categories" [channels]="channels" [chainShops]="chainShops" (formDetailsMarkAsDirty)="updatePromotionFormMarkAsDirty($event)" />
}

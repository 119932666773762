import angular from 'angular';
import _ from 'lodash';

angular.module('application').directive('sclPrecision', ["$rootScope", function($rootScope) {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function(scope, element, attributes, ngModel) {
            var precision = _.isEmpty(attributes.sclPrecision) ? $rootScope.currentCurrency.decimal_digits : attributes.sclPrecision;

            function setPrecision() {
                var value = ngModel.$modelValue;

                if (!_.isNil(value) && !_.isNaN(value)) {
                    ngModel.$setViewValue(Number(value).toFixed(precision));
                    ngModel.$render();
                }
            }

            element.on('blur', setPrecision);
            setTimeout(setPrecision, 0); //after initial page render
        }
    };
}]);
export const ITALY = 'Italy';
export const REGEX_TIME_VALIDATOR = new RegExp(/^(?:[01]\d|2[0-3]):[0-5]\d$/);
export class MathUtils {
    static round(num: number, decimals = 2) {
        if (typeof num !== 'number') {
            return NaN;
        }

        return Number(`${Math.round(Number(`${num.toFixed(8)}e${decimals}`))}e-${decimals}`);
    }
}


/**
 * Groups the elements of an array based on a given function.
 *
 * @param {T[]} arr - The array to be grouped.
 * @param {(element: T) => any} f - The function used to determine the grouping.
 * @return {Record<string, T[]>} An object containing the grouped elements.
 */
export function groupBy<T>(arr: T[], f: (element: T) => any): Record<string, T[]> {
    const groups: Record<string, T[]> = {};

    for (const item of arr) {
        const group = `${f(item)}`;

        if (!Array.isArray(groups[group])) {
            groups[group] = [];
        }

        groups[group].push(item);
    }

    return groups;
}

/**
 * A function that creates a dictionary of elements from an array, using a specified key function.
 *
 * @param {T[]} arr - The array from which to create the dictionary.
 * @param {(element: T) => any} f - The key function used to generate the keys for the dictionary.
 * @param {Object} options - The optional configuration options.
 * @param {boolean} options.firstMatchOnly - If true, when there are duplicates of the same key, only the first element will be included in the dictionary instead of the last one.
 * @return {Record<string, T>} - A dictionary where the keys are generated using the key function and the values are the corresponding elements from the array.
 */
export function keyBy<T>(arr: T[], f: (element: T) => any, options?: { firstMatchOnly?: boolean }): Record<string, T> {
    const dictionary: Record<string, T> = {};

    for (const item of arr) {
        const key = `${f(item)}`;

        if (!options?.firstMatchOnly || !dictionary[key]) {
            dictionary[key] = item;
        }
    }

    return dictionary;
}

/**
 * A function that creates a new object with the selected keys from the original object, based on a predicate function.
 *
 * @param {T} obj - The input object.
 * @param {(value: any, key: string) => boolean} predicate - The function used to select the keys.
 * @return {Partial<T>} - A new object with the selected keys.
 */
export function pickBy<T>(obj: T, predicate: (value: T[keyof T], key: keyof T) => boolean): Partial<T> {
    const picked: Partial<T> = {};

    for (const key in obj) {
        if (predicate(obj[key], key)) {
            picked[key] = obj[key];
        }
    }

    return picked;
}

/**
 * Returns a new object with all properties of the input object that do not satisfy the provided predicate.
 *
 * @param {T} obj - The input object.
 * @param {(value: T[keyof T], key: keyof T) => boolean} predicate - The predicate function that determines which properties to omit.
 * @param {boolean} recursive - Optional. Specifies whether to recursively omit properties from nested objects. Defaults to false.
 * @return {Partial<T>} - The new object with the omitted properties.
 */
export function omitBy<T>(obj: T, predicate: (value: T[keyof T], key: keyof T) => boolean, recursive: boolean = false): Partial<T> {
    const omitted: any = {};

    for (const key in obj) {
        if (!predicate(obj[key], key)) {
            if (recursive && typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
                omitted[key] = omitBy(obj[key] as any, predicate, recursive);
            } else {
                omitted[key] = obj[key];
            }
        }
    }

    return omitted;
}


/**
 * Removes elements from an array that satisfy a given predicate function.
 * The original array is modified in-place.
 *
 * @param {T[]} arr - The array to remove elements from.
 * @param {(element: T) => boolean} predicate - The predicate function used to determine if an element should be removed.
 * @return {T[]} An array containing the removed elements.
 */
export function removeFromArray<T>(arr: T[], predicate: (element: T) => boolean) {
    const removedElements: T[] = [];

    // Remove elements from the end of the array in reverse order to avoid index issues
    for (let i = arr.length - 1; i >= 0; i--) {
        if (predicate(arr[i]!)) {
            removedElements.push(arr.splice(i, 1)[0]!);
        }
    }

    return removedElements;
}

/**
 * Executes an array of async tasks in parallel with a limit on the number of concurrent tasks.
 *
 * @param {T[]} tasks - An array of tasks to execute.
 * @param {(element: T, index: number) => Promise<any>} workerFunction - The function to execute for each task.
 * @param {object} [options] - Optional parameters.
 * @param {number} [options.parallelLimit=5] - The maximum number of tasks to execute concurrently.
 * @param {boolean} [options.abortOnError=false] - Whether to abort the execution if an error occurs.
 * @return {Promise<{error: boolean, results: Promise<any>[]}>} - A promise that resolves to an object containing the error flag and the results of each task
 *      (if an element of results is undefined, the task has not been executed).
 */
export async function asyncParallelLimit<T>(tasks: T[], workerFunction: (element: T, index: number) => Promise<any>, options?: { parallelLimit?: number, abortOnError?: boolean }) {
    const taskEntries = tasks.entries(); //We need this to be an iterable iterator as each worker will consume each task at most once
    const workers = Array(options?.parallelLimit || 5).fill(taskEntries) as IterableIterator<[number, T]>[];
    const results: Promise<any>[] = Array(tasks.length);
    let error = false;

    await Promise.allSettled(workers.map(async (worker) => {
        for (const [index, task] of worker) {
            try {
                results[index] = workerFunction(task, index);
                await results[index];
            } catch (err) {
                error = true;
            }

            //Aborts the loop if an error occurred (either in this worker or in other workers)
            if (error && options?.abortOnError) {
                break;
            }
        }
    }));

    //Returns the results of each task
    return { error, results };
}

/**
 * Divides an array into sub-arrays of length n.
 *
 * @param {T[]} arr - The array to divide.
 * @param {number} n - The length of the sub-arrays. If n is negative or zero, the array is not split and the function returns a single-element array containing a copy of the input array.
 * @return {T[][]} - An array of sub-arrays.
 */
export function chunk<T>(arr: T[], n: number): T[][] {
    if (n <= 0) {
        return [arr.slice()];
    }

    const chunks: T[][] = [];
    const length = arr.length;

    for (let i = 0; i < length; i += n) {
        chunks.push(arr.slice(i, i + n));
    }

    return chunks;
}

/**
 * Computes the difference between two arrays, returning an array containing
 * elements present in the first array but not in the second.
 *
 * @param {T[]} object1 - The first array to compare.
 * @param {T[]} object2 - The second array to compare against.
 * @return {T[]} An array containing elements that are in the first array but not in the second.
 */
export function difference<T>(array: T[], ...values: T[][]): T[] {
    let result = array;

    for (const arr of values) {
        const set = new Set(arr);
        result = result.filter(item => !set.has(item));
    }

    return result;
}

/**
 * Computes the intersection of multiple arrays, returning an array containing
 * elements present in all input arrays.
 *
 * @param {T[][]} arrays - The arrays to intersect.
 * @return {T[]} An array containing elements that are present in all input arrays.
 */
export function intersection<T>(...arrays: T[][]): T[] {
    let [result, ...values] = arrays;

    if (!result) {
        return [];
    }

    for (const arr of values) {
        const set = new Set(arr);
        result = result.filter(item => set.has(item));
    }

    return result;
}

/**
 * Compares two objects and returns a new object with the changed attributes.
 *
 * @param {Object} currentObject - The first object to compare.
 * @param {Object} previousObject - The second object to compare against.
 * @param {any} [missingValue=undefined] - The value to use for missing attributes.
 * @return {Partial<Object>} A new object with the changed attributes.
 */
export function compareObjects<T extends Object>(currentObject: T, previousObject: T, missingValue: any = undefined): Partial<T> {
    const changedAttributes: Partial<T> = {};

    const currentKeys = Object.keys(currentObject);
    const previousKeys = Object.keys(previousObject);

    const keys = Array.from(new Set([...currentKeys, ...previousKeys])) as Array<keyof T>;

    for (const key of keys) {
        // Check if key is missing in currentObject
        if (!currentObject.hasOwnProperty(key)) {
            changedAttributes[key] = missingValue;
            continue;
        }

        // Compare values if key exists in both objects
        if (currentObject[key] !== previousObject[key]) {
            changedAttributes[key] = currentObject[key];
        }
    }

    return changedAttributes;
}

/**
 * Compares two objects to determine if they have identical keys and values.
 *
 * @param {T} currentObject - The first object to compare.
 * @param {T} previousObject - The second object to compare against.
 * @return {boolean} True if both objects have the same keys and values, false otherwise.
 */

export function isEqual<T = any>(currentObject: T, previousObject: T): boolean {
    // Quick reference check
    if (currentObject === previousObject) {
        return true;
    }

    // Null/undefined checks and ensure both are objects
    if (currentObject == null || previousObject == null || typeof currentObject !== 'object' || typeof previousObject !== 'object') {
        return false;
    }

    // Special handling for Date objects
    if (currentObject instanceof Date && previousObject instanceof Date) {
        return currentObject.getTime() === previousObject.getTime();
    }

    const currentKeys = Object.keys(currentObject);
    const previousKeys = Object.keys(previousObject);

    // Check key count
    if (currentKeys.length !== previousKeys.length) {
        return false;
    }

    // Use Set for key comparison (based on benchmark)
    const keys = Array.from(new Set([...currentKeys, ...previousKeys])) as Array<keyof T>;

    // Ensure same keys
    if (keys.length !== currentKeys.length) {
        return false;
    }

    // Compare values
    for (const key of keys) {
        const currentValue = currentObject[key];
        const previousValue = previousObject[key];

        // Deep comparison for objects/arrays
        if (currentValue !== previousValue) {
            // If both are objects, recursively compare
            if (
                currentValue && previousValue &&
                typeof currentValue === 'object' &&
                typeof previousValue === 'object'
            ) {
                if (!isEqual(currentValue, previousValue)) {
                    return false;
                }
            } else {
                return false;
            }
        }
    }

    return true;
}

/**
 * Checks if all the properties in object `b` are present and have the same values in object `a`.
 *
 * @param {T} a - The object to be checked against.
 * @param {Partial<T>} b - The object containing properties to match in `a`.
 * @return {boolean} True if all properties in `b` match those in `a`, false otherwise.
 */
export function matches<T>(a: T, b: Partial<T>): boolean {
    const bKeys = Object.keys(b) as Array<keyof T>;

    for (const key of bKeys) {
        if (a[key] !== b[key]) {
            return false;
        }
    }

    return true;
}

/**
 * Binary search to find an element in a sorted array.
 *
 * @param {T[]} arr - The array to search. Must be sorted.
 * @param {(element: T) => number} predicate - The function used to compare elements. 
 *        Should return:
 *          - a negative number if `element` is less than the target.
 *          - a positive number if `element` is greater than the target.
 *          - zero if `element` is equal to the target.
 * @return {{element: T, index: number} | undefined} - The element and its index in the array, or undefined if not found.
 */
export function binaryFind<T>(arr: T[], predicate: (element: T) => number): { element: T, index: number } | undefined {
    let min = 0;
    let max = arr.length - 1;

    while (min <= max) {
        const mid = Math.floor((min + max) / 2);
        const element = arr[mid];

        if (predicate(element) < 0) {
            min = mid + 1;
        } else if (predicate(element) > 0) {
            max = mid - 1;
        } else {
            return { element, index: mid };
        }
    }

    return undefined;
}
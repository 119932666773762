import angular from 'angular';
import _ from 'lodash';

angular.module('application').factory('optionsDialog', optionsDialog);

optionsDialog.$inject = ["$rootScope", "$mdDialog"];

function optionsDialog($rootScope, $mdDialog) {
    optionsDialogController.$inject = ["$scope", "$mdDialog", "title", "fields"];

    function optionsDialogController($scope, $mdDialog, title, fields) {
        Object.assign($scope, {
            fields: fields,
            selectedOptions: Array(fields.length).fill(null),
            title: title,
        });

        //Setup defaults
        for(let i = 0; i < fields.length; i++) {
            if(fields[i].default) {
                $scope.selectedOptions[i] = fields[i].default;
            }
        }

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.confirm = function() {
            $mdDialog.hide($scope.selectedOptions);
        };
    }

    const optionsDialog = {
        show: function(title, fields, options) {
            $rootScope.hideAppLoader();

            let defaultOptions = {
                controller: optionsDialogController,
                template: require('./options-dialog.html'),
                locals: {
                    title: title,
                    fields: fields,
                }
            };

            if (options) {
                _.merge(options, defaultOptions);

                if(options.blocking) {
                    options.clickOutsideToClose = false;
                    options.escapeToClose = false;
                }
            } else {
                options = defaultOptions;
            }

            return $mdDialog.show(options);
        }
    };

    return optionsDialog;
}
import angular from 'angular';
import _ from 'lodash';

angular.module('settings').controller('KioskSettingsCtrl', KioskSettingsCtrl);

KioskSettingsCtrl.$inject = ["$scope", "$translate", "checkManager", "confirmDialog", "preferencesData", "alertDialog", "toast"];

function KioskSettingsCtrl($scope, $translate, checkManager, confirmDialog, preferencesData, alertDialog, toast) {
    Object.assign($scope, {
        preferencesData: preferencesData,
        preferencesDataReset: _.cloneDeep(preferencesData),
        topbar_context:  {
            title: $translate.instant('SETTINGS.TOPBAR_SETTINGS.KIOSK_SETTINGS'),
            view: 'home'
        }
    });

    // trasform string value in number value
    $scope.preferencesData['kiosk.general.standby_timeout'] = _.isUndefined(preferencesData['kiosk.general.standby_timeout']) ? 20 : _.toNumber(preferencesData['kiosk.general.standby_timeout']);
    $scope.preferencesData['kiosk.general.customer_assistant_timeout'] = _.isUndefined(preferencesData['kiosk.general.customer_assistant_timeout']) ? 10 : _.toNumber(preferencesData['kiosk.general.customer_assistant_timeout']);
    $scope.preferencesData['kiosk.confirmation.timeout'] = _.isUndefined(preferencesData['kiosk.confirmation.timeout']) ? 10 : _.toNumber(preferencesData['kiosk.confirmation.timeout']);
    $scope.preferencesData['kiosk.catalog.default_discount_value'] = _.isUndefined(preferencesData['kiosk.catalog.default_discount_value']) ? 0 : _.toNumber(preferencesData['kiosk.catalog.default_discount_value']);
    $scope.preferencesData['kiosk.general.header_height'] = _.isUndefined(preferencesData['kiosk.general.header_height']) ? 144 : _.toNumber(preferencesData['kiosk.general.header_height']);

    var createRanges = function(start, end) {
        return _(_.range(start, (end + 1))).map(function(x) { return { val: x, key: x }; }).value();
    };

    $scope.tabs = [
        {
            name: 'GENERAL',
            label: $translate.instant('SETTINGS.KIOSK.TABS.GENERAL')
        },
        {
            name: 'APPARANCE',
            label: $translate.instant('SETTINGS.KIOSK.TABS.APPARANCE')
        },
        {
            name: 'SERVICE',
            label: $translate.instant('SETTINGS.KIOSK.TABS.SERVICE')
        },
        {
            name: 'CATALOG',
            label: $translate.instant('SETTINGS.KIOSK.TABS.CATALOG')
        },
        {
            name: 'PAYMENTS',
            label: $translate.instant('SETTINGS.KIOSK.TABS.PAYMENTS')
        },
        {
            name: 'ADVANCED',
            label: $translate.instant('SETTINGS.KIOSK.TABS.ADVANCED')
        },
    ];

    $scope.zoomLevels = [
        { key: "1", val: 1 },
        { key: "1.1", val: 1.1 },
        { key: "1.2", val: 1.2 },
        { key: "1.3", val: 1.3 },
        { key: "1.4", val: 1.4 },
        { key: "1.5", val: 1.5 },
        { key: "1.6", val: 1.6 },
        { key: "1.7", val: 1.7 },
        { key: "1.8", val: 1.8 },
        { key: "1.9", val: 1.9 },
        { key: "2", val: 2 }
    ];

    $scope.textFonts = [
        { key: "Arial", val: "Arial" },
        { key: "Verdana", val: "Verdana" },
        { key: "Helvetica", val: "Helvetica" },
        { key: "Tahoma", val: "Tahoma" },
        { key: "Trebuchet MS", val: "Trebuchet MS" },
        { key: "Times New Roman", val: "Times New Roman" },
        { key: "Georgia", val: "Georgia" },
        { key: "Garamond", val: "Garamond" },
        { key: "Courier New", val: "Courier New" },
        { key: "Brush Script MT", val: "Brush Script MT" }
    ];

    $scope.itemBorderSize = createRanges(0, 5);

    $scope.itemMarginSize = createRanges(0, 15);

    $scope.gridTextAlignment = [
        { key: "left",    val: $translate.instant('SETTINGS.KIOSK.CATALOG.LEFT')      },
        { key: "center",  val: $translate.instant('SETTINGS.KIOSK.CATALOG.CENTER')    },
        { key: "right",   val: $translate.instant('SETTINGS.KIOSK.CATALOG.RIGHT')     },
        { key: "justify", val: $translate.instant('SETTINGS.KIOSK.CATALOG.JUSTIFIED') }
    ];

    $scope.gridOrder = [
        { key: "+name",  val: $translate.instant('SETTINGS.KIOSK.CATALOG.NAME')             },
        { key: "+index", val: $translate.instant('SETTINGS.KIOSK.CATALOG.INDEX')            },
        { key: "+price", val: $translate.instant('SETTINGS.KIOSK.CATALOG.ASCENDING_PRICE')  },
        { key: "-price", val: $translate.instant('SETTINGS.KIOSK.CATALOG.DESCENDING_PRICE') }
    ];

    // ---------- start priceListNames ----------
    $scope.priceListNames = [];
    _.times(10, function(idx) {
        $scope.priceListNames.push({
            index: (idx + 1),
            fieldName: 'price' + (idx + 1),
            value: checkManager.getPreference("price_list_" + (idx + 1) + "_name") || $translate.instant('SETTINGS.KIOSK.CATALOG.PRICE_LIST_START') + ' ' + (idx + 1),
            hidden: checkManager.getPreference("price_list_"  + (idx + 1) +  "_hide") ? true : false
        });
    });
    $scope.enabledPriceLists = _.filter($scope.priceListNames, { hidden: false });
    // ---------- end priceListNames ----------

    $scope.defaultViewMode = [
        { key: "gridView", val: $translate.instant('SETTINGS.KIOSK.CATALOG.GRID') },
        { key: "listView", val: $translate.instant('SETTINGS.KIOSK.CATALOG.LIST') }
    ];

    $scope.itemsPerRow = createRanges(1, 5);

    $scope.defaultDiscountType = [
        { key: "none",  val: $translate.instant('SETTINGS.KIOSK.CATALOG.NONE')       },
        { key: "fixed", val: $translate.instant('SETTINGS.KIOSK.CATALOG.FIXED')      },
        { key: "prec",  val: $translate.instant('SETTINGS.KIOSK.CATALOG.PERCENTAGE') }
    ];

    var inputOFgroupImages = [
        // Indirizzo immagine/video standby
        {
            label: $translate.instant('SETTINGS.KIOSK.GENERAL.MEDIA_URL_STANDBY'),
            preference: 'kiosk.standby.media_url'
        },
        // Indirizzo immagine/video saluti
        {
            label: $translate.instant('SETTINGS.KIOSK.CONFIRMATION.MEDIA_URL_CONFIRMATION'),
            preference: 'kiosk.confirmation.media_url'
        },
        // Indirizzo immagine logo azienda
        {
            label: $translate.instant('SETTINGS.KIOSK.GENERAL.MEDIA_URL_LOGO'),
            preference: 'kiosk.general.media_url_logo'
        },
        // Indirizzo immagine sfondo selezione servizio
        {
            label: $translate.instant('SETTINGS.KIOSK.SERVICE_SELECTION.BACKGROUND_URL'),
            preference: 'kiosk.service_selection.background_url'
        },
        // Indirizzo immagine testata selezione servizio
        {
            label: $translate.instant('SETTINGS.KIOSK.SERVICE_SELECTION.BANNER_URL'),
            preference: 'kiosk.service_selection.banner_url'
        },
        // Indirizzo immagine sfondo catalogo
        {
            label: $translate.instant('SETTINGS.KIOSK.CATALOG.BACKGROUND_URL'),
            preference: 'kiosk.catalog.background_url'
        },
        // Indirizzo immagine testata catalogo
        {
            label: $translate.instant('SETTINGS.KIOSK.CATALOG.BANNER_URL'),
            preference: 'kiosk.catalog.banner_url'
        },
        // Indirizzo immagine sfondo carrello
        {
            label: $translate.instant('SETTINGS.KIOSK.CART.BACKGROUND_URL'),
            preference: 'kiosk.cart.background_url'
        },
        // Indirizzo immagine testata carrello
        {
            label: $translate.instant('SETTINGS.KIOSK.CART.BANNER_URL'),
            preference: 'kiosk.cart.banner_url'
        },
        // Indirizzo immagine sfondo selezione documento
        {
            label: $translate.instant('SETTINGS.KIOSK.DOCUMENT_SELECTION.BACKGROUND_URL'),
            preference: 'kiosk.document_selection.background_url'
        },
        // Indirizzo immagine testata selezione documento
        {
            label: $translate.instant('SETTINGS.KIOSK.DOCUMENT_SELECTION.BANNER_URL'),
            preference: 'kiosk.document_selection.banner_url'
        },
        // Indirizzo immagine sfondo sezione cliente
        {
            label: $translate.instant('SETTINGS.KIOSK.CUSTOMER_SELECTION.BACKGROUND_URL'),
            preference: 'kiosk.customer_selection.background_url'
        },
        // Indirizzo immagine testata sezione cliente
        {
            label: $translate.instant('SETTINGS.KIOSK.CUSTOMER_SELECTION.BANNER_URL'),
            preference: 'kiosk.customer_selection.banner_url'
        },
        // Indirizzo immagine sfondo pagamento
        {
            label: $translate.instant('SETTINGS.KIOSK.PAYMENT_SELECTION.BACKGROUND_URL'),
            preference: 'kiosk.payment_selection.background_url'
        },
        // Indirizzo immagine testata pagamento
        {
            label: $translate.instant('SETTINGS.KIOSK.PAYMENT_SELECTION.BANNER_URL'),
            preference: 'kiosk.payment_selection.banner_url'
        },
        // last element is read in catalog content
        // Indirizzo immagine di default articolo del catalogo
        {
            label: $translate.instant('SETTINGS.KIOSK.CATALOG.ITEM_IMAGE_PLACEHOLDER'),
            preference: 'kiosk.catalog.item_image_placeholder'
        }
    ];
    // init groupImages
    $scope.groupImages = {};
    // load groupImages from the data of inputOFgroupImages
    _.forEach(inputOFgroupImages, function(obj) {
        var preference = obj.preference;
        var objOFgroupImages = {
            imgLogo: preferencesData[preference],
            thumbnail: null,
            itemThumbnail: null,
            itemImages: [],
            imagesCallbacks: [],
            imagesDirty: false,
            hasImage: function() {
                return _(this.itemImages).reject({ deleted: true }).size() >= 1;
            }
        };

        if (objOFgroupImages.imgLogo) {
            var img = { image_url: objOFgroupImages.imgLogo, deleted: false };
            objOFgroupImages.itemImages.push(img);
            objOFgroupImages.itemThumbnail = img;
        }

        $scope.groupImages[preference] = objOFgroupImages;
    });

    /*
    $scope.groupImages = {
        'kiosk.service_selection.background_url': {
            imgLogo: preferencesData['kiosk.service_selection.background_url'],
            thumbnail: null,
            itemThumbnail: null,
            itemImages: [],
            imagesCallbacks: [],
            imagesDirty: false,
            hasImage: function() {
                return _(this.itemImages).reject({ deleted: true }).size() >= 1;
            }
        },
        {...},
        {...}
    };
    */

    // groupImages used in apparance content
    $scope.apparanceImages = inputOFgroupImages.slice(0, -1);
    // groupImages used in catalog content
    $scope.catalogImages = inputOFgroupImages.slice(-1);

    $scope.savePreferences = function() {
        console.log("save clicked");
        var changedPrefs = {};

        var savePreferences = function() {
            _.forIn(changedPrefs, function(val, key) {
                if(!_.startsWith(key, '_')) {
                    if(_.isArray(val) || _.isObject(val)) {
                        checkManager.setShopPreference(key, JSON.stringify(val));
                    } else {
                        checkManager.setShopPreference(key, val);
                    }
                }
            });

            _.forIn($scope.groupImages, function(val, key) {
                var imgPromises = [];
                // call image-handler functions
                _.forEach(val.imagesCallbacks, function(actualFunction) {
                    imgPromises.push(actualFunction());
                });

                var allImgPromise = Promise.all(imgPromises);

                allImgPromise.catch(function(reason) {
                    alertDialog.show($translate.instant('ITEMS.DETAILS.IMAGES_SAVE_HAS_ERRORS'));
                }).finally(function() {
                    val.imgLogo = _.get(val.itemImages, [0, 'image_url'], '');
                    checkManager.setShopPreference(key, val.imgLogo);
                });
            });
        };

        var getChangedPreferences = function(current, previous) {
            _.forIn(current, function(val, key) {
                if(!_.isEqual(val, previous[key])) {
                    _.set(changedPrefs, [key], val);
                }
            });
        };

        confirmDialog.show($translate.instant('SETTINGS.SETTINGS.SAVE_CONFIRM')).then(function(result) {
            if(result) {
                getChangedPreferences($scope.preferencesData, $scope.preferencesDataReset);
                savePreferences();

                $scope.preferencesDataReset = _.cloneDeep($scope.preferencesData);
                toast.show({ message: $translate.instant('SETTINGS.SETTINGS.SAVE_SUCCESSFUL'), hideDelay: 10000 });
            }
        });
    };

    $scope.topbar_context.saveButton = {
        label: $translate.instant('SETTINGS.KIOSK.SAVE'),
        action: $scope.savePreferences,
        disabled: function() {
            return false;
        },
        visible: function() {
            return true;
        }
    };

    $scope.onTabSelected = function(tab) {
        $scope.topbar_context.tabView = tab;
    };
}
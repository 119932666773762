import angular from 'angular';

angular.module('kiosk').component('kioskHeader', {
    bindings: {
        headerParams: "<"
    },
    template: require('./kiosk-header.html'),
    controller: ["kioskUtils", "$state", "checkManager", "userSessionsManager", function(kioskUtils, $state, checkManager, userSessionsManager) {
        this.$onInit = function() {
            if(this.headerParams) {
                this.bannerStyle = {
                    'height': this.headerParams.bannerHeight ? this.headerParams.bannerHeight + 'px' : '144px',
                    'background-color': this.headerParams.bannerBgColor || 'transparent'
                };
                this.videoStyle = {
                    'max-height': this.headerParams.bannerHeight ? this.headerParams.bannerHeight + 'px' : '144px'
                };

                if(this.headerParams.bannerUrl) {
                    this.bannerType = kioskUtils.getMediaType(this.headerParams.bannerUrl);
                }

                this.disposition = this.headerParams.title ? "space-between center" : "end center";
                this.zoomLevel = this.headerParams.zoomLevel;

                this.titleRowStyle = {
                    'justify-content': this.headerParams.title ? 'space-between' : 'flex-end',
                    'zoom': this.headerParams.zoomLevel
                };
            }
        };

        this.exit = async () => {
            const user = await userSessionsManager.show({ disableNewUser: true, canDismiss: true, selectActiveUser: true });

            if(user) {
                $state.go('app.new.cashregister');
            }
        };

        this.help = function() {
            // @toDO
            console.debug('AIUTO Da completare');
        };
    }]
});

import { 
    Directive, 
    ElementRef, 
    Injector, 
} from '@angular/core';

import {UpgradeComponent} from "@angular/upgrade/static";
import angular from 'angular';

// AngularJs component con ui-view (per utilizzare ui-router)
class RouterOutletWrapper {}

let routerOutletWrapperOne = {
    controller: RouterOutletWrapper,
    template: `<div class="ui-view" name="one" style="height:100%"/>`
};

angular
    .module('TilbyApp')
    .component('routerOutletWrapperOne', routerOutletWrapperOne);

// UpgradeComponent per Angular con ui-view Upgradato (per utilizzare ui-router in contesto Angular)
@Directive({
    standalone:true,
    selector: '[appUpgradeUiViewOne]',
    host: {style:  'height: 100%'}

})
export class UpgradeUiViewOneDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef, injector: Injector) {
        super('routerOutletWrapperOne', elementRef, injector);
    }
}
import angular from 'angular';
import html2pdf from 'html2pdf.js';
import _ from 'lodash';
import * as QRCode from 'qrcode';

angular.module('settings').controller('EcommerceSettingsCtrl', ["$scope", "$translate", "$filter", "confirmDialog", "alertDialog", "checkManager", "preferencesData", "fontFamily", "$q", "util", "items", "itemSelector", "rooms", "$window", "$rootScope", "toast", "$timeout", function($scope, $translate, $filter, confirmDialog, alertDialog, checkManager, preferencesData, fontFamily, $q, util, items, itemSelector, rooms, $window, $rootScope, toast, $timeout) {
    const itemDictionary = _.groupBy(items, _.property(['id']) );
    const parseItemIdList = (input) => {
        if(input) {
            input = _.toString(input);
            const arrInput = input.replace(/[\[\]]/g, '').split(',');
            return arrInput.map( el => _.toNumber(el) );
        } else {
            return [];
        }
    };

    _.assign($scope, {
        preferencesData: preferencesData,
        preferencesDataReset: _.cloneDeep(preferencesData),
        topbar_context:  {
            tabView: 'general',
            landscapeMode: false,
            view: 'home',
            title: $translate.instant('SETTINGS.TOPBAR_SETTINGS.E_COMMERCE_SETTINGS'),
            goToEcommerce: {
                label: $translate.instant('SETTINGS.TOPBAR_SETTINGS.GO_TO_ECOMMERCE.LABEL'),
                tooltip: $translate.instant('SETTINGS.TOPBAR_SETTINGS.GO_TO_ECOMMERCE.TOOLTIP'),
                disabled: () => false,
                visible: () => true,
                action: () => util.openExternalLink(`http://${$scope.preferencesData['ecommerce.hostname']}.tilby.it`)
            },
            deleteButton: {
                visible: () => false,
            },
            saveButton: {
                label: $translate.instant('SETTINGS.TOPBAR_SETTINGS.SAVE'),
                disabled: () => false,
                visible: () => true,
                action: $scope.savePreferences
            }
        },
        mainFontFamily: _.cloneDeep(fontFamily),
        fontLabel: $translate.instant('SETTINGS.E_COMMERCE.APPEARANCE.MAIN_FONT_FAMILY'),
        fontPreviewTooltip: $translate.instant('SETTINGS.E_COMMERCE.APPEARANCE.OPEN_FONT_PREVIEW'),
        filterFontFamily: function(fontText) {
            return $filter('filter')($scope.mainFontFamily, fontText);
        },
        openFontPreview: function() {
            util.openExternalLink( fontToLink($scope.preferencesData['ecommerce.theme.mainFontFamily']) );
        },
        paymentsGateway: [
            {
                name: $translate.instant('SETTINGS.E_COMMERCE.PAYMENTS.NONE'),
                value: null
            },
            {
                name: 'Nexi',
                value: 'nexi'
            },
            {
                name: 'Stripe',
                value: 'stripe'
            }
        ],
        viewGrid: [
            {
                label: $translate.instant('SETTINGS.E_COMMERCE.CATALOG.VIEW_GRID.GRID'),
                value: 'on'
            },
            {
                label: $translate.instant('SETTINGS.E_COMMERCE.CATALOG.VIEW_GRID.LIST'),
                value: 'off'
            }
        ],
        addButton: $translate.instant('SETTINGS.E_COMMERCE.BUTTON.ADD'),
        deleteButton: $translate.instant('SETTINGS.E_COMMERCE.BUTTON.DELETE'),
        editButton: $translate.instant('SETTINGS.E_COMMERCE.BUTTON.EDIT'),
        item_id_list: parseItemIdList(preferencesData['ecommerce.home.item_id_list']),
        urlRegExp: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
        hostname: preferencesData['ecommerce.hostname'] + '.tilby.it',
        loadingQrCode: false
    });

    $scope.getImage = (id) => {
        var itemImagePlaceholder = 'assets/images/sales/piatto-search.png';
        var thumbnail = itemDictionary[id] ? itemDictionary[id][0].thumbnail : itemImagePlaceholder;
        return thumbnail ? thumbnail : itemImagePlaceholder;
    };
    $scope.getName = (id) => {
        var name = itemDictionary[id] ? itemDictionary[id][0].name : 'placeholder';
        return name ? name : 'placeholder';
    };
    $scope.addNewFeaturedProduct = (index) => {
        itemSelector.show(items).then(function(item) {
            if (item) {
                $scope.item_id_list[index] = item.id;
            }
        });
    };
    $scope.deleteFeaturedProduct = (index) => {
        confirmDialog.show($translate.instant('SETTINGS.E_COMMERCE.HOME.DELETE_CONFIRM')).then( answer => {
            if(answer) {
                $scope.item_id_list.splice(index, 1);
            }
        });
    };

    // trasform string value in number value
    var trasformStringToNumber = (str) => {
        return (str && str !== 0) ? _.toNumber(str) : 0;
    };
    var bookingCancellationNotice = $scope.preferencesData['ecommerce.bookinsg.bookingCancellationNotice'];
    $scope.preferencesData['ecommerce.bookinsg.bookingCancellationNotice'] = trasformStringToNumber(bookingCancellationNotice);

    var bookingNotice = $scope.preferencesData['ecommerce.bookings.bookingNotice'];
    $scope.preferencesData['ecommerce.bookings.bookingNotice'] = trasformStringToNumber(bookingNotice);

    var takeAwayNotice = $scope.preferencesData['ecommerce.shifts.take_away_notice'];
    $scope.preferencesData['ecommerce.shifts.take_away_notice'] = trasformStringToNumber(takeAwayNotice);

    var deliveryScheduledNotice = $scope.preferencesData['ecommerce.shifts.delivery_scheduled_notice'];
    $scope.preferencesData['ecommerce.shifts.delivery_scheduled_notice'] = trasformStringToNumber(deliveryScheduledNotice);

    $scope.preferencesData['ecommerce.products.viewGrid'] = preferencesData['ecommerce.products.viewGrid'] ? 'on' : 'off';
    $scope.mainFontFamilyValue = _.find(fontFamily, ['family', $scope.preferencesData['ecommerce.theme.mainFontFamily'] ]);

    $scope.fontChange = function(fontObj) {
        if (fontObj) {
            $scope.preferencesData['ecommerce.theme.mainFontFamily'] = fontObj.family;
        }
    };

    var fontToLink = function(font) {
        var urlSuffix, urlPrefix = 'https://fonts.google.com/?query=';
        if (typeof(font) === 'object') {
            urlSuffix = font.family.replace(" ", "+");
        } else {
            urlSuffix = font.replace(" ", "+");
        }
        return urlPrefix + urlSuffix;
    };

    $scope.goToTab = function(id) {
        $scope.topbar_context.tabView = id;
        $scope.tabIndexCopy = $scope.selectedTabIndex;
        var saveButton = {
            label: $translate.instant('SETTINGS.SETTINGS.SAVE'),
            action: $scope.savePreferences,
            disabled: function() {
                return false;
            },
            visible: function() {
                return true;
            }
        };
        $scope.topbar_context.saveButton = saveButton;
    };

    var inputOFgroupImages = [
        // Indirizzo immagine general logo
        {
            label: $translate.instant('SETTINGS.E_COMMERCE.GENERAL.IMG_LOGO'),
            preference: 'ecommerce.imgLogo'
        },
        // Indirizzo immagine Logo home page
        {
            label: $translate.instant('SETTINGS.E_COMMERCE.HOME.BACKGROUND_IMAGE'),
            preference: 'ecommerce.home.image_url'
        }
    ];
    // init groupImages
    $scope.groupImages = {};
    // load groupImages from the data of inputOFgroupImages
    _.forEach(inputOFgroupImages, function(obj) {
        var preference = obj.preference;
        var objOFgroupImages = {
            imgLogo: preferencesData[preference],
            thumbnail: null,
            itemThumbnail: null,
            itemImages: [],
            imagesCallbacks: [],
            imagesDirty: false,
            hasImage: function() {
                return _(this.itemImages).reject({ deleted: true }).size() >= 1;
            }
        };

        if (objOFgroupImages.imgLogo) {
            var img = { image_url: objOFgroupImages.imgLogo, deleted: false };
            objOFgroupImages.itemImages.push(img);
            objOFgroupImages.itemThumbnail = img;
        }

        $scope.groupImages[preference] = objOFgroupImages;
    });

    /*
    $scope.groupImages = {
        'ecommerce.imgLogo': {
            imgLogo: preferencesData['ecommerce.imgLogo'],
            thumbnail: null,
            itemThumbnail: null,
            itemImages: [],
            imagesCallbacks: [],
            imagesDirty: false,
            hasImage: function() {
                return _(this.itemImages).reject({ deleted: true }).size() >= 1;
            }
        },
        'ecommerce.home.image_url': {
            imgLogo: preferencesData['ecommerce.home.image_url'],
            thumbnail: null,
            itemThumbnail: null,
            itemImages: [],
            imagesCallbacks: [],
            imagesDirty: false,
            hasImage: function() {
                return _(this.itemImages).reject({ deleted: true }).size() >= 1;
            }
        }
    };
    */

    // groupImages used in general content
    $scope.generalImages = inputOFgroupImages.slice(0, -1);
    // groupImages used in home content
    $scope.homeImages = inputOFgroupImages.slice(-1);

    $scope.savePreferences = function() {
        console.log("save clicked");
        var changedPrefs = {};

        var savePreferences = function() {
            _.forIn(changedPrefs, function(val, key) {
                if(!_.startsWith(key, '_')) {
                    if(_.isArray(val) || _.isObject(val)) {
                        checkManager.setShopPreference(key, JSON.stringify(val));
                    } else {
                        checkManager.setShopPreference(key, val);
                    }
                }
            });

            _.forIn($scope.groupImages, function(val, key) {
                var imgPromises = [];
                // call image-handler functions
                _.forEach(val.imagesCallbacks, function(actualFunction) {
                    imgPromises.push(actualFunction());
                });

                var allImgPromise = $q.all(imgPromises);

                allImgPromise.catch(function(reason) {
                    alertDialog.show($translate.instant('ITEMS.DETAILS.IMAGES_SAVE_HAS_ERRORS'));
                }).finally(function() {
                    val.imgLogo = _.get(val.itemImages, [0, 'image_url'], '');
                    checkManager.setShopPreference(key, val.imgLogo);
                });
            });
        };

        var getChangedPreferences = function(current, previous) {
            _.forIn(current, function(val, key) {
                if(!_.isEqual(val, previous[key])) {
                    _.set(changedPrefs, [key], val);
                }
            });
        };

        confirmDialog.show($translate.instant('SETTINGS.SETTINGS.SAVE_CONFIRM')).then(function(result) {
            if(result) {
                $scope.preferencesData['ecommerce.home.item_id_list'] = $scope.item_id_list;
                getChangedPreferences($scope.preferencesData, $scope.preferencesDataReset);
                savePreferences();

                $scope.preferencesDataReset = _.cloneDeep($scope.preferencesData);
                toast.show({ message: $translate.instant('SETTINGS.SETTINGS.SAVE_SUCCESSFUL'), hideDelay: 10000 });
            }
        });

    };

    const generateHtmlForPDF = async () => {
        const hostName = $scope.preferencesData['ecommerce.hostname'];
        const wrapperStart = "<div style='height:1038px;width:21cm;padding: 5px;'>";
        const wrapperEnd = "</div>";
        const rowStart = "<div style='display:flex;height:50%;margin:0;'>";
        const rowEnd = "</div>";
        const breakPage = "<span class='break-page'></span>";
        const contents = [];

        let tempArrRows = [];
        let tempArrBoxes = [];
        let page = "";
        let boxes = "";

        for (const room of rooms) {
            for (const t of room.tables) {
                const qrcode = `http://${hostName}.tilby.it/it/selfordering?tableId=${t.id}`;

                const svg = await QRCode.toString(qrcode, {
                    errorCorrectionLevel: 'M',
                    type: 'svg',
                    scale: 1,
                    width: 200
                });

                const objQr = { sale: room.name, table: t.name, qrcode: qrcode, svg: svg };

                if (tempArrRows.length < 2) {
                    if (tempArrBoxes.length < 2) {
                        tempArrBoxes.push(objQr);
                        if (tempArrBoxes.length === 2) {
                            tempArrRows.push(tempArrBoxes);
                            tempArrBoxes = [];
                        }
                    }
                    if (tempArrRows.length === 2) {
                        page = wrapperStart;
                        for (const row of tempArrRows) {
                            page += rowStart;
                            for (const box of row) {
                                boxes += `<div style='margin:0;border:1px solid #000;text-align:center;padding:20px 10px;'>
                                            <div style='font-size:20px;font-weight:bold;'>${box.sale}</div>
                                            <div style='font-size:24px;font-weight:bold;margin-bottom:1.5em;'>${box.table}</div>
                                            <div style='font-size:22px;text-align:center;margin-bottom:2em;'>
                                                <div style='font-weight:bold;'>${$translate.instant('SETTINGS.E_COMMERCE.SERVICES.SCAN_QRCODE')}</div>
                                                <div><em>Scan the QR Code to start ordering</em></div>
                                            </div>
                                            ${box.svg}
                                        </div>`;
                            }
                            page += boxes + rowEnd;
                            boxes = "";
                        }
                        page += wrapperEnd + breakPage;
                        contents.push(page);
                        page = "";
                        tempArrRows = [];
                    }
                }
            }
        }

        const contentBoxes = contents.join('');

        const opt = {
            margin: 0,
            filename: `${hostName}-selfordering.pdf`,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 1, ignoreElements: (el) => (el.nodeName === 'HEAD' || el.id === 'content') },
            jsPDF: { unit: 'cm', format: 'letter', orientation: 'portrait' },
            pagebreak: { mode: ['css'], after: '.break-page' }
        };

        html2pdf().set(opt).from(contentBoxes).save().catch((err) => {
            toast.show({
                message: $translate.instant('SETTINGS.E_COMMERCE.SERVICES.ERROR_CREATE_PDF'),
                actions: [{
                    text: $translate.instant('MISC.OK'),
                    action: 'dismiss',
                    highlight: true
                }],
                hideDelay: 0
            });
        });
    };

    $scope.generateQrcode = () => {
        $scope.loadingQrCode = true;
        $timeout(() => generateHtmlForPDF().finally(() => $scope.loadingQrCode = false));
    };

}]);

import _ from 'lodash';

const XMLNode = function (name, attributes, value, options) {
    var self = this;

    _.assign(self, {
        _name: _.toString(name),
        _value: value,
        _attributes: {},
        _escape: true
    });

    if (_.isObject(attributes)) {
        _.forIn(attributes, function (val, name) {
            if (!_.isNil(val) && !_.isNaN(val)) {
                _.set(self._attributes, [name], val);
            }
        });
    }

    if (_.isObject(options)) {
        _.assign(self, options);
    }
};

XMLNode.prototype.appendChild = function (child) {
    if (!_.isArray(this._value)) {
        this._value = [];
    }

    this._value.push(child);

    return this;
};

XMLNode.prototype.removeChild = function (target) {
    if (_.isArray(this._value)) {
        var idx = _.findIndex(this._value, function (child) {
            return child === target;
        });

        if (idx !== -1) {
            _.pullAt(this._value, idx);
        }
    } else {
        this._value = [];
    }

    return this;
};

XMLNode.prototype.children = function (target) {
    return _.isArray(this._value) ? this._value : [];
};

XMLNode.prototype.toString = function () {
    if (_.isArray(this._value)) {
        var elements = _.map(this._value, function(child) {
            return child.toString();
        });

        return getXmlTag(this._name, elements.join(''), this._attributes);
    } else {
        var finalVal = this._escape ? _.escape(_.toString(this._value)) : _.toString(this._value);
        return getXmlTag(this._name, finalVal, this._attributes);
    }
};

var getXmlTag = function (tagName, tagVal, tagAttrs) {
    var finalvalue = _.toString(tagVal);

    var attrs = _.map(tagAttrs, function (val, name) {
        return _.escape(_.toString(name)) + '="' + _.escape(val) + '"';
    });

    attrs = _.isEmpty(attrs) ? '' : ' ' + attrs.join(' ');

    return (tagName ? '<' + tagName + attrs + '>' : '') + finalvalue + (tagName ? '</' + tagName + '>' : '');
};

export { XMLNode };
import angular from 'angular';
import _ from 'lodash';

angular.module('printers').factory('newPrinter', ["$mdDialog", function($mdDialog) {

    newPrinterController.$inject = ["$scope", "$translate"];
    function newPrinterController($scope, $translate) {
        var vendors = {
            fiscal: [{
                name: "RCH",
                value: "rch"
            },{
                name: "Epson",
                value: "epson"
            }],
            rt: [{
                name: "RCH",
                value: "rch"
            },{
                name: "Epson",
                value: "epson"
            },{
                name: "Custom / Italretail",
                value: "custom"
            },{
                name: "Axon (vecchio protocollo)",
                value: "axon"
            },{
                name: "Axon Hydra II",
                value: "axon_g100"
            },{
                name: "DTR",
                value: "dtr"
            }]
        };

        $scope.printerTypes = [{
            value: "fiscal",
            name: $translate.instant('PRINTERS.NEW_DIALOG.FISCAL')
        },{
            value: "rt",
            name: $translate.instant('PRINTERS.NEW_DIALOG.RT')
        },{
            value: "nonfiscal",
            name: $translate.instant('PRINTERS.NEW_DIALOG.NONFISCAL')
        },{
            value: "kds",
            name: $translate.instant('PRINTERS.NEW_DIALOG.KDS')
        },{
            value: "receipt",
            name: $translate.instant('PRINTERS.NEW_DIALOG.RECEIPT')
        }];

        $scope.data = {};

        $scope.getVendors = function(type) {
            return type ? vendors[type] : [];
        };

        $scope.hide = function() {
            $mdDialog.hide();
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.answer = function() {
            $mdDialog.hide($scope.data);
        };

        $scope.isFiscalOrRT = () => ['fiscal', 'rt'].includes($scope.data.type);

        $scope.dataChanged = function() {
            if (['nonfiscal', 'receipt', 'kds'].includes($scope.data.type) || $scope.data.manifacturer) {
                $scope.answer();
            }
        };
    }

    var newPrinter = {
        show: function() {
            return $mdDialog.show({
                controller: newPrinterController,
                template: require('./new-printer.html')
            });
        }
    };

    return newPrinter;
}]);

import {
    Component,
    inject,
    ViewChild
} from '@angular/core';
import { $state } from 'app/ajs-upgraded-providers';
import {
    CustomerFe,
    DataExport,
    QueryPagination,
    RowModelType,
    TableData,
    TableRowUpdatable
} from 'src/app/shared/model/model';
import {
    IServerSideDatasource,
    RowClickedEvent,
} from "ag-grid-community";
import {
    distinctUntilChanged,
    from,
    Subject
} from "rxjs";
import {
    Rest,
    TilbyToolbar
} from "src/app/models";
import { ToolbarEventsService } from "src/app/core/services/toolbar-events/toolbar-events.service";
import { ModuleEvents } from "src/app/core/services/toolbar-events/toolbar-events.model";
import {
    ConfirmDialogService,
    GridDownloadDialogService,
    OpenDialogsService
} from "src/app/dialogs";
import { autobind } from "src/app/models/decorators.model";
import { GridServerSideComponent } from "src/app/shared/components/grid-server-side/grid-server-side.component";
import { OnDestroyService } from "src/app/core/services/on-destroy.service";
import {
    headersTranslate,
    ImplementServerSideGrid
} from "src/app/shared/components/grid";
import { GridService } from "src/app/shared/components/grid/grid.service";
import { GridCellFormatterService } from 'src/app/shared/components/grid/utils/grid-cell-formatter.service';
import { EntityManagerService } from "src/app/core/services/entity/entity-manager.service";
import { GridCellExportFormatterService } from 'src/app/shared/components/grid/utils/grid-cell-export-formatter.service';
import {
    ConfigurationManagerService,
    ConnectionService,
    PaginatedResponse
} from 'src/app/core/services';
import {
    CustomersFields,
    CustomersFormGroups
} from "../customers-details";
import { CustomerFormService } from "../../services";
import { callbackToGrid } from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import { Transition } from 'angular-ui-router';
import { Customers } from 'tilby-models';

const customField = [['custom_field_1','custom_1'], ['custom_field_2','custom_2'], ['custom_field_3','custom_3'],['custom_field_4','custom_4'],['custom_field_5','custom_5'],['custom_field_6','custom_6'],['custom_field_7','custom_7'],['custom_field_8','custom_8'],['custom_field_9','custom_9']];

@Component({
    selector: 'app-customers-showcase',
    templateUrl: './customers-showcase.component.html',
    styleUrls: ['./customers-showcase.component.scss'],
    providers: [CustomerFormService,OnDestroyService]
})
export class CustomersShowcaseComponent implements TilbyToolbar, ImplementServerSideGrid{
    private readonly entityManager = inject(EntityManagerService);
    private readonly gridService = inject(GridService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly state = inject($state);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly onDestroyService = inject(OnDestroyService);
    private readonly gridCellFormatterService = inject(GridCellFormatterService);
    private readonly gridCellExportFormatterService = inject(GridCellExportFormatterService);
    private readonly customerFormService= inject(CustomerFormService);
    private readonly connection = inject(ConnectionService);
    private readonly gridDownloadDialogService = inject(GridDownloadDialogService);

    rowModelType: RowModelType = "serverSide";
    isSelected = false;
    gridPath = this.state.$current.name.split('.').pop() === 'customers';
    gridReady$ = new Subject();

    @ViewChild('appGridComponent', {static: true}) gridRef!: GridServerSideComponent;

    exportFormatterOptions?: (...any:any[])=>any;

    readonly query: Readonly<QueryPagination> = {
        pagination: true,
        per_page: 30,
        page: 0,
    };

    value = "";

    tableData: TableData[] = [
        {
            rowData$: null,
            dataType: this.updateCustomField(new CustomerFe()),
            headersTranslate: headersTranslate.customers_showcase,
            columnsFormatter: this.gridCellFormatterService.customers
            // columnsExcelFormatter: this.gridCellExportStyleService.customers
        }
    ];
    datasource: IServerSideDatasource = {
        getRows: (params) => this.gridRef.getRowsForServersideDatasource(params,[this.query],(a,b?)=>from(this.entityManager.customers.fetchCollectionOnline(a) as Promise<PaginatedResponse<Customers>>),{sort_type:"asc",sort_col:"last_name"})
    };
    //-------- NEW GENERIC TOOLBAR - START ----///

    private updateCustomField(customerFe: CustomerFe): any {
        customField.forEach((element) => {
            if (!this.configurationManagerService.getSetting('customers.' + element[0] as any)) {
                // @ts-ignore
                delete customerFe[element[1]]
            }
        });
        return customerFe;
    }

    private movColumnsSwitch = false;
    private isAdvancedFilterActive = false;

    constructor() {
        this.state.router.transitionService.onSuccess({}, (transition: Transition&any) => this.gridPath = transition._targetState._identifier.split('.').pop() === 'customers');
    }

    ngOnInit() {
        // first time
        this.createToolbarButtons();
        this.setModuleTitle();
        // on show again
        this.state.router.transitionService.onSuccess({to: 'app.new.customers'}, (transition: Transition) => {
            if(!!transition.params().remove) this.gridRef.onRemoveSelected(transition.params().remove);
            this.createToolbarButtons();
        });
        this.toolbarEventsService.events.pipe(distinctUntilChanged(),this.onDestroyService.takeUntilDestroy).subscribe(e => this.callbackToToolbarClick(e))
        this.toolbarEventsService.searchBarValue$.next('');
        this.toolbarEventsService.searchBarIcon$.next('tune');
        this.toolbarEventsService.searchBarIconFilled$.next('tune');
        this.toolbarEventsService.searchBarAction$.next({ openToolPanel: 'filters' });
        this.toolbarEventsService.searchBarActionFilled$.next({ openToolPanel: 'filters' });
        this.exportFormatterOptions = this.gridCellExportFormatterService.customerExportFormatter;
    }

    setModuleTitle() {
        this.toolbarEventsService.moduleTitle.next("CUSTOMERS");
    }

    async openDeleteDialog() {
        const res = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'DIALOG.CUSTOMER_DELETE.TITLE',
                confirmLabel: 'DIALOG.CUSTOMER_DELETE.CONFIRM',
                cancelLabel: 'DIALOG.CUSTOMER_DELETE.CANCEL'
            }
        });

        if(res) {
            this.toolbarEventsService.events.next({ delete: true });
        }
    }

    /**
     * Create buttons for the toolbar
     */
    createToolbarButtons() {
        this.toolbarEventsService.buttons$.next({
            barButtons: [
                {
                    isIt: () => this.connection.isOffline(),
                    name: 'cloud_off',
                    icon: () => 'cloud_off',
                    click: _ => null
                },
                {
                    isIt: () => true,
                    name: 'add_new_customer',
                    icon: () => 'playlist_add',
                    click: _ => this.state.go("app.new.customers.new")
                },
                {
                    isIt: () => this.isSelected,
                    name: 'edit',
                    icon: () => 'edit',
                    click: _ => this.toolbarEventsService.events.next({edit: true})
                },
                {
                    isIt: () => this.isSelected,
                    name: 'delete',
                    icon: () => 'delete',
                    click: _ => this.openDeleteDialog()
                }
            ],
            panelButtons: [
                {
                    isIt: () => true,
                    name: 'columns_panel',
                    icon: () => 'view_week',
                    label: () => 'TOPBAR.ACTIONS.COLUMN_SELECTED',
                    click: _ => this.toolbarEventsService.events.next({openToolPanel: 'columns'})
                },
                {
                    isIt: () => true,
                    name: 'movable_columns',
                    icon: () => 'drag_indicator',
                    label: () => this.movColumnsSwitch ? 'TOPBAR.ACTIONS.DISABLED_COLUMN_MOVEMENT': 'TOPBAR.ACTIONS.ENABLED_COLUMN_MOVEMENT',
                    click: _ => this.toolbarEventsService.events.next({type: 'movColumnsSwitch', movColumnsSwitch: this.movColumnsSwitch = !this.movColumnsSwitch})
                },
                {
                    isIt: () => true,
                    name: 'save_columns_position',
                    icon: () => 'save',
                    label: () => 'TOPBAR.ACTIONS.SAVE_COLUMN_PREFERENCE',
                    click: _ => this.toolbarEventsService.events.next({save: true})
                },
                {
                    isIt: () => true,
                    name: 'advanced_filter',
                    icon: () => this.isAdvancedFilterActive ? 'filter_alt_off' : 'filter_list_alt',
                    label: () => this.isAdvancedFilterActive ? 'TOPBAR.ACTIONS.ADVANCED_FILTERS_DISABLED' : 'TOPBAR.ACTIONS.ADVANCED_FILTERS_ENABLED',
                    click: _ => this.toolbarEventsService.events.next({type: 'advFiltersSwitch', advFiltersSwitch: this.isAdvancedFilterActive = !this.isAdvancedFilterActive})
                },
                {
                    isIt: () => true,
                    name: 'upload',
                    icon: () => 'upload',
                    label: () => 'TOPBAR.ACTIONS.IMPORT',
                    click: _ => this.goToImporter()
                },
                {
                    isIt: () => true,
                    name: 'export',
                    icon: () => 'download',
                    label: () => 'TOPBAR.ACTIONS.EXPORT',
                    click: _ => this.toolbarEventsService.events.next({export: true})
                },
            ]
        });
    }

    goToImporter() {
        this.state.go('app.new.file_importer');
    }

    async callbackToToolbarClick(event: Partial<ModuleEvents>&{id?:number}) {
        if ("search" in event) this.gridRef.onFilterTextBoxChanged(event.search);
        else if ("delete" in event) event.id?this.gridRef.onRemoveSelected(event.id):this.gridRef.onRemoveSelected();
        else if ("export" in event) await this.gridDownloadDialogService.openDialog('customers',{data: this.tableData.map(tableData => ({...tableData,sortFilterQuery: this.gridRef.getSortFilterQuery(),countSelectedRows:this.gridRef.gridApi?.getSelectedRows().length}))}).then(res=>!!res&&this.onExport(res));
        else if ("openToolPanel" in event && event.openToolPanel) this.gridRef.openToolPanel(event.openToolPanel);
        else if ("edit" in event) await this.openDialogsService.openEditRowsDialog<CustomersFields,CustomersFormGroups>({data: {form:this.customerFormService.createForm()}}).then(res=>!!res&&this.gridRef.updateItems(callbackToGrid(res)));
        else if ("type" in event && event.type === 'advFiltersSwitch') { this.gridRef.advancedFilterSwitch(event.advFiltersSwitch || false);}
        else if ("type" in event && event.type === 'movColumnsSwitch') this.gridRef.columnMovableSwitch(event.movColumnsSwitch || false);
        else if ("save" in event) await this.gridRef.openDialogSaveColumnsPreference();
    }

//-------- NEW GENERIC TOOLBAR - END ----///



    @autobind
    onExport(dataExport: DataExport) {
        this.gridRef?.onBtnExport(dataExport)
    }

    addCustomer() {
        this.state.go("app.customers.new");
    };



    rowClicked({data}: RowClickedEvent) {
        this.movColumnsSwitch && this.toolbarEventsService.events.next({type: 'movColumnsSwitch', movColumnsSwitch: this.movColumnsSwitch = false});
        this.state.go(`app.new.customers.details`, {customerId: data.id});
    }

    @autobind
    updateItems(updatedRows: TableRowUpdatable[]) {
        updatedRows.forEach(updatedRow => this.entityManager.customers.putOneOnline(updatedRow).then(() => this.gridRef?.agGrid.api.refreshServerSide({ purge: false })));
    }

    deleteCustomers(ids: number[]) {
        ids.forEach(id => this.entityManager.customers.deleteOneOnline(id).then(() => { this.gridRef.agGrid.api.refreshServerSide({purge: false}); }).then(_ => this.gridService.tableProperties.idsDeleting = []));
    }

    crud({method, id}: Rest) {
        if (method === 'delete') this.toolbarEventsService.events.next({delete: true, id});
    }
}

import { CurrencyPipe } from "@angular/common";
import { Injectable } from "@angular/core";
import {
    ProcessCellForExportParams
} from "ag-grid-community";
import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class GridCellExportFormatterService {

    constructor(private tilbyDate: TilbyDatePipe, private currencyPipe: CurrencyPipe, private translateService: TranslateService) {
    }

    suppliersExportFormatter = ({column, value}:ProcessCellForExportParams) => {
        if (value) {
            // @ts-ignore
            switch (column.colId) {
                case 'created_at': return this.tilbyDate.transform(value, 'dd/MM/yyyy HH:mm');
                case 'updated_at': return this.tilbyDate.transform(value, 'dd/MM/yyyy HH:mm');
                default: return value;
            }
        }
    }

    fidelityExportFormatter = ({column, value}: ProcessCellForExportParams) => {
        if (value) {
            // @ts-ignore
            switch (column.colId) {
                case 'date': return this.tilbyDate.transform(value, 'dd/MM/yyyy HH:mm');
                default: return value;
            }
        }
    }

    prepaidExportFormatter = ({column, value}: ProcessCellForExportParams) => {
        if (value) {
            // @ts-ignore
            switch (column.colId) {
                case 'valid_from': return this.tilbyDate.transform(value, 'dd/MM/yyyy HH:mm');
                default: return value;
            }
        }
    }

    customerExportFormatter = ({column, value}: ProcessCellForExportParams) => {
        if (value) {
            // @ts-ignore
            switch (column.colId) {
                case 'birthdate': return this.tilbyDate.transform(value, 'dd/MM/yyyy HH:mm');
                case 'created_at': return this.tilbyDate.transform(value, 'dd/MM/yyyy HH:mm');
                case 'updated_at': return this.tilbyDate.transform(value, 'dd/MM/yyyy HH:mm');
                case 'discount_perc': return value +'%';
                default: return value;
            }
        }
    }

    historySalesFormatter = ({column, value}: ProcessCellForExportParams) => {
        if (value) {
            // @ts-ignore
            switch (column.colId) {
                case 'closed_at': return this.tilbyDate.transform(value, 'dd/MM/yyyy HH:mm');
                case 'open_at': return this.tilbyDate.transform(value, 'dd/MM/yyyy HH:mm');
                default: return value;
            }
        }
    }

    giftcardsFormatter = ({column, value}: ProcessCellForExportParams) => {
        if (value) {
            // @ts-ignore
            switch (column.colId) {
                case 'code': return value + '';
                case 'active': return value ? this.translateService.instant("APPLICATION.YES") : this.translateService.instant("APPLICATION.NO");
                case 'is_divisible': return value ? this.translateService.instant("APPLICATION.YES") : this.translateService.instant("APPLICATION.NO");
                case 'created_at': return this.tilbyDate.transform(value, 'dd/MM/yyyy HH:mm');
                case 'updated_at': return this.tilbyDate.transform(value, 'dd/MM/yyyy HH:mm');
                case 'deleted_at': return this.tilbyDate.transform(value, 'dd/MM/yyyy HH:mm');
                case 'valid_since': return this.tilbyDate.transform(value, 'dd/MM/yyyy HH:mm');
                case 'valid_until': return this.tilbyDate.transform(value, 'dd/MM/yyyy HH:mm');
                case 'initial_value': return this.currencyPipe.transform(value);
                case 'last_value': return this.currencyPipe.transform(value);
                default: return value;
            }
        }
    }

    historyCashMovementsFormatter = ({column, value}: ProcessCellForExportParams) => {
        if (value) {
            // @ts-ignore
            switch (column.colId) {
                case 'date': return this.tilbyDate.transform(value, 'dd/MM/yyyy HH:mm');
                case 'type': return value === 'income' ? this.translateService.instant("HISTORY.CASH_MOVEMENTS.INCOME") : this.translateService.instant("HISTORY.CASH_MOVEMENTS.OUTCOME");
                case 'amount': return this.currencyPipe.transform(value);
                case 'account': return value === 'cash' ? this.translateService.instant("HISTORY.TOPBAR_CASH_MOVEMENTS.CASHREGISTER") : this.translateService.instant("HISTORY.TOPBAR_CASH_MOVEMENTS.OUT_CASHREGISTER");
                default: return value;
            }
        }
    }
}

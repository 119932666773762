import angular from 'angular';
import _ from 'lodash';

angular.module('printers').controller('PrintersGeneralCtrl', ["$scope", "preferences", "checkManager", "alertDialog", "$translate", "toast", function($scope, preferences, checkManager, alertDialog, $translate, toast) {
    $scope.preferences = _.cloneDeep(preferences);

    $scope.save = function() {
        _.forEach($scope.preferences.fiscalprinter, function(pref, key) {
            if (pref !== preferences.fiscalprinter[key]) {
                checkManager.setShopPreference('fiscalprinter.' + key, pref);
            }
        });
        _.forEach($scope.preferences.nofiscalprinter, function(pref, key) {
            if (pref !== preferences.nofiscalprinter[key]) {
                checkManager.setShopPreference('nofiscalprinter.' + key, pref);
            }
        });
        toast.show({ message: $translate.instant('SETTINGS.SETTINGS.SAVE_SUCCESSFUL'), hideDelay: 10000 });
        $scope.comandeForm.$setPristine();
        $scope.fiscalForm.$setPristine();
        angular.copy($scope.preferences, preferences);
    };

    $scope.isValid = function() {
        if ($scope.fiscalForm) {
            return $scope.fiscalForm.$valid;
        } else {
            return false;
        }
    };

    $scope.$parent.topbar_context.saveButton = {
        label: $translate.instant('PRINTERS.GENERAL.SAVE'),
        action: $scope.save,
        enabled: $scope.isValid
    };

    $scope.$parent.topbar_context.unsaved = function() {
        if ($scope.comandeForm && $scope.comandeForm.$dirty) {
            return true;
        }
        if ($scope.fiscalForm && $scope.fiscalForm.$dirty) {
            return true;
        }
        return false;
    };

    $scope.$parent.hidePrintersLoader();
}]);

const customers_prepaid = new Map<string, boolean>([
    ['amount', true]
]);

const giftcard = new Map<string, boolean>([
    ['active', true]
]);
const promotions = new Map<string, boolean>([
    ['active', false]
]);

export const sortingsMenuDisabled = {
    customers_prepaid,
    giftcard,
    promotions
};

import angular from 'angular';
import _ from 'lodash';

function kioskCtrl($scope, $state, $filter, $timeout, $mdDialog, kioskSettings, confirmDialog, waitDialog, $rootScope, kioskUtils, migrationHelper) {
    const ActiveSale = migrationHelper.getActiveSale();

    _.assign($scope, {
        kioskStyle:  {
            'font-family': kioskSettings['general.text_font'] || 'unset',
            'color': kioskSettings['general.base_text_color'] || '#000000'
        },
        kioskBackButtonStyle: {
            'color': kioskSettings['general.back_button_color'] || '#FFFFFF',
            'background-color': kioskSettings['general.back_button_bgcolor'] || '#666666',
            'min-width': '64px'
        },
        kioskNextButtonStyle: {
            'color': kioskSettings['general.next_button_color'] || '#FFFFFF',
            'background-color': kioskSettings['general.next_button_bgcolor'] || '#666666',
            'min-width': '64px'
        },
        customerAssistantEnabled: kioskSettings['general.customer_assistant'] !== false,
        totalLabel: kioskUtils.getLabelText(kioskSettings, 'cart.total_label', 'KIOSK.TOTAL'),
        kioskFooterStyle: { 'zoom': kioskSettings['general.zoom_level'] || 1.5 },
        paymentInProgress: false,
        kioskSaleData: kioskUtils.getKioskSaleData(),
    });

    var kioskTimeout;
    var customerAssistantTimeout;
    var helpPromptPromise;

    var kioskTimeoutTime = (kioskSettings['general.standby_timeout'] || 600) * 1000;
    var customerAssistantTimeoutTime = (kioskSettings['general.customer_assistant_timeout'] || 400) * 1000;
    var customerAssistantTimeoutMessage = kioskUtils.getLabelText(kioskSettings, 'general.customer_assistant_timeout_message', 'KIOSK.HELP_PROMPT');
    var customerAssistantMessage = kioskUtils.getLabelText(kioskSettings, 'general.customer_assistant_message', 'KIOSK.HELP_WAIT');

    var goToStandby = function() {
        $state.go('app.kiosk.standby');
        $rootScope.$broadcast('kiosk:standby');

        if(helpPromptPromise) {
            $mdDialog.cancel(helpPromptPromise);
        }
    };

    var askHelpRequest = function() {
        helpPromptPromise = confirmDialog.show(customerAssistantTimeoutMessage).then(function(answer) {
            if(answer) {
                $scope.sendHelpRequest();
            }
        }).finally(function() {
            helpPromptPromise = null;
        });
    };

    var disableTimeouts = function() {
        if(kioskTimeout) {
            $timeout.cancel(kioskTimeout);
        }
        if(customerAssistantTimeout) {
            $timeout.cancel(customerAssistantTimeout);
        }
    };

    var enableTimeouts = function() {
        disableTimeouts();

        if(!$scope.paymentInProgress) {
            if(customerAssistantTimeoutTime) {
                //customerAssistantTimeout = $timeout(askHelpRequest, customerAssistantTimeoutTime);
            }
            if(kioskTimeoutTime) {
                kioskTimeout = $timeout(goToStandby, kioskTimeoutTime);
            }
        }
    };

    $scope.enableTimeouts = function(){
        enableTimeouts();
    };

    $scope.disableTimeouts = function(){
        disableTimeouts();
    };

    $rootScope.enableTimeouts = function(){
        enableTimeouts();
    };

    $scope.getTotal = function() {
        return ActiveSale.isActiveSale() ? $filter('sclCurrency')(ActiveSale.currentSale.final_amount) : $filter('sclCurrency')(0);
    };

    $scope.getItemsCount = function() {
        return ActiveSale.isActiveSale() ? _.sumBy(ActiveSale.currentSale.sale_items, function(saleItem) { return saleItem.quantity; }) : 0;
    };

    $scope.sendHelpRequest = function() {
        waitDialog.show({ message: customerAssistantMessage });
    };

    $scope.$on("$destroy", $scope.disableTimeouts);

    if(!_.startsWith($state.transition.from().name, 'app.kiosk') && $state.transition.to().name !== 'app.kiosk.standby') {
        $state.go('app.kiosk.standby');
    }

    /*background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;*/
}

kioskCtrl.$inject = ["$scope", "$state", "$filter", "$timeout", "$mdDialog", "kioskSettings", "confirmDialog", "waitDialog", "$rootScope", "kioskUtils", "migrationHelper"];

angular.module('kiosk').controller('KioskCtrl', kioskCtrl);

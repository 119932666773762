import { CommonModule } from "@angular/common";
import { Component, effect, inject, Injectable, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatRadioModule } from "@angular/material/radio";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogContentComponent,
    TilbyDialogToolbarComponent
} from "@tilby/tilby-ui-lib/components/tilby-dialog";

import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { lastValueFrom } from "rxjs";
import { Bookings, Rooms } from "tilby-models";
import { util } from "app/ajs-upgraded-providers";
import { TDocumentDefinitions } from "pdfmake/interfaces";
import { EntityManagerService } from "src/app/core";
import { BookingsStateService } from "src/app/features";

interface TableInfo {
    table_name: string;
    table_id: number;
    room_name: string;
    room_id: number;
    covers: number;
    type: string;
}

@Component({
    selector: 'app-download-booking-dialog',
    standalone: true,
    imports: [
        CommonModule,
        TilbyDialogContentComponent,
        TilbyDialogToolbarComponent,
        MatDialogModule,
        MatFormFieldModule,
        FormsModule,
        TranslateModule,
        ReactiveFormsModule,
        MatRadioModule
    ],
    templateUrl: './download-booking-dialog.component.html',
    styleUrls: ['./download-booking-dialog.component.scss']
})
export class DownloadBookingDialogComponent implements OnInit {

    private readonly utilService = inject(util);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly tilbyDatePipe = inject(TilbyDatePipe);
    private readonly translateService = inject(TranslateService);
    private readonly bookingsStateService = inject(BookingsStateService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly data: {currentDate: Date, rooms: Rooms[], bookings: any} = inject(MAT_DIALOG_DATA);

    dialogTitle = 'BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TITLE';

    downloadBookingForm!: FormGroup;

    tablesById: Record<number, TableInfo> = {};
    bookings : Bookings[] = [];
    bookingToday : any = undefined;
    bookingsFiltered: Bookings[] = [];

    constructor(private fb: FormBuilder) {
        effect(() => {
            if(this.bookingsStateService.bookingFiltered()) this.bookingsFiltered = this.bookingsStateService.bookingFiltered();
        })
    }

    async ngOnInit() {

        this.downloadBookingForm = this.fb.group({
            format: ['xls']
        });

        await this.getBookingsToday();

        this.data.rooms.forEach((room : any) => {
            room.tables.forEach((table: any) => {
                this.tablesById[table.id] = {
                    table_name: table.name,
                    table_id: table.id,
                    room_name: room.name,
                    room_id: room.id,
                    covers: table.covers,
                    type: table.order_type
                };
            });
        });
    }

    isBookingOnline(currentDate: Date, typeView: number) {

        const todayString = this.tilbyDatePipe.transform(new Date(), 'yyyy-MM-dd');
        let todayDate = TilbyDatePipe.date({outputFormat: 'date', date: todayString || ''});
        let todayDatePlus31 = TilbyDatePipe.date({outputFormat: 'date', date: todayString || ''});
        todayDatePlus31.setDate(todayDatePlus31.getDate() + 31);

        if (typeView === 1) {
            if (currentDate >= todayDate && currentDate < todayDatePlus31) {
                return false;
            } else {
                return true;
            }
        } else {
            throw new Error('Not implemented for other view');
        }
    }

    async getBookingsToday() {
        const isBookingOnline = this.isBookingOnline(this.data.currentDate, 1);

        if (!isBookingOnline) {
            let bookings = await this.entityManagerService.bookings.fetchCollectionOffline();

            bookings = bookings.slice().sort((a: Bookings, b: Bookings) => {
                const aDate = new Date(a.booked_for);
                const bDate = new Date(b.booked_for);
                const aTimestamp = aDate.getTime();
                const bTimestamp = bDate.getTime();
                return aTimestamp - bTimestamp;
            });

            this.bookingToday = bookings.filter((b: Bookings) => {
                return this.tilbyDatePipe.transform(this.data.currentDate, 'dd/MM/yyyy', 'UTC') === this.tilbyDatePipe.transform(new Date(b.booked_for), 'dd/MM/yyyy');
            });
        } else {
            const fromDate = this.data.currentDate;
            let toDate = new Date(this.data.currentDate.getTime());
            toDate.setHours(toDate.getHours() + 23);
            toDate.setMinutes(toDate.getMinutes() + 59);
            toDate.setSeconds(toDate.getSeconds() + 59);

            this.bookingToday = await this.entityManagerService.bookings.fetchCollectionOnline(
                {
                    booked_for_since: fromDate.toISOString(),
                    booked_for_max: toDate.toISOString()
                }
            );
        }
    }

    getTablesCaption(booking: any): string {
        const caption: string[] = [];

        booking.tables.forEach((table: any) => {
            const tableData = this.tablesById[table.table_id];

            if (tableData) {
                caption.push([tableData.room_name, tableData.table_name].join(', '));
            }
        });

        return caption.length > 0 ? caption.join(' - ') : '';
    }

    getStatus(status : string) {
        switch(status) {
            case 'provisional':
                return this.translateService.instant('BOOKINGS.STATUSES.PROVISIONAL');
            case 'confirmed':
                return this.translateService.instant('BOOKINGS.STATUSES.CONFIRMED');
            case 'arrived':
                return this.translateService.instant('BOOKINGS.STATUSES.ARRIVED');
            case 'seated':
                return this.translateService.instant('BOOKINGS.STATUSES.SEATED');
            case 'departed':
                return this.translateService.instant('BOOKINGS.STATUSES.DEPARTED');
            case 'noshow':
                return this.translateService.instant('BOOKINGS.STATUSES.NOSHOW');
            default:
                return '';
        }
    };

    async downloadPDF() {

        const prefixFileName = this.translateService.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.PREFIX_FILE_NAME');
        const currentDate = new Date(this.data.currentDate);
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1;
        const year = currentDate.getFullYear();
        const suffixFileName = `${day}_${month}_${year}`;
        const tableCaption = `${prefixFileName} ${day}/${month}/${year}`;
        const filename = `${prefixFileName}_${suffixFileName}.pdf`;

        let tableBody : any = [];

        const header = [{ text: this.translateService.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.START'), style: 'tableHeader',margin: [10, 5, 10, 5] },
            { text: this.translateService.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.END'), style: 'tableHeader',margin: [10, 5, 10, 5] },
            { text: this.translateService.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.DURATION'), style: 'tableHeader',margin: [10, 5, 10, 5] },
            { text: this.translateService.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.COVERS'), style: 'tableHeader',margin: [10, 5, 10, 5] },
            { text: this.translateService.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.CUSTOMER'), style: 'tableHeader',margin: [10, 5, 10, 5] },
            { text: this.translateService.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.TABLE'), style: 'tableHeader',margin: [10, 5, 10, 5] },
            { text: this.translateService.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.STATUS'), style: 'tableHeader',margin: [10, 5, 10, 5] },
            { text: this.translateService.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.NOTES'), style: 'tableHeader',margin: [10, 5, 10, 5] },];

        tableBody.push(header);

        for(let b of this.bookingsFiltered) {
            const start = this.tilbyDatePipe.transform(b.booked_for,'HH:mm');
            const endDate = new Date(b.booked_for);
            endDate.setMinutes(endDate.getMinutes() + b.duration);
            const end = this.tilbyDatePipe.transform(endDate, 'HH:mm');
            const companyName = b.booking_customer?.company_name;
            const customerName = `${b.booking_customer?.first_name} ${b.booking_customer?.last_name}`;
            const customer = companyName && companyName !== "" ? companyName: customerName;
            tableBody.push([
                { text: start, margin: [10, 5, 10, 5] },
                { text: end, margin: [10, 5, 10, 5] },
                { text: b.duration, margin: [10, 5, 10, 5] },
                { text: b.people, margin: [10, 5, 10, 5] },
                { text: customer, margin: [10, 5, 10, 5] },
                { text: this.getTablesCaption(b), margin: [10, 5, 10, 5] },
                { text: this.getStatus(b.status), margin: [10, 5, 10, 5] },
                { text: b.notes ? b.notes : '', margin: [10, 5, 10, 5] },
            ]);
        }

        const documentDefinition: TDocumentDefinitions = {
            content: [
              { text: tableCaption, fontSize: 14, bold: true, margin: [0, 0, 0, 20] },
              {
                table: {
                    headerRows: 1,
                    body: tableBody
                },
                layout: {
                    hLineWidth: function (i, node) {
                        if (i === 1) {
                          return 2;
                        } else {
                            return 1;
                        }
                    },
                    vLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.widths!.length) ? 1 : 0;
                    }
                }
              }
            ],
            styles: {
                tableHeader: {
                    bold: true,
                    fontSize: 11,
                    alignment: 'center'
                  },
            },
            defaultStyle: {
                font: 'Monospace',
                fontSize: 10,
                alignment: 'center'
            },
            pageSize: 'A4',
            pageOrientation: 'landscape',
            pageMargins: [20, 40, 20, 40],
            footer: (currentPage: number, pageCount: number) => ({
              text: `Page ${currentPage} of ${pageCount}`,
              alignment: 'center'
            })
          };
        pdfMake.createPdf(documentDefinition).download(filename);
    }

    downloadXLS() {
        const fieldsBooking = [
            this.translateService.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.START'),
            this.translateService.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.END'),
            this.translateService.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.DURATION'),
            this.translateService.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.COVERS'),
            this.translateService.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.CUSTOMER'),
            this.translateService.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.TABLE'),
            this.translateService.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.STATUS'),
            this.translateService.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.TABLE.NOTES'),
        ];

        const bookingForCSV = this.bookingsFiltered.map( (b : any) => {
            let result = {};
            const start = this.tilbyDatePipe.transform(b.booked_for,'HH:mm');
            const endDate = new Date(b.booked_for);
            endDate.setMinutes(endDate.getMinutes() + b.duration);
            const end = this.tilbyDatePipe.transform(endDate, 'HH:mm');
            const companyName = b.booking_customer.company_name;
            const customerName = `${b.booking_customer.first_name} ${b.booking_customer.last_name}`;
            const customer = companyName && companyName !== "" ? companyName: customerName;

            Object.assign(result, {
                start: start,
                end: end,
                duration: b.duration,
                people: b.people,
                customer: customer,
                table: this.getTablesCaption(b),
                status: this.getStatus(b.status),
                notes: b.notes ? b.notes : ''
            });

            return result;
        });

        const columns = ['start', 'end', 'duration', 'people', 'customer', 'table', 'status', 'notes'];
        const delimiter = ';';
        const csv = this.generateCSV(bookingForCSV, fieldsBooking, columns, delimiter);

        const prefixFileName = this.translateService.instant('BOOKINGS.DOWNLOAD_BOOKING_DIALOG.PREFIX_FILE_NAME');
        const currentDate = new Date(this.data.currentDate);
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1;
        const year = currentDate.getFullYear();
        const suffixFileName = `${day}_${month}_${year}`;
        this.utilService.downloadFile(csv, `${prefixFileName}_${suffixFileName}.csv`, 'text/plain;charset=utf-8;');
    };

    generateCSV(bookingForCSV: any[], headers: string[], columns: string[], delimiter: string = ';'): string {
        const header = headers.join(delimiter);

        const rows = bookingForCSV.map(booking => {
            return columns.map(column => {
                const value = booking[column] !== undefined ? booking[column] : '';
                return value.toString();
            }).join(delimiter);
        });

        return header + '\r\n' + rows.join('\r\n');
    }

    async confirm() {
        if (this.downloadBookingForm.valid && this.downloadBookingForm.get('format')) {
            switch (this.downloadBookingForm.get('format')!.value) {
                case "pdf":
                    this.downloadPDF();
                    break;
                case "xls":
                    this.downloadXLS();
                    break;
                }

        }
        this.matDialogRef.close();
    }

    close() {
        this.matDialogRef.close();
    }
}

@Injectable({
    providedIn: 'root'
})
export class DownloadBookingDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public async openDialog(data?: {currentDate: Date, rooms: Rooms[]}) {
        const config:NonNullableConfigData<any>={...this.switchMobileDesktopDimensions({ height: 'auto', width: 'auto' }), disableClose:false, data };

        return lastValueFrom(this.dialogRef.open(DownloadBookingDialogComponent, config).afterClosed()).then((res: string) => {
            return {
                result: res
            }
        });
    }
}

import { Component, computed, inject, Inject, Injectable, OnInit, signal, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BaseDialogService, NonNullableConfigData, TilbyDialogContentComponent, TilbyDialogToolbarComponent } from '@tilby/tilby-ui-lib/components/tilby-dialog';
import { GeneralInputButton } from '@tilby/tilby-ui-lib/models';
import { lastValueFrom } from 'rxjs';
import { ConfigurationManagerService, EnvironmentInfoService } from 'src/app/core';
import { DevLogger } from 'src/app/shared/dev-logger';
import {
    Rooms,
    Sales,
    SalesDocuments,
    SalesItems
} from 'tilby-models';
import { TilbyCurrencyPipe } from '@tilby/tilby-ui-lib/pipes/tilby-currency';
import { CommonModule } from '@angular/common';
import { MatListModule, MatSelectionListChange } from '@angular/material/list';
import { TilbyDatePipe } from '@tilby/tilby-ui-lib/pipes/tilby-date';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OpenDialogsService } from '../services';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { documentPrinter } from 'app/ajs-upgraded-providers';
import {mobileCheck} from 'src/utilities';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectAllDirective } from 'src/app/directives/select-all.direttive';

type SummaryOfOccupiedTablesDialogData = {
    rooms: Rooms[];
    sales: Sales[];
};

type SummaryType = {
    sale_id: number;
    name: string | undefined;
    open_at: Date;
    room_id: number | undefined;
    room_name: string | undefined;
    table_id: number | undefined;
    table_name: string | undefined;
    amount: number;
    final_amount: number;
    covers: number | undefined;
    exit: number;
    maxExit: number;
    saleItemUUID: string | null;
    seller_id: number | null;
    seller_name: string | null;
}

@Component({
    selector: 'app-summary-of-occupied-tables-dialog',
    templateUrl: './summary-of-occupied-tables-dialog.component.html',
    styleUrls: ['./summary-of-occupied-tables-dialog.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TilbyDialogToolbarComponent,
        TilbyDialogContentComponent,
        //MatDialogModule,
        MatListModule,
        TranslateModule,
        TilbyDatePipe,
        MatIconModule,
        TilbyCurrencyPipe,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
        SelectAllDirective
    ],
    encapsulation: ViewEncapsulation.None
})
export class SummaryOfOccupiedTablesDialog implements OnInit {
    protected readonly data: SummaryOfOccupiedTablesDialogData = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly tilbyDatePipe = inject(TilbyDatePipe);
    private readonly tilbyCurrencyPipe = inject(TilbyCurrencyPipe);
    public readonly translateService = inject(TranslateService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly environmentInfoService = inject(EnvironmentInfoService);

    // ???
    private readonly documentPrinterService = inject(documentPrinter);
    isMobile = mobileCheck();
    private canShare = this.environmentInfoService.canShare();
    private canDownloadFiles = this.environmentInfoService.canDownloadFiles();

    protected customActions: GeneralInputButton[] = [
        {
            isIt: signal(this.canDownloadFiles && !this.isMobile),
            name: '',
            icon: signal('download'),
            click: () => this.downloadFile(),
            isDisable: () => false
        },
        {
            isIt: signal(this.canDownloadFiles && !this.isMobile),
            name: '',
            icon: signal('picture_as_pdf'),
            click: () => this.downloadPdf(),
            isDisable: () => false
        },
        {
            isIt: signal(this.canShare && this.isMobile),
            name: '',
            icon: signal('share'),
            iconType: 'symbols',
            click: () => this.share()
        },
        {
            isIt: () => true,
            name: '',
            icon: signal('print'),
            click: () => this.openDetails(),
            isDisable: () => false
        }
    ];

    selectedDocument?: SalesDocuments;

    inProgress: boolean;
    hasSelectedAllRooms = true;
    summary: SummaryType[] = [];
    summaryRecords: Record<number, {tot: SummaryType, partial: SummaryType[]}> = {};
    rooms = this.data.rooms;
    selectedRooms: Rooms[] = [];
    selectedRoomsMobile = new FormControl<Rooms[]>(this.data.rooms);

    salesWhitRoomsAndTables = this.data.sales.filter(sale => sale.order_type === 'normal' &&
        sale.room_id && sale.room_name && sale.table_id && sale.table_name);

    sellers: {id:number; name: string}[] = [];
    selectedSellers: {id:number; name: string}[] = [];
    selectedSellersMobile = new FormControl<{id:number; name: string}[]>([]);

    totalAmount = signal(0);
    totalCovers = signal(0);
    title = computed(() => `${this.translateService.instant(`DIALOG.SUMMARY_OF_OCCUPIED_TABLES.OPEN_TABLES`)} - ${
        this.tilbyCurrencyPipe.transform(this.totalAmount())} `);

    subTitle = computed(() => this.totalCovers());

    // ???
    sale = this.data.sales[0];

    otherLabel = this.translateService.instant(`DIALOG.SUMMARY_OF_OCCUPIED_TABLES.OTHER`);
    othersLabel = this.translateService.instant(`DIALOG.SUMMARY_OF_OCCUPIED_TABLES.OTHERS`);

    constructor(
        public dialogRef: MatDialogRef<SummaryOfOccupiedTablesDialog>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
        protected configurationManager: ConfigurationManagerService,
    ) {
        this.inProgress = false;
        this.data = _data;
    }

    ngOnInit(): void {
        const sellers : {id:number; name: string}[] = [];

        this.salesWhitRoomsAndTables.forEach(sale => {
            const obj: SummaryType = {
                sale_id: sale.id || 0,
                name: sale.name,
                open_at: sale.open_at,
                room_id: sale.room_id,
                room_name: sale.room_name,
                table_id: sale.table_id,
                table_name: sale.table_name,
                final_amount: sale.final_amount || 0,
                amount: 0,
                covers: sale.covers || 0,
                exit: 0,
                maxExit: 0,
                saleItemUUID: null,
                seller_id: sale.seller_id,
                seller_name: sale.seller_name
            }

            const mainObj = structuredClone(obj);

            let sum = 0;
            let maxExit = 0;
            const summarySalesItems: SummaryType[] = [];

            sale?.sale_items?.forEach((saleItem: SalesItems) => {
                obj.exit = saleItem.exit || 0;
                obj.maxExit = Math.max(maxExit, saleItem.exit || 0);
                maxExit = obj.maxExit;
                obj.amount = saleItem.quantity * saleItem.final_price || 0;
                sum += obj.amount;
                obj.saleItemUUID = saleItem.uuid;
                if(saleItem.seller_id && saleItem.seller_name) {
                    obj.seller_id = saleItem.seller_id;
                    obj.seller_name = saleItem.seller_name;
                    const seller = {
                        id: saleItem.seller_id,
                        name: saleItem.seller_name
                    };
                    sellers.push(seller);
                }
                summarySalesItems.push(structuredClone(obj));
            });

            mainObj.amount = sum;
            mainObj.maxExit = maxExit;
            this.summaryRecords[sale.id || 0] = {
                tot: mainObj,
                partial: summarySalesItems
            }
            this.totalAmount.update(currValue => currValue + (sale.final_amount || 0));
            this.totalCovers.update(currValue => currValue + (sale.covers || 0));
        });

        for(let key in this.summaryRecords ) {
            const tot = this.summaryRecords[key].tot;
            this.summary.push(tot);
        }

        // remove duplicates
        const sellersSet = new Set();
        this.sellers = sellers.filter(el => {
            const duplicate = sellersSet.has(el.id);
            sellersSet.add(el.id);
            return !duplicate;
        });
    }

    cancel() {
        this.matDialogRef.close();
    };

    hasSelectedButNotAllRooms() {
        return this.selectedRooms.length > 0 && !this.compareArrays(this.selectedRooms, this.rooms);
    }

    compareArrays(arr1: Rooms[], arr2: Rooms[]) {
        return arr1.length === arr2.length
            && arr1.every((value: Rooms) => arr2.includes(value))
            && arr2.every((value: Rooms) => arr1.includes(value));
    }

    onRoomSelect(event: MatSelectionListChange) {
        const roomsList = event.source;
        const isSelected = event.options[0].selected;
        const _room = event.options[0].value;

        if(_room == 'null') {
            this.hasSelectedAllRooms = isSelected;
            this.selectedRooms = [];
            roomsList.deselectAll();
        } else {
            if(isSelected) {
                this.selectedRooms.push(_room);
            } else {
                this.selectedRooms = this.selectedRooms.filter(room => room.id !== _room.id);
                roomsList.selectedOptions.deselect();
            }
            this.hasSelectedAllRooms = this.compareArrays(this.selectedRooms, this.rooms);
            if(this.hasSelectedAllRooms) {
                this.selectedRooms = [];
                roomsList.deselectAll();
            }
        }

        this.applayFilter();
    }

    onRoomMobileSelect(event: MatSelectChange) {
        const _rooms = event.value;

        if(Array.isArray(_rooms) && _rooms[0] === 'select-all') {
            this.selectedRooms = this.data.rooms;
        } else {
            this.selectedRooms = _rooms.length > 0 ? _rooms : [];
        }

        this.applayFilter();
    }

    onSellerSelect(event: MatSelectionListChange) {
        const _seller = event.options[0].value;

        if(event.options[0].selected) {
            this.selectedSellers.push(_seller);
        } else {
            this.selectedSellers = this.selectedSellers.filter(seller => seller.id !== _seller.id);
        }

        this.applayFilter();
    }

    onSellerMobileSelect(event: MatSelectChange) {
        this.selectedSellers = event.value;
        this.applayFilter();
    }

    applayFilter() {
        this.totalAmount.set(0);
        this.totalCovers.set(0);
        this.summary = [];

        const arrs = (this.selectedRooms.length === 0) ? this.rooms : this.selectedRooms;

        for(let key in this.summaryRecords ) {
            const totalSale = this.summaryRecords[key].tot;
            const partialSaleItems = this.summaryRecords[key].partial;
            if (arrs.some(room => room.id === totalSale.room_id)) {
                if(this.selectedSellers.length > 0) {
                    let sumCovers = 0;
                    let countCovers = 0;
                    this.selectedSellers.forEach(seller => {
                        let sumAmount = 0;
                        const sellerFilter = partialSaleItems.filter(el => el.seller_id === seller.id);
                        if(sellerFilter.length > 0) {
                            countCovers += sellerFilter.length;
                            const obj: SummaryType = {
                                sale_id: totalSale.sale_id,
                                name: totalSale.name,
                                open_at: totalSale.open_at,
                                room_id: totalSale.room_id,
                                room_name: totalSale.room_name,
                                table_id: totalSale.table_id,
                                table_name: totalSale.table_name,
                                amount: 0,
                                final_amount: totalSale.final_amount,
                                covers: totalSale.covers,
                                exit: 0,
                                maxExit: 0,
                                saleItemUUID: null,
                                seller_id: seller.id || null,
                                seller_name: seller.name || null
                            };
                            sellerFilter.forEach(el => {
                                    obj.amount += el.amount;
                                    obj.exit = el.exit || 0;
                                    obj.maxExit = Math.max(obj.exit, obj.maxExit);
                                    obj.saleItemUUID = el.saleItemUUID;
                                    sumAmount += el.amount;
                                    sumCovers += el.covers || 0;
                            });
                            this.summary.push(obj);

                        }
                        this.totalAmount.update(currValue => currValue + sumAmount);
                    });
                    if(countCovers > 0) {
                        this.totalCovers.update(currValue => currValue + (totalSale.covers || 0));
                    }
                } else {
                    this.summary.push(totalSale);
                    this.totalAmount.update(currValue => currValue + totalSale.amount);
                    this.totalCovers.update(currValue => currValue + (totalSale.covers || 0));
                }
            }
        }
    }

    getDataOpenAt(value: Date) {
        const openAt = this.tilbyDatePipe.transform(value, 'dd/MM');
        return (openAt !== new Date().toDateString()) ? ` ${openAt}` : '';
    }

    async openDetails() {
        console.log('click print');
        await this.openDialogsService.openGeneralDocumentPrinterSelectorDialog({
            data: { titleLabel: 'APPLICATION.ORDER_PRINTER.TITLE' },
            disableClose:true
        });
    }

    private async getDocumentToDownload() {
        let document = this.selectedDocument;

        if(!document) {
            return '';
        }

        const hasFiscalProvider = !!this.sale.sale_documents?.some(doc => doc.document_type === 'fiscal_provider');

        //Call the fiscal provider reprint function if we are dealing with a fiscal provider (except if we are printing the fiscal provider document itself)
        if(hasFiscalProvider && document.document_type !== 'fiscal_provider') {
            document = await this.documentPrinterService.reprintDocument(this.selectedDocument, this.sale, 'dummy_receipt');
        }

        return document?.document_content || '';
    }

    private async getPdfDocument(): Promise<TDocumentDefinitions> {
        const document = await this.getDocumentToDownload();

        return {
            content: [
                document
            ],
            defaultStyle: {
                font: 'Monospace',
                fontSize: 10
            }
        };
    };

    private downloadPdf() {
        this.log('click pdf');
    }

    private async share() {
        if(!this.canShare) {
            return;
        }

        const document = await this.getPdfDocument();
        const dataUri: string = await new Promise((resolve) => pdfMake.createPdf(document).getBase64(resolve));

        window.plugins.socialsharing.shareWithOptions({
            message: this.sale.name, // not supported on some apps (Facebook, Instagram)
            subject: this.sale.name, // fi. for email
            files: ['data:application/pdf;base64,' + dataUri], // an array of filenames either locally or remotely
        }, () => {}, (error: any) => {});
    };

    private async downloadFile() {
        this.log('click download');
    }

    private log(...args: any[]) {
        DevLogger.debug('[ SummaryOfOccupiedTablesDialog ]', ...args);
    }
}

@Injectable({
    providedIn: 'root',
})
export class SummaryOfOccupiedTablesDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);
    public openDialog(config: NonNullableConfigData<SummaryOfOccupiedTablesDialogData>) {
        const data: SummaryOfOccupiedTablesDialogData = config?.data;
        const configEdited: NonNullableConfigData<SummaryOfOccupiedTablesDialogData> = {
            ...config,
            ...this.switchMobileDesktopDimensions({width: '900px', height: '710px'}, {fullScreenForMobile: true}),
            disableClose: true,
            data,
        };
        return lastValueFrom(
            this.dialogRef.open(SummaryOfOccupiedTablesDialog, configEdited).afterClosed()
        );
    }
}

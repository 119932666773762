<ng-container
    *ngIf="{
            showToolbarContext: toolbarEventsContextService.showToolbarContext$|async,
            componentPortal: rightSidenavService.componentPortal$|async,
            centralToolbarComponent: centralToolbarComponent$|async,
            backBtn: toolbarEventsContextService.backButton$|async,
            label: toolbarEventsContextService.label,
            showButtonsBar: toolbarEventsService.showButtonsBar$|async
            } as data"
>
    <mat-sidenav-container class="sidenav-container" [hasBackdrop]="false" [ngClass]="{'*:tw-pointer-events-none mat-pointer-events-none': lockUIActions()}">

        @if(!isMobile && !isSidenavOpen) {
            <mat-sidenav #sidenavFixed class="sidenav tw-w-min frame-sidenav"
                fixedInViewport
                role="navigation"
                [mode]="'side'"
                [@.disabled]="true"
            >
                <div class="tw-flex tw-flex-col tw-h-full">
                    <mat-toolbar-row>
                        <button
                            [color]="'primary'"
                            type="button"
                            aria-label="Toggle sidenav"
                            mat-icon-button
                            class="frame-button"
                            (click)="toggle()"
                        >
                            <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
                        </button>
                    </mat-toolbar-row>
                    @if(currentRoute) {
                        <app-fixed-nav-list
                            class="tw-flex tw-flex-col tw-h-full"
                            [currentRoute]="($any(currentRoute)?.self?.url) || $any(currentRoute)?.url || currentRoute"
                            (goTo)="goTo($event)"
                            (menuClickOpenSettings)="toggleSubMenuSettings()"
                            (automaticPrintOpenSettings)="toggleAutomaticPrintSettings()"
                        ></app-fixed-nav-list>
                    }
                </div>
            </mat-sidenav>
        }
        @if(data.componentPortal) {
            <mat-sidenav
                #sidenavDetail
                class="tw-w-full sm:tw-w-[26rem] tw-rounded-tl-3xl"
                [opened]="rightSidenavService.isOpen"
                (closed)="rightSidenavService.isOpen=false"
                [mode]="'over'"
                position="end">
                <ng-template [cdkPortalOutlet]="$any(data.componentPortal)"></ng-template>
            </mat-sidenav>
        }
        @if(isSidenavOpen) {
            <mat-sidenav #sidenavMenu
                id="sidenavMenu"
                class="tw-rounded-tr-3xl tw-w-[20rem]"
                role="navigation"
                [mode]="'over'"
                [autoFocus]="false">
                <app-sidenav-menu
                    #appSidenavMenu
                    (closeMenuSidenav)="closeMenuSidenav()"
                    (goTo)="goTo($event)"/>
            </mat-sidenav>
        }

        <mat-sidenav-content (click)="closeMenuSidenav()" class="sidenav-content tw-overflow-hidden" [class.tw-ml-[80px]]="!isMobile">
            <mat-toolbar class="frame-toolbar tw-px-0 sm:tw-px-4" color="primary" [attr.border-color]="'primary'" [class.tw-pointer-events-none]="isSidenavOpen">
                <app-generalized-toolbar id="generalized-toolbar">
                    <app-left-toolbar start [menuIcon]="isMobile" (menuClickChange)="toggle()" id="left-toolbar"></app-left-toolbar>
                    <div [cdkPortalOutlet]="data.centralToolbarComponent" center class="tw-contents"></div>
                    @if(data.showButtonsBar) {
                        <app-buttons-bar end class="tw-flex tw-justify-end" id="buttons-bar"></app-buttons-bar>
                    }
                </app-generalized-toolbar>
            </mat-toolbar>
            <ng-container *ngTemplateOutlet="page"></ng-container>
        </mat-sidenav-content>
    </mat-sidenav-container>
    <ng-template #page>
        <div id="contentContainer" class="tw-relative tw-h-[calc(100%-56px)] sm:tw-h-[calc(100%-4rem)]" [ngClass]="{'tw-flex':data.showToolbarContext}"  [class.tw-pointer-events-none]="isSidenavOpen">
            @if(data.showToolbarContext) {
                <app-left-context-toolbar [label]="data.label|translate" [btn]="data.backBtn||undefined" class="sm:tw-pr-10 tw-h-12"></app-left-context-toolbar>
            }
            <div appUpgradeUiView class="tw-flex-[2]" [ngClass]="{'absolute-container':data.showToolbarContext && !isContextTabs(), 'context-toolbar-tabs':data.showToolbarContext && isContextTabs()}"></div>
            @if(data.showToolbarContext) {
                <app-buttons-bar [isContextToolbar]="true" class="tw-flex tw-flex-1 tw-justify-end tw-h-12 sm:tw-pr-4 tw-pr-0"></app-buttons-bar>
            }
        </div>
    </ng-template>
</ng-container>


<tilby-dialog-toolbar [hideConfirm]="true" [hideCancel]="!canDismiss">
    <div class="tw-flex tw-justify-center tw-w-full" [ngClass]="canDismiss ? 'tw-mr-12' : 'tw-mr-3'" toolbar-content>
        <img src="assets/images/tilby/logo.png" class="tw-h-10" alt="tilby_logo"/>
    </div>
</tilby-dialog-toolbar>
<tilby-dialog-content>
    <div class="tw-flex" [ngClass]="{'tw-justify-around': hideUsers, 'tw-justify-between': !hideUsers }">
        @if(!hideUsers) {
            <section class="users-sessions-manager-list-section mobile-section" [ngClass]="{'tw-w-1/2': openPinKeypad, 'tw-w-full': !openPinKeypad}" style="height: 330px; overflow-y: hidden;">
                <div class="tw-h-[46px] tw-pb-[4px]">
                    <div class="tw-h-[46px] tw-flex tw-justify-end">
                        @if(openFilter){
                            <div class="tw-w-[200px]">
                                <mat-form-field class="tw-w-full tw-h-[46px]" subscriptSizing="dynamic" cdkFocusInitial>
                                    <mat-label translate="USERS.SESSIONS.FILTER"></mat-label>
                                    <input matInput id="userFilterInput" type="text" [(ngModel)]="userFilter" (input)="onFilterChange($event)">
                                    <button mat-icon-button matSuffix style="top: -4px" (click)="clearUserFilter()">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>
                        }
                        <div class="tw-flex tw-gap-2 tw-items-center">
                            @if(!openFilter){
                                <div class="tw-flex tw-items-center tw-h-full">
                                    <button class="buttons tw-h-full" (click)="userFilterOpen()">
                                        <mat-icon class="tw-m-0" style="transform: scale(1.4);">filter_list</mat-icon>
                                    </button>
                                </div>
                            }
                            @if(showNewUser()){
                                <div class="tw-flex tw-items-center tw-h-full">
                                    <button class="buttons tw-h-full" (click)="newLogin()">
                                        <mat-icon class="tw-m-0" style="transform: scale(1.4);">add</mat-icon>
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div class='tw-h-[17.4rem]'>
                    <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="tw-h-full">
                        <div *cdkVirtualFor="let user of usersFiltered">
                            <div class="tw-flex tw-items-center tw-gap-4 tw-mb-2" (click)="selectUserSession(user)" [ngClass]="{ 'selected': userSelected === user}">
                                @if(user.thumbnail){
                                    <img src="{{user.thumbnail}}" class="users-sessions-manager-user-thumbnail" alt="{{user.username}}">
                                }
                                @if(!user.thumbnail){   
                                    <div class="tw-mt-2 tw-ml-4">
                                        <mat-icon style="color:gray; transform: scale(2)">account_circle</mat-icon>
                                    </div>
                                }
                                <div class="tw-flex tw-flex-col">
                                    {{ user.first_name | uppercaseFirstLetter }} {{ user.last_name | uppercaseFirstLetter}}
                                    {{ user.username }}
                                </div>
                            </div>
                        </div>
                    </cdk-virtual-scroll-viewport>
                </div>
            </section>
        }

        @if(this.userSelected?.pin) {
            <section class="users-sessions-manager-pin-section mobile-section">
                <div class="pin-container tw-h-fit tw-pb-[22px] tw-px-5">
                    @for(digit of pinDigitClass; track digit) {
                        <div [ngClass]="digit" class="circle-pin"></div>
                    }
                </div>
                <keypad-pin class="tw-flex tw-justify-around" field="pincode" maxlength="4" (digitChange)="onDigitChange($event)" [blockKeypad]="blockPinKeypad"></keypad-pin>
            </section>
        }
    </div>
    <div class="tw-flex tw-justify-center tw-mt-2.5">
        <img src="assets/images/tilby/Zuc_Orizz_B.svg" class="tw-h-7" alt="Logo Zucchetti">
    </div>
</tilby-dialog-content>
import angular from 'angular';
import _ from 'lodash';

angular.module('fidelity').directive('topbarFidelity', function() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            context: "="
        },
        template: require('./topbar-fidelity.html'),
        controller: ["$scope", "$element", "$attrs", "$mdSidenav", "$state", "connection", "offlineDialog", function($scope, $element, $attrs, $mdSidenav, $state, connection, offlineDialog) {
            var dateTimeout;

            $scope.isConnectionOffline = function() {
                return connection.isOffline();
            };

            $scope.openOfflineDialog = function() {
                offlineDialog.show();
            };

            $scope.openLeftMenu = function() {
                $mdSidenav('left').toggle();
            };

            var changeState = function(targetState) {
                if ($state.current.name !== targetState) {
                    if (_.isFunction($scope.context.checkBeforeExit)) {
                        $scope.context.checkBeforeExit().then(function() {
                            $state.go(targetState);
                        });
                    } else {
                        $state.go(targetState);
                    }
                }
            };

            $scope.goToSetupCampaigns = function() {
                changeState("app.fidelity.setup.campaigns");
            };

            $scope.goToSetupRules = function() {
                changeState("app.fidelity.setup.rules");
            };

            $scope.goToSetupPrizes = function() {
                changeState("app.fidelity.setup.prizes");
            };
        }]
    };
});

import {Component, Inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {TranslateModule} from "@ngx-translate/core";
import {CustomFormGroup, TilbyMagicFormComponent} from "@tilby/tilby-ui-lib/components/tilby-magic-form";

export interface MagicFormData<Title extends string,Form extends CustomFormGroup<any>> {
    itemId?:number,
    title:Title,
    form: Form,
    buttonConfirmLabel?: string,
    buttonCancelLabel?: string,
    extraData?: any
};

@Component({
    selector: 'app-magic-form-dialog',
    standalone: true,
    imports: [CommonModule, MatButtonModule, TranslateModule, MatDialogModule, TilbyMagicFormComponent],
    templateUrl: './magic-form-dialog.component.html',
    styleUrls: ['./magic-form-dialog.component.scss']
})
export class MagicFormDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<MagicFormDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: MagicFormData<string,CustomFormGroup<any>>,
    ) {

    }
}

import angular from 'angular';
import { HistoryPagination, NotificationResult, GetAPi, MissingMessagesResult } from './iot-models'
import { IotMessageManager } from './iot-message-manager';
import { IotHistoryStorage } from './iot-history-storage';
export class IotHistoryManager {

    constructor(
        private errorsLogger: any,
        private restManager: any,
        private iotMessageManager: IotMessageManager,
        private historyStorage: IotHistoryStorage,
        private checkManager: any
    ) {
    }

    /**
     * Logs an event using errorsLogger.sendReport
     * @param {*} message
     * @param content
     * @returns
     */
    private logMessagingEvent = (message: string, content: any, send_anyway: boolean) => {
        if (send_anyway == true) {
            this.errorsLogger.sendReport({
                type: 'iot-history: ' + message,
                content: content
            })
            return;
        }
        if (this.checkManager.getSetting("messaging.iot_send_report")) {
            this.errorsLogger.sendReport({
                type: 'iot-history: ' + message,
                content: content
            })
            return;
        }
    };

    private getList: GetAPi = async (entityName, entityID, queryString) => {
        let dataResponse = await this.restManager.getList(entityName, queryString);
        if (dataResponse.status) {
            throw dataResponse;
        } else {
            return dataResponse
        }
    }

    reset() {
        this.historyStorage.history = {};
    }

    async processHistory(pagination: HistoryPagination): Promise<boolean> {
        try {
            if (pagination) {
                let resHistory: NotificationResult = await this.getList("notifications", undefined, pagination);
                if (resHistory.count > 0) {
                    // need to recover messages
                    for (let message of resHistory.items) {
                        await this.iotMessageManager.processMessage(message, true).catch(err => {
                            this.logMessagingEvent(`process message broken on: ${message.msg_id}`, err, true);
                        });
                    }
                }
                return true;
            } else {
                let resHistory: NotificationResult = await this.getList("notifications", undefined, { is_v2_table: true });
                if (resHistory.count > 0) {
                    // need to recover messages
                    for (let message of resHistory.items) {
                        await this.iotMessageManager.processMessage(message, true).catch(err => {
                            this.logMessagingEvent(`process message broken on: ${message.msg_id}`, err, true);
                        });
                    }
                }
                return true;
            }
        } catch (error: any) {
            if (error?.status !== -1 && error?.status !== 403 && error != "MISSING_OAUTH_DATA") {
                this.logMessagingEvent("process history", error, false);
            }

            return false;
        }
    }
    async getHistory(pagination: HistoryPagination): Promise<NotificationResult> {
        try {
            if (pagination) {
                let resHistory: NotificationResult = await this.getList("notifications", undefined, pagination);
                return resHistory;
            } else {
                let resHistory: NotificationResult = await this.getList("notifications", undefined, { is_v2_table: true });
                return resHistory;

            }
        } catch (error: any) {
            if (error?.status !== -1 && error?.status !== 403 && error != "MISSING_OAUTH_DATA") {
                this.logMessagingEvent("get History", error, false);
            }
            throw error;
        }
    }
    /**
     * @description Check if there are any missing messages in the local history
     * @returns
     */
    isMissingMessages(): MissingMessagesResult {
        if (this.historyStorage.lastMessageIdChecked >= this.historyStorage.lastMessageId - 1) {
            return { missing: false, firstElementMissing: 0 };
        }
        let messages = Object.keys(this.historyStorage.history);
        let listMessages: number[] = []
        messages.forEach(x => listMessages.push(parseInt(x)));

        let container: {
            messages: number[];
            i: number;
            last: number;
            missing: number[];
        } = {
            messages: [],
            i: 0,
            last: 1,
            missing: []
        };

        Object.assign(container, {
            messages: listMessages,
            i: this.historyStorage.lastMessageIdChecked,
            last: this.historyStorage.lastMessageId,
            missing: []
        });

        for (container.i; container.i <= container.last; container.i++) {
            if (container.messages.indexOf(container.i) == -1) {
                container.missing.push(container.i);
            }
        }
        this.historyStorage.lastMessageIdChecked = container.last;
        let res: MissingMessagesResult = { missing: container.missing.length > 0, firstElementMissing: container.missing[0] };
        return res;
    }

}
IotHistoryManager.$inject = ["errorsLogger", "restManager", "IotMessageManager", "IotHistoryStorage", "checkManager"];
angular.module('core').service('IotHistoryManager', IotHistoryManager);

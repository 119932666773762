import angular from 'angular';
import _ from 'lodash';
import {SalesItems} from "tilby-models";

class RefundItemsSelectorController {
    constructor(
        $scope: any,
        $mdDialog: any,
        saleItems: any[]
    ) {
        saleItems = _.cloneDeep(saleItems);

        for(let saleItem of saleItems) {
            if(Number.isInteger(saleItem.quantity)) {
                saleItem._quantities = [...Array(saleItem.quantity + 1).keys()];
            } else {
                saleItem._quantities = [0, saleItem.quantity];
            }

            saleItem._quantity = 0;
        }

        $scope.saleItems = saleItems;

        $scope.cancel = $mdDialog.cancel;

        $scope.isConfirmDisabled = () => !$scope.saleItems.find((saleItem:SalesItems&any) => saleItem._quantity);

        $scope.selectAll = () => {
            for(let saleItem of saleItems) {
                saleItem._quantity = saleItem._quantities[saleItem._quantities.length - 1];
            }
        }

        $scope.onBarcodeScanned = (barcode: String) => {
            for(let saleItem of saleItems) {
                if(saleItem.barcode === barcode) {
                    let quantityIndex = _.findIndex(saleItem._quantities, (qty) => qty === saleItem._quantity)

                    if(quantityIndex < (saleItem._quantities.length - 1)) {
                        saleItem._quantity = saleItem._quantities[quantityIndex + 1];
                        return;
                    }
                }
            }
        }

        $scope.confirm = () =>  {
            let saleItems = _.cloneDeep($scope.saleItems.filter((saleItem:SalesItems&any) => saleItem._quantity));

            for(let saleItem of saleItems) {
                saleItem.quantity = saleItem._quantity;
                delete saleItem._quantity;
                delete saleItem._quantities;
            }

            $mdDialog.hide(saleItems);
        }
    }
}

RefundItemsSelectorController.$inject = ["$scope", "$mdDialog", "saleItems"];

class RefundItemsSelector {

    constructor(
        private $mdDialog: any
    ) {
    }

    public show (saleItems: any[]) {
        return this.$mdDialog.show({
            controller: RefundItemsSelectorController,
            template: require('./refund-items-selector.html'),
            locals: {
                saleItems: saleItems
            }
        });
    }
}

RefundItemsSelector.$inject = ["$mdDialog"];

angular.module('history').factory('refundItemsSelector', RefundItemsSelector);

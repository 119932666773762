<div class="tw-flex tw-h-full">
    <section class="{{isMobile ? 'tw-w-full' : 'tw-w-[30%]'}}">
        <app-booking-data [rooms]="rooms" [bookings]="bookings"></app-booking-data>
    </section>
    <mat-divider [vertical]="true" style="border: 2px solid"></mat-divider>
    <ng-container *ngIf="!isMobile">
        <section class="tw-w-[70%]">
            <app-booking-map [rooms]="rooms" [bookings]="bookings"></app-booking-map>
        </section>
    </ng-container>
</div>
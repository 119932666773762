import angular from 'angular';
import _ from 'lodash';

angular.module('kiosk').factory('kioskUtils', kioskUtils);

kioskUtils.$inject = ["$state", "$translate", "checkManager", "entityManager", "DigitalPaymentsManager", "documentPrinter", "optionsDialog"];

function kioskUtils($state, $translate, checkManager, entityManager, DigitalPaymentsManager, documentPrinter, optionsDialog) {
    const kioskStates = [
        'standby',
        'service_selection',
        'catalog',
        'cart',
        'document_selection',
        'customer_selection',
        //'customer_form',
        'payment_selection',
        'confirmation'
    ];

    const baseState = 'app.kiosk.';

    let kioskSaleData = {};

    const kioskUtils = {
        getKioskSaleData: function() {
            return _.cloneDeep(kioskSaleData);
        },
        setKioskSaleData: function(obj) {
            Object.assign(kioskSaleData, obj);
        },
        resetKioskSaleData: function() {
            kioskSaleData = {};
        },
        getMediaType: function(url) {
            let extensionRegex = /^.*\.([^.]+)$/;
            let fileExtension = url.match(extensionRegex);

            if(!_.isNil(fileExtension)) {
                fileExtension = fileExtension[1];

                switch(fileExtension) {
                    case 'mp4':
                        return 'video/mp4';
                    case 'ogg': case 'ogv':
                        return 'video/ogg';
                    case 'webm':
                        return 'video/webm';
                    case 'gif': case 'png': case 'jpg': case 'jpeg':
                        return 'image';
                    default:
                        break;
                }
            }
        },
        getLabelText: function(kioskSettings, preferenceName, translationName) {
            if(kioskSaleData.defaultLanguage) {
                return kioskSettings[preferenceName] || $translate.instant(translationName);
            } else {
                return $translate.instant(translationName);
            }
        },
        compareStatesOrder: function() {
            let previousIndex = _.indexOf(kioskStates, _.replace($state.transition.from().name, baseState, ''));
            let currentIndex = _.indexOf(kioskStates, _.replace($state.current.name, baseState, ''));

            if(previousIndex < currentIndex) {
                return 1;
            } else if(previousIndex > currentIndex) {
                return -1;
            } else {
                return 0;
            }
        },
        skipModule: function() {
            let currentIndex = _.indexOf(kioskStates, _.replace($state.current.name, baseState, ''));
            let newIndex = currentIndex + this.compareStatesOrder($state.transition.from(), $state.current);

            $state.go(baseState + kioskStates[newIndex]);
        },
        setLanguage: function(lang) {
            let preferredLanguage = checkManager.getPreferredLanguage();

            kioskSaleData.defaultLanguage = _.isNil(lang) || preferredLanguage.id === lang;

            return kioskSaleData.defaultLanguage ? checkManager.setupLanguage() : $translate.use(lang);
        },
        getAvailableCashPayments: (paymentMethods, preferredMethod) => {
            const allowedCashPayments = [19, 21, 32, 38, 40].filter((methodTypeId) => DigitalPaymentsManager.isPaymentDigitalEnvironmentAllowed(methodTypeId));
            let availableMethods = paymentMethods.filter((method) => allowedCashPayments.includes(method.payment_method_type_id));

            if(preferredMethod) {
                availableMethods = _.sortBy(availableMethods, (method) => method.id === preferredMethod ? 0 : 1);
            }

            return availableMethods;
        },
        getAvailableCardPayments: (paymentMethods, preferredMethod) => {
            const allowedCardPayments = [14, 27, 31].filter((methodTypeId) => DigitalPaymentsManager.isPaymentDigitalEnvironmentAllowed(methodTypeId));

            let availableMethods = paymentMethods.filter((method) => allowedCardPayments.includes(method.payment_method_type_id));

            if(preferredMethod) {
                availableMethods = _.sortBy(availableMethods, (method) => method.id === preferredMethod ? 0 : 1);
            }

            return availableMethods;
        },
        getAvailablePrinters: (printers) => {
            let printersByValidType = _.chain(printers)
                .filter(documentPrinter.isPrinterUsable)
                .groupBy((printer) => {
                    switch(printer.type) {
                        case 'fiscal': case 'rt':
                            return 'fiscal';
                        case 'receipt':
                            return 'receipt';
                        default:
                            break;
                    }
                })
            .value();

            let availableFiscalPrinters = [];
            let availableReceiptPrinters = [];

            if(printersByValidType.fiscal) {
                availableFiscalPrinters = _.uniq([
                    ...printersByValidType.fiscal.filter((printer) => (printer.print_fiscal_receipt && (printer.print_invoice || printer.print_e_invoice))),
                    ...printersByValidType.fiscal.filter((printer) => (printer.print_invoice || printer.print_e_invoice)),
                    ...printersByValidType.fiscal.filter((printer) => printer.print_fiscal_receipt)
                ]);
            }

            if(printersByValidType.receipt) {
                availableReceiptPrinters = _.uniq([
                    ...printersByValidType.receipt.filter((printer) => (printer.print_generic_receipt && printer.print_generic_invoice)),
                    ...printersByValidType.receipt.filter((printer) => printer.print_generic_receipt),
                    ...printersByValidType.receipt.filter((printer) => printer.print_generic_invoice)
                ]);
            }

            return [...availableFiscalPrinters, ...availableReceiptPrinters];
        },
        getNextModule: function() {
            let currentIndex = _.indexOf(kioskStates, _.replace($state.current.name, baseState, ''));
            return baseState + kioskStates[++currentIndex];
        },
        getPreviousModule: function() {
            let currentIndex = _.indexOf(kioskStates, _.replace($state.current.name, baseState, ''));
            return baseState + kioskStates[--currentIndex];
        },
        getKioskModules: function() {
            return _.clone(kioskStates);
        },
        promptKioskConfig: async () => {
            let printers = await entityManager.printers.fetchCollectionOffline();
            let paymentMethods = await entityManager.paymentMethods.fetchCollectionOffline();

            let [defaultPrinter, defaultCardPayment, defaultCashPayment] = await optionsDialog.show($translate.instant('KIOSK.PRECONFIGURATION.TITLE'), [{
                default: _.toInteger(checkManager.getPreference('kiosk.document_selection.default_printer_id')),
                message: $translate.instant('KIOSK.PRECONFIGURATION.PRINTER'),
                options: kioskUtils.getAvailablePrinters(printers).map((printer) => ({ name: printer.name, value: printer.id })),
                required: true
            }, {
                default: _.toInteger(checkManager.getPreference('kiosk.payment_selection.default_card_payment_id')) || 'none',
                message: $translate.instant('KIOSK.PRECONFIGURATION.CARD_PAYMENT'),
                options: [
                    { name: $translate.instant('KIOSK.PRECONFIGURATION.NONE'), value: 'none' },
                    ...kioskUtils.getAvailableCardPayments(paymentMethods).map((pm) => ({ name: pm.name, value: pm.id }))
                ],
                required: true
            }, {
                default: _.toInteger(checkManager.getPreference('kiosk.payment_selection.default_cash_payment_id')) || 'none',
                message: $translate.instant('KIOSK.PRECONFIGURATION.CASH_PAYMENT'),
                options: [
                    { name: $translate.instant('KIOSK.PRECONFIGURATION.NONE'), value: 'none' },
                    ...kioskUtils.getAvailableCashPayments(paymentMethods).map((pm) => ({ name: pm.name, value: pm.id }))
                ],
                required: true
            }]);

            if(defaultPrinter) {
                checkManager.setUserPreference('kiosk.document_selection.default_printer_id', defaultPrinter);
            }

            if(defaultCardPayment) {
                checkManager.setUserPreference('kiosk.payment_selection.default_card_payment_id', defaultCardPayment);
            }

            if(defaultCashPayment) {
                checkManager.setUserPreference('kiosk.payment_selection.default_cash_payment_id', defaultCashPayment);
            }
        },
        askForHelp: function() {

        }
    };

    return kioskUtils;
}
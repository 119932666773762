import angular from 'angular';
import _ from 'lodash';
import { MathUtils } from 'src/app/shared/utils';

angular.module('core').factory('fiscalUtils', fiscalUtils);

fiscalUtils.$inject = ["$http", "$translate", "$injector", "checkManager", "errorsLogger", "entityManager", "util"];

function fiscalUtils($http, $translate, $injector, checkManager, errorsLogger, entityManager, util) {
    const cashPaymentTypes = [1, 19, 21, 32, 38, 40];
    const unclaimedPaymentTypes = [2, 6, 10, 20, 22, 23, 24, 25, 26, 28, 29, 33, 34, 36];

    const taxCodeItMap = [1, 0, 5, 7, 9, 13, 15, 17, 19, 21, 1, 0, 5, 7, 9, 13, 15, 17, 19, 21, 2, 4, 18, 20, 11, 3, 6, 8, 12, 14, 16, 10, 22, 25, 24, 23];

    const taxCodeRegexps = {
        IT: /^[0-9A-Z]{16}$/,
        ES: /(X(-|\.)?0?\d{7}(-|\.)?[A-Z]|[A-Z](-|\.)?\d{7}(-|\.)?[0-9A-Z]|\d{8}(-|\.)?[A-Z])$/gmi
    };

    const amountReducer = (amount, payment) => fiscalUtils.roundDecimals(amount + _.toNumber(fiscalUtils.roundDecimals(payment.amount)));

    const fiscalUtils = {
        roundDecimals: (num, decimals) => MathUtils.round(num, decimals),
        roundDecimalsToString: function roundDecimalsToString(num, decimals) {
            if (_.isUndefined(decimals)) {
                decimals = 2;
            }
            return this.roundDecimals(num, decimals).toFixed(decimals).replace('.', ',');
        },
        decimalToString: function decimalToString(number, decimals) {
            if(_.isUndefined(decimals)) {
                decimals = 2;
            }
            return number.toFixed(decimals).replace('.',',');
        },
        isCashPayment: (methodTypeId) => cashPaymentTypes.includes(methodTypeId),
        isMethodUnclaimed: function(methodTypeId, unclaimed) {
            if([16].includes(methodTypeId)) {
                return unclaimed;
            } else {
                return unclaimedPaymentTypes.includes(methodTypeId);
            }
        },
        isRefundVoidSale: (sale) => {
            //Legacy check, (check only if final_amount is negative), left in case of regressions
            if(checkManager.getSetting('fiscal.use_legacy_refund_check')) {
                return sale.final_amount < 0;
            }

            //New check, if the amount is exactly 0 check if all items are refunds
            if(sale.final_amount !== 0) {
                return sale.final_amount < 0;
            }

            return (sale.sale_items || []).every((item) => item.type === 'refund');
        },
        isPaymentRoundingEnabled: () => checkManager.getPreference('cashregister.enable_payment_rounding'),
        checkTaxCode: function checkTaxCode(taxCode) {
            let valid = true;

            taxCode = _.chain(taxCode).toString().toUpper().value();

            switch(checkManager.getShopCountry()) {
                case 'ES':
                    let spainValidationRegex = new RegExp(taxCodeRegexps['ES']);
                    valid = spainValidationRegex.test(taxCode);
                break;
                case 'IT':
                    let s = 0;

                    let italyValidationRegex = new RegExp(taxCodeRegexps['IT']);

                    if(!italyValidationRegex.test(taxCode)) {
                        valid = false;
                    } else {
                        //Checksum validation
                        for(let i = 0; i < 15; i++) {
                            let c = taxCode.charCodeAt(i);

                            if( c < 65 ) {
                                c = c - 48;
                            } else {
                                c = c - 55;
                            }
                            if( i % 2 === 0 ) {
                                s += taxCodeItMap[c];
                            } else {
                                s += c < 10 ? c : c - 10;
                            }
                        }

                        valid = String.fromCharCode(65 + s % 26) === taxCode.charAt(15);
                    }
                break;
                default:
                break;
            }

            return valid;
        },
        sendRequest: function(url, options) {
            if(!_.isObject(options)) {
                options = {};
            }

            const logRequests = _.isNil(options.log) ? checkManager.getPreference('fiscalprinter.log_data') : (options.log ? true : false);

            if(logRequests) {
                errorsLogger.sendReport({
                    type: 'fiscalPrinter',
                    ip_address: url,
                    content: {
                        action: 'send',
                        data: options.data
                    }
                });
            }

            let httpOptions = _.chain(options).pick(['method', 'timeout', 'headers', 'data', 'params', 'responseType']).defaults({ url: url, method: 'GET' }).value();
            let responseData;

            return new Promise((resolve, reject) => {
                $http(httpOptions).then(function(responseSuccess) {
                    responseData = angular.copy(responseSuccess);
    
                    resolve(responseSuccess);
                }, function(responseError) {
                    responseData = angular.copy(responseError);
    
                    reject(responseError);
                }).finally(function() {
                    if(logRequests && httpOptions.responseType !== 'blob') {
                        errorsLogger.sendReport({
                            type: 'fiscalPrinter',
                            ip_address: url,
                            content: {
                                action: 'receive',
                                data: responseData.data,
                                status: responseData.status
                            }
                        });
                    }
                });
            });
        },
        getPriceChangeAmount: function getPriceChangeAmount(priceChange, partialPrice, decimals) {
            switch(priceChange.type) {
                case 'discount_fix':
                    return fiscalUtils.roundDecimals(-priceChange.value, decimals);
                case 'discount_perc':
                    return fiscalUtils.roundDecimals(-(partialPrice * priceChange.value / 100), decimals);
                case 'surcharge_fix':
                    return fiscalUtils.roundDecimals(priceChange.value, decimals);
                case 'surcharge_perc':
                    return fiscalUtils.roundDecimals(partialPrice * priceChange.value / 100, decimals);
                case 'gift':
                    return fiscalUtils.roundDecimals(-partialPrice, decimals);
            }
        },
        extractSaleItems: (sale) => {
            const considerIngredientsRemoval = checkManager.getPreference("orders.ingredients_removal_affects_price");
            const saleUtils = $injector.get('newSaleUtils');
            const halfPortionValue = saleUtils.getHalfPortionValue();

            let saleItemsResult = _.cloneDeep(sale.sale_items) || [];

            //Get ingredients and variations amount
            for(let saleItem of saleItemsResult) {
                let ingredients = Array.isArray(saleItem.ingredients) ? saleItem.ingredients : [];
                let ingredientsSum = 0;
                let variations = Array.isArray(saleItem.variations) ? saleItem.variations : [];
                let variationsSum = 0;
                let notesArray = [];

                for(let variation of variations) {
                    if(variation.price_difference) {
                        variationsSum += variation.price_difference;
                    }

                    notesArray.push(`${variation.name} - ${variation.value}`);
                }

                for(let ingredient of ingredients) {
                    if(ingredient.price_difference) {
                        switch(ingredient.type) {
                            case 'removed':
                                if(considerIngredientsRemoval) {
                                    ingredientsSum -= ingredient.price_difference;
                                }
                                break;
                            case 'added':
                            default:
                                ingredientsSum += ingredient.price_difference * (ingredient.quantity || 1);
                            break;
                        }
                    }

                    notesArray.push(`${ingredient.type == 'removed' ? '-' : '+'}${ingredient.quantity > 1 ? ingredient.quantity + 'x' : ''} ${ingredient.name}`);
                }

                const price = (saleItem.half_portion) ? util.round(saleItem.price * (1 - halfPortionValue)) : saleItem.price;

                //Merge notes
                let notes = notesArray.join('\n');

                if(saleItem.notes) {
                    notes += '\n' + saleItem.notes;
                }

                Object.assign(saleItem, {
                    price: util.round((price + _.toFinite(variationsSum) + _.toFinite(ingredientsSum))),
                    ingredients: [],
                    notes: notes,
                    price_changes: _.orderBy(saleItem.price_changes, 'index', ['asc']),
                    variations: [],
                });
            }

            return saleItemsResult;
        },
        extractTax: function(sale, departments) {
            let groupedItems;

            //If the departments list is provided, group the taxes by vat id, else use group by vat value
            if(departments) {
                let departmentsById = _.keyBy(departments, 'id');
                groupedItems = _.groupBy(sale.sale_items, (saleItem) => departmentsById[saleItem.department_id]?.vat?.id);
            } else {
                groupedItems = _.groupBy(sale.sale_items, 'vat_perc');
            }

            // Compute the aggregate VAT info for each items group
            return _.reduce(groupedItems, function(result, items, ivaValue) {
                let i = _.reduce(items, function(aggregateIva, item) {
                    let tax = (item.final_price - item.final_net_price) * item.quantity;
                    let taxable = item.final_net_price * item.quantity;
                    let total = item.final_price * item.quantity;

                    return {
                        tax: aggregateIva.tax + _.toNumber(tax),
                        taxable: aggregateIva.taxable + _.toNumber(taxable),
                        total: aggregateIva.total + _.toNumber(total)
                    };
                }, { tax: 0, taxable: 0, total: 0 });

                result[ivaValue] = {
                    tax: fiscalUtils.roundDecimals(i.tax),
                    taxable: fiscalUtils.roundDecimals(i.taxable),
                    total: fiscalUtils.roundDecimals(i.total)
                };

                return result;
            }, {});
        },
        extractDepartments: function extractDepartments(sale) {
            // Group items by department
            var groupedItems = _.groupBy(sale.sale_items, function(si) {
                return si.department_id;
            });

            var departmentNames = [];
            _.forEach(groupedItems, function(gi) {
                departmentNames.push({
                    id: gi[0].department_id,
                    name: gi[0].department_name
                });
            });

            var aggregateDepartmentsAmount = _.reduce(groupedItems, function(result, items, departmentId) {

                // Per ogni array di items restituisce l'aggregato IVA
                var i = _.reduce(items, function(aggregateDepartment, item) {
                    var t = fiscalUtils.roundDecimals(item.final_price * item.quantity);
                    return fiscalUtils.roundDecimals(aggregateDepartment + _.toNumber(t));
                }, 0);

                result[departmentId] = i;
                return result;
            }, {});

            var aggregateDepartments = [];

            for (var did in aggregateDepartmentsAmount) {
                aggregateDepartments.push({
                    id: _.toInteger(did),
                    amount: aggregateDepartmentsAmount[did],
                    name: _.result(_.find(departmentNames, {
                        id: _.toInteger(did)
                    }), 'name')
                });
            }

            return aggregateDepartments;
        },
        /**
         *  extractPayments
         *  @param sale
         *  @return an an array of objects like {method_type_id: payment_method_id, amount: sum(amounts)}
         */
        extractPayments: function extractPayments(sale) {
            return _(sale.payments).groupBy('payment_method_id').mapValues(function(payments, pmId) {
                var methodTypeId = _.head(payments).payment_method_type_id;
                var result = [];

                if(methodTypeId === 33 && checkManager.getPreference('cashregister.send_giftcards_as_unclaimed_payments')) {
                    methodTypeId = 2;
                }

                var resultTemplate = {
                    date: _.chain(payments).map('date').min().value(),
                    method_type_id: methodTypeId,
                    method_id: _.toInteger(pmId),
                    method_name: _.head(payments).payment_method_name,
                };

                switch(methodTypeId) {
                    case 6: case 34: //Tickets
                        result = _(payments).map(function(ticket) {
                            return _.assign({}, resultTemplate, {
                                amount: fiscalUtils.roundDecimals(ticket.amount),
                                code: ticket.code,
                                unclaimed: true
                            });
                        }).orderBy(['amount'], ['desc']).value();
                    break;
                    case 16: //For prepaid payments, split between credit and ticket type payments
                        result = _([
                            _.assign({}, resultTemplate, { method_type_id: 26, amount: _(payments).filter({ unclaimed: true }).reduce(amountReducer, 0), unclaimed: true }),
                            _.assign({}, resultTemplate, { method_type_id: 26, amount: _(payments).filter({ unclaimed: false }).reduce(amountReducer, 0), unclaimed: false }),
                        ]).filter('amount').value();
                    break;
                    default:
                        var paymentsArr = payments;
                        var paymentsToMerge = paymentsArr;

                        if(_.includes([1, 26], methodTypeId)) {
                            paymentsToMerge = _.remove(paymentsArr, function(payment) { return _.isNil(payment.payment_data); });

                            _.forEach(paymentsArr, function(payment) {
                                var paymentData;

                                try {
                                    paymentData = JSON.parse(payment.payment_data);
                                } catch(e) {
                                    paymentData = payment.payment_data;
                                }

                                result.push(_.assign({}, resultTemplate, {
                                    amount: payment.amount,
                                    unclaimed: fiscalUtils.isMethodUnclaimed(methodTypeId),
                                    payment_data: paymentData
                                }));
                            });
                        }

                        if(!_.isEmpty(paymentsToMerge)) {
                            result.push(_.assign({}, resultTemplate, {
                                amount: _(paymentsToMerge).reduce(amountReducer, 0),
                                unclaimed: fiscalUtils.isMethodUnclaimed(methodTypeId)
                            }));
                        }
                    break;
                }

                return result;
            }).values().flatten().orderBy([function(payment) {
                return fiscalUtils.isCashPayment(payment.method_type_id) ? 1 : 0;
            } , 'amount'], ['asc', 'desc']).value();
        },
        parseRTDocumentSequentialNumber: function parseRTDocumentSequentialNumber(seq_number) {
            var seqNumStr = _.padStart(seq_number, 8, '0');
            var dailyClosingNum = seqNumStr.substring(0, 4);
            var docSeqNum = seqNumStr.substring(4, 8);
    
            return {
                daily_closing_num: _.toInteger(dailyClosingNum),
                document_sequential_number: _.toInteger(docSeqNum),
                sequential_number_string: dailyClosingNum + '-' + docSeqNum
            };
        },
        getCustomerInfo: function getCustomerInfo(saleCustomer) {
            var result = [];
            // Customer info in fiscal receipt
			if (_.isObject(saleCustomer) && ((!_.chain(saleCustomer.first_name).trim().isEmpty().value() && !_.chain(saleCustomer.last_name).trim().isEmpty().value()) || !_.chain(saleCustomer.company_name).trim().isEmpty().value())) {
                result.push("Informazioni cliente:");
                result.push(util.getCustomerCaption(saleCustomer));

				if (saleCustomer.fidelity) {
					result.push(_.toString(saleCustomer.fidelity));
				}

				if (saleCustomer.tax_code) {
					result.push("CF: " + _.toUpper(saleCustomer.tax_code));
				}

				if (saleCustomer.vat_code) {
					result.push("P.IVA: " + saleCustomer.vat_code);
				}

				if(saleCustomer.phone) {
					result.push("Tel: " + saleCustomer.phone);
				}

				if(saleCustomer.mobile) {
					result.push("Mobile: " + saleCustomer.mobile);
				}

				if(saleCustomer.billing_street && saleCustomer.billing_zip && saleCustomer.billing_city) {
					result.push(" ");
					result.push("Informazioni di fatturazione:");
					result.push(saleCustomer.billing_street + (saleCustomer.billing_number ? ', ' + _.toString(saleCustomer.billing_number) : ''));
					result.push(saleCustomer.billing_zip + ' ' + saleCustomer.billing_city);
				}

				if(saleCustomer.shipping_street && saleCustomer.shipping_zip && saleCustomer.shipping_city) {
					result.push(" ");
					result.push("Informazioni per la consegna:");
					result.push(saleCustomer.shipping_street + (saleCustomer.shipping_number ? ', ' + _.toString(saleCustomer.shipping_number) : ''));
					result.push(saleCustomer.shipping_zip + ' ' + saleCustomer.shipping_city);
				}
            }
            
            return result;
        },
        getQueueCouponRows: function getQueueCouponRows(sale, columns, options) {
            if(!columns) {
                columns = 46;
            }

            if(!_.isObject(options)) {
                options = {};
            }
    
            var queueMainRow = _.chunk([checkManager.getPreference('cashregister.queue.main_row') || $translate.instant('CASHREGISTER.QUEUE.PRINT_MAIN_ROW'), sale.sale_number].join(' '), columns);
            var queueSecondRow = _.chunk(checkManager.getPreference('cashregister.queue.second_row') || $translate.instant('CASHREGISTER.QUEUE.PRINT_SECOND_ROW'), columns);
            var rows = [{ text: ' ', doubleHeight: true }];
    
            _.forEach(queueMainRow, function(row) {
                rows.push({ text: _.pad(row.join(''), options.doubleWidth ? _.floor(columns / 2) : columns), doubleHeight: true });
            });
    
            _.forEach(queueSecondRow, function(row) {
                rows.push({ text: _.pad(row.join(''), columns), doubleHeight: false });
            });
            
            rows.push({ text: ' ', doubleHeight: true });
    
            return rows;
        },
        getPrinterConfigurationResources: async () => {
            let results = {};

            results.activityCodes = await entityManager.activityCodes.fetchCollectionOffline();
            results.departments = await entityManager.departments.fetchCollectionOffline();
            results.paymentMethods = await entityManager.paymentMethods.fetchCollectionOffline();
            results.vats = await entityManager.vat.fetchCollectionOffline();

            return results;
        },
        getFiscalReceiptHeaderLines: (sale) => {
            const  headerLines = [];

            headerLines.push(sale.name);
    
            if(sale.room_name && sale.table_name) {
                headerLines.push(`${sale.room_name} - ${sale.table_name}`);
            }
    
            if(sale.notes) {
                headerLines.push(sale.notes);
            }

            if(headerLines.length) {
                headerLines.push("");
            }
    
            return headerLines;
        }
    };

    return fiscalUtils;
}
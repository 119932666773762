import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "uppercaseFirstLetter",
    standalone: true
})
export class UppercaseFirstLetterPipe implements PipeTransform {
    transform(value: string): string {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
}

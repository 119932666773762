import angular from 'angular';
import _ from 'lodash';
import moment from 'moment-timezone';
import * as Papa from 'papaparse';

angular.module('stock').controller('StockMovementsCtrl', StockMovementsCtrl);

StockMovementsCtrl.$inject = ["$scope", "$rootScope", "$state", "$translate", "$filter", "environmentInfo", "alertDialog", "checkManager", "util", "user", "restManager", "addEditMovement", "itemLabelsDialog", "stockLocations"];

function StockMovementsCtrl($scope, $rootScope, $state, $translate, $filter, environmentInfo, alertDialog, checkManager, util, user, restManager, addEditMovement, itemLabelsDialog, stockLocations) {
	$scope.topbar_context = {
        sidenav_label: $translate.instant('STOCK.MOVEMENTS.MOVEMENTS'),
        viewmode: 'movements',
        mode: 'showcase',
        textFilter: "",
        movementsPerPage: 50,
        movementsPerPageList: [50, 100, 200, 500],
        movementType: "allManuals",
        currentBatch: {},
        stockLocations: stockLocations,
        stockLocationId: 1,
        dateFilter: {
            from: moment().subtract(1, 'day').toDate(),
            to: moment().toDate()
        }
    };

    Object.assign($scope, {
        currentPage: 0,
        loadingInProgress: false,
        Math: Math,
        movementsData: {},
        showPriceData: checkManager.isModuleEnabled('items')
    });

    var getQueryObject = function() {
        var queryObj = {
            orderby_desc: 'date'
        };

        switch($scope.topbar_context.mode) {
            case 'showcase': case 'search':
                Object.assign(queryObj, {
                    date_since: moment($scope.topbar_context.dateFilter.from).startOf('day').toISOString(),
                    date_max: moment($scope.topbar_context.dateFilter.to).endOf('day').toISOString(),
                    barcode: $scope.barcodeInput,
                    stock_location_id: $scope.topbar_context.stockLocationId
                });

                if(!_.isEmpty($scope.topbar_context.textFilter)) {
                    var encodedFilter = util.fixedEncodeURIComponent($scope.topbar_context.textFilter);

                    var xQuery = _.map([
                        ['item_id', encodedFilter],
                        ['code', encodedFilter],
                        ['name_like', encodedFilter],
                        ['supplier_name_like', encodedFilter],
                        ['item.sku_like', encodedFilter],
                        ['item.department.name_like', encodedFilter],
                        ['item.category.name_like', encodedFilter],
                        ['supplier_order_like', encodedFilter],
                    ], function(val) { return val.join('='); }).join(',');

                    queryObj.x_query = 'OR(' + xQuery + ')';
                }

                switch ($scope.topbar_context.movementType) {
                    case 'allManuals':
                        queryObj.sale_id = 'null';
                        break;
                    case 'load': case 'unload': case 'setup':
                        queryObj.type = $scope.topbar_context.movementType;
                        break;
                    case 'unloadSales':
                        Object.assign(queryObj, {
                            type: 'unload',
                            sale_id: 'notnull'
                        });
                        break;
                    case 'unloadManual':
                        Object.assign(queryObj, {
                            type: 'unload',
                            sale_id: 'null'
                        });
                        break;
                    default:
                        break;
                }
            break;
            case 'batch_details':
                Object.assign(queryObj, _.pick($scope.topbar_context.currentBatch, ['date', 'type', 'supplier_id', 'supplier_order', 'stock_location_id']));
            break;
        }

        return queryObj;
    };


    $scope.topbar_context.searchTriggered = function() {
        if(!_.isEmpty($scope.topbar_context.textFilter)) {
            $scope.topbar_context.mode = 'search';
        }
        $scope.changeQueryStatus();
    };

    $scope.topbar_context.onSubmitBarcode = function(barcodeInput) {
        $scope.barcodeInput = barcodeInput;
        $scope.changeQueryStatus();
    };

    $scope.topbar_context.selectMovementType = function(type) {
        $scope.topbar_context.movementType = type;
        $scope.changeQueryStatus();
    };

    $scope.topbar_context.changeResultsPerPage = function(movementsPerPage) {
        if($scope.topbar_context.movementsPerPage !== movementsPerPage) {
            $scope.topbar_context.movementsPerPage = movementsPerPage;
            $scope.changeQueryStatus();
        }
    };

    $scope.topbar_context.goBack = function() {
        switch($scope.topbar_context.mode) {
            case 'batch_details': case 'search':
                $scope.topbar_context.currentBatch = {};
                $scope.topbar_context.mode = 'showcase';
                $scope.movementsData = {};
                $scope.currentPage = 0;
                $scope.topbar_context.textFilter = '';

                getMovementsPage($scope.currentPage);
            break;
            default: break;
        }
    };

    $scope.topbar_context.newSupplierOrder = () => {
        $state.go("app.stock.new-movement", { mode: 'supplier_order' });
    };

    $scope.selectMovement = function(movement) {
        switch($scope.topbar_context.mode) {
            case 'showcase':
                $scope.topbar_context.currentBatch = movement;
                $scope.topbar_context.mode = 'batch_details';
                $scope.movementsData = {};
                $scope.currentPage = 0;
                getMovementsPage($scope.currentPage);
            break;
            case 'batch_details': case 'search':
                addEditMovement.show(movement).then(function(result) {
                    if(_.isArray(result) && result[1]) {
                        var cloned = _.cloneDeep(movement);
                        cloned.date = moment().startOf('minute').toISOString();

                        delete cloned.id;
                        $state.go("app.stock.new-movement", { movements: [cloned], stock_location_id: $scope.topbar_context.stockLocationId } );
                    } else if(!_.isArray(result)){
                        if(movement !== result) {
                            angular.copy(result,movement);
                        }
                    }
                });
            break;
        }
    };

    const getBatchMovements = (batch) => restManager.getList('stock_movements', {
        date: batch.date,
        type: batch.type,
        stock_location_id: batch.stock_location_id,
        pagination: false
    });

    $scope.generateLabels = async (batch) => {
        let results = await getBatchMovements(batch);
        let itemMovements = _.filter(results, { entry_type: 'item' });

        let items = await util.getItemsFromIds(itemMovements);
        let foundItems = _.values(items);

        // bugfix: remove undefined values
        for(let item of foundItems) {
            if(!item) {
                foundItems.splice(foundItems.indexOf(item), 1);
            }
        }

        if(!_.isEmpty(foundItems)) {
            let quantitiesMap = {};

            for(let movement of itemMovements) {
                if(_.isNil(quantitiesMap[movement.item_id])) {
                    quantitiesMap[movement.item_id] = 0;
                }

                quantitiesMap[movement.item_id] += movement.quantity;
            }

            for(let item of foundItems) {
                if(item?.id && quantitiesMap[item.id]) {
                    item._quantity = quantitiesMap[item.id];
                }
            }

            itemLabelsDialog.openDialog({ data: {
                promotion: { base_pricelist: 1 },
                items: structuredClone(foundItems)
            }});
        } else {
            alertDialog.show($translate.instant('STOCK.MOVEMENTS.LABELS_GENERATOR_NO_ITEMS'));
        }
    };

    const fieldsMovements = ['code', 'barcode', 'name', 'quantity', 'options_values', 'combination', 'supplier_name', 'supplier_order', 'type', 'cause', 'notes', 'date'];

    $scope.exportBatch = async (batch) => {
        const batchMovements = await getBatchMovements(batch);

        const movementsForCSV = _.map(batchMovements, function(movement) {
            let m = _.pick(movement, ['name', 'barcode', 'code', 'options_values', 'combination', 'supplier_name', 'supplier_order', 'quantity', 'notes']);

            switch(movement.type) {
                case 'load':
                    m.cause = $translate.instant('STOCK.CAUSES.' + _.toUpper(movement.load_cause));
                    break;
                case 'unload':
                    m.cause = $translate.instant('STOCK.CAUSES.' + _.toUpper(movement.unload_cause));
                    break;
                default:
                break;
            }

            Object.assign(m, {
                date: $filter('sclDate')(movement.date),
                type: $translate.instant('STOCK.TOPBAR_STOCK.' + _.toUpper(movement.type)),
            });

            return m;
        });

        const csv = Papa.unparse(movementsForCSV, {
            columns: fieldsMovements,
            delimiter: ";",
            header: true,
        });

        util.downloadFile(csv, 'export_csv_movements.csv', 'text/plain;charset=utf-8;');
    };

    $scope.goodsDelivery = async (batch) =>  {
        const batchMovements = await getBatchMovements(batch);

        $state.go("app.stock.new-movement", { movements: batchMovements, mode: 'goods_delivery' });
    };

    $scope.rollbackBatch = async (batch) => {
        const results = await getBatchMovements(batch);

        _.forEach(results, function(movement) {
            Object.assign(movement, {
                date: moment(movement.date).add(1, 'second').toISOString(),
                quantity: Math.abs(movement.quantity)
            });

            switch(movement.type) {
                case 'load':
                    Object.assign(movement, {
                        type: 'unload',
                        unload_cause: 'negative_adjustment',
                        load_cause: null
                    });
                break;
                case 'unload':
                    Object.assign(movement, {
                        type: 'load',
                        load_cause: 'positive_adjustment',
                        unload_cause: null
                    });
                break;
                case 'setup':
                break;
            }
        });

        $state.go("app.stock.new-movement", { movements: results, stock_location_id: $scope.topbar_context.stockLocationId } );
    };

    $scope.getRowAmount = function(movement) {
        return _.isNil(movement.price) ? '' : $filter('sclCurrency')(util.round(movement.price * movement.quantity * (1 - (movement.perc_discount / 100))));
    };

    $scope.getMovementCause = (movement) => (movement.load_cause || movement.unload_cause);

    $scope.getMovementTypeString = function(movement) {
        var movementType = $translate.instant('STOCK.TOPBAR_STOCK.' + _.toUpper(movement.type));

        if(movement.type !== 'setup') {
            movementType += ' (' + $translate.instant('STOCK.CAUSES.' + _.toUpper(movement.load_cause || movement.unload_cause)) + ')';
        }

        return movementType;
    };

    $scope.addNewMovement = function() {
        $state.go("app.stock.new-movement", { stock_location_id: $scope.topbar_context.stockLocationId });
    };

    $scope.changeQueryStatus = function() {
        $scope.currentPage = 0;
        getMovementsPage(0);
    };

	$scope.hasNoResults = function() {
        // when result of get is 404
        if ($scope.movementsData === false) {
            return true;
        }
        return $scope.movementsData.length === 0;
    };

    $scope.decrementPage = function() {
        if ($scope.currentPage > 0) {
            $scope.currentPage--;
            getMovementsPage($scope.currentPage);
        }
    };

    $scope.incrementPage = function() {
        if ($scope.currentPage < $scope.movementsData.pages - 1) {
            $scope.currentPage++;
            getMovementsPage($scope.currentPage);
        }
    };

    $scope.goToFirstPage = function() {
        if ($scope.currentPage > 0) {
            $scope.currentPage = 0;
            getMovementsPage($scope.currentPage);
        }
    };

    $scope.goToLastPage = function() {
        if ($scope.currentPage < $scope.movementsData.pages - 1) {
            $scope.currentPage = $scope.movementsData.pages - 1;
            getMovementsPage($scope.currentPage);
        }
    };

    $scope.itemProduction = async () => {
        await addEditMovement.show(null, { productionMode: true, operator: user });
        getMovementsPage($scope.currentPage);
    };

    $scope.topbar_context.onStockLocationChange = () => {
        getMovementsPage();
    };

    $scope.topbar_context.downloadMovements = async () => {
        const result = await restManager.downloadOne("stock_movements", "export", Object.assign(getQueryObject(), { pagination: false }));
        util.downloadFile(result.data, 'export_csv_movements.csv', 'text/plain;charset=utf-8;');
    };

    $scope.topbar_context.shareMovements = async () => {
        const result = await restManager.downloadOne("stock_movements", "export", Object.assign(getQueryObject(), { pagination: false }));
        const blob = result.data;
        const title = 'export_csv_movements';

        const dataUrl = await util.blobToDataURL(blob);

        window.plugins.socialsharing.shareWithOptions({
            message: title, // not supported on some apps (Facebook, Instagram)
            subject: title, // fi. for email
            files: ['df:' + title + '.csv;' + dataUrl], // an array of filenames either locally or remotely
        }, () => {}, (error) => {});
    };

    $scope.canDownload = function() {
        return environmentInfo.canDownloadFiles();
    };

	const getMovementsPage = async (pageNumber) => {
        $rootScope.$broadcast("loader:changeStatus", "stockMovementsLoader", { enabled: true });
        $scope.loadingInProgress = true;

        try {
            $scope.movementsData.results = [];

            const query = Object.assign({
                pagination: true,
                per_page: $scope.topbar_context.movementsPerPage,
                page: pageNumber
            }, getQueryObject());

            const movementsEndpoint = $scope.topbar_context.mode === 'showcase' ? "stock_movements/batches" : "stock_movements";

            $scope.barcodeInput = null;

            const result = await restManager.getList(movementsEndpoint, query);

            $scope.movementsData = result;
            $scope.topbar_context.currentBatch.notes = result?.results?.[0]?.notes;
        } finally {
            $rootScope.$broadcast("loader:changeStatus", "stockMovementsLoader", { enabled: false });
            $scope.loadingInProgress = false;
        }
    };

    getMovementsPage($scope.currentPage);
}

import angular from 'angular';

angular.module('printers').factory('dgfeResult', ["$mdDialog", "$translate", "environmentInfo", "util", function($mdDialog, $translate, environmentInfo, util) {
    dgfeResultController.$inject = ["$scope", "from", "to", "result"];
    function dgfeResultController($scope, from, to, result) {
        $scope.from = from;
        $scope.to = to;
        $scope.result = result;

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.canShare = function() {
            return environmentInfo.canShare();
        };

        $scope.share = function() {
            var title = ($scope.from && $scope.to) ? $translate.instant('PRINTERS.DGFE.FROM') + " " + $scope.from + " " + $translate.instant('PRINTERS.DGFE.TO') + " " + $scope.to : $translate.instant('PRINTERS.DGFE.DGFE');
            var blob = new Blob(['\ufeff', result], { type: 'text/plain' });

            util.blobToDataURL(blob).then(function(dataUrl) {
                window.plugins.socialsharing.shareWithOptions({
                    message: title, // not supported on some apps (Facebook, Instagram)
                    subject: title, // fi. for email
                    files: ['df:' + title + '.txt;' + dataUrl], // an array of filenames either locally or remotely
                  }, function() {
    
                }, function(error) {
    
                });
            });

        };
    }

    var dgfeResult = {
        show: function(from, to, result) {
            return $mdDialog.show({
                controller: dgfeResultController,
                template: require('./dgfe-result.html'),
                locals: {
                    from: from,
                    to: to,
                    result: result
                }
            });
        }
    };

    return dgfeResult;
}]);
import angular from 'angular';
import _ from 'lodash';

angular.module('kiosk').controller('KioskStandbyCtrl', kioskStandbyCtrl);

kioskStandbyCtrl.$inject = ["$scope", "$state", "environmentInfo", "kioskSettings", "kioskUtils", "checkManager", "migrationHelper"];

function kioskStandbyCtrl($scope, $state, environmentInfo, kioskSettings, kioskUtils, checkManager, migrationHelper) {
    const ActiveSale = migrationHelper.getActiveSale();
    const countryThatUseEnglish = ['GB', 'IE', 'US', 'CA', 'AU', 'MT', 'BS', 'VG', 'VI'];
    var buttonTextColor = kioskSettings['general.base_button_text_color'];
    var buttonBackgroundColor = kioskSettings['general.base_button_bgcolor'];
    var zoomLevel = _.toNumber(kioskSettings['general.zoom_level']);
    var country = checkManager.getShopCountry();

    _.assign($scope, {
        countryDoesNotSpeakEnglish: !_.includes(countryThatUseEnglish, country),
        mediaUrl: _.toString(kioskSettings['standby.media_url']),
        logoUrl: kioskSettings['general.media_url_logo'] ? _.toString(kioskSettings['general.media_url_logo']) : false,
        iconStyle: { color: buttonTextColor ? buttonTextColor : '#fff' },
        buttonStyle: {
            color: buttonTextColor ? buttonTextColor : '#fff',
            'background-color': buttonBackgroundColor ? buttonBackgroundColor : "#666"
        },
        zoomStyle: { 'zoom': zoomLevel },
        showButtonStandby: kioskSettings['standby.show_button_standby']
    });

    if ($scope.mediaUrl) {
        $scope.mediaType = kioskUtils.getMediaType($scope.mediaUrl);
    }

    $scope.next = function(lang) {
        $scope.$parent.enableTimeouts();
        kioskUtils.setLanguage(lang).finally(function() {
            $state.go(kioskUtils.getNextModule());
        });
    };

    if(environmentInfo.isElectronApp()) {
        const electronHandler = window.require('electron');

        electronHandler.webFrame.setZoomFactor(_.toFinite(zoomLevel) || 1);
        electronHandler.ipcRenderer.invoke('kiosk-mode', true);
    }

    kioskUtils.resetKioskSaleData();
    ActiveSale.loadSale(null);
}
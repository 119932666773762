import angular from 'angular';
import { Transition } from 'angular-ui-router';

angular.module('history', ['application']);

angular.module('history').config(["$stateProvider", "$translatePartialLoaderProvider", function ($stateProvider: any, $translatePartialLoaderProvider: any) {
    $translatePartialLoaderProvider.addPart('history');

    $stateProvider.state('app.history', {
        url: '/history',
        redirectTo: 'app.new.history.sales'
    });

    $stateProvider.state('app.history.e_invoices', {
        url: '/e-invoices',
        views: {
            "appContent@app": {
                template: require('./partial/history-e-invoices/history-e-invoices.html'),
                controller: 'HistoryEInvoicesCtrl'
            }
        }
    });

    $stateProvider.state('app.history.e_invoices_passive', {
        url: '/e-invoices/passive',
        views: {
            "appContent@app": {
                template: require('./partial/history-e-invoices-passive/history-e-invoices-passive.html'),
                controller: 'HistoryEInvoicesPassiveCtrl'
            }
        }
    });


    $stateProvider.state('app.new.history', {
        url: '/history',
        redirectTo: 'app.new.history.sales'
    });

    $stateProvider.state('app.new.history.sales', {
        url: '/sales',
        params: {
            id: { type: 'int', value: null }
        },
        component: 'historySalesShowcase'
    });

    $stateProvider.state('app.new.history.sale', {
        url: '/sale/:id',
        params: {
            id: { type: 'int', value: null },
        },
        redirectTo: function (transition: Transition) {
            return transition.router.stateService.target('app.new.history.sales', transition.params(), { reload: 'app.new.history' });
        }
    });

    $stateProvider.state('app.new.history.customer', {
        url: '/sale/customer',
        params: {
            customer: null,
            salesToBeInvoicedCustomer: null
        },
        component: 'historySalesShowcase'
    });

    $stateProvider.state('app.new.history.cash-movements', {
        url: '/cash-movements',
        component: 'historyCashMovements'
    });

    /* Add New States Above */
}]);

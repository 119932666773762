import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { mobileCheck } from "@tilby/tilby-ui-lib/utilities";
import { BookingMapComponent } from "./booking-map/booking-map.component";
import { BookingDataComponent } from "./booking-data/booking-data.component";
import { Bookings, Rooms } from "tilby-models";
import { MatDividerModule } from "@angular/material/divider";


@Component({
    selector: 'app-booking-list',
    templateUrl: './booking-list.component.html',
    styleUrls: ['./booking-list.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        BookingDataComponent,
        BookingMapComponent,
        MatDividerModule
    ],
})
export class BookingListComponent {

    isMobile = mobileCheck();
    @Input() rooms: Rooms[] = [];
    @Input() bookings: Bookings[] = [];

    constructor(){}

    ngOnInit(){

    }
}
import angular from 'angular';
import { AngularJSObservableHelper } from 'app/libs/angularjs-observable-helper';
import _ from 'lodash';

angular.module('printers').controller('PrintersCtrl', PrintersCtrl);

PrintersCtrl.$inject = ["$scope", "$rootScope", "$translate", "screenOrientation", "user", "printers", "$state", "newPrinter", "confirmDialog"];

function PrintersCtrl($scope, $rootScope, $translate, screenOrientation, user, printers, $state, newPrinter, confirmDialog) {
    Object.assign($scope, {
        loading: false,
        printers: printers,
        topbar_context: {
            landscapeMode: _.startsWith(screenOrientation.getOrientation(), 'landscape'),
            view: $state.current.name.split('.')[2] || 'home'
        },
        user: user
    });

    $scope.goToGeneralSettings = async() => {
        if($state.current.name !== "app.printers.general") {
            await $scope.topbar_context.checkUnsaved();
            $scope.currentSelection = undefined;
            $scope.goTo('printers.general');
        }
    };

    $scope.topbar_context.goBack = async() => {
        await $scope.topbar_context.checkUnsaved();
        $scope.currentSelection = undefined;
        $state.go('app.printers');
    };

    $scope.goToPrinter = async (printer) => {
        if($scope.currentSelection !== printer && printer.id) {
            await $scope.topbar_context.checkUnsaved();
            $scope.currentSelection = printer;
            $scope.goTo('printers.detail', { id: printer.id });
        }
    };

    $scope.createNewPrinter = async () => {
        //TODO show modal
        await $scope.topbar_context.checkUnsaved();
        let result = await newPrinter.show();
        $scope.goTo('printers.new', result);
    };

    $scope.currentSelection = _.find(printers, {id: parseInt($state.params.id)});

    // when user creates a new printer
    if ($state.current.name === 'app.printers.new') {
        if ($state.params.type) {
            var printerToAdd = {
                name: 'Nuova stampante',
                type: $state.params.type,
                id: 0
            };
            printers.push(printerToAdd);
            $scope.currentSelection = printerToAdd;
        }
    }

    $scope.topbar_context.saveButton = {
        label: '',
        action: undefined,
        enabled: function() { return false;}
    };

    // used by this controller and child controllers
    $scope.showPrintersLoader = function() {
        $rootScope.$broadcast("loader:changeStatus", "printersLoader", { enabled: true });
        $scope.loading = true;
        $scope.topbar_context.saveButton = {
            label: '',
            action: undefined,
            enabled: function() { return false;}
        };
    };

    $scope.hidePrintersLoader = function() {
        $rootScope.$broadcast("loader:changeStatus", "printersLoader", { enabled: false });
        $scope.loading = false;
    };

    $scope.topbar_context.checkUnsaved = async () => {
        if ($scope.topbar_context.unsaved && $scope.topbar_context.unsaved()) {
            let result = await confirmDialog.show($translate.instant('PRINTERS.PRINTERS.EDITS_NOT_SAVED'));

            if(!result) {
                throw true;
            }
        }
    };

    $scope.goTo = function(destination, params, reloadPrinters) {
        // displays loader (may be already displayed)
        $scope.showPrintersLoader();
        $state.go(`app.${destination}`, params, reloadPrinters ? { reload: "app.printers" } : undefined);
    };

    $scope.$watch(function() {
        return $state.current.name.split('.')[2] || 'home';
    }, function(newView) {
        $scope.topbar_context.view = newView;
    });

    AngularJSObservableHelper.attachObservableToScope($scope, screenOrientation.getOrientationObservable(), (orientation) => {
        $scope.topbar_context.landscapeMode = _.startsWith(orientation, 'landscape');
    });

    if($scope.topbar_context.landscapeMode) {
        $scope.goToGeneralSettings();
    }
}
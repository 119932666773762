import { escape } from "src/app/shared/string-utils";

type XmlNodeValue = string | number | XMLNode;
type XmlNodeAttributes = { [key: string]: string | number | boolean | null };

export type XMLNodeObject = {
	name?: string,
	attributes?: Record<string, string>,
	children?: XMLNodeObject[],
	content?: string
}

type XMLNodeOptions = {
	escape?: boolean
}

export class XMLNode {
	private name?: string;
	private content?: string;
	private children: XMLNode[] = [];
	private attributes: XmlNodeAttributes;
	private escape: boolean;

	constructor(name?: string, attributes?: Partial<XmlNodeAttributes>, content?: string | number, options?: XMLNodeOptions) {
		this.name = name;
		this.content = content != null ? String(content) : undefined;
		this.attributes = {};
		this.escape = options?.escape ?? false;

		if (typeof attributes === 'object') {
			for (const [key, value] of Object.entries(attributes)) {
				if (value != null && !Number.isNaN(value)) {
					this.attributes[key] = value;
				}
			}
		}
	}
	
	/**
	 * Appends a child node to the XMLNode.
	 *
	 * @param {XmlNodeValue} child - The node to append.
	 * @return {XMLNode} The updated XMLNode with the child appended.
	 */
	public appendChild(child: XmlNodeValue): XMLNode {
		if(child instanceof XMLNode) {
			this.children.push(child);
		} else {
			this.children.push(new XMLNode(undefined, undefined, child));
		}

		return this;
	}

	/**
	 * Removes a child node from the XMLNode.
	 *
	 * @param {XMLNode} target - The node to remove.
	 * @return {XMLNode} The updated XMLNode with the child removed.
	 */
	public removeChild(target: XMLNode): XMLNode {
		const idx = this.children.findIndex((child) => child === target);

		if (idx !== -1) {
			this.children.splice(idx, 1);
		}

		return this;
	}

	/**
	 * Returns a string representation of the XMLNode.
	 *
	 * @return {string} The string representation of the XMLNode.
	 */
	public toString(): string {
		if (!this.name) {
			return this.content ?? '';
		}

		let elements = this.children.map((child) => this.escape ? escape(child.toString()) : child.toString()).join('');

		if(this.content) {
			elements += this.escape ? escape(this.content) : this.content;
		}

		let attributeString = Object.entries(this.attributes)
			.map(([key, value]) => `${key}="${value}"`)
			.join(' ');

		attributeString = attributeString ? ` ${attributeString}` : '';

		return `<${this.name}${attributeString}>${elements}</${this.name}>`;
	}

	private static recursiveParser(element: Element): XMLNodeObject | null {
		if (!element) {
			return null;
		}

		const children: XMLNodeObject[] = [];
		const name = element.localName;
		const attributes: Record<string, string> = {};

		for (const attr of Array.from(element.attributes)) {
			attributes[attr.localName] = attr.value
		}

		if (element.children.length) {
			for (const child of Array.from(element.children)) {
				const parseResult = XMLNode.recursiveParser(child);

				if (parseResult) {
					children.push(parseResult);
				}
			}
		}

		return { name, attributes, children, content: element.textContent ?? undefined };
	}

	
	/**
	 * Parses an XML string into an array of XMLNodeObjects (one element for each first-level node).
	 *
	 * @param {string} xmlString - The XML string to parse.
	 * @return {XMLNodeObject[]} The array of parsed XMLNodeObjects.
	 */
	public static stringToObject(xmlString: string): XMLNodeObject[] {
		const parser = new DOMParser();
		const xml = parser.parseFromString(xmlString, 'text/xml');

		const result: XMLNodeObject[] = [];

		for(const child of Array.from(xml.children)) {
			const parseResult = XMLNode.recursiveParser(child);

			if (parseResult) {
				result.push(parseResult);
			}
		}

		return result;
	}

	/**
	 * Creates an XMLNode from a given payload object.
	 *
	 * @param {XMLNodeObject} payload - The payload object containing the name, attributes, content, and children of the XMLNode.
	 * @param {XMLNodeOptions} [options] - Optional options for the XMLNode.
	 * @return {XMLNode} The created XMLNode object.
	 */
	public static fromObject(payload: XMLNodeObject, options?: XMLNodeOptions): XMLNode {
		const node = new XMLNode(payload.name, payload.attributes, payload.content, options);

		for (const child of payload.children || []) {
			node.appendChild(XMLNode.fromObject(child, options));
		}

		return node;
	}
}
import angular from 'angular';
import _ from 'lodash';

angular.module('stock').factory("stockSummary", ["$mdDialog", function($mdDialog) {
    var stockSummaryController = function($scope, $filter, restManager) {        
        $scope.summaryData = {};

        restManager.getList('stock_summary').then(function(summaryData) {
            var sData = _.head(summaryData);
            _.assign($scope.summaryData, {
                stock_value: $filter('sclCurrency')(sData.stock_value),
                stock_amount: _.toInteger(sData.stock_amount)
            });
        });

        $scope.cancel = function() {
            $mdDialog.cancel();
        };
    };
    stockSummaryController.$inject = ["$scope", "$filter", "restManager"];

    var stockSummary = {
        show: function() {
            return $mdDialog.show({
                controller: stockSummaryController,
                template: require('./stock-summary.html')
            });
        }
    };

    return stockSummary;
}]);
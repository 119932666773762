import {
    AfterViewInit,
    Component,
    inject,
    ViewChild
} from "@angular/core";
import {$state} from "app/ajs-upgraded-providers";
import {DataExport, SuppliersFe, TableData, TableRowUpdatable} from "src/app/shared/model/model";
import {distinctUntilChanged, from} from "rxjs";
import {RowClickedEvent} from "ag-grid-community";
import {Rest, TilbyToolbar} from "src/app/models";
import {autobind} from "src/app/models/decorators.model";
import {ModuleEvents} from "src/app/core/services/toolbar-events/toolbar-events.model";
import {GridClientSideComponent} from "src/app/shared/components/grid-client-side/grid-client-side.component";
import {headersTranslate} from "src/app/shared/components/grid";
import {EntityManagerService} from "src/app/core/services/entity/entity-manager.service";
import {ConfigurationManagerService} from "src/app/core/services/configuration-manager/configuration-manager.service";
import {
    ConfirmDialogService,
    GridDownloadDialogService,
    OpenDialogsService
} from "src/app/dialogs";
import {GridCellFormatterService,GridCellExportFormatterService} from "src/app/shared/components";
import {Transition} from "angular-ui-router";
import {SupplierFormService, TutorialInAppSuppliersService} from "../../services";
import {ConnectionService, ToolbarEventsService} from "src/app/core";
import {SupplierDefault} from "../../suppliers-form.model";
import {callbackToGrid} from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { Suppliers } from "tilby-models";
import { OnDestroyService } from "src/app/core/services/on-destroy.service";


@Component({
    selector: "suppliers-showcase",
    templateUrl: "./suppliers-showcase.component.html",
    styleUrls: ["./suppliers-showcase.component.scss"],
    providers: [OnDestroyService]
})
export class SuppliersShowcaseComponent implements AfterViewInit, TilbyToolbar {
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly entityManager = inject(EntityManagerService);
    private readonly state = inject($state);
    private readonly tinapp = inject(TutorialInAppSuppliersService);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    public readonly openDialogsService = inject(OpenDialogsService);
    private readonly onDestroyService = inject(OnDestroyService);
    private readonly gridCellFormatterService = inject(GridCellFormatterService);
    private readonly gridCellExportFormatterService = inject(GridCellExportFormatterService);
    private readonly supplierFormService= inject(SupplierFormService);
    protected readonly configurationManager = inject(ConfigurationManagerService);
    private readonly connection = inject(ConnectionService);
    private readonly gridDownloadDialogService = inject(GridDownloadDialogService);

    @ViewChild('appGridComponent', {static: true}) gridRef!: GridClientSideComponent;
    isSelected = false;
    gridPath = this.state.$current.name.split('.').pop() === 'suppliers';
    query = {
        pagination: false,
        // per_page: 12000,
        // page: 0,
        orderby_asc: "name",
    };
    value = "";
    exportFormatterOptions?: (...any: any[]) => any;
    tableData: TableData[] = [
        {
            rowData$: from(this.entityManager.suppliers.fetchCollectionOnline(this.query) as Promise<Suppliers[]>),
            dataType: new SuppliersFe(),
            headersTranslate: headersTranslate.suppliers_showcase,
            columnsFormatter: this.gridCellFormatterService.suppliers
        }
    ];

    constructor() {
        this.tinapp.init();

        this.state.router.transitionService.onSuccess({}, (transition: Transition&any) => this.gridPath = transition._targetState._identifier.split('.').pop() === 'suppliers');
    }

    //-------- NEW GENERIC TOOLBAR - START ----///

    private movColumnsSwitch = false;
    private isAdvancedFilterActive = false;

    ngOnInit() {
        // first time
        this.createToolbarButtons();
        this.setModuleTitle();
        // on show again
        this.state.router.transitionService.onSuccess({to: 'app.new.suppliers'}, (transition: Transition) => {
            if (!!transition.params().remove) this.gridRef.onRemoveSelected(transition.params().remove);
            this.createToolbarButtons();
        });

        this.toolbarEventsService.events.pipe(distinctUntilChanged(), this.onDestroyService.takeUntilDestroy).subscribe(e => this.callbackToToolbarClick(e))
        this.toolbarEventsService.searchBarValue$.next('');
        this.toolbarEventsService.searchBarIcon$.next('tune');
        this.toolbarEventsService.searchBarIconFilled$.next('tune');
        this.toolbarEventsService.searchBarAction$.next({ openToolPanel: 'filters' });
        this.toolbarEventsService.searchBarActionFilled$.next({ openToolPanel: 'filters' });
        this.exportFormatterOptions = this.gridCellExportFormatterService.suppliersExportFormatter;
    }

    setModuleTitle() {
        this.toolbarEventsService.moduleTitle.next("SUPPLIERS");
    }

    async openDeleteDialog() {
        const res = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'DIALOG.SUPPLIER_DELETE.TITLE',
                confirmLabel: 'DIALOG.SUPPLIER_DELETE.CONFIRM',
                cancelLabel: 'DIALOG.SUPPLIER_DELETE.CANCEL'
            }
        });

        if(res) {
            this.toolbarEventsService.events.next({delete: true});
        }
    }

    /**
     * Create buttons for the toolbar
     */
    createToolbarButtons() {
        this.toolbarEventsService.buttons$.next({
            barButtons: [
                {
                    isIt: () => this.connection.isOffline(),
                    name: 'cloud_off',
                    icon: () => 'cloud_off',
                    click: _ => null
                },
                {
                    isIt: () => true,
                    name: 'add_new_supplier',
                    icon: () => 'playlist_add',
                    click: _ => this.state.go("app.new.suppliers.new")
                },
                {
                    isIt: () => this.isSelected,
                    name: 'edit',
                    icon: () => 'edit',
                    click: _ => this.toolbarEventsService.events.next({edit: true})
                },
                {
                    isIt: () => this.isSelected,
                    name: 'delete',
                    icon: () => 'delete',
                    click: _ => this.openDeleteDialog()
                },

            ],
            panelButtons: [
                {
                    isIt: () => true,
                    name: 'columns_panel',
                    icon: () => 'view_week',
                    label: () => 'TOPBAR.ACTIONS.COLUMN_SELECTED',
                    click: _ => this.toolbarEventsService.events.next({openToolPanel: 'columns'})
                },
                {
                    isIt: () => true,
                    name: 'movable_columns',
                    icon: () => 'drag_indicator',
                    label: () => this.movColumnsSwitch ? 'TOPBAR.ACTIONS.DISABLED_COLUMN_MOVEMENT' : 'TOPBAR.ACTIONS.ENABLED_COLUMN_MOVEMENT',
                    click: _ => this.toolbarEventsService.events.next({
                        type: 'movColumnsSwitch',
                        movColumnsSwitch: this.movColumnsSwitch = !this.movColumnsSwitch
                    })
                },
                {
                    isIt: () => true,
                    name: 'save_columns_position',
                    icon: () => 'save',
                    label: () => 'TOPBAR.ACTIONS.SAVE_COLUMN_PREFERENCE',
                    click: _ => this.toolbarEventsService.events.next({save: true})
                },
                {
                    isIt: () => true,
                    name: 'advanced_filter',
                    icon: () => this.isAdvancedFilterActive ? 'filter_alt_off' : 'filter_list_alt',
                    label: () => this.isAdvancedFilterActive ? 'TOPBAR.ACTIONS.ADVANCED_FILTERS_DISABLED' : 'TOPBAR.ACTIONS.ADVANCED_FILTERS_ENABLED',
                    click: _ => this.toolbarEventsService.events.next({
                        type: 'advFiltersSwitch',
                        advFiltersSwitch: this.isAdvancedFilterActive = !this.isAdvancedFilterActive
                    })
                },
                {
                    isIt: () => true,
                    name: 'upload',
                    icon: () => 'upload',
                    label: () => 'TOPBAR.ACTIONS.IMPORT',
                    click: _ => this.goToImporter()
                },
                {
                    isIt: () => true,
                    name: 'export',
                    icon: () => 'download',
                    label: () => 'TOPBAR.ACTIONS.EXPORT',
                    click: _ => this.toolbarEventsService.events.next({export: true})
                }
            ]
        });
    }

    goToImporter() {
        this.state.go('app.new.file_importer');
    }

    async callbackToToolbarClick(event: Partial<ModuleEvents> = {}) {
        if ("search" in event) this.gridRef.onFilterTextBoxChanged(event.search);
        else if ("delete" in event) this.gridRef.onRemoveSelected();
        else if ("export" in event) await this.gridDownloadDialogService.openDialog('suppliers',{data: this.tableData.map(tableData => ({...tableData,sortFilterQuery: this.gridRef.getSortFilterQuery(),countSelectedRows:this.gridRef.gridApi?.getSelectedRows().length}))}).then(res=>!!res&&this.onExport(res));
        else if ("openToolPanel" in event && event.openToolPanel) this.gridRef.openToolPanel(event.openToolPanel);
        else if ("edit" in event) await this.openDialogsService.openEditRowsDialog<Partial<SupplierDefault>>({data: {form:this.supplierFormService.createForm(undefined,['name','vat_code','id','createdby_id','updatedby_id','conto_vendita'])}}).then(res=>!!res&&this.gridRef.updateItems(callbackToGrid(res)));
        else if ("type" in event && event.type === 'advFiltersSwitch') this.gridRef.advancedFilterSwitch(event.advFiltersSwitch || false);
        else if ("type" in event && event.type === 'movColumnsSwitch') this.gridRef.columnMovableSwitch(event.movColumnsSwitch || false);
        else if ("save" in event) await this.gridRef.openDialogSaveColumnsPreference();
    }

//-------- NEW GENERIC TOOLBAR - END ----///

    ngAfterViewInit() {
        // TODO: Provisional, waiting to know where to store the information centrally (problem for users belonging to multiple store chains)
        // TODO: Disabled waiting for the steps necessary for the tutorial to be managed
        // if (!this.configurationManager.getPreference("suppliers.tutorial")) {
        //     this.configurationManager.setUserPreference("suppliers.tutorial", "1");
        //     this.tinapp.start();
        // }
    }

    addSupplier() {
        this.state.go("app.new.suppliers.new");
    };

    rowClicked({data}: RowClickedEvent) {
        this.movColumnsSwitch && this.toolbarEventsService.events.next({type: 'movColumnsSwitch', movColumnsSwitch: this.movColumnsSwitch = false});
        this.state.go("app.new.suppliers.details", {supplierId: data.id});
    }

    @autobind
    updateItems(updatedRows: TableRowUpdatable[]) {
        // updatedRows.forEach(updatedRow=>this.entityManager.suppliers.putOneOnline<TableRowUpdatable>(updatedRow).then(({results}) => results));
        updatedRows.forEach(updatedRow => this.entityManager.suppliers.putOneOnline(updatedRow as Suppliers) as Promise<Suppliers>);
    }

    crud({method, id}: Rest) {
        if (method === 'delete') this.gridRef.onRemoveSelected(id);
    }

    deleteSuppliers(ids: number[]) {
        ids.forEach(id => {
            this.entityManager.suppliers.deleteOneOnline(id);
        });

    }

    @autobind
    onExport(dataExport: DataExport) {
        this.gridRef?.onBtnExport(dataExport)
    }
}

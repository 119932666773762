import angular from 'angular';
import _ from 'lodash';
import moment from 'moment-timezone';

angular.module('promotions').controller('PromotionDetailsCtrl', ["$scope", "$state", "$translate", "$filter", "$timeout", "entityManager", "barcodeManager", "checkManager", "fiscalUtils", "promotion", "items", "suppliers", "categories", "channels", "chainShops", "alertDialog", "confirmDialog", function($scope, $state, $translate, $filter, $timeout, entityManager, barcodeManager, checkManager, fiscalUtils, promotion, items, suppliers, categories, channels, chainShops, alertDialog, confirmDialog) {

    var brands = _(items).map('brand').reject(_.isNil).uniq().value();
    _.assign($scope, {
        channels: channels,
        currentSection: 'general',
        customerTypes: checkManager.getPreference("customers.custom_types") ? _.split(checkManager.getPreference("customers.custom_types"), '\n') : [],
        filterBrands: function(filterText) { return $filter('filter')(brands, filterText); },
        filterCategories: function(filterText) { return $filter('filter')(categories, filterText); },
        filterChannels: function(filterText) { return $filter('filter')(channels, filterText); },
        filterSuppliers: function(filterText) { return $filter('filter')(suppliers, filterText); },
        isSaving: false,
        availableItems: items,
        availableReqItems: items,
        itemsBySku: _.keyBy(items, 'sku'),
        itemsById: _.keyBy(items, 'id'),
        orderBy: {},
        priceLists: [],
        promotion: promotion,
        requiredItems: [],
        searchFilter: {},
        searchFilterReq: {},
        selectedItems: [],
        shops: chainShops,
        topbar_context: {
            mode: 'details',
            title: promotion.name || $translate.instant('PROMOTIONS.DETAILS.NEW_PROMOTION'),
            goBack: function() {
                if($scope.currentSection === 'required-items') {
                    $scope.currentSection = 'general';
                } else {
                    if($scope.addPromotionForm.$pristine) {
                        $state.go("app.promotions.showcase");
                    } else {
                        confirmDialog.show($translate.instant('PROMOTIONS.DETAILS.EDITS_NOT_SAVED')).then(function(answer) {
                            if(answer) {
                                $state.go("app.promotions.showcase");
                            }
                        });
                    }
                }
            },
            onSubmitBarcode: function(barcodeInput) {
                barcodeManager.manageBarcodeInput(barcodeInput);
            },
            showBarcode: function() {
                return _.includes(['items', 'required-items'], $scope.currentSection);
            }
        },
        weekDays: _.zipWith(moment.localeData('en').weekdaysMin(false), moment.weekdays(false), function(id, name) {
            return {
                id: id,
                name: _.upperFirst(name)
            };
        })
    });

    //Setup pricelists
    _.times(10, function(idx) {
        $scope.priceLists.push({
            id: idx + 1,
            name: checkManager.getPreference("price_list_" + (idx + 1) + "_name") || $translate.instant("PROMOTIONS.DETAILS.PRICE_LIST_START") + ' ' + (idx + 1)
        });
    });

    var parsePromotionsData = function(promotion) {
        //Promotion Type
        _.assign(promotion, {
            _fromDate: promotion.from_date ? moment(promotion.from_date, 'YYYY-MM-DD').toDate() : null,
            _toDate: promotion.to_date ? moment(promotion.to_date, 'YYYY-MM-DD').toDate() : null,
            _startTime: promotion.start_time ? moment(promotion.start_time, 'HH:mm:ss').seconds(0).toDate() : moment('00:00', 'HH:mm').toDate(),
            _endTime: promotion.end_time ? moment(promotion.end_time, 'HH:mm:ss').seconds(0).toDate() : moment('23:59', 'HH:mm').toDate(),
            _type: promotion.perc_discount ? 'discount_perc' : 'discount_fix'
        });

        $scope.selectedItems = [];
        $scope.requiredItems = [];

        _.forEach(promotion.items, function(item) {
            if($scope.itemsBySku[item.item_sku]) {
                _.assign($scope.itemsBySku[item.item_sku], {
                    _enabled: true,
                    _prevId: item.id,
                    _discountedPrice: item.discounted_price
                });
                $scope.selectedItems.push($scope.itemsBySku[item.item_sku]);
            }
        });

        _.forEach(promotion.required_items, function(item) {
            if($scope.itemsBySku[item.item_sku]) {
                _.assign($scope.itemsBySku[item.item_sku], {
                    _required: true,
                    _prevReqId: item.id,
                    _quantity: item.quantity
                });

                $scope.requiredItems.push($scope.itemsBySku[item.item_sku]);
            }
        });

        $scope.updateAvailableItems();
        $scope.updateAvailableReqItems();
    };

    var checkPromotion = function(promotion) {
        if(promotion._type === 'discount_fix') {
            if(!_.every($scope.selectedItems, function(item) {  return _.isFinite(item._discountedPrice) && item._discountedPrice >= 0; })) {
                return 'PROMOTIONS.DETAILS.MISSING_DISCOUNTED_PRICE';
            }
        }

        if($scope.addPromotionForm.$invalid) {
            return $scope.addPromotionForm.$error.required ? 'FORM_ERRORS.INSERT_REQUIRED_DATA' : 'FORM_ERRORS.THERE_ARE_ERRORS';
        }

        return null;
    };

    var preparePromotion = function(promotion) {
        var result = _.cloneDeep(promotion);

        _.assign(result, {
            from_date: result._fromDate ? moment(result._fromDate).format('YYYY-MM-DD') : null,
            to_date: result._toDate ? moment(result._toDate).format('YYYY-MM-DD') : null,
            start_time: moment(result._startTime).startOf('minute').format('HH:mm:ss'),
            end_time: moment(result._endTime).endOf('minute').format('HH:mm:ss')
        });

        delete result._fromDate;
        delete result._toDate;
        delete result._startTime;
        delete result._endTime;

        result.items = _.map($scope.selectedItems, function(item) {
            return {
                item_sku: item.sku,
                id: item._prevId,
                discounted_price: result._type === 'discount_fix' ? item._discountedPrice : null,
            };
        });

        result.required_items = _.map($scope.requiredItems, function(item) {
            return {
                item_sku: item.sku,
                id: item._prevReqId,
                quantity: item._quantity,
            };
        });

        if(result._type === 'discount_fix') {
            delete result.perc_discount;
        } else {
            delete result.base_pricelist;
            delete result.quantity_threshold;
        }

        delete result._type;

        return result;
    };

    var applyItemFilters = function(item, filter) {
        if(filter.supplier && item.default_supplier_id !== filter.supplier.id) {
            return false;
        }

        if(filter.category && item.category_id !== filter.category.id) {
            return false;
        }

        if(filter.brand && item.brand !== filter.brand) {
            return false;
        }

        if(filter.channel && !_.some(item.channels, { channel_id: filter.channel.id })) {
            return false;
        }

        return true;
    };

    $scope.getOrderBy = function(target, field) {
        var currentOrder = _.get($scope.orderBy, [target]);

        return _.endsWith(currentOrder, field) ? currentOrder[0] : null;
    };

    $scope.setOrderBy = function(target, field) {
        var currentOrder = _.get($scope.orderBy, [target]);
        var newOrder = _.startsWith(currentOrder, '+') ? '-' : '+';

        _.set($scope.orderBy, [target], newOrder + field);
    };

    $scope.getPriceField = function() {
        switch(promotion._type) {
            case 'discount_fix':
                return 'price' + (promotion.base_pricelist || 1);
            case 'discount_perc': default:
                return 'price1';
        }
    };

    $scope.hasCost = function(item) {
        return !_.isNil(item.cost);
    };

    $scope.clearDateFrom = function() {
        $scope.promotion._fromDate = null;
    };

    $scope.clearDateTo = function() {
        $scope.promotion._toDate = null;
    };

    $scope.getDiscountedPrice = function(item) {
        return fiscalUtils.roundDecimals(item.price1 - fiscalUtils.roundDecimals(item.price1 * promotion.perc_discount / 100));
    };

    $scope.getItemDiscountColor = function(item) {
        return (item.cost && $scope.getDiscountedPrice(item) < item.cost) ? '#ff5252' : '#4caf50';
    };

    $scope.getItemDiscountedPriceColor = function(item) {
        return (item.cost && item._discountedPrice < item.cost) ? '#ff5252' : '#4caf50';
    };

    $scope.addAll = function() {
        _.forEach($scope.availableItems, function(item) {
            _.assign(item, {
                _enabled: true,
                _discountedPrice: null
            });

            $scope.selectedItems.push(item);
        });

        $scope.updateAvailableItems();
        $scope.addPromotionForm.$setDirty();
    };

    $scope.addAllReq = function() {
        _.forEach($scope.availableReqItems, function(item) {
            _.assign(item, {
                _required: true,
                _quantity: _.isNil(item._quantity) ? 1 : item._quantity
            });

            $scope.requiredItems.push(item);
        });

        $scope.updateAvailableReqItems();
        $scope.addPromotionForm.$setDirty();
    };

    $scope.removeAll = function() {
        _.forEach($scope.selectedItems, function(item) {
            _.assign(item, {
                _enabled: false,
                _discountedPrice: null
            });
        });

        $scope.selectedItems = [];
        $scope.updateAvailableItems();
        $scope.addPromotionForm.$setDirty();
    };

    $scope.removeAllReq = function() {
        _.forEach($scope.requiredItems, function(item) {
            _.assign(item, {
                _required: false,
                _quantity: null
            });
        });

        $scope.requiredItems = [];
        $scope.updateAvailableReqItems();
        $scope.addPromotionForm.$setDirty();
    };

    var filterAvailableItems = function(filter, keyField) {
        var filteredItems = _.filter(items, function(item) {
            if(item[keyField]) {
                return false;
            }

            return applyItemFilters(item, filter);
        });

        var filterText = _.trim(filter.text);

        if(filterText) {
            if(_.startsWith(filterText, '*')) {
                filterText = _.tail(filterText).join('');
                filteredItems = _.filter(filteredItems, function(item) { return _.endsWith(_.toLower(item.name), _.toLower(filterText)); });
            } else if(_.endsWith(filterText, '*')) {
                filterText = _.initial(filterText).join('');
                filteredItems = _.filter(filteredItems, function(item) { return _.startsWith(_.toLower(item.name), _.toLower(filterText)); });
            } else {
                filteredItems = $filter('filter')(filteredItems, filterText);
            }
        }

        return filteredItems;
    };

    $scope.updateAvailableItems = function() {
        $scope.availableItems = filterAvailableItems($scope.searchFilter, '_enabled');
    };

    $scope.updateAvailableReqItems = function() {
        $scope.availableReqItems = filterAvailableItems($scope.searchFilterReq, '_required');
    };

    $scope.switchTab = function(tabName) {
        $scope.currentSection = tabName;
        $timeout(function() {
            $scope.$broadcast('$md-resize');
        }, 1000);
    };

    $scope.addRequiredItem = function(item) {
        if(!item._required) {
            _.assign(item, {
                _required: true,
                _quantity: _.isNil(item._quantity) ? 1 : item._quantity
            });

            $scope.requiredItems.push(item);
            $scope.updateAvailableReqItems();
            $scope.addPromotionForm.$setDirty();
        }
    };

    $scope.removeRequiredItem = function(item) {
        item._required = false;
        _.pull($scope.requiredItems, item);
        $scope.updateAvailableReqItems();
        $scope.addPromotionForm.$setDirty();
    };

    $scope.addItem = function(item) {
        if(!item._enabled) {
            _.assign(item, {
                _enabled: true,
                _discountedPrice: null
            });

            $scope.selectedItems.push(item);
            $scope.updateAvailableItems();
            $scope.addPromotionForm.$setDirty();
        }
    };

    $scope.removeItem = function(item) {
        item._enabled = false;
        _.pull($scope.selectedItems, item);
        $scope.updateAvailableItems();
        $scope.addPromotionForm.$setDirty();
    };

    $scope.isEditingPromotion = function() {
        return !_.isUndefined($scope.promotion.id);
    };

    $scope.isCreatingPromotion = function() {
        return _.isUndefined($scope.promotion.id);
    };

    $scope.topbar_context.restorePromotion = function() {
        angular.copy(promotion, $scope.promotion);
        parsePromotionsData($scope.promotion);
    };

    $scope.topbar_context.isValidForm = function() {
        return $scope.addPromotionForm.$valid;
    };

    $scope.topbar_context.showConfirm = function() {
        return $scope.currentSection !== 'required-items';
    };

    $scope.topbar_context.confirmPromotion = function() {
        var error = checkPromotion($scope.promotion);

        if(error) {
            alertDialog.show($translate.instant(error));
        } else {
            if(!$scope.isSaving) {
                $scope.isSaving = true;

                var tmpPromotion = preparePromotion($scope.promotion);

                if ($scope.isEditingPromotion()) {
                    entityManager.promotions.putOneOnline(tmpPromotion).then(function(result) {
                        $state.go("app.promotions.showcase");
                    }, function() {
                        alertDialog.show($translate.instant('PROMOTIONS.DETAILS.PROMOTION_SAVE_ERROR'));
                    }).finally(function() {
                        $scope.isSaving = false;
                    });
                } else if ($scope.isCreatingPromotion()) {
                    entityManager.promotions.postOneOnline(tmpPromotion).then(function(result) {
                        $state.go("app.promotions.showcase");
                    }, function() {
                        alertDialog.show($translate.instant('PROMOTIONS.DETAILS.PROMOTION_SAVE_ERROR'));
                        $scope.isSaving = false;
                    });
                }
            }
        }
    };

    $scope.topbar_context.deletePromotion = function() {
        entityManager.promotions.deleteOneOnline(promotion.id).then(function() {
            $state.go("app.promotions.showcase");
        }, function() {
            alertDialog.show($translate.instant('PROMOTIONS.DETAILS.DELETE_PROMOTION_ERROR'));
        });
    };

    parsePromotionsData($scope.promotion);

    $scope.$on("barcodeManager:Item", function(event, eventData) {
        const foundData = eventData.itemData;
        const foundArray = foundData.data;

        if(foundArray.length) {
            _.forEach(foundArray, function(item) {
                var targetItem = $scope.itemsById[item.item_id];

                if(targetItem) {
                    switch($scope.currentSection) {
                        case 'items':
                            $scope.addItem(targetItem);
                        break;
                        case 'required-items':
                            $scope.addRequiredItem(targetItem);
                        break;
                    }
                }
            });
        } else {
            alertDialog.show($translate.instant('PROMOTIONS.DETAILS.BARCODE_NOT_FOUND'));
        }
    });
}]);

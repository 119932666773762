import { Injectable, inject, OnDestroy, computed, signal } from "@angular/core";
import {
    CurrentSection,
    ItemsEstended,
    PromotionsDefault,
    PromotionsFields,
    PromotionsForm,
    PromotionsSearchItemsForm,
    PromotionType,
    StatusItemsSource,
    TableItemsPromotion
} from '../components';
import { TranslateService } from "@ngx-translate/core";
import { PromotionsFe } from "src/app/shared/model/model";
import moment from 'moment-timezone';
import {
    Categories,
    ChainPromotions,
    Channels,
    Items,
    PromotionsItems,
    Suppliers
} from "tilby-models";
import { DevLogger } from "src/app/shared/dev-logger";
import { ConfigurationManagerService, EntityManagerService, UserActiveSessionManagerService } from "src/app/core";
import { errorsLogger, restManager } from "app/ajs-upgraded-providers";
import { BehaviorSubject, Subject } from "rxjs";
import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { CustomFormControl, CustomFormControlProps, CustomFormGroup, KeyValueIconImage } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { ShopChain } from "src/app/models";
import { KeyValue } from "@angular/common";
import { Validators } from "@angular/forms";
import { keyBy, REGEX_TIME_VALIDATOR } from "src/app/shared/utils";

type WeekDaysMap = {
    [key: string]: string; // Keys are strings, values are strings (day mappings)
};

@Injectable({
    providedIn: 'root'
})
export class UtilsPromotionsService implements OnDestroy {
    private readonly configurationManager = inject(ConfigurationManagerService);
    private readonly entityManager = inject(EntityManagerService);
    private readonly restManagerService = inject(restManager);
    private readonly userActiveSession = inject(UserActiveSessionManagerService);
    private readonly tilbyDatePipe = inject(TilbyDatePipe);
    private readonly translate = inject(TranslateService);
    private readonly onDestroy$ = new Subject<void>();
    private readonly errorsLoggerService = inject(errorsLogger);

    private statusSource = new BehaviorSubject<StatusItemsSource>({
        itemsSourceAdd: [],
        itemsSourceRemoved: [],
        requiredItemsSourceAdd: [],
        requiredItemsSourceRemoved: []
    });
    currentStatusSource$ = this.statusSource.asObservable();

    promotionForm!: CustomFormGroup<PromotionsForm>;
    formSearchItems!:CustomFormGroup<PromotionsSearchItemsForm>;
    itemsSourceAdd: Array<ItemsEstended> = [];
    itemsSourceRemoved: Array<ItemsEstended> = [];
    requiredItemsSourceAdd: Array<ItemsEstended> = [];
    requiredItemsSourceRemoved: Array<ItemsEstended> = [];
    $promotion_type = signal<PromotionType>('discount_perc');
    $perc_discount = signal<number>(0);

    itemsBySku: Record<string, ItemsEstended> = {};
    selectedItems: Array<ItemsEstended> = [];
    requiredItems: Array<ItemsEstended> = [];
    availableItems: Array<ItemsEstended> = [];
    availableReqItems: Array<ItemsEstended> = [];
    items: Array<Items> = [];
    itemsWithSku: Array<ItemsEstended> = [];
    brands: Array<string> = [];

    filteredSearch: Array<ItemsEstended> = [];
    filteredBrand: Array<ItemsEstended> = [];
    filteredSupplier: Array<ItemsEstended> = [];
    filteredCategory: Array<ItemsEstended> = [];
    filteredChannel: Array<ItemsEstended> = [];

    currentPromotion: PromotionsFields = new PromotionsDefault();
    weekDays: Array<{id: string, name: string}> = [];
    weekDaysChoices: Array<KeyValue<string, number>> = [];
    chainShops: Array<ShopChain> = [];
    priceLists = [
        {
            index: -1,
            fieldName: this.translate.instant('SETTINGS.SHIFTS.FORM.DEFAULT_PRICELIST'),
            value: this.translate.instant('SETTINGS.SHIFTS.FORM.DEFAULT_PRICELIST'),
            hidden: false
        },
        ...Array.from({ length: 10 }, (_, i) => ({
            index: (i + 1),
            fieldName: `price${i+1}`,
            value: this.configurationManager.getPreference(`price_list_${i+1}_name`) || `${this.translate.instant('SETTINGS.SHIFTS.PRICE_LIST_START')} ${i+1}`,
            hidden: !!this.configurationManager.getPreference(`price_list_${i+1}_hide`)
        }))
    ];
    private currentShopChain?: ShopChain;
    user = this.userActiveSession.getSession()!;

    //weekDaysMap: any = _.zipObject(moment.localeData('en').weekdaysMin(), _.map(moment.weekdaysShort(false), _.upperFirst));
    weekDaysMin = moment.localeData('en').weekdaysMin();
    weekdaysShort = moment.weekdaysShort(false).map(day => day.charAt(0).toUpperCase() + day.slice(1));

    weekDaysMap = this.weekDaysMin.reduce<WeekDaysMap>((acc, day, index) => {
        acc[day] = this.weekdaysShort[index];
        return acc;
    }, {});

    public currentSection = signal<CurrentSection>('general');
    public showConfirm = computed(() => this.currentSection() !== 'required-items');
    public showTabs = computed(() => this.currentSection() !== 'required-items');
    public researchSource = computed(() => this.currentSection() === 'required-items' ? this.availableReqItems : this.availableItems);
    filteredSource: Array<ItemsEstended> = [];

    constructor() {
        this.log('constructor');
        this.log('currentSection', this.currentSection());
        this.log('showConfirm', this.showConfirm());
        this.log('showTabs', this.showTabs());
        this.weekDays = moment.localeData('en').weekdaysMin().map((id, index) => ({
            id,
            name: moment.weekdays(false)[index].charAt(0).toUpperCase() + moment.weekdays(false)[index].slice(1)
        }));
        this.weekDaysChoices = this.weekDays.map((weekDay, i) => ({ key: weekDay.name, value: Number(weekDay.id) || i}));
        // this.log("weekDays", this.weekDays);
        // this.log("weekDaysChoices", this.weekDaysChoices);

        // this.log("weekDaysMin", this.weekDaysMin);
        // this.log("weekdaysShort", this.weekdaysShort);
        this.init();
    }

    async init() {
        this.log('init');
        this.items = await this.getRemoteItems();
        this.log('--- items', this.items);
        this.itemsWithSku = this.items.filter(item => item.sku);
        this.log('--- itemsWithSku', this.itemsWithSku);

        const uniqueBrands = new Set<string>();

        this.itemsWithSku.forEach((item: ItemsEstended) => {
            item._enabled = false;
            item._required = false;
            // Get brands
            if (item?.brand) {
                uniqueBrands.add(item.brand.trim());
            }
        });

        this.brands = Array.from(uniqueBrands);
        this.log('--- brands', this.brands);

        this.itemsBySku = keyBy(this.itemsWithSku, (item) => item.sku);

        this.chainShops = await this.getRemoteChainShops();
        this.log("REMOTE chainShops", this.chainShops);
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    async getRemoteItems() {
        try {
            const items = await this.entityManager.items.fetchCollectionOffline();
            return (items && Array.isArray(items)) ? items : [];
        } catch (e) {
            this.errorsLoggerService.err(e);
            return [];
        }
    }

    getItemsWithSku() {
        if(this.items && this.items.length > 0) {
            const itemsWithSku = this.items.filter(item => item.sku);
            this.log('getItemsWithSku -> itemsWithSku', itemsWithSku);
            return itemsWithSku && itemsWithSku.length > 0 ? itemsWithSku : [];
        } else {
            return [];
        }
    }

    async getRemoteChainShops() {
        try {
            const chain_shops = await this.restManagerService.getList("chain_shops", {pagination: false});
            return (chain_shops && Array.isArray(chain_shops)) ? chain_shops : [];
        } catch (e) {
            this.errorsLoggerService.err(e);
            return [];
        }
    }

    /** FORM DETAILS */
    async createFormDetails(promotion: PromotionsFields = new PromotionsDefault(), channels: Array<Channels>, hiddenFields?:Array<keyof PromotionsFields>) {
        this.log("createFormDetails params promotion", promotion);
        this.currentPromotion = promotion;
        this.log("createFormDetails params channels", channels);
        await this.parsePromotionsData(promotion);
        let chainShopsChoices : Array<KeyValue<string, string>> = [];
        if(this.chainShops && this.chainShops.length > 0) {
            this.currentShopChain = this.chainShops.find(shop => shop.db_name === this.user.shop.name);
            this.log('CURRENT_SHOP_CHAIN', this.currentShopChain, this.chainShops, this.user.shop);
            chainShopsChoices = this.chainShops.map(chainShop => ({
                key: chainShop.shop_name ? `${chainShop.shop_name} (${chainShop.db_name})` : `(${chainShop.db_name})`,
                value: chainShop.db_name
            })) || [];
        }

        const applyRuleTrue = this.translate.instant('PROMOTIONS.DETAILS.REQUIRE_ALL_ITEMS_TRUE');
        const applyRuleFalse = this.translate.instant('PROMOTIONS.DETAILS.REQUIRE_ALL_ITEMS_FALSE');
        const none = this.translate.instant('PROMOTIONS.DETAILS.NONE');
        const only_fidelity = this.translate.instant('PROMOTIONS.DETAILS.ONLY_FIDELITY');
        const no_fidelity = this.translate.instant('PROMOTIONS.DETAILS.NO_FIDELITY');
        const normal = this.translate.instant('PROMOTIONS.DETAILS.ORDER_TYPE_NORMAL');
        const take_away = this.translate.instant('PROMOTIONS.DETAILS.ORDER_TYPE_TAKE_AWAY');
        const delivery = this.translate.instant('PROMOTIONS.DETAILS.ORDER_TYPE_DELIVERY');
        const customerTypes = this.configurationManager.getPreference('customers.custom_types')?.split('\n') || [];
        const customerTypesChoices = customerTypes?.map(customerType => ({ key: customerType, value: customerType})) || [];
        const channelsChoices = channels?.map(channel => ({ key: channel.name, value: channel.id})) || [];

        const promotionType: PromotionType = promotion.perc_discount ? 'discount_perc' : 'discount_fix';
        const discount_perc = this.translate.instant('PROMOTIONS.DETAILS.TYPE_DISCOUNT_PERC');
        const discount_fix = this.translate.instant('PROMOTIONS.DETAILS.TYPE_DISCOUNT_FIX');

        const priceListsChoices: Array<KeyValue<string, number>> = this.priceLists.filter(priceList => !priceList.hidden).map(priceList => ({key: priceList.value, value: priceList.index}));

        this.promotionForm = new CustomFormGroup<PromotionsForm>({
            active: new CustomFormControl(promotion.active || false, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.ENABLED',
                inputType: 'slideToggle',
                class: 'tw-w-full tw-p-4 tw-mb-4'
            }),
            name: new CustomFormControl(promotion.name || '', { validators: Validators.required }, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.NAME',
                inputType: 'text',
                class: 'tw-w-full tw-pl-4 tw-pr-4 tw-mb-2'
            }),
            from_date: new CustomFormControl(promotion.from_date, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.FROM',
                inputType: 'date',
                class: 'tw-w-[45%] tw-pl-4 tw-mb-2'
            }),
            to_date: new CustomFormControl(promotion.to_date, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.TO',
                inputType: 'date',
                class: 'tw-w-[45%] tw-pr-4 tw-mb-2',
                inputConstraint: { minDate: promotion.from_date ? promotion.from_date : this.from_date?.value }
            }),
            start_time: new CustomFormControl(this.removeSeconds(promotion.start_time || '00:00') , { validators: [Validators.required, Validators.pattern(REGEX_TIME_VALIDATOR)] }, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.START_TIME',
                inputType: 'time',
                class: 'tw-w-[45%] tw-pl-4 tw-mb-2'
            }),
            end_time: new CustomFormControl(this.removeSeconds(promotion.end_time || '23:59'), { validators: [Validators.required, Validators.pattern(REGEX_TIME_VALIDATOR)] }, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.END_TIME',
                inputType: 'time',
                class: 'tw-w-[45%] tw-pr-4 tw-mb-2'
            }),
            weekdays_period: new CustomFormControl(this.getNumbersOfWeekdays(promotion.weekdays_period || []), {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.WEEKDAYS_PERIOD',
                inputType: 'selectMultiple',
                inputChoices: this.weekDaysChoices,
                class: 'tw-w-full tw-pl-4 tw-pr-4 tw-mb-2'
            }),
            required_items: new CustomFormControl(promotion.required_items?.length || 0, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.REQUIRED_ITEMS',
                inputType: 'text',
                id: 'required_items_controller',
                class: 'tw-w-[45%] tw-pl-4 tw-mb-2 tw-cursor-pointer',
                matElementClass: 'tw-cursor-pointer',
            }),
            require_all_items: new CustomFormControl(promotion.require_all_items ? 'true' : 'false', {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.REQUIRE_ALL_ITEMS',
                inputType: 'select',
                inputChoices: [
                    { key: applyRuleTrue, value: 'true' },
                    { key: applyRuleFalse, value: 'false' }
                ],
                class: 'tw-w-[45%] tw-pr-4 tw-mb-2'
            }),
            fidelity_rule: new CustomFormControl(promotion.fidelity_rule || 'none', {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.FIDELITY_RULE',
                inputType: 'select',
                inputChoices: [
                    { key: none, value: 'none' },
                    { key: only_fidelity, value: 'only_fidelity' },
                    { key: no_fidelity, value: 'no_fidelity' }
                ],
                class: 'tw-w-[45%] tw-pl-4 tw-mb-2'
            }),
            customer_type: new CustomFormControl(promotion.customer_type || 'null', {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.CUSTOMER_TYPE',
                inputType: 'select',
                inputChoices: [
                    { key: none, value: 'null' },
                    ...customerTypesChoices
                ],
                class: 'tw-w-[45%] tw-pr-4 tw-mb-2'
            }),
            channels: new CustomFormControl(promotion.channels || [], {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.CHANNELS',
                inputType: 'selectMultiple',
                inputChoices: channelsChoices,
                class: 'tw-w-[45%] tw-pl-4 tw-mb-2'
            }),
            shops: new CustomFormControl(promotion.shops || [], {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.SHOPS',
                inputType: 'selectMultiple',
                inputChoices: chainShopsChoices,
                class: 'tw-w-[45%] tw-pr-4 tw-mb-2'
            }),
            order_types: new CustomFormControl(promotion.order_types || [], {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.ORDER_TYPE',
                inputType: 'selectMultiple',
                inputChoices: [
                    { key: normal, value: 'normal' },
                    { key: take_away, value: 'take_away' },
                    { key: delivery, value: 'delivery' }
                ],
                class: 'tw-w-full tw-pl-4 tw-pr-4 tw-mb-2'
            }),
            promotion_type: new CustomFormControl(promotionType || 'discount_perc', {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.PROMOTION_TYPE',
                inputType: 'select',
                inputChoices: [
                    { key: discount_perc, value: 'discount_perc' },
                    { key: discount_fix, value: 'discount_fix' }
                ],
                class: 'tw-w-full tw-pl-4 tw-pr-4 tw-mb-2'
            }),
            perc_discount: new CustomFormControl(promotion.perc_discount || 0, {validators: [Validators.min(0), Validators.max(100)]}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.PERC_DISCOUNT',
                inputType: 'number',
                inputTypeInitial: promotionType === 'discount_fix' ? 'hidden' : 'number',
                inputConstraint: { min: 0, max: 100 },
                class: 'tw-w-full tw-pl-4 tw-pr-4 tw-mb-2'
            }),
            base_pricelist: new CustomFormControl(promotion.base_pricelist || 1, {validators: Validators.required}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.BASE_PRICELIST',
                inputType: 'select',
                inputTypeInitial: promotionType === 'discount_perc' ? 'hidden' : 'select',
                inputChoices: priceListsChoices,
                class: 'tw-w-[45%] tw-pl-4 tw-pr-4 tw-mb-2'
            }),
            quantity_threshold: new CustomFormControl(promotion.quantity_threshold || 1, {validators: [Validators.required,Validators.min(1)]}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.QUANTITY_THRESHOLD',
                inputType: 'number',
                inputTypeInitial: promotionType === 'discount_perc' ? 'hidden' : 'number',
                inputConstraint: { min: 1 },
                class: 'tw-w-[45%] tw-pl-4 tw-pr-4 tw-mb-2'
            }),
            apply_below_threshold: new CustomFormControl(promotion.apply_below_threshold, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.APPLY_BELOW_THRESHOLD',
                inputType: 'checkbox',
                inputTypeInitial: promotionType === 'discount_perc' ? 'hidden' : 'checkbox',
                class: 'tw-w-full tw-pl-4 tw-pr-4 tw-mb-1'
            }),
            multiples_only: new CustomFormControl(promotion.multiples_only, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.MULTIPLES_ONLY',
                inputType: 'checkbox',
                inputTypeInitial: promotionType === 'discount_perc' ? 'hidden' : 'checkbox',
                class: 'tw-w-full tw-pl-4 tw-pr-4 tw-mb-1'
            }),
            force_pricelist_apply: new CustomFormControl(promotion.force_pricelist_apply, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.FORCE_PRICELIST_APPLY',
                inputType: 'checkbox',
                inputTypeInitial: promotionType === 'discount_perc' ? 'hidden' : 'checkbox',
                class: 'tw-w-full tw-pl-4 tw-pr-4 tw-mb-1'
            }),
            exclude_other_promotions: new CustomFormControl(promotion.exclude_other_promotions, {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.EXCLUDE_OTHER_PROMOTIONS',
                inputType: 'checkbox',
                class: 'tw-w-full tw-pl-4 tw-pr-4 tw-mb-1'
            })
        });

        return this.promotionForm;
    }

    get from_date() {
        return this.promotionForm?.controls.from_date;
    }
    get to_date() {
        return this.promotionForm?.controls.to_date;
    }

    get perc_discount() {
        return this.promotionForm?.controls.perc_discount;
    }

    getNumbersOfWeekdays(weekdays: Array<ChainPromotions.WeekdaysPeriodEnum>): Array<number> {
        return (weekdays && weekdays.length > 0)
            ? weekdays.map((weekday: ChainPromotions.WeekdaysPeriodEnum) => {
                switch (weekday) {
                    case 'Su': return 0;
                    case 'Mo': return 1;
                    case 'Tu': return 2;
                    case 'We': return 3;
                    case 'Th': return 4;
                    case 'Fr': return 5;
                    case 'Sa': return 6;
                }
            })
            : [];
    }

    getChannels(arrChannels: Array<string> | undefined): Array<string> {
        return (arrChannels && arrChannels.length > 0) ? arrChannels.map((channel: string) => channel) : [];
    }

    getChainShops(arrChainShops: Array<string> | undefined): Array<string> {
        return (arrChainShops && arrChainShops.length > 0) ? arrChainShops.map((chainShop: string) => chainShop) : [];
    }

    /** FORM SEARCH ITEMS */
    createFormSearchItems(categories: Array<Categories>, suppliers: Array<Suppliers>, channels: Array<Channels>) {
        const brandChoices: KeyValueIconImage<string>[] = this.brands ? this.brands.map((brand) => ({ key: brand, value: brand })) : [];
        const categoriesChoices: KeyValueIconImage<string>[] = categories.map((category: Categories) => ({
            key: category.name,
            value: category.name
        }));
        const suppliersChoices: KeyValueIconImage<string>[] = suppliers.map((supplier: Suppliers) => ({
            key: supplier.name,
            value: supplier.name
        }));
        const channelsChoices: KeyValueIconImage<string>[] = channels.map((channel: Channels) => ({
            key: channel?.id,
            value: channel.name
        }))
        this.formSearchItems = new CustomFormGroup<PromotionsSearchItemsForm>({
            search: new CustomFormControl('', {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.SEARCH',
                inputType: 'text',
                class: 'tw-w-full tw-pl-4 tw-pr-4 tw-mt-4'
            }),
            supplier: new CustomFormControl('', {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.SUPPLIER',
                inputType: 'autocomplete',
                inputChoices: suppliersChoices,
                class: 'tw-w-[45%] tw-pl-4'
            }),
            brand: new CustomFormControl('', {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.BRAND',
                inputType: 'autocomplete',
                inputChoices: brandChoices,
                class: 'tw-w-[45%] tw-pr-4'
            }),
            category: new CustomFormControl('', {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.CATEGORY',
                inputType: 'autocomplete',
                inputChoices: categoriesChoices,
                class: 'tw-w-[45%] tw-pl-4'
            }),
            channel: new CustomFormControl('', {}, {
                ...new CustomFormControlProps(),
                hint: () => ``,
                label: 'PROMOTIONS.DETAILS.CHANNEL',
                inputType: 'autocomplete',
                inputChoices: channelsChoices,
                class: 'tw-w-[45%] tw-pr-4'
            })
        });

        return this.formSearchItems;
    }

    filterCategory(categoryId: number) {
        return this.filteredCategory = categoryId > 0
            ? this.researchSource().filter((item: ItemsEstended) => item.category_id === categoryId)
            : [];
    }

    filterChannels(channelName: string) {
        const reduceItems: Array<ItemsEstended> = [];
        if (channelName) {
            const lowerCaseChannelName = channelName.toLowerCase();
            this.researchSource().forEach((item: ItemsEstended) => {
                if(item.channels?.some(c => c.channel_id.toLowerCase() === lowerCaseChannelName)) {
                    reduceItems.push(item);
                }
            });
        }
        return this.filteredChannel = reduceItems;
    }

    filterSearch(textSearch: string): Array<ItemsEstended> {
        const searchTerm = textSearch?.toLowerCase() || '';

        return this.filteredSearch = searchTerm
            ? this.researchSource().filter((item: ItemsEstended) =>
                item.name.toLowerCase().includes(searchTerm)
            )
            : [];
    }

    // filterSearch(textSearch: string): Array<ItemsEstended> {
    //     let searchTerm = textSearch.trim().toLowerCase() || '';

    //     if(searchTerm) {
    //         searchTerm = searchTerm.replace(/\*$/, ''); // Remove trailing '*'
    //         searchTerm = searchTerm.replace(/^\*/, ''); // Remove leading '*'

    //         const isStartingWithAsterisk = searchTerm.startsWith('*');
    //         const isEndingWithAsterisk = searchTerm.endsWith('*');

    //         this.filteredSearch = this.researchSource().filter((item: ItemsEstended) => {
    //                 const itemName = item.name?.toLowerCase() || '';
    //                 const normalizedSearchTerm = searchTerm.toLowerCase();

    //                 if (isStartingWithAsterisk) {
    //                     return itemName.endsWith(normalizedSearchTerm);
    //                 } else if (isEndingWithAsterisk) {
    //                     return itemName.startsWith(normalizedSearchTerm);
    //                 } else {
    //                     return itemName.includes(normalizedSearchTerm); // Default behavior
    //                 }
    //             });

    //     }

    //     return this.filteredSearch;
    // }

    filterSuppliers(supplierId: number) {
        return this.filteredSupplier = supplierId > 0 ? this.researchSource().filter((item: ItemsEstended) => item.default_supplier_id === supplierId) : [];
    }

    filterBrand(brand: string) {
        this.log('researchSource', this.researchSource());
        return this.filteredBrand = brand ? this.researchSource().filter((item: ItemsEstended) => item.brand === brand) : [];
    }

    applyStatusSource() {
        const statusSource =  {
            itemsSourceAdd: this.itemsSourceAdd,
            itemsSourceRemoved: this.itemsSourceRemoved,
            requiredItemsSourceAdd: this.requiredItemsSourceAdd,
            requiredItemsSourceRemoved: this.requiredItemsSourceRemoved
        };
        this.statusSource.next(statusSource);
    }

    public async parsePromotionsData(promotion: PromotionsFields) {
        this.selectedItems = [];
        this.requiredItems = [];

        promotion.items?.forEach((item: PromotionsItems) => {
            const refItem = this.itemsBySku[item.item_sku];
            if(refItem) {
                refItem._enabled = true;
                refItem._prevId = item.id;
                refItem._discountedPrice = item.discounted_price;
                // Object.assign(this.itemsBySku[item.item_sku], {
                //     _enabled: true,
                //     _prevId: item.id,
                //     _discountedPrice: item.discounted_price
                // });
                this.selectedItems.push(refItem);
            }
        });

        this.log('this.selectedItems', this.selectedItems);
        this.itemsSourceRemoved = this.selectedItems;

        promotion.required_items?.forEach((item: PromotionsItems) => {
            const refItem = this.itemsBySku[item.item_sku];
            if(refItem) {
                refItem._required = true;
                refItem._prevReqId = item.id;
                refItem._quantity = item.quantity;

                this.requiredItems.push(refItem);
            }
        });

        this.log('this.requiredItems', this.requiredItems);
        this.requiredItemsSourceRemoved = this.requiredItems;

        this.updateAvailableItems();
        this.updateAvailableReqItems();
    }

    updateAvailableItems() {
        this.log('--- updateAvailableItems');
        this.availableItems = [];
        this.itemsWithSku.forEach((item: ItemsEstended) => {
            if(!item._enabled) {
                this.availableItems.push(item);
            }
        });
        //this.availableItems = this.itemsWithSku.filter((item: ItemsEstended) => !item._enabled);
        this.itemsSourceAdd = this.availableItems;
        this.log('this.itemsWithSku', this.itemsWithSku.length);
        this.log('foundAvailableItems', this.availableItems.length);
    }

    updateAvailableReqItems() {
        this.log('--- updateAvailableReqItems');
        this.availableReqItems = [];
        this.itemsWithSku.forEach((item: ItemsEstended) => {
            if(!item._required) {
                this.availableReqItems.push(item);
            }
        });
        //this.availableReqItems = this.itemsWithSku.filter((item: ItemsEstended) => !item._required);
        this.requiredItemsSourceAdd = this.availableReqItems;
        this.log('this.itemsWithSku', this.itemsWithSku.length);
        this.log('foundAvailableReqItems', this.availableReqItems.length);
    }

    removeAll() {
        this.log('--- removeAll');
        switch(this.currentSection()) {
            case 'required-items':
                this.removeAllRequiredItems();
            case 'items':
                this.removeAllItems();
        }

        this.applyStatusSource();
    }

    removeAllItems() {
        this.log('--- removeAllItems');
        this.selectedItems.forEach((item: ItemsEstended) => {
            item._enabled = false;
            item._discountedPrice = null;
        });

        this.selectedItems = [];
        this.itemsSourceRemoved = this.selectedItems;
        this.updateAvailableItems();
    }

    removeAllRequiredItems() {
        this.log('--- removeAllRequiredItems');
        this.requiredItems.forEach((item: ItemsEstended) => {
            item._required = false;
            item._quantity = null;
        });

        this.requiredItems = [];
        this.requiredItemsSourceRemoved = this.requiredItems;
        this.updateAvailableReqItems();
    }

    addAll() {
        this.log('--- addAll');
        switch(this.currentSection()) {
            case 'required-items':
                this.addAllRequiredItems();
            case 'items':
                this.addAllItems();
        }

        this.applyStatusSource();
    }

    addAllItems() {
        this.log('--- addAllItems');
        this.log('filteredSource', this.filteredSource);

        this.filteredSource.forEach((item: ItemsEstended) => {
            item._enabled = true;
            item._discountedPrice = null;

            this.selectedItems.push(item);
        });

        this.itemsSourceRemoved = this.selectedItems;
        this.updateAvailableItems();
    }

    addAllRequiredItems() {
        this.log('--- addAllRequiredItems');
        this.log('filteredSource', this.filteredSource);

        this.filteredSource.forEach((item: ItemsEstended) => {
            item._required = true;
            item._quantity = item._quantity == null ? 1 : item._quantity;

            this.requiredItems.push(item);
        });

        this.itemsSourceRemoved = this.requiredItems;
        this.updateAvailableReqItems();
    }

    addRow(row: TableItemsPromotion) {
        this.log('--- addRow', row);
        //const foundItem = this.itemsWithSku.find((item: ItemsEstended) => item.sku === row.sku);
        const foundItem = this.itemsBySku[row.sku];
        this.log('foundItem', foundItem);

        if(foundItem) {
            switch(this.currentSection()) {
                case 'required-items':
                    this.addRequiredItem(foundItem);
                case 'items':
                    this.addItem(foundItem);
            }
        }

        this.applyStatusSource();
    }

    addRequiredItem(item: ItemsEstended) {
        this.log('--- addRequiredItem', item);
        if(!item._required) {
            item._required = true;
            item._quantity = item._quantity == null ? 1 : item._quantity;

            this.requiredItems.push(item);
            this.requiredItemsSourceRemoved = this.requiredItems;
            this.updateAvailableReqItems();
        }
    }

    addItem(item: ItemsEstended) {
        this.log('addItem', item);
        if(!item._enabled) {
            item._enabled = true;
            item._discountedPrice = null;

            this.selectedItems.push(item);
            this.itemsSourceRemoved = this.selectedItems;
            this.updateAvailableItems();
        }
    }

    removeRow(row: TableItemsPromotion) {
        this.log('--- removeRow', row);
        //const foundItem = this.itemsWithSku.find((item: ItemsEstended) => item.sku === row.sku);
        const foundItem = this.itemsBySku[row.sku];
        this.log('foundItem', foundItem);
        if(foundItem) {
            switch(this.currentSection()) {
                case 'required-items':
                    this.removeRequiredItem(foundItem);
                case 'items':
                    this.removeItem(foundItem);
            }
        }

        this.applyStatusSource();
    }

    removeRequiredItem(item: ItemsEstended) {
        this.log('removeRequiredItem', item);
        item._required = false;
        this.requiredItems = this.requiredItems.filter(requiredItem => requiredItem.sku !== item.sku);
        this.requiredItemsSourceRemoved = this.requiredItems;
        this.updateAvailableReqItems();
    }

    removeItem(item: ItemsEstended) {
        this.log('removeItem', item);
        item._enabled = false;
        this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem.sku !== item.sku);
        this.itemsSourceRemoved = this.selectedItems;
        this.updateAvailableItems();
    }

    updateDiscountPrice(sku: string, discountPrice: number) {
        this.log('updateDiscountPrice', sku, discountPrice);
        const foundItem = this.selectedItems.find((item: ItemsEstended) => item.sku === sku);
        this.log('foundItem', foundItem);
        if(foundItem) {
            foundItem._discountedPrice = discountPrice;
        }
    }

    updateQuantity(sku: string, quantity: number) {
        this.log('updateQuantity', sku, quantity);
        const foundItem = this.requiredItems.find((item: ItemsEstended) => item.sku === sku);
        this.log('foundItem', foundItem);
        if(foundItem) {
            foundItem._quantity = quantity;
        }
    }

    // utils start
    onePromotionFormatter(promotion: PromotionsFields): PromotionsFe {
        // this.log('promotionsFormatter', promotion);
        // this.log('prova data moment', moment(promotion.from_date, 'YYYY-MM-DD').format('l'));
        // this.log('prova data tilby pipe', this.tilbyDatePipe.transform(promotion.from_date, 'dd/MM/YY'));
        return {
            id: promotion.id || -1,
            status: promotion.active ? this.translate.instant('PROMOTIONS.SHOWCASE.ACTIVE') : this.translate.instant('PROMOTIONS.SHOWCASE.INACTIVE'),
            name: promotion.name,
            items: promotion.items?.length || 0,
            startData: promotion.from_date ? this.tilbyDatePipe.transform(promotion.from_date, 'd/MM/yy') : '-',
            endData: promotion.to_date ? this.tilbyDatePipe.transform(promotion.to_date, 'd/MM/yy') : '-',
            startTime: promotion.start_time ? this.removeSeconds(promotion.start_time) : '-',
            endTime: promotion.end_time ? this.removeSeconds(promotion.end_time) : '-',
            weekDays: promotion.weekdays_period ? this.getWeekDays(promotion.weekdays_period) : '-',
            fidelity_rule: promotion.fidelity_rule ? this.getFidelityRule(promotion.fidelity_rule) : '-',
            customer_type: promotion.customer_type ? promotion.customer_type : '',
        };
    }

    promotionsFormatter(promotions: Array<PromotionsFields>): Array<PromotionsFe> {
        return promotions.map(promotion => this.onePromotionFormatter(promotion));
    }

    getWeekDays(weekdays_period: Array<ChainPromotions.WeekdaysPeriodEnum>) {
        const len = weekdays_period?.length || 0;
        return len === 0 || len === 7
            ? this.translate.instant('PROMOTIONS.SHOWCASE.EVERY_DAY')
            : weekdays_period.map(day => this.weekDaysMap[day]).join(', ');
    }

    getFidelityRule(rule: ChainPromotions.FidelityRuleEnum) {
        switch (rule) {
            case 'none': return this.translate.instant('PROMOTIONS.SHOWCASE.EVERY_CUSTOMER');
            case 'only_fidelity': return this.translate.instant('PROMOTIONS.SHOWCASE.ONLY_FIDELITY');
            case 'no_fidelity': return this.translate.instant('PROMOTIONS.SHOWCASE.NO_FIDELITY');
        }
    }

    private removeSeconds(time: string): string {
        const arr = time.split(':');
        return `${arr[0]}:${arr[1]}`;
    }
    // utils end

    private log(...args: any[]) {
        DevLogger.log('UTILS_PROMOTIONS_SERVICE', ...args);
    }
}

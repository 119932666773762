import angular from 'angular';
import _ from 'lodash';

angular.module('kiosk').factory('showAllergensDialog', ["$mdDialog", "$translate", "$rootScope", function($mdDialog, $translate, $rootScope) {

    showAllergensDialogController.$inject = ["$scope", "params", "$mdDialog"];
    function showAllergensDialogController($scope, params, $mdDialog) {
        if(!_.isObject(params)) {
            params = {};
        }

        _.assign($scope, {
            title: $translate.instant('KIOSK.SHOW_ALLERGENS_DIALOG.TITLE'),
            name: $translate.instant('KIOSK.SHOW_ALLERGENS_DIALOG.PLACEHOLDER'),
            allergens: []
        });

        if(params.name) {
            $scope.name = params.name;
        }
        if(params.allergens) {
            $scope.allergens = _.cloneDeep(params.allergens);
        }

        $scope.enableTimeouts = function() {
            $rootScope.enableTimeouts();
        };

        $scope.hide = function() {
            $mdDialog.hide();
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.$on('kiosk:standby', function(event) {
            $mdDialog.cancel();
        });
    }

    var showAllergens = {
        show: function(params) {
            return $mdDialog.show({
                controller: showAllergensDialogController,
                template: require('./show-allergens.html'),
                locals: {
                    params: params
                },
            });
        }
    };

    return showAllergens;
}]);

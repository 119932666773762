import {
    Component,
    inject,
    Injectable
} from "@angular/core";
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogModule,
    MatDialogRef,
} from "@angular/material/dialog";
import { lastValueFrom } from "rxjs";
import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogActionButtonsComponent,
    TilbyDialogContentComponent,
    TilbyDialogProgressBarComponent,
    TilbyDialogTabsComponent,
    TilbyDialogToolbarComponent,
} from "@tilby/tilby-ui-lib/components/tilby-dialog";
import {
    CustomFormControl,
    CustomFormControlProps,
    CustomFormGroup,
    tilbyErrors,
    TilbyMagicFormComponent
} from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import {
    AbstractControl,
    Validators
} from "@angular/forms";
import { restManager } from "../../../../../app/ajs-upgraded-providers";
import { BarcodeManagerService, EnvironmentInfoService, UserActiveSessionManagerService } from "src/app/core";
import {
    TranslateModule,
    TranslateService
} from "@ngx-translate/core";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { autobind } from "src/app/models/decorators.model";
import {
    ErrorStateMatcher,
    ShowOnDirtyErrorStateMatcher
} from "@angular/material/core";

export type GiftcardDialogData = { };

@Component({
    selector: "app-tilby-dialog",
    standalone: true,
    imports: [
        TilbyDialogTabsComponent,
        TilbyDialogProgressBarComponent,
        TilbyDialogContentComponent,
        TilbyDialogActionButtonsComponent,
        TilbyDialogToolbarComponent,
        MatDialogModule,
        TilbyMagicFormComponent,
        TranslateModule,
        TilbyCurrencyPipe,
        TilbyDatePipe
    ],
    providers:[{provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher}],
    templateUrl: "./giftcard-dialog.component.html",
    styleUrls: ["./giftcard-dialog.component.scss"],
})
export class GiftcardDialogComponent {
    protected readonly data: GiftcardDialogData = inject(MAT_DIALOG_DATA);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly restManagerService = inject(restManager);
    private readonly userActiveSessionManagerService = inject(UserActiveSessionManagerService);
    private readonly translateService = inject(TranslateService);
    private readonly tilbyCurrencyPipe = inject(TilbyCurrencyPipe);
    private readonly tilbyDatePipe = inject(TilbyDatePipe);
    private readonly environmentInfoService = inject(EnvironmentInfoService);
    private readonly barcodeManagerService = inject(BarcodeManagerService);

    protected validationStatusMessage = '';
    protected form = new CustomFormGroup({
        giftcardCode: new CustomFormControl(
            '',
            {asyncValidators: [this.giftcardCodeValidator], validators: [Validators.required]},
            {
                ...new CustomFormControlProps(),
                label: "GIFT_CARD.PAYMENT_DIALOG.LABEL",
                ...(this.environmentInfoService.isCameraBarcodeSupported() && { customActions: { suffix: { icon: "photo_camera", callback: async () => await this.openCameraBarcode() }}}),
                errors: [
                    ...tilbyErrors,
                    {key: 'giftcardNotFound', value: 'GIFT_CARD.PAYMENT_DIALOG.NOT_FOUND'},
                    {key: 'giftcardDisabled', value: 'GIFT_CARD.PAYMENT_DIALOG.GIFTCARD_DISABLED'},
                    {key: 'giftcardOriginEnvironmentOnly', value: 'GIFT_CARD.PAYMENT_DIALOG.ORIGIN_ENVIRONMENT_ONLY'}
                ]
            })
    });

    private async openCameraBarcode() {
        let giftcardCode = await this.barcodeManagerService.openCameraBarcode();
        this.form?.patchValue({giftcardCode: giftcardCode});
    }

    @autobind
    async giftcardCodeValidator(control: AbstractControl){
        return control.value && (await this.validationFunction(control.value));        //control.value.indexOf(' ') >= 0 ? {cannotContainSpace: true} as ValidationErrors : null;
    }

    async validationFunction(value: string) {
        let results = [];

        try {
            results = await this.restManagerService.getList('giftcards', {code: value});
        } catch (err) {
            //Nothing to do
        }


        if (!results.length) {
            return {giftcardNotFound: true};
        }

        const giftCard = results[0];

        if (!giftCard.active) {
            return {giftcardDisabled: true};
        }

        if (giftCard.origin_environment_only && this.userActiveSessionManagerService.getSession()?.shop.name !== giftCard.origin_environment) {
            return {giftcardOriginEnvironmentOnly: true};
        }

        const messageParts = [
            this.translateService.instant("GIFT_CARD.PAYMENT_DIALOG.AVAILABLE_CREDIT", {value: this.tilbyCurrencyPipe.transform(giftCard.last_value ?? giftCard.initial_value)})
        ];

        if (giftCard.valid_until) {
            messageParts.push(this.translateService.instant("GIFT_CARD.PAYMENT_DIALOG.EXPIRE_DATE", {date: this.tilbyDatePipe.transform(giftCard.valid_until, 'short')}));
        }

        this.validationStatusMessage = messageParts.join(' - ');
        return null;
    }

    protected confirm() {
        if(!this.form.valid) return;
        this.matDialogRef.close(this.form.value.giftcardCode);
    }
}

@Injectable({
    providedIn: "root",
})
export class GiftcardDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public openDialog(config?: NonNullableConfigData<GiftcardDialogData>) {
        const data: GiftcardDialogData = config?.data || {};
        const configEdited: NonNullableConfigData<GiftcardDialogData> = {
            ...config,
            ...this.switchMobileDesktopDimensions(),
            disableClose: true,
            data,
        };
        return lastValueFrom(
            this.dialogRef.open(GiftcardDialogComponent, configEdited).afterClosed()
        );
    }
}

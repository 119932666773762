import { CommonModule } from '@angular/common';
import { Component, Input, signal, SimpleChanges } from '@angular/core';
import { RoomsTables } from 'tilby-models';
import { matchAllWords } from "@tilby/tilby-ui-lib/utilities";
import { Color, ICON_TO_SVG_ELEMENTS, ICON_TO_SVG_TABLES, STATIC_ELEMENT_PREFIX } from './tilby-tables.model';
import { TilbyCurrencyPipe } from '@tilby/tilby-ui-lib/pipes/tilby-currency';
import { MatIconModule } from '@angular/material/icon';

type ColorState = {
    name: Color,
    HEX: '#009688' | '#B71C1C' | '#E88737' | '#FFEA00' | '#E0E0E0' | '#00BCD4',
    isSelected: boolean,
    showCovers: boolean,
    showCoversIcon: boolean,
    showMinutes: boolean,
    showReserved: boolean,
    showExitsAndFinalAmount: boolean,
    class: string,
    billLock?: boolean
}

const tableColorStates: Record<Color, ColorState> = {
    green: {
        name: 'green',
        HEX: '#009688',
        showCovers: true,
        showCoversIcon: false,
        showMinutes: false,
        showReserved: true,
        showExitsAndFinalAmount: false,
        isSelected: false,
        class: 'tw-fill-white'
    },
    red: {
        name: 'red',
        HEX: '#B71C1C',
        showCovers: true,
        showCoversIcon: true,
        showMinutes: true,
        showReserved: true,
        showExitsAndFinalAmount: true,
        isSelected: false,
        class: 'tw-fill-white'
    },
    orange: {
        name: 'orange',
        HEX: '#E88737',
        showCovers: true,
        showCoversIcon: false,
        showMinutes: true,
        showReserved: true,
        showExitsAndFinalAmount: true,
        isSelected: false,
        class: 'tw-fill-white'
    },
    yellow: {
        name: 'yellow',
        HEX: '#FFEA00',
        showCovers: true,
        showCoversIcon: true,
        showMinutes: true,
        showReserved: true,
        showExitsAndFinalAmount: true,
        isSelected: false,
        billLock: true,
        class: 'tw-fill-black'
    },
    grey: {
        name: 'grey',
        HEX: '#E0E0E0',
        showCovers: false,
        showCoversIcon: false,
        showMinutes: false,
        showReserved: false,
        showExitsAndFinalAmount: false,
        isSelected: false,
        class: 'tw-fill-white'
    },
    blue: {
        name: 'blue',
        HEX: '#00BCD4',
        showCovers: false,
        showCoversIcon: false,
        showMinutes: false,
        showReserved: false,
        showExitsAndFinalAmount: false,
        isSelected: true,
        class: 'tw-fill-white'
    }
};

@Component({
    selector: 'tilby-table-element',
    standalone: true,
    imports: [CommonModule, TilbyCurrencyPipe, MatIconModule],
    templateUrl: './tilby-table-element.component.html',
    styleUrls: ['./tilby-table-element.component.scss']
})
export class TilbyTableElementComponent {
    protected readonly STATIC_ELEMENT_PREFIX = STATIC_ELEMENT_PREFIX;

    protected tableName = '';
    protected width = 0;
    protected height = 0;
    protected minSize = 0;

    @Input() scale = 1;
    @Input() covers = 0;
    @Input() tableMultipleBusyCovers = 0;
    @Input() isTableMultipleOrSingleWithMultipleSales = false;
    @Input() exitsSent = 0;
    @Input() exitsTotalInUse = 5;
    @Input() finalAmount?: number;
    @Input() tableBookingWarning = false;
    @Input() tableHanging = false;
    @Input() disableTableInfo = false;
    @Input() saleNameToShow?: string;
    @Input() disabledToggleShowAmount = false;
    @Input() minutes = "0'";

    @Input({ required: true }) iconsPath!: string;
    @Input({ required: true }) shapesPath!: string;
    @Input({ required: true }) furnituresPath!: string;

    protected shape = 'circle';

    protected colorState = tableColorStates['green'];
    protected _color = signal<Color>('green');
    protected svg = ICON_TO_SVG_TABLES.circle4;
    protected isTable = true;

    protected toggleShowAmount = true;

    @Input() billLockForEditMode = false;
    @Input() table?: RoomsTables;

    @Input() set color(color: Color) {
        const nextColorState = tableColorStates[color];

        if (nextColorState) {
            this.colorState = nextColorState;
            this._color.set(color);
        }
    }

    get color() {
        return this._color();
    }

    private interval = setInterval(() => this.toggleShowAmount = (this.finalAmount && this.finalAmount > 0) ? !this.toggleShowAmount : true, 2000);

    ngOnChanges(changes: SimpleChanges) {
        const table = this.table;
        
        if(!table || (!changes['table']?.currentValue && !changes['scale']?.currentValue)) {
            return;
        }

        this.tableName = table.name;
        this.width = table.width * this.scale;
        this.height = table.height * this.scale;
        this.minSize = (this.width < this.height ? this.width : this.height);
        this.shape = matchAllWords(table.shape)?.[0] || 'square';
        this.isTable = !this.shape.startsWith(STATIC_ELEMENT_PREFIX);

        // @ts-ignore
        this.svg = this.isTable ? ICON_TO_SVG_TABLES[table.shape] : ICON_TO_SVG_ELEMENTS[table.shape];
    }

    ngOnDestroy() {
        clearInterval(this.interval);
    }
}

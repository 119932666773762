import angular from 'angular';
import { ActiveSaleService } from 'src/app/features';

export class MigrationHelper {
    constructor(
        private readonly injector: angular.auto.IInjectorService
    ) {
    }

    getActiveSale(): ActiveSaleService {
        return this.injector.get('ActiveSaleNew');
    }
}

MigrationHelper.$inject = ['$injector'];

angular.module('application').service('migrationHelper', MigrationHelper);
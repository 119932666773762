import angular from 'angular';
import _ from 'lodash';

angular.module('items', ['application']);

angular.module('items').config(["$stateProvider", "$translatePartialLoaderProvider", function($stateProvider, $translatePartialLoaderProvider) {
    $translatePartialLoaderProvider.addPart('items');

    $stateProvider.state('app.items', {
        url: '/items',
        redirectTo: 'app.items.showcase'
    });

    $stateProvider.state('app.items.showcase', {
        url: '/showcase',
        views: {
            "appContent@app": {
                template: require('./partial/items-showcase/items-showcase.html'),
                controller: 'ItemsShowcaseCtrl'
            }
        },
        params: {
            itemsQuery: null,
            scrollIndex: null
        },
        resolve: {
            categories: ["entityManager", function(entityManager) {
                return entityManager.categories.fetchCollectionOnline();
            }],
            channels: ["entityManager", function(entityManager) {
                return entityManager.channels.fetchCollectionOnline();
            }],
            departments: ["entityManager", function(entityManager) {
                return entityManager.departments.fetchCollectionOnline();
            }],
            suppliers: ["entityManager", function(entityManager) {
                return entityManager.suppliers.fetchCollectionOffline();
            }],
            itemsCollectionSize: ["storageManager", function(storageManager) {
                return storageManager.getCollectionSize('items');
            }]
        }
    });
    $stateProvider.state('app.items.categories', {
        url: '/categories',
        views: {
            "appContent@app": {
                template: require('./partial/categories/categories.html'),
                controller: 'CategoriesCtrl'
            }
        },
        resolve: {
            categories: ["entityManager", function(entityManager) {
                return entityManager.categories.fetchCollectionOnline();
            }]
        }
    });
    $stateProvider.state('app.items.details', {
        url: '/details/:id',
        views: {
            "appContent@app": {
                template: require('./partial/item-details/item-details.html'),
                controller: 'ItemsDetailsCtrl'
            }
        },
        params: {
            id: null,
            showcaseState: null,
        },
        resolve: {
            categories: ["entityManager", function(entityManager) {
                return entityManager.categories.fetchCollectionOnline();
            }],
            departments: ["entityManager", function(entityManager) {
                return entityManager.departments.fetchCollectionOnline();
            }],
            vat: ["entityManager", function(entityManager) {
                return entityManager.vat.fetchCollectionOnline();
            }],
            item: ["entityManager", "$stateParams", function(entityManager, $stateParams) {
                if ($stateParams.id !== "new") {
                    return entityManager.items.fetchOneOnline($stateParams.id);
                }
            }],
            components: ["entityManager", function(entityManager) {
                return entityManager.components.fetchCollectionOnline();
            }],
            allergens: ["entityManager", function(entityManager) {
                return entityManager.allergens.fetchCollectionOnline();
            }],
            suppliers: ["entityManager", function(entityManager) {
                return entityManager.suppliers.fetchCollectionOffline();
            }],
            channels: ["entityManager", function(entityManager) {
                return entityManager.channels.fetchCollectionOffline().then(function(channels) {
                    return _.reject(channels, { id: 'pos' });
                });
            }],
            orderPrinters: ["entityManager", function(entityManager) {
                return entityManager.printers.fetchCollectionOffline({ type: 'nonfiscal' });
            }],
            itemLastMovements: ["restManager", "$stateParams", function(restManager, $stateParams) {
                return restManager.getList('stock_movements', { item_id: $stateParams.id, per_page: 5, orderby_desc: 'date', price: 'notnull' }).then(function(res) {
                    return res.results;
                });
            }],
            itemStock: ["entityManager", "$stateParams", function(entityManager, $stateParams) {
                return entityManager.stock.fetchCollectionOffline({ item_id: _.toInteger($stateParams.id) }).then(function(results) {
                    return _.head(results) || {};
                });
            }]
        }
    });
    $stateProvider.state('app.items.departments', {
        url: '/departments',
        views: {
            "appContent@app": {
                template: require('./partial/departments/departments.html'),
                controller: 'DepartmentsCtrl',
            }
        },
        resolve: {
            activityCodes: ["entityManager", (entityManager) => entityManager.activityCodes.fetchCollectionOnline()],
            departments: ["entityManager", (entityManager) => entityManager.departments.fetchCollectionOnline()],
            giftcardsTypes: ["checkManager", "restManager", async (checkManager, restManager) => {
                if(!checkManager.isModuleEnabled("giftcard")) {
                    return [];
                }

                let result;

                try {
                    result = await restManager.getList("giftcards_types");

                    if(!_.isArray(result)) {
                        result = [];
                    }
                } catch(err) {
                    result = [];
                }

                return result;
            }],
            paymentMethods: ["entityManager", (entityManager) => entityManager.paymentMethods.fetchCollectionOffline()],
            vats: ["entityManager", (entityManager) => entityManager.vat.fetchCollectionOnline()]
        }
    });
    $stateProvider.state('app.items.components', {
        url: '/components',
        views: {
            "appContent@app": {
                template: require('./partial/components/components.html'),
                controller: 'ComponentsCtrl'
            }
        },
        resolve: {
            components: ["entityManager", function(entityManager) {
                return entityManager.components.fetchCollectionOnline();
            }],
            categories: ["entityManager", function(entityManager) {
                return entityManager.categories.fetchCollectionOnline();
            }]
        }
    });
    $stateProvider.state('app.items.raw-materials', {
        url: '/raw-materials',
        views: {
            "appContent@app": {
                template: require('./partial/raw-materials/raw-materials.html'),
                controller: 'RawMaterialsCtrl'
            }
        },
        resolve: {
            rawMaterials: ["entityManager", function(entityManager) {
                return entityManager.rawMaterials.fetchCollectionOnline();
            }],
            suppliers: ["entityManager", function(entityManager) {
                return entityManager.suppliers.fetchCollectionOffline();
            }]
        }
    });

    $stateProvider.state('app.items.vat-rate-management', {
        url: '/vat-rate-management',
        views: {
            "appContent@app": {
                template: require('./partial/vat-rate-management/vat-rate-management.html'),
                controller: 'VatRateManagementCtrl',
            }
        },
        resolve: {
            vatRates: ["entityManager", function(entityManager) {
                return entityManager.vat.fetchCollectionOnline();
            }]
        }
    });
    /* Add New States Above */
}]);

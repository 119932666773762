<ng-container>
    @if (isProcessing()) {
        <mat-progress-bar mode="query"></mat-progress-bar>
    }
    <app-grid-client-side
        #appGridComponent
        (clickableAction)="actions($event)"
        (rowClicked)="rowClicked($event)"
        [fixedButtons]="fixedButtons"
        [hidden]="!gridPath"
        [tableData]="tableData[0]"
        (deleteChange)="deletePromotions($event)"
        feature="promotions"
    ></app-grid-client-side>
</ng-container>

<div appUpgradeUiView *ngIf="!gridPath"></div>

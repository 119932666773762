export type FloorType = 'parquet' | 'parquet_light' | 'resilient' | 'parquet_white';

export interface Floor {
    id: number;
    name: FloorType;
    imageUrl: `${FloorType}.png`;
}

export const FLOORS: Floor[] = [
    {
        "id": 1,
        "name": "parquet",
        "imageUrl": "parquet.png"
    },
    {
        "id": 2,
        "name": "parquet_light",
        "imageUrl": "parquet_light.png"
    },
    {
        "id": 3,
        "name": "resilient",
        "imageUrl": "resilient.png"
    },
    {
        "id": 4,
        "name": "parquet_white",
        "imageUrl": "parquet_white.png"
    }
];

import {
    CommonModule
} from "@angular/common";

import {
    Component,
    EventEmitter,
    HostBinding,
    input,
    Input,
    output,
    Output,
} from "@angular/core";

import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { GeneralInputButton } from "@tilby/tilby-ui-lib/models";
import { mobileCheck } from "@tilby/tilby-ui-lib/utilities";

@Component({
    selector: 'tilby-dialog-toolbar',
    templateUrl: './tilby-dialog-toolbar.component.html',
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        TranslateModule,
    ],
    host: {
        class: 'tw-flex tw-flex-row tw-items-center tw-pt-2 tw-px-2'
    }
})
export class TilbyDialogToolbarComponent {
    private readonly mobileCheck = mobileCheck();
    @HostBinding('class.tw-p-0') get myClass() {
        // replace 'condition' with your actual condition
        return this.mobileCheck;
    }

    @Input() title: string = '';
    @Input() titleParams?: { [key: string]: string };
    @Input() customActions: GeneralInputButton[] = [];
    @Input() disabled: boolean = false;
    @Input() confirmLabel?: string;
    @Input() hideConfirm: boolean = false;
    @Input() hideCancel: boolean = false;
    @Output() confirm = new EventEmitter<boolean>();
    isBack = input<boolean>(false);
    backClick = output<void>();
}
import angular from 'angular';

angular.module('digitalPayments').factory('ingenico', ingenico);

ingenico.$inject = ["$q", "$translate", "$window"];

function ingenico($q, $translate, $window) {
    var getPosErrorCode = function(code) {
        switch (code) {
            case 20:
            case 23:
                return $translate.instant('DIGITAL_PAYMENTS.INGENICO.CANCELLED_PAYMENT');
            case 122:
            case 130:
                return $translate.instant('DIGITAL_PAYMENTS.INGENICO.POS_OFFLINE');
            case 159:
                return $translate.instant('DIGITAL_PAYMENTS.INGENICO.NO_CONNECTION');
            case 188:
            case 189:
                return $translate.instant('DIGITAL_PAYMENTS.INGENICO.REFUSED_PAYMENT');
            case 250:
                return $translate.instant('DIGITAL_PAYMENTS.INGENICO.MERCHANT_NOT_CONFIGURED');
            default:
                return $translate.instant('DIGITAL_PAYMENTS.INGENICO.UNKNOWN', {value: code});
        }
    };

    var ingenico = {
        payment: function(amount, options) {
            let paymentMethod = options.paymentMethod;

            var d = $q.defer();

            if (!paymentMethod.bundle_name) {
                d.reject($translate.instant('DIGITAL_PAYMENTS.COMMON.BUNDLE_NAME_NOT_FOUND'));
                return d.promise;
            }

            if (amount * 100 <= 9) {
                d.reject($translate.instant('DIGITAL_PAYMENTS.INGENICO.AMOUNT_MUST_BE_GREATER'));
                return d.promise;
            }

            $window.IngenicoPlugin.initPlugin(paymentMethod.bundle_name + '.external.pay');

            $window.IngenicoPlugin.isAvailable(function() {
                $window.IngenicoPlugin.performPayment(Math.round(amount * 100), function(result) {
                    // resolve to print and store sale on closed
                    var acquirer_id = null;
                    if(result.match(/ACQ\.ID\s{1}.+/g) !== null){
                        acquirer_id = parseInt(result.match(/ACQ\.ID\s{1}.+/g)[0].replace("ACQ.ID", "").trim());
                    }

                    var acquirer_name = null;
                    if(result.match(/ACQUISTO\s{1}.+/g) !== null){
                        acquirer_name = result.match(/ACQUISTO\s{1}.+/g)[0].replace("ACQUISTO", "").trim();
                    }

                    var returnObject = {
                        acquirer_id : acquirer_id,
                        acquirer_name : acquirer_name,
                        card_circuit_name: acquirer_name,
                        payment_data: result,
                        tail: result,                        
                        unclaimed: false
                    };
                    d.resolve(returnObject);
                }, function(error) {
                    d.reject($translate.instant('DIGITAL_PAYMENTS.INGENICO.FAILED_PAYMENT', {value: getPosErrorCode(error)}));
                });
            }, function() {
                d.reject($translate.instant('DIGITAL_PAYMENTS.INGENICO.UNINSTALLED_POS'));
            });

            return d.promise;
        }
    };

    return ingenico;
}
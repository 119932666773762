import angular from 'angular';
import _ from 'lodash';

const orderPrinterDefaultConfig = require('src/app/features/printers/constants/order_printer_default_config.json');

angular.module('printers').controller('PrintersNewCtrl', printersNewCtrl);

printersNewCtrl.$inject = ["$scope", "$rootScope", "$stateParams", "$state", "$translate", "confirmDialog", "entityManager", "FiscalPrinters", "NoFiscalPrinters", "alertDialog", "environmentInfo", "printerErrorFiscal"];

function printersNewCtrl($scope, $rootScope, $stateParams, $state, $translate, confirmDialog, entityManager, FiscalPrinters, NoFiscalPrinters, alertDialog, environmentInfo, printerErrorFiscal) {
    $scope.patternIP = /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])$/;
    $scope.patternMAC = /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/;

    $scope.printer = {
        configuration_pending: true
    };

    var fiscalPrinters = {
        axon: { label: 'Axon (Vecchio protocollo)', driver: 'axon', connectionType: 'ts' },
        axon_g100: { label: 'Axon Hydra II', driver: 'axon_g100', connectionType: 'ts' },
        custom: { label: 'Custom / Italretail', driver: 'custom', connectionType: 'ts' },
        dtr: { label: 'DTR', driver: 'dtr', connectionType: 'ts' },
        epson: { label: 'Epson', driver: 'epson', connectionType: 'ws' },
        rch: { label: 'RCH', driver: 'rch', connectionType: 'ws' },
    };

    $scope.fiscalProviders = [
        { name: 'RetailForce', value: 'retailforce' }
    ];

    switch ($stateParams.type) {
        case 'fiscal': case 'rt':
            $scope.printer.type = $stateParams.type;
            $scope.label = $scope.printer.type === 'rt' ? $translate.instant('PRINTERS.NEW.RT') : $translate.instant('PRINTERS.NEW.FP');
            var printerInfo = fiscalPrinters[$stateParams.manifacturer];

            if(printerInfo) {
                Object.assign($scope.printer, {
                    driver: printerInfo.driver,
                    connection_type: printerInfo.connectionType
                });

                $scope.label += ' ' + printerInfo.label;
            } else {
                $state.go('app.printers.general');
            }
        break;
        case 'nonfiscal':
            Object.assign($scope.printer, {
                configuration: JSON.stringify(orderPrinterDefaultConfig),
                driver: 'escpos',
                type: 'nonfiscal'
            });

            $scope.label = $translate.instant('PRINTERS.NEW.ORDER');
        break;
        case 'receipt':
            Object.assign($scope.printer, {
                driver: 'escpos',
                fiscal_provider: null,
                type: 'receipt'
            });

            $scope.label = $translate.instant('PRINTERS.NEW.NONFISCAL');
        break;
        case 'kds':
            Object.assign($scope.printer, {
                driver: 'http',
                type: 'kds'
            });

            $scope.label = $translate.instant('PRINTERS.NEW.KDS');
        break;
        default:
            $state.go('app.printers.general');
    }

    // enables discovery and configure via bluetooth
    $scope.bluetoothFuncEnabled = environmentInfo.isMobileApp() && $scope.printer.driver === 'rch' && $scope.printer.type === 'fiscal';

    // enables discovery of escpos printers
    $scope.networkDiscoveryEnabled = environmentInfo.canScanNetwork() && _.includes(['escpos', 'epos'], $scope.printer.driver);

    $scope.searchPrinter = async () => {
        $scope.foundPrinters = [];

        try {
            $scope.searchingPrinter = true;

            switch ($scope.printer.driver) {
                case 'escpos': case 'epos':
                    let results = await NoFiscalPrinters.discoverPrinters($scope.printer.driver);

                    if (!results.length) {
                        alertDialog.show($translate.instant('PRINTERS.NEW.NO_PRINTERS_IN_LAN'));
                    }

                    $scope.foundPrinters = results.map((ip) =>  ({ ip_address: ip }));
                break;
                case 'rch':
                    let result = await FiscalPrinters.discoverPairedBluetoothRch();

                    if (!result.length) {
                        alertDialog.show($translate.instant('PRINTERS.NEW.NO_PAIRED_BT'));
                    }

                    $scope.foundPrinters = result;
                break;
            }
        } catch(err) {
        } finally {
            $scope.searchingPrinter = false;
        }

    };

    // printer= {address, class, id, name}

    $scope.selectPrinter = function(printer) {
        switch ($scope.printer.driver) {
            case 'rch':
                $scope.printer.name = printer.name;
                $scope.printer.mac_address_bt = printer.address;
            break;
            case 'escpos':
            case 'epos':
                $scope.printer.ip_address = printer.ip_address;
            break;
        }

    };


    $scope.save = async () => {
        let dialogText = "";

        if ($scope.bluetoothFuncEnabled) {
            try {
                if ($scope.printer.mac_address_bt && $scope.printer.ip_address && $scope.printer.subnet_mask && $scope.printer.gateway) {
                    let result = await confirmDialog.show($translate.instant('PRINTERS.NEW.WANT_TO_CONFIG_BT'));

                    if (!result) {
                        throw 'NO_LAN_PARAMETERS_CONFIGURED';
                    }

                    try {
                        $scope.configuringLanBt = true;
                        $rootScope.$broadcast("loader:changeStatus", "configuringLanBtLoader", { enabled: true });

                        await FiscalPrinters.configurePrinterLanBluetoothRch($scope.printer);
                        await alertDialog.show($translate.instant('PRINTERS.NEW.LAN_PARAMETERS_CONFIGURED'));
                    } catch(error) {
                        await printerErrorFiscal.show(error);
                        throw 'CONFURATION_ERROR';
                    } finally {
                        $scope.configuringLanBt = false;
                        $rootScope.$broadcast("loader:changeStatus", "configuringLanBtLoader", { enabled: false });
                    }
                } else {
                    // connection_type === bt
                    await alertDialog.show($translate.instant('PRINTERS.NEW.NO_LAN_DATA'));
                    throw 'NO_LAN_DATA';
                }
            } catch(error) {
                dialogText = $translate.instant('PRINTERS.NEW.NO_LAN_PARAMETERS_CONFIGURED');
            }
        }

        let result = await confirmDialog.show($translate.instant('PRINTERS.NEW.WANT_TO_SAVE', { value: dialogText }));

        if (result) {
            $scope.$parent.showPrintersLoader();
            // clean "" from ip, subnet, gateway, mac when they are not required
            _.forIn($scope.printer, function(value, key) {
                if (['ip_address', 'subnet_mask', 'gateway', 'mac_address_bt'].includes(key) && _.isEmpty(value)) {
                    $scope.printer[key] = null;
                }
            });

            let result = await entityManager.printers.postOneOnline($scope.printer);
            $scope.$parent.goTo('printers.detail', { id: result.id }, true);
        }
    };

    $scope.cancel = function() {
        confirmDialog.show($translate.instant('PRINTERS.NEW.WANT_TO_CANCEL')).then(function(result) {
            if (result) {
                $scope.$parent.goTo("printers.general");
            }
        });
    };

    $scope.isValid = function() {
        if ($scope.newPrinterForm) {
            return $scope.newPrinterForm.$valid;
        } else {
            return false;
        }
    };

    $scope.$parent.topbar_context.saveButton = {
        label: $translate.instant('PRINTERS.NEW.SAVE'),
        action: $scope.save,
        enabled: $scope.isValid
    };

    $scope.$parent.topbar_context.unsaved = function() {
        return true;
    };

    $scope.$parent.hidePrintersLoader();
}
import angular from 'angular';
import _ from 'lodash';

angular.module('settings').factory('activationRequestPosDialog', ['$mdDialog', '$translate', '$rootScope', 'restManager', 'checkManager', 'util', 'fiscalUtils', function($mdDialog, $translate, $rootScope, restManager, checkManager, util, fiscalUtils) {
    activationRequestPosDialogController.$inject = ['$scope', '$mdDialog'];
    function activationRequestPosDialogController($scope, $mdDialog) {
        const vat = checkManager.getPreference("e_invoice.merchant.vat_code") || '';
        _.assign($scope, {
            isActivationRequestPosEnabled: true,
            formDirty: false,
            data: {
                posAmount: 1,
                posOffer: 24
            },
            error: {
                show: false,
                message: ''
            },
            devices: _.range(1, 21),
            vatRegExp: util.getVatRegexp(),
            nexiCode: 123456,
            nexi: {
                posOffer: 24,
                fiscalCode: '',
                requestId: '',
                externalId: '',
                insertDate: '',
                lastUpdate: '',
                quantityAllocated: 1,
                quantityTakingCharge: 0,
                name: '',
                surname: '',
                email: '',
                phoneNumber: '',
                wholesalesLeadContract: []
            }
        });

        $scope.validateTaxCode = function(taxCodeField) {
            taxCodeField.$setValidity('taxCode', _.isEmpty($scope.data.fiscalCode) || fiscalUtils.checkTaxCode($scope.data.fiscalCode));
        };

        restManager.getList('nexi/terminals', { vat: vat }).then( res => {
             if(res) {
                 if(res.length > 0) {
                     console.log("RES", res);
                     res = _.orderBy(res, 'lastUpdate', ['asc']);
                     console.log("RES", res);
                     $scope.nexi = res[0];
                     $scope.isActivationRequestPosEnabled = true;
                    } else {
                        $scope.isActivationRequestPosEnabled = false;
                    }
                } else {
                    $scope.isActivationRequestPosEnabled = false;
                }
        }).catch(err => {
            console.log("ERROR GET nexi/terminals", err.data.error.message);
            $scope.isActivationRequestPosEnabled = false;
        });


        $scope.hide = function() {
            $mdDialog.hide();
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.proceed = function() {
            const payload = {
                name: $scope.data.name,
                surname: $scope.data.surname,
                fiscalCode: $scope.data.fiscalCode,
                vat: $scope.data.vat,
                email: $scope.data.email || '',
                phoneNumber: $scope.data.phoneNumber || '',
                posAmount: $scope.data.posAmount,
                posOffer: $scope.data.posOffer,
            };
            restManager.post('nexi/terminals', payload).then(result => {
                console.log("POST", result);
                if(result) {
                    $scope.nexiCode = result.requestId;
                    $scope.formDirty = true;
                }
            }).catch(err => {
                console.log("ERROR POST nexi/terminals", err.data.error.message);
                if(err.data) {
                    $scope.error.message = err.data.error.message;
                    $scope.error.show = true;
                }
            });
        };

        $scope.continue = function() {
            util.openExternalLink("https://www.nexi.it/shop/attivapos");
        };
    }

    var activationRequestPos = {
        show: function() {
            return $mdDialog.show({
                controller: activationRequestPosDialogController,
                template: require('./activation-request-pos-dialog.html')
            });
        }
    };

    return activationRequestPos;
}]);

import angular from 'angular';
import _ from 'lodash';

angular.module('application').component('sidenav', {
    template: require('./sidenav.html'),
    controller: ["$state", "$rootScope", "$mdSidenav", "$timeout", function ($state, $rootScope, $mdSidenav, $timeout) {
        this.closeLeftMenu = function () {
            $mdSidenav('left').close();
        };

        this.goTo = function (destination) {
            this.closeLeftMenu();

            if ($state.current.name !== "app." + destination) {
                $rootScope.showAppLoader();
                $timeout(function () {
                    $state.go("app." + destination);
                });
            }
        };

        //Component Init
        this.$onInit = async () => {
            this.isSidenavOpen = false;
        };
    }]
});
import angular from 'angular';
import _ from 'lodash';
import { validate as validateUuid } from 'uuid';
import { stringToLines } from 'src/app/shared/string-utils';

angular.module('printers').factory('documentPrinter', documentPrinter);

documentPrinter.$inject = ["$translate", "$rootScope", "util", "alertDialog", "errorsLogger", "FiscalPrinters", "fiscalUtils", "NoFiscalPrinters", "checkManager", "entityManager", "ddtWine", "prepaidSale", "satispay", "eatsready", "bedzzle", "beddy", "leanPMS", "spiaggeIt", "ericsoftPMS", "fidelitySalePoints", "GiftCardSale", "invoicePlugin", "eInvoicePlugin", "eInvoiceSend", "shippingInvoice", "NonFiscalDocumentHook", "environmentInfo", "VneCashdrawer", "campgestPMS"];

function documentPrinter($translate, $rootScope, util, alertDialog, errorsLogger, FiscalPrinters, fiscalUtils, NoFiscalPrinters, checkManager, entityManager, ddtWine, prepaidSale, satispay, eatsready, bedzzle, beddy, leanPMS, spiaggeIt, ericsoftPMS, fidelitySalePoints, GiftCardSale, invoicePlugin, eInvoicePlugin, eInvoiceSend, shippingInvoice, NonFiscalDocumentHook, environmentInfo, VneCashdrawer, campgestPMS) {
    const paymentPlugins = [prepaidSale, GiftCardSale, satispay, eatsready, VneCashdrawer];
    const pmsPlugins = [bedzzle, beddy, leanPMS, spiaggeIt, ericsoftPMS, campgestPMS];

    const documentPlugins = {
        fiscal_receipt: [ddtWine, ...paymentPlugins, ...pmsPlugins, fidelitySalePoints],
        invoice: [invoicePlugin, ...paymentPlugins],
        receipt_invoice: [invoicePlugin, satispay, eatsready],
        summary_invoice: [invoicePlugin],
        shipping_invoice: [shippingInvoice, ...paymentPlugins],
        e_invoice: [eInvoicePlugin, ...paymentPlugins, ...pmsPlugins],
        summary_e_rc: [eInvoicePlugin, ...paymentPlugins, ...pmsPlugins],
        summary_e_nrc: [eInvoicePlugin, ...pmsPlugins],
        generic_receipt: [NonFiscalDocumentHook, ...paymentPlugins, ...pmsPlugins, fidelitySalePoints],
        generic_invoice: [invoicePlugin, NonFiscalDocumentHook, ...paymentPlugins, ...pmsPlugins, fidelitySalePoints],
        generic_document: [NonFiscalDocumentHook, ...paymentPlugins, ...pmsPlugins, fidelitySalePoints],
        credit_note: [NonFiscalDocumentHook, ...paymentPlugins, ...pmsPlugins, fidelitySalePoints],
    };

    const dailyClosingPlugins = [leanPMS];

    const getDocumentType = (printerDocumentData) => printerDocumentData?.document_type?.id;

    const checkPrinterDocumentData = (printerDocumentData) => {
        if (!_.isObject(printerDocumentData)) {
            throw 'MISSING_PRINTER_DOCUMENT_DATA';
        }

        if (!_.isObject(printerDocumentData.printer)) {
            throw 'MISSING_PRINTER';
        }
    };

    const reflowDocumentColumns = (documentToPrint, printer) => {
        //Split document into multiple lines if necessary and remove white spaces at beginning/end of each line
        let doc = _.chain(_.isString(documentToPrint) ? documentToPrint.split('\n') : documentToPrint).map((line) => {
            line = _.trimEnd(line);

            //If the trimmed line is empty, add an empty space to keep line breaks intact
            if (_.isEmpty(line)) {
                line = ' ';
            }

            return line;
        }).value();

        //Get number of columns
        const documentCols = _.chain(doc).maxBy('length').size().value();

        //Get Printer Columns
        let printerCols;

        if (printer.columns) {
            printerCols = printer.columns;
        } else {
            switch (printer.driver) {
                case 'rch': case 'axon': case 'axon_g100':
                    printerCols = 48;
                    break;
                case 'epson':
                    printerCols = 46;
                    break;
                default:
                    printerCols = 46;
            }
        }

        //Reflow lines if the printer hasn't enough columns
        if (documentCols > printerCols) {
            doc = stringToLines(doc, printerCols);
        }

        return doc;
    };

    const handleDocumentPlugins = async (clonedSale, pluginData, printerDocumentData) => {
        let result = {
            tail: [],
            otherDocuments: [],
            options: {}
        };

        const documentType = getDocumentType(printerDocumentData);

        for (let i = 0; i < _.size(documentPlugins[documentType]); i++) {
            const plugin = documentPlugins[documentType][i];

            if (plugin.isEnabled() && _.isFunction(plugin.getData)) {
                let pluginResult = await plugin.getData(clonedSale, printerDocumentData, pluginData[i]);

                if (!_.isObject(pluginResult)) {
                    pluginResult = {};
                }

                if (_.isArray(pluginResult.tail)) {
                    result.tail.push('');
                    result.tail = _.concat(result.tail, pluginResult.tail);
                    result.tail.push('');
                }

                if (_.isArray(pluginResult.otherDocument)) {
                    result.otherDocuments.push(pluginResult.otherDocument);
                }

                if (_.isObject(pluginResult.options)) {
                    Object.assign(result.options, pluginResult.options);
                }
            }
        }

        result.tail = result.tail.join('\n');
        return result;
    };

    const handlePrintHooks = async (printHookName, clonedSale, pluginData, printerDocumentData) => {
        const documentType = getDocumentType(printerDocumentData);
        const plugins = documentPlugins[documentType] || [];

        for(const [i, plugin] of plugins.entries()) {
            if (plugin.isEnabled() && typeof plugin[printHookName] === 'function') {
                const data = await plugin[printHookName](clonedSale, printerDocumentData, pluginData[i]);

                if (data) {
                    pluginData[i] = data;
                }
            }
        }
    };

    const cleanupSale = (sale) => {
        //remove void price changes
        _.remove(sale.price_changes, { price_partial: 0 });

        _.forEach(sale.sale_items, (saleItem) => {
            _.remove(saleItem.price_changes, { price_partial: 0 });
        });

        return sale;
    };

    const checkSaleBeforePrint = async (sale, printerDocumentData, options) => {
        if (!_.isObject(sale) || !printerDocumentData?.document_type) {
            throw 'DOCUMENT_TYPE_NOT_FOUND';
        }

        //Check customer for certain document types
        if (checkManager.getSetting('cashregister.fiscalprinter.must_select_customer') && _.isEmpty(sale?.sale_customer)) {
            throw 'CUSTOMER_MISSING';
        }

        const documentType = getDocumentType(printerDocumentData);
        const isEInvoice = ['summary_e_rc', 'summary_e_nrc', 'e_invoice'].includes(documentType);
        const isRT = printerDocumentData.printer?.type === 'rt';

        //Check for e-invoice document type if sale is an e-invoice
        if (!_.isNil(sale.e_invoice) && !isEInvoice) {
            throw 'E_INVOICE_WRONG_DOCTYPE';
        }

        //Check if there are items with a positive quantity when the amount is negative
        if (sale.final_amount < 0 && _.some(sale.sale_items, (saleItem) => (saleItem.quantity > 0))) {
            throw 'INCONGRUITY_ON_SALE';
        }

        if (isRT && !isEInvoice) {
            let taxSum = _(fiscalUtils.extractTax(sale)).toArray().sumBy('tax');

            if (taxSum < 0 && _.some(sale.sale_items, (saleItem) => (saleItem.quantity > 0))) {
                throw 'NEGATIVE_TAX_ON_RT';
            }
        }

        //Check for 0 VAT items that have not an exemption (only RT printers)
        if (isRT) {
            let departments = await entityManager.departments.fetchCollectionOffline();
            let departmentsById = _.keyBy(departments, 'id');

            let hasZeroVatItem = _.some(sale.sale_items, (saleItem) => {
                let vatData = departmentsById[saleItem.department_id]?.vat;

                return vatData.value === 0 && !(vatData.id === 0 || vatData.id >= 10);
            });

            if (hasZeroVatItem && !isEInvoice) {
                throw 'ZERO_VAT_ITEMS_ON_RT';
            }
        }

        if (_.isArray(documentPlugins[documentType])) {
            for (let plugin of documentPlugins[documentType]) {
                if (plugin.isEnabled() && _.isFunction(plugin.isPrintable)) {
                    await plugin.isPrintable(sale, printerDocumentData, options);
                }
            }
        }
    };

    const preparePrinterDriver = async (printer) => {
        switch (printer.type) {
            case 'fiscal': case 'rt':
                return FiscalPrinters.preparePrinterDriver(printer.id);
            case 'receipt':
                return { printer: printer };
            default:
                throw 'INVALID_PRINTER_TYPE';
        }
    };

    const checkDriverCapability = async (printerData, documentTypeId) => {
        switch (printerData.printer.type) {
            case 'fiscal': case 'rt':
                return FiscalPrinters.checkDriverCapability(printerData, documentTypeId);
            case 'receipt':
                return true;
            default:
                throw 'INVALID_PRINTER_TYPE';
        }
    };

    const documentPrinter = {
        checkSale: function checkSale(sale, printerDocumentData) {
            return checkSaleBeforePrint(sale, printerDocumentData);
        },
        isPrinterReachable: async (printer) => {
            checkPrinterDocumentData({ printer: printer });

            switch (printer.type) {
                case 'fiscal': case 'rt':
                    return FiscalPrinters.isReachable(printer);
                case 'receipt':
                    return NoFiscalPrinters.isReachable(printer);
                default:
                    throw 'INVALID_PRINTER_TYPE';
            }
        },
        isPrinterUsable: function (printer) {
            switch (printer?.driver) {
                case 'custom': case 'axon': case 'axon_g100': case 'escpos': case 'dtr':
                    return environmentInfo.canUseTcpSockets();
                case 'epos': case 'epson': case 'rch':
                    return true;
                default: //Unknown driver
                    return false;
            }
        },
        openCashDrawer: async (printerDocumentData) => {
            checkPrinterDocumentData(printerDocumentData);

            $rootScope.$broadcast('drawer-open');

            switch (printerDocumentData.printer.type) {
                case 'fiscal': case 'rt':
                    return FiscalPrinters.openCashDrawer(printerDocumentData);
                case 'receipt':
                    return NoFiscalPrinters.openCashDrawer(printerDocumentData);
                default:
                    throw 'INVALID_PRINTER_TYPE';
            }
        },
        displayText: async (printer, textLines) => {
            if (!documentPrinter.isPrinterUsable(printer)) {
                throw 'UNSUPPORTED_ENVIRONMENT';
            }

            switch (printer.type) {
                case 'fiscal': case 'rt':
                    return FiscalPrinters.displayText(printer, textLines);
                case 'receipt':
                    return NoFiscalPrinters.displayText(printer, textLines);
                default:
                    throw 'INVALID_PRINTER_TYPE';
            }
        },
        printNonFiscalSale: async (sale, printer) => {
            let localSale = _.cloneDeep(sale);

            switch (printer.type) {
                case 'fiscal': case 'rt':
                    return FiscalPrinters.printNonFiscalSale(localSale, printer);
                case 'receipt': case 'nonfiscal':
                    return NoFiscalPrinters.printNonFiscalSale(localSale, printer);
                default:
                    throw 'INVALID_PRINTER_TYPE';
            }
        },
        dailyClosing: async (printer, data) => {
            if (!_.isObject(printer)) {
                throw 'MISSING_PRINTER';
            }

            if (!_.isObject(data)) {
                data = {};
            }

            const printerId = printer.id;

            let dailyClosingReturn = {};

            $rootScope.$broadcast('daily-closing', { status: 'IN_PROGRESS', printerId: printerId });

            switch (printer.type) {
                case 'fiscal': case 'rt':
                    dailyClosingReturn = await FiscalPrinters.dailyClosing(printer, data);
                break;
                case 'receipt':
                    dailyClosingReturn = await NoFiscalPrinters.dailyClosing(printer, data);
                break;
                default:
                    throw 'INVALID_PRINTER_TYPE';
            }

            const { status, errors } = dailyClosingReturn;

            if (status === 'COMPLETED') {
                for (const plugin of dailyClosingPlugins) {
                    if (plugin.isDailyClosingEnabled() && typeof(plugin.dailyClosing) === 'function') {
                        try {
                            await plugin.dailyClosing(printer, data);
                        } catch (error) {
                            errors.push(error);
                        }
                    }
                }
            }

            $rootScope.$broadcast('daily-closing', { status: status, errors: errors, printerId: printerId });
        },
        printFreeNonFiscal: async (documentToPrint, printerId, options) => {
            if (!_.isObject(options)) {
                options = {};
            }

            let printer = await entityManager.printers.fetchOneOffline(printerId);

            if (!printer) {
                throw 'PRINTERS_NOT_FOUND';
            }

            let doc = reflowDocumentColumns(documentToPrint, printer);

            switch (printer.type) {
                case 'fiscal': case 'rt':
                    return FiscalPrinters.printFreeNonFiscal(doc, printer, options);
                case 'receipt':
                    return NoFiscalPrinters.printFreeNonFiscal(doc, printer, options);
                default:
                    throw 'INVALID_PRINTER_TYPE';
            }
        },
        reprintDocument: async (documentToReprint, referenceSale, printerId) => {
            let printer;

            if(printerId === 'dummy_receipt') {
                printer = {
                    type: 'receipt',
                    name: 'Dummy Receipt Printer',
                    driver: 'epos',
                    ip_address: '0.0.0.0'
                };
            } else {
                printer = await entityManager.printers.fetchOneOffline(printerId);
            }

            if (!printer) {
                throw 'PRINTERS_NOT_FOUND';
            }

            switch (printer.type) {
                case 'fiscal': case 'rt':
                    let documentToPrint = reflowDocumentColumns(documentToReprint.document_content, printer);

                    try {
                        await FiscalPrinters.printFreeNonFiscal(documentToPrint, printer);

                        return { document_content: documentToPrint };
                    } catch(error) {
                        throw error;
                    }
                break;
                case 'receipt':
                    return NoFiscalPrinters.reprintDocument(documentToReprint, referenceSale, printer);
                default:
                    throw 'INVALID_PRINTER_TYPE';
            }
        },
        printDocument: async (origSale, printerDocumentData) => {
            let pluginData = {};

            if (!printerDocumentData.document_type) {
                throw 'DOCUMENT_TYPE_NOT_FOUND';
            }

            //Work on a cloned sale, then replace the original with the clone before returning it
            let clonedSale = _.chain(origSale).cloneDeep().thru(cleanupSale).value();

            await checkSaleBeforePrint(clonedSale, printerDocumentData, { skip_warnings: true });

            let printerData = await preparePrinterDriver(printerDocumentData.printer);
            // tail to define with algorithm ie password generator put inside printerDocumentData

            if (!checkDriverCapability(printerData, printerDocumentData.document_type.id)) {
                alertDialog.show($translate.instant('PRINTERS.FISCAL.FUNCTION_NOT_AVAILABLE'));
                throw 'FUNCTION_NOT_AVAILABLE';
            }

            let canOpenCashDrawer = checkManager.isFunctionEnabledOptout('cashregister.fiscalprinter.can_open_cash_drawer');

            if(canOpenCashDrawer) {
                //Find payment methods used in the sales and disable cash drawer opening if all of the payments have it disabled with the flag disable_open_cash_drawer
                const usedPayments = new Set(origSale.payments?.map((payment) => payment.payment_method_id) || []);
                const paymentMethods = await entityManager.paymentMethods.fetchCollectionOffline({ id_in: [...usedPayments] });

                if(paymentMethods.length && paymentMethods.every((paymentMethod) => paymentMethod.disable_open_cash_drawer)) {
                    canOpenCashDrawer = false;
                }
            }

            let optionsPrint = {
                can_open_cash_drawer: canOpenCashDrawer,
                print_details: !(printerDocumentData.options?.printOnlyDepartments),
                e_receipt: printerDocumentData.options?.eReceipt ? 1 : 0,
                tail: printerDocumentData.tail
            };

            //QR Code
            let receiptUrl = checkManager.getPreference('fiscalprinter.qr_code_url');

            if (receiptUrl) {
                let shopName = $rootScope.userActiveSession.shop.name;
                let saleId = validateUuid(clonedSale.id) ? null : clonedSale.id;
                let saleUuid = validateUuid(clonedSale.uuid) ? clonedSale.uuid : null;
                let finalAmount = clonedSale.final_amount;
                let saleNotes = _.truncate(clonedSale.notes, { length: 30 });

                let initialSeparator = _.includes(receiptUrl, '?') ? '&' : '?';

                optionsPrint.qr_code_url = receiptUrl + initialSeparator + "shop_name=" + _.escape(shopName) + (saleId ? "&id=" + _.escape(saleId) : '') + (saleUuid ? "&uuid=" + _.escape(saleUuid) : '') + "&final_amount=" + finalAmount + (saleNotes ? "&notes=" + _.escape(saleNotes) : '');
                optionsPrint.qr_code_message = checkManager.getPreference('fiscalprinter.qr_code_message');
            }

            printerDocumentData.itemsMap = await util.getItemsFromIds(clonedSale.sale_items);

            await handlePrintHooks('prePrintHook', clonedSale, pluginData, printerDocumentData);

            let result = await handleDocumentPlugins(clonedSale, pluginData, printerDocumentData);

            if (!_.isEmpty(result.tail)) {
                optionsPrint.tail = _.toString(optionsPrint.tail) + result.tail;
            }

            Object.assign(optionsPrint, result.options);

            optionsPrint.otherDocuments = result.otherDocuments;

            try {
                if (['e_invoice', 'summary_e_rc', 'summary_e_nrc'].includes(printerDocumentData.document_type.id)) {
                    const savedSale = await eInvoiceSend.sendEInvoice(structuredClone(clonedSale), printerDocumentData);

                    try {
                        await handlePrintHooks('postPrintHook', structuredClone(savedSale), pluginData, printerDocumentData);
                    } catch (err) {
                        //Nothing to do
                    }

                    //Resolve and handle receipt printing in the background
                    return new Promise(async (resolve) => {
                        resolve(structuredClone(savedSale));

                        /*
                            Receipt Printing is enabled only if e-receipt is not enabled and one of the following is true:
                            - Print e-invoice Receipt is enabled
                            - Print DDT is enabled
                            - Customer is private
                        */
                        const shouldPrintReceipt = (
                            !printerDocumentData?.options?.eReceipt &&
                            (
                                printerDocumentData?.options?.printEInvoiceReceipt ||
                                printerDocumentData?.options?.printDDT ||
                                (!!clonedSale.customer_tax_code || !clonedSale?.sale_customer?.company_name)
                            )
                        );
    
                        if (!shouldPrintReceipt) {
                            return;
                        }

                        const invoiceReceipt = savedSale.sale_documents?.find((document) => document.document_type === 'receipt');

                        if (!invoiceReceipt) {
                            return;
                        }

                        let success = false;

                        try {
                            const printCopies = parseInt(checkManager.getPreference('fiscalprinter.e_invoice_receipt_copies')) || 1;

                            for (let i = 0; i < printCopies; i++) {
                                await documentPrinter.printFreeNonFiscal(invoiceReceipt.document_content, printerDocumentData.printer.id, { printHeader: false });
                            }

                            success = true;
                        } catch (err) {
                            //Nothing to do
                        } finally {
                            $rootScope.$broadcast('documentPrinter:document-printed', { type: 'e_invoice', success: success, saleData: _.cloneDeep(savedSale) });
                        }
                    });
                } else {
                    let printService;

                    switch (printerData.printer.type) {
                        case 'fiscal': case 'rt':
                            printService = FiscalPrinters;
                            break;
                        case 'receipt':
                            printService = NoFiscalPrinters;
                            break;
                        default:
                            throw 'INVALID_PRINTER_TYPE';
                    }

                    // Send a copy of the sale to the printing layer
                    const fiscalSale = structuredClone(clonedSale);

                    //Check if the user wants to remove the items with price 0
                    if (checkManager.getPreference('cashregister.remove_zero_price_items_from_receipt')) {
                        fiscalSale.sale_items = (fiscalSale.sale_items || []).filter(item => item.price !== 0 || item.final_price !== 0);
                    }

                    let [success, printedDocuments] = await printService.printDocument(fiscalSale, printerDocumentData, printerData, optionsPrint);

                    try {
                        await handlePrintHooks('postPrintHook', clonedSale, pluginData, printerDocumentData);
                    } catch (err) {
                        //Nothing to do
                    }

                    for (let document of printedDocuments) {
                        Object.assign(document, {
                            printer_id: printerDocumentData.printer.id,
                            printer_name: printerDocumentData.printer.name
                        });
                    }

                    Object.assign(clonedSale, {
                        is_summary: ['summary_invoice', 'summary_e_rc', 'summary_e_nrc'].includes(printerDocumentData.document_type.id),
                        sale_documents: _.cloneDeep(printedDocuments)
                    });

                    angular.copy(clonedSale, origSale);

                    return new Promise(async (resolve, reject) => {
                        resolve(printedDocuments);

                        $rootScope.$broadcast('documentPrinter:document-printed', { type: 'other', success: success, saleData: clonedSale });

                        if (['fiscal', 'rt'].includes(printerData.printer.type) && printerDocumentData?.options?.courtesyReceipt) {
                            try {
                                await FiscalPrinters.printCourtesyReceipt(clonedSale, printerData.printer.id);
                            } catch (err) {
                                errorsLogger.err(`[ documentPrinter] failed to print courtesy receipt for ${printerDocumentData.document_type.id}`);
                            }
                        }
                    });
                }
            } catch (errMessage) {
                let errors = [errMessage];

                try {
                    await handlePrintHooks('printFailHook', clonedSale, pluginData, printerDocumentData);
                } catch (error) {
                    errors.push(error);
                } finally {
                    $rootScope.$broadcast('documentPrinter:document-print-failed', { error: errMessage, printerId: printerData.printer.id });

                    throw errors;
                }
            }
        },
        sendSaleToPMS: async(sale) => {
            const saleToSend = structuredClone(sale);

            for(const plugin of pmsPlugins) {
                if (plugin.isEnabled() && typeof plugin['postPrintHook'] === 'function') {
                    await plugin['postPrintHook'](saleToSend, { document_type: { id: 'generic_receipt' }}).catch(() => {
                        //Nothing to do
                    });
                }
            }
        }
    };

    return documentPrinter;
}

import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';

angular.module('stock').directive('topbarStock', topbarStock);

topbarStock.$inject = ["$timeout", "$filter", "$mdSidenav", "$state", "connection", "environmentInfo", "offlineDialog", "checkManager"];

function topbarStock($timeout, $filter, $mdSidenav, $state, connection, environmentInfo, offlineDialog, checkManager) {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            context: "="
        },
        template: require('./topbar-stock.html'),
        controller: ["$scope", "$element", "$attrs", function($scope, $element, $attrs) {
            $scope.isConnectionOffline = function() {
                return connection.isOffline();
            };

            $scope.openOfflineDialog = function() {
                offlineDialog.show();
            };

            $scope.openLeftMenu = function() {
                $mdSidenav('left').toggle();
            };

            $scope.goToImporter = function() {
                $state.go('app.new.file_importer');
            };

            $scope.showSearch = function() {
                $scope.context.mode = 'search';
                $scope.context.resetPagedStockElementResult();
                $timeout(function() {
                    $('#stockManagementShowcaseSearch').trigger('focus');
                }, 100);
            };

            $scope.goToState = function(state) {
                $state.go("app." + state);
            };

            $scope.changeListSize = function(value) {
                $scope.context.changeListSize(value);
            };

            $scope.updateDateRange = function() {
                if ($scope.context.dateFilter.from <= $scope.context.dateFilter.to) {
                    $scope.context.searchTriggered();
                }
            };

            $scope.canGoBack = function() {
                return _.includes(['new_movement', 'batch_details', 'search'], $scope.context.mode);
            };

            $scope.canDownloadFiles = function() {
                return environmentInfo.canDownloadFiles();
            };

            $scope.canShare = function() {
                return environmentInfo.canShare();
            };

            $scope.onSupplierOrderClick = () => {
                if($scope.context.defaultSupplierOrder && ($scope.context.defaultSupplierOrder === $scope.context.supplierOrder)) {
                    $scope.context.supplierOrder = '';
                }
            };

            $scope.onSupplierOrderBlur = () => {
                if(!$scope.context.supplierOrder) {
                    $scope.context.supplierOrder = $scope.context.defaultSupplierOrder || '';
                }
            };

            $scope.onSupplierSelect = () => {
                delete $scope.context.defaultSupplierOrder;
            };

            $scope.filterSuppliers = function(searchText) {
                return _.isEmpty(searchText) ? _.get($scope, ["context", "suppliers"]) : $filter('filter')($scope.context.suppliers, searchText);
            };
        }]
    };
}

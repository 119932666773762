import angular from 'angular';
import _ from 'lodash';

angular.module('application').factory('waitDialog', ["$rootScope", "$timeout", "$mdDialog", function($rootScope, $timeout, $mdDialog) {
    waitDialogController.$inject = ["$scope", "options"];

    function waitDialogController($scope, options) {
        if(!_.isObject(options)) {
            options = {};
        }

        var updateState = function(state) {
            $scope.message = state.message || $scope.message;

            if(state.cancelAction) {
                _.assign($scope, {
                    cancelAction: state.cancelAction,
                    customActions: null,
                });
            } else if(state.customActions) {
                _.assign($scope, {
                    cancelAction: null,
                    customActions: state.customActions
                });
            }
        };

        updateState(options);

        $scope.cancel = function() {
            if(_.isFunction($scope.cancelAction.callback)) {
                $scope.cancelAction.callback();
            } else {
                $mdDialog.cancel("CANCELED");
            }
        };

        if(options.promise) {
            options.promise.then((result) => {
                $mdDialog.hide(result);
            }, (error) => {
                $mdDialog.cancel(error);
            });
        }

        if(options.timeout) {
            $scope.timeoutProgress = 0;
            $scope.timeoutTarget = (options.timeout) * 1000;

            var progressUpdate = function() {
                $scope.timeoutProgress += 100;
                if($scope.timeoutProgress >= $scope.timeoutTarget) {
                    $mdDialog.hide();
                } else {
                    $timeout(progressUpdate, 100);
                }
            };

            $timeout(progressUpdate, 100);
        }

        $scope.$on("wait-dialog:update-state", function(event, state) {
            updateState(state);
            $scope.$applyAsync();
        });
    }

    var waitDialog = {
        show: function(options) {
            $rootScope.hideAppLoader();

            return $mdDialog.show({
                controller: waitDialogController,
                template: require('./wait-dialog.html'),
                locals: {
                    options: options
                },
                clickOutsideToClose: false,
                escapeToClose: false
            });
        }
    };

    return waitDialog;
}]);

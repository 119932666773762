import { Injectable, inject } from '@angular/core';
import { Subject } from 'rxjs';

import {
    Customers,
    Items,
    Sales
} from 'tilby-models';


import {
    BarcodeItem,
    ConfigurationManagerService,
    EntityDictionariesStoreService,
    EntityManagerService
} from 'src/app/core';

import { MathUtils } from 'src/app/shared/utils';

import {
    fiscalUtils,
    util
} from 'app/ajs-upgraded-providers';

export type BarcodeEvent = { barcodeInput: string } & ({
    type: 'customer'
    customer?: Customers
    fidelity?: string
    taxCode?: string
    customerUuid?: string
} | {
    type: 'sale'
    sale: Sales
    uuid?: string
    barcodeInput?: string
} | {
    type: 'ticketChange'
    ticketChange: number
    saleUuid?: string
} | {
    type: 'discount'
    discountPerc?: number
    discountFix?: number
    discountDescription?: string
} | {
    type: 'printer'
    printerId?: number
} | {
    type: 'item'
    itemData: BarcodeItemData
    uuid?: string
} | {
    type: 'network-display'
    address: string,
    version: string
})

type BarcodeManagerItem = BarcodeItem & {
    barcode: string
}

type BarcodeItemData = {
    total: number
    data: BarcodeManagerItem[]
}

type DisplayQRCode = {
    address: string,
    version: string
}

type PAQRCodeResult = Pick<Customers, "company_name" | "country" | "tax_code" | "vat_code" | "billing_street" | "billing_zip" | "billing_city" | "billing_prov" | "billing_country" | "email_pec" | "sdi_code">

@Injectable({
    providedIn: 'root'
})
export class BarcodeManagerService {
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly entityDictionariesStoreService = inject(EntityDictionariesStoreService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly fiscalUtilsService = inject(fiscalUtils);
    private readonly util = inject(util);

    private static readonly barcodeEvents = new Subject<BarcodeEvent>();
    public static readonly barcodeEvents$ = BarcodeManagerService.barcodeEvents.asObservable();

    constructor(
    ) {
        window.setInterval(this.elementUnfocusLoop, 1000);
    }

    private async elementUnfocusLoop() {
        if (!document.activeElement || !(document.activeElement instanceof HTMLElement)) {
            return;
        }

        switch (document.activeElement.nodeName) {
            case 'BODY':
            case 'INPUT':
            case 'TEXTAREA':
            case 'WEBVIEW':
            case 'SELECT':
                break;
            case 'DIV':
                if (!document.activeElement.id.startsWith('_md-chips') && document.activeElement.className !== 'ag-list-item ag-select-list-item ag-active-item') {
                    document.activeElement.blur();
                }
                break;
            default:
                document.activeElement.blur();
        }
    }

    private fetchCustomerById(customerId?: number) {
        if (!customerId) {
            return;
        }

        return this.entityManagerService.customers.fetchOneOffline(customerId);
    }

    private wrapSingleResult(result: BarcodeManagerItem): BarcodeItemData {
        return {
            total: 1,
            data: [result]
        };
    }

    private searchLibraBarcode(barcodePrefix: string): BarcodeItem & { barcode: string } | undefined {
        const prefix = barcodePrefix.toUpperCase();
        let item;

        const pricePrefix = `${prefix}P`;
        const quantityPrefix = `${prefix}Q`;

        item = this.entityDictionariesStoreService.getItemByBarcode(pricePrefix);

        if (item) {
            return { ...item, barcode: pricePrefix };
        }

        item = this.entityDictionariesStoreService.getItemByBarcode(quantityPrefix);

        if (item) {
            return { ...item, barcode: quantityPrefix };
        }
    }

    private async checkLibraBarcode(barcodeInput: string): Promise<BarcodeItemData | null> {
        const priceList = this.util.getCurrentPriceList();
        const priceListStr = `price${priceList}` as `price${number}` & keyof Items;
        const libraQRCodeSize = this.configurationManagerService.getPreference('cashregister.dynamic_libra_barcode') ? 31 : 29;

        if (barcodeInput.length === 13) {
            const suffix = barcodeInput.substring(7, 12);
            const libraItem = this.searchLibraBarcode(barcodeInput.substring(0, 7)) || this.searchLibraBarcode(barcodeInput.substring(0, 6));

            if (!libraItem) {
                return null;
            }

            const barcodeType = libraItem.barcode.charAt(libraItem.barcode.length - 1).toUpperCase();

            switch (barcodeType) {
                case 'Q':
                    return this.wrapSingleResult({
                        barcode: libraItem.barcode,
                        item_id: libraItem.item_id,
                        quantity: ((parseInt(suffix) || 0) / 1000)
                    });
                case 'P':
                    const Pb = (parseInt(suffix) || 0) / 100;
                    const item = await this.entityManagerService.items.fetchOneOffline(libraItem.item_id) as Items;

                    if (!item) {
                        return null
                    }

                    return this.wrapSingleResult({
                        barcode: libraItem.barcode,
                        item_id: libraItem.item_id,
                        quantity: MathUtils.round(Pb / (item[priceListStr] || 0), 3)
                    });
                default:
                    return null;
            }
        } else if (barcodeInput.length % libraQRCodeSize === 0) {
            const qrCodes = barcodeInput.match(new RegExp(`.{${libraQRCodeSize}}`, 'g')) || [];
            const inputCodes = [];

            for (const qr of qrCodes) {
                // Unit price
                const unitPrice = MathUtils.round(parseFloat(qr.slice(0, 4)) + (parseFloat(qr.slice(4, 6)) / 100), 2);

                // Quantity / weight
                const quantityType = qr[30] === '1' ? 'quantity' : 'weight';
                const quantity = quantityType === 'weight'
                    ? MathUtils.round(parseFloat(qr.slice(6, 8)) + (parseFloat(qr.slice(8, 11)) / 1000), 3)
                    : parseInt(qr.slice(6, 11));

                // Item code
                const code = qr.slice(23, 29);

                const libraItem = this.searchLibraBarcode(code);

                if (libraItem) {
                    inputCodes.push({
                        barcode: libraItem.barcode,
                        item_id: libraItem.item_id,
                        quantity: quantity,
                        price: unitPrice,
                    });
                }
            }

            return { total: qrCodes.length, data: inputCodes };
        } else {
            return null;
        }
    }

    public isFidelity(barcodeInput: string) {
        const prefix = this.configurationManagerService.getPreference("fidelity_prefix");

        return prefix ? barcodeInput.startsWith(prefix) : false;
    }

    public isTaxCode(barcodeInput: string) {
        return this.configurationManagerService.getShopCountry() === 'IT' && this.fiscalUtilsService.checkTaxCode(barcodeInput);
    }

    public isTicket(barcodeInput: string) {
        return [13, 26, 32].includes(barcodeInput.length) && (barcodeInput.slice(-5, -4) === "0");
    }

    public isTilbyQRCode(barcodeInput: string) {
        return ['c_uid', 's_uid', 'o_uid', 't_chng', 'd_p', 'd_f', 'p_id'].includes(barcodeInput.split('=')[0]);
    }

    public isTilbyItemBarcode(barcodeInput: string) {
        return (barcodeInput.length === 13 && barcodeInput.startsWith('2013') && this.getEan13CheckDigit(barcodeInput) === parseInt(barcodeInput[12]));
    }

    public getGenericItemBarcode(item: Items) {
        const barcode = `2013${item.id?.toString().padStart(8, '0')}`;
        const checkDigit = this.getEan13CheckDigit(barcode);

        return `${barcode}${checkDigit}`;
    }

    public parseTilbyQRCode(barcodeInput: string) {
        const resultObj = this.util.getUrlParams(`?${barcodeInput}`);

        return {
            customerUuid: resultObj.c_uid,
            saleUuid: resultObj.s_uid,
            orderUuid: resultObj.o_uid,
            ticketChange: resultObj.t_chng,
            discountDescription: resultObj.d,
            discountPerc: resultObj.d_p,
            discountFix: resultObj.d_f,
            printerId: resultObj.p_id
        };
    }

    public parsePAQRCode(barcodeInput: string) {
        const result: PAQRCodeResult = {};

        try {
            const PAObj = JSON.parse(barcodeInput);

            if (!PAObj?.anag) {
                return;
            }

            const anag = PAObj.anag;

            Object.assign(result, {
                company_name: anag?.denom || undefined,
                country: anag?.naz || undefined,
                tax_code: anag?.cf || undefined,
                vat_code: anag?.piva || undefined,
            });

            if (anag?.domFisc) {
                const domFisc = anag.domFisc;

                Object.assign(result, {
                    billing_street: domFisc?.ind || undefined,
                    billing_zip: domFisc?.cap || undefined,
                    billing_city: domFisc?.com || undefined,
                    billing_prov: domFisc?.prov || undefined,
                    billing_country: domFisc?.naz || undefined
                });
            }

            if (PAObj.SDI) {
                const SDI = PAObj.SDI;

                Object.assign(result, {
                    email_pec: SDI.pec || undefined,
                    sdi_code: SDI.cod || undefined
                });
            }

            return result;
        } catch (e) {
            return;
        }
    }

    public parseDisplayQRCode(barcodeInput: string): DisplayQRCode | undefined {
        try {
            const parsed = JSON.parse(barcodeInput);

            if ('displayserver' in parsed && 'version' in parsed) {
                return {
                    address: parsed.displayserver,
                    version: parsed.version
                };
            }
        } catch (err) {
            return;
        }
    }

    public parseTicket(barcodeInput: string) {
        if (this.isTicket(barcodeInput)) {
            return (parseInt(barcodeInput.slice(-4)) || 0) / 100;
        }
    }

    public async searchBarcodeCustomerByTaxCode(barcodeInput: string) {
        return this.fetchCustomerById(this.entityDictionariesStoreService.getCustomerByTaxCode(barcodeInput.toUpperCase()));
    }

    public async searchBarcodeCustomerByFidelity(barcodeInput: string) {
        return this.fetchCustomerById(this.entityDictionariesStoreService.getCustomerByFidelity(barcodeInput.toUpperCase()));
    }

    private sendBarcodeEvent(event: BarcodeEvent) {
        BarcodeManagerService.barcodeEvents.next(event);
    }

    public async manageBarcodeInput(barcodeInput: string) {
        // Fidelity
        if (this.isFidelity(barcodeInput)) {
            const customer = await this.searchBarcodeCustomerByFidelity(barcodeInput);
            return this.sendBarcodeEvent({ type: 'customer', customer: customer, fidelity: barcodeInput, barcodeInput: barcodeInput });
        }

        // Tax code
        if (this.isTaxCode(barcodeInput)) {
            const customer = await this.searchBarcodeCustomerByTaxCode(barcodeInput);
            return this.sendBarcodeEvent({ type: 'customer', customer: customer, taxCode: barcodeInput, barcodeInput: barcodeInput });
        }

        // Tilby QR Code
        if (this.isTilbyQRCode(barcodeInput)) {
            const barcodeContent = this.parseTilbyQRCode(barcodeInput);

            if (barcodeContent.customerUuid) {
                const customers = await this.entityManagerService.customers.fetchCollectionOffline({ uuid: barcodeContent.customerUuid })
                this.sendBarcodeEvent({ type: 'customer', customer: customers[0], customerUuid: barcodeContent.customerUuid, barcodeInput: barcodeInput });
            } else if (barcodeContent.saleUuid) {
                const sales = await this.entityManagerService.sales.fetchCollectionOffline({ uuid: barcodeContent.saleUuid })
                this.sendBarcodeEvent({ type: 'sale', sale: sales[0], uuid: barcodeContent.saleUuid, barcodeInput: barcodeInput });
            } else if (barcodeContent.ticketChange) {
                this.sendBarcodeEvent({ type: 'ticketChange', ticketChange: barcodeContent.ticketChange, saleUuid: barcodeContent.saleUuid, barcodeInput: barcodeInput });
            } else if (barcodeContent.discountPerc || barcodeContent.discountFix) {
                this.sendBarcodeEvent({ type: 'discount', discountPerc: barcodeContent.discountPerc, discountFix: barcodeContent.discountFix, discountDescription: barcodeContent.discountDescription, barcodeInput: barcodeInput });
            } else if (barcodeContent.printerId) {
                this.sendBarcodeEvent({ type: 'printer', printerId: barcodeContent.printerId, barcodeInput: barcodeInput });
            }

            return;
        }

        // Display QR Code
        const displayQRCode = this.parseDisplayQRCode(barcodeInput);

        if (displayQRCode) {
            this.sendBarcodeEvent({ type: 'network-display', address: displayQRCode.address, version: displayQRCode.version, barcodeInput: barcodeInput });
            return;
        }

        // PA QR Code
        const customer = this.parsePAQRCode(barcodeInput);

        if (customer) { //is a PA QRCode
            const foundCustomers = await this.entityManagerService.customers.fetchCollectionOffline({ vat_code: customer.vat_code }) as Customers[];

            if (foundCustomers[0]?.id) {
                const customerToUpdate = foundCustomers[0];

                Object.assign(customerToUpdate, customer);

                const updatedCustomer = await this.entityManagerService.customers.putOneOfflineFirst(customerToUpdate);
                this.sendBarcodeEvent({ type: 'customer', customer: updatedCustomer, barcodeInput: barcodeInput });
            } else {
                const newCustomer = await this.entityManagerService.customers.postOneOfflineFirst(structuredClone(customer))
                this.sendBarcodeEvent({ type: 'customer', customer: newCustomer, barcodeInput: barcodeInput });
            }

            return;
        }

        // Barcode Item
        const barcodeItem = await this.searchBarcodeItem(barcodeInput);
        const isTilbyItemBarcode = this.isTilbyItemBarcode(barcodeInput);

        if (barcodeItem.data.length || !isTilbyItemBarcode) {
            this.sendBarcodeEvent({ type: 'item', itemData: barcodeItem, barcodeInput: barcodeInput });
        } else {
            const itemId = parseInt(barcodeInput.substring(4, 12)) || 0;

            this.sendBarcodeEvent({ type: 'item', itemData: this.wrapSingleResult({ barcode: barcodeInput, item_id: itemId }), barcodeInput: barcodeInput });
        }
    }

    public async searchBarcodeItem(barcodeInput: string): Promise<BarcodeItemData> {
        const single = this.entityDictionariesStoreService.getItemByBarcode(barcodeInput.toUpperCase());
        const result = single ? this.wrapSingleResult({...single, barcode: barcodeInput }) : await this.checkLibraBarcode(barcodeInput);

        return result || {
            data: [],
            total: 0
        }
    }

    public getEan13CheckDigit(barcodeInput: string) {
        const reversedBarcode = barcodeInput.padEnd(13, '0').split('').reverse();

        let checkDigitSum = 0;

        for (var i = 1; i < reversedBarcode.length; i++) {
            checkDigitSum += (parseInt(reversedBarcode[i]) || 0) * ((i & 1) ? 3 : 1);
        }

        return (Math.ceil(checkDigitSum / 10) * 10) - checkDigitSum;
    }

    public openCameraBarcode() {
        return new Promise<string>((resolve,reject) => {
            try {
                cordova.plugins.barcodeScanner.scan((result) => {
                    if (!result.cancelled) {
                        let barcodeResult = result.text;

                        if (result.format === 'CODE_39' && result.text.length === 6) {
                            //This could be a pharmacode
                            const code32 = this.code39to32(result.text);

                            if (code32) {
                                barcodeResult = code32;
                            }
                        }

                        resolve(barcodeResult);
                    } else {
                        reject();
                    }
                });
            } catch (e) {
                reject(e);
            }
        });
    };

    private code39to32(code: string) {
        let finalCode = 0;

        const conversionTable = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'B', 'C', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

        //Do conversion
        const wrongCode = code.split('').find((c, idx) => {
            const index = conversionTable.indexOf(c);

            if (index === -1) {
                return true;
            }

            finalCode += (index * Math.pow(32, 5 - idx));
        });

        if (wrongCode) {
            return null;
        }

        finalCode = Number(Math.round(finalCode).toString().padStart(9, '0'));

        //Calculate checksum
        let checkSum = 0;

        finalCode.toString().split('').forEach((c, idx) => {
            if (idx < 8) {
                let sum = parseInt(c) * (1 + (idx % 2));
                if (sum >= 10) { sum = 1 + (sum % 10); }
                checkSum += sum;
            }
        });

        if (Math.round(checkSum % 10).toString() !== finalCode.toString()[8]) {
            return null;
        }

        return `A${finalCode}`;
    };
}
import { CustomForm, CustomFormControl } from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import { Form } from 'src/app/models';
import { ChainPromotions, Items } from 'tilby-models';

interface PromotionsFieldsFe {
    isNew?:boolean;
}

export type PromotionsFields = ChainPromotions & PromotionsFieldsFe;

export class PromotionsDefault implements PromotionsFields {
    name = '';
    active = false;
}

export type PromotionsFieldsNullable = { [prop in string & keyof PromotionsFields]:PromotionsFields[prop]|null }
export type PromotionsFormOLD = Partial<Form<PromotionsFieldsNullable>>;
export type PromotionType = 'discount_perc' | 'discount_fix';
export type PromotionsForm = {
    active: CustomFormControl<boolean>,
    name: CustomFormControl<string>,
    from_date: CustomFormControl<string>,
    to_date: CustomFormControl<string>,
    start_time: CustomFormControl<string>,
    end_time: CustomFormControl<string>,
    weekdays_period: CustomFormControl<Array<number>>,
    required_items: CustomFormControl<number>,
    require_all_items: CustomFormControl<boolean>,
    fidelity_rule: CustomFormControl<ChainPromotions.FidelityRuleEnum>,
    customer_type: CustomFormControl<string>,
    channels: CustomFormControl<Array<string>>,
    shops: CustomFormControl<Array<string>>,
    order_types: CustomFormControl<Array<string>>,
    promotion_type: CustomFormControl<PromotionType>,
    perc_discount: CustomFormControl<number>,
    base_pricelist: CustomFormControl<number>,
    quantity_threshold: CustomFormControl<number>,
    apply_below_threshold: CustomFormControl<boolean>,
    multiples_only: CustomFormControl<boolean>,
    force_pricelist_apply: CustomFormControl<boolean>,
    exclude_other_promotions: CustomFormControl<boolean>,
};
export type PromotionsSearchItemsFormValue = {
    search: string,
    supplier: string,
    brand: string,
    category: string,
    channel: string
}
export type PromotionsSearchItemsForm = CustomForm<PromotionsSearchItemsFormValue>;
export type CurrentSection = 'general' | 'items' | 'required-items';
export type ItemsEstended = Items & {
    _enabled?: boolean,
    _required?: boolean,
    _prevId?: number,
    _prevReqId?: number,
    _discountedPrice?: number | null,
    _quantity?: number | null
}

export interface TableItemsPromotion {
    id: number;
    sku: string;
    name: string;
    cost: number;
    price?: number;
    discountPrice?: number;
    quantity?: number;
}

export type StatusItemsSource = {
    itemsSourceAdd: Array<ItemsEstended>;
    itemsSourceRemoved: Array<ItemsEstended>;
    requiredItemsSourceAdd: Array<ItemsEstended>;
    requiredItemsSourceRemoved: Array<ItemsEstended>;
}

import angular from 'angular';

import { MathUtils } from '@tilby/tilby-ui-lib/utilities';
import { ConfigurationManagerService } from 'src/app/core';
import { TilbyTcpSocket, TilbyTcpSocketUtils } from 'src/app/shared/net/tilby-tcp-socket';

type CashlogyResponse = {
    status: string,
    resultData: string[]
}

type CashlogyTransactionResponse = {
    status: string,
    toPay?: number,
    paid?: number,
    changeGiven?: number,
    changeNotGiven?: number,
    currency?: string
}

type CashlogyOptions = {
    port?: number
}

export class CashlogyDriver {
    private initPromise?: Promise<void>;
    private ipAddress: string | null = null;
    private operationIndex: number = 0;
    private options?: CashlogyOptions;
    private socket?: TilbyTcpSocket;
    private status: string = 'UNINITIALIZED';

    constructor(
        private readonly configurationManager: ConfigurationManagerService,
    ) { }

    /**
     * Converts a cashlogy command to a packet
     * @param {string | string[]} data the cashlogy command
     * @returns {Uint8Array} the packet
     */
    private getPacket(data: string | string[]) {
        const packet = [];

        if (Array.isArray(data)) {
            data = `#${data.join('#')}#`;
        }

        //DATA
        for (let i = 0; i < data.length; i++) {
            packet.push(data.charCodeAt(i));
        }

        return new Uint8Array(packet);
    }

    /**
     * Converts a cashlogy response packet to an object
     * @param {Uint8Array} responseData the cashlogy response
     * @returns {CashlogyResponse} the object
     */
    private getResponseData(responseData: Uint8Array): CashlogyResponse {
        const respStr = TilbyTcpSocketUtils.byteArrayToString(responseData);
        // Remove leading and trailing '#' and split response on '#'
        const respArr = respStr.slice(1, respStr.length - 2).split('#');

        return {
            status: respArr[0],
            resultData: respArr.slice(1)
        };
    };

    /**
     * Parses a cashlogy transaction response
     * @param {CashlogyResponse} responseData The cashlogy response
     * @param {number} amount The amount
     * @returns {CashlogyTransactionResponse} The transaction response
     */
    private parseTransactionResponse(responseData: CashlogyResponse, amount: number) {
        const result: CashlogyTransactionResponse = {
            status: responseData.status
        };

        const paidAmount = MathUtils.round(parseFloat(responseData.resultData[0]) / 100);
        const changeGiven = MathUtils.round(parseFloat(responseData.resultData[1]) / 100);
        const changeNotGiven = MathUtils.round(paidAmount - (amount + changeGiven));

        if (Number.isFinite(paidAmount) && Number.isFinite(changeGiven)) {
            result.toPay = amount;
            result.paid = paidAmount;
            result.changeGiven = changeGiven;
            result.changeNotGiven = changeNotGiven;
            result.currency = 'EUR';
        }

        return result;
    };

    /**
     * Initializes the device
     * @returns {Promise<void>} The initialization promise. If the device is already initialized, it will resolve immediately
     */
    private initializeDevice() {
        if (!this.initPromise) {
            this.initPromise = new Promise(async (resolve, reject) => {
                try {
                    this.status = 'INITIALIZING';
                    this.socket = TilbyTcpSocketUtils.getSocket();

                    if (!this.socket) {
                        return reject('UNSUPPORTED_DEVICE');
                    }

                    try {
                        await this.socket.connect(this.ipAddress!, this.options?.port || 8092, { timeout: 10000 });
                    } catch (err) {
                        throw 'CONNECTION_ERROR';
                    }

                    await this.sendAndWaitResponse(['I'], 1, 120000);

                    // Wait 2 seconds since some cashlogy devices require more time to initialize
                    await new Promise((resolve) => setTimeout(resolve, 2000));

                    this.status = 'INITIALIZED';

                    resolve();
                } catch (err) {
                    this.closeSocket();
                    reject(err);
                }
            });
        }

        return this.initPromise;
    };

    /**
     * Closes the cashlogy socket and resets the status
     * @returns {Promise<void>}
     */
    private async closeSocket() {
        if (!this.socket) {
            return;
        }

        const tmpSocket = this.socket;

        this.initPromise = undefined;
        this.socket = undefined;
        this.status = 'UNINITIALIZED';

        try {
            await tmpSocket.close();
        } catch (err) {
            //Do nothing
        }
    };

    /**
     * Opens the cashlogy backoffice
     * @returns {Promise<void>}
     */
    public async openBackoffice() {
        await this.initializeDevice();

        const backofficeCommand = [
            'G',
            this.configurationManager.isUserPermittedOptIn("cashdrawers.see_status") ? '1' : '0',
            this.configurationManager.isUserPermittedOptIn("cashdrawers.add_change") ? '1' : '0',
            this.configurationManager.isUserPermittedOptIn("cashdrawers.add_change_one_cent") ? '1' : '0',
            this.configurationManager.isUserPermittedOptIn("cashdrawers.remove_cash") ? '1' : '0',
            this.configurationManager.isUserPermittedOptIn("cashdrawers.remove_stacker") ? '1' : '0',
            this.configurationManager.isUserPermittedOptIn("cashdrawers.complete_empty") ? '1' : '0',
            this.configurationManager.isUserPermittedOptIn("cashdrawers.give_change") ? '1' : '0',
            this.configurationManager.isUserPermittedOptIn("cashdrawers.close_till") ? '1' : '0',
            this.configurationManager.isUserPermittedOptIn("cashdrawers.see_log") ? '1' : '0',
            this.configurationManager.isUserPermittedOptIn("cashdrawers.zero_coins") ? '1' : '0',
            this.configurationManager.isUserPermittedOptIn("cashdrawers.see_stats") ? '1' : '0',
            this.configurationManager.getPreference("cashdrawers.developer_mode") ? '0' : '1',
            this.configurationManager.isUserPermittedOptIn("cashdrawers.maintenance") ? '1' : '0',
            '1'
        ];

        try {
            await this.sendAndWaitResponse(backofficeCommand, 5);
        } finally {
            this.status = 'INITIALIZED';
        }
    }

    /**
     * Initializes the cashlogy driver
     * @param {string} ipAddress The cashlogy IP address
     * @param {CashlogyOptions} options The cashlogy options
     * @param {string} options.port The cashlogy port
     * @returns {Promise<void>}
     */
    public initialize(ipAddress: string, options?: CashlogyOptions) {
        if (this.ipAddress && ipAddress !== this.ipAddress) {
            throw 'IP_ADDRESS_MISMATCH';
        }

        this.ipAddress = ipAddress;
        this.options = options || {};
    }

    /**
     * Gets the payment command
     * @param amount The payment amount
     * @param trId The transaction id
     * @returns {string[]} The payment command
     */
    private getPaymentCommand(amount: number, trId: string) {
        return [
            'C',
            (++(this.operationIndex)).toString(),
            trId,
            Math.round(amount * 100).toString(),
            '0',
            '0',
            '0',
            '0',
            '0',
            this.configurationManager.getPreference("cashdrawers.developer_mode") ? '0' : '1',
            '0',
            '0'
        ];
    };

    /**
     * Gets the transaction id (a random string of 4 digits)
     * @returns {string} The transaction id
     */
    private getTransactionId() {
        return Array(4).fill(0).map(() => Math.floor(Math.random() * 10)).join('');
    };

    /**
     * Sends a cashlogy command and waits for a response
     * @param {string | string[]} message The cashlogy command
     * @param {number} fieldsExpected The number of fields expected in the response
     * @param {number} [timeout] The timeout in milliseconds
     * @returns {Promise<CashlogyResponse>} The cashlogy response
     */
    private async sendAndWaitResponse(message: string | string[], fieldsExpected: number, timeout?: number): Promise<CashlogyResponse> {
        return new Promise((resolve, reject) => {
            if (!this.socket) {
                return reject('DEVICE_NOT_INITIALIZED');
            }

            let reqTimeout = null;
            const packet = this.getPacket(message);

            const dataListener = this.socket.onData.subscribe((dataStream) => {
                if (reqTimeout) {
                    clearTimeout(reqTimeout);
                }

                const data = this.getResponseData(dataStream);

                switch (data.status) {
                    case 'ER:BUSY': case 'ER:ILLEGAL': case 'ER:BAD_DATA':
                        dataListener.unsubscribe();
                        reject(data.status.split(':')[data.status.length - 1]);
                        break;
                    default:
                        if (fieldsExpected == null || data.resultData.length === fieldsExpected) {
                            dataListener.unsubscribe();
                            resolve(data);
                        }
                        break;
                }
            });

            this.socket.send(packet);

            if (timeout) {
                setTimeout(() => {
                    dataListener.unsubscribe();
                    reject('REQ_TIMEOUT');
                }, timeout);
            }
        });

    };

    /**
     * Perform a payment
     * @param {number} amount The amount to pay
     * @returns {Promise<CashlogyTransactionResponse>} The payment result
     */
    public async performPayment(amount: number) {
        await this.initializeDevice();

        const transactionId = this.getTransactionId();
        const paymentCommand = this.getPaymentCommand(amount, transactionId);

        this.status = 'PAYING';

        try {
            const trResp = await this.sendAndWaitResponse(paymentCommand, 4)
            const transactionData = this.parseTransactionResponse(trResp, amount);

            switch (transactionData.status) {
                case '0':
                case 'WR:LEVEL':
                    return transactionData;
                case 'WR:CANCEL':
                    throw 'CANCELED';
                case 'ER:GENERIC':
                    if (transactionData.paid == null || transactionData.paid < amount) {
                        throw 'GENERIC';
                    }

                    return transactionData;
                default:
                    throw 'UNKNOWN';
            }
        } finally {
            this.status = 'INITIALIZED';
        }
    }

    /**
     * Get the current status of the cashdrawer.
     * @returns {string} The current device status.
     */
    public getStatus() {
        return this.status;
    }

    /**
     * Ends the current session.
     */
    public endSession() {
        this.sendAndWaitResponse(['E'], 0, 5000).finally(this.closeSocket);
    };
}

CashlogyDriver.$inject = ["checkManager"];

angular.module('digitalPayments').service('cashlogyDriver', CashlogyDriver);
import {
    EpsonAlign,
    EpsonBarcode,
    EpsonColor,
    EpsonCut,
    EpsonDrawer,
    EpsonFont,
    EpsonHRI,
    EpsonLevel,
    EpsonPulse,
    EpsonSymbol,
    NFSendResult,
    NFThermalPrinter,
    PrintSettings
} from 'src/app/shared/model/nf-thermal-printer.model';

import { TilbyTcpSocketUtils } from 'src/app/shared/net/tilby-tcp-socket';
import { EscPosEncoder } from './escpos-encoder';

interface NetworkSettings {
    ipAddress: string;
    port: number;
    connection?: 'sp' | 'ts';
}

/**
 * @class EscPosDriver
 * @implements {NFThermalPrinter}
 * Implements the NFThermalPrinter interface using ESC/POS commands.
 */
export class EscPosDriver implements NFThermalPrinter {
    networkSettings: NetworkSettings;
    printSettings: PrintSettings;
    lastPrintSettings: PrintSettings;
    escPosDoc: number[];
    codepageMapping: string | undefined;
    eReceiptMode: boolean;

    /**
     * Constructs a new EscPosDriver instance.
     * @param {string} ipAddress - IP address of the printer.
     * @param {number} [port=9100] - Port number of the printer.
     * @param {any} [configuration] - Optional configuration object with connection type and codepage mapping.
     */
    constructor(ipAddress: string, port: number = 9100, configuration?: any) {
        this.networkSettings = {
            ipAddress: ipAddress,
            port: port,
            connection: configuration?.connection_type
        };

        //Default style settings
        this.printSettings = {
            dw: false,
            dh: false,
            reverse: false,
            height: 1,
            width: 1,
            ul: false,
            em: false,
            linespc: configuration?.line_spacing ?? 0x50,
            color: EpsonColor.COLOR_1,
            align: EpsonAlign.LEFT
        };

        this.lastPrintSettings = { ...this.printSettings };

        this.escPosDoc = [
            0x1b, 0x3d, 0x01, //Set peripheral device
            0x1b, 0x40, //Reset printer
            0x1b, 0x33, this.printSettings.linespc //Set line spacing
        ];

        this.codepageMapping = configuration?.codepage_mapping;
        this.eReceiptMode = !!(configuration?.eReceiptMode);
    }

    /**
     * Establishes a connection with the printer and sends the document.
     * @param {NetworkSettings} target - Network settings of the printer.
     * @param {number[]} docArray - Array of ESC/POS commands to send.
     * @returns {Promise<void>} A promise that resolves when the document is sent successfully.
     * @throws {string} 'UNSUPPORTED_PLATFORM' if the platform doesn't support serial ports or TCP sockets.
     * @throws {string} 'CONNECTION_ERROR' if there is a problem with the connection.
     */
    private static async connectAndSend(target: NetworkSettings, docArray: number[]): Promise<void> {
        if (target.ipAddress === '0.0.0.0') {
            return;
        }

        switch (target.connection) {
            case 'sp': //Serial port
                if (!window.require) {
                    throw 'UNSUPPORTED_PLATFORM';
                }

                const { SerialPort } = window.require('serialport');
                const targetPort = new SerialPort({ path: target.ipAddress, baudRate: 9600, autoOpen: false });

                return new Promise((resolve, reject) => {
                    targetPort.open((err: any) => {
                        if (err) {
                            return reject('CONNECTION_ERROR');
                        }

                        targetPort.write(docArray, (err: any) => {
                            if (err) {
                                reject('CONNECTION_ERROR');
                            } else {
                                resolve();
                            }

                            targetPort.close();
                        });
                    });
                });
                break;
            case 'ts': //TCP Socket (Default)
            default:
                const tcpSocket = TilbyTcpSocketUtils.getSocket();

                if (!tcpSocket) {
                    throw 'UNSUPPORTED_PLATFORM';
                }

                try {
                    await tcpSocket.connect(target.ipAddress, target.port, { timeout: 3000 });
                    await tcpSocket.send(Uint8Array.from(docArray));
                } catch (error) {
                    throw 'CONNECTION_ERROR';
                } finally {
                    tcpSocket.close();
                }
                break;
        }
    };

    /**
     * Encodes text to ESC/POS commands based on the provided codepage mapping.
     * @param {string} text - Text to be encoded.
     * @param {string | undefined} codepageMapping - Codepage mapping to use for encoding.
     * @returns {number[]} Array of ESC/POS commands representing the encoded text.
     */
    private encodeText(text: string, codepageMapping: string | undefined): number[] {
        const result: number[] = [];
        const encodedData = EscPosEncoder.encode(text, codepageMapping);

        for (const set of encodedData) {
            result.push(0x1b, 0x74, set.codepageCommand, ...set.bytes);
        }

        return result;
    };

    /**
     * Generates ESC/POS command for setting line spacing.
     * @param {number} linespc - Line spacing value.
     * @returns {number[]} Array of ESC/POS commands.
     */
    private getLineSpace(linespc: number): number[] {
        return [0x1b, 0x33, linespc];
    };

    /**
     * Generates ESC/POS command for setting print mode (double width, double height, emphasis, underline).
     * @param {boolean} dw - Double width flag.
     * @param {boolean} dh - Double height flag.
     * @param {boolean} em - Emphasis flag.
     * @param {boolean} ul - Underline flag.
     * @returns {number[]} Array of ESC/POS commands.
     */
    private setPrintMode(dw: boolean, dh: boolean, em: boolean, ul: boolean): number[] {
        return [0x1b, 0x21, ((ul ? 128 : 0) + (dw ? 32 : 0) + (dh ? 16 : 0) + (em ? 8 : 0))];
    };

    /**
    * Generates ESC/POS command for setting reverse printing mode.
    * @param {boolean} reverse - Reverse print flag.
    * @returns {number[]} Array of ESC/POS commands.
    */
    private getReverse(reverse: boolean): number[] {
        return [0x1d, 0x42, (reverse ? 1 : 0)];
    };

    /**
     * Generates ESC/POS command for setting character size (width and height).
     * @param {number} width - Width of the characters.
     * @param {number} height - Height of the characters.
     * @returns {number[]} Array of ESC/POS commands.
     */
    private getSizeCommands(width: number, height: number): number[] {
        return [0x1d, 0x21, ((height - 1) * 16) + (width - 1)];
    };

    /**
     * Generates ESC/POS command for setting text alignment.
     * @param {EpsonAlign} align - Text alignment option.
     * @returns {number[]} Array of ESC/POS commands.
     */
    private getAlignment(align: EpsonAlign): number[] {
        switch (align) {
            case EpsonAlign.CENTER:
                return [0x1b, 0x61, 0x31];
            case EpsonAlign.LEFT:
                return [0x1b, 0x61, 0x30];
            case EpsonAlign.RIGHT:
                return [0x1b, 0x61, 0x32];
            default:
                return [0x1b, 0x61, 0x30];
        }
    };

    /**
     * Adds text to the document.
     * @param {string} text - Text to be added.
     */
    public addText(text: string): void {
        if (this.eReceiptMode) {
            return;
        }

        if (this.printSettings.dw !== this.lastPrintSettings.dw || this.printSettings.dh !== this.lastPrintSettings.dh || this.printSettings.em !== this.lastPrintSettings.em || this.printSettings.ul !== this.lastPrintSettings.ul) {
            this.escPosDoc.push(...this.setPrintMode(this.printSettings.dw, this.printSettings.dh, this.printSettings.em, this.printSettings.ul));
        }
        if (this.printSettings.width !== this.lastPrintSettings.width || this.printSettings.height !== this.lastPrintSettings.height) {
            this.escPosDoc.push(...this.getSizeCommands(this.printSettings.width, this.printSettings.height));
        }
        if (this.printSettings.align !== this.lastPrintSettings.align) {
            this.escPosDoc.push(...this.getAlignment(this.printSettings.align));
        }
        if (this.printSettings.linespc !== this.lastPrintSettings.linespc) {
            this.escPosDoc.push(...this.getLineSpace(this.printSettings.linespc));
        }
        if (this.printSettings.reverse !== this.lastPrintSettings.reverse) {
            this.escPosDoc.push(...this.getReverse(this.printSettings.reverse));
        }

        this.escPosDoc.push(...this.encodeText(text, this.codepageMapping));
        this.lastPrintSettings = { ...this.printSettings };
    };

    /**
     * Sets double width and double height for text.
     * @param {boolean} [dw] - Double width flag.
     * @param {boolean} [dh] - Double height flag.
     */
    public addTextDouble(dw?: boolean, dh?: boolean): void {
        this.printSettings.dw = typeof dw === 'boolean' ? dw : this.printSettings.dw;
        this.printSettings.dh = typeof dh === 'boolean' ? dh : this.printSettings.dh;
    };

    /**
     * Sets the size of the text.
     * @param {number} [w] - Width of the text (1-8).
     * @param {number} [h] - Height of the text (1-8).
     */
    public addTextSize(w?: number, h?: number): void {
        this.printSettings.width = w && Number.isInteger(w) && (w >= 1 && w <= 8) ? w : this.printSettings.width;
        this.printSettings.height = h && Number.isInteger(h) && (h >= 1 && h <= 8) ? h : this.printSettings.height;
    };

    /**
     * Adds a logo to the document.
     * @param {number} key1 - First key for logo selection (0-255).
     * @param {number} key2 - Second key for logo selection (0-255).
     */
    public addLogo(key1: number, key2: number): void {
        if (this.eReceiptMode) {
            return;
        }

        this.escPosDoc.push(0x1d, 0x28, 0x4c, 0x06, 0x00, 0x30, 0x45, ((key1 >= 0 && key1 <= 255) ? key1 : 0x20), ((key2 >= 0 && key2 <= 255) ? key2 : 0x20), 0x01, 0x01);
    };

    /**
    * Sets the text alignment.
    * @param {EpsonAlign} align - Text alignment option.
    */
    public addTextAlign(align: EpsonAlign): void {
        if (Object.values(EpsonAlign).includes(align)) {
            this.printSettings.align = align;
        }
    };

    /**
     * Sets the line spacing for text.
     * @param {number} linespc - Line spacing value.
     */
    public addTextLineSpace(linespc: number): void {
        this.printSettings.linespc = linespc;
    };

    /**
     * Sets the text style (reverse, underline, emphasis, color).
     * @param {boolean} [reverse] - Reverse print flag.
     * @param {boolean} [ul] - Underline flag.
     * @param {boolean} [em] - Emphasis flag.
     * @param {EpsonColor} [color] - Text color option.
     */
    public addTextStyle(reverse?: boolean, ul?: boolean, em?: boolean, color?: EpsonColor): void {
        this.printSettings.reverse = typeof reverse === 'boolean' ? reverse : this.printSettings.reverse;
        this.printSettings.ul = typeof ul === 'boolean' ? ul : this.printSettings.ul;
        this.printSettings.em = typeof em === 'boolean' ? em : this.printSettings.em;
        this.printSettings.color = (Object.values(EpsonColor).includes(color!) ? color : EpsonColor.COLOR_1) as EpsonColor;
    };


    /**
     * Gets the barcode type code.
     * @param {string} type - Barcode type.
     * @returns {number} Barcode type code.
     */
    private getBarcodeTypeCode(type: string): number {
        switch (type) {
            case EpsonBarcode.UPC_A:
                return 0x41;
            case EpsonBarcode.UPC_E:
                return 0x42;
            case EpsonBarcode.EAN13:
            case EpsonBarcode.JAN13:
                return 0x43;
            case EpsonBarcode.EAN8:
            case EpsonBarcode.JAN8:
                return 0x44;
            case EpsonBarcode.CODE39:
                return 0x45;
            case EpsonBarcode.ITF:
                return 0x46;
            case EpsonBarcode.CODABAR:
                return 0x47;
            case EpsonBarcode.CODE93:
                return 0x48;
            case EpsonBarcode.CODE128:
                return 0x49;
            case EpsonBarcode.GS1_128:
                return 0x4a;
            case EpsonBarcode.GS1_DATABAR_OMNIDIRECTIONAL:
                return 0x4b;
            case EpsonBarcode.GS1_DATABAR_TRUNCATED:
                return 0x4c;
            case EpsonBarcode.GS1_DATABAR_LIMITED:
                return 0x4d;
            case EpsonBarcode.GS1_DATABAR_EXPANDED:
                return 0x4e;
            default:
                return 0x45; //CODE39
        }
    };

    /**
     * Gets the HRI (Human Readable Interpretation) code.
     * @param {string} hri - HRI position.
     * @returns {number} HRI code.
     */
    private getHRICode(hri: string): number {
        switch (hri) {
            case EpsonHRI.NONE:
                return 0x00;
            case EpsonHRI.ABOVE:
                return 0x01;
            case EpsonHRI.BELOW:
                return 0x02;
            case EpsonHRI.BOTH:
                return 0x03;
            default:
                return 0x00;
        }
    };

    /**
     * Gets the font code.
     * @param {string} font - Font type.
     * @returns {number} Font code.
     */
    private getFontCode(font: string): number {
        switch (font) {
            case EpsonFont.FONT_A:
                return 0x00;
            case EpsonFont.FONT_B:
                return 0x01;
            case EpsonFont.FONT_C:
                return 0x02;
            case EpsonFont.FONT_SPECIAL_A:
                return 0x61;
            case EpsonFont.FONT_SPECIAL_B:
                return 0x62;
            default:
                return 0x00;
        }
    };

    /**
    * Gets the QR code correction level code.
    * @param {string} level - Error correction level.
    * @returns {number} QR code correction level code.
    */
    private getQrCodeCorrectionLevel(level: string): number {
        switch (level) {
            case EpsonLevel.LEVEL_L:
                return 0x30;
            case EpsonLevel.LEVEL_M:
                return 0x31;
            case EpsonLevel.LEVEL_Q:
                return 0x32;
            case EpsonLevel.LEVEL_H:
                return 0x33;
            case EpsonLevel.LEVEL_DEFAULT:
                return 0x30;
            default:
                return 0x30;
        }
    };

    /**
     * Adds a barcode to the document.
     * @param {string} data - Barcode data.
     * @param {EpsonBarcode} type - Barcode type.
     * @param {EpsonHRI} [hri] - HRI position.
     * @param {EpsonFont} [font] - Font for HRI.
     * @param {number} [width] - Width of barcode.
     * @param {number} [height] - Height of barcode.
     */
    public addBarcode(data: string, type: EpsonBarcode, hri?: EpsonHRI, font?: EpsonFont, width?: number, height?: number): void {
        if (this.eReceiptMode) {
            return;
        }

        if (hri) {
            this.escPosDoc.push(...[0x1d, 0x48, this.getHRICode(hri)]);
        }

        if (font) {
            this.escPosDoc.push(...[0x1d, 0x66, this.getFontCode(font)]);
        }

        if (width && width > 0 && width < 256) {
            this.escPosDoc.push(...[0x1d, 0x77, width]);
        }

        if (height && height > 0 && height < 256) {
            this.escPosDoc.push(...[0x1d, 0x68, height]);
        }

        this.escPosDoc.push(...[0x1d, 0x6b, this.getBarcodeTypeCode(type), data.length, ...data.split('').map((l) => l.charCodeAt(0))]);
    };

    /**
     * Adds a symbol (like QR code) to the document.
     * @param {string} data - Symbol data.
     * @param {EpsonSymbol} type - Symbol type.
     * @param {EpsonLevel} [level] - Error correction level.
     * @param {number} [width] - Width of symbol.
     * @param {number} [height] - Height of symbol (not used in QR codes).
     * @param {number} [size] - Size of symbol (not used in QR codes).
     */
    public addSymbol(data: string, type: EpsonSymbol, level?: EpsonLevel, width?: number, height?: number, size?: number): void {
        if (this.eReceiptMode) {
            return;
        }

        const symbolCommands = [0x1d, 0x28, 0x6b];
        const dataSize = data.length;

        let typeCode: number = 0;
        let functionParams: { [key: number]: number[] } = {};

        switch (type) {
            case EpsonSymbol.QRCODE_MODEL_1:
                typeCode = 0x31;
                functionParams = { 0x41: [0x31, 0x00] };
                break;
            case EpsonSymbol.QRCODE_MODEL_2:
                typeCode = 0x31;
                functionParams = { 0x41: [0x32, 0x00] };
                break;
            case EpsonSymbol.QRCODE_MICRO:
                typeCode = 0x31;
                functionParams = { 0x41: [0x33, 0x00] };
                break;
        }

        switch (typeCode) {
            case 0x31: //QR Code
                this.escPosDoc.push(...symbolCommands, 0x04, 0x00, typeCode, 0x41, ...functionParams[0x41]); //Select QR Code model
                this.escPosDoc.push(...symbolCommands, 0x03, 0x00, typeCode, 0x43, width || 0); //Set size (use width parameter for matching the ePos API)
                this.escPosDoc.push(...symbolCommands, 0x03, 0x00, typeCode, 0x45, this.getQrCodeCorrectionLevel(level || 'default')); //Set correction level
                this.escPosDoc.push(...symbolCommands, (dataSize % 256) + 3, Math.floor(dataSize / 256), typeCode, 0x50, 0x30, ...(data.split('').map((l) => l.charCodeAt(0)))); //Store the QR Code data
                this.escPosDoc.push(...symbolCommands, 0x03, 0x00, typeCode, 0x51, 0x30); //Print the QR Code
                break;
        }
    };

    /**
     * Adds a cut command to the document.
     * @param {EpsonCut} [type] - Cut type.
     */
    public addCut(type?: EpsonCut): void {
        if (this.eReceiptMode) {
            return;
        }

        let typeCode: number;

        switch (type) {
            case EpsonCut.FEED:
                typeCode = 0x42;
                break;
            case EpsonCut.NO_FEED:
                typeCode = 0x31;
                break;
            case EpsonCut.RESERVE:
                typeCode = 0x62;
                break;
            default:
                typeCode = 0x42;
        }

        this.escPosDoc.push(0x0a, 0x0d, 0x0a, 0x0d, 0x1d, 0x56, typeCode);

        if (typeCode !== 49) { //FEED and RESERVE require an additional parameter
            this.escPosDoc.push(0x00);
        }
    };

    /**
     * Adds a pulse command to activate a cash drawer.
     * @param {EpsonDrawer} [drawer] - Drawer number (1 or 2).
     * @param {EpsonPulse} [time] - Pulse time.
     */
    public addPulse(drawer?: EpsonDrawer, time?: EpsonPulse): void {
        let drawerCode: number;
        let pulseCode: number;

        switch (drawer) {
            case EpsonDrawer.DRAWER_1:
                drawerCode = 0;
                break;
            case EpsonDrawer.DRAWER_2:
                drawerCode = 1;
                break;
            default:
                drawerCode = 0;
        }

        switch (time) {
            case EpsonPulse.PULSE_100:
                pulseCode = 50;
                break;
            case EpsonPulse.PULSE_200:
                pulseCode = 100;
                break;
            case EpsonPulse.PULSE_300:
                pulseCode = 150;
                break;
            case EpsonPulse.PULSE_400:
                pulseCode = 200;
                break;
            case EpsonPulse.PULSE_500:
                pulseCode = 250;
                break;
            default:
                pulseCode = 250;
        }

        this.escPosDoc.push(0x1b, 0x70, drawerCode, pulseCode, pulseCode);

        //Add RCH internal pulse
        this.escPosDoc.push(0x1b, 0x42, 0x01, 0x09);
    };

    /**
     * Sends the document to the printer.
     * @returns {Promise<NFSendResult>} A promise that resolves with the result of the print operation.
     */
    public async send(): Promise<NFSendResult> {
        return EscPosDriver.connectAndSend(this.networkSettings, this.escPosDoc).then(
            () => <NFSendResult>'PRINTED',
            (err: NFSendResult) => this.eReceiptMode ? 'PRINTED' : err
        );
    };

    /**
     * Attempts to connect to a printer.
     * @param {string} ip - IP address of the printer.
     * @param {number} port - Port number of the printer.
     * @returns {Promise<{ip: string, connected: boolean}>} A promise that resolves with the connection attempt result.
     */
    public static async connectAttempt(ip: string, port: number): Promise<{ ip: string, connected: boolean }> {
        if (ip === '0.0.0.0') {
            return { ip: ip, connected: true };
        }

        let connected = false;

        const tcpSocket = TilbyTcpSocketUtils.getSocket();

        if (tcpSocket) {
            try {
                await tcpSocket.connect(ip, port, { timeout: 1000 });
                connected = true;
            } catch (error) {
                //do nothing
            } finally {
                tcpSocket.close();
            }
        }

        return { ip: ip, connected: connected };
    };

    /**
    * Displays text on a specified printer's display.
    * @param {any} printer - Object containing printer connection info.
    * @param {string[]} textLines - Array of text lines to display.
    * @returns {Promise<void>} A promise that resolves when the text is sent successfully.
    */
    static async displayText(printer: any, textLines: string[]): Promise<void> {
        const docToSend = [0x1b, 0x3d, 0x02, 0x1b, 0x40, 0x0c, 0x0b];

        const printerInfo = {
            ipAddress: printer.ip_address,
            port: printer.port || 9100,
            connection: printer.connection_type
        };

        for (const line of textLines) {
            docToSend.push(...this.prototype.encodeText(line, printer.configuration?.codepage_mapping || 'epson'));
        }

        return this.connectAndSend(printerInfo, docToSend);
    };
}
import angular from 'angular';
import _ from 'lodash';

angular.module('application').factory('radioListSelector', ["$mdDialog", function($mdDialog) {
    radioListSelectorController.$inject = ["$scope", "$translate", "elements", "options"];
    function radioListSelectorController($scope, $translate, elements, options) {
        if(!_.isObject(options)) {
            options = {};
        }

        $scope.addAction = options.addAction;
        $scope.hideCancel = options.hideCancel;

        $scope.label = options.label || $translate.instant('APPLICATION.RADIO.SELECT_OPTION');

        $scope.elements = !_.isEmpty(elements) ? _.clone(elements) : [];
        $scope.selectedElement = _.isInteger(options.default) ? $scope.elements[options.default] : undefined;

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.executeAction = function(action) {
            if(_.isFunction(action.callback)) {
                $mdDialog.cancel().finally(function() {
                    action.callback();
                });
            }
        };

        $scope.confirm = function() {
            $mdDialog.hide($scope.selectedElement);
        };
    }

    var radioListSelector = {
        show: function(elements, options) {
            return $mdDialog.show({
                controller: radioListSelectorController,
                template: require('./radio-list-selector.html'),
                locals: {
                    elements: elements,
                    options: options
                }
            });
        }
    };

    return radioListSelector;
}]);

import {
    Component,
    inject,
    Injectable,
    OnInit
} from "@angular/core";
import {
    FormControl,
    FormsModule,
    Validators
} from "@angular/forms";
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef
} from "@angular/material/dialog";
import { CommonModule } from "@angular/common";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { ConfigurationManagerService } from "src/app/core/services/configuration-manager/configuration-manager.service";
import { OpenDialogsService } from "../services";
import { DataGridDownloadDialog } from "../dialog.model";
import { restManager } from "../../../../app/ajs-upgraded-providers";
import { DevLogger } from "src/app/shared/dev-logger";
import {
    BaseDialogService,
    NonNullableConfigData,
    TilbyDialogContentComponent,
    TilbyDialogToolbarComponent
} from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { lastValueFrom } from "rxjs";
import {
    ColumnDataExport,
    TableData
} from "../../shared/model/model";
import { Feature } from "../../models";

@Component({
    standalone: true,
    selector: 'app-grid-download-dialog',
    templateUrl: './grid-download-dialog.component.html',
    styleUrls: ['./grid-download-dialog.component.scss'],
    imports: [CommonModule, TilbyDialogToolbarComponent, TilbyDialogContentComponent, MatProgressSpinner, MatFormFieldModule, MatIconModule, TranslateModule, MatCheckboxModule, FormsModule, MatButtonModule, MatInputModule]
})
  export class GridDownloadDialogComponent implements OnInit{
    private readonly configurationManager= inject( ConfigurationManagerService);
    protected readonly restManagerService= inject( restManager);
    public readonly dialogRef= inject( MatDialogRef<OpenDialogsService>);
    public readonly data:DataGridDownloadDialog= inject(MAT_DIALOG_DATA);

    openColumns = false;
    selectFormControl = new FormControl('', Validators.required);
    rowCount?:number;

    private log(...args: any[]){
        DevLogger.log('GRID_DOWNLOAD_DIALOG_COMPONENT',...args);
    }

    ngOnInit() {
        let columns : string[];
        try {
            columns = JSON.parse(this.configurationManager.getPreference(`grid.${this.data.feature}.export`) || '').columns;
            this.data.column.map(col => col.isChecked = !!columns.includes(col.colField));
        } catch(err) {
            this.data.column.map(col => col.isChecked = true);
        }
    }

    async ngAfterViewInit(){
        this.rowCount= this.data.countSelectedRows || (await this.getRowCount());
    }

    private async getRowCount(){
        try{
            const res = (await this.restManagerService.post(this.data.previewUrl, undefined, {...this.data.sortFilterQuery,...this.data.extraQueryParams}))?.count;
            this.log('MASSIVE_EXPORT_PREVIEW',res);
            return res;
        } catch(err){
            return undefined;
        }

    }


    export() {
        this.dialogRef.close({data: this.data.column.filter(col=>col.isChecked)});
    }

    setAllCheckbox(checked: boolean) {
        this.data.column.map(col => col.isChecked = checked);
    }

    isAllColumnsUnchecked() {
        return !this.data.column.some(obj => obj.isChecked);
    }

    openColumnsAction() {
        this.openColumns = !this.openColumns;
    }

    isHeaderConfiguration(headerKey : string) {
        return this.data.headersTranslate.get(headerKey)?.substring(0,2) === '-s';
    }

    getHeaderConfiguration(headerKey: string) {
        let headerIdentifier = this.data.headersTranslate.get(headerKey);
        return this.configurationManager.getSetting(headerIdentifier?.split(" ",2)[1]||'' as any);
    }

    hasHeader(headerKey : string) {
        return !(this.isHeaderConfiguration(headerKey) && !this.getHeaderConfiguration(headerKey));
    }
}

@Injectable({
    providedIn: "root",
})
export class GridDownloadDialogService extends BaseDialogService {
    private readonly dialogRef = inject(MatDialog);

    public async openDialog(feature: Feature,config:NonNullableConfigData<TableData[]>,tableToCall?:string) {
        const tableData=config.data||[];
        const column: ColumnDataExport[] = Object.keys(tableData[0]?.dataType).map(colField => ({
            colField,
            isChecked: false
        }));
        const headersTranslate = tableData[0]?.headersTranslate;
        const data: DataGridDownloadDialog = {column, headersTranslate, feature, typeExport:'csv', previewUrl: `massive_export/preview?table=${tableToCall||feature}`,extraQueryParams:tableData[0].extraQueryParams,sortFilterQuery:tableData[0]?.sortFilterQuery,countSelectedRows:tableData[0]?.countSelectedRows};
        const configEdited: NonNullableConfigData<DataGridDownloadDialog> = {
            ...config,
            ...this.switchMobileDesktopDimensions({width:'auto'}),
            disableClose: true,
            data,
        };
        return await lastValueFrom(
            this.dialogRef.open(GridDownloadDialogComponent, configEdited).afterClosed()
                );
    }
}

import angular from 'angular';
import * as async from 'async';
import _ from 'lodash';
import moment from 'moment-timezone';

angular.module('printers').factory('printerUpgradeDialog', ["$rootScope", "$mdDialog", "$translate", "$http", "$timeout", "FiscalPrinters", "printerErrorFiscal", function($rootScope, $mdDialog, $translate, $http, $timeout, FiscalPrinters, printerErrorFiscal) {
    printerUpgradeDialogController.$inject = ["$scope", "printer"];

    function printerUpgradeDialogController($scope, printer) {
        $scope.printerStatus = {};
        $scope.latestVersion = {};
        $scope.fwStatus = null;
        $scope.updateProgress = 0;
        $scope.progressMode = 'indeterminate';

        var updateStart;
        var updateEnd;

        var getFiscalPrinterError = function(error) {
            $scope.errorMessage = printerErrorFiscal.translateError(error);
            $scope.fwStatus = 'ERROR';
        };

        var checkUpdateProgress = function() {
            var now = moment();

            async.series([
                function(cb) { //Printer ping function
                    if(now - updateStart >= 300000) { //5 minutes have passed, start checking if the printer has finished updating
                        FiscalPrinters.getPrinterStatus(printer, { getFiscalStatus: true }).then(function(printerStatus) {
                            if(_.get(printerStatus, "printer_serial")) {
                                _.set(printerStatus, "shop", $rootScope.userActiveSession.shop.name);
                                $http.post("https://printerremotemanager.scloby.com/ps/", printerStatus);
                            }

                            cb(true); //Printer is back online
                        }, function(err) {
                            cb(null); //Printer is still in update mode
                        });
                    } else {
                        cb();
                    }
                },
                function(cb) { //Progress updater
                    if(now > updateEnd) { //If the printer should be already updated by now
                        $scope.errorMessage = $translate.instant('PRINTERS.PRINTER_UPGRADE_DIALOG.UPDATE_FAILED');
                        $scope.fwStatus = 'ERROR';
                    } else {
                        $scope.updateProgress = (now - updateStart) / (updateEnd - updateStart) * 100;
            
                        if($scope.fwStatus === 'UPDATING') {
                            $timeout(checkUpdateProgress, 10000);
                        }
                    }
                    cb(null);
                }
            ], function(finished, status) {
                if(finished) {
                    delete $scope.fwStatus;
                    $scope.updateCheck();
                }
            });
        };

        $scope.updateCheck = function() {
            if($scope.fwStatus !== 'UPDATING') {
                $scope.printerStatus = {};
                $scope.latestVersion = {};
                delete $scope.fwStatus;
    
                FiscalPrinters.getPrinterStatus(printer).then(function(status) {
                    $scope.printerStatus = status;
                }).then(function() {
                    return $http.get('https://printerupgrade.scloby.com/' + printer.driver + '/authorization/', {
                        params: {
                            envirorement: $rootScope.userActiveSession.shop.name,
                            number: _.replace($scope.printerStatus.printer_serial, ' ', '')
                        }
                    }).then(function(latestVersion) {
                        $scope.latestVersion = latestVersion.data;
                    });
                }).then(function() {
                    $scope.fwStatus = (_.toFinite($scope.latestVersion.last_firmware) > _.toFinite($scope.printerStatus.cpuRel)) ? 'UPDATE_AVAILABLE' : 'UP_TO_DATE';
                }).catch(getFiscalPrinterError);
            }
        };

        $scope.performUpdate = function() {
            if($scope.fwStatus === 'UPDATE_AVAILABLE') {
                //Get update authotization and credentials
                $scope.fwStatus = 'UPDATING';
                $scope.progressMode = 'indeterminate';

                //Send update request to printer
                FiscalPrinters.sendFwUpgradeRequest(printer, _.cloneDeep($scope.latestVersion)).then(function() {
                    //Wait for printer to update
                    var updateTime = _.toFinite($scope.latestVersion.firmware_upgrade_time);

                    updateStart = moment();
                    updateEnd = moment(updateStart).add(updateTime, 'minutes');
                    $scope.progressMode = 'determinate';
                    $scope.updateProgress = 0;

                    $timeout(checkUpdateProgress, 10000);
                }, getFiscalPrinterError);
            }
        };

        $scope.cancel = function() {
            if($scope.fwStatus !== 'UPDATING') {
                $mdDialog.cancel();
            }
        };

        $scope.updateCheck();
    }

    var printerUpgradeDialog = {
        show: function(printer) {
            return $mdDialog.show({
                controller: printerUpgradeDialogController,
                template: require('./printer-upgrade-dialog.html'),
                locals: {
                    printer: printer
                }
            });
        }
    };

    return printerUpgradeDialog;
}]);
import angular from 'angular';
import _ from 'lodash';

angular.module('application').factory('eatsreadyApi', ["restManager", function(restManager) {
    var eatsreadyApi = {
        setupShop: function(email, password) {
            return restManager.post('/eatsready/setup', { email: email, password: password });
        },
        getNewPayments: function() {
            return restManager.getList('/eatsready/orders/received', { status: 'new', typology: 'paynow' });
        },
        acceptPayment: function(payment) {
            return restManager.put('/eatsready/orders', payment.id, { status: 'delivered' });
        }
    };

    return eatsreadyApi;
}]);
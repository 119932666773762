import angular from 'angular';

angular.module('giftcard', ['application']);

angular.module('giftcard').config(["$stateProvider", "$translatePartialLoaderProvider", function($stateProvider: any, $translatePartialLoaderProvider: any) {
    $translatePartialLoaderProvider.addPart('giftcard');

    $stateProvider.state('app.new.giftcard', {
        url: '/giftcard',
        redirectTo: 'app.new.giftcard.showcase',
    });

    $stateProvider.state('app.new.giftcard.showcase', {
        url: '/showcase',
        component: 'giftcardShowcase'
    });

    $stateProvider.state('app.new.giftcard.types-panel', {
        url: '/types-panel',
        component: 'giftcardTypesPanel'
    });

    /* Add New States Above */
}]);

import angular from 'angular';
import _ from 'lodash';

angular.module('printers').factory('dailyClosingDialog', dailyClosingDialog);

dailyClosingDialog.$inject = ["$mdDialog", "checkManager", "documentPrinter"];

function dailyClosingDialog($mdDialog, checkManager, documentPrinter) {
    dailyClosingDialogController.$inject = ["$scope", "$mdDialog", "printer"];

    function dailyClosingDialogController($scope, $mdDialog, printer) {
        $scope.verifyCashRequired = checkManager.getPreference('printers.verify_cash_on_dailyclousure');
        $scope.printer = printer;

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.answer = function() {
            if($scope.cashVerification || !$scope.verifyCashRequired) {
                $mdDialog.hide({
                    cash_verification: $scope.cashVerification
                });
            }
        };
    }

    const dailyClosingDialog = {
        show: async (printer) => {
            let result = await $mdDialog.show({
                controller: dailyClosingDialogController,
                template: require('./daily-closing-dialog.html'),
                locals: {
                    printer: printer
                },
                multiple: true
            });

            return documentPrinter.dailyClosing(printer, result);
        }
    };

    return dailyClosingDialog;
}

import angular from 'angular';
import {EntityManagerService} from "../../../src/app/core/services";

angular.module('dashboard', ['application']).config(["$stateProvider", "$translatePartialLoaderProvider", function($stateProvider: any, $translatePartialLoaderProvider: any) {
    $translatePartialLoaderProvider.addPart('dashboard');

    $stateProvider.state('app.new.dashboard', {
        url: '/dashboard',
        template: '<dashboard [deliverychannels]="$resolve.deliveryChannels"></dashboard>',
        params: {
          remove: {dynamic: true, value: null}
        },
        resolve: {
            deliveryChannels: ["entityManager", function(entityManagerService: EntityManagerService) {
                return entityManagerService.channels.fetchCollectionOffline();
            }]
        }
    });
}]);

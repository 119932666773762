import angular from 'angular';
import _ from 'lodash';

angular.module('history').directive('historyFiltersInvoicesPassive', function () {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            context: "="
        },
		template: require('./history-filters-invoices-passive.html'),
        controller: ["$scope", "$element", "$attrs", "$translate", "$timeout", "connection", "offlineDialog", "alertDialog", function ($scope, $element, $attrs, $translate, $timeout, connection, offlineDialog, alertDialog) {
            $scope.filterChips = [];
            $scope.filterChipsDevice = [];
            $scope.inputChips = [];
            $scope.context.selected = [];
            $scope.separatorKeys = [13, 188];
            $scope.isConnectionOffline = function () {
                return connection.isOffline();
            };

            $scope.openOfflineDialog = function () {
                offlineDialog.show();
            };
            $scope.updateDateRange = function () {
                $scope.context.selected_card = null;
                if ($scope.context.dateFilter.from && $scope.context.dateFilter.to) {
                    if ($scope.context.dateFilter.from <= $scope.context.dateFilter.to) {
                        $scope.context.changeQueryStatus();
                    } else {
                        alertDialog.show("Intervallo di date non valido");
                    }
                } else {
                    $scope.context.changeQueryStatus();
                }
            };
            $scope.filterByContent = function () {
                $scope.context.contentFilter = $scope.context.filters.content;
                $scope.context.mode = "filter";
                $scope.context.filterType = "content";
                $timeout(function () {
                    //this seems not working
                    angular.element("#contentFilterInput").trigger("focus");
                });
            };
            $scope.context.applyFilter = function () {
                switch ($scope.context.filterType) {
                    case 'content':
                        $scope.context.filters.content = _.map($scope.inputChips, function (value) {
                            return value.word;
                        });
                        break;
                    default:
                        break;
                }
                $scope.context.mode = "showcase";

                $scope.context.filters.chips = $scope.inputChips;
                $scope.buildFilterChips();
                $scope.context.changeQueryStatus();
            };
            $scope.addChipToFilter = function (chipInput) {
                return { word: chipInput, typeChip: "word" };
            };

            $scope.searchForEnter = function (event) {
                if (event.keyCode === 13) {
                    $scope.context.applyFilter();
                }
            };

            $scope.abortFilter = function () {
                switch ($scope.context.filterType) {
                    case 'content':
                        break;
                    case 'customer':
                        $scope.context.customerFilter = "";
                }
            };

            $scope.cancelFilter = function () {
                $scope.context.mode = "showcase";
                $scope.context.changeQueryStatus();
            };
            $scope.chipRemoved = function (chip) {
                $timeout(function () {
                    switch (chip.id) {
                        case 'content':
                            $scope.context.filters.content = _.reject($scope.context.filters.content, function (value) {
                                return value === chip.value;
                            });
                            $scope.inputChips = _.map($scope.context.filters.content, function (value) {
                                return { word: value };
                            });
                            $scope.context.changeQueryStatus();
                            break;
                        case 'daLeggere':
                            $scope.context.filters.upperFilter = null;
                            $scope.context.changeQueryStatus();
                            break;
                        case 'inScadenza':
                            $scope.context.filters.upperFilter = null;
                            $scope.context.changeQueryStatus();
                            break;
                        case 'scadute':
                            $scope.context.filters.upperFilter = null;
                            $scope.context.changeQueryStatus();
                            break;
                        default:
                            break;

                    }
                    $scope.buildFilterChips();
                    //$scope.context.applyFilters();
                });
            };
            $scope.buildFilterChips = function () {
                $scope.filterChips = [];
                $scope.filterChipsDevice = [];
                _.each($scope.context.filters, function (filter, key) {
                    switch (key) {
                        case 'content':
                            if (filter.length) {
                                _.forEach(filter, function (value) {
                                    $scope.filterChips.push({ id: "content", value: value });
                                    $scope.filterChipsDevice.push({ id: "content", value: value });
                                });
                            }
                            break;
                        case 'upperFilter':
                            if (filter === "daLeggere" || filter === "inScadenza" || filter === "scadute") {
                                $scope.context.filters.upperFilter = filter;
                                $scope.filterChips.push({ id: filter, value: filter });
                            }
                            break;
                        default:
                            break;
                    }
                });
            };

        }]
    };
});

import _ from "lodash";

import {
    Injectable,
    inject
} from "@angular/core";

import {
    documentPrinter,
    restManager,
    RootScope
} from "app/ajs-upgraded-providers";

import {
    ConfigurationManagerService,
    EntityManagerService,
    EnvironmentInfoService
} from "src/app/core";

import {
    AlertDialogService,
    OpenDialogsService
} from "src/app/dialogs";

import {TranslateService} from "@ngx-translate/core";
import * as pdfMake from 'pdfmake/build/pdfmake';
import {
    getVatValue,
    sumAmounts,
    sumDiscounts,
    sumSurcharges
} from "../../features/dashboard/components/dashboard/dashboard.utils";
import {ObjDataData, ReducePrinter, Today} from "../../features/dashboard/models/dashboard.model";
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { PrinterErrorFiscalDialogService } from "../printer-error-fiscal-dialog";

@Injectable()
export class DashboardReportGeneratorService {
    private $rootScope = inject(RootScope);
    private alertDialogService = inject(AlertDialogService);
    private configurationManagerService = inject(ConfigurationManagerService);
    private documentPrinterService = inject(documentPrinter);
    private entityManagerService = inject(EntityManagerService);
    private environmentInfoService = inject(EnvironmentInfoService);
    private openDialogsService = inject(OpenDialogsService);
    private printerErrorFiscalService = inject(PrinterErrorFiscalDialogService);
    private restManagerService = inject(restManager);
    private tilbyCurrencyPipe = inject(TilbyCurrencyPipe);
    private tilbyDatePipe = inject(TilbyDatePipe);
    private translate = inject(TranslateService);

    printInProgress = false;
    defaultPrinterColumns = 42;

    canShare() {
        return this.environmentInfoService.canShare();
    }

    canDownloadFiles() {
        return this.environmentInfoService.canDownloadFiles();
    }

    async shareDetails({printers,today}:ObjDataData) {
        const filename = 'Riepilogo';

        if (this.canShare()) {
            let pdfDocument = await this.getPdfDocument(<ReducePrinter[]>printers,today);

            pdfMake.createPdf(pdfDocument).getBase64((dataUri: string) => {
                window.plugins.socialsharing.shareWithOptions({
                        message: filename, // not supported on some apps (Facebook, Instagram)
                        subject: filename, // fi. for email
                        files: ["data:application/pdf;base64," + dataUri], // an array of filenames either locally or remotely
                    },
                    (success: any) => console.debug('SUCCESS', success),
                    (err: any) => console.error('ERROR', err));
            });
        }
    };

    async printDetails({ printers, today }: ObjDataData) {
        if (!this.configurationManagerService.isModuleEnabled('cashregister')) {
            await this.alertDialogService.openDialog({ data: { messageLabel: "DASHBOARD.DIALOG.NO_CASHREGISTER_MODULE" } });
            throw 'NO_CASHREGISTER_MODULE';
        }

        if (!this.printInProgress) {
            const document = await this.generateReport(<ReducePrinter[]>printers, today);
            const printer = await this.openDialogsService.openGeneralDocumentPrinterSelectorDialog({ data: { titleLabel: 'APPLICATION.ORDER_PRINTER.TITLE' } });
            const printerId = printer?.id;

            if (!printerId) {
                return;
            }

            this.printInProgress = true;

            try {
                await this.documentPrinterService.printFreeNonFiscal(document, printerId);
            } catch (error: any) {
                this.printerErrorFiscalService.openDialog({ data: { error, options: { printerId } } });
            } finally {
                this.printInProgress = false;
            }
        }
    };

    async emailDetails({printers,today}:ObjDataData) {
        let pdfDocument = await this.getPdfDocument(<ReducePrinter[]>printers, today);
        let pdfDocGenerator = pdfMake.createPdf(pdfDocument);

        pdfDocGenerator.getBuffer(async buffer => {
            const callbackFunction = (mailAddress: string) => {
                if (mailAddress) {
                    this.restManagerService.postOne("utilities/send_document_via_email", buffer, {
                        email: mailAddress,
                        filename: "Riepilogo",
                    }, {"content-type": "application/pdf"});
                }
            }
            await this.openDialogsService.openEmailExportDialog({data: {messageLabel:'DASHBOARD.EXPORT.SEND_VIA_EMAIL',confirmLabel:'DIALOG.EMAIL.ACTION.CONFIRM', cancelLabel:'DIALOG.EMAIL.ACTION.CANCEL'}}).then(res=>!!res&&callbackFunction(res.email));
        });
    };

    async downloadDetails({printers,today}:ObjDataData) {
        let pdfDocument = await this.getPdfDocument(<ReducePrinter[]>printers, today);
        let filename = 'Riepilogo';

        pdfMake.createPdf(pdfDocument).download(filename);
    };

    private async getPdfDocument(printers:ReducePrinter[],today:Today) {
        let document = await this.generateReport(printers, today);

        let pdfDocument = {
            content: [document],
            defaultStyle: {
                font: 'Monospace',
                fontSize: 10
            }
        };

        return pdfDocument;
    };

    private async generateReport(printers:ReducePrinter[], todayData:Today, options?:any){
        if(!_.isObject(options)) {
            options = {};
        }

        let text = [];
        let vats = await this.entityManagerService.vat.fetchCollectionOffline();
        let printerColumns = options.printerColumns || this.defaultPrinterColumns;

        text.push(_.padEnd(this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.TITLE'), printerColumns, ' '));
        text.push(_.padStart(this.tilbyDatePipe.transform(TilbyDatePipe.date())||''));
        text.push("");
        text.push("");

        for(let printer of printers) {
            text.push(_.padEnd('** ' + printer.name.toUpperCase() + ' **', printerColumns, ' '));
            text.push("");

            let printerReport = await this.getPrinterReport(printer, { vats, printerColumns });

            text.push(...printerReport);

            text.push("");
            text.push(_.pad("", printerColumns, "-"));
            text.push("");
        }

        if(!_.isEmpty(todayData.credit_notes)) {
            let creditNotesLabel = this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.CREDIT_NOTES');
            let creditNotesValue = _.padEnd(this.tilbyCurrencyPipe.transform(todayData.credit_notes.amount || 0), 10, " ");

            text.push( _.padEnd(creditNotesLabel,printerColumns - creditNotesValue.length , " ") + creditNotesValue );
            text.push("");
        }

        if(!_.isEmpty(todayData.cash_movements)) {
            text.push(this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.DRAWER_TOTAL'));
            let cashDrawerIncomeLabel = this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.DEPOSIT');
            let cashDrawerIncomeValue = _.padEnd(this.tilbyCurrencyPipe.transform(todayData.cash_movements.total?.income?.amount || 0), 10, " ");

            text.push( _.padEnd(cashDrawerIncomeLabel,printerColumns - cashDrawerIncomeValue.length , " ") + cashDrawerIncomeValue);

            let cashDrawerOutcomeLabel = this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.WITHDRAWAL');
            let cashDrawerOutcomeValue = _.padEnd(this.tilbyCurrencyPipe.transform(todayData.cash_movements.total?.outcome?.amount || 0), 10, " ");

            text.push( _.padEnd(cashDrawerOutcomeLabel,printerColumns - cashDrawerOutcomeValue.length , " ") + cashDrawerOutcomeValue);
            text.push("");
        }

        this.$rootScope.$broadcast('dashboardReport:reportCreated');

        return text.join("\n");
    };

    private async getPrinterReport(printer:ReducePrinter, options?:any){
        if(!_.isObject(options)) {
            options = {};
        }

        let vats = options.vats || (await this.entityManagerService.vat.fetchCollectionOffline());
        let printerColumns = options.printerColumns || this.defaultPrinterColumns;
        let printerReport = [];

        let fiscalReceiptsTotal = !_.isEmpty(printer.fiscal_receipts?.vats) ? _(printer.fiscal_receipts.vats).map('amount').compact().sum() : 0;
        let invoicesTotal = !_.isEmpty(printer.invoices?.vats) ? _(printer.invoices.vats).map('amount').compact().sum() : 0;
        let grandTotal = this.tilbyCurrencyPipe.transform(_.chain([fiscalReceiptsTotal, invoicesTotal]).sum().value()).padEnd(10, " ");

        printerReport.push(_.padEnd(this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.TOTAL'), printerColumns - grandTotal.length, ' ') + grandTotal);
        printerReport.push("");

        if(!_.isEmpty(printer.fiscal_receipts?.vats)) {
            let fiscalReceiptsLabel = this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.FISCAL_RECEIPTS');
            let fiscalReceiptsTotalStr = _.padEnd(this.tilbyCurrencyPipe.transform(fiscalReceiptsTotal), 10, ' ');

            printerReport.push(_.padEnd(fiscalReceiptsLabel, printerColumns - fiscalReceiptsTotalStr.length, " ") + fiscalReceiptsTotalStr);

            for(let vat of printer.fiscal_receipts.vats) {
                let label = "-- " + getVatValue(vats, vat.name);
                let value1 = _.padEnd(this.tilbyCurrencyPipe.transform(vat.amount || 0), 10, " ");

                printerReport.push(_.padEnd(label,printerColumns - value1.length , " ") + value1);
            }

            let labelReceipts = this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.UNCLAIMED_VAT');
            let valueReceipts = _.padEnd(this.tilbyCurrencyPipe.transform(printer.fiscal_receipts.unclaimed || 0), 10, " ");

            printerReport.push(_.padEnd(labelReceipts,printerColumns - valueReceipts.length , " ") + valueReceipts);
            printerReport.push("");
        }

        if(!_.isEmpty(printer.invoices?.vats)) {
            let invoicesLabel = this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.INVOICES');
            let invoicesTotalStr = _.padEnd(this.tilbyCurrencyPipe.transform(invoicesTotal), 10, ' ');

            printerReport.push(_.padEnd(invoicesLabel, printerColumns - invoicesTotalStr.length, " ") + invoicesTotalStr);

            for(let vat of printer.invoices.vats) {
                let label = "-- " + getVatValue(vats, vat.name);
                let value1 = _.padEnd(this.tilbyCurrencyPipe.transform(vat.amount || 0), 10, " ");

                printerReport.push(_.padEnd(label,printerColumns - value1.length , " ") + value1);
            }

            let labelNRinvoices = this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.UNCLAIMED_VAT');
            let valueNRinvoices = _.padEnd(this.tilbyCurrencyPipe.transform(printer.invoices.unclaimed || 0), 10, " ");

            printerReport.push(_.padEnd(labelNRinvoices,printerColumns - valueNRinvoices.length , " ") + valueNRinvoices);
            printerReport.push("");
        }

        if(!_.isEmpty(printer.summary_rc_invoices?.vats)) {
            let summaryRCinvoicesLabel = this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.SUMMARY_RC_INVOICES');
            printerReport.push(_.padEnd(summaryRCinvoicesLabel, printerColumns - summaryRCinvoicesLabel.length, " ") );

            for(let vat of printer.summary_rc_invoices.vats) {
                let label = "-- " + getVatValue(vats, vat.name);
                let value1 = _.padEnd(this.tilbyCurrencyPipe.transform(vat.amount || 0), 10, " ");

                printerReport.push(_.padEnd(label,printerColumns - value1.length , " ") + value1);
            }

            printerReport.push("");
        }

        if(!_.isEmpty(printer.summary_nrc_invoices?.vats)) {
            let summaryNRCinvoicesLabel = this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.SUMMARY_NRC_INVOICES');
            let value1 = _.padEnd(this.tilbyCurrencyPipe.transform(sumAmounts(printer.summary_nrc_invoices.vats) || 0), 10, " ");

            printerReport.push(_.padEnd(summaryNRCinvoicesLabel,printerColumns - value1.length , " ") + value1);
            printerReport.push("");
        }

        if(!_.isEmpty(printer.refunds)) {
            let refundsLabel = this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.REFUNDS');
            let value1 = _.padEnd(this.tilbyCurrencyPipe.transform(Math.abs(printer.refunds.amount || 0)), 10, " ");

            printerReport.push(_.padEnd(refundsLabel, printerColumns - value1.length , " ") + value1);
            printerReport.push("");
        }

        if(!_.isEmpty(printer.voids)) {
            let voidsLabel = this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.VOIDS');
            let value1 = _.padEnd(this.tilbyCurrencyPipe.transform(Math.abs(printer.voids.amount || 0)), 10, " ");

            printerReport.push(_.padEnd(voidsLabel, printerColumns - value1.length , " ") + value1);
            printerReport.push("");
        }

        if(!_.isEmpty(printer.departments)) {
            printerReport.push(this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.DEPARTMENTS'));

            _.forEach(printer.departments, (data) => {
                let label = "- " + data.name;
                let value = _.padEnd(this.tilbyCurrencyPipe.transform(data.amount || 0), 10, " ");

                printerReport.push(_.padEnd(label, printerColumns - value.length, " ") + value);
            });

            printerReport.push("");
        }

        if(!_.isEmpty(printer.channels)) {
            printerReport.push(this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.CHANNELS'));

            _.forIn(printer.channels, (channel, channelName) => {
                let label = "- " + channelName;
                let value = _.padEnd(this.tilbyCurrencyPipe.transform(channel.amount || 0), 10, " ");

                printerReport.push(_.padEnd(label, printerColumns - value.length, " ") + value);
            });

            printerReport.push("");
        }

        let claimeds = _.filter(printer.payments, { unclaimed: 0 });

        if(!_.isEmpty(claimeds)) {
            printerReport.push(_.padEnd(this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.CLAIMED'), printerColumns, " "));

            for(let claimed of claimeds) {
                let label = "- " +  claimed.name;
                let value = _.padEnd(this.tilbyCurrencyPipe.transform(claimed.amount || 0), 10, " ");
                printerReport.push(_.padEnd(label, printerColumns - value.length, " ") + value);
            }

            printerReport.push("");
        }

        let unclaimeds = _.filter(printer.payments, { unclaimed: 1 });

        if(!_.isEmpty(unclaimeds)) {
            printerReport.push(_.padEnd(this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.UNCLAIMED'), printerColumns, " "));

            for(let unclaimed of unclaimeds) {
                let label = "- " + unclaimed.name;
                let value = _.padEnd(this.tilbyCurrencyPipe.transform(unclaimed.amount || 0), 10, " ");
                printerReport.push(_.padEnd(label, printerColumns - value.length, " ") + value);
            }

            printerReport.push("");
        }

        if(printer.cash_movements?.sales || printer.cash_movements?.tickets) {
            printerReport.push(_.padEnd(this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.DRAWER'), printerColumns, " "));

            if(printer.cash_movements?.sales) {
                let label = this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.CASH');
                let value = _.padEnd(this.tilbyCurrencyPipe.transform(printer.cash_movements.sales || 0), 10, " ");

                printerReport.push(_.padEnd(label, printerColumns - value.length, " ") + value);
                printerReport.push("");
            }

            if(printer.cash_movements?.tickets) {
                printerReport.push(_.padEnd(this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.TICKET'), printerColumns, " "));

                _.forEach(printer.cash_movements.tickets, (data) => {
                    let label = "-- " +  data.name;
                    let value = _.padEnd(this.tilbyCurrencyPipe.transform(data.amount || 0) + " (" + data.count + ")", 10, " ");

                    printerReport.push(_.padEnd(label, printerColumns - value.length, " ") + value);
                });

                printerReport.push("");
            }
        }

        if(_.get(printer, ["other_changes_amount"], 0)) {
            let value = _.padEnd(this.tilbyCurrencyPipe.transform(_.get(printer, ["other_changes_amount"], 0)), 10, " ");
            printerReport.push(_.padEnd(this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.OTHER_CHANGES'), printerColumns - value.length, " ") + value);

            printerReport.push("");
        }

        let extraData = [
            {name: this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.DISCOUNTS'), amount: this.tilbyCurrencyPipe.transform(sumDiscounts(printer))},
            {name: this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.SURCHARGES'), amount: this.tilbyCurrencyPipe.transform(sumSurcharges(printer))},
            {name: this.translate.instant('DASHBOARD.EXPORTED_DOCUMENT.ORDERS'), amount: _.get(printer, ["orders", "count"], 0)}
        ];

        for(let data of extraData) {
            let value = _.padEnd(_.toString(data.amount), 10, " ");
            printerReport.push(_.padEnd(data.name, printerColumns - value.length, " ") + value);
        }

        return printerReport;
    };

}

import angular from 'angular';
import * as async from 'async';
import _ from 'lodash';

angular.module('digitalPayments').factory('Ingenico17Driver', Ingenico17Driver);

Ingenico17Driver.$inject = ["checkManager", "errorsLogger"];

function Ingenico17Driver(checkManager, errorsLogger) {
    let scope = {};

    const eventLog = (message) => console.debug("[Ingenico17]", message);

    /**
     * calculateLRC
     */
    function calculateLRC(str) {
        let lrc = 0x7F;

        for (let i = 0; i < str.length; i++) {
            lrc ^= str.charCodeAt(i);
        }

        return String.fromCharCode(lrc);
    }

    /**
     * ab2str
     */
    const ab2str = (buf) => String.fromCharCode.apply(null, new Uint8Array(buf));

    /**
     * str2ab
     */
    function str2ab(str) {
        let buf = new ArrayBuffer(str.length);
        let bufView = new Uint8Array(buf);

        for (let i = 0, strLen = str.length; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i);
        }

        return buf;
    }

    function ab2Hex(ab) {
        let result = [];

        _.forEach(new Uint8Array(ab), function(byte) {
            result.push(_.padStart(_.toInteger(byte).toString(16), 2, '0'));
        });

        return result.join(' ');
    }

    const cleanup = function() {
        if(scope.socketInfo) {
            window.chrome.sockets.tcp.onReceiveError.removeListener(errorListener);

            window.chrome.sockets.tcp.disconnect(scope.socketInfo.socketId, function() {
                eventLog("Socket disconnected");
                window.chrome.sockets.tcp.close(scope.socketInfo.socketId);
            });

            scope.pluginInfo = {
                status: 'IDLE'
            };
        }
    };

    const errorListener = function(info) {
        if(info.socketId === scope.socketInfo.socketId) {
            scope.errorFunction("CONNECTION_ERROR");
            cleanup();
        }
    };

    const sendData = function(buffer, waitUntil, callback) {
        let socketId = scope.socketInfo.socketId;

        let listen = function(info) {
           if(info.socketId === socketId) {
                if(scope.log) {
                    errorsLogger.sendReport({
                        type: 'Ingenico17',
                        ip_address: scope.ip_address,
                        content: {
                            action: 'receive',
                            data: ab2Hex(info.data)
                        }
                    });
                }

                let doCallback = false;

                if(_.isNull(waitUntil)) {
                    doCallback = true;
                } else if(_.isString(waitUntil)) {
                    doCallback = ab2str(info.data).indexOf(waitUntil) > -1;
                } else {
                    doCallback = _.some(waitUntil, function(target) {
                        return ab2str(info.data).indexOf(target) > -1;
                    });
                }

                if(doCallback) {
                    window.chrome.sockets.tcp.onReceive.removeListener(listen);
                    callback(ab2str(info.data));
                }
           }
       };

       window.chrome.sockets.tcp.onReceive.addListener(listen);

       // Send
       if(scope.log) {
           errorsLogger.sendReport({
               type: 'Ingenico17',
               ip_address: scope.ip_address,
               content: {
                   action: 'send',
                   data: ab2Hex(buffer)
               }
           });
       }

       window.chrome.sockets.tcp.send(scope.socketInfo.socketId, buffer, function(sendInfo) { });
    };

    /**
     * send
     */
    const sendAndWaitResponse = function(message, waitUntil, callback) {
        // Start
        let str = String.fromCharCode(2);
        // Message
        str += message;
        // End
        str += String.fromCharCode(3);
        // LRC
        str += calculateLRC(str);

        // Create Array Buffer to send
        let buf = str2ab(str);

        //Send
        sendData(buf, waitUntil, callback);
    };

    /**
     * send
     */
    const sendAck = function(callback) {
        // Ack
        let ack = String.fromCharCode(6) + String.fromCharCode(3);
        ack += calculateLRC(ack);
        let str = ack;

        // Create Array Buffer to send
        let buf = str2ab(str);

        //Send
        sendData(buf, null, callback);
    };

    function retreiveReceipt(callback) {
        let results = "";
        let terminationSequence = '\u007D\u007D\u001B';

        async.doUntil(function(cb) {
            let messageEnded = false;

            sendAck(function(message) {
                let segmentContent = message.substring(11) //Remove STX, terminalID, reserved and message code
                    .slice(0, -2) //Remove LRC and ETX
                    .replace(/[\u007B\u007C\u007E\u007F\u005E]/g, ''); //Remove POS format chars

                if(_.endsWith(segmentContent, terminationSequence)) {
                    segmentContent = segmentContent.replace(terminationSequence, ''); //Remove termination sequence
                    messageEnded = true;
                }

                segmentContent.replace(/\u007D/g, '\n');

                results += segmentContent;

                cb(messageEnded);
            });
        }, function(msgEnded) {
            return msgEnded;
        }, function(err, res) {
            parseReceipt(results, callback);
        });
    }

    function legacyRetreiveReceipt(callback) {
        let results = "";

        function processOne() {
            sendAck(function(message) {
                message = message.substring(0, message.length - 2)
                    .substring(11)
                    .replace('\u007B', '')
                    .replace('\u007C', '')
                    .replace('\u007E', '')
                    .replace('\u007F', '')
                    .replace('\u005E', '')
                    .replace('\u001B', '');

                let isLastMessage = _.includes(message, '}}');

                results += message.replace('\u007D', '');

                if (!isLastMessage) {
                    setTimeout(processOne, 100); // schedule immediately
                } else {
                    parseReceipt(results, callback);
                }
            });
        }

        setTimeout(processOne, 0); // schedule immediately
    }

    const parseReceipt = function(receipt, callback) {
        let rows = receipt.match(/[\s\S]{1,24}/g);
        let acquirer_id;
        let acquirer_name;

        _.forEach(rows, function(r) {
            let id_match = r.match(/A\.ID\s{1}[^\d]+(\d+)/);

            if(!_.isNil(id_match)) {
                acquirer_id = _.toInteger(id_match[1]);
            } else {
                let name_match = r.match(/APPL\s+(.*)/);

                if (!_.isNil(name_match)) {
                    acquirer_name = _.trim(name_match[1]);
                }
            }
        });

        if(acquirer_id === 1410001) {
            acquirer_name = "PagoBANCOMAT";
        }

        callback({
            receipt: rows.map(row => row.replace(/[^ -~]+/g, "")).join('\n'),
            acquirer_id: acquirer_id,
            acquirer_name: acquirer_name
        });
    };

    let getPaymentMessage = function(amount) {
        // Term.ID | Reserved to 0 | Message Code | Cassa | Reserved to 0 | Riconoscimento auto carta | Amount | Reserved
        return scope.termId + '0' + 'P' + '00000000' + '0000' + '0' + _.padStart(_.round(amount * 100).toString(), 8, '0') + '00000000';
    };

    /**
     * pay
     */
    let pay = function(amount, successFunction, errorFunction) {

        /*
            Codice terminale: 84510359
            02h MSG 03h LRC
         */

        /*
            Abilita stampa su ECR
            ID Terminale (8byte)
            0 riservato fisso (1byte)
            E codice messaggio (1byte)
            1 abilita stampa su ECR

            Pagamento

            ID Terminale (8 byte)
            0 (1 byte) Riservato fisso
            E (1 byte) (45 Hex)
            00/01 (2 byte) OK/KO
            PAN (19 byte)
            Tipo (3 byte) CLI
            COD. AUT. (6 byte)
            TIME (7 byte)
            TIPO CARTA (1 byte)
            ID ACQ. (11 byte)
            STAN (6 byte)
            PROGRESSIVO (6 byte)
         */

        let timeoutHandle = null;

        window.chrome.sockets.tcp.create(function(socketInfo) {
            scope.socketInfo = socketInfo;

            let timeoutConn = function() {
                window.chrome.sockets.tcp.getInfo(socketInfo.socketId, function(info) {
                    if (!info.connected) {
                        errorFunction("CONN_TIMEDOUT");
                        cleanup();
                    }
                });
            };

            window.chrome.sockets.tcp.onReceiveError.addListener(errorListener);
            timeoutHandle = setTimeout(timeoutConn, 10000);
            scope.pluginInfo.status = 'CONNECTING';

            // Connect
            window.chrome.sockets.tcp.connect(socketInfo.socketId, scope.ip_address, 9100, function(result) {
                clearTimeout(timeoutHandle);

                eventLog(result);
                // Ok
                if (result === 0) {
                    eventLog(`Socket connected to ${scope.ip_address}`);

                    // Enable print on ECR message
                    let enablePrint = scope.termId + '0E1';

                    Object.assign(scope.pluginInfo, {
                        status: 'PAYING',
                        connectedAt: new Date()
                    });

                    // SEND DISABLE PRINT ON POS
                    sendAndWaitResponse(enablePrint, null, function(message) {
                        // SEND PAY MESSAGE
                        let payMsg = getPaymentMessage(amount);

                        sendAndWaitResponse(payMsg, ['0E0', '0V0'], function(message) {
                            //SUCCESSFUL TRANSACTION
                            switch(message.charCodeAt(12)) {
                                case 48:
                                    eventLog("=== Transaction OK ===");
                                    let receiptFunction = checkManager.getSetting('ingenico17.use_new_parser') ? retreiveReceipt : legacyRetreiveReceipt;
        
                                    receiptFunction(function(receipt) {
                                        successFunction(receipt);
                                        sendAck();
                                        cleanup();
                                    });
                                break;
                                case 49:
                                    errorFunction(message.substring(13, 37).trim());
                                    sendAck();
                                    cleanup();
                                break;
                            }
                        });
                    });
                }
                // Connection not ok
                if (result < 0) {
                    errorFunction("CONNECTION_ERROR");
                    cleanup();
                }
            });
        });
    };

    /**
     * public methods
     */
    return {
        /**
         * init
         * @param  {8 char string} termId: if can find it doing
         *         [F1] -> [2] enter 0107 -> [0] -> [0]
         *         on an Ingenico ethernet POS
         *
         * @param  {string} ip_address
         */
        init: function(termId, ip_address, options) {
            if(!_.isObject(options)) {
                options = {};
            }

            scope = {
                termId: termId,
                ip_address: ip_address,
                log: checkManager.getPreference('ingenico17.log_data'),
                options: options,
                pluginInfo: {
                    status: 'IDLE',
                    connectedAt: null
                }
            };
        },
        getStatus: function() {
            return _.clone(scope.pluginInfo);
        },

        /**
         * [performPayment description]
         * @param  {[number]} amount ex. 2.50
         */
        performPayment: function(amount, successFunction, errorFunction) {
            eventLog(`Start paying ${amount}...`);

            Object.assign(scope, {
                amount: amount,
                successFunction: successFunction,
                errorFunction: errorFunction
            });

            pay(amount, successFunction, errorFunction);
        },
        cancelPayment: function() {
            cleanup();
            if(_.isFunction(scope.errorFunction)) {
                scope.errorFunction('USER_CANCELED');
            }
        }
    };
}
<div class="tw-flex tw-justify-center tw-mt-36 tw-mb-4 sm:tw-mb-6 md:tw-mb-8 lg:tw-mb-10">
    <span class="tw-scale-[2.8] sm:tw-scale-[3.3] md:tw-scale-[3.8] lg:tw-scale-[4.3]">
        <mat-icon>account_circle</mat-icon>
    </span>
</div>
<div class="tw-font-bold tw-flex tw-justify-center tw-text-[18px] sm:tw-text-[24px] md:tw-text-[30px] lg:tw-text-[36px]">
    {{customer?.first_name}} {{customer?.last_name}}
</div>
<div class="tw-flex tw-justify-center tw-mt-3 tw-text-[12px] sm:tw-text-[15px] md:tw-text-[18px] lg:tw-text-[21px]">
    {{customer?.company_name}}
</div>
<div class="tw-flex tw-justify-center tw-mt-4 sm:tw-mt-6 md:tw-mt-8 lg:tw-mt-10 tw-mb-6 sm:tw-mb-8 md:tw-mb-10 lg:tw-mb-12">
    <span class="tw-scale-[0.8] tw-mr-4 sm:tw-scale-[0.9] sm:tw-mr-6 md:tw-scale-[1] md:tw-mr-8 lg:tw-scale-[1.1] lg:tw-mr-10">
        <button mat-fab [color]="'primary'" [disabled]="!(customer?.phone||customer?.mobile)" (click)="callWithTel()">
            <mat-icon>call</mat-icon>
        </button>
    </span>
    <span class="tw-scale-[0.8] tw-mr-4 sm:tw-scale-[0.9] sm:tw-mr-6 md:tw-scale-[1] md:tw-mr-8 lg:tw-scale-[1.1] lg:tw-mr-10">
        <button mat-fab [color]="'primary'" [disabled]="!(customer?.mobile)" (click)="sendWithWa()">
            <mat-icon svgIcon="whatsapp"></mat-icon>
        </button>
    </span>
    <span class="tw-scale-[0.8] tw-mr-4 sm:tw-scale-[0.9] sm:tw-mr-6 md:tw-scale-[1] md:tw-mr-8 lg:tw-scale-[1.1] lg:tw-mr-10">
        <button mat-fab [color]="'primary'" (click)="goToBookingsByCustomer()">
            <mat-icon class="material-symbols-outlined">calendar_add_on</mat-icon>
        </button>
    </span>
    <span class="tw-scale-[0.8] sm:tw-scale-[0.9] md:tw-scale-[1] lg:tw-scale-[1.1]">
        <button mat-fab [color]="'primary'" (click)="goToCashregisterByCustomer()">
            <mat-icon>shopping_bag</mat-icon>
        </button>
    </span>
</div>
<div class="tw-flex tw-justify-center tw-text-[12px] sm:tw-text-[15px] md:tw-text-[18px] lg:tw-text-[21px]">
    {{'CUSTOMERS.DETAILS.SUMMARY.CREDIT' | translate}} {{credit||0 | tilbyCurrency}} - {{'CUSTOMERS.DETAILS.SUMMARY.POINTS' | translate}} {{pointsToShow|| 0}}
</div>
<div class="tw-flex tw-justify-center tw-mt-2 sm:tw-mt-3 md:tw-mt-4 lg:tw-mt-5 tw-text-[12px] sm:tw-text-[15px] md:tw-text-[18px] lg:tw-text-[21px] tw-align-middle">
    <span style="line-height: 28px;">{{'CUSTOMERS.DETAILS.SUMMARY.LAST_SALE' | translate}} : {{lastSale}}</span>
    <span class="tw-scale-[0.6] sm:tw-scale-[0.7] md:tw-scale-[0.7] lg:tw-scale-[0.85] tw-mb-0 tw-ml-1">
        <button *ngIf="hasLastSale" mat-button style="line-height:0px; min-width: 0px; padding: 0px;" (click)="goToHistorySaleByCustomer()">
            <mat-icon class="lg:tw-mb-1">launch</mat-icon>
        </button>
    </span>
</div>
<div class="tw-flex tw-justify-center tw-mt-2 sm:tw-mt-3 md:tw-mt-4 lg:tw-mt-5 tw-text-[12px] sm:tw-text-[15px] md:tw-text-[18px] lg:tw-text-[21px] tw-align-middle">
    <span *ngIf="notEmptySalesObj" style="line-height: 28px;">
        {{salesToBeInvoicedCustomer.transaction_count}} {{"CUSTOMERS.DETAILS.SUMMARY.RECEIPTS_TO_BE_INVOICED" | translate}} ({{salesToBeInvoicedCustomer.transaction_total | tilbyCurrency}})
    </span>
    <span *ngIf="notEmptySalesObj" class="tw-scale-[0.6] sm:tw-scale-[0.7] md:tw-scale-[0.7] lg:tw-scale-[0.85] tw-mb-0 tw-ml-1">
        <button mat-button style="line-height:0px; min-width: 0px; padding: 0px;" (click)="goToHistorySaleByCustomer()">
            <mat-icon class="lg:tw-mb-1">launch</mat-icon>
        </button>
    </span>
    <span *ngIf="!notEmptySalesObj" style="line-height: 28px;" translate="CUSTOMERS.DETAILS.SUMMARY.NO_RECEIPTS_TO_BE_INVOICED"></span>
</div>

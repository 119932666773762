import angular from 'angular';
import _ from 'lodash';

angular.module('cashregister').factory('ExternalSalesManager', ExternalSalesManager);

ExternalSalesManager.$inject = ["$rootScope", "checkManager", "entityManager"];

function ExternalSalesManager($rootScope, checkManager, entityManager) {
    var externalSalesFilter = function(sale) {
        return sale.seller_id === 0;
    };

    var moduleData = {
        count: 0
    };

    var updateExternalSalesCount = function() {
        entityManager.sales.fetchCollectionOffline({ status: 'open' }).then(function(sales) {
            moduleData.count = sales ? _.filter(sales, externalSalesFilter).length : 0;
        });
    };

    const externalSalesManager = {
        init: function() {
            if(checkManager.isModuleEnabled('cashregister')) {
                updateExternalSalesCount();
                $rootScope.$on("storage-updated:sales", updateExternalSalesCount);
            }
        },
        getExternalSalesCount: function() {
            return moduleData.count;
        }
    };

    return externalSalesManager;
}

import angular from 'angular';
import _ from 'lodash';
const { tilbyVersion } = require('app/tilby.properties.json');
import { FiscalPrintersService } from 'app/modules/printers/service/fiscal-printers/fiscal-printers';

angular.module('application').factory('globalEvents', globalEvents);

globalEvents.$inject = ["$q", "$rootScope", "$state", "$timeout", "$interval", "$translate", "$document", "$window", "environmentInfo", "checkManager", "entityManager", "errorsLogger", "onlineOnlyDialog", "waitDialog", "alertDialog", "toast", "printerErrorFiscal", "cashlogyDriver", "updateManager"];

function globalEvents($q, $rootScope, $state, $timeout, $interval, $translate, $document, $window, environmentInfo, checkManager, entityManager, errorsLogger, onlineOnlyDialog, waitDialog, alertDialog, toast, printerErrorFiscal, cashlogyDriver, updateManager) {
    let idleTimeoutPromise;
    let closingPromise;
    let closingDialogPromise;

    const lockScreen = () => {
        let currentModule = $state.current.name.split('.')[1];
        let lockScreenModulesPref = checkManager.getPreference("application.idle_lock_screen_modules");
        let lockScreenModules = _.isString(lockScreenModulesPref) ? _.split(lockScreenModulesPref, '|') : ['tables', 'orders', 'cashregister'];

        if(_.includes(lockScreenModules, currentModule)) {
            $state.go("app.lockScreen");
        }
    };

    const initLockScreen = (timeout) => {
        let lockSeconds = null;

        if(lockSeconds = _.toInteger(checkManager.getPreference("application.idle_lock_screen_timeout"))) {
            idleTimeoutPromise =  $timeout(lockScreen, 1000 * lockSeconds);
            let resetIdle = function(e) {
                $timeout.cancel(idleTimeoutPromise);
                idleTimeoutPromise =  $timeout(lockScreen, 1000 * lockSeconds);
            };

            $document.mousedown(resetIdle);
            $document.keypress(resetIdle);
        }
    };

    const initElectronCustomerDisplay = () => {
        if(checkManager.getPreference('customer_display.enable_color_display')) {
            $window.require('electron').ipcRenderer.invoke('show-customer-display', {
                sale: {},
                settings: _(checkManager.getShopPreferences('customer_display.')).value()
            });
        }
    };

    const initOnlineOnlyDialog = () =>{
        $rootScope.$on('connection:changed', function(event, data) {
            if(data.status === 'offline') {
                let arrCurrentModule = $state.current.name.split('.');
                let currentModule = arrCurrentModule[1] !== 'new' ? arrCurrentModule[1] : arrCurrentModule[2];
                if(_.includes(['dashboard', 'history', 'items', 'customers', 'fidelity', 'prepaid', 'stock', 'suppliers', 'printers', 'settings', 'promotions', 'giftcard'], currentModule)) {
                    onlineOnlyDialog.show();
                }
            }
        });
    };

    const startCashlogyListener = () =>{
        entityManager.paymentMethods.fetchCollectionOffline({ payment_method_type_id: 21 }).then(function(methods) {
            if(!_.isEmpty(methods)) {
                $window.addEventListener('beforeunload', function() {
                    cashlogyDriver.endSession();
                });
            }
        });
    };

    const onDailyClosingCompleted = async (data) => {
        if(closingPromise) {
            closingPromise.resolve();
        }

        if(!_.isEmpty(data.errors)) {
            try {
                await closingDialogPromise;
            } catch(err) {
                //Nothing to do
            } finally {
                for(let error of data.errors) {
                    try {
                        await alertDialog.show($translate.instant(error));
                    } catch{}
                }
            }
        }

        let updateStatus = updateManager.getLatestUpdateStatus() || {};

        if(updateStatus.updateAvailable && updateStatus.updateRequired) {
            $state.go('update-required');
        }
    };

    const bindGlobalEvents = (applicationScope) => {
        applicationScope.$on('daily-closing', function(event, data) {
            if(_.isObject(data)) {
                switch(data.status) {
                    case 'IN_PROGRESS':
                        closingPromise = $q.defer();
                        closingDialogPromise = waitDialog.show({ message: $translate.instant('PRINTERS.FISCAL.DAILY_CLOSING_IN_PROGRESS'), timeout: 60, promise: closingPromise.promise });
                    break;
                    case 'COMPLETED':
                        onDailyClosingCompleted(data);
                    break;
                    case 'FAILED':
                        if(closingPromise) {
                            closingPromise.reject();
                        }

                        closingDialogPromise.finally(function() {
                            printerErrorFiscal.show(_.head(data.errors), { printerId: data.printerId });
                        });
                    break;
                }
            }
        });

        FiscalPrintersService.fiscalClosingStatus$.subscribe((data) => {
            let message;
            let hideDelay;

            switch(data.action) {
                case 'dgfeRead':
                    switch(data.status) {
                        case 'IN_PROGRESS':
                            message = 'SENDING_TO_CLOUD';
                        break;
                        case 'COMPLETED':
                            message = 'SENT';
                        break;
                        case 'FAILED':
                            message = 'DGFE_READ_ERROR';
                        break;
                    }
                break;
                case 'configure':
                    switch(data.status) {
                        case 'IN_PROGRESS':
                            message = 'CONFIGURATION_IN_PROGRESS';
                            hideDelay = 0;
                        break;
                        case 'COMPLETED':
                            message = 'PRINTER_READY';
                            hideDelay = 0;
                        break;
                    }
            }

            if(message) {
                toast.show({ message: $translate.instant(`PRINTERS.FISCAL.${message}`), hideDelay: hideDelay });
            }
        });

        applicationScope.$on('activeSale:sale-creation-failed', (event, data) => {
            alertDialog.show($translate.instant(data));
        });

        if(environmentInfo.isElectronApp()) {
            window.require('electron').ipcRenderer.on('electron-update-downloaded', (event, message) => {
                toast.show({
                    message: $translate.instant('UPDATER.UPDATE_READY'),
                    actions: [{
                        text: $translate.instant('UPDATER.INSTALL_UPDATE'),
                        action: 'update',
                        highlight: true
                    }, {
                        text: $translate.instant('UPDATER.UPDATE_LATER'),
                        action: 'dismiss',
                        highlight: false
                    }],
                    hideDelay: 0
                }).then(function (action) {
                    if (action === 'update') {
                        window.require('electron').ipcRenderer.send('electron-update-commit');
                    }
                });
            });
        }
    };

    const shouldEnableAuditEvents = () => {
        return checkManager.getShopPreference('logging.enable_audits') ?? (['FR'].includes(checkManager.getShopCountry()) && checkManager.getSettingUserFirst('retailforce.client_id'));
    };

    const updateChecker = async () => {
        let updateStatus = await updateManager.checkForUpdates();

        if(updateStatus.updateAvailable && !['macos', 'windows'].includes(updateStatus.platformClass)) {
            if(updateStatus.hasUpdateUrl) {
                toast.show({
                    message: $translate.instant('UPDATER.UPDATE_AVAILABLE'),
                    actions: [{
                        text: $translate.instant('UPDATER.UPDATE_NOW'),
                        action: 'update',
                        highlight: true
                    }, {
                        text: $translate.instant('UPDATER.UPDATE_LATER'),
                        action: 'dismiss',
                        highlight: false
                    }],
                    hideDelay: 0
                }).then((action) => {
                    if (action === 'update') {
                        updateManager.updateNow(null);
                    }
                });
            }
        }
    };

    const bindAuditEvents = (applicationScope) => {
        //Audit Events
        applicationScope.$on('connection:changed', (event, data) => {
            errorsLogger.sendAudit(`application${_.upperFirst(data.status)}`); //applicationOnline/applicationOffline
        });

        applicationScope.$on('history:sale-reprinted', (event, data) => {
            errorsLogger.sendAudit(`historyReprint`, data); // { reprintedSale }
        });

        applicationScope.$on('activeOrder:order-opened', (event, data) => {
            if(!_.isEmpty(data)) {
                errorsLogger.sendAudit(`orderOpened`, { openedOrder: data });
            }
        });

        applicationScope.$on('activeOrder:order-deleted', (event, data) => {
            if(!_.isEmpty(data)) {
                errorsLogger.sendAudit(`orderDeleted`, { deletedOrder: data });
            }
        });

        applicationScope.$on('activeOrder:order-saved', (event, data) => {
            if(!_.isEmpty(data)) {
                errorsLogger.sendAudit(`orderSaved`, { savedOrder: data });
            }
        });

        applicationScope.$on('user-setting:changed', (event, data) => { //{ user, setting }
            errorsLogger.sendAudit(`userSettingChanged`, _.cloneDeep(data));
        });

        applicationScope.$on('activeSale:sale-opened', (event, data) => {
            if(!_.isEmpty(data)) {
                errorsLogger.sendAudit(`saleOpened`, { openedSale: data });
            }
        });

        applicationScope.$on('activeSale:sale-closed', (event, data) => {
            if(!_.isEmpty(data)) {
                errorsLogger.sendAudit(`saleClosed`, { closedSale: data });
            }
        });

        applicationScope.$on('activeSale:sale-parked', (event, data) => {
            errorsLogger.sendAudit(`saleParked`, { parkedSale: data });
        });

        applicationScope.$on('cashMovements:created', (event, data) => { //Cash movement
            errorsLogger.sendAudit(`cashMovementCreated`, { cashMovement: data });
        });

        applicationScope.$on('activeOrder:item-changed', (event, data) => { // { previousOrderItem, currentOrderItem }
            errorsLogger.sendAudit(`orderItemUpdated`, data);
        });

        applicationScope.$on('activeSale:item-changed', (event, data) => { // { previousSaleItem, currentSaleItem }
            errorsLogger.sendAudit(`saleItemUpdated`, data);
        });

        applicationScope.$on('activeOrder:item-removed', (event, data) => { // OrderItem
            errorsLogger.sendAudit(`orderItemRemoved`, { removedOrderItem: data });
        });

        applicationScope.$on('activeSale:item-removed', (event, data) => { // SaleItem
            errorsLogger.sendAudit(`saleItemRemoved`, { removedSaleItem: data });
        });

        applicationScope.$on('order:printed', (event, data) => { //{ printType , order?, sale?, results, reprint }
            errorsLogger.sendAudit(`orderPrinted`, data);
        });

        applicationScope.$on('documentPrinter:document-print-failed', (event, data) => { // { error }
            let error = data;

            if(_.isObject(data)) {
                try {
                    error = JSON.stringify(error);
                } catch(err) {}
            }

            errorsLogger.sendAudit(`documentPrintFailed`, { error });
        });

        applicationScope.$on('startup:completed', (event, data) => { // { user, lastActiveSession }
            errorsLogger.sendAudit(`applicationStartup`, { appVersion: tilbyVersion });

            if(!data.lastLoginStatus.loggedUser) {
                errorsLogger.sendAudit(`newLoggedUser`, { user: data.user });
            }

            if(_.startsWith(data.user.shop.name, 'demo_') || _.endsWith(data.user.shop.name, '_sandbox')) {
                errorsLogger.sendAudit(`trainingModeStart`);
            }
        });

        applicationScope.$on('daily-closing', (event, data) => {
            if(_.isObject(data)) {
                switch(data.status) {
                    case 'IN_PROGRESS':
                    break;
                    case 'COMPLETED':
                        errorsLogger.sendAudit(`dailyClosingCompleted`);
                    break;
                    case 'FAILED':
                    break;
                }
            }
        });

        applicationScope.$on('drawer-open', (event, data) => {
            errorsLogger.sendAudit(`drawerOpen`);
        });

        applicationScope.$on('dashboardReport:reportCreated', (event, data) => {
            errorsLogger.sendAudit(`dailyReportCreated`);
        });
    };


    let globalEvents = {
        init: function(applicationScope) {
            initLockScreen();
            initOnlineOnlyDialog();

            if (environmentInfo.isElectronApp()) {
                initElectronCustomerDisplay();
            }

            if(applicationScope) {
                bindGlobalEvents(applicationScope);
                if(shouldEnableAuditEvents()) {
                    bindAuditEvents(applicationScope);
                }
            }

            $interval(updateChecker, 1800000);

            if(environmentInfo.canUseTcpSockets()) {
                startCashlogyListener();
            }
        }
    };

    return globalEvents;
}

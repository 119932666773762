import angular from 'angular';
import _ from 'lodash';

angular.module('settings', ['application', 'ngAnimate']);

angular.module('settings').config(["$stateProvider", "$translatePartialLoaderProvider", function($stateProvider, $translatePartialLoaderProvider) {
    $translatePartialLoaderProvider.addPart('settings');

    $stateProvider.state('app.settings', {
        url: '/settings',
        redirectTo: 'app.settings.user'
    });

    $stateProvider.state('app.settings.general', {
        url: '/general',
        views: {
            "appContent@app": {
                template: require('./partial/general-settings/general-settings.html'),
                controller: 'GeneralSettingsCtrl'
            }
        },
        resolve: {
            departments: ["entityManager", (entityManager) => entityManager.departments.fetchCollectionOffline() ],
            nonFiscalDocuments: ["entityManager", (entityManager) => entityManager.nonfiscalDocuments.fetchCollectionOffline()],
            paymentMethods: ["entityManager", (entityManager) => entityManager.paymentMethods.fetchCollectionOffline() ],
            preferencesData: ["checkManager", (checkManager) => checkManager.getShopPreferences() ],
            printers: ["entityManager", (entityManager) => entityManager.printers.fetchCollectionOffline() ]
        }
    });

    $stateProvider.state('app.settings.my_subscription', {
        url: '/old_my-subscription',
        views: {
            "appContent@app": {
                template: require('./partial/my-subscription/my-subscription.html'),
                controller: 'MySubscriptionCtrl'
            }
        }
    });

    $stateProvider.state('app.settings.batch', {
        url: '/batch',
        views: {
            "appContent@app": {
                template: require('./partial/batch-settings/batch-settings.html'),
                controller: 'BatchSettingsCtrl'
            }
        },
        resolve: {
            documentPrinters: ["entityManager", (entityManager) => entityManager.printers.fetchCollectionOffline({ type_in: ['fiscal', 'rt', 'receipt'] }) ],
            nonFiscalDocuments: ["entityManager", (entityManager) => entityManager.nonfiscalDocuments.fetchCollectionOffline()],
        }
    });

    $stateProvider.state('app.settings.kiosk', {
        url:'/old_kiosk',
        views: {
            "appContent@app": {
                template: require('./partial/kiosk-settings/kiosk-settings.html'),
                controller: 'KioskSettingsCtrl'
            }
        },
        resolve: {
            preferencesData: ["checkManager", function(checkManager) {
                return checkManager.getShopPreferences('kiosk.');
            }]
        }
    });

    $stateProvider.state('app.settings.shifts', {
        url: '/old_shifts',
        views: {
            "appContent@app": {
                template: require('./partial/shifts-settings/shifts-settings.html'),
                controller: 'ShiftsSettingsCtrl'
            }
        },
        resolve: {
            rooms: ["entityManager", function(entityManager) {
                return entityManager.rooms.fetchCollectionOffline();
            }],
            shiftsList: ["restManager", function(restManager) {
                return restManager.getList("/booking_shifts", { pagination: false });
            }]
        }
    });

    $stateProvider.state('app.settings.e_commerce', {
        url: '/e-commerce-settings',
        views: {
            "appContent@app": {
                template: require('./partial/e-commerce-settings/e-commerce-settings.html'),
                controller: 'EcommerceSettingsCtrl'
            }
        },
        resolve: {
            items: ["entityManager", function(entityManager) {
                return entityManager.items.fetchCollectionOffline().then(function(items) {
                    return _(items).filter( item => _.some(item.channels, { channel_id: 'website' }) ).value();
                });
            }],
            preferencesData: ["checkManager", function(checkManager) {
                return checkManager.getShopPreferences('ecommerce.');
            }],
            rooms: ["entityManager", function(entityManager) {
                return entityManager.rooms.fetchCollectionOffline();
            }]
        }
    });

    /* Add New States Above */
}]);

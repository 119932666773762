import angular from 'angular';
import _ from 'lodash';

angular.module('application').factory('toast',["$mdToast", "$q", "$state", function($mdToast, $q, $state) {
    toastController.$inject = ["$scope", "message", "actions"];

    function toastController($scope, message, actions) {
		$scope.message = message;
		$scope.actions = actions;

		$scope.buttonClicked = function(messageSelected){
			$mdToast.hide(messageSelected);
		};
    }

	var toast = {
		show: function(options) {
            if(!_.includes('kiosk', $state.current.name.split('.')[1])) {
                var defaultOptions = {
                    controller: toastController,
                    template: require('./toast.html'),
                };

                if (options) {
                    _.merge(options, defaultOptions);

                    options.locals = {
                        message: options.message,
                        actions: options.actions || [{ text: 'ok', action: 'dismiss', highlight: true }]
                    };

                    return $mdToast.show(options);
                } else {
                    return $q.reject();
                }
            } else {
                return $q.reject();
            }
		},
        hide: function(toastPromise) {
            return $mdToast.hide(toastPromise);
        }
	};

	return toast;
}]);
import angular from 'angular';
import _ from 'lodash';

angular.module('fidelity').controller('FidelityCampaignsCtrl', ["$scope", "$rootScope", "$q", "$translate", "entityManager", "itemSelector", "alertDialog", "items", "confirmDialog", "toast", function($scope, $rootScope, $q, $translate, entityManager, itemSelector, alertDialog, items, confirmDialog, toast) {

    $scope.selectedCampaign = null;
    var pristineCampaign = null;

    $scope.items = items;
    $scope.isSaving = false;

    $scope.topbar_context.view = "campaigns";
    $scope.topbar_context.selectedTab = 0;

    $scope.ruleApplicationTypes = [
        { value: 'dont_apply', name: $translate.instant('FIDELITY.SETUP.CAMPAIGNS.DONT_APPLY') },
        { value: 'apply_with_prizes', name: $translate.instant('FIDELITY.SETUP.CAMPAIGNS.APPLY_WITH_PRIZES') },
        { value: 'apply_without_prizes', name: $translate.instant('FIDELITY.SETUP.CAMPAIGNS.APPLY_WITHOUT_PRIZES') }
    ];

    $scope.topbar_context.checkBeforeExit = function() {
        var d = $q.defer();

        if ($scope.campaignForm.$dirty) {
            confirmDialog.show($translate.instant('FIDELITY.SETUP.CAMPAIGNS.EXIT_CONFIRM')).then(function(answer) {
                if (answer) {
                    d.resolve();
                } else {
                    $scope.topbar_context.selectedTab = 0;
                    d.reject();
                }
            }, function() {
                $scope.topbar_context.selectedTab = 0;
                d.reject();
            });
        } else {
            d.resolve();
        }

        return d.promise;
    };

    var checkCampaignForErrors = function() {
        var isCorrect = true;

        //Check rules section
        $scope.selectedCampaign.rules = [];
        var invalidRules = [];
        _.each($scope.selectedCampaign.$rules, function(rule, idx) {
            if (!rule.rule_id) {
                invalidRules.push(idx);
            } else {
                $scope.selectedCampaign.rules.push($scope.rulesMap[rule.rule_id]);
            }
        });
        if (invalidRules.length) {
            _.pullAt($scope.selectedCampaign.$rules, invalidRules);
        }

        //Check items section
        var invalidItems = [];
        _.each($scope.selectedCampaign.items, function(item, idx) {
            if (!(item.sku && item.name && item.points)) {
                if (!item.sku && !item.name && !item.points) {
                    invalidItems.push(idx);
                } else {
                    isCorrect = false;
                }
            }
        });
        if (invalidItems.length) {
            _.pullAt($scope.selectedCampaign.items, invalidItems);
        }

        if (!isCorrect) {
            alertDialog.show($translate.instant('FIDELITY.SETUP.CAMPAIGNS.MISSING_ITEM_INFO'));
            return false;
        }

        return true;
    };

    var prepareCampaignForSend = function(campaign) {
        if (campaign.$fromDate) {
            campaign.from_date = campaign.$fromDate.toISOString();
            delete campaign.$fromDate;
        } else {
            delete campaign.from_date;
        }
        if (campaign.$toDate) {
            campaign.to_date = campaign.$toDate.toISOString();
            delete campaign.$toDate;
        } else {
            delete campaign.to_date;
        }

        if (!campaign.items.length) {
            delete campaign.items;
        }
        if (!campaign.rules.length) {
            delete campaign.rules;
        }

        delete campaign.$rules;
        delete campaign.$$hashKey;
    };

    var parseCampaign = function(campaign) {
        campaign.$rules = [];

        _.each(campaign.rules, function(rule) {
            campaign.$rules.push({ rule_id: rule.id });
        });

        if (campaign.from_date) {
            campaign.$fromDate = new Date(campaign.from_date);
        }
        if (campaign.to_date) {
            campaign.$toDate = new Date(campaign.to_date);
        }
    };

    $scope.addCampaign = function() {
        var performAdd = function() {
            pristineCampaign = null;
            $scope.selectedCampaign = {
                active: true,
                items: [],
                rules: []
            };
        };

        if ($scope.campaignForm.$dirty) {
            confirmDialog.show($translate.instant('FIDELITY.SETUP.CAMPAIGNS.CANCEL_CHANGES_CONFIRM')).then(function(answer) {
                if (answer) {
                    performAdd();
                    parseCampaign($scope.selectedCampaign);
                    $scope.campaignForm.$setPristine();
                }
            });
        } else {
            performAdd();
            parseCampaign($scope.selectedCampaign);
        }
    };

    $scope.selectCampaign = function(campaign) {
        var performSelection = function() {
            pristineCampaign = campaign;
            $scope.selectedCampaign = _.cloneDeep(campaign);
            parseCampaign($scope.selectedCampaign);
        };

        if ($scope.campaignForm.$dirty) {
            confirmDialog.show($translate.instant('FIDELITY.SETUP.CAMPAIGNS.CANCEL_CHANGES_CONFIRM')).then(function(answer) {
                if (answer) {
                    performSelection();
                    $scope.campaignForm.$setPristine();
                }
            });
        } else {
            performSelection();
        }
    };

    $scope.isSelected = function(campaign) {
        if ($scope.selectedCampaign) {
            return campaign.id === $scope.selectedCampaign.id;
        } else {
            return false;
        }
    };

    $scope.addRuleToCampaign = function() {
        $scope.selectedCampaign.$rules.push({});
        $scope.campaignForm.$setDirty();
    };

    $scope.addProductToCampaign = function() {
        $scope.selectedCampaign.items.push({});
        $scope.campaignForm.$setDirty();
    };

    $scope.removeRuleFromCampaign = function(idx) {
        _.pullAt($scope.selectedCampaign.$rules, idx);
        $scope.campaignForm.$setDirty();
    };

    $scope.removeItemFromCampaign = function(idx) {
        _.pullAt($scope.selectedCampaign.items, idx);
        $scope.campaignForm.$setDirty();
    };

    $scope.editCampaignItem = function(item) {
        itemSelector.show($scope.items).then(function(res) {
            if (res) {
                item.name = res.name;
                item.sku = res.sku;
                $scope.campaignForm.$setDirty();
            }
        });
    };

    $scope.saveCampaign = function(form) {
        if (form.$invalid) {
            alertDialog.show($translate.instant('FIDELITY.SETUP.CAMPAIGNS.SAVE_INCORRECT_DATA'));
        } else {
            if (checkCampaignForErrors()) {
                var campaignToSend = _.cloneDeep($scope.selectedCampaign);

                prepareCampaignForSend(campaignToSend);
                $scope.isSaving = true;
                $rootScope.$broadcast("loader:changeStatus", "fidelityCampaignsLoader", { enabled: true });
                if ($scope.selectedCampaign.id) {
                    entityManager.campaigns.putOneOnline(campaignToSend).then(function(result) {
                        toast.show({ message: $translate.instant('FIDELITY.SETUP.CAMPAIGNS.SAVE_EDIT_OK'), hideDelay: 10000 }).then(function() {
                            angular.copy(result, pristineCampaign);
                            angular.copy(pristineCampaign, $scope.selectedCampaign);
                            parseCampaign($scope.selectedCampaign);
                            $scope.campaignForm.$setPristine();
                        });
                    }, function(error) {
                        alertDialog.show($translate.instant('FIDELITY.SETUP.CAMPAIGNS.SAVE_EDIT_ERROR'));
                    }).finally(function() {
                        $rootScope.$broadcast("loader:changeStatus", "fidelityCampaignsLoader", { enabled: false });
                        $scope.isSaving = false;
                    });
                } else {
                    entityManager.campaigns.postOneOnline(campaignToSend).then(function(result) {
                        toast.show({ message: $translate.instant('FIDELITY.SETUP.CAMPAIGNS.SAVE_CREATE_OK'), hideDelay: 10000 }).then(function() {
                            pristineCampaign = _.cloneDeep(result);
                            angular.copy(pristineCampaign, $scope.selectedCampaign);
                            parseCampaign($scope.selectedCampaign);
                            $scope.campaigns.push(result);
                            $scope.campaignForm.$setPristine();
                        });
                    }, function(error) {
                        alertDialog.show($translate.instant('FIDELITY.SETUP.CAMPAIGNS.SAVE_CREATE_ERROR'));
                    }).finally(function() {
                        $rootScope.$broadcast("loader:changeStatus", "fidelityCampaignsLoader", { enabled: false });
                        $scope.isSaving = false;
                    });
                }
            }
        }
    };

    $scope.resetCampaign = function() {
        if ($scope.selectedCampaign.id) {
            confirmDialog.show($translate.instant('FIDELITY.SETUP.CAMPAIGNS.EDIT_CANCEL_CONFIRM')).then(function(answer) {
                if (answer) {
                    angular.copy(pristineCampaign, $scope.selectedCampaign);
                    parseCampaign($scope.selectedCampaign);
                    $scope.campaignForm.$setPristine();
                }
            });
        } else {
            confirmDialog.show($translate.instant('FIDELITY.SETUP.CAMPAIGNS.CREATE_CANCEL_CONFIRM')).then(function(answer) {
                if (answer) {
                    $scope.selectedCampaign = null;
                    $scope.campaignForm.$setPristine();
                }
            });
        }
    };
}]);

// keypad.component.ts
import { Component, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { mobileCheck } from "@tilby/tilby-ui-lib/utilities";

@Component({
    selector: "keypad-pin",
    templateUrl: "./keypad-pin.component.html",
    styleUrls: ["./keypad-pin.component.scss"],
    standalone: true,
    imports: [
        MatButtonModule,
        MatIconModule
    ]
})
export class KeypadPinComponent {
    @Input() blockKeypad:boolean = false;
    @Output() digitChange = new EventEmitter<string>();

    field: string = "";
    maxlength: number = 0;
    isMobile = mobileCheck();

    onClick(key: string) {
        if(this.checkBlockPinKeypad()){
            return;
        }

        if(this.field.length >= 4){
            this.field = '';
        }

        if (key === 'Backspace' || key === 'del') {
            this.field = '';
        } else {
            if (this.maxlength && (this.field.length < this.maxlength )) {
                this.field += key;
            } else if (!this.maxlength){
                this.field += key;
            }
        }

        this.digitChange.emit(this.field);
    };

    checkBlockPinKeypad(){
        return this.blockKeypad;
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if(this.checkBlockPinKeypad()){
            return;
        }

        const key = event.key;

        if (!isNaN(parseInt(key)) || key === 'Backspace') {
            this.onClick(key);
        }
    }
}

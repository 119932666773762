import angular from 'angular';
import _ from 'lodash';
import { keyBy } from 'src/app/shared/utils';

function CategoriesCtrl($scope, $translate, user, categories, entityManager, confirmDialog, alertDialog, checkManager, itemSelector) {
    const isMagoEnabled = checkManager.getPreference('integrations.mago.publish_sales') || checkManager.getPreference('integrations.mago.publish_customers');
    let variationLinkableItems = [];
    let variationLinkableItemsByUuid = {};

    const createIndexes = () => Array(20).fill().map((_, i) => i + 1);

    Object.assign($scope, {
        user: user,
        topbar_context: {
            showcaseView: 'crudviewmode',
            back_label: $translate.instant('ITEMS.CATEGORIES.PRODUCTS'),
            current_label: $translate.instant('ITEMS.CATEGORIES.CATEGORIES')
        },
        maximumIndexes: createIndexes(),
        saveInProgress: false,
        categories: _.sortBy(categories, 'index'),
        pristineCategory: null,
        kioskEnabled: checkManager.isModuleEnabled('kiosk'),
        websiteEnabled: checkManager.isModuleEnabled('c_sito_base'),
        itemColors: [{
            value: null,
            name: $translate.instant('ITEMS.DETAILS.COLOR_NONE')
        }, {
            value: 'D1C4E9',
            name: $translate.instant('ITEMS.DETAILS.COLOR_PURPLE')
        }, {
            value: 'C5CAE9',
            name: $translate.instant('ITEMS.DETAILS.COLOR_INDIGO')
        }, {
            value: 'B3E5FC',
            name: $translate.instant('ITEMS.DETAILS.COLOR_LIGHT_BLUE')
        }, {
            value: 'B2DFDB',
            name: $translate.instant('ITEMS.DETAILS.COLOR_WATER_GREEN')
        }, {
            value: 'C8E6C9',
            name: $translate.instant('ITEMS.DETAILS.COLOR_GREEN')
        }, {
            value: 'FFECB3',
            name: $translate.instant('ITEMS.DETAILS.COLOR_AMBER')
        }, {
            value: 'FFE0B2',
            name: $translate.instant('ITEMS.DETAILS.COLOR_ORANGE')
        }, {
            value: 'FFCDD2',
            name: $translate.instant('ITEMS.DETAILS.COLOR_RED')
        }]
    });

    $scope.canSave = function() {
        return !_.isEqual($scope.category, $scope.pristineCategory);
    };

    $scope.afterSelectCategory = function() {
        if(!$scope.pristineCategory.variations) {
            $scope.pristineCategory.variations = [];
        }

        for (const variation of $scope.pristineCategory.variations) {
            computeAvailableIndexes(variation);

            for (const variationValue of variation.variation_values) {
                if(variationValue.linked_item_uuid) {
                    variationValue._linkedItemName = variationLinkableItemsByUuid[variationValue.linked_item_uuid]?.name;
                }
            }
        }

        $scope.category = structuredClone($scope.pristineCategory);

    };

    // The first category is selected by default, can be changed to undefined and {}
    var selectDefaultCategory = function() {
        $scope.pristineCategory = _.isEmpty($scope.categories) ? {} : _.head($scope.categories);

        $scope.afterSelectCategory();
    };

    const loadVariationLinkableItems = async () => {
        const items = await entityManager.items.fetchCollectionOffline();
        variationLinkableItems = items.filter((item) => (item.uuid && !item.combinations.length));
        variationLinkableItemsByUuid = keyBy(variationLinkableItems, (i) => i.uuid);
    };

    loadVariationLinkableItems().finally(selectDefaultCategory);

    $scope.deleteCategory = async () => {
        if (!$scope.category.id) {
            return;
        }

        if(isMagoEnabled) {
            alertDialog.show($translate.instant('ITEMS.NO_EDIT_WITH_MAGO_ENABLED'));
            return;
        }

        const answer = await confirmDialog.show($translate.instant('ITEMS.CATEGORIES.WANT_TO_DELETE'));

        if(!answer) {
            return;
        }

        try {
            const success = await entityManager.categories.deleteOneOnline($scope.category.id, true);
            $scope.categories = $scope.categories.filter((category) => category.id !== success);

            selectDefaultCategory();
        } catch (error) {
            alertDialog.show($translate.instant('ITEMS.CATEGORIES.ERROR_WHILE_DELETING'));
        }
    };

    $scope.addNewCategory = function() {
        if(isMagoEnabled) {
            alertDialog.show($translate.instant('ITEMS.NO_EDIT_WITH_MAGO_ENABLED'));
            return;
        }

        // Fields are now blank, category is shown on cashregister/orders by default
        $scope.pristineCategory = {
            display: true,
            display_kiosk: false,
            display_website: false,
            display_slave_device: true,
            variations: []
        };

        $scope.afterSelectCategory();
    };

    $scope.addNewVariation = async () => {
        $scope.category.variations.push({
            name: "",
            required: false,
            variation_values: [],
            availableIndexes: createIndexes()
        });
    };

    $scope.removeVariation = async (variationIndex) => {
        $scope.category.variations.splice(variationIndex, 1);
    };

    $scope.addVariationValue = async (variation) => {
        // Add the new variation option to the scope
        variation.variation_values.push({
            value: "",
            price_difference: 0
        });
    };

    $scope.removeVariationValue = (variationIndex, valueIndex) => {
        const variation = $scope.category.variations[variationIndex];
        const variationValue = variation.variation_values[valueIndex];

        // Put the index back into availableIndexes
        if (variationValue.index != null) {
            variation.availableIndexes.push(variationValue.index);
        }

        variation.variation_values.splice(valueIndex, 1);
    };

    $scope.onVariationValueDefaultChange = function(variation, variationValue) {
        if(variationValue.default_value) {
            _.forEach(variation.variation_values, function(vVal) {
                if(vVal !== variationValue) {
                    vVal.default_value = false;
                }
            });
        }
    };

    $scope.editVariationLinkedItem = async (variationValue) => {
        const selectedItem = await itemSelector.show(variationLinkableItems);

        Object.assign(variationValue, {
            linked_item_uuid: selectedItem.uuid,
            _linkedItemName: selectedItem.name
        });

        if(!variationValue.name) {
            variationValue.value = selectedItem.name;
        }
    };

    const computeAvailableIndexes = function(v) {
        //Find indexes between 1 and 20 that are not used by other variations and sort them
        const usedIndexes = v.variation_values.map((v) => v.index);

        v.availableIndexes = createIndexes().filter(i => !usedIndexes.includes(i)).sort((a, b) => a - b);
    };

    $scope.updateIndexes = function(variation, index, oldValue, variationValueIndex) {
        if(index == null || variation.availableIndexes.includes(index)) {
            computeAvailableIndexes(variation);
        } else {
            alertDialog.show($translate.instant('ITEMS.DETAILS.INDEX_ALREADY_USED'));
            variation.variation_values[variationValueIndex].index = parseInt(oldValue) || undefined;
        }
    };

    $scope.saveEdit = async () => {
        if(isMagoEnabled) {
            alertDialog.show($translate.instant('ITEMS.NO_EDIT_WITH_MAGO_ENABLED'));
            return;
        }

        if($scope.saveInProgress) {
            return;
        }

        const result = $scope.categories.find((category) => category.name === $scope.category.name && category.id !== $scope.category.id);

        if (result) {
            alertDialog.show($translate.instant('ITEMS.CATEGORIES.CATEGORY_ALREAD_EXISTING'));
            return;
        }

        // Send to the BE
        const entityToBE = structuredClone($scope.category);
        $scope.saveInProgress = true;

        try {
            const functionToCall = entityToBE.id ? 'putOneOnline' : 'postOneOnline';
            const updatedEntity = await entityManager.categories[functionToCall](entityToBE);

            if(entityToBE.id) { //After PUT
                angular.copy(updatedEntity, $scope.pristineCategory);
            } else { //After POST
                $scope.categories.push(updatedEntity);
                $scope.pristineCategory = updatedEntity;
            }

            $scope.afterSelectCategory();
        } catch (error) {
            // Show error dialog
            alertDialog.show($translate.instant('ITEMS.CATEGORIES.ERROR_TRY_LATER'));
        } finally {
            $scope.saveInProgress = false;
        }
    };

    $scope.abortEdit = function() {
        if ($scope.category.id) {
            // Update the (form) view
            $scope.afterSelectCategory();
        } else {
            selectDefaultCategory();
        }
    };
}

CategoriesCtrl.$inject = ["$scope", "$translate", "user", "categories", "entityManager", "confirmDialog", "alertDialog", "checkManager", "itemSelector"];

angular.module('items').controller('CategoriesCtrl', CategoriesCtrl);

import { Component, inject } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, RowNode } from 'ag-grid-community';
import { OpenDialogsService } from 'src/app/dialogs';
import { GridComponent } from '../grid.component';
import { GridService } from '../grid.service';
import { PromotionsFe } from 'src/app/shared/model/model';
export interface CliccableActionPromotion {
    action: 'details' | 'duplicate' | 'deactivate' | 'print-labels' | 'delete';
    data: PromotionsFe;
    node?: RowNode;
}

@Component({
    selector: 'app-grid-clickable-promotion-button',
    templateUrl: './grid-clickable-promotion-button.component.html',
    styleUrls: ['./grid-clickable-promotion-button.component.scss'],
})
export class GridClickablePromotionButtonComponent implements ICellRendererAngularComp {
    private gridService = inject(GridService);

    _params!: ICellRendererParams;
    hide = false;

    get params() {
        return this._params;
    }

    set params(v) {
        this._params = v;
        if (this.gridService.tableProperties.idsDeleting.indexOf(Number(this.params.data?.id)) > -1) this.hide = true;
    }

    constructor(
        public openDialogsService: OpenDialogsService,
    ) {}

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }

    goToDetails($event: any) {
        (<GridComponent>this.params.context.componentParent).clickableAction.emit({
            action: 'details',
            data: this.params.data
        });
    }

    duplicatePromotion($event: any) {
        setTimeout(() => {
            (<GridComponent>this.params.context.componentParent).clickableAction.emit({
                action: 'duplicate',
                data: this.params.data,
                node: this.params.node
            })
        });
    }
    deletePromotion($event: any) {
        setTimeout(() => {
            (<GridComponent>this.params.context.componentParent).clickableAction.emit({
                action: 'delete',
                data: this.params.data
            })
        });
    }
    generateLabels($event: any) {
        setTimeout(() => {
            (<GridComponent>this.params.context.componentParent).clickableAction.emit({
                action: 'print-labels',
                data: this.params.data
            })
        });
    }
    togglePromotion($event: any) {
        setTimeout(() => {
            (<GridComponent>this.params.context.componentParent).clickableAction.emit({
                action: 'deactivate',
                data: this.params.data
            })
        });
    }

    preventSelection($event: MouseEvent) {
        $event.stopPropagation();
    }

    hideGenerateLabels() {
        return this.params.data?.items === 0;
    }

}

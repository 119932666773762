import { Component, OnInit, inject, ViewChild, signal } from '@angular/core';
import { RowClickedEvent } from 'ag-grid-community';
import { Rest, TilbyToolbar } from 'src/app/models';
import {
    CliccableActionPromotion,
    GridClickablePromotionButtonComponent,
    GridFixedButtons,
    headersTranslate,
    sortingsMenuDisabled,
} from 'src/app/shared/components';
import { distinctUntilChanged, from } from 'rxjs';
import { PromotionsFe, QueryPagination, RowModelType, TableData } from 'src/app/shared/model/model';
import { ToolbarEventsService } from 'src/app/core/services';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';
import { $state, errorsLogger } from 'app/ajs-upgraded-providers';
import { ModuleEvents } from 'src/app/core/services/toolbar-events/toolbar-events.model';
import { ItemLabelsDialogService, ConfirmDialogService } from 'src/app/dialogs';
import { GridCellFormatterService } from 'src/app/shared/components/grid/utils';
import { PromotionsFields } from '../promotions-form/promotions-form.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { EntityManagerService } from 'src/app/core';
import { Transition } from 'angular-ui-router';
import { PromotionsItems } from 'tilby-models';
import { DevLogger } from "src/app/shared/dev-logger";
import { UtilsPromotionsService } from '../../services';
import { GridClientSideComponent } from "src/app/shared/components/grid-client-side/grid-client-side.component";

const promotionOptions = {
    pagination: false,
    orderby_asc: 'name'
};

@Component({
  selector: 'promotions-showcase',
  templateUrl: './promotions-showcase.component.html',
  styleUrls: ['./promotions-showcase.component.scss'],
  providers: [OnDestroyService, UtilsPromotionsService]
})
export class PromotionsShowcaseComponent implements OnInit, TilbyToolbar {
    private readonly utilsPromotionsService = inject(UtilsPromotionsService);
    private readonly entityManager = inject(EntityManagerService);
    private readonly translate = inject(TranslateService);
    private readonly errorsLoggerService = inject(errorsLogger);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly gridCellFormatterService = inject(GridCellFormatterService);
    private readonly onDestroyService = inject(OnDestroyService);
    private readonly itemLabelsDialogService = inject(ItemLabelsDialogService);
    private readonly _snackBar = inject(MatSnackBar);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly state = inject($state);


    @ViewChild('appGridComponent', {static: true}) gridRef!: GridClientSideComponent;
    rowModelType: RowModelType = 'clientSide';
    isSelected: boolean;
    gridPath = this.state.$current.name.split('.').pop() === 'promotions';
    promotions: Array<PromotionsFields>;
    isProcessing = signal<boolean>(false);

    fixedButtons: GridFixedButtons;
    readonly query: Readonly<QueryPagination>;

    tableData: TableData[] = [{
        rowData$: from(this.entityManager.promotions.fetchCollectionOnline(promotionOptions).then(res => res && Array.isArray(res) ? this.utilsPromotionsService.promotionsFormatter(res): [])),
        dataType: new PromotionsFe(),
        headersTranslate: headersTranslate.promotions_showcase,
        columnsFormatter: this.gridCellFormatterService.promotions,
        filtersMenuDisabled: true,
        sortingsMenuDisabled: sortingsMenuDisabled.promotions,
    }];

    constructor() {
        this.isSelected = false;
        this.fixedButtons = {
            checkbox: { visible: false, lockPosition: 'left' },
            customButton: { visible: true, cellRenderer: GridClickablePromotionButtonComponent, lockPosition: 'right' }
        };
        this.query = {
            pagination: true,
            per_page: 30,
            page: 0,
        };
        this.state.router.transitionService.onSuccess({}, (transition: Transition&any) => {
            this.gridPath = transition._targetState._identifier.split('.').pop() === 'promotions';
            if (this.gridPath) {
                this.createToolbarButtons();
            }
        });
        this.promotions = [];
    }

    // START - LIFECYCLE
    ngOnInit(): void {
        // first time
        this.createToolbarButtons();
        this.setModuleTitle();
        // on show again
        this.state.router.transitionService.onSuccess({to: 'app.new.promotions'}, (transition: Transition) => {
            //if(!!transition.params().remove) this.gridRef.onRemoveSelected(transition.params().remove);
            this.createToolbarButtons();
            this.refreshPromotions();
            this.refreshDataTable();
        });

        this.toolbarEventsService.events.pipe(distinctUntilChanged(), this.onDestroyService.takeUntilDestroy).subscribe(e => this.callbackToToolbarClick(e));
        this.refreshPromotions();
    }
    // END - LIFECYCLE

    createToolbarButtons() {
        this.toolbarEventsService.buttons$.next({
            barButtons: [
                {
                    isIt: () => true,
                    name: 'add_new_promotion',
                    icon: () => 'playlist_add',
                    click: _ => this.state.go("app.new.promotions.new")
                }
            ],
            panelButtons: []
        });
    }

    async callbackToToolbarClick(event: Partial<ModuleEvents> & { id?: number }) {
        if ('open_template_editor' in event) this.log("open_template_editor");
    }

    setModuleTitle() {
        this.toolbarEventsService.moduleTitle.next('PROMOTIONS');
    }

    rowClicked({data}: RowClickedEvent) {
        this.log('rowClicked', data);
        this.state.go("app.new.promotions.details", {promotionId: data.id});
    }

    deletePromotions(ids: number[]) {
        this.log('deletePromotions', ids);
        ids.forEach(id => this.entityManager.promotions.deleteOneOnline(id));
    }

    crud({method, id}: Rest) {
        this.log('crud', method, id);
        if (method === 'delete') this.gridRef.onRemoveSelected(id);
    }

    actions({action, data}: CliccableActionPromotion) {
        switch (action) {
            case 'details':
                this.goToDetails(data);
                break;
            case 'deactivate':
                this.toggleActivePromotion(data);
                break;
            case 'duplicate':
                this.duplicatePromotion(data);
                break;
            case 'print-labels':
                this.generateLabels(data);
                break;
            case 'delete':
                this.deletePromotion(data);
                break;
        }
    }

    goToDetails(row: PromotionsFe) {
        this.state.go('app.new.promotions.details', { promotionId: row.id });
    }

    async toggleActivePromotion(row: PromotionsFe) {
        const promotion: PromotionsFields | undefined = this.getPromotionsById(row.id);
        if(promotion) {
            this.isProcessing.set(true);
            const promotionToChange = {...promotion};
            const oldActiveStatus = promotionToChange.active;
            promotionToChange.active = !promotionToChange.active;
            try {
                const res = await this.entityManager.promotions.putOneOnline(promotionToChange);
                if(res) {
                    //this.gridRef?.refreshGrid();
                    ////this.updateItems(this.utilsPromotionsService.promotionsFormatter(res));
                    this.refreshPromotions(res);
                    this.refreshDataTable();
                    const msgDeactiveSuccess = this.translate.instant('PROMOTIONS.SHOWCASE.DEACTIVATE_SUCCESS');
                    const msgActiveSuccess = this.translate.instant('PROMOTIONS.SHOWCASE.ACTIVATE_SUCCESS');
                    const label_snackBar = oldActiveStatus ? msgDeactiveSuccess : msgActiveSuccess;
                    this._snackBar.open(
                        label_snackBar, undefined,
                        {duration: 3000, horizontalPosition: 'left', verticalPosition: 'bottom'}
                    );
                }
            } catch (e) {
                this.errorsLoggerService.err(e);
            } finally {
                this.isProcessing.set(false);
            }
        }
    }

    async duplicatePromotion(row: PromotionsFe) {
        const promotion = this.getPromotionsById(row.id);
        if(promotion) {
            const confirm = await this.confirmDialogService.openDialog({
                data: {
                    messageLabel: 'PROMOTIONS.SHOWCASE.COPY_TITLE',
                    confirmLabel: 'APPLICATION.CONFIRM_DIALOG.YES',
                    cancelLabel: 'APPLICATION.CONFIRM_DIALOG.NO'
                }
            });

            if (confirm) {
                this.isProcessing.set(true);
                const clonedPromotion = {...promotion, ...{
                    name: promotion.name + ' copy',
                    active: false,
                    id: undefined
                }};

                clonedPromotion.items?.forEach((item: PromotionsItems) => {
                    delete item.id;
                });

                clonedPromotion.required_items?.forEach((item: PromotionsItems) => {
                    delete item.id;
                });

                try {
                    const res = await this.entityManager.promotions.postOneOnline(clonedPromotion);
                    this.log('duplicatePromotion res', res);
                    if(res) {
                        const label_snackBar = this.translate.instant('PROMOTIONS.SHOWCASE.CLONE_SUCCESS');
                        this._snackBar.open(
                            label_snackBar, undefined,
                            {duration: 3000, horizontalPosition: 'left', verticalPosition: 'bottom'}
                        );
                        this.refreshPromotions(res);
                        this.refreshDataTable();
                    }
                } catch (e) {
                    this.errorsLoggerService.err(e);
                } finally {
                    this.isProcessing.set(false);
                }
            }
        }
    }

    generateLabels(row: PromotionsFe) {
        const promotion = this.getPromotionsById(row.id);

        if(promotion) {
            this.itemLabelsDialogService.openDialog({data: {promotion}}).then(res => this.log('RES', res));
        }
    }

    async deletePromotion(promotion: PromotionsFe) {
        const messageLabel = this.translate.instant('PROMOTIONS.SHOWCASE.CONFIRM_DELETE', { name: promotion.name });
        const confirm = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: messageLabel,
                confirmLabel: 'APPLICATION.CONFIRM_DIALOG.YES',
                cancelLabel: 'APPLICATION.CONFIRM_DIALOG.NO'
            }
        });

        if (confirm && promotion.id) {
            this.isProcessing.set(true);
            try {
                const res = await this.entityManager.promotions.deleteOneOnline(promotion.id);
                this.log('deletePromotion res', res);
                if(res) {
                    const label_snackBar = this.translate.instant('PROMOTIONS.SHOWCASE.DELETE_SUCCESS');
                    this._snackBar.open(
                        label_snackBar, undefined,
                        {duration: 3000, horizontalPosition: 'left', verticalPosition: 'bottom'}
                    );
                    this.refreshPromotions();
                    this.refreshDataTable();
                }
            } catch (e) {
                this.errorsLoggerService.err(e);
            } finally {
                this.isProcessing.set(false);
            }
        }
    }

    getPromotionsById(id: number): PromotionsFields | undefined {
        return this.promotions.find(promotion => promotion.id === id);
    }

    async getRemotePromotions() {
        try {
            const res = await this.entityManager.promotions.fetchCollectionOnline(promotionOptions);
            return (res && Array.isArray(res)) ? res : [];
        } catch (e) {
            this.errorsLoggerService.err(e);
            return [];
        }
    }

    async refreshPromotions(promoToRefresh?: PromotionsFields) {
        if(promoToRefresh) {
            const index = this.promotions.findIndex(promotion => promotion.id === promoToRefresh.id);
            if (index !== -1) {
                this.promotions[index] = promoToRefresh;
            }
        } else {
            // scarica tutto
            this.promotions = await this.getRemotePromotions();
        }
    }

    refreshDataTable() {
        //const rowsPromotions = this.utilsPromotionsService.promotionsFormatter(this.promotions);
        //this.tableData[0].rowData$ = from([rowsPromotions]);
        this.tableData[0].rowData$ = from(this.entityManager.promotions.fetchCollectionOnline(promotionOptions)
        .then(res => res && Array.isArray(res) ? this.utilsPromotionsService.promotionsFormatter(res) : []));
    }

    private log(...args: any[]) {
        DevLogger.log('PROMOTIONS_SHOWCASE_COMPONENT', ...args);
    }
}

import angular from 'angular';
import _ from 'lodash';

angular.module('cashregister').factory('selectSaleItemCombination', ["$mdDialog", "checkManager", function($mdDialog, checkManager) {

    selectSaleItemCombinationController.$inject = ["$scope", "$mdDialog", "item", "stockDictionary", "newSaleUtils"];
    function selectSaleItemCombinationController($scope, $mdDialog, item, stockDictionary, newSaleUtils) {
        $scope.item = item;
        $scope.selectedCombination = undefined;
        $scope.stockDictionary = stockDictionary;
        $scope.priceList = newSaleUtils.getCurrentPriceList();

        const parseCombinations = () => {
            if (!Array.isArray($scope.item.combinations)) {
                return [];
            }

            return $scope.item.combinations.map((combination) => {
                return {
                    combinationRef: combination,
                    name: (combination.combination_values || []).map((value) => value.variation_value).join(', '),
                    price: combination[`price${$scope.priceList}`]
                };
            });
        };

        $scope.combinations = parseCombinations();

        $scope.goToItemInfo = function() {
            $scope.context = "showItemInfo";
        };

        $scope.goToCombinations = function() {
            $scope.context = "showCombinations";
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.confirm = function() {
            $mdDialog.hide($scope.selectedCombination);
        };

        $scope.getCombinationStockAvailability = function(combination) {
            if (!!checkManager.getPreference('cashregister.show_stock_on_showcase') && $scope.item.stock_type === 'simple' && $scope.stockDictionary.get('combination_id_' + combination.combinationRef.id)) {
                switch ($scope.stockDictionary.get('combination_id_' + combination.combinationRef.id).available) {
                    case 'available': case 'producible':
                        return 'stock-info-available';
                    case 'unavailable':
                        return 'stock-info-unavailable';
                    case 'alert':
                        return 'stock-info-alert';
                    default:
                        return 'stock-info-nostock';
                }
            }
        };


    }

    var selectSaleItemCombination = {
        show: function(item, stockDictionary) {
            return $mdDialog.show({
                    controller: selectSaleItemCombinationController,
                    template: require('./select-sale-item-combination.html'),
                    locals: {
                        item: item,
                        stockDictionary: stockDictionary
                    }
                })
                .then(function(answer) {
                    return answer;
                });
            }
    };

    return selectSaleItemCombination;
}]);

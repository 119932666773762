import {
    IProvidedFilter,
    TextFilterModel,
    SetFilterModel
} from 'ag-grid-community';

import {customStringConvertionFilter} from '../components/grid';
import { ResponseViewFiles } from 'tilby-models';

type CustomFilterType = 'data' | 'text' | 'set' | number;

export interface CustomUIFilter {
    type: CustomFilterType;
    sortable:boolean;
    filterable:boolean;

    getUIFilter(params:any):any;

    // getUIFilterValue(params);
    getConversionFilter(params:any&IProvidedFilter):string;
}

type DocumentCustomValue = '0_RECEIPT' | '1_INVOICE' | '2_OTHER';

export class TypeDocumentFilter implements CustomUIFilter {
    type: CustomFilterType = 'set';
    sortable = false;
    filterable = true;
    static documentCustomValues: DocumentCustomValue[] = ['0_RECEIPT', '1_INVOICE', '2_OTHER'];

    constructor() {
    }

    getUIFilter(params: any) {
        return params.success(TypeDocumentFilter.documentCustomValues);
    }

    // getUIFilterValue(params){
    //     return params;
    // }
    getConversionFilter(params: SetFilterModel) {
        let query = '';
        params.values.forEach((value: string|DocumentCustomValue|null) => {
            switch (value) {
                case '0_RECEIPT':
                    query = query.concat(`,sale_documents.document_type=fiscal_receipt,sale_documents.document_type=commercial_doc,sale_documents.document_type=refund_doc,sale_documents.document_type=void_doc,sale_documents.document_type=generic_receipt`);
                    break;
                case '1_INVOICE':
                    query = query.concat(`,sale_documents.document_type=invoice,sale_documents.document_type=receipt_invoice,sale_documents.document_type=summary_invoice,sale_documents.document_type=credit_note,sale_documents.document_type=shipping_invoice,sale_documents.document_type=e_invoice,sale_documents.document_type=summary_e_rc,sale_documents.document_type=summary_e_nrc,sale_documents.document_type=generic_invoice`);
                    break;
                case '2_OTHER':
                    query = query.concat(`,sale_documents.document_type=generic_document`);
                    break;
            }
        });
        query = query.replace(',', '');
        return `OR(${query})`;
    }
}

export class CustomerNameFilter implements CustomUIFilter {
    type: CustomFilterType = 'text';
    sortable = false;
    filterable = true;
    constructor() {
    }

    getUIFilter(params:any) {
    };

    getConversionFilter(params: TextFilterModel) {
        let query = '';

        const {prefix, x_queryPrefix, inputFilter} = customStringConvertionFilter(params,'AND');
        const inputFilterNoDashes = inputFilter.replace(/-/g, ' ');
        inputFilterNoDashes.split(/ +/g).forEach((value: string) => {
            query = query.concat(`,OR(sale_customer.first_name${prefix}=${value},sale_customer.last_name${prefix}=${value},sale_customer.company_name${prefix}=${value})`);
        });
        query = query.replace(',', '');
        return `${x_queryPrefix}(${query})`;
    }
}

export class PrinterNameFilter implements CustomUIFilter {
    type: CustomFilterType = 'text';
    sortable = false;
    filterable = true;
    constructor() {
    }

    getUIFilter(params:any) {
    };

    getConversionFilter(params: TextFilterModel) {
        const {prefix, x_queryPrefix, inputFilter} = customStringConvertionFilter(params,'OR');
        const query = `sale_documents.printer_name${prefix}=${inputFilter}`;
        return `${x_queryPrefix}(${query})`;
    }
}
export class AmountPrepaidFilter implements CustomUIFilter {
    type: CustomFilterType = 0;
    sortable = false;
    filterable = false;
    constructor() {
    }

    getUIFilter(params:any) {
    };

    getConversionFilter(params: TextFilterModel) {
        return ``;
    }
}

export class PaymentTypeFilter implements CustomUIFilter {
    type: CustomFilterType = 'text';
    sortable = false;
    filterable = true;
    constructor() {
    }

    getUIFilter(params:any) {
    };

    getConversionFilter(params: TextFilterModel) {
        let query = '';

        const {prefix, x_queryPrefix, inputFilter} = customStringConvertionFilter(params,'AND');
        const inputFilterNoDashes = inputFilter.replace(/-/g, ' ');
        inputFilterNoDashes.split(/ +/g).forEach((value: string) => {
            query = query.concat(`,OR(payments.payment_method_name${prefix}=${value})`);
        });
        query = query.replace(',', '');
        return `${x_queryPrefix}(${query})`;
    }
}

export class SequentialNumberFilter implements CustomUIFilter {
    type: CustomFilterType = 'text';
    sortable = false;
    filterable = true;
    constructor() {
    }

    getUIFilter(params:any) {
    };

    getConversionFilter(params: TextFilterModel) {
        let query = '';

        const {prefix, x_queryPrefix, inputFilter} = customStringConvertionFilter(params,'AND');
        const inputFilterNoDashes = inputFilter.replace(/-/g, ' ');
        inputFilterNoDashes.split(/ +/g).forEach((value: string) => {
            query = query.concat(`,OR(sale_documents.sequential_number${prefix}=${value})`);
        });
        query = query.replace(',', '');
        return `${x_queryPrefix}(${query})`;
    }
}

type TypeCashMovementCustomValue = 'income' | 'outcome';

export class TypeCashMovementFilter implements CustomUIFilter {
    type: CustomFilterType = 'set';
    sortable = false;
    filterable = true;
    static typeCashMovementValues: TypeCashMovementCustomValue[] = ['income', 'outcome'];

    constructor() {
    }

    getUIFilter(params: any) {
        return params.success(TypeCashMovementFilter.typeCashMovementValues);
    }

    getConversionFilter(params: SetFilterModel) {
        let query = '';

        params.values.forEach((value: string|TypeCashMovementCustomValue|null) => {
            switch (value) {
                case 'income':
                    query = query.concat(`,type=income`);
                    break;
                case 'outcome':
                    query = query.concat(`,type=outcome`);
                    break;
            }
        });
        query = query.replace(',', '');
        return `OR(${query})`;
    }
}

type AccountCashMovementCustomValue = 'cash' | 'other';

export class AccountCashMovementFilter implements CustomUIFilter {
    type: CustomFilterType = 'set';
    sortable = false;
    filterable = true;
    static accountCashMovementValues: AccountCashMovementCustomValue[] = ['cash', 'other'];

    constructor() {
    }

    getUIFilter(params: any) {
        return params.success(AccountCashMovementFilter.accountCashMovementValues);
    }

    getConversionFilter(params: SetFilterModel) {
        let query = '';

        params.values.forEach((value: string|AccountCashMovementCustomValue|null) => {
            switch (value) {
                case 'cash':
                    query = query.concat(`,account=cash`);
                    break;
                case 'other':
                    query = query.concat(`,account=other`);
                    break;
            }
        });
        query = query.replace(',', '');
        return `OR(${query})`;
    }
}

export class ResponseViewFilesStatusFilter implements CustomUIFilter {
    type: CustomFilterType = 'set';
    sortable = false;
    filterable = true;
    static responseViewFilesStatus: ResponseViewFiles.StatusEnum[] = ["completed", "loading", "processed", "created"];

    getUIFilter(params: any) {
        return params.success(ResponseViewFilesStatusFilter.responseViewFilesStatus);
    }

    getConversionFilter(params: TextFilterModel) {
        return ``;
    }
}

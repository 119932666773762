import {
  Component,
  OnInit,
  ViewChild,
  inject
} from "@angular/core";

import { TranslateService } from "@ngx-translate/core";
import { TilbyDatePipe } from "@tilby/tilby-ui-lib/pipes/tilby-date";
import { subscribeInComponent } from "@tilby/tilby-ui-lib/utilities";
import { $state, restManager } from "app/ajs-upgraded-providers";

import {
  Subscription,
  catchError,
  from,
  interval,
  map,
  of
} from "rxjs";

import {
  ConfigurationManagerService,
  ModuleEvents,
  ToolbarEventsService
} from "src/app/core";

import {
  AlertDialogService,
  ConfirmDialogService,
  GridDownloadDialogService,
} from "src/app/dialogs";

import {
  GridCellFormatterService,
  GridClientSideComponent,
  GridFixedButtons,
  headersTranslate
} from "src/app/shared/components";

import { GridClickableButtonFileImporterComponent } from "src/app/shared/components/grid/grid-clickable-button-file-importer/grid-clickable-button-file-importer.component";
import {
    DataExport,
    FileImporterFe,
    TableData
} from "src/app/shared/model/model";

import { autobind } from "src/app/models/decorators.model";

@Component({
    selector: "app-file-importer",
    templateUrl: "./file-importer-showcase.component.html",
    styleUrls: ["./file-importer-showcase.component.scss"],
})
export class FileImporterComponent implements OnInit {
    @ViewChild("appGridComponent", { static: true }) gridRef!: GridClientSideComponent;

    private readonly alertDialogService = inject(AlertDialogService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly gridCellFormatterService = inject(GridCellFormatterService);
    private readonly gridDownloadDialogService = inject(GridDownloadDialogService);
    private readonly restManagerService = inject(restManager);
    private readonly state = inject($state);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly translateService = inject(TranslateService);

    private movColumnsSwitch = false;
    private isAdvancedFilterActive = false;
    showProgressSpinner = false;
    selectRowUIEnabled = true;
    fileIntervalSubscription = new Subscription();
    checkFileStatusSubscription = new Subscription();
    isTabacchiEnabled = this.configurationManagerService.isModuleEnabled('tobacco_updates');
    isUveEnabled = this.configurationManagerService.getPreference('uve.enabled') || false;
    fixedButtons: GridFixedButtons = {
        checkbox: { visible: false, lockPosition: "left" },
        customButton: {
          visible: true,
          cellRenderer: GridClickableButtonFileImporterComponent,
          lockPosition: "right",
      },
    };
    gridOptions = {
      loadingCellRendererParams: {
        loadingMessage: 'One moment please...',
      },
      enableCellChangeFlash: false
    };
    rows: FileImporterFe[] = [];
    rowsBeforeResponse: FileImporterFe[] = [];
    startRequest: number = 0;
    tableData: TableData[] = [
        {
            rowData$: from<Promise<{files: FileImporterFe[]}>>(this.restManagerService.getList('importing/files'))
                .pipe(
                  map(res => {
                    this.gridRef.gridApi?.setGridOption('animateRows', false);
                    this.rows = res.files;
                    this.checkFilesStatus();
                    return res.files.sort((fileA, fileB) => (TilbyDatePipe.toUtcDateMillis(fileB.createdAt) - TilbyDatePipe.toUtcDateMillis(fileA.createdAt)))
                  }),
                  catchError((error) => {
                    console.error(this.translateService.instant("FILE_IMPORTER.SHOWCASE.GENERIC_ERROR"));
                    return of([]);
                  })
                ),
            dataType: new FileImporterFe(),
            headersTranslate: headersTranslate.file_importer,
            columnsFormatter: this.gridCellFormatterService.file_imported,
        },
    ];

    constructor() {
      subscribeInComponent(this.toolbarEventsService.events, e => this.callbackToToolbarClick(e));
      this.openSubscription();
    }

    openSubscription() {
      this.fileIntervalSubscription = interval(this.showProgressSpinner ? 1000 : 10000).subscribe(() =>
        this.restManagerService.getList('importing/files')
          .then(({files} : {files: FileImporterFe[]}) => {
            this.rows = files;
            if(this.rows.length > this.rowsBeforeResponse.length) {
              this.showProgressSpinner = false;
              this.fileIntervalSubscription.unsubscribe();
              this.openSubscription();
            }
              this.tableData = [
                {
                    rowData$: from<Promise<{files: FileImporterFe[]}>>(this.restManagerService.getList('importing/files'))
                        .pipe(
                          map(res => {
                            return res.files.sort((fileA, fileB) => (TilbyDatePipe.toUtcDateMillis(fileB.createdAt) - TilbyDatePipe.toUtcDateMillis(fileA.createdAt)))
                          })
                        ),
                    dataType: new FileImporterFe(),
                    headersTranslate: headersTranslate.file_importer,
                    columnsFormatter: this.gridCellFormatterService.file_imported,
                },
              ]
          })
      );

      this.checkFileStatusSubscription = interval(this.showProgressSpinner ? 1000 : 10000).subscribe(() => this.checkFilesStatus());
    }

    checkFilesStatus() {
      //Check if there are some file with status "loading"
      if(!this.rows.length) return;
      const result = this.rows.find(file => (file.status as unknown !== 'completed'));
      if(!result) {
        this.fileIntervalSubscription.unsubscribe();
        this.checkFileStatusSubscription.unsubscribe();
      }
    }

    ngOnInit(): void {
      this.createToolbarButtons();
      this.isMagoEnabled();
    }

    ngAfterViewInit(): void {
      this.gridRef.gridApi?.setGridOption('animateRows', false);
      this.checkFilesStatus();
    }

    createToolbarButtons(){
      this.toolbarEventsService.moduleTitle.next("FILE_IMPORTER");
      this.toolbarEventsService.searchBarValue$.next('');
      this.toolbarEventsService.searchBarAction$.next({ openToolPanel: 'filters' });
      this.toolbarEventsService.searchBarActionFilled$.next({ openToolPanel: 'filters' });
      this.toolbarEventsService.buttons$.next({
        barButtons: [
          {
            isIt: () => true,
            name: 'import_new_file',
            icon: () => 'playlist_add',
            click: _ => {
              this.state.go("app.new.file_importer.add")
            }
        },
        ],
        panelButtons: [
          {
            isIt: () => true,
            name: 'columns_panel',
            icon: () => 'view_week',
            label: () => 'TOPBAR.ACTIONS.COLUMN_SELECTED',
            click: _ => this.toolbarEventsService.events.next({openToolPanel: 'columns'})
        },
        {
            isIt: () => true,
            name: 'movable_columns',
            icon: () => 'drag_indicator',
            label: () => this.movColumnsSwitch ? 'TOPBAR.ACTIONS.DISABLED_COLUMN_MOVEMENT': 'TOPBAR.ACTIONS.ENABLED_COLUMN_MOVEMENT',
            click: _ => this.toolbarEventsService.events.next({type: 'movColumnsSwitch', movColumnsSwitch: this.movColumnsSwitch = !this.movColumnsSwitch})
        },
        {
            isIt: () => true,
            name: 'save_columns_position',
            icon: () => 'save',
            label: () => 'TOPBAR.ACTIONS.SAVE_COLUMN_PREFERENCE',
            click: _ => this.toolbarEventsService.events.next({save: true})
        },
        {
            isIt: () => true,
            name: 'advanced_filter',
            icon: () => this.isAdvancedFilterActive ? 'filter_alt_off' : 'filter_list_alt',
            label: () => this.isAdvancedFilterActive ? 'TOPBAR.ACTIONS.ADVANCED_FILTERS_DISABLED' : 'TOPBAR.ACTIONS.ADVANCED_FILTERS_ENABLED',
            click: _ => this.toolbarEventsService.events.next({type: 'advFiltersSwitch', advFiltersSwitch: this.isAdvancedFilterActive = !this.isAdvancedFilterActive})
        },
        {
            isIt: () => this.isTabacchiEnabled ? true : false,
            name: 'tabacchi',
            icon: () => 'smoking_rooms',
            label: () => 'TOPBAR.ACTIONS.IMPORT_TABACCHI',
            click: _ => {
              if(this.isMagoEnabled()) {
                this.alertDialogService.openDialog({data: {messageLabel: 'FILE_IMPORTER.SHOWCASE.MAGO_NOT_ENABLED'}});
                return;
              }

              this.getTabacchi();
            }
        },
        {
            isIt: () => this.isUveEnabled ? true : false,
            name: 'uve',
            icon: () => 'liquor',
            label: () => 'TOPBAR.ACTIONS.IMPORT_UVE',
            click: _ => this.getUveImporting()
        },
        {
            isIt: () => false,
            name: 'export',
            icon: () => 'download',
            label: () => 'TOPBAR.ACTIONS.EXPORT',
            click: async _ => await this.gridDownloadDialogService.openDialog('file_importer',{data: this.tableData.map(tableData => ({...tableData,sortFilterQuery: this.gridRef.getSortFilterQuery(),countSelectedRows:this.gridRef.gridApi?.getSelectedRows().length}))},'files').then(res=>!!res&&this.onExport(res,'files'))
        },
        ]
      })
    }

    async callbackToToolbarClick(event: Partial<ModuleEvents>&{id?:number}) {
      if ("search" in event) {
        this.gridRef.onFilterTextBoxChanged(event.search);
      } else if ("openToolPanel" in event && event.openToolPanel) {
        this.gridRef.openToolPanel(event.openToolPanel);
      } else if ("type" in event && event.type === 'advFiltersSwitch') {
        this.gridRef.advancedFilterSwitch(event.advFiltersSwitch || false);
      } else if ("type" in event && event.type === 'movColumnsSwitch') {
        this.gridRef.columnMovableSwitch(event.movColumnsSwitch || false);
      } else if ("save" in event) {
        await this.gridRef.openDialogSaveColumnsPreference();
      }
    }

    getTabacchi = async () => {
      try {
          const result = await this.restManagerService.getList('importing/files');
          const importFiles = result.files.sort((fileA: any, fileB: any) => (TilbyDatePipe.toUtcDateMillis(fileB.createdAt) - TilbyDatePipe.toUtcDateMillis(fileA.createdAt)))
          let pendingFile = importFiles.find((file: any) => (file.name.includes("Tabacchi") && file.status !== "completed"));

          if(pendingFile) {
              this.alertDialogService.openDialog({data: {messageLabel: 'FILE_IMPORTER.SHOWCASE.ERROR_TABACCHI'}});
          } else {
              this.restManagerService.post('importing/file/tabacchi')
              .then((res: any) => {
                if(res.message === 'ok') {
                  this.tableData = [
                    {
                      rowData$: from<Promise<{files: FileImporterFe[]}>>(res)
                      .pipe(
                        map(res => {
                          return res.files.sort((fileA, fileB) => (TilbyDatePipe.toUtcDateMillis(fileB.createdAt) - TilbyDatePipe.toUtcDateMillis(fileA.createdAt)))
                        })
                      ),
                        dataType: new FileImporterFe(),
                        headersTranslate: headersTranslate.file_importer,
                        columnsFormatter: this.gridCellFormatterService.file_imported,
                      },
                  ];
                  this.alertDialogService.openDialog({data: {messageLabel: 'FILE_IMPORTER.SHOWCASE.SUCCESS_TABACCHI'}});
                }
              })
          }
      } catch(err) {
          console.debug(err);
          this.alertDialogService.openDialog({data: {messageLabel: 'FILE_IMPORTER.SHOWCASE.GENERIC_ERROR'}});
      }
    };

    getUveImporting = async () => {
      const answer = await this.confirmDialogService.openDialog({
        data: {
          messageLabel: 'FILE_IMPORTER.SHOWCASE.ANSWER_UVE'
        }
      });

      if(!answer) {
        return;
      }

      try {
          const result = await this.restManagerService.getList('importing/files');
          const importFiles = result.files.sort((fileA: any, fileB: any) => (TilbyDatePipe.toUtcDateMillis(fileB.createdAt) - TilbyDatePipe.toUtcDateMillis(fileA.createdAt)))

          let pendingFile = importFiles.find((file: any) => (file.name.includes("UVE") && file.status !== "completed"));

          if(pendingFile) {
              this.alertDialogService.openDialog({data: {messageLabel: 'FILE_IMPORTER.SHOWCASE.ERROR_UVE'}});
          } else {
              this.startRequest = new Date().getTime();
              let res = await this.restManagerService.getOne('importing/uve');
              
              if(res.message === 'ok') {
                  this.tableData = [
                    {
                      rowData$: from<Promise<{files: FileImporterFe[]}>>(res)
                      .pipe(
                        map(res => {
                          return res.files.sort((fileA, fileB) => (TilbyDatePipe.toUtcDateMillis(fileB.createdAt) - TilbyDatePipe.toUtcDateMillis(fileA.createdAt)))
                        })
                      ),
                        dataType: new FileImporterFe(),
                        headersTranslate: headersTranslate.file_importer,
                        columnsFormatter: this.gridCellFormatterService.file_imported,
                      },
                  ];
                  this.alertDialogService.openDialog({data: {messageLabel: 'FILE_IMPORTER.SHOWCASE.SUCCESS_UVE'}});
              }
          }
      } catch(err: any) {
          const endRequest = new Date().getTime();
          const seconds = (endRequest - this.startRequest) / 1000;

          // === -1 for web app and >500 for electron
          if(err.status === -1 && seconds >= 30|| err.status > 500){
            this.showProgressSpinner = true;
            this.fileIntervalSubscription.unsubscribe();
            this.openSubscription();
            this.rowsBeforeResponse = this.rows;
          } else if(err.status <= 500){
            this.alertDialogService.openDialog({data: {messageLabel: 'FILE_IMPORTER.SHOWCASE.IMPORT_FAILED_UVE'}});
          }
      }
  };

    isMagoEnabled = () => {
      const integrations_mago_publish_sales = this.configurationManagerService.getPreference("integrations.mago.publish_sales");
      const integrations_mago_publish_customers = this.configurationManagerService.getPreference('integrations.mago.publish_customers');
      return integrations_mago_publish_sales || integrations_mago_publish_customers;
    };

    @autobind
    onExport(dataExport: DataExport,tableToCall?:string) {
        this.gridRef?.onBtnExport(dataExport,tableToCall);
    }

    ngOnDestroy() {
      this.fileIntervalSubscription.unsubscribe();
      this.checkFileStatusSubscription.unsubscribe();
    }
}

import angular from 'angular';
import { camelCase } from 'lodash';
import { EVENTS_IOT, IotBroadcastCallback, IotEmitter, MESSAGE_ORIGIN, MessagingConfig, Notification, OnMessageIot } from "./iot-models";
import { IotHistoryStorage } from "./iot-history-storage";
import { mqtt5 } from 'aws-crt/dist.browser/browser';
import { EntityManagerService } from 'src/app/core';
const entitiesToDiscard = ["daily_closings", "stock_movements", "cash_movements"];
const userSpecificEntities = ["user_preferences"];

export class IotMessageManager {
    private MessagingConfig?: MessagingConfig;

    constructor(
        private $rootScope: any,
        private errorsLogger: any,
        private progressivesManager: any,
        private iotHistoryStorage: IotHistoryStorage,
        private entityManagerService: EntityManagerService,
        private toast: any,
        private $translate: any
    ) {
    }

    private logger(...args: any[]) {
        this.errorsLogger.debug("[ IotMessageManager ]: ", ...args);
    }
    private logger_error(...args: any[]) {
        this.errorsLogger.err("[ IotMessageManager ]: ", ...args);
    }
    bootstrap(MessagingConfig: MessagingConfig) {
        this.MessagingConfig = MessagingConfig;
    }
    emitter: IotEmitter = (event_name: EVENTS_IOT, message: any) => {
        this.$rootScope.$emit(event_name, { message });
    }
    delayNextCheckId = (message: any) => {
        this.emitter(EVENTS_IOT.iot_delay_next_id, message);
    }
    /**
     * @description callback used from iot manager when a new message arrives from cloud
     */
    messageIot(eventData: mqtt5.MessageReceivedEvent) {
        const decoder = new TextDecoder('utf8');
        if (eventData.message.payload) {
            let message: Notification = JSON.parse(decoder.decode(new Uint8Array(eventData.message.payload as Buffer)));
            this.logger("processing from iot");
            this.processMessage(message, false).then(x => { return; });
        }
    }

    handleEntity = async (notification: Notification) => {
        const entityName = camelCase(notification.entity_name) as keyof (EntityManagerService);
        const entityManager = this.entityManagerService[entityName];

        try {
            this.logger(`save ${entityName} on msg_id: ${notification.msg_id}`);
            // if this is the last message from cloud => save entire entity without doing api otherwise fetch entity from cloud
            const msgToNotify = {
                action: notification.type as ('CREATED' | 'UPDATED'),
                externalClient: notification.external_client,
                noOrigin: notification.session_origin ? false : true
            }

            if (notification.msg_id === this.iotHistoryStorage.lastMessageId && notification.entities?.length) {
                if ('saveOneOffline' in entityManager) {
                    await entityManager.saveOneOffline(notification.entities[0], { dirty: false, notification: msgToNotify })
                }
            } else {
                if ('fetchOneOnline' in entityManager) {
                    await entityManager.fetchOneOnline(notification.entity_id, true, { notification: msgToNotify });
                }
            }

            return null;
        } catch (error) {
            this.logger_error(`Error handle entity ${entityName}`, error);
            this.iotHistoryStorage.unsetMessage(notification);
        }
    }

    /**
     * @description callback used to apply incoming message and to notify app of the new event. MESSAGE_ORIGIN decides what events can come from notification
     */
    broadcast: IotBroadcastCallback = (msg: Notification) => {
        const entityName = camelCase(msg.entity_name) as keyof (EntityManagerService);
        const entityManager = this.entityManagerService[entityName] || {};

        this.logger(`broadcast msg with type: ${msg.type}, entity_name: ${msg.entity_name} and clientId: ${msg.client_id}`);

        if (msg.entity_id) {
            switch (msg.type) {
                case MESSAGE_ORIGIN.DELETED:
                case MESSAGE_ORIGIN.CLOSED:
                    if ('deleteOneOffline' in entityManager) {
                        entityManager.deleteOneOffline(msg.entity_id, {
                            notification: {
                                action: msg.type,
                                externalClient: msg.external_client,
                                noOrigin: msg.session_origin ? false : true
                            }
                        });
                    }
                    break;
                case MESSAGE_ORIGIN.CREATED:
                case MESSAGE_ORIGIN.UPDATED:
                    //fare prima fetchOneOffline
                    /**
                        this is done in order to prevent overwrite of entity that has been modified online and offline
                        in this way only BE decide which one must be taken (generally speaking is matching last_update_at)
                     */
                    if ('fetchOneOffline' in entityManager) {
                        entityManager.fetchOneOffline(msg.entity_id).then(
                            (res: any) => {
                                // se c'è dirty === true | 1 allora balza handle msg
                                if (res?.dirty) {
                                    this.logger(`${entityName} is discarded because entity is in dirty state`);
                                    return;
                                }

                                this.handleEntity(msg).then(x => this.logger(`${entityName} handled`));
                            }
                        ).catch((noEntity: any) => {
                            this.handleEntity(msg).then(x => this.logger(`${entityName} handled`));
                        });
                    }

                    break;
                default:
                    break;
            }
        } else {
            // process system notification
            switch (msg.type) {
                case MESSAGE_ORIGIN.STOCK_UPDATED:
                    this.$rootScope.$broadcast("stock-updated", msg.entities[0]);
                    this.entityManagerService.stock.saveOneOffline(msg.entities[0]);
                    break;
                case MESSAGE_ORIGIN.FIDELITY_UPDATED:
                    this.$rootScope.$broadcast("fidelity-updated", msg.entities[0]);
                    this.entityManagerService.fidelitiesPoints.saveOneOffline(msg.entities[0]);
                    break;
                case MESSAGE_ORIGIN.PREPAID_UPDATED:
                    this.$rootScope.$broadcast("prepaid-updated", msg.entities[0]);
                    break;
                case MESSAGE_ORIGIN.IMPORTING_ENDED:
                    // if it comes from importing reload entity involved
                    if (msg.client_id === "Importing") {
                        this.logger(`${msg.entity_name} is reloading because of ${msg.client_id} `);
                        this.toast.show({ message: `${this.$translate.instant('MESSAGING.NOTIFY_IMPORTING_ENDED')} ${msg.entity_name}` });

                        if ('reloadEntityCollection' in entityManager) {
                            entityManager.reloadEntityCollection().then((x: any) => {
                                this.toast.show({ message: `${this.$translate.instant('MESSAGING.RELOAD_COMPLETED')} ${msg.entity_name}` });
                            }).catch((err: any) => {
                                this.toast.show({ message: `${this.$translate.instant('MESSAGING.ERRORS.RELOAD_FAILED')}` });
                                this.logger_error("Error handle importing message", err);
                                this.iotHistoryStorage.unsetMessage(msg);
                            });
                        }
                    }
                    break;
                default:
                    if ('reloadEntityCollection' in entityManager) {
                        entityManager.reloadEntityCollection();
                    }
                    break;
            }
        }

        return null;
    }
    /**
     * @description this is used to process messages that can come from iot or from notifications history api
     * @param message notification to handle
     * @param isCallingFromHistory is called from recovery history?
     */
    processMessage = async (message: Notification, isCallingFromHistory: boolean) => {
        this.logger("processing message : ", message.msg_id);
        // need to check if processing is working?
        try {
            // check if the message has already been processed and if the message is not too much old ( check if the incoming message is older then the first downloaded on app launch)
            if ((this.iotHistoryStorage.history[message.msg_id] === null || this.iotHistoryStorage.history[message.msg_id] == undefined) && message.msg_id > this.iotHistoryStorage.firstMessageReceived) {
                // set in anycase the message in the local history
                this.iotHistoryStorage.set(message);
                await this.onMessage(message);

            } else {
                this.logger("message already processed", message.msg_id, "last message id", this.iotHistoryStorage.lastMessageId)
            }

        } catch (error) {
            // if the process fails, do not worry recovery system will get it
            this.logger_error("Failed process Message", error);
        } finally {
            // finally launch check last id if this function is not called from recovery system
            // history do not need to restart callCheckLastId
            if (isCallingFromHistory === false) {
                // this.iotMessageRecoveryManager.callCheckLastId();
                this.delayNextCheckId("last_arrived: " + message.msg_id);

            }
        }
    }
    updateProgressives: OnMessageIot = (msg: Notification) => {
        let msg_progressive = {
            entity: msg.entity_name,
            time: msg.time,
            progressive: msg.progressive
        }
        this.progressivesManager.updateProgressives(msg_progressive);
    }
    async onMessage(msg: Notification): Promise<string> {
        this.logger("on message with: ", this.MessagingConfig);
        if (msg.progressive != null) {
            this.updateProgressives(msg);
            this.logger("update progressive", msg.progressive);
            return "progressive";
        }
        const ownClient = msg.session_origin === this.MessagingConfig?.client;
        const userSpecificFromOther = userSpecificEntities.includes(msg.entity_name) && msg.user_id !== this.MessagingConfig?.userId;
        const messageToProcess = !entitiesToDiscard.includes(msg.entity_name) && !ownClient && !userSpecificFromOther;
        this.logger(entitiesToDiscard.includes(msg.entity_name), !ownClient, !userSpecificFromOther);
        return this.setMessage(msg, messageToProcess);
    }

    private async setMessage(msg: Notification, messageToProcess: boolean): Promise<string> {
        this.logger("setting message with entity", msg.entity_name, "is to process", messageToProcess);
        if (messageToProcess) {
            this.broadcast(msg);
        }
        return msg.entity_name;
    }
}

IotMessageManager.$inject = ["$rootScope", "errorsLogger", "progressivesManager", "IotHistoryStorage", "entityManager", "toast", "$translate"];
angular.module('core').service('IotMessageManager', IotMessageManager);

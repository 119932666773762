import angular from 'angular';
import _ from 'lodash';

angular.module('application').directive('imageHandler', function() {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: {
            // images can be a string or an array of elements with image_url property
            images: '=',
            // constraints for maximum dimensions
            maxImageWidth: '=',
            maxImageHeight: '=',
            onNewImage: '=',
            // in this array the directive pushes a function that can be called in external scope to save the images
            callbacks: "=",
            // this boolean is set to true when images are modified (selected or deleted)
            dirty: "=",
            disabled: "=?"
        },
        template: require('./image-handler.html'),
        link: function(scope, element, attrs, ctrl, transclude) {

            //setting the transcluded scope to the directive's scope
            transclude(scope, function(clone, scope) {
                element.append(clone);
            });

            scope.fileField = element.find('input[type="file"]');

            scope.fileField.on('click', function() {
                this.value = null;
            });
            scope.fileField.on('change', function() {
                var fileHandle = scope.fileField.get(0).files[0];
                scope.handleNewFile(fileHandle);
            });

        },
        controller: ["$scope", "$timeout", "$q", "ImagesManager", "alertDialog", function($scope, $timeout, $q, ImagesManager, alertDialog) {
            if (typeof $scope.images === "string") {
                $scope.imageObj = {};
                $scope.imageObj.image_url = $scope.images;
            }

            $scope.addNewImage = function() {
                console.log("addNewImage");
                $scope.newImages = $scope.fileField.files;
                $scope.fileField.trigger('click');
            };

            $scope.handleNewFile = function(fileHandle) {
                if ($scope.dirty !== undefined) {
                    $scope.dirty = true;
                }
                ImagesManager.localLoadNewImage(fileHandle, $scope.maxImageWidth, $scope.maxImageHeight).then(function(result) {
                    if (result) {
                        if ($scope.images instanceof Array) {
                            $scope.images.push(result);
                        } else {
                            if ($scope.imageObj) {
                                // there are previous images
                                if (!$scope.oldImageBackedUp) {
                                    // copy in oldImage saved image (only the first time)
                                    $scope.oldImageBackedUp = true;
                                    $scope.oldImage = $scope.imageObj;
                                    ImagesManager.markToDelete($scope.oldImage);
                                }
                            }

                            $scope.imageObj = result;
                            $scope.images = result.image_url;
                        }

                        if(_.isFunction($scope.onNewImage)) {
                            $scope.onNewImage(result);
                        }
                    }
                }, function(reason) {
                    // invalid file
                    switch (reason) {
                        case "not image":
                            alertDialog.show("Immagine non valida");
                            break;
                        default:
                            alertDialog.show("Problemi con l'apertura dell'immagine");
                    }
                });
            };

            $scope.saveAllImages = function() {
                if (_.isArray($scope.images)) {
                    return ImagesManager.saveAllImages($scope.images);
                } else {
                    var deferred = $q.defer();
                    var imgArray = [];
                    if ($scope.imageObj) {
                        imgArray.push($scope.imageObj);
                    }
                    if ($scope.oldImage) {
                        imgArray.push($scope.oldImage);
                    }
                    ImagesManager.saveAllImages(imgArray).then(function(result) {
                        //Remove old image
                        delete $scope.oldImage;
                        $scope.oldImageBackedUp = false;
                        //check if the image has been removed and reset imageObj if so
                        if($scope.images === "") {
                            delete $scope.imageObj;
                        }
                        if ($scope.imageObj && $scope.images !== "") {
                            $scope.images = $scope.imageObj.image_url;
                        }
                        // need the $digest() phase, so use timeout
                        $timeout(deferred.resolve, 0);
                    }, function(reason) {
                        if ($scope.imageObj && $scope.images !== "") {
                            $scope.images = $scope.imageObj.image_url;
                        }
                        // need the $digest() phase, so use timeout
                        $timeout(function() {
                            deferred.reject(reason);
                        }, 0);
                    });
                    return deferred.promise;
                }
            };

            $scope.removeImage = function(image) {
                if ($scope.dirty !== undefined) {
                    $scope.dirty = true;
                }
                if ($scope.imageObj) {
                    image = $scope.imageObj;
                    $scope.images = "";
                }
                ImagesManager.markToDelete(image);
            };

            $scope.callbacks.push($scope.saveAllImages);

        }]
    };
});

import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';

angular.module('cashregister').factory('eatsreadyPayments', ["$mdDialog", "$timeout", "$translate", "eatsreadyApi", "util", function($mdDialog, $timeout, $translate, eatsreadyApi, util) {
    eatsreadyPaymentsController.$inject = ["$scope", "options"];
    var dialogHasShown = false;

    function eatsreadyPaymentsController($scope, options) {
        $scope.payments = [];
        $scope.paymentsByUuid = {};
        $scope.needsSetup = false;
        $scope.userMail = '';
        $scope.userPass = '';
        $scope.operationInProgress = false;
        $scope.isOffline = false;

        if(options.amount) {
            $scope.amountToPay = util.round(options.amount / 100);
        }

        $scope.showLoader = function() {
            return _.isEmpty($scope.payments) && dialogHasShown && !$scope.needsSetup;
        };

        $scope.$on("connection:changed", function(event, data) {
            $scope.isOffline = data.status === 'offline';
        });

        $scope.pairShop = function() {
            if(!_.isEmpty($scope.userMail) && !_.isEmpty($scope.userPass)) {
                $scope.operationInProgress = true;
                $scope.message = $translate.instant('CASHREGISTER.EATSREADY_PAYMENTS.PAIRING');
                eatsreadyApi.setupShop($scope.userMail, $scope.userPass).then(function(result) {
                    $scope.message = $translate.instant('CASHREGISTER.EATSREADY_PAYMENTS.ENABLE_SUCCESSFUL');
                    $scope.needsSetup = false;
                    $timeout(function() {
                        $scope.deleteMessage();
                        fetchPayments();
                    }, 1500);
                }, function(error) {
                    $('#EatsreadyMailField').trigger('focus');
                    switch(_.get(error, 'status')) {
                        case -1:
                            $scope.message = '';
                            break;
                        case 404: case 403:
                            $scope.message = $translate.instant('CASHREGISTER.EATSREADY_PAYMENTS.INVALID_CREDENTIALS');
                            break;
                        default:
                            $scope.message = $translate.instant('CASHREGISTER.EATSREADY_PAYMENTS.ENABLE_FAILED');
                            break;
                    }
                }).finally(function() {
                    $scope.operationInProgress = false;
                });
            }
        };

        $scope.deleteMessage = function() {
            $scope.message = '';
        };

        var fetchPayments = function() {
            if(!$scope.$$destroyed) {
                eatsreadyApi.getNewPayments().then(function(response) {
                    if (_.get(response, 'orders')) {
                        _.forEach(response.orders, function(p) {
                            var payment = _.pick(p.order, ['id', 'status', 'status_new']);
                            payment.original_data = p;

                            payment.amount_unit = p.order.amount;
                            payment.customer_amount = util.round(payment.amount_unit / 100);

                            if(payment.expired || (options.amount && payment.amount_unit !== options.amount)) {
                                payment.disabled = true;
                            }

                            if(p.customer) {
                                payment.customer_name = p.customer.displayName;
                            }

                            $scope.paymentsByUuid[p.order.uuid] = payment;
                        });

                        var pDict = _.keyBy(response.orders, function(o) { return o.order.uuid; });

                        _.forIn($scope.paymentsByUuid, function(p, pUuid) {
                            if(!pDict[pUuid]) { //this payment is no longer in the current list, so mark it as removed
                                _.assign(p, {
                                    disabled: true,
                                    removed: true
                                });
                            }
                        });

                        $scope.payments = _($scope.paymentsByUuid).sortBy(function(p) {
                            return (p.expired || (options.amount && p.amount_unit !== options.amount)) ? Infinity : _.toInteger(new Date(p.status_new));
                        }).value();
                    }
                    $timeout(fetchPayments, 2000);
                }, function(error) {
                    if(_.isObject(error)) {
                        switch(error.status) {
                            case -1:
                                $timeout(fetchPayments, 1000);
                            break;
                            case 403:
                                $scope.needsSetup = true;
                            break;
                            default:
                                $mdDialog.cancel('UNKNOWN_ERROR');
                            break;
                        }
                    } else {
                        $mdDialog.cancel('UNKNOWN_ERROR');
                    }
                });
            }
        };

        if (!_.isObject(options)) {
            options = {};
        }

        $scope.cancel = function() {
            $mdDialog.cancel('CANCELED');
        };

        $scope.selectPayment = function(payment) {
            if (!payment.disabled) {
                $mdDialog.hide(payment);
            }
        };

        $timeout(function() {
            fetchPayments();
        });
    }

    var eatsreadyPaymentsDialog = {
        show: function(options) {
            return $mdDialog.show({
                controller: eatsreadyPaymentsController,
                template: require('./eatsready-payments.html'),
                locals: {
                    options: options
                },
                onShowing: function() { dialogHasShown = false; },
                onComplete: function() { dialogHasShown = true; },
                onRemoving: function() { dialogHasShown = false; }
            });
        }
    };

    return eatsreadyPaymentsDialog;
}]);

import {Component, EventEmitter, HostBinding, inject, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CdkDrag, CdkDragEnd, DragAxis, Point} from "@angular/cdk/drag-drop";
import {FloorType} from 'src/app/core';
import {TouchZoomDirective} from 'src/app/directives/touch-zoom.directive';
import {MatListModule} from "@angular/material/list";
import {DevLogger} from "../../../../shared/dev-logger";
import {matchAllNumbers} from '@tilby/tilby-ui-lib/utilities';
import {resizeRoom, ROOM_MULTIPLICATION_FACTOR} from "../../tables.component";
import {Rooms} from 'tilby-models';
import {RoomsStateService} from '../../rooms.state.service';

export type ResizeRoom = { roomWidth: number, roomHeight: number };

@Component({
    selector: 'app-room',
    standalone: true,
    imports: [
        CommonModule,
        CdkDrag,
        TouchZoomDirective,
        MatListModule,
    ],
    templateUrl: './room.component.html',
    styleUrls: ['./room.component.scss']
})
export class RoomComponent implements OnInit {
    private readonly roomsStateService = inject(RoomsStateService);
    private _roomFloor: FloorType = 'parquet';
    @HostBinding('style.--roomWidthInitial') roomWidthInitial = '2000px';
    @HostBinding('style.--roomHeightInitial') roomHeightInitial = '800px';
    @HostBinding('style.--roomWidth') @Input() roomWidth = '2000px';
    @HostBinding('style.--roomHeight') @Input() roomHeight = '800px';
    @HostBinding('style.--roomBackground') roomBackground = `url(assets/images/tables/floor/${this.roomFloor}.png)`;

    @Input() room?: Rooms;
    @Input() isEditMode = false;
    @Output() sizeEmitter = new EventEmitter<ResizeRoom>();
    protected cdkDragFreeDragPositionX: Point = {x: 0, y: 0};
    protected cdkDragFreeDragPositionY: Point = {x: 0, y: 0};

    @Input() set roomFloor(floor: FloorType) {
        this._roomFloor = floor;
        this.roomBackground = `url(assets/images/tables/floor/${floor}.png)`;
    }

    get roomFloor() {
        return this._roomFloor;
    }

    ngOnInit() {
        this.roomWidthInitial = this.roomWidth;
        this.roomHeightInitial = this.roomHeight;
    }

    private log(...args: any[]) {
        DevLogger.debug('ROOM', ...args);
    }

    onDragEnd($event: CdkDragEnd, axis: DragAxis) {

        const {staticStep, updatedStep, updatedRoomSizeNumber,updatedRoomSizeNumberWithMultiplier,staticRoomSizeNumber} = this.setSize($event, axis);
        if (axis == 'x') {
            this.roomWidth = `${updatedRoomSizeNumberWithMultiplier}px`;
            this.cdkDragFreeDragPositionX = {x: updatedStep, y: staticStep};
            this.sizeEmitter.emit({roomWidth: updatedRoomSizeNumber, roomHeight: staticRoomSizeNumber});
        } else if (axis == 'y') {
            this.roomHeight = `${updatedRoomSizeNumberWithMultiplier}px`;
            this.cdkDragFreeDragPositionY = {x: staticStep, y: updatedStep};
            this.sizeEmitter.emit({roomWidth: staticRoomSizeNumber, roomHeight: updatedRoomSizeNumber});
        }


    }

    private setSize($event: CdkDragEnd, axis: DragAxis) {
        const {x, y} = $event.source.getFreeDragPosition();
        const {minHeight, minWidth} = this.roomsStateService.minRoomHeightWidth(this.room);
        this.log('DRAG_END', x, y, $event);
        const isBigger = $event.distance[axis] > 0;
        const isX = axis == 'x';
        const prevRoomSize = +matchAllNumbers(isX ? this.roomWidthInitial : this.roomHeightInitial)[0];
        const updatedStepGetted= isX ? x : y;
        const staticStep = isX ? y : x;
        const roomSizeNumber = prevRoomSize + updatedStepGetted;
        const minSize = isX ? minWidth : minHeight;
        const isMinSize = roomSizeNumber < minSize;
        const isStepUp = isMinSize || isBigger;
        const staticRoomSizeNumber = resizeRoom(+matchAllNumbers(isX ? this.roomHeight : this.roomWidth)[0], isStepUp);
        // const staticRoomSizeNumberWithMultiplier = staticRoomSizeNumber * ROOM_MULTIPLICATION_FACTOR;
        const updatedRoomSizeNumber = resizeRoom(!isMinSize ? roomSizeNumber : minSize, isStepUp);
        const updatedRoomSizeNumberWithMultiplier = updatedRoomSizeNumber * ROOM_MULTIPLICATION_FACTOR;
        const updatedStep = updatedRoomSizeNumberWithMultiplier - +prevRoomSize;

        return {
            updatedRoomSizeNumber,
            updatedRoomSizeNumberWithMultiplier,
            staticRoomSizeNumber,
            // staticRoomSizeNumberWithMultiplier,
            updatedStep,
            staticStep
        }

    }
}

import angular from 'angular';
import _ from 'lodash';

function kioskCartCtrl($scope, $state, kioskSettings, kioskUtils, items, stockDictionary, migrationHelper) {
    const ActiveSale = migrationHelper.getActiveSale();

    if(kioskSettings['cart.skip_cart'] === true) {
        kioskUtils.skipModule();
    } else {
        var itemDictionary = _.groupBy(items, _.property(['id']) );
        var itemImagePlaceholder = kioskSettings['catalog.item_image_placeholder'] || '';
        var baseTextColor = kioskSettings['general.base_text_color'] || '#000';
        var zoomLevel = kioskSettings['general.zoom_level'] || 1.5;

        _.assign($scope, {
            headerParams: {
                bannerUrl: kioskSettings['cart.banner_url'],
                bannerBgColor: kioskSettings['general.header_background_color'],
                bannerHeight: kioskSettings['general.header_height'],
                logoUrl: _.toString(kioskSettings['general.media_url_logo']),
                zoomLevel: zoomLevel,
                customerAssistantEnabled: kioskSettings['general.customer_assistant'],
                title: kioskUtils.getLabelText(kioskSettings, 'cart.title_label', 'KIOSK.CART.TITLE')
            },
            nextButtonLabel: kioskUtils.getLabelText(kioskSettings, 'general.next_button_label', 'KIOSK.NEXT'),
            backButtonLabel: kioskUtils.getLabelText(kioskSettings, 'general.back_button_label', 'KIOSK.BACK'),
            totalLabel: kioskUtils.getLabelText(kioskSettings, 'cart.total_label', 'KIOSK.TOTAL'),
            cartStyle: {
                'zoom': zoomLevel,
                color: baseTextColor
            },
            sale: ActiveSale,
            disableUnavailable: !(kioskSettings['catalog.sell_out_of_stock'] || false),
            backgroundUrl: kioskSettings['cart.background_url']
        });

        if($scope.backgroundUrl) {
            $scope.mediaType = kioskUtils.getMediaType($scope.backgroundUrl);
        }
        if( _.isNil($scope.mediaType) ) {
            // important
            $scope.cartStyle.height = '100%';
        }

        $scope.hasItemsInCart = function() {
            return !ActiveSale.isEmpty();
        };

        $scope.back = function() {
            $scope.$parent.enableTimeouts();
            $state.go( kioskUtils.getPreviousModule() );
        };

        $scope.next = function() {
            $scope.$parent.enableTimeouts();
            $state.go( kioskUtils.getNextModule() );
        };

        $scope.isItemDisabled = function(saleItem) {
            var canBePurchased = true;
            var item = itemDictionary[saleItem.item_id][0];

            if($scope.disableUnavailable) {
                if (item.stock_type === 'simple') {
                    var itemStock = stockDictionary.get('item_id_' + item.id);

                    if (itemStock) {
                        if (itemStock.stock_quantity > 0) {
                            var itemCartQuantity = _(ActiveSale.currentSale.sale_items).filter({ item_id: item.id }).sumBy('quantity');

                            canBePurchased = (itemStock.stock_quantity > itemCartQuantity);
                        } else {
                            canBePurchased = false;
                        }
                    }
                }
            }

            return !canBePurchased;
        };

        $scope.decreaseQuantity = function(saleItem) {
            if(_.isInteger(saleItem.quantity) && saleItem.quantity > 1) {
                ActiveSale.editSaleItem(saleItem, { quantity: saleItem.quantity - 1 });
            } else {
                ActiveSale.removeSaleItem(saleItem);
                if( ActiveSale.isEmpty() ) {
                    $scope.back();
                }
            }
        };

        $scope.increaseQuantity = function(saleItem) {
            if (!$scope.isItemDisabled(saleItem)) {
                ActiveSale.editSaleItem(saleItem, { quantity: saleItem.quantity + 1 });
            }
        };

        $scope.getImage = function(saleItem) {
            var thumbnail = itemDictionary[saleItem.item_id][0].thumbnail;
            return thumbnail ? thumbnail : itemImagePlaceholder;
        };
    }
}

kioskCartCtrl.$inject = ["$scope", "$state", "kioskSettings", "kioskUtils", "items", "stockDictionary", "migrationHelper"];

angular.module('kiosk').controller('KioskCartCtrl', kioskCartCtrl); 
import angular from 'angular';
import _ from 'lodash';
import { ConfigurationManagerService, EntityManagerService } from 'src/app/core';
import { Items, Sales } from 'tilby-models';

export class ShippingInvoiceService {
	constructor(
		private entityManager: EntityManagerService,
		private checkManager: ConfigurationManagerService,
		private shippingInvoiceDialog: any
	) {
	}

	private async fetchVats() {
		const vats = await this.entityManager.vat.fetchCollectionOffline();

		for (let vat of vats) {
			if (vat.id) {
				vat.code = String.fromCharCode(64 + vat.id);
			}
		}

		return _.keyBy(vats, 'value');
	}

	public isEnabled() {
		return true; //We don't need checks here, as the shipping invoice support is determined by the printer driver
	}

	public async isPrintable(sale: Sales, printerDocumentData: any, options: any) {
		if (!this.checkManager.getSetting("shipping_invoice.enabled")) {
			throw 'SHIPPING_INVOICE_DISABLED';
		}

		const saleCustomer = sale.sale_customer

		if (!saleCustomer || Object.keys(saleCustomer).length == 0) {
			throw 'CUSTOMER_MISSING';
		}

		if (!saleCustomer.billing_street || !saleCustomer.billing_zip || !saleCustomer.billing_city || !saleCustomer.billing_prov) {
			throw 'MISSING_BILLING_ADDRESS';
		}

		if (!saleCustomer.shipping_street || !saleCustomer.shipping_zip || !saleCustomer.shipping_city || !saleCustomer.shipping_prov) {
			Object.assign(saleCustomer, {
				shipping_street: saleCustomer.billing_street,
				shipping_number: saleCustomer.billing_number,
				shipping_zip: saleCustomer.billing_zip,
				shipping_city: saleCustomer.billing_city,
				shipping_prov: saleCustomer.billing_prov,
				shipping_country: saleCustomer.billing_country
			});
		}
	}

	public async getData(sale: Sales, printerDocumentData: any) {
		const vatsMap = await this.fetchVats();

		const defaults = {
			itemsCount: 0
		};

		const saleItems = sale.sale_items || [];

		for (let saleItem of saleItems) {
			const originalItem = (saleItem.item_id ? printerDocumentData.itemsMap?.[saleItem.item_id] || {} : {}) as Items;

			defaults.itemsCount += (originalItem.unit?.toLowerCase() != 'pz') ? 1 : saleItem.quantity;
		}

		try {
			const meta = await this.shippingInvoiceDialog.show(sale, defaults);

			return {
				options: {
					shippingVats: vatsMap,
					shippingMeta: meta,
					shippingTail: _.toString(this.checkManager.getSetting("shipping_invoice.tail"))
				}
			};
		} catch (err: any) {
			throw 'CANCELED';
		}
	}
}

ShippingInvoiceService.$inject = ['entityManager', 'checkManager', 'shippingInvoiceDialog'];

angular.module('printers').service('shippingInvoice', ShippingInvoiceService);
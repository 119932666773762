import angular from 'angular';
import _ from 'lodash';
import { ZvtPosDriver } from './zvt-pos-driver';

class ZvtPos {
    constructor(
        private $rootScope: any,
        private $translate: any,
        private waitDialog: any,
        private ZvtPosDriver: ZvtPosDriver
    ) {
    }

    private async performPayment(amount: number, options: any) {
        let paymentMethod = options.paymentMethod;

        if (!paymentMethod.bundle_name) {
            throw this.$translate.instant('DIGITAL_PAYMENTS.COMMON.BUNDLE_NAME_NOT_FOUND');
        }

        if (!paymentMethod.schema_name) {
            throw this.$translate.instant('DIGITAL_PAYMENTS.COMMON.SCHEMA_NAME_NOT_FOUND');
        }

        let netConfig = paymentMethod.schema_name.split(':');
        let ipAddress = netConfig[0];
        let port = netConfig[1] || "20007"

        this.ZvtPosDriver.init(paymentMethod.bundle_name, ipAddress, port);

        let transactionProgress = this.ZvtPosDriver.performPayment(amount);

        transactionProgress.intermediateStatus.subscribe((intermediateStatus) => {
            this.$rootScope.$broadcast("wait-dialog:update-state", { message: intermediateStatus });
        });

        await this.waitDialog.show({ message: this.$translate.instant('DIGITAL_PAYMENTS.INGENICO_17.CONNECTING_POS'), promise: transactionProgress.finalStatus });

        return {
            //acquirer_id : result.acquirer_id,
            //acquirer_name : result.acquirer_name,
            card_circuit_name: null,
            payment_data: null,
            tail: null,
            unclaimed: false
        };
    }

    public payment(amount: number, options: any) {
        return this.performPayment(amount, options);
    }
}

ZvtPos.$inject = ["$rootScope", "$translate", "waitDialog", "ZvtPosDriver"];

angular.module('digitalPayments').service('ZvtPos', ZvtPos);

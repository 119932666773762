
<tilby-dialog-toolbar title={{data.title}} [hideConfirm]="true"/>
<tilby-dialog-content>
    <mat-list>
        @for(variation of variationSales; track variation) {
            <div class="tw-container tw-flex">
                <mat-list-item class="tw-h-max tw-py-5">
                    @if(variation.name) {
                        <div>
                            <span translate="HISTORY.TRANSACTIONS_VIEWER.NAME"></span>: {{ variation.name }}
                        </div>
                    }
                    @if(variation.status) {
                        <div>
                            <span translate="HISTORY.TRANSACTIONS_VIEWER.STATUS"></span>: {{ variation.status }}
                        </div>
                    }
                    @if(variation.channel) {
                        <div>
                            <span translate="HISTORY.TRANSACTIONS_VIEWER.CHANNEL"></span>: {{ variation.channel }}
                        </div>
                    }
                    @if(variation.table_name) {
                        <div>
                            <span translate="HISTORY.TRANSACTIONS_VIEWER.TABLE"></span>: {{ variation.table_name }}
                        </div>
                    }
                    @if(variation.room_name) {
                        <div>
                            <span translate="HISTORY.TRANSACTIONS_VIEWER.ROOM_NAME"></span>: {{ variation.room_name }}
                        </div>
                    }
                    @if(variation.external_id) {
                        <div>
                            <span translate="HISTORY.TRANSACTIONS_VIEWER.EXTERNAL_ID"></span>: {{ variation.external_id }}
                        </div>
                    }
                    @if(variation.sale_customer) {
                        <div>
                            <span translate="HISTORY.TRANSACTIONS_VIEWER.SALE_CUSTOMER"></span>:
                            @if(variation.sale_customer.company_name) {
                                {{ variation.sale_customer.company_name }}
                            } @else {
                                {{ variation.sale_customer.first_name }} {{ variation.sale_customer.last_name }}
                            }
                        </div>
                    }
                    @if(variation.printed_prebills) {
                        <div class="tw-flex">
                            <span translate="HISTORY.TRANSACTIONS_VIEWER.PRINTED_PREBILLS"></span>
                        </div>
                    }
                    @if(variation.bill_lock == true) {
                        <div class="tw-flex">
                            <span translate="HISTORY.TRANSACTIONS_VIEWER.BILL_LOCK"></span>
                            <mat-icon class="tw-scale-[0.8] material-symbols-outlined">lock</mat-icon>
                        </div>
                    } @else if(variation.bill_lock == false) {
                        <div class="tw-flex">
                            <span translate="HISTORY.TRANSACTIONS_VIEWER.BILL_UNLOCK"></span>
                            <mat-icon class="tw-scale-[0.8] material-symbols-outlined">lock_open_right</mat-icon>
                        </div>
                    }
                    @if(variation.variation_items?.length) {
                        <div class="tw-flex tw-items-center">
                            <span translate="HISTORY.TRANSACTIONS_VIEWER.ITEMS"></span>:
                        </div>
                        @for(item of variation.variation_items; track item) {
                            <div
                                [ngClass]="{
                                    'tw-text-green-500': item.quantity_difference! > 0,
                                    'tw-text-red-500': item.quantity_difference! < 0,
                                    'tw-text-gray-500': item.quantity_difference === 0,
                                    'tw-line-through': item.type === 'removal'
                                }"
                                class="tw-font-normal">
                                <div class="tw-flex">
                                    @if(item.quantity_difference === 0) {
                                        <mat-icon class="tw-scale-[0.8]">edit</mat-icon>
                                    }
                                    
                                    {{ item.quantity_difference! > 0 ? "+" + item.quantity_difference : item.quantity_difference! < 0 ? item.quantity_difference : item.quantity }} x {{ item.half_portion ? "1/2" : "" }} {{ item.name }}
                                </div>
                                @for(variation of item.variations; track $index;) {
                                    <div class="tw-pl-7">
                                        <div>
                                            {{variation.name}}: {{variation.value}}
                                        </div>
                                    </div>
                                }
                                @if(item.notes) {
                                    <div class="tw-pl-7">{{item.notes}}</div>
                                }
                                @for(ingredient of item.ingredients; track ingredient;) {
                                    <div>
                                        <span [ngClass]="{ 'tw-line-through': ingredient.quantity === 0, 'tw-underline': item.ingredients_differences && item.type !== 'removal' }" class="tw-pl-7">
                                            {{ ingredient.type === 'removed' ? '-' : '+' }}{{ ingredient.quantity! > 1 ? ingredient.quantity : '' }} {{ ingredient.name }}
                                        </span>
                                    </div>
                                }
                            </div>
                        }
                    }
                    @if(variation.sent_exits) {
                        <div>
                            <span translate="HISTORY.TRANSACTIONS_VIEWER.SENT_EXITS"></span>:
                            @for(exit of variation.sent_exits | keyvalue; track exit; let last = $last) {
                                @if(exit.value) {
                                    <span>{{ exit.key }}</span>
                                    @if(!last) {
                                        <span>, </span>
                                    }
                                }
                            }
                        </div>
                    }
                </mat-list-item>
                <mat-list-item class="tw-h-max tw-py-5 tw-text-right">
                    <div>
                        {{ variation.date | tilbyDate : "HH:mm:ss" }}
                    </div>
                    <div>
                        {{ variation.date | tilbyDate : "dd/MM/yyyy" }}
                    </div>
                    <div class="tw-flex tw-items-center tw-justify-end">
                        <mat-icon>person</mat-icon>
                        {{ variation.seller_name }}
                    </div>

                    @if(variation.printed) {
                        <mat-icon class="material-symbols-outlined">print_connect</mat-icon>
                    }
                    @if(variation.skip_printing) {
                        <mat-icon class="material-symbols-outlined">step_over</mat-icon>
                    }
                </mat-list-item>
            </div>
            <mat-divider></mat-divider>
        }
    </mat-list>
</tilby-dialog-content>
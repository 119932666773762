import {
    AfterViewInit,
    Component,
    ElementRef,
    Injector,
    Input,
    inject,
    signal
} from "@angular/core";

import {TranslateService} from '@ngx-translate/core';
import {OnDestroyService} from "src/app/core/services/on-destroy.service";
import {TilbyToolbar} from "src/app/models";
import {HttpClient} from "@angular/common/http";
import _ from 'lodash';
import {firstValueFrom} from "rxjs";
import {mobileCheck} from "@tilby/tilby-ui-lib/utilities";

import {
    News,
    ObjData,
    SelectedNews,
    Today
} from "src/app/features/dashboard/models";

import {
    AlertDialogService,
    BestItems,
    BestItemsDialogService,
    ConfirmDialogService,
    OpenDialogsService,
    DashboardSettingsDialogService,
    WaitDialogService,
} from "src/app/dialogs";

import {TilbyDatePipe} from "@tilby/tilby-ui-lib/pipes/tilby-date";

import {
    LoaderService,
    UserActiveSessionManagerService,
    ConfigurationManagerService,
    GridEvents,
    ToolbarEventsService
} from "src/app/core";

import {
    $state,
    dashboardDataManager,
    restManager,
    util
} from "app/ajs-upgraded-providers";

import { DialogStateService } from "src/app/core/services/dialog.state.service";
import { Channels } from "tilby-models";
import { alignDataPrinters, getPayments, getToday, getWeatherIcon } from "./dashboard.utils";
import { MathUtils } from "src/app/shared/utils";

type Consumi = {
    tipo: string;
    denominazione: string;
    idPaese: string;
    idFiscale: string;
    anno: string;
    dataScadenza: string;
    acquistati: string;
    utilizzati: string;
    rimanenti?: number;
    limita: string;
    segnala: string;
}

@Component({
    selector: "dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
    providers: [OnDestroyService]
})
export class DashboardComponent implements AfterViewInit, TilbyToolbar {
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly bestItemsDialogService = inject(BestItemsDialogService);
    private readonly configurationManager = inject(ConfigurationManagerService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly dashboardDataManagerService = inject(dashboardDataManager);
    private readonly dialogStateService = inject(DialogStateService);
    private readonly http = inject(HttpClient);
    private readonly injector = inject(Injector);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly restManagerService = inject(restManager);
    private readonly state = inject($state);
    private readonly tilbyDatePipe = inject(TilbyDatePipe);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly translateService = inject(TranslateService);
    private readonly userActiveSession = inject(UserActiveSessionManagerService);
    private readonly utilService = inject(util);
    private readonly waitDialogService = inject(WaitDialogService);
    
    protected isMobile = mobileCheck();

    hostRef:ElementRef<HTMLElement>=inject(ElementRef);
    @Input() deliverychannels: Channels[] = []; //UIROUTER CONFLICT CON deliveryChannels
    user = this.userActiveSession.getSession()!;

    objData: ObjData = {
        canOpenStocks: this.configurationManager.isModuleEnabled('stock'),
        country: this.configurationManager.getShopCountry(),
        data: {
            date: TilbyDatePipe.date({outputFormat:'date'}),
            today: {} as Today,
            printers: [],
            stocks: {
                alert: 0,
                unavailable: 0,
                available: 0
            },
            e_invoices: {
                error: 0,
                pending: 0
            },
            closings: {
                last_closing: null
            },
            news: [],
            selectedNews: {
                title: null,
                content: null,
                url: null,
                icon: null
            },
            missing_sends: 0,
            progress: {
                current: 0,
                percentage: 0,
                target: 0
            }
        },
        loadCompleted: false,
        userFirstName: this.user.first_name || "",
        thumbnail: this.user.thumbnail || null,
        topbar_context: {
            reloading: Promise.resolve(false)
        }
    };

    protected scrollToReload = true;

    bestItems: BestItems | null = null;
    consumptionInvoices: Consumi | undefined = undefined;
    isLoading = true;
    show_best_products = this.configurationManager.isFunctionEnabledOptin('dashboard.show_best_products');

    eInvoiceSendMode = this.configurationManager.getSetting('e_invoice.send_mode');
    disableEinvoiceStatus: boolean = this.configurationManager.getPreference('dashboard.disable_einvoice_status') || false;

    protected onScroll(event:any){
        this.scrollToReload = (event.target?.scrollTop == 0)
    }

    constructor(
    ) {
    }

    async ngOnInit() {
        this.createToolbarButtons();
        this.setModuleTitle();
        this.toolbarEventsService.searchBarValue$.next('');
        this.checkEInvoices();
        this.downloadNews();

        let dataCache = this.dashboardDataManagerService.getDataCache();

        if (!_.isEmpty(dataCache)) {
            this.refreshView(dataCache);
        }

        this.reloadData();
        this.checkImportingTabacchi();
    }

    async getConsumi() {
        let result: Consumi | undefined;

        try {
            result = await this.restManagerService.getOne('e_invoice/accounts_payable/get_consumi');

            if (result) {
                this.checkRemainingInvoices(result);
            }
        } catch (e) {
            console.log(e);
        } finally {
            return result;
        }
    }

    checkRemainingInvoices(result: Consumi) {
        let message: string = '';

        result.rimanenti = Number(result.acquistati) - Number(result.utilizzati);

        if (result.rimanenti <= 0) {
            message = 'DASHBOARD.INVOICES_REMAINING_ZERO';
        } else if (result.rimanenti < 10) {
            message = 'DASHBOARD.INVOICES_REMAINING_LESS_THAN_10';
        } else if (result.rimanenti < 50) {
            message = this.translateService.instant('DASHBOARD.INVOICES_REMAINING_LESS_THAN_50', { value: result.rimanenti });
        }

        if (message) {
            this.alertDialogService.openDialog({
                data: {
                    messageLabel: message,
                    confirmLabel: this.translateService.instant('MISC.OK'),
                }
            });
        }
    }

    getRemainingInvoicesClass() {
        let _class = '';
        if (this.getRemainingInvoices() < 100) {
            _class = 'tw-font-bold';
        }
        if (this.getRemainingInvoices() < 50) {
            _class = 'tw-font-bold tw-text-orange-500';
        }
        if (this.getRemainingInvoices() < 11) {
            _class = 'tw-font-bold tw-text-red-500';
        }
        return _class;
    }

    getRemainingInvoices() {
        return this.consumptionInvoices?.rimanenti || 0;
    }

    ngAfterViewInit(): void {
        this.isLoading=false;
    }

    private setModuleTitle() {
        this.toolbarEventsService.moduleTitle.next("DASHBOARD");
    }

    callbackToToolbarClick(event: Partial<GridEvents>): void {
        throw new Error("Method not implemented.");
    }

    async checkEInvoices() {
        let result = await this.restManagerService.getOne('sales/e_invoices', 'sync_status');
        _.keys(this.objData.data.e_invoices).forEach((value) => {
            this.objData.data.e_invoices[value as keyof typeof this.objData.data.e_invoices] = _.get(result, ['status', value], 0);
        });
    };

    async downloadNews() {
        let result = await firstValueFrom(this.http.get<News>('https://internalcrm.scloby.com/news/').pipe());
        let countryData: SelectedNews | SelectedNews[] = <SelectedNews>result[this.objData.country as keyof News] || result['DEFAULT'];

        if (Object.keys(countryData).length === 0) {
            countryData = {content: null, title: null, url: null, icon: null};
        }

        // TODO : WORKAROUND in attesa che ritorni la corretta icona, quando corretto basta cancellare la riga
        countryData.icon?.lastIndexOf('.svg') !== 0 ? countryData.icon = 'event' : null;

        Object.assign(this.objData.data, {
            news: countryData,
            // @ts-ignore
            selectedNews: !!countryData.length ? countryData[0] : countryData,
            selectedNewsIndex: 0
        });
    }

    openUrl(url: any) {
        this.utilService.openExternalLink(`${url}?utm_source=scloby-app&utm_medium=dashboard&utm_campaign=dashboard_button`);
    };

    openStocks() {this.state.go('app.stock.management')};

    refreshView(dashboardData: any) {
        this.bestItems = dashboardData?.today_dashboard?.best_items || {};
        let current_cash_movements = dashboardData.today_dashboard.cash_movements || {amount: 0};
        let current_cash_in_register = current_cash_movements.total.income.amount + current_cash_movements.total.outcome.amount;

        let weather_icon = getWeatherIcon(dashboardData);

        let payments = getPayments(dashboardData, this.translateService.instant('DASHBOARD.PREPAID_CARDS'));

        this.objData.data.today = getToday(dashboardData, payments, current_cash_movements, current_cash_in_register, weather_icon);

        const goal = parseFloat(this.configurationManager.getPreference('daily_goal') || '') || 1;

        this.objData.data.progress = {
            current: this.objData.data.today.total_amount || 0,
            target: goal,
            percentage: Math.min(100 * (this.objData.data.today.total_amount || 0) / goal, 100) || 0,
            remainder: Math.max(MathUtils.round((this.objData.data.today.total_amount || 0) - goal), 0) || null
        };

        let lastClosing = _.reduce(dashboardData.printers_status, (prev: any, value) => {
            let date = TilbyDatePipe.date({outputFormat:'date',date: value.lastUpdate});

            if (_.isNil(prev) || prev > date) {
                return date;
            }

            return prev;
        }, null);

        this.objData.data.last_closing = (lastClosing === null) ? null : this.tilbyDatePipe.transform(lastClosing);

        this.objData.data.printers = this.dashboardDataManagerService.getMergedResources(dashboardData.today_printers);

        this.objData.data.alignedPrinters = alignDataPrinters(this.objData.data, this.deliverychannels);

        _.reduce(dashboardData.printers_status, (prev, value) => (prev + (_.toInteger(value.rtOldFileToSend) || 0)), 0);

        this.objData.data.stocks = {
            alert: _.filter(dashboardData.stocks, {available: 'alert'}).length || 0,
            unavailable: _.filter(dashboardData.stocks, {available: 'unavailable'}).length || 0,
            available: _.filter(dashboardData.stocks, {available: 'available'}).length || 0
        };

        this.objData.loadCompleted = true;
    };

    async reloadData() {
        LoaderService.showLoader();

        if (this.eInvoiceSendMode === 'digitalhub' && !this.disableEinvoiceStatus) {
            this.consumptionInvoices = await this.getConsumi();
        }

        if (await this.objData.topbar_context.reloading) {
            return;
        }
        this.objData.topbar_context.reloading = new Promise(async (resolve, _reject) => {
            try {
                this.toolbarEventsService.events.next({
                    btnCss: {
                        button: 'dashboardRefresh',
                        type: 'addCssClass',
                        cssClass: 'rotate-infinite'
                    }
                });
                let dashboardData = await this.dashboardDataManagerService.fetchData();
                this.refreshView(dashboardData);
            } catch (err) { console.error(err); }
            this.toolbarEventsService.events.next({
                btnCss: {
                    button: 'dashboardRefresh',
                    type: 'removeCssClass',
                    cssClass: 'rotate-infinite'
                }
            });
            this.objData.topbar_context.reloading = Promise.resolve(false);
            resolve(false);
        });

        this.objData.topbar_context.reloading.finally(()=>LoaderService.hideLoader());
    }

    createToolbarButtons() {
        this.toolbarEventsService.buttons$.next({
            barButtons: [
                {
                    isIt: signal(true),
                    name: 'dashboardRefresh',
                    icon: signal('refresh'),
                    click: _ => this.reloadData()
                },
                {
                    isIt: signal(true),
                    name: 'settings_daily_goal',
                    icon: signal('settings'),
                    click: _ => this.openDashboardSettings()
                }
            ],
            panelButtons: []
        });
    }

    private async openDashboardSettings() {
        const currentDailyGoal = parseFloat(this.configurationManager.getPreference('daily_goal') || '') || 0;
        const currentYearlyProgressive = parseInt(this.configurationManager.getPreference('e_invoice.yearly_progressive') || '') || 0;

        const res = await this.injector.get(DashboardSettingsDialogService).openDialog({
            data: {
                settings: {
                    amount: currentDailyGoal,
                    yearly_progressive: currentYearlyProgressive
                }
            }
        });

        if(!res) {
            return;
        }

        const amount = res.amount || 0;
        this.configurationManager.setShopPreference('daily_goal', amount);

        if(res.yearly_progressive && res.yearly_progressive !== currentYearlyProgressive) {
            this.configurationManager.setShopPreference('e_invoice.yearly_progressive', res.yearly_progressive);
        }

        this.objData.data.progress.target = amount;
        this.objData.data.progress.percentage = Math.min(100 * (this.objData.data.today.total_amount || 0) / amount, 100) || 0;
        this.objData.data.progress.remainder = Math.max(MathUtils.round((this.objData.data.today.total_amount || 0)) - amount, 0) || null;
    }

    async openDetails() {
        if (this.objData.topbar_context.reloading) {
            await this.waitDialogService.openDialog({data: {
                message: "DASHBOARD.WAIT_UPDATING_DATA",
                promise: this.objData.topbar_context.reloading
            },disableClose:true});
            await this.openDialogsService.openFiscalPrinterDashboardDialog({data: this.objData.data,disableClose:true});
        } else {
            await this.openDialogsService.openFiscalPrinterDashboardDialog({data: this.objData.data,disableClose:true});
        }
    }

    async openBestProducts() {
        const data = {data: {
            title: 'DASHBOARD.DIALOG_BEST_PRODUCTS.TITLE',
            bestItems: this.bestItems
        }};
        await this.bestItemsDialogService.openDialog(data);
    }

    async checkImportingTabacchi() {
        if (!this.dialogStateService.isDialogJustOpen('dialog-import-tobacchi') && this.configurationManager.isModuleEnabled('tobacco_updates')) {
            const confirm = await this.confirmDialogService.openDialog({
                data: {
                    messageLabel: "DIALOG.IMPORT_TABACCHI.TITLE",
                    confirmLabel: "DIALOG.IMPORT_TABACCHI.CONFIRM",
                    cancelLabel: "DIALOG.IMPORT_TABACCHI.CANCEL"
                }
            });
            this.dialogStateService.setDialogJustOpen('dialog-import-tobacchi');
            if (confirm) {
                this.importingTabacchi();
            }
        }
    }

    async importingTabacchi() {
        const result = await this.restManagerService.getList('importing/files');
        const importFiles = result.files.slice().sort((a: { createdAt: Date }, b: { createdAt: Date }) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });
        let pendingFile = importFiles.find((file : { name: string; status: string;}) => (file.name.includes("Tabacchi") && file.status !== "completed"));
        if(!pendingFile) {
            await this.restManagerService.post('importing/file/tabacchi');
        }
    }
}

import angular from 'angular';
import _ from 'lodash';
import { SclDictionary } from 'app/libs/SclDictionary';

angular.module('kiosk', ['application']);

angular.module('kiosk').config(["$stateProvider", "$translatePartialLoaderProvider", function($stateProvider, $translatePartialLoaderProvider) {
    $translatePartialLoaderProvider.addPart('kiosk');

    $stateProvider.state('app.kiosk', {
        url: '/kiosk',
        redirectTo: 'app.kiosk.standby',
        resolve: {
            kioskSettings: ["checkManager", async (checkManager) => {
                const kioskShopPrefs = _.chain(checkManager.getShopPreferences('kiosk.')).mapKeys((val, key) => _.replace(key, 'kiosk.', '')).value();
                const kioskUserPrefs = _.chain(checkManager.getUserPreferences('kiosk.')).mapKeys((val, key) => _.replace(key, 'kiosk.', '')).value();
                return Object.assign({}, kioskShopPrefs, kioskUserPrefs);
            }]
        },
        views: {
            "appContent@app": {
                template: require('./partial/kiosk/kiosk.html'),
                controller: 'KioskCtrl'
            }
        }
    });

    $stateProvider.state('app.kiosk.standby', {
        url: '/standby',
        views: {
            "kioskView@app.kiosk": {
                template: require('./partial/standby/standby.html'),
                controller: 'KioskStandbyCtrl'
            }
        }
    });

    $stateProvider.state('app.kiosk.service_selection', {
        url: '/service_selection',
        views: {
            "kioskView@app.kiosk": {
                template: require('./partial/service-selection/service-selection.html'),
                controller: 'KioskServiceSelectionCtrl'
            }
        }
    });

    $stateProvider.state('app.kiosk.catalog', {
        url: '/catalog',
        views: {
            "kioskView@app.kiosk": {
                template: require('./partial/catalog/catalog.html'),
                controller: 'KioskCatalogCtrl'
            }
        },
        resolve: {
            categories: ["entityManager", (entityManager) => entityManager.categories.fetchCollectionOffline()],
            items: ["entityManager", async (entityManager) => {
                let items = await entityManager.items.fetchCollectionOffline({ on_sale: true });
                let result = _.chain(items)
                    .groupBy("category_id")
                    .value();

                return result;
            }],
            stockDictionary: ["entityManager", async (entityManager) => {
                const fields = ['combination_id', 'item_id', 'name', 'code', 'combination', 'options_values', 'barcode', 'unit', 'available', 'stock_quantity'];
                let result = new SclDictionary();

                let stockCollection = entityManager.stock.fetchCollectionOffline();

                for(let stock in stockCollection) {
                    if (stock.combination_id) {
                        result.set(`combination_id_${stock.combination_id}`, _.pick(stock, fields));
                    } else if (stock.item_id) {
                        result.set(`item_id_${stock.item_id}`, _.pick(stock, fields));
                    }
                }

                return result;
            }]
        }
    });

    $stateProvider.state('app.kiosk.cart', {
        url: '/cart',
        views: {
            "kioskView@app.kiosk": {
                template: require('./partial/cart/cart.html'),
                controller: 'KioskCartCtrl'
            }
        },
        resolve: {
            items: ["entityManager", function(entityManager) {
                return entityManager.items.fetchCollectionOffline();
            }],
            stockDictionary: ["entityManager", "$q", function(entityManager, $q) {
                var d = $q.defer();

                entityManager.stock.fetchCollectionOffline().then(function(stockCollection) {
                    var result = new SclDictionary();
                    var fields = ['combination_id', 'item_id', 'name', 'code', 'combination', 'options_values', 'barcode', 'unit', 'available', 'stock_quantity'];

                    _.forEach(stockCollection, function(stock) {
                        if (stock.combination_id) {
                            result.set('combination_id_' + stock.combination_id, _.pick(stock, fields));
                        } else if (stock.item_id) {
                            result.set('item_id_' + stock.item_id, _.pick(stock, fields));
                        }
                    });

                    return d.resolve(result);
                });

                return d.promise;
            }]
        }
    });

    $stateProvider.state('app.kiosk.document_selection', {
        url: '/document_selection',
        views: {
            "kioskView@app.kiosk": {
                template: require('./partial/document-selection/document-selection.html'),
                controller: 'KioskDocumentSelectionCtrl'
            }
        },
        resolve: {
            printers: ["entityManager", function(entityManager) {
                return entityManager.printers.fetchCollectionOffline();
            }]
        }
    });

    $stateProvider.state('app.kiosk.customer_selection', {
        url: '/customer_selection',
        views: {
            "kioskView@app.kiosk": {
                template: require('./partial/customer-selection/customer-selection.html'),
                controller: 'KioskCustomerSelectionCtrl'
            }
        }
    });

    $stateProvider.state('app.kiosk.payment_selection', {
        url: '/payment_selection',
        views: {
            "kioskView@app.kiosk": {
                template: require('./partial/payment-selection/payment-selection.html'),
                controller: 'KioskPaymentSelectionCtrl'
            }
        },
        resolve: {
            paymentMethods: ["entityManager", function(entityManager) {
                return entityManager.paymentMethods.fetchCollectionOffline();
            }]
        }
    });

    $stateProvider.state('app.kiosk.confirmation', {
        url: '/confirmation',
        views: {
            "kioskView@app.kiosk": {
                template: require('./partial/confirmation/confirmation.html'),
                controller: 'KioskConfirmationCtrl'
            }
        }
    });
}]);

angular.module('kiosk').run(["$transitions", "$window", "environmentInfo", "checkManager", function($transitions, $window, environmentInfo, checkManager) {
    $transitions.onEnter({
        from: 'app.kiosk.**',
        to: function(state, transition) { return !_.startsWith(state.self.name, 'app.kiosk'); }
    }, function(trans) {
        checkManager.setupLanguage();
    });

    $transitions.onSuccess({
        from: 'app.kiosk.**',
        to: function(state, transition) { return !_.startsWith(state.self.name, 'app.kiosk'); }
    }, function(trans) {
        if(environmentInfo.isElectronApp()) {
            const electronHandler = window.require('electron');

            electronHandler.webFrame.setZoomFactor(1);
            electronHandler.ipcRenderer.invoke('kiosk-mode', false);
        }
    });
}]);

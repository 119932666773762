import angular from 'angular';
import { Notification } from './iot-models'

export class IotHistoryStorage {
    history: { [name: number]: Notification };
    lastMessageId: number;
    firstMessageReceived: number;
    lastMessageIdChecked: number;

    constructor(
        private errorsLogger: any
    ) {
        this.history = {};
        this.lastMessageId = 0;
        this.firstMessageReceived = 0;
        this.lastMessageIdChecked = 0;
    }
    setStartHistory(msg_id: number) {
        this.firstMessageReceived = msg_id;
        this.lastMessageIdChecked = msg_id;
        this.history = {};

    }

    private logger(...args: any[]){
        this.errorsLogger.debug("[IotHistoryStorage]: ",...args);
    }

    getDiffLastAndFirst(){
        return this.lastMessageId - this.firstMessageReceived;
    }

    setList(list_messages: Notification[]): void {
        list_messages.forEach((m: Notification) => this.set(m));
        return;
    }
    set(message: Notification): void {
        this.history[message.msg_id] = message;
        if (this.lastMessageId < message.msg_id) {
            this.lastMessageId = message.msg_id;
            this.logger("lastMessageId", this.lastMessageId, "firstMessageId", this.firstMessageReceived);
        }
        return;
    }
    unsetMessage(msg: Notification){
        if(this.lastMessageId === msg.msg_id){
            this.lastMessageId --;
        }
        delete this.history[msg.msg_id];
    }
}

IotHistoryStorage.$inject = ["errorsLogger"];
angular.module('core').service('IotHistoryStorage', IotHistoryStorage);


import angular from 'angular';
import _ from 'lodash';
import moment from 'moment-timezone';
import { SclDictionary } from 'app/libs/SclDictionary';

angular.module('stock').controller('StockNewMovementCtrl', StockNewMovementCtrl);

StockNewMovementCtrl.$inject = ["$scope", "$rootScope", "$state", "$stateParams", "$timeout", "$mdMedia", "$translate", "$filter", "user", "items", "suppliers", "rawMaterials", "stocksByLocation", "util", "alertDialog", "confirmDialog", "promptDialog", "waitDialog", "environmentInfo", "restManager", "entityManager", "barcodeManager", "checkManager", "stockLocations"];

function StockNewMovementCtrl($scope, $rootScope, $state, $stateParams, $timeout, $mdMedia, $translate, $filter, user, items, suppliers, rawMaterials, stocksByLocation, util, alertDialog, confirmDialog, promptDialog, waitDialog, environmentInfo, restManager, entityManager, barcodeManager, checkManager, stockLocations) {
    $scope.topbar_context = {
        sidenav_label: $translate.instant('STOCK.NEW_MOVEMENT.NEW_MOVEMENT'),
        showUnitCount: checkManager.getPreference("stock_movements.show_unit_count"),
        canUseCameraBarcode: environmentInfo.isCameraBarcodeSupported(),
        isAppleMobile: environmentInfo.isAppleMobile(),
        viewmode: 'movements',
        mode: 'new_movement',
        suppliers: suppliers,
        stockLocations: stockLocations,
        sourceLocationId: $stateParams.stock_location_id || 1,
        movementCauses: [
            { type: 'load', typeName: $translate.instant('STOCK.TOPBAR_STOCK.LOAD'), value: 'buy', name: $translate.instant('STOCK.CAUSES.BUY') },
            { type: 'load', typeName: $translate.instant('STOCK.TOPBAR_STOCK.LOAD'), value: 'refund', name: $translate.instant('STOCK.CAUSES.REFUND') },
            { type: 'load', typeName: $translate.instant('STOCK.TOPBAR_STOCK.LOAD'), value: 'replacement', name: $translate.instant('STOCK.CAUSES.REPLACEMENT') },
            { type: 'load', typeName: $translate.instant('STOCK.TOPBAR_STOCK.LOAD'), value: 'production', name: $translate.instant('STOCK.CAUSES.PRODUCTION') },
            { type: 'load', typeName: $translate.instant('STOCK.TOPBAR_STOCK.LOAD'), value: 'transfer', name: $translate.instant('STOCK.CAUSES.TRANSFER') },
            { type: 'load', typeName: $translate.instant('STOCK.TOPBAR_STOCK.LOAD'), value: 'positive_adjustment', name: $translate.instant('STOCK.CAUSES.POSITIVE_ADJUSTMENT') },
            { type: 'load', typeName: $translate.instant('STOCK.TOPBAR_STOCK.LOAD'), value: 'supplier_order', name: $translate.instant('STOCK.CAUSES.SUPPLIER_ORDER') },
            { type: 'unload', typeName: $translate.instant('STOCK.TOPBAR_STOCK.UNLOAD'), value: 'sale', name: $translate.instant('STOCK.CAUSES.SALE') },
            { type: 'unload', typeName: $translate.instant('STOCK.TOPBAR_STOCK.UNLOAD'), value: 'gift', name: $translate.instant('STOCK.CAUSES.GIFT') },
            { type: 'unload', typeName: $translate.instant('STOCK.TOPBAR_STOCK.UNLOAD'), value: 'internal_use', name: $translate.instant('STOCK.CAUSES.INTERNAL_USE') },
            { type: 'unload', typeName: $translate.instant('STOCK.TOPBAR_STOCK.UNLOAD'), value: 'damage', name: $translate.instant('STOCK.CAUSES.DAMAGE') },
            { type: 'unload', typeName: $translate.instant('STOCK.TOPBAR_STOCK.UNLOAD'), value: 'theft', name: $translate.instant('STOCK.CAUSES.THEFT') },
            { type: 'unload', typeName: $translate.instant('STOCK.TOPBAR_STOCK.UNLOAD'), value: 'waste', name: $translate.instant('STOCK.CAUSES.WASTE') },
            { type: 'unload', typeName: $translate.instant('STOCK.TOPBAR_STOCK.UNLOAD'), value: 'supplier_return', name: $translate.instant('STOCK.CAUSES.SUPPLIER_RETURN'), },
            { type: 'unload', typeName: $translate.instant('STOCK.TOPBAR_STOCK.UNLOAD'), value: 'transfer', name: $translate.instant('STOCK.CAUSES.TRANSFER') },
            { type: 'unload', typeName: $translate.instant('STOCK.TOPBAR_STOCK.UNLOAD'), value: 'move', name: $translate.instant('STOCK.CAUSES.MOVE') },
            { type: 'unload', typeName: $translate.instant('STOCK.TOPBAR_STOCK.UNLOAD'), value: 'replacement', name: $translate.instant('STOCK.CAUSES.REPLACEMENT') },
            { type: 'unload', typeName: $translate.instant('STOCK.TOPBAR_STOCK.UNLOAD'), value: 'negative_adjustment', name: $translate.instant('STOCK.CAUSES.NEGATIVE_ADJUSTMENT') },
            { type: 'unload', typeName: '', value: 'stock_transfer', name: $translate.instant('STOCK.CAUSES.STOCK_TRANSFER') },
            { type: 'setup', typeName: '', value: 'setup', name: $translate.instant('STOCK.TOPBAR_STOCK.SETUP') },
        ],
        defaultDate: moment().milliseconds(0).seconds(0).toDate()
    };

    const salesStock = stocksByLocation['1'];
    const ordersStock = stocksByLocation['2'];

    Object.assign($scope, {
        allMovementsSelected: false,
        currency: $rootScope.currentCurrency.symbol_native,
        Math: Math,
        movementsToSend: [],
        rawMaterials: _.sortBy(rawMaterials, 'name'),
        showPriceData: checkManager.isModuleEnabled('items')
    });

    if($stateParams.mode === 'supplier_order') {
        Object.assign($scope.topbar_context, {
            newMovementMode: $stateParams.mode,
            movementCause: $scope.topbar_context.movementCauses.find((cause) => (cause.value === 'supplier_order')),
            sidenav_label: $translate.instant('STOCK.NEW_MOVEMENT.NEW_SUPPLIER_ORDER'),
            sourceLocationId: 2
        });
    }

    $scope.isLandscape = $mdMedia('min-width: 600px');

    $scope.$watch(function() { return $mdMedia('min-width: 600px'); }, function(landscape) {
        $scope.isLandscape = landscape;
    });

    const createMovement = function(movementData) {
        if($scope.topbar_context.sourceLocationId === $scope.topbar_context.targetLocationId) {
            $scope.topbar_context.targetLocationId = null;
        }

        if(typeof ($scope.topbar_context.movementCause) != 'object') {
            throw 'MISSING_MOVEMENT_CAUSE';
        }

        if(!$scope.topbar_context.sourceLocationId) {
            throw 'MISSING_LOCATION_TARGET';
        }

        if($scope.topbar_context.movementCause.value == 'stock_transfer') {
            if(!$scope.topbar_context.targetLocationId) {
                throw 'MISSING_LOCATION_TARGET';
            }

            if($scope.topbar_context.newMovementMode !== 'goods_delivery') {
                delete $scope.topbar_context.supplierOrder;
                delete $scope.topbar_context.movementSupplier;
            }
        }

        if(movementData._entry == null) {
            throw 'MISSING_ENTRY';
        }

        if(movementData.quantity == null) {
            throw 'MISSING_QUANTITY';
        }

        const tmpMovement = _.pick(movementData, ['price', 'perc_discount', 'quantity']);

        Object.assign(tmpMovement, {
            type: $scope.topbar_context.movementCause.type,
            supplier_order: $scope.topbar_context.supplierOrder,
            notes: $scope.topbar_context.movementNotes,
            entry_type: movementData._entry._entryType,
            date: moment($scope.topbar_context.defaultDate).toISOString(),
            operator_id: user.id,
            operator_username: user.username,
            operator_fullname: (user.first_name && user.last_name) ? `${user.first_name} ${user.last_name}` : null,
            stock_location_id: $scope.topbar_context.sourceLocationId,
            supplier_name: $scope.topbar_context.movementSupplier?.name,
            supplier_id: $scope.topbar_context.movementSupplier?.id,
            target_location_id: $scope.topbar_context.movementCause.value == 'stock_transfer' ? $scope.topbar_context.targetLocationId : undefined,
            _scopeRef: movementData
        });

        switch(tmpMovement.entry_type) {
            case 'item':
                Object.assign(tmpMovement, {
                    item_id: movementData._entry.id,
                    combination_id: movementData._entry.combination_id
                });
            break;
            case 'raw_material':
                tmpMovement.raw_material_id = movementData._entry.id;
            break;
            default:
            break;
        }

        switch (tmpMovement.type) {
            case 'load':
                tmpMovement.load_cause = $scope.topbar_context.movementCause.value;
                break;
            case 'unload':
                delete tmpMovement.supplier_id;
                delete tmpMovement.supplier_name;
                tmpMovement.unload_cause = $scope.topbar_context.movementCause.value;
                tmpMovement.quantity *= -1;
                break;
            default:
                break;
        }

        if(tmpMovement.type === 'load' && tmpMovement.load_cause === 'positive_adjustment' && tmpMovement.quantity < 0) {
            Object.assign(tmpMovement, {
                load_cause: undefined,
                type: 'unload',
                unload_cause: 'negative_adjustment'
            });
        }

        return tmpMovement;
    };

    var parseItemOptionsValues = function(item) {
        var optionsValues = [];

        _.times(5, function(i) {
            var idx = i + 1;
            if (checkManager.getPreference('items.option' + idx + '_name')) {
                if(!_.isEmpty(item["option" + idx + "_value"])) {
                    optionsValues.push(item["option" + idx + "_value"]);
                }
            }
        });

        return !_.isEmpty(optionsValues) ? optionsValues.join(', ') : null;
    };

    $scope.topbar_context.onSubmitBarcode = async (barcodeInput) => {
        const foundData = await barcodeManager.searchBarcodeItem(barcodeInput);
        const foundArray = foundData.data;

        if (!foundArray.length) {
            return alertDialog.show($translate.instant('STOCK.NEW_MOVEMENT.BARCODE_NOT_FOUND'));
        }

        const movementsByBarcode = _.keyBy($scope.movementsToSend, 'barcode');

        for(let found of foundArray) {
            const movement = movementsByBarcode[found.barcode];

            if (movement) {
                if (found.quantity) {
                    movement.quantity = _.round(movement.quantity + found.quantity, 4);
                } else {
                    movement.quantity = _.round(movement.quantity + 1, 4);
                }
            } else {
                let item = dictItems.get(`item_${found.item_id}`);

                if (item) {
                    if(Array.isArray(item)) {
                        item = item.find((i) => (i.combination_id == found.combination_id));
                    }

                    if (item.stock_type !== 'simple') {
                        try {
                            await alertDialog.show($translate.instant('STOCK.NEW_MOVEMENT.STOCK_NOT_ENABLED_FOR_SELECTED'));
                        } catch(err) {}
                    }

                    $scope.movementsToSend.push({
                        _entry: item,
                        barcode: found.barcode,
                        combination_id: found.combination_id,
                        entry_type: 'item',
                        item_id: found.item_id,
                        name: item.name,
                        options_values: parseItemOptionsValues(item),
                        quantity: found.quantity || 1,
                        supplier_id: item.default_supplier_id
                    });
                }
            }
        }

        if(foundArray.length !== foundData.total) {
            alertDialog.show($translate.instant('STOCK.NEW_MOVEMENT.SOME_ITEMS_NOT_FOUND'));
        }
    };

    $scope.topbar_context.openCameraBarcode = function() {
        try {
            window.cordova.plugins.barcodeScanner.scan(function(result) {
                if (!result.cancelled) {
                    $scope.topbar_context.onSubmitBarcode(result.text);
                }
            });
        } catch (e) {

        }
    };

    $scope.topbar_context.isAMovementChecked = function() {
        return _.find($scope.movementsToSend, { $checked: true });
    };

    $scope.topbar_context.getUnitCount = function() {
        var count = _($scope.movementsToSend).map('quantity').map(Math.abs).map(_.toFinite).sum();
        var value = _($scope.movementsToSend).map($scope.getRowAmount).sum();

        return $translate.instant('STOCK.NEW_MOVEMENT.PIECES') + count + ' / ' + $filter('sclCurrency')(value);
    };

    var setupCommonDefaults = function(movement) {
        Object.assign($scope.topbar_context, {
            defaultDate: moment(movement.date).toDate(),
            movementCause: _.find($scope.topbar_context.movementCauses, { type: movement.type, value: movement.load_cause || movement.unload_cause || 'setup' }),
            movementSupplier: dictSuppliers.get(movement.supplier_id) || null,
            supplierOrder: movement.supplier_order,
            movementNotes: movement.notes
        });
    };

    $scope.topbar_context.goBack = function() {
        if (!_.isEmpty($scope.movementsToSend)) {
            confirmDialog.show($translate.instant('STOCK.NEW_MOVEMENT.WARNING_MOVEMENTS_NOT_SAVED')).then(function(answer) {
                if (answer) {
                    $state.go('app.stock.movements');
                }
            });
        } else {
            $state.go('app.stock.movements');
        }
    };

    $scope.selectAllMovements = function() {
        $timeout(function() {
            for(let movement of $scope.movementsToSend) {
                movement.$checked = $scope.allMovementsSelected;
            }
        });
    };

    $scope.topbar_context.removeSelectedMovements = async () => {
        const answer = await confirmDialog.show($translate.instant('STOCK.NEW_MOVEMENT.WANT_TO_REMOVE'));

        if (answer) {
            _.remove($scope.movementsToSend, { $checked: true });
        }
    };

    $scope.addNewMovement = function() {
        $scope.movementsToSend.push({});
    };

    $scope.newSupplierOrder = async () => {
        if(!$scope.topbar_context.movementSupplier) {
            return;
        }

        const supplierItems = $scope.items.filter((item) => (item.default_supplier_id === $scope.topbar_context.movementSupplier.id));

        if(!supplierItems.length) {
            return;
        }

        const surchargePerc = await promptDialog.show({ message: $translate.instant('STOCK.NEW_MOVEMENT.SUPPLIER_ORDER_PROMPT_MESSAGE'), label: $translate.instant('STOCK.NEW_MOVEMENT.SURCHARGE_PERC'), type: "number", title: $translate.instant('STOCK.NEW_MOVEMENT.SUPPLIER_ORDER_PROMPT_TITLE'), });

        const defaultSupplierOrder = checkManager.getPreference('stock.supplier_order_progressive') || '1';

        Object.assign($scope.topbar_context, {
            defaultSupplierOrder: defaultSupplierOrder,
            supplierOrder: $scope.topbar_context.supplierOrder || defaultSupplierOrder,
            movementCause: $scope.topbar_context.movementCauses.find((cause) => (cause.value === 'supplier_order')),
            sourceLocationId: 2
        });

        $scope.movementsToSend = [];

        for(let supplierItem of supplierItems) {
            let stockQuantity;

            if(supplierItem.combination_id) {
                stockQuantity = _.toFinite(salesStock?.get(`combination_id_${supplierItem.combination_id}`)?.stock_quantity) + _.toFinite(ordersStock?.get(`combination_id_${supplierItem.combination_id}`)?.stock_quantity);
            } else {
                stockQuantity = _.toFinite(salesStock?.get(`item_id_${supplierItem.id}`)?.stock_quantity) + _.toFinite(ordersStock?.get(`item_id_${supplierItem.id}`)?.stock_quantity);
            }

            const quantityToLoad = Math.ceil((_.toFinite(supplierItem.quantity_alert) * (1 + (surchargePerc / 100))) - stockQuantity);

            if(quantityToLoad > 0) {
                $scope.movementsToSend.push({
                    _entry: supplierItem,
                    quantity: quantityToLoad,
                    price: supplierItem.cost
                });
            }
        }
    };

    $scope.filterEntries = function(searchText) {
        var dataset = _.concat($scope.rawMaterials, $scope.items);
        return _.isEmpty(searchText) ? dataset : $filter('filter')(dataset, searchText);
    };

    $scope.getRowAmount = function(movement) {
        return util.round(_.toFinite(movement.price) * _.toFinite(movement.quantity) * (1 - (_.toFinite(movement.perc_discount) / 100)));
    };

    $scope.getCurrentStockQuantity = (movement) => {
        if(movement._oldQuantity) {
            return `(${movement._oldQuantity})`;
        }

        const item = movement?._entry;

        if(item) {
            const target = item._entryType === 'raw_material' ? `raw_material_id_${item.id}` : (item.combination_id ? `combination_id_${item.combination_id}` : `item_id_${item.id}`);

            if($scope.topbar_context.sourceLocationId === 2) {
                const salesQuantity = _.toFinite(salesStock?.get(target)?.stock_quantity);
                const ordersQuantity =  _.toFinite(ordersStock?.get(target)?.stock_quantity);

                return `(${salesQuantity} ${ordersQuantity >= 0 ? '+' : '-'} ${Math.abs(ordersQuantity)})`;
            } else {
                const sourceStock = stocksByLocation[$scope.topbar_context.sourceLocationId];

                return `(${_.toFinite(sourceStock?.get(target)?.stock_quantity)})`;
            }
        }
    };

    $scope.onEntrySelect = function(movement) {
        movement.price = _.get(movement, ['_entry', 'cost']) || null;
    };

    $scope.onLastFieldTab = function(movement) {
        if(movement === _.last($scope.movementsToSend)) {
            $scope.addNewMovement();
        }
    };

    const updateItemsCost = async (movements) => {
        const itemsToChange = _(movements)
            .filter(function(movement) { return movement._scopeRef._entry._entryType === 'item' && !_.isNil(movement.price); })
            .map(function(movement) { return _.pick(movement, ['item_id', 'combination_id', 'price']); })
            .groupBy('item_id')
            .value();

        for(let itemId in itemsToChange) {
            let item = dictItems.get(`item_${_.toInteger(itemId)}`);

            if(Array.isArray(item)) {
                item = item[0];
            }

            const itemMovement = itemsToChange[itemId][0];

            if(item.cost !== itemMovement.price) {
                item.cost = itemMovement.price;

                try {
                    await entityManager.items.putOneOnline(item);
                } catch(err) {

                }
            }
        }
    };

    const sendMovements = async (movements) => {
        const promise = async () => {
            for(let i = 0; i < movements.length; i++) {
                try {
                    let movement = movements[i];
                    //Send the movements
                    await restManager.post("stock_movements", _.omit(movement, ['_scopeRef']));
                    //Mark the movement as sent
                    movement._scopeRef._sent = true;
                    //Update the wait dialog progress
                    $rootScope.$broadcast("wait-dialog:update-state", { message: $translate.instant("STOCK.NEW_MOVEMENT.SEND_MOVEMENTS", { first: i, second: movements.length }) });
                } catch(err) {}
            }
        };

        //Show the wait dialog and send the movements
        await waitDialog.show({ message: $translate.instant("STOCK.NEW_MOVEMENT.SEND_MOVEMENTS", { first: 0, second: movements.length }), promise: promise() });

        //Remove sent movements
        const sentMovements = _.remove($scope.movementsToSend, { _sent: true });
        
        if(checkManager.isModuleEnabled('items') && !checkManager.getPreference('stock_movements.disable_item_cost_update')) {
            updateItemsCost(movements.filter((movement) => movement._scopeRef._sent));
        }

        if ($scope.movementsToSend.length) {
            return alertDialog.show($translate.instant('STOCK.NEW_MOVEMENT.ERROR_IN_SAVE'));
        }

        if($scope.topbar_context.defaultSupplierOrder && ($scope.topbar_context.defaultSupplierOrder === $scope.topbar_context.supplierOrder)) {
            checkManager.setShopPreference('stock.supplier_order_progressive', _.toInteger($scope.topbar_context.defaultSupplierOrder) + 1);
        }

        if($scope.topbar_context.newMovementMode === 'goods_delivery') {
            for(let movement of sentMovements) {
                if(movement._oldQuantity !== movement.quantity) {
                    movement.quantity = _.toFinite(movement.quantity) - _.toFinite(movement._oldQuantity);
                    delete movement._oldQuantity;
                    delete movement._sent;
                    $scope.movementsToSend.push(movement);
                }
            }

            Object.assign($scope.topbar_context, {
                movementCause: $scope.topbar_context.movementCauses.find((cause) => (cause.value === 'positive_adjustment')),
                newMovementMode: 'goods_delivery_adjustment',
                sourceLocationId: 2
            });

            delete $scope.topbar_context.targetLocationId;
            delete $scope.selectAllGoodsForDelivery;

            if($scope.movementsToSend.length) {
                return;
            }
        }

        $state.go('app.stock.movements');
    };

    $scope.topbar_context.hasMovements = function() {
        return !_.isEmpty($scope.movementsToSend);
    };

    $scope.topbar_context.commitMovements = async () => {
        try {
            //Remove empty rows
            _.remove($scope.movementsToSend, (movement) => (movement._entry == null && movement.quantity == null && movement.price == null && movement.perc_discount == null));

            if(!_.isEmpty($scope.movementsToSend)) {
                const tmpMovements = _.map($scope.movementsToSend, createMovement);
    
                let answer = await confirmDialog.show($translate.instant('STOCK.NEW_MOVEMENT.WANT_TO_SAVE'));

                if (answer) {
                    sendMovements(tmpMovements);
                }
            }
        } catch(error) {
            alertDialog.show($translate.instant(`STOCK.NEW_MOVEMENT.ERRORS.${error}`));
        }
    };

    const dictItems = new SclDictionary();
    const dictMaterials = new SclDictionary();
    const dictSuppliers = new SclDictionary();

    $scope.items = _.chain(items)
        .filter((item) => (item.stock_type === 'simple' || (item.stock_type === 'bom' && item.manual_production)))
        .sortBy('name')
        .map((item) => {
            let res;

            if(!_.isEmpty(item.combinations)) {
                res = _.map(item.combinations, function(combination) {
                    return Object.assign({}, item, {
                        combination_id: combination.id,
                        _entryType: 'item',
                        _name: [item.name, '(' + _(combination.combination_values).map('value').join(', ') + ')'].join(' '),
                    });
                });
            } else {
                res = Object.assign(item, {
                    _entryType: 'item',
                    _name: item.name,
                });
            }

            return res;
        })
        .flatten()
        .value();

    for(let item of $scope.items) {
        if(item.combination_id) {
            dictItems.set(`combination_${item.combination_id}`, item);
        } else {
            dictItems.set(`item_${item.id}`, item);
        }
    }

    for(let supplier of suppliers) {
        dictSuppliers.set(supplier.id, supplier);
    }

    for(let rawMaterial of rawMaterials) {
        rawMaterial._name = [rawMaterial.name, `(${$translate.instant('STOCK.NEW_MOVEMENT.RAW_MATERIAL')})`].join(' ');

        dictMaterials.set(rawMaterial.id, Object.assign(rawMaterial, { _entryType: 'raw_material' }));
    }

    if(!_.isEmpty($stateParams.movements)) {
        let movementsToAdd = _.cloneDeep($stateParams.movements);

        if(!Array.isArray(movementsToAdd)) {
            movementsToAdd = [movementsToAdd];
        }

        for(let movement of movementsToAdd) {
            movement._entry = movement.entry_type === 'item' ? dictItems.get(movement.combination_id ? `combination_${movement.combination_id}` : `item_${movement.item_id}`) : dictMaterials.get(movement.raw_material_id);
        }

        $scope.movementsToSend = movementsToAdd;

        setupCommonDefaults(movementsToAdd[0]);

        if($stateParams.mode === 'goods_delivery') {
            for(let movement of movementsToAdd) {
                movement._oldQuantity = movement.quantity;
                movement.quantity = 0;
            }

            Object.assign($scope.topbar_context, {
                defaultDate: moment().milliseconds(0).seconds(0).toDate(),
                newMovementMode: $stateParams.mode,
                movementCause: $scope.topbar_context.movementCauses.find((cause) => (cause.value === 'stock_transfer')),
                sidenav_label: $translate.instant('STOCK.MOVEMENTS.OPTIONS.GOODS_DELIVERY'),
                sourceLocationId: 2,
                targetLocationId: 1
            });

            $scope.selectAllGoodsForDelivery = async () => {
                const answer = await confirmDialog.show($translate.instant('STOCK.NEW_MOVEMENT.ALL_GOODS_DELIVERY_CONFIRM'));
        
                if(answer) {
                    for(let movement of $scope.movementsToSend) {
                        if(movement._oldQuantity) {
                            movement.quantity = movement._oldQuantity;
                        }
                    }
                }
            };
        }
    } else {
        $scope.addNewMovement();
    }
}
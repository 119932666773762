import { inject, Injectable } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { DocumentPrinterManagerDialogComponent } from "./document-printers-manager-dialog.component";
import {NonNullableConfigData} from "@tilby/tilby-ui-lib/components/tilby-dialog";
import { NonNullablePortalConfigData, OpenDialogsService, PortalConfigData } from "../services";
import { InputItemDataMagicForm } from "../../shared/model/model";
import { lastValueFrom, map } from "rxjs";
import { MagicPortalDialogComponent } from "../magic-portal-dialog";
import {
    CustomFormArrayProps,
    CustomFormControl,
    CustomFormControlProps,
    CustomFormGroup,
} from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { CustomForm } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { Validators } from "@angular/forms";
import {
    ConfigurationManagerService,
    documentTypes,
    EntityManagerService,
    EnvironmentInfoService,
    PrinterDocumentType,
    PrinterDocumentTypeCheck,
    PrinterDocumentTypeId
} from "src/app/core";
import { DocumentPrinterOptions, OptionsInDocumentPrinterOptions } from "src/app/shared/model/document-printer.model";
import { NonFiscalDocuments, Printers } from "tilby-models";
import _ from "lodash";
import { TranslateService } from "@ngx-translate/core";

type Options = {
    taxFree?: boolean,
    courtesyReceipt: boolean,
    printOnlyDepartments: boolean,
    printEInvoiceReceipt: boolean,
    printDDT: boolean
}

export type DocumentPrintersManagerData = {
    printer: string,
    document: string,
    options: Options,
    defaultDocument: boolean,
};

export type DocumentPrintersManagerForm = CustomFormGroup<CustomForm<DocumentPrintersManagerData>>
type AvailableDocuments = { document_type: PrinterDocumentType, printer: Printers, document_template?: NonFiscalDocuments }
type FE_Printers = Printers & { availableDocuments?: AvailableDocuments[] };

type ScanPrintersCapabilitiesOptions = {
    summaryOnly?: boolean,
    skipProtocolCheck?: boolean
}
export type DocumentPrintersManagerDialogData = InputItemDataMagicForm<DocumentPrintersManagerData>;

@Injectable({
    providedIn: 'root',
})
export class DocumentPrintersManagerDialogStateService {
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly environmentInfoService = inject(EnvironmentInfoService);
    private readonly translate = inject(TranslateService);

    private readonly allowedNonFiscalProtocols = this.environmentInfoService.canUseTcpSockets() ? ['epos', 'escpos'] : ['epos'];
    private readonly unallowedFiscalProtocols = this.environmentInfoService.canUseTcpSockets() ? [] : ['ts'];

    private dialogRef?: MatDialogRef<MagicPortalDialogComponent<DocumentPrinterManagerDialogComponent>, PortalConfigData<DocumentPrintersManagerDialogData, DocumentPrinterManagerDialogComponent>>;
    private _form?: DocumentPrintersManagerForm;

    private summaryOnly?: boolean;

    private printers: FE_Printers[] = [];
    private nonFiscalDocuments: NonFiscalDocuments[] = [];
    private defaultPrinterId?: number;
    private selectedPrinter?: FE_Printers;
    private selectedDocument?: AvailableDocuments;
    private options?: OptionsInDocumentPrinterOptions;

    // UTILS
    private checkCapability(capability: PrinterDocumentTypeCheck, printer: Printers) {
        if (!printer) {
            return false;
        }

        //Wildcard type is used only internally to access every document available on the application (used in method payments configuration)
        if (printer.type === 'wildcard') {
            return true;
        }

        switch (capability) {
            case 'print_invoice':
            case 'print_receipt_invoice':
            case 'print_summary_invoice':
            case 'print_summary_e_nrc':
            case 'print_summary_e_rc':
            case 'print_shipping_invoice':
            case 'print_e_invoice':
                return (this.configurationManagerService.isUserPermitted('cashregister.print_invoice')) ? printer[capability] : false;
            case 'print_generic_document':
            case 'print_generic_invoice':
            case 'print_receipt':
                return printer[capability];
            default:
                return printer[capability];
        }
    };

    private mapTemplateToPrinter = (type: PrinterDocumentType, printer: FE_Printers) => (documentTemplate: NonFiscalDocuments): AvailableDocuments => ({
        document_template: documentTemplate,
        document_type: type,
        printer: printer
    });

    private getAvaliableDocumentsUniqueId = (selectedDocument: AvailableDocuments | undefined) => {
        return `${selectedDocument?.document_template?.id || selectedDocument?.document_type?.id || 'NO_ID'}`;
    }

    /*-------PUBLIC METHODS---------*/
    public scanPrintersCapabilities(printers: FE_Printers[], nonFiscalDocuments: NonFiscalDocuments[], options: ScanPrintersCapabilitiesOptions) {
        const nonfiscalDocumentsByType = _.chain(nonFiscalDocuments).groupBy('type').cloneDeep().value();
        const fiscalDocumentTypes = documentTypes.filter((docType) => docType.printers.find((printerType) => ['fiscal', 'rt'].includes(printerType)));
        const nonFiscalDocumentTypes = documentTypes.filter((docType) => docType.printers.find((printerType) => ['receipt'].includes(printerType)));

        if (typeof options !== 'object') {
            options = {};
        }

        for (let printer of printers) {
            let localPrinter = structuredClone(printer); //used to avoid self-references in printer object
            let documentsAvailable: AvailableDocuments[] = [];
            let documentsToScan: PrinterDocumentType[] = [];

            switch (printer.type) {
                case 'fiscal': case 'rt':
                    if (options.skipProtocolCheck || (printer.connection_type && !this.unallowedFiscalProtocols.includes(printer.connection_type))) {
                        documentsToScan = fiscalDocumentTypes;
                    }
                    break;
                case 'receipt':
                    if (options.skipProtocolCheck || this.allowedNonFiscalProtocols.includes(printer.driver)) {
                        documentsToScan = nonFiscalDocumentTypes;
                    }
                    break;
                case 'wildcard':
                    documentsToScan = [...fiscalDocumentTypes, ...nonFiscalDocumentTypes];
                    break;
                default: break;
            }

            //Show only summary documents if requested, otherwise hide them
            const summaryOnly = !!options.summaryOnly;

            documentsToScan = documentsToScan.filter((docType) => docType.id.startsWith('summary_') === summaryOnly);

            for (const type of Object.values(documentsToScan)) {
                if (type.check && this.checkCapability(type.check, printer)) {
                    if (type.id.startsWith('generic_')) {
                        documentsAvailable.push(...(nonfiscalDocumentsByType[type.id]?.map((template) => this.mapTemplateToPrinter(type, printer)(template)) || []));
                    } else {
                        documentsAvailable.push({ document_type: type, printer: localPrinter });
                    }
                }
            }

            printer.availableDocuments = documentsAvailable;
        }
    };
    public getDocumentTypeName(documentData?: AvailableDocuments | DocumentPrinterOptions, translationBase: string = 'CASHREGISTER.DOCUMENTS_MANAGER', options?: { excludeReceipts?: boolean }): string | null {
        const docType = documentData?.document_type?.id;

        if (!docType) {
            return null;
        }

        if(options?.excludeReceipts && ['fiscal_receipt', 'generic_receipt'].includes(docType)) {
            return null;
        }

        switch (docType) {
            case "fiscal_receipt":
                return ['rt', 'wildcard'].includes(documentData?.printer?.type) ? this.translate.instant(`${translationBase}.COMMERCIAL_DOC`) : this.translate.instant(`${translationBase}.FISCAL_RECEIPT`);
            case 'invoice':
            case 'e_invoice':
            case 'receipt_invoice':
            case 'summary_invoice':
            case 'summary_e_nrc':
            case 'summary_e_rc':
            case 'shipping_invoice':
                return this.translate.instant(`${translationBase}.${docType.toUpperCase()}`);
            case 'generic_receipt': case 'generic_invoice': case 'generic_document':
                return documentData?.document_template?.name || this.translate.instant(`${translationBase}.UNKNOWN_DOCUMENT`);
            default:
                return this.translate.instant(`${translationBase}.UNKNOWN_DOCUMENT`);
        }
    }
    public async getPrinterDocumentData(printerID: number | 'default', chosenTypes: PrinterDocumentTypeId | PrinterDocumentTypeId[] | number | 'default', options?: Partial<OptionsInDocumentPrinterOptions>) {
        let documentTemplate: NonFiscalDocuments | undefined;

        if (printerID === 'default') {
            printerID = parseInt(<string>this.configurationManagerService.getPreference("fiscalprinter.def.id") || '');
        }

        if (!printerID) {
            throw 'ID_NOT_SET';
        }

        //Get the printer
        const printer = await this.entityManagerService.printers.fetchOneOffline(printerID);

        if (!printer) {
            throw 'PRINTER_NOT_FOUND';
        }

        //Check if we can use the printer on this environment
        let isPrinterUsable;

        switch (printer.type) {
            case 'fiscal': case 'rt':
                isPrinterUsable = printer.connection_type && !this.unallowedFiscalProtocols.includes(printer.connection_type);
                break;
            case 'receipt':
                isPrinterUsable = this.allowedNonFiscalProtocols.includes(printer.driver);
                break;
            default:
                throw "UNKNOWN_PRINTER_TYPE";
        }

        if (!isPrinterUsable) {
            throw "PRINTER_NOT_SUPPORTED";
        }

        //Figure out the document type that we need to print
        if (chosenTypes === 'default') {
            chosenTypes = <PrinterDocumentTypeId>this.configurationManagerService.getPreference('fiscalprinter.def.document_type') || '';
        }

        const chosenTypeInt = parseInt(String(chosenTypes));

        if (chosenTypeInt) { //We have a template ID here, so find the related template and figure out the document type by it
            documentTemplate = await this.entityManagerService.nonfiscalDocuments.fetchOneOffline(chosenTypeInt);

            if (!documentTemplate) {
                throw "UNKNOWN_DOCUMENT_TYPE";
            }

            //Assign the found template and set the document type
            chosenTypes = documentTemplate.type as PrinterDocumentTypeId;
        }

        //Check if the document type is actually usable by the selected printer
        if (!chosenTypes) {
            chosenTypes = [];
        } else if (!Array.isArray(chosenTypes)) {
            chosenTypes = (chosenTypes ? [chosenTypes] : []) as PrinterDocumentTypeId[];
        }

        let targetType: PrinterDocumentTypeId | undefined;

        for (let i = 0; i < chosenTypes.length && !targetType; i++) {
            const docType = chosenTypes[i];
            const isCapable = this.checkCapability(`print_${docType}`, printer);

            if (isCapable) {
                if (docType.startsWith('generic_') && !documentTemplate) {
                    //Find the first available template if the user wants to print a generic (non-fiscal) document
                    const nonFiscalDocuments = await this.entityManagerService.nonfiscalDocuments.fetchCollectionOffline({ type: docType });

                    //Skip to the next type if we don't have any compatible templates
                    if (!nonFiscalDocuments.length) {
                        continue;
                    }

                    documentTemplate = nonFiscalDocuments[0];
                }

                targetType = docType;
            }
        }

        if (!targetType || (!documentTemplate && targetType.startsWith('generic_'))) {
            if (!targetType) {
                throw "PRINTER_NOT_CAPABLE";
            } else {
                throw "MISSING_DOCUMENT_TEMPLATE";
            }
        }

        //Finally return the document data config to the caller
        return <DocumentPrinterOptions>{
            document_type: structuredClone(documentTypes.find(docType => docType.id == targetType)),
            document_template: documentTemplate || null,
            printer: printer,
            options: {
                courtesyReceipt: (options?.courtesyReceipt ?? !!this.configurationManagerService.getPreference("fiscalprinter.def.courtesy_receipt")),
                eReceipt: !!options?.eReceipt,
                printDDT: !!this.configurationManagerService.getPreference("fiscalprinter.def.print_ddt"),
                printEInvoiceReceipt: options?.eReceipt ? false : this.configurationManagerService.getPreference("fiscalprinter.def.print_invoice_receipt") ?? true,
                printOnlyDepartments: (options?.printOnlyDepartments ?? !!this.configurationManagerService.getPreference("fiscalprinter.def.print_only_departments")),
                taxFree: options?.taxFree
            }
        };
    }
    // OPEN_DIALOG
    public async beforeOpenDialog(documentSettings?: DocumentPrinterOptions){
        await this.resolveEntities();
        this.init(documentSettings);
        return this.createForm();
    }
    public async openDialog(config?: NonNullableConfigData<{ documentSettings?: DocumentPrinterOptions }>) {
        const documentSettings = config?.data.documentSettings;
        await this.beforeOpenDialog(documentSettings);

        const configPortal: NonNullablePortalConfigData<DocumentPrintersManagerDialogData, DocumentPrinterManagerDialogComponent> = {
            data: {
                form: this._form!,
                component: DocumentPrinterManagerDialogComponent,
                titleLabel: 'CASHREGISTER.DOCUMENTS_MANAGER.TITLE',
            }
        };

        this.dialogRef = this.openDialogsService.openPortalDialogRef<DocumentPrinterManagerDialogComponent, PortalConfigData<DocumentPrintersManagerDialogData, DocumentPrinterManagerDialogComponent>>({
            ...this.openDialogsService.switchMobileDesktopDimensions({ width: '48rem' }),
            ...configPortal
        });

        return await lastValueFrom(this.dialogRef.afterClosed()
            .pipe(
                map(result => result?.form?.value),
                // @ts-ignore
                map(res => res && this.confirm(res)),
            ));
    }

    /*-------PRIVATE METHODS---------*/
    // METHODS IN OPEN_DIALOG
    private async resolveEntities() {
        this.printers = await this.entityManagerService.printers.fetchCollectionOffline();
        this.nonFiscalDocuments = await this.entityManagerService.nonfiscalDocuments.fetchCollectionOffline();
    }

    private init(documentSettings?: DocumentPrinterOptions) {
        this.summaryOnly = documentSettings?.document_type?.id.startsWith('summary');
        this.scanPrintersCapabilities(this.printers, this.nonFiscalDocuments, { summaryOnly: this.summaryOnly });
        this.printers = this.printers.filter(printer => (printer.availableDocuments?.length || 0) > 0);

        if (this.printers.length > 0) {
            this.defaultPrinterId = documentSettings?.printer?.id ?? parseInt(this.configurationManagerService.getPreference("fiscalprinter.def.id") || '')
            //Try to obtain the current document from document settings, otherwise use the preferences
            let { defaultDocumentType, defaultTemplate } = this.getCurrentDocument(documentSettings);
            //Initialize printer index
            this.setSelectedPrinter();
            //Initialize document
            this.setSelectedDocument(defaultTemplate, defaultDocumentType);
            //Initialize options
            this.setOptions(documentSettings);
        }
    }
    private createForm() {
        this.changePrinter(this.printers.find(({ id }) => id == (this.defaultPrinterId || '')) || this.printers[0]);

        return this._form = new CustomFormGroup<CustomForm<DocumentPrintersManagerData>>({
            printer: new CustomFormControl({
                value: `${this.selectedPrinter?.id}`,
                disabled: false
            }, {
                validators: [Validators.required]
            }, {
                ...new CustomFormControlProps(),
                label: 'CASHREGISTER.DOCUMENTS_MANAGER.PRINTER',
                icon: { prefix: 'print' },
                inputType: 'select',
                inputChoices: this.printers.map((printer) => ({
                    key: printer.name,
                    value: `${printer.id}` || printer.name,
                    ...(printer.id == this.defaultPrinterId && { icon: 'star_rate' })
                })),
                class: 'tw-w-full tw-mx-0'
            }),
            document: new CustomFormControl({
                value: `${this.getAvaliableDocumentsUniqueId(this.selectedDocument)}`,
                disabled: false
            }, {
                validators: [Validators.required]
            }, {
                ...new CustomFormControlProps(),
                label: 'CASHREGISTER.DOCUMENTS_MANAGER.DOCUMENT',
                inputType: 'radio',
                class: 'sm:tw-max-w-[50%] tw-overflow-y-hidden',
                matElementClass: 'tw-flex-col tw-mx-0 tw-min-h-[10rem] tw-py-1 tw-max-h-80 tw-overflow-y-auto tw-w-full',
                inputChoices: this.getAvailableDocuments(this.selectedPrinter)
            }),
            options: new CustomFormGroup({
                courtesyReceipt: new CustomFormControl(
                    !!this.options?.courtesyReceipt,
                    { validators: [Validators.required] },
                    {
                        ...new CustomFormControlProps(),
                        label: 'CASHREGISTER.DOCUMENTS_MANAGER.COURTESY_RECEIPT',
                        inputType: 'checkbox',
                        class: ''
                    }
                ),
                printOnlyDepartments: new CustomFormControl(
                    !!this.options?.printOnlyDepartments,
                    { validators: [Validators.required] },
                    {
                        ...new CustomFormControlProps(),
                        label: 'CASHREGISTER.DOCUMENTS_MANAGER.PRINT_ONLY_DEPARTMENTS',
                        inputType: 'checkbox',
                        class: ''
                    }
                ),
                printEInvoiceReceipt: new CustomFormControl(
                    !!this.options?.printEInvoiceReceipt,
                    { validators: [Validators.required] },
                    {
                        ...new CustomFormControlProps(),
                        label: 'CASHREGISTER.DOCUMENTS_MANAGER.PRINT_E_INVOICE',
                        inputType: this.isEinvoiceSelected() ? 'checkbox' : "hidden",
                        class: ''
                    }
                ),
                printDDT: new CustomFormControl(
                    !!this.options?.printDDT,
                    { validators: [Validators.required] },
                    {
                        ...new CustomFormControlProps(),
                        label: 'CASHREGISTER.DOCUMENTS_MANAGER.PRINT_DDT',
                        inputType: this.isEinvoiceSelected() ? 'checkbox' : "hidden",
                        class: ''
                    }
                ),
            }, {}, {
                ...new CustomFormArrayProps(),
                label: 'CASHREGISTER.DOCUMENTS_MANAGER.OPTIONS',
                class: 'tw-self-start tw-flex-1 tw-pb-8 sm:tw-pb-0'
            }),
            defaultDocument: new CustomFormControl({
                value: false,
                disabled: false
            }, {
                validators: [Validators.required]
            }, {
                ...new CustomFormControlProps(),
                inputType: 'slideToggle',
                label: 'CASHREGISTER.DOCUMENTS_MANAGER.SAVE_AS_DEFAULT',
                class: 'tw-pl-2 tw-mx-0 tw-absolute tw-bottom-0 tw-pb-4 tilby-dialog-background sm:tw-bottom-0 sm:tw-relative'
            }),
        }, {});
    }

    public confirm(res: Partial<DocumentPrintersManagerData>): DocumentPrinterOptions {
        if(!this.selectedPrinter || !this.selectedDocument) {
            throw { printError: 'CANCELED' };
        }

        if (res.defaultDocument) {
            if (this.selectedPrinter) {
                this.configurationManagerService.setUserPreference("fiscalprinter.def.id", this.selectedPrinter?.id?.toString() || '');
            }

            if (res.options) {
                this.configurationManagerService.setUserPreference("fiscalprinter.def.courtesy_receipt", res.options.courtesyReceipt);
                this.configurationManagerService.setUserPreference("fiscalprinter.def.print_only_departments", res.options.printOnlyDepartments);
            }

            if (res.document && !this.summaryOnly) {
                this.configurationManagerService.setUserPreference("fiscalprinter.def.document_type", this.selectedDocument?.document_template?.id ?? this.selectedDocument?.document_type.id ?? '');
            }

            if (this.isEinvoiceSelected() && res.options) {
                this.configurationManagerService.setUserPreference("fiscalprinter.def.print_invoice_receipt", res.options.printEInvoiceReceipt);
                this.configurationManagerService.setUserPreference("fiscalprinter.def.print_ddt", res.options.printDDT);
            }
        }

        return <DocumentPrinterOptions>{
            options: {
                taxFree: !!this.options?.taxFree,
                courtesyReceipt: !!res.options?.courtesyReceipt,
                eReceipt: false,
                printOnlyDepartments: !!res.options?.printOnlyDepartments,
                printEInvoiceReceipt: !!res.options?.printEInvoiceReceipt,
                printDDT: !!res.options?.printDDT
            },
            printer: this.selectedPrinter,
            document_type: this.selectedDocument?.document_type,
            document_template: this.selectedDocument?.document_template
        };
    }

    // METHODS IN INIT
    private getCurrentDocument(documentSettings?: DocumentPrinterOptions) {
        let defaultDocumentType = documentSettings?.document_type?.id;
        let defaultTemplate: number | undefined;

        if (defaultDocumentType) {
            if (defaultDocumentType.startsWith('generic_')) {
                defaultTemplate = documentSettings?.document_template?.id;
            }
        } else {
            defaultDocumentType = this.configurationManagerService.getPreference("fiscalprinter.def.document_type") as PrinterDocumentTypeId;

            if (parseInt(defaultDocumentType)) {
                defaultTemplate = parseInt(defaultDocumentType);
            }
        }
        return { defaultDocumentType, defaultTemplate };
    }
    private setSelectedPrinter() {
        if (this.defaultPrinterId) {
            this.selectedPrinter = this.printers.find(printer => printer.id == this.defaultPrinterId);
        }
    }
    private setSelectedDocument(defaultTemplate: number | undefined, defaultDocumentType: string) {
        this.selectedDocument = this.selectedPrinter?.availableDocuments?.find(
            (documentData) => defaultTemplate
                ? documentData?.document_template?.id === defaultTemplate
                : documentData?.document_type?.id === defaultDocumentType)
            || this.selectedPrinter?.availableDocuments?.[0];
    }
    private setOptions(documentSettings?: DocumentPrinterOptions) {
        if (typeof documentSettings == 'object' && !!Object.keys(documentSettings?.options||{}).length) {
            ({options:this.options}=documentSettings); //destructor and assign to this.options
        }
        else {
            this.options = {
                taxFree: false,
                courtesyReceipt: false,
                printOnlyDepartments: false,
                printEInvoiceReceipt: true,
                eReceipt:false,
                printDDT:false
            }
        }
    }

    // METHODS IN CREATE FORM
    private changePrinter(printer: FE_Printers) {
        this.selectedPrinter = printer;
        this.selectedDocument = this.selectedPrinter?.availableDocuments?.find((doc) => doc === this.selectedDocument) || this.selectedPrinter?.availableDocuments?.[0];
    }

    private getAvailableDocuments(selectedPrinter: FE_Printers | undefined) {
        return selectedPrinter?.availableDocuments?.map((docType) => ({ key: this.getDocumentTypeName(docType) || '', value: (`${this.getAvaliableDocumentsUniqueId(docType)}`) })) || [];
    }

    private isEinvoiceSelected(selectedDocument: AvailableDocuments | undefined = this.selectedDocument) {
        const { id } = selectedDocument?.document_type || {}
        return id && ['e_invoice', 'summary_e_rc', 'summary_e_nrc'].includes(id);
    }

    // METHODS IN VALUE CHANGES
    public printerControlChanges(printerSelection: string) {
        const selectedPrinter = this.printers.find(printer => printerSelection && ((printer.id || printer.name) == printerSelection));
        if (selectedPrinter) {
            this.changePrinter(selectedPrinter);
            if (this.documentControl) {
                this.documentControl.customProps.inputChoices = this.getAvailableDocuments(selectedPrinter);
                this.documentControl.setValue(this.getAvaliableDocumentsUniqueId(this.selectedDocument));
            }
        }
    }
    public documentControlChanges(selectedDocumentId: PrinterDocumentTypeId | number) {
        this.selectedDocument = this.selectedPrinter?.availableDocuments?.find(doc => this.getAvaliableDocumentsUniqueId(doc) == selectedDocumentId);
        const type = this.isEinvoiceSelected(this.selectedDocument) ? 'checkbox' : 'hidden';
        this.optionsPrintEInvoiceControl!.customProps.inputType = type;
        this.optionsPrintDdtControl!.customProps.inputType = type;
        return this.selectedDocument;
    }

    //FORM_CONTROLLERS GET
    private get documentControl() {
        return this._form?.controls.document;
    }
    private get optionsFormArray() {
        return this._form?.controls.options;
    }
    private get optionsPrintEInvoiceControl() {
        return this.optionsFormArray?.controls.printEInvoiceReceipt;
    }
    private get optionsPrintDdtControl() {
        return this.optionsFormArray?.controls.printDDT;
    }
}

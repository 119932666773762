import {
    Component,
    Input,
    signal,
    SimpleChanges
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { RoomsTables } from 'tilby-models';
import { matchAllWords } from "@tilby/tilby-ui-lib/utilities";

import {
    Color,
    ICON_TO_SVG_ELEMENTS,
    ICON_TO_SVG_TABLES,
    STATIC_ELEMENT_PREFIX
} from './tilby-booking-tables.model';

import { MatIconModule } from '@angular/material/icon';
import { TilbyDatePipe } from '@tilby/tilby-ui-lib/pipes/tilby-date';

type ColorsHEX = '#009688' | '#8e8e8e';

type ColorState = {
    name: Color,
    HEX: ColorsHEX,
    showCovers: boolean,
    showBookings: boolean,
    class?: string,
}

const tableColorStates: Record<Color, ColorState> = {
    green: {
        name: 'green',
        HEX: '#009688',
        showCovers: false,
        showBookings: true,
        class: 'tw-fill-white',
    },
    grey: {
        name: 'grey',
        HEX: '#8e8e8e',
        showCovers: true,
        showBookings: false,
        class: 'tw-fill-white',
    },
};

@Component({
    selector: 'tilby-booking-table-element',
    standalone: true,
    imports: [
        CommonModule,
        TilbyDatePipe,
        MatIconModule
    ],
    templateUrl: './tilby-booking-table-element.component.html',
    styleUrls: ['./tilby-booking-table-element.component.scss']
})
export class TilbyBookingTableElementComponent {
    protected readonly STATIC_ELEMENT_PREFIX = STATIC_ELEMENT_PREFIX;

    protected tableName = '';
    protected width = 0;
    protected height = 0;
    protected minSize = 0;

    @Input() public scale = 1;
    @Input() public covers = 0;
    @Input() public disableTableInfo = false;
    @Input() minutes = "0'";
    @Input({ required: true }) shapesPath!: string;
    @Input({ required: true }) furnituresPath!: string;

    protected shape = 'circle';
    protected colorState = tableColorStates['green'];
    protected svg = ICON_TO_SVG_TABLES.circle4;
    protected isTable = true;
    protected toggleShowAmount = true;

    @Input() billLockForEditMode = false;
    @Input() table?: RoomsTables;
    @Input() bookingInfo!: { table_id: number, booking_id: number, people: number, booked_for: string }[];

    private _color = signal<Color>('green');

    @Input() set color(color: Color) {
        const nextColorState = tableColorStates[color];

        if (nextColorState) {
            this.colorState = nextColorState;
            this._color.set(color);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        const table = this.table;
        
        if(!table || (!changes['table']?.currentValue && !changes['scale']?.currentValue)) {
            return;
        }

        this.tableName = table.name;
        this.width = table.width * this.scale;
        this.height = table.height * this.scale;
        this.minSize = (this.width < this.height ? this.width : this.height);
        this.shape = matchAllWords(table.shape)?.[0] || 'square';
        this.isTable = !this.shape.startsWith(STATIC_ELEMENT_PREFIX);

        // @ts-ignore
        this.svg = this.isTable ? ICON_TO_SVG_TABLES[table.shape] : ICON_TO_SVG_ELEMENTS[table.shape];
    }

    onDragStart(event: DragEvent, value: string) {
        (event.dataTransfer) && (event.dataTransfer.setData('application/json', JSON.stringify(this.table)));
    }
}

import angular from 'angular';

angular.module('settings').factory('activeSiteDialog', activeSiteDialog);

activeSiteDialog.$inject = ['$mdDialog', '$translate', 'restManager', 'checkManager', 'errorsLogger'];

function activeSiteDialog($mdDialog, $translate, restManager, checkManager, errorsLogger) {
    activeSiteDialogController.$inject = ['$scope', '$mdDialog'];

    function activeSiteDialogController($scope, $mdDialog) {
        Object.assign($scope, {
            hostnameRegExp:  /^[a-z0-9-]+$/,
            error: {
                show: false,
                message: ''
            }
        });

        $scope.hide = function() {
            $mdDialog.hide();
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.answer = function() {
            const hostname = $scope.data.hostname.trim().toLowerCase();
            const payload = {
                hostname: hostname
            };
            restManager.post('ecommerce/activate', payload).then(res => {
                errorsLogger.debug('POST ecommerce/activate', res);
                if(res && res.message) {
                    checkManager.setShopPreference('ecommerce.hostname', hostname);
                    $mdDialog.hide($translate.instant('SETTINGS.ACTIVE_SITE_DIALOG.SUCCESS_MSG'));
                }
            }).catch(res => {
                errorsLogger.err('POST ecommerce/activate', res);
                let errorMessage = $translate.instant('SETTINGS.ACTIVE_SITE_DIALOG.ERROR.GENERIC_ERROR');
                if(res && res.status === 400) {
                    const error = res.error;
                    switch(error) {
                        case 'Something went wrong':
                        case 'invalidInputFormat':
                            errorMessage = $translate.instant('SETTINGS.ACTIVE_SITE_DIALOG.ERROR.SOMETHING_WENT_WRONG');
                            break;
                        case 'invalidInput':
                            errorMessage = $translate.instant('SETTINGS.ACTIVE_SITE_DIALOG.ERROR.INVALID_INPUT');
                            break;
                        default:
                            errorMessage = $translate.instant('SETTINGS.ACTIVE_SITE_DIALOG.ERROR.WRONG_INPUT');
                            break;
                    }
                }
                $scope.error.message = errorMessage;
                $scope.error.show = true;
            });
        };

        $scope.resetApiError = () => {
            if($scope.error.show) {
                $scope.error.show = false;
            }
        };

    }

    var activeSiteDialog = {
        show: function() {
            return $mdDialog.show({
                controller: activeSiteDialogController,
                template: require('./active-site-dialog.html')
            });
        }
    };

    return activeSiteDialog;
}
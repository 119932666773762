import angular from 'angular';
import _ from 'lodash';
import moment from 'moment-timezone';
import { v4 as generateUuid } from 'uuid';
import { countryCodes } from 'src/app/core/constants/country-codes';
import { blobToDataURL } from 'src/app/shared/data-conversion-utils';
import { stringToLines } from 'src/app/shared/string-utils';

const { tilbyVersion } = require('app/tilby.properties.json');

const dailyClosingCountryBlacklist = [
	'FR'
];

angular.module('printers').factory('RetailForceProvider', retailForceProvider);

retailForceProvider.$inject = ['$rootScope', 'fiscalUtils', 'salePayment', 'checkManager', 'errorsLogger', 'restManager', 'entityManager', 'OperatorManager', 'util'];

function retailForceProvider($rootScope, fiscalUtils, salePayment, checkManager, errorsLogger, restManager, entityManager, OperatorManager, util) {
	let rfVersion;
	let rfClientVersion;

	/**
	 *  Tilby RetailForce driver for Austria and Germany
	 */
	const getRetailForceClientID =  () => {
		const clientID = checkManager.getSettingUserFirst('retailforce.client_id');

		if (!clientID) {
			throw 'RETAILFORCE_NOT_CONFIGURED';
		}

		return clientID;
	};

	const getTilbyVersion = () => {
		return checkManager.getSetting('retailforce.tilby_version_override') || tilbyVersion;
	};

	const getTerminalNumber = () => {
		return checkManager.getSettingUserFirst('retailforce.terminal_number') || checkManager.getPreference('retailforce.terminal_number');
	};

	const getSaleRetailForceDocument = (sale) => {
		let fiscalDocument;

		if(_.isObject(sale)) {
			let fiscalProviderDocument = _.find(sale.sale_documents, { document_type: 'fiscal_provider' });

			if(!_.isEmpty(fiscalProviderDocument) && _.get(fiscalProviderDocument, 'meta.fiscal_provider') === 'retailforce') {
				fiscalDocument = fiscalProviderDocument;
			}
		}

		return fiscalDocument;
	};

	const getAuditType = (auditData) => _.chain(auditData.type).replace('audit', '').lowerFirst().value();

	const getAuditInfo = (auditData) => {
		const auditType = getAuditType(auditData);
		let auditInfo = {};

		switch(auditType) {
			case 'applicationOffline':
				auditInfo.logEntryType = 'ApplicationEmergencyModeOn';
			break;
			case 'applicationOnline':
				auditInfo.logEntryType = 'ApplicationEmergencyModeOff';
			break;
			case 'applicationStartup':
				Object.assign(auditInfo, {
					logEntryType: 'ApplicationStart',
					message: auditData.appVersion
				});
			break;
			case 'cashMovementCreated':
				let cashMovement = auditData.cashMovement;

				if(cashMovement.type === 'outcome') {
					Object.assign(auditInfo, {
						amount: Math.abs(cashMovement.amount),
						identier: cashMovement.id,
						logEntryType: 'DocumentTypeWithDrawal'
					});
				}
			break;
			case 'dailyReportCreated':
				auditInfo.logEntryType = 'FiscalReportClosingDay';
			break;
			case 'documentPrintFailed':
				switch(auditData.error) {
					case 'CONNECTION_ERROR':
						Object.assign(auditInfo, {
							identier: auditData.printerId,
							logEntryType: 'PrinterUnavailable'
						});
					break;
					default:
					break;
				}
			break;
			case 'drawerOpen':
				auditInfo.logEntryType = 'DrawerOpen';
			break;
			case 'historyReprint':
				Object.assign(auditInfo, getAuditSaleInfo(auditData.reprintedSale), {
					logEntryType: 'DocumentReprintOther'
				});
			break;
			case 'newLoggedUser':
				auditInfo.logEntryType = 'UserLogin';
			break;
			case 'trainingModeStart':
				auditInfo.logEntryType = 'DocumentTrainingModeOn';
			break;
			case 'orderItemRemoved':
				Object.assign(auditInfo, getAuditRowItemInfo(auditData.removedOrderItem), {
					logEntryType: 'DocumentCancelLine'
				});
			break;
			case 'orderItemUpdated':
				Object.assign(auditInfo, getAuditRowItemInfo(auditData.currentOrderItem), {
					logEntryType: 'DocumentUpdateLine'
				});
			break;
			case 'orderOpened':
				Object.assign(auditInfo, getAuditOrderInfo(auditData.openedOrder), {
					logEntryType: 'DocumentResume'
				});
			break;
			case 'orderOpened':
				Object.assign(auditInfo, getAuditOrderInfo(auditData.deletedOrder), {
					logEntryType: 'DocumentAbandonLongTermOrder'
				});
			break;
			case 'orderPrinted':
				if(auditData.reprint) {
					Object.assign(auditInfo, (auditData.printType === 'sale' ? getAuditSaleInfo(auditData.sale) : getAuditOrderInfo(auditData.order)), {
						logEntryType: 'DocumentReprintLongTermOrder'
					});
				}
			break;
			case 'orderSaved':
				Object.assign(auditInfo, getAuditOrderInfo(auditData.savedOrder), {
					logEntryType: 'DocumentUpdateLongTermOrder'
				});
			break;
			case 'saleItemRemoved':
				Object.assign(auditInfo, getAuditRowItemInfo(auditData.removedSaleItem), {
					logEntryType: 'DocumentCancelLine'
				});
			break;
			case 'saleItemUpdated':
				Object.assign(auditInfo, getAuditRowItemInfo(auditData.currentSaleItem), {
					logEntryType: 'DocumentUpdateLine'
				});
			break;
			case 'saleOpened':
				Object.assign(auditInfo, getAuditSaleInfo(auditData.openedSale), {
					logEntryType: 'DocumentResume'
				});
			break;
			case 'saleParked':
				Object.assign(auditInfo, getAuditSaleInfo(auditData.parkedSale), {
					logEntryType: 'DocumentSuspend'
				});
			break;
			case 'saleClosed':
				let sale = auditData.closedSale;

				if(sale) {
					let otherDocumentEmitted = _.find(sale.sale_documents, (document) => (['generic_document'].includes(document.document_type)));

					if(otherDocumentEmitted) {
						Object.assign(auditInfo, getAuditSaleInfo(sale), {
							logEntryType: 'DocumentTypeOther'
						});
					}
				}
			break;
			case 'userSettingChanged':
				Object.assign(auditInfo, {
					identier: auditData.user?.username,
					logEntryType: 'UserRightsChange'
				});
			break;
		}

		return auditInfo;
	};

	const fetchClientVersion = async () => {
		if(_.isNil(rfVersion) || _.isNil(rfClientVersion)) {
			let clientId = getRetailForceClientID();

			rfVersion = (await sendToRetailForce(['information', 'version'], 'GET')).message;
			rfClientVersion = (await sendToRetailForce(['information', 'version', clientId], 'GET')).message;
		}
	};

	const getAuditRowItemInfo = (rowItem) =>  ({
		amount: util.round(rowItem.final_price * rowItem.quantity),
		identier: rowItem.uuid,
	});

	const getAuditOrderInfo = (order) => {
		let result = {};

		if(order) {
			Object.assign(result, {
				amount: order.amount,
				identier: order.uuid
			});
		}

		return result;
	};

	const getAuditSaleInfo = (sale) => {
		let result = {};

		if(sale) {
			Object.assign(result, {
				amount: sale.final_amount,
				identier: sale.uuid
			});

			let fiscalDocument = getSaleRetailForceDocument(sale);

			if(fiscalDocument) {
				try {
					let docContent = JSON.parse(fiscalDocument.document_content);

					result.documentGuid = docContent.documentGuid;
				} catch(err) {
					//Todo: log as warning
				}
			}
		}

		return result;
	};

	const getOperatorUser = (user) => {
		const opData = OperatorManager.getSellerData(user);

		return {
			"caption": opData.full_name,
			"firstName": opData.first_name,
			"id": _.toString(opData.id),
			"lastName": opData.last_name
		};
	};

	let sendToRetailForce = async(endpoint, method, data, params, responseType) => {
		let retailForceHost = checkManager.getPreference('retailforce.host');

		const isSendingDocument = (['POST', 'PUT'].includes(method) && endpoint[0] == 'transactions' && !['auditLog', 'validateDocument'].includes(endpoint[1]));

		if (endpoint[0] !== 'api') {
			endpoint.unshift('api', 'v1');
		}

		const endpointPath = endpoint.join('/');

		try {
			let response;

			if (retailForceHost) {
				if(!retailForceHost.endsWith('/')) {
					retailForceHost += '/';
				}

				let requestOptions = { data: data, params: params, method: method };

				if(responseType === 'blob') {
					requestOptions.responseType = responseType;
				}

				response = await fiscalUtils.sendRequest(`${retailForceHost}${endpointPath}`, requestOptions);

				return response.data;
			} else {
				switch (method) {
					case 'POST':
						response = await restManager.post(`retailforce/${endpointPath}`, data, params);
					break;
					case 'PUT':
						response = await restManager.put('retailforce', endpointPath, data, params);
					break;
					case 'DELETE':
						response = await restManager.deleteOne('retailforce', endpointPath, params);
					break;
					case 'GET':
					default:
						response = await ((responseType === 'blob') ? restManager.downloadOne : restManager.getOne)('retailforce', endpointPath, params);
					break;
				}

				return response;
			}
		} catch(err) {
			if(err.status == 422 && isSendingDocument) {
				//Call validateDocument in order to get the full error message
				const errors = await sendToRetailForce(['transactions', 'validateDocument'], 'POST', data);

				errorsLogger.sendReport({
					type: 'RetailForceValidationError',
					content: {
						errors: errors,
						payload: data
					}
				});

				throw errors;
			}

			throw err;
		}
	};

	const storeDocument = (saleDocument) => sendToRetailForce(['transactions', 'storeDocument'], 'POST', saleDocument);
	const cancelDocument = (saleDocument) => sendToRetailForce(['transactions', 'cancelDocument'], 'POST', saleDocument);
	const countryProperties = (clientId) => sendToRetailForce(['information', 'client', clientId, 'countryProperties'], 'GET');
	const revertDocument = (saleDocument) => sendToRetailForce(['transactions', 'revertDocument'], 'POST', saleDocument);
	const reprintDocument = (documentGuid, clientId, operatorData) => sendToRetailForce(['transactions', 'reprintDocument', clientId, documentGuid], 'PUT', operatorData);

	const getTaxFreeVat = () => {
		let clientID = getRetailForceClientID();

		return sendToRetailForce(['information', 'client', clientID, 'getTaxFreeVat'], 'GET');
	};

	/**
	 * @description creates a RetailForce document template
	 * @param {object} sale the Tilby sale for the document (optional)
	 * @returns {object} a RetailForce document template
	 */
	const getDocumentTemplate = async (sale, documentType, options) => {
		let clientID = getRetailForceClientID();

		if(!_.isObject(options)) {
			options = {};
		}

		if (_.isNil(sale)) {
			sale = {
				uuid: generateUuid(),
				open_at: moment().toISOString()
			};
		}

		const operatorUser = getOperatorUser();

		const bookDate = new Date();
		bookDate.setMilliseconds(0);

		let documentTemplate = {
			"allowedVatDeviation": 0.02,
			"applicationVersion": getTilbyVersion(),
			"bookDate": bookDate.toISOString(),
			"cancellationDocument": false,
			"createDate": sale.open_at,
			"customerCount": sale.covers,
			"deliveryPrintCount": _.chain(sale.sale_items).map('exit').reject(_.isNil).uniq().size().value(),
			"documentGuid": sale.uuid,
			"documentId": sale.uuid,
			"documentNumber": moment(sale.open_at).format('YYYYMMDD') + _.chain(options.progressive).toInteger().padStart(10, '0').value(),
			"documentNumberSeries": _.chain([_.toString(options.progressive_prefix), _.toString(options.printer_prefix)]).compact().join('').value() || null,
			"documentReference": null,
			"isTraining": checkManager.getPreference('retailforce.training_mode') ? true : false,
			"printCount": 1,
			"proformaPrintCount": _.toInteger(sale.printed_prebills),
			"uniqueClientId": clientID,
			"salesPerson": {
				"id": _.toString(sale.seller_id) || operatorUser.Id,
				"caption": sale.seller_name || operatorUser.Caption
			},
			"user": operatorUser,
		};

		if (!_.isObject(documentType)) {
			documentType = RetailForceProvider.documentTypes.RECEIPT;
		}

		if(documentType === RetailForceProvider.documentTypes.INVOICE) {
			Object.assign(documentTemplate, {
				"paymentTerms": {
					"dueDateDays": 1,
					"discount": 0,
					"discountDueDays": 0,
					"latePaymentPenaltyRate": 0
				},
				"servicePeriodStart": documentTemplate.createDate,
				"servicePeriodEnd": documentTemplate.createDate
			});
		}

		Object.assign(documentTemplate, documentType);

		return documentTemplate;
	};

	/**
	 * @description returns the RetailForce partner node from a Tilby sale
	 * @param {object} sale the source Tilby sale
	 * @returns {object} the RetailForce Partner node
	 */
	const getDocumentPartner = (sale) => {
		let saleCustomer = sale.sale_customer;

		if (!_.isEmpty(saleCustomer) && !_.isEmpty(saleCustomer.billing_street) && !_.isEmpty(saleCustomer.billing_number) && !_.isEmpty(saleCustomer.billing_zip) && !_.isEmpty(saleCustomer.billing_city) && !_.isEmpty(saleCustomer.billing_country)) {
			return {
				"id": saleCustomer.uuid,
				"caption": util.getCustomerCaption(saleCustomer),
				"partnerType": 0,
				"partnerClassification": "Customer",
				"vatNumber": saleCustomer.vat_code,
				"taxNumber": saleCustomer.tax_code,
				"street": saleCustomer.billing_street,
				"streetNumber": saleCustomer.billing_number,
				"postalCode": saleCustomer.billing_zip,
				"city": saleCustomer.billing_city,
				"countryCode": _.get(countryCodes, [saleCustomer.billing_country, 'alpha3'])
			};
		} else {
			return null;
		}
	};

	/**
	 * @description returns the RetailForce discounts node from a Tilby sale element (sale or sale_item)
	 * @param {object} element the element to extract the discounts from
	 * @param {object} partialPrice the starting partial price for the element
	 * @returns the RetailForce discounts node
	 */
	const getDiscounts = (element, partialPrice) => {
		let discounts = [];
		let discountIdx = 0;

		_(element.price_changes).sortBy('index').forEach((priceChange) => {
			const pcAmount = fiscalUtils.getPriceChangeAmount(priceChange, partialPrice, 4);

			if (!_.isNil(pcAmount)) {
				partialPrice = fiscalUtils.roundDecimals(partialPrice + util.round(pcAmount));

				discounts.push({
					"discountValue": -pcAmount,
					"caption": priceChange.description,
					"discountOrder": discountIdx++,
					"identifier": priceChange.description,
					"type": ['discount_perc', 'surcharge_perc', 'gift'].includes(priceChange.type) ? 1 : 0,
					"typeValue": ['surcharge_perc', 'surcharge_fix'].includes(priceChange.type) ? -priceChange.value : priceChange.value,
					"promotionKeys": priceChange.promotion_id ? [priceChange.promotion_id] : []
				});
			}
		});

		return discounts;
	};

	/**
	 * @description returns the RetailForce positions node from a Tilby sale
	 * @param {object} sale the Tilby sale
	 * @param {object} vatsMap a sale to RetailForce vat map (obtainable from getSaleVatsMap)
	 * @returns the RetailForce positions node
	 */
	const getDocumentPositions = (sale, vatsMap, departmentsMap) => {
		let positionNumber = 0;

		let itemPositions = fiscalUtils.extractSaleItems(sale).map((saleItem) => {
			let saleItemNetPrice = _.toFinite(saleItem.price / (1 + saleItem.vat_perc / 100));

			return {
				"accountingIdentifier": null,
				"additionalFields": {},
				"baseGrossValue": util.round(saleItem.price * saleItem.quantity),
				"baseNetValue": util.round(saleItemNetPrice * saleItem.quantity),
				"baseTaxValue": util.round((saleItem.price - saleItemNetPrice) * saleItem.quantity),
				"businessTransactionType": 0,
				"cancellationPosition": false,
				"createDate": saleItem.added_at,
				"costPrice": saleItem.cost ?? null,
				"discounts": getDiscounts(saleItem, fiscalUtils.roundDecimals(saleItem.price * saleItem.quantity)),
				"grossValue": util.round(saleItem.final_price * saleItem.quantity),
				"gtin": saleItem.barcode,
				"inHouse": !_.includes(['take_away', 'delivery'], sale.order_type),
				"itemCaption": saleItem.name,
				"itemDateOfEntry": saleItem.added_at,
				"itemGroupCaption": null,
				"itemGroupId": null,
				"itemId": saleItem.uuid,
				"itemShortCaption": null,
				"itemTaxType": departmentsMap[saleItem.department_id]?.sales_type === 'goods' ? 0 : 1,
				"netValue": util.round(saleItem.final_net_price * saleItem.quantity),
				"positionNumber": positionNumber++,
				"positionReference": null,
				"quantity": saleItem.quantity,
				"quantityUnit": null,
				"subItems": null,
				"taxValue": util.round((saleItem.final_price - saleItem.final_net_price) * saleItem.quantity),
				"type": 0, //item
				"useSubItemVatCalculation": true,
				"vatIdentification": vatsMap[saleItem.vat_perc],
				"vatPercent": saleItem.vat_perc
			};
		});

		let totalPositions = [];

		//Add subtotal if there are some sale price_changes to apply
		if (!_.isEmpty(sale.price_changes)) {
			totalPositions.push({
				"type": 9,
				"baseValue": sale.amount,
				"value": sale.final_amount,
				"discounts": getDiscounts(sale, fiscalUtils.roundDecimals(sale.amount)),
				"caption": null,
				"positionNumber": positionNumber++,
				"positionReference": null,
				"cancellationPosition": false,
				"additionalFields": {}
			});
		}

		totalPositions.push({
			"type": 10,
			"baseValue": sale.final_amount,
			"value": sale.final_amount,
			"caption": null,
			"positionNumber": positionNumber++,
			"positionReference": null,
			"rounding": util.round(sale.final_amount - _.sumBy(itemPositions, 'grossValue')),
			"cancellationPosition": false,
			"additionalFields": {}
		});

		return _.concat(itemPositions, totalPositions);
	};

	/**
	 * @description returns the RetailForce payment type from a Tilby payment
	 * @param {object} payment the Tilby payment
	 * @returns {string} the RetailForce payment type
	 */

	//[ cash, ecCard, creditCard, singlePurposeVoucher, multiPurposeVoucher, paymentProvider, deposit, noCash, none ]
	const getPaymentType = (payment) => {
		switch (payment.method_type_id) {
			case 1: case 19: case 21: case 32: case 38: case 39: case 40: //Cash
				return 'cash';
			case 3: //Cheque
				return 'check';
			case 4: case 5: //Electronic payment
			case 11: case 13: case 14: case 15: case 27: case 30: case 31: case 35: case 37: //POS
				return 'creditCard';
			case 2: case 22: case 23: case 24: case 25: case 26: case 28: case 29: case 36: case 41:
				return 'noCash';
			case 8: //Bank transfer
				return 'bankAccount';
			case 16: //Prepaid credit
				return 'customerCard';
			case 18: //Satispay
				return 'mobilePhoneApps';
			case 6: case 10: case 20: case 33: case 34: //Tickets / multi-purpose vouchers
				return 'multiPurposeVoucher';
			default:
				return 'cash';
		}
	};

	/**
	 * @description returns the RetailForce payments node from a Tilby sale
	 * @param {object} sale the Tilby sale
	 * @returns {object} the RetailForce payments node
	 */
	const getDocumentPayments = (sale, validVats) => {
		const operatorUser = getOperatorUser();
		const currentCountry = checkManager.getShopCountry();
		let paymentVatId, paymentVatPerc;

		switch(currentCountry) {
			case 'AT': case 'FR':
				[paymentVatId, paymentVatPerc] = [validVats.find((vatData) => _.includes(vatData.vatPercents, 10)), 10];
			break;
			case 'DE': case 'ES':
				[paymentVatId, paymentVatPerc] = [validVats.find((vatData) => _.includes(vatData.vatPercents, 7)), 7];
			break;
			default:
			break;
		}

		const payments = fiscalUtils.extractPayments(sale).map((payment) => {
			const result = {
				"amount": payment.amount,
				"caption": payment.method_name,
				"createDate": payment.date,
				"currencyIsoCode": sale.currency,
				"isCash": fiscalUtils.isCashPayment(payment.method_type_id),
				"paymentTerminalReferenceId": null,
				"paymentType": getPaymentType(payment),
				"salesPerson": operatorUser,
				"uniqueReadablePaymentIdentifier": generateUuid(),
				"user": operatorUser
			};

			if(result.paymentType === 'multiPurposeVoucher') {
				Object.assign(result, {
					additionalFields: {
						voucherId: payment.code || '0000'
					},
					taxValue: paymentVatPerc ? util.round(_.toFinite(payment.amount / (1 + paymentVatPerc / 100) * (paymentVatPerc / 100))) : undefined,
					vatIdentification: paymentVatId?.vatIdentification,
					vatPercent: paymentVatPerc
				});
			}

			return result;
		});

		//Add sale change as a negative cash payment
		const saleChange = salePayment.getSaleChange(sale);

		if(saleChange) {
			payments.push({
				"amount": -saleChange,
				"caption": 'Change',
				"createDate": new Date().toISOString(),
				"currencyIsoCode": sale.currency,
				"isCash": true,
				"paymentTerminalReferenceId": null,
				"paymentType": 'cash',
				"salesPerson": operatorUser,
				"uniqueReadablePaymentIdentifier": generateUuid(),
				"user": operatorUser
			});
		}

		return payments;
	};

	/**
	 * @description returns a sale vat map for the current sale
	 * @param {object} sale the Tilby sale
	 * @returns {Promise} a promise (can be resolved with the vats map or rejected with an error code)
	 */
	const getSaleVatsMap = async (sale) => {
		let clientID = getRetailForceClientID();

		let vatInfo = await sendToRetailForce(['information', 'client', clientID, 'supportedVatDefinitions'], 'GET');
		//Check Vat compliancy
		let now = moment();

		let validVats = _(vatInfo).filter((vat) => (now.isBetween(moment(vat.validFrom), moment(vat.validTo)))).value();
		let documentVats = _(sale.sale_items).map('vat_perc').sortBy().sortedUniq().value();

		let vatsToUse = _(documentVats)
			.map((vatValue) => _.find(validVats, (vatData) => _.includes(vatData.vatPercents, vatValue)))
			.map('vatIdentification')
			.value();

		if (_.some(vatsToUse, _.isNil)) {
			throw 'RETAILFORCE_INVALID_VATS';
		}

		return [_.zipObject(documentVats, vatsToUse), validVats];
	};


	/**
	 * @description converts a Tilby sale to a RetailForce document
	 * @param {object} sale the Tilby sale
	 * @returns {Promise} a promise (can be resolved with the RetailForce document or rejected with an error code)
	 */
	const saleToDocument = async (sale, documentType, options) => {
		const [vatsMap, validVats] = await getSaleVatsMap(sale);
		const departments = await entityManager.departments.fetchCollectionOffline();
		const departmentsMap = _.keyBy(departments, 'id');

		let document = await getDocumentTemplate(sale, documentType, options);
		let documentPositions = getDocumentPositions(sale, vatsMap, departmentsMap);

		Object.assign(document, {
			"partner": getDocumentPartner(sale),
			"positions": documentPositions,
			"positionCount": documentPositions.length
		});

		if(documentType !== RetailForceProvider.documentTypes.INVOICE) {
			Object.assign(document, {
				"payments": getDocumentPayments(sale, validVats)
			});
		}

		return document;
	};

	/**
	 * @description Updates the sale_document with the information from the fiscal client
	 * @param {object} saleDocument the original sale document
	 * @param {object} documentSent the document sent to the fiscal client
	 * @param {object} saleDocument the fiscal client response
	 * @returns {array} the updated sale_document
	 */
	 const getUpdatedSaleDocument = (saleDocument, documentSent, fiscalClientResponse, options) => {
		if(!_.isObject(options)) {
			options = {};
		}

		let returnDocument = Object.assign(saleDocument, {
			date: moment(fiscalClientResponse.requestCompletionTime).toDate(),
			document_content: JSON.stringify(Object.assign({}, documentSent, fiscalClientResponse)),
		});

		if(options.progressive) {
			Object.assign(returnDocument,{
				sequential_number: _.toInteger(options.progressive),
				sequential_number_prefix: documentSent.documentNumberSeries
			});
		}

		return [returnDocument];
	};

	const getTicketBaiDocument = async (saleDocuments, fiscalDocument) => {
		//For spain fiscalizations try to retrieve TicketBai documents
		if(checkManager.getShopCountry() === 'ES') {
			let tBaiDocument;

			//Try to retrieve TicketBai document (10 tries)
			for(let i = 0; i < 10 && !tBaiDocument; i++) {
				try {
					const response = await sendToRetailForce(['management', 'spain', getRetailForceClientID(), 'ticketBai', fiscalDocument.documentNumber], 'GET', null, null, 'blob');

					if(response.data) {
						tBaiDocument = response.data;
					}
				} catch(err) {
					//Wait 3 seconds before trying again
					await new Promise(resolve => setTimeout(resolve, 3000));
				}
			}

			//Discard document if bigger than 100 kB
			if(tBaiDocument && tBaiDocument.size <= 100000) {
				const tBaiDataUrl = await blobToDataURL(tBaiDocument, 'application/zip');

				//Add the document in base64 format
				saleDocuments.push({
					date: saleDocuments[0].date,
					document_content: tBaiDataUrl,
					document_type: 'attachment',
					meta: { mime_type: 'application/zip', file_name: `TicketBai ${fiscalDocument.documentNumber}.zip` },
					sequential_number: saleDocuments[0].sequential_number,
					sequential_number_prefix: saleDocuments[0].sequential_number_prefix
				});
			}
		}
	};

	const retailForceTailStrings = {
		'DE': {
			'TSE_SERIAL': "TSE Seriennummer:",
			'TSE_TRANSACTION_START': "TSE Start:",
			'TSE_TRANSACTION_END': "TSE Ende:",
			'TSE_TRANSACTION_NUM':"TSE Transaktionsnr.:",
			'TSE_CERT': "TSE Zertifikat:",
			'TSE_IDENTIFICATION': "TSE Identifikation:",
			'TSE_SIGNATURE_COUNTER': "TSE Signaturzähler:",
			'TSE_HASH_ALGORITHM': "TSE Algorithmus:",
			'TSE_TIME_FORMAT': "TSE Zeitformat:",
			'TSE_SIGNATURE': "TSE Signatur:",
			'TSE_PUBLIC_KEY': "TSE PublicKey:"
		},
		'EN': {
			'TSE_SERIAL': "TSE Serial:",
			'TSE_TRANSACTION_START': "TSE Start:",
			'TSE_TRANSACTION_END': "TSE End:",
			'TSE_TRANSACTION_NUM':"TSE Transaction nr.:",
			'TSE_CERT': "TSE Certificate:",
			'TSE_IDENTIFICATION': "TSE Identification:",
			'TSE_SIGNATURE_COUNTER': "TSE Signature Counter:",
			'TSE_HASH_ALGORITHM': "TSE Algotithm:",
			'TSE_TIME_FORMAT': "TSE Time Format:",
			'TSE_SIGNATURE': "TSE Signature:",
			'TSE_PUBLIC_KEY': "TSE Public Key:"
		},
		'FR': {
			'SOFTWARE_VERSION': "NF525 SW version",
			'FISCAL_MARK': "Code de signature",
			'ORIGINAL_RECEIPT': "Orig. Ticket",
			'ORIGINAL_INVOICE': "Orig. Facture",
			'RECEIPT_NUMBER': "Ticket N.",
			'INVOICE_NUMBER': "Facture N.",
			'PRELIMINARY_NUMBER': "Note N.",
			'POSITION_NUMBERS': "Nombre de lignes:",
			'IDENTIFICATION': "Société:",
			'STORE_NUMBER': "Filiale:",
			'TERMINAL_NUMBER': "Pos No.:",
			'REPRINT_COUNT': "Imprimés Numéro:"
		}
	};

	retailForceTailStrings['AU'] = retailForceTailStrings['DE'];

	/**
	 * Public methods
	 */
	const RetailForceProvider = {
		documentTypes: {
			RECEIPT: { "documentType": 0, "documentTypeCaption": "Receipt" },
			INVOICE: { "documentType": 1, "documentTypeCaption": "Invoice" },
			DELIVERY_NOTE: { "documentType": 2, "documentTypeCaption": "DeliveryNote" },
			PAYOUT: { "documentType": 10, "documentTypeCaption": "PayOut" },
			PAYIN: { "documentType": 11, "documentTypeCaption": "PayIn" },
			PROFORMA_INVOICE: { "documentType": 12, "documentTypeCaption": "ProformaInvoice" },
			CUSTOMER_ORDER: { "documentType": 13, "documentTypeCaption": "CustomerOrder" },
			PRELIMINARY_RECEIPT: { "documentType": 14, "documentTypeCaption": "PreliminaryReceipt" },
			LONG_TERM_ORDER: { "documentType": 80, "documentTypeCaption": "LongTermOrder" },
			OPENING_BALANCE: { "documentType": 90, "documentTypeCaption": "OpeningBalance" },
			END_OF_DAY: { "documentType": 99, "documentTypeCaption": "EndOfDay" },
			INVENTORY: { "documentType": 100, "documentTypeCaption": "Inventory" },
			PURCHASE: { "documentType": 101, "documentTypeCaption": "Purchase" },
			NULL_RECEIPT: { "documentType": 1000, "documentTypeCaption": "NullReceipt" },
			MISCELLANEOUS_NON_FISCAL: { "documentType": 1100, "documentTypeCaption": "MiscellaneousNonFiscal" }
		},

		getVersion: async () => {
			await fetchClientVersion();

			return {
				fpVersion: rfVersion,
				fpClientVersion: rfClientVersion
			};
		},
		getSaleReference: (sale) => {
			const result = {
				reference_sale_id: sale.id
			};

			let originalDocumentType = _.chain(sale.sale_documents).find((saleDocument) => saleDocument.document_type !== 'fiscal_provider').get('document_type').value();
			let fpDocument = _.find(sale.sale_documents, (saleDocument) => saleDocument.document_type === 'fiscal_provider');

			if(fpDocument) {
				let rfDocument;

				if(fpDocument) {
					try {
						rfDocument = JSON.parse(fpDocument.document_content);
					} catch(e) {}
				}

				if(rfDocument) {
					const currentCountry = checkManager.getShopCountry();
					const countryStrings = retailForceTailStrings[currentCountry];
					let documentTypeId = rfDocument.documentType;
					let documentType;

					Object.assign(result, {
						reference_sequential_number: _.toInteger(fpDocument.sequential_number),
						reference_date: fpDocument.date
					});

					switch(currentCountry) {
						case 'FR':
							switch(documentTypeId) {
								case 0:
									documentType = originalDocumentType === 'generic_invoice' ? 'INVOICE' : 'RECEIPT';
								break;
								case 1:
									documentType = 'INVOICE';
								break;
								default:
									documentType = 'RECEIPT';
								break;
							}

							let documentProgressive =  moment(sale.open_at).format('YYYYMMDD') + _.chain(fpDocument.sequential_number).padStart(10, '0').value();

							result.reference_text = _.pad(`${countryStrings[`ORIGINAL_${documentType}`]} ${documentProgressive}`);
						break;
						default:
						break;
					}
				}
			}

			return result;
		},
		getReceiptTail: function(saleDocuments, options) {
			if(!_.isObject(options)) {
				options = {};
			}

			const columns = options.columns || 46;
			let currentCountry = checkManager.getShopCountry();
			let countryStrings = retailForceTailStrings[currentCountry];

			if(_.isNil(countryStrings)) {
				countryStrings = retailForceTailStrings['EN'];
			}

			let printInfoLine = (key, value) => (countryStrings[key] + _.padStart(value, columns - _.size(countryStrings[key]), ' '));

			let tailRows = [];
			let tailQr;
			let tailQrSize;

			let fiscalProviderDocument =  _.find(saleDocuments, { document_type: 'fiscal_provider' });
			let retailForceDocument;

			if(fiscalProviderDocument) {
				try {
					retailForceDocument = JSON.parse(fiscalProviderDocument.document_content);
				} catch(e) {}
			}

			if(retailForceDocument) {
				let documentTypeId = retailForceDocument.documentType;
				let documentType;

				switch(documentTypeId) {
					case 0:
						documentType = options.document_type === 'generic_invoice' ? 'INVOICE' : 'RECEIPT';
					break;
					case 1:
						documentType = 'INVOICE';
					break;
					case 14:
						documentType = 'PRELIMINARY';
					break;
					default:
						documentType = 'RECEIPT';
					break;
				}

				switch(currentCountry) {
					case 'AT':
						if(retailForceDocument.printMessage) {
							tailRows.push(stringToLines(retailForceDocument.printMessage, columns));
							tailRows.push(" ");
						}

						if(retailForceDocument.fiscalisationDocumentNumber) {
							tailRows.push(_.pad("Fiskaldokument Nr. " + retailForceDocument.fiscalisationDocumentNumber, columns, ' '));
						}
					break;
					case 'DE':
						//Start header
						tailRows.push(_.pad('******** Fiscal Information ********', columns, ' '));

						//TSE Serial
						if(retailForceDocument.AdditionalFields?.TseSerial) {
							tailRows.push(countryStrings['TSE_SERIAL']);
							tailRows.push.apply(tailRows, stringToLines(retailForceDocument.AdditionalFields.TseSerial, columns));
						}

						//TSE transaction start and end
						if(retailForceDocument.AdditionalFields?.TransactionStartTime) {
							tailRows.push(printInfoLine('TSE_TRANSACTION_START', moment(retailForceDocument.AdditionalFields.TransactionStartTime * 1000).toISOString()));
						}

						if(retailForceDocument.AdditionalFields?.TransactionEndTime) {
							tailRows.push(printInfoLine('TSE_TRANSACTION_END', moment(retailForceDocument.AdditionalFields.TransactionEndTime * 1000).toISOString()));
						}

						//TSE transaction number
						tailRows.push(printInfoLine('TSE_TRANSACTION_NUM', ''));

						//TSE certificate
						tailRows.push(printInfoLine('TSE_CERT', ''));

						//TSE identification
						if(retailForceDocument.AdditionalFields?.TseIdentification) {
							tailRows.push(printInfoLine('TSE_IDENTIFICATION', retailForceDocument.AdditionalFields.TseIdentification || ''));
						}

						//TSE signature counter
						if(retailForceDocument.AdditionalFields?.TseSignatureCounter) {
							tailRows.push(printInfoLine('TSE_SIGNATURE_COUNTER', retailForceDocument.AdditionalFields.TseSignatureCounter));
						}

						//TSE algorithm
						if(retailForceDocument.AdditionalFields?.TseHashAlgorithm) {
							tailRows.push(printInfoLine('TSE_HASH_ALGORITHM', retailForceDocument.AdditionalFields.TseHashAlgorithm));
						}

						//TSE time format
						if(retailForceDocument.AdditionalFields?.TseTimeFormat) {
							tailRows.push(printInfoLine('TSE_TIME_FORMAT', retailForceDocument.AdditionalFields.TseTimeFormat));
						}

						//TSE signature
						if(retailForceDocument.signature) {
							tailRows.push(countryStrings['TSE_SIGNATURE']);
							tailRows.push.apply(tailRows, stringToLines(retailForceDocument.signature, columns));
						}

						//TSE public key
						if(retailForceDocument.AdditionalFields?.TsePublicKey) {
							tailRows.push(countryStrings['TSE_PUBLIC_KEY']);
							tailRows.push.apply(tailRows, stringToLines(retailForceDocument.AdditionalFields.TsePublicKey, columns));
						}

						//End header
						tailRows.push(_.pad('****************************************', columns, ' '));
					break;
					case 'FR':
						//Document date
						tailRows.push(_.pad(moment(fiscalProviderDocument.date).format('DD-MM-YYYY HH:mm'), columns));

						//Receipt Number
						let receiptNumber = _.split(retailForceDocument.AdditionalFields?.DocumentNumber, ':');

						if(receiptNumber.length >= 2) {
							//Receipt/Invoice copy
							tailRows.push(_.pad(`${countryStrings[`ORIGINAL_${documentType}`]} ${_.last(receiptNumber)}`, columns));
							tailRows.push(_.pad(`${countryStrings[`${documentType}_NUMBER`]} ${_.first(receiptNumber)}`, columns));
						} else {
							//Receipt/Invoice
							if(retailForceDocument.documentReference?.documentNumber) {
								tailRows.push(_.pad(`${countryStrings[`ORIGINAL_${documentType}`]} ${retailForceDocument.documentReference.documentNumber}`, columns));
							}

							tailRows.push(_.pad(`${countryStrings[`${documentType}_NUMBER`]} ${retailForceDocument.documentNumber}`, columns));
						}

						//Fiscal Mark
						if(retailForceDocument.AdditionalFields?.FiscalMark) {
							tailRows.push(_.pad(`${countryStrings['FISCAL_MARK']} - ${retailForceDocument.AdditionalFields.FiscalMark}`, columns));
						}

						//SW version
						let appMajor = _.chain(getTilbyVersion()).split('.').head().value();
						let rfMajor = _.chain(rfVersion).split('.').head().value();
						let rfClientMajor = _.chain(rfClientVersion).split('.').head().value();

						tailRows.push(_.pad(`${countryStrings['SOFTWARE_VERSION']} ${appMajor}-${rfMajor}-${rfClientMajor}`, columns));

						//Number of positions
						tailRows.push(printInfoLine('POSITION_NUMBERS', _.chain(retailForceDocument.positions).filter({ type: 0 }).size().value()));

						//Company identification
						tailRows.push(printInfoLine('IDENTIFICATION', checkManager.getPreference('retailforce.identification')));

						//Store number
						tailRows.push(printInfoLine('STORE_NUMBER', checkManager.getPreference('retailforce.store_number')));

						//Terminal number
						tailRows.push(printInfoLine('TERMINAL_NUMBER', getTerminalNumber()));

						//Reprint count
						if(retailForceDocument.AdditionalFields?.ReprintCount) {
							tailRows.push(printInfoLine('REPRINT_COUNT', retailForceDocument.AdditionalFields.ReprintCount));
						}
					break;
					case 'ES':
						//Process signature string
						let signature = _.split(retailForceDocument.signature, '-');
						let currentChunk = '';

						for(let i = 0; i < signature.length; i++) {
							let chunk = signature[i];

							if(currentChunk.length + (chunk.length + 1) > columns) {
								if(!_.isEmpty(currentChunk)) {
									tailRows.push(_.pad(currentChunk, columns, ' '));
								}

								currentChunk = '';
							}

							currentChunk += `${chunk}${i === (signature.length -1) ? '' : '-'}`;
						}

						if(!_.isEmpty(currentChunk)) {
							tailRows.push(_.pad(currentChunk, columns, ' '));
						}
					break;
				}

				if(retailForceDocument.AdditionalFields?.QrCode) {
					tailQrSize =  _.toInteger(checkManager.getPreference('retailforce.qr_code_size') || 6);

					if(tailQrSize) {
						tailQr = retailForceDocument.AdditionalFields.QrCode;
					}
				}
			}

			return {
				tailRows: tailRows.join('\n'),
				tailQrCode: tailQr,
				tailQrCodeSize: tailQrSize
			};
		},

		getProviderError: (error) => {
			return error?.data?.Message ?? error;
		},

		/**
		 * @desc creates a fiscalDocument from the Tilby sale and stores it in RetailForce
		 *
		 * @param sale The Tilby sale
		 * @param documentType The documentType (can be RECEIPT or INVOICE)
		 *
		 * @return promise
		 */
		storeFiscalDocument: async (sale, documentType, options) => {
			await fetchClientVersion();

			let documentTypeObj = RetailForceProvider.documentTypes[documentType];

			if(!documentTypeObj) {
				throw 'INVALID_RETAILFORCE_DOCUMENT_TYPE';
			}

			//Allow invoices only for bank transfer payments
			if(documentTypeObj === RetailForceProvider.documentTypes.INVOICE) {
				if(!_.every(sale.payments, { payment_method_type_id: 8 })) {
					documentTypeObj = RetailForceProvider.documentTypes.RECEIPT;
				}
			}

			const documentData = await RetailForceProvider.openFiscalDocument(sale, documentTypeObj);
			const fiscalDocument = JSON.parse(documentData.document_content);

			const saleDocument = await saleToDocument(sale, documentTypeObj, options);
			Object.assign(saleDocument, fiscalDocument);

			//If the request completion time is later than the create date, use the request completion time as the create date
			if(saleDocument.requestCompletionTime) {
				const bookDate = new Date(saleDocument.bookDate);
				const requestCompletionTime = new Date(saleDocument.requestCompletionTime);

				if(requestCompletionTime > bookDate) {
					//Use the request completion time, ceiling the seconds
					requestCompletionTime.setMilliseconds(1000);
					saleDocument.bookDate = requestCompletionTime.toISOString();
				}
			}

			let storedDocument = {};

			if(documentType === 'PRELIMINARY_RECEIPT') {
				const preliminaryUuid = generateUuid();
				saleDocument.documentGuid = preliminaryUuid;
				saleDocument.documentId = preliminaryUuid;
			}

			storedDocument = await storeDocument(saleDocument);

			const saleDocuments = getUpdatedSaleDocument(documentData, saleDocument, storedDocument, options);

			await getTicketBaiDocument(saleDocuments, saleDocument);

			return saleDocuments;
		},

		/**
		 * @desc Reprints a sale, increasing the document reprint counter
		 *
		 * @param sale The Tilby sale
		 *
		 * @return promise
		 */
		reprintDocument: async (sale) => {
			await fetchClientVersion();

			const clientID = getRetailForceClientID();
			const fiscalDocument = getSaleRetailForceDocument(sale);

			if(!fiscalDocument) {
				throw 'NOT_A_RETAILFORCE_DOCUMENT';
			}

			const cProperties = await countryProperties(clientID);

			//Skip if document reprint recording is not mandatory
			if (!cProperties.mustRecordDocumentReprint) {
				return;
			}

			const docContent = JSON.parse(fiscalDocument.document_content);
			const documentGuid = docContent.documentGuid;

			//Send request to RetailForce
			const reprintAnswer = await reprintDocument(documentGuid, clientID, getOperatorUser());

			return getUpdatedSaleDocument(fiscalDocument, docContent, reprintAnswer);
		},

		/**
		 * @desc creates a fiscalDocument from the Tilby sale and stores it in RetailForce
		 *
		 * @param sale The Tilby sale
		 * @param documentType The documentType (can be RECEIPT or INVOICE)
		 *
		 * @return promise
		 */
		voidFiscalDocument: async (voidSale, originalProviderDocument, options) => {
			await fetchClientVersion();
			let originalFiscalDocument = JSON.parse(originalProviderDocument.document_content);
			let documentTypeObj = _.chain(RetailForceProvider.documentTypes).values().find({ documentType: originalFiscalDocument.documentType }).value();

			let documentData = await RetailForceProvider.openFiscalDocument(voidSale, documentTypeObj);
			let voidDocument = JSON.parse(documentData.document_content);

			let revertedDocument = await revertDocument(originalFiscalDocument);

			let documentReference = {
				"documentBookDate": originalFiscalDocument.bookDate,
				"documentId": originalFiscalDocument.documentId,
				"documentGuid": originalFiscalDocument.documentGuid,
				"referenceType": 0, //Cancellation
				"documentNumber": originalFiscalDocument.documentNumber,
				"documentNumberSeries": originalFiscalDocument.documentNumberSeries,
				"fiscalDocumentNumber": originalFiscalDocument.fiscalDocumentNumber,
			};

			let documentTemplate = await getDocumentTemplate(voidSale, documentTypeObj, options);

			Object.assign(voidDocument, documentTemplate, _.pick(revertedDocument, ["partner", "positions", "positionCount", "payments"]), { "cancellationDocument": true, "documentReference" : documentReference });

			let voidResult = await storeDocument(voidDocument);

			const saleDocuments = getUpdatedSaleDocument(documentData, voidDocument, voidResult, options);

			await getTicketBaiDocument(saleDocuments, voidDocument);

			return saleDocuments;
		},

		/**
		 * @desc creates a fiscalDocument from the Tilby sale and stores it in RetailForce
		 *
		 * @param sale The Tilby sale
		 * @param documentType The documentType (can be RECEIPT or INVOICE)
		 *
		 * @return promise
		 */
		refundFiscalDocument: async (refundSale, originalProviderDocument, options) => {
			await fetchClientVersion();

			if(!refundSale.sale_parent_id) {
				throw 'MISSING_SALE_PARENT_ID';
			}

			let originalSale = await entityManager.sales.fetchOneOnline(refundSale.sale_parent_id);

			if(!originalSale) {
				throw 'MISSING_ORIGINAL_SALE';
			}

			let fiscalProviderDocument = getSaleRetailForceDocument(originalSale);

			if(!fiscalProviderDocument) {
				throw 'MISSING_ORIGINAL_DOCUMENT';
			}

			let originalFiscalDocument;

			try {
				originalFiscalDocument = JSON.parse(fiscalProviderDocument.document_content);
			} catch(e) {
				throw 'INVALID_ORIGINAL_DOCUMENT';
			}

			let documentTypeObj = _.chain(RetailForceProvider.documentTypes).values().find({ documentType: originalFiscalDocument.documentType }).value();

			let documentData = await RetailForceProvider.openFiscalDocument(refundSale, documentTypeObj);

			let fiscalDocument = JSON.parse(documentData.document_content);

			let saleDocument = await saleToDocument(refundSale, documentTypeObj, options);

			for(let position of saleDocument.positions.filter((position) => position.type == 0)) {
				let originalPosition = _.find(originalFiscalDocument.positions, { itemId: position.itemId });

				if(!originalPosition) {
					throw 'MISSING_ORIGINAL_POSITION';
				}

				Object.assign(position, {
					"cancellationPosition": true,
					"positionReference": {
						"documentBookDate": originalFiscalDocument.bookDate,
						"documentId": originalFiscalDocument.documentId,
						"documentGuid": originalFiscalDocument.documentGuid,
						"documentNumber": originalFiscalDocument.documentNumber,
						"documentNumberSeries": originalFiscalDocument.documentNumberSeries,
						"positionNumber": originalPosition.positionNumber,
						"referenceType": 0
					}
				});
			}

			Object.assign(saleDocument, fiscalDocument);

			let storedDocument = await storeDocument(saleDocument);

			const saleDocuments = getUpdatedSaleDocument(documentData, saleDocument, storedDocument, options);

			await getTicketBaiDocument(saleDocuments, saleDocument);

			return saleDocuments;
		},

		/**
		 * @desc cancels the fiscalDocument related to a Tilby sale in retailForce
		 *
		 * @param sale The Tilby sale
		 *
		 * @return promise
		 */
		cancelFiscalDocument: async (sale) => {
			let documentData = await RetailForceProvider.openFiscalDocument(sale);
			let fiscalDocument = JSON.parse(documentData.document_content);

			let saleDocument = await saleToDocument(sale);
			delete saleDocument.payments;

			Object.assign(saleDocument, {
				"documentNumber": `${moment(sale.open_at).format('YYYYMMDDHHmmSS')}0000`,
				"documentNumberSeries": "DEL"
			});

			Object.assign(saleDocument, fiscalDocument);

			let cancelResult = await cancelDocument(saleDocument);

			return getUpdatedSaleDocument(documentData, saleDocument, cancelResult);
		},

		/**
		 * @desc performs a daily closing
		 *
		 * @return promise (resolved with the closing document or rejected with an error)
		 */
		dailyClosing: async () => {
			const currentCountry = checkManager.getShopCountry();
			
			if(dailyClosingCountryBlacklist.includes(currentCountry)) {
				return;
			}

			const clientId = getRetailForceClientID();
			const opData = OperatorManager.getSellerData();

			let dailyClosingData = await sendToRetailForce(['closing', clientId, 'endofdayDocument'], 'GET');
			let documentNumber = _.chain(dailyClosingData.documentNumber).split('_').reverse().tail().value(); //['YYYYMMDD', 'N']
			let dailyClosingNumber = moment(documentNumber[0]).format('YYMMDD') + _.padStart(documentNumber[1], 2, '0');

			await sendToRetailForce(['closing', clientId, 'cashpointClose'], 'POST', null, { userId: opData.id, userCaption: opData.full_name });

			return ({
				date: dailyClosingData.createDate,
				meta: dailyClosingData,
				printer_serial: dailyClosingData.uniqueClientId,
				sequential_number: dailyClosingNumber
			});
		},

		/**
		 * @desc sends a cash movement (pay-in/pay-out)
		 * @param {number} amount to send (euro)
		 * @return promise (returns the cash movement tail if resolved, otherwise rejects with an error)
		 */
		sendCashMovement: async (amount, printerColumns) => {
			let transactionType = (amount > 0) ? 11 : 10;
			let documentType = (amount > 0) ? RetailForceProvider.documentTypes.PAYIN : RetailForceProvider.documentTypes.PAYOUT;

			let vatInfo = await getTaxFreeVat();
			let saleDocument = await RetailForceProvider.openFiscalDocument(null, documentType);
			let fiscalDocument = JSON.parse(saleDocument.document_content);
			let transactionAmount = Math.abs(amount);
			let documentTemplate = await getDocumentTemplate(null, documentType);
			let cashMovementPositions = [{
				"type": 3,
				"caption": "Cash movement",
				"businessTransactionType": transactionType,
				"vatIdentification": vatInfo.vatIdentification,
				"vatPercent": 0.0,
				"netValue": transactionAmount,
				"grossValue": transactionAmount,
				"taxValue": 0.0,
				"positionNumber": 0,
				"cancellationPosition": false
			}];

			Object.assign(fiscalDocument, documentTemplate, {
				"positions": cashMovementPositions,
				"positionCount": cashMovementPositions.length,
				"payments": [{
					"amount": transactionAmount,
					"isCash": true,
					"currencyIsoCode": $rootScope.currentCurrency.code,
					"caption": null,
					"foreignAmount": 0.0,
					"foreignAmountExchangeRate": 0.0,
					"paymentType": "cash",
					"uniqueReadablePaymentIdentifier": generateUuid()
				}]
			});

			await storeDocument(fiscalDocument);

			if(_.isEmpty(printerColumns)) {
				printerColumns = 46;
			}

			let printInfoLine = (name, value) => (name + _.padStart(value, printerColumns - _.size(name), ' '));

			let tailRows = [];

			//Start header
			tailRows.push(_.pad('******** Fiscal Information ********', printerColumns, ' '));

			//Transaction start and end
			tailRows.push(printInfoLine('Start Transaction:', moment(fiscalDocument.AdditionalFields.TransactionStartTime * 1000).toISOString()));
			tailRows.push(printInfoLine('End Transaction:', moment(fiscalDocument.AdditionalFields.TransactionEndTime * 1000).toISOString()));

			tailRows.push('');

			//Client ID
			let clientID = getRetailForceClientID();

			tailRows.push('Cashbox Identification:');
			tailRows.push(clientID);

			//Receipt Identification
			tailRows.push(printInfoLine('Receipt Identification: ', ''));

			//Fiscal Signature
			tailRows.push('Fiscal Signature:');
			tailRows.push.apply(tailRows, stringToLines(fiscalDocument.signature, printerColumns));

			//End header
			tailRows.push(_.pad('****************************************', printerColumns, ' '));

			return tailRows.join('\n');
		},

		configureProvider: async (configuration) => {
			if(!_.isObject(configuration)) {
				throw 'INVALID_CONFIGURATION';
			}

			let result;

			if(_.isEmpty(configuration?.host)) {
				//Cloud Setup
				result = await restManager.post('retailforce/setup', {
					cloud_api_key: configuration.cloudApiKey,
					cloud_api_secret: configuration.cloudApiSecret,
					identification: configuration.identification,
					store_number: configuration.storeNumber,
					terminal_number: configuration.terminalNumber
				});
			} else {
				//Local Setup (TODO)
			}

			return result;
		},

		sendAuditLog: async (auditData) => {
			let clientId = await getRetailForceClientID();
			let auditInfo = await getAuditInfo(auditData);

			if(_.isEmpty(auditInfo)) {
				return;
			}

			let payload = {
				recordId: auditData.id,
				uniqueClientId: clientId,
				message: "",
				user: getOperatorUser(auditData.user)
			};

			Object.assign(payload, auditInfo);

			return sendToRetailForce(['transactions', 'auditLog'], 'POST', payload);
		},

		/**
		 * @description openFiscalDocument
		 * @param  {object} printer the printer to query
		 * @return promise (document data on resolve, error_code on reject)
		 */
		openFiscalDocument: async (sale, documentType) => {
			const fiscalDocument = getSaleRetailForceDocument(sale);

			if(fiscalDocument) {
				return fiscalDocument;
			}

			let clientID = getRetailForceClientID();

			if (!_.isObject(documentType)) {
				documentType = RetailForceProvider.documentTypes.RECEIPT;
			}

			if(documentType === RetailForceProvider.documentTypes.PRELIMINARY_RECEIPT) {
				return {
					sequential_number: null,
					date: null,
					meta: { fiscal_provider: 'retailforce' },
					printer_serial: null,
					document_content: JSON.stringify({}),
					document_type: 'fiscal_provider'
				};
			}

			const document = await sendToRetailForce(['transactions', 'createDocument'], 'PUT', null, { 'uniqueClientId': clientID, 'documentType': documentType.documentType });

			Object.assign(document, {
				fiscalDocumentNumber: document.fiscalDocumentNumber || document.fiscalisationDocumentNumber,
				fiscalDocumentRevision: document.AdditionalFields?.FiscalDocumentRevision,
			});

			delete document.fiscalisationDocumentNumber;

			if(document.fiscalDocumentNumber < 0) {
				throw 'INVALID_DOCUMENT_NUMBER';
			}

			return {
				sequential_number: document.fiscalDocumentNumber,
				date: moment(document.requestCompletionTime).toDate(),
				meta: { fiscal_provider: 'retailforce' },
				printer_serial: null,
				document_content: JSON.stringify(document),
				document_type: 'fiscal_provider'
			};
		}
	};

	return RetailForceProvider;
}

import angular from 'angular';

angular.module('application').directive('barcode', function() {
    return {
        restrict: 'E',
        template: require('./barcode.html'),
        scope: {
            onSubmitBarcode: '&',
            hideButton: '='
        },
        controller: ["$scope", "$element", "$attrs", "$window", "environmentInfo", function($scope, $element, $attrs, $window, environmentInfo) {
            $scope.barcodeInput = "";
            $scope.isAppleMobile = environmentInfo.isAppleMobile();

            var eventKeyManagement = function(event) {
                if (event.target.nodeName === 'BODY') {
                    switch(event.keyCode) {
                        case 13:
                            $scope.barcodeSubmitted();
                        break;
                        case 27:
                            $scope.barcodeInput = "";
                        break;
                        default:
                            $scope.barcodeInput += String.fromCharCode(event.keyCode);
                        break;
                    }
                }
            };

            if (!$scope.isAppleMobile) {
                angular.element($window).on('keypress', eventKeyManagement);
            }

            $scope.getBarcodeIconColor = function() {
                return document.activeElement.id === 'barcodeInput' ? 'barcode-icon-enabled' : "";
            };

            $scope.barcodeSubmitted = function() {
                if($scope.barcodeInput.length) {
                    $scope.onSubmitBarcode({
                        barcodeInput: $scope.barcodeInput
                    });
                    $scope.barcodeInput = "";
                }
            };

            $scope.focusBarcodeInput = function() {
                const barcodeInput = $element.find('#barcodeInput');
                barcodeInput.val('');
                barcodeInput.trigger('focus');
            };

            $element.on('$destroy', function() {
                angular.element($window).off('keypress', eventKeyManagement);
            });
        }]
    };
});

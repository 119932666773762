import angular from 'angular';
import _ from 'lodash';

import { countryCodes } from 'src/app/core/constants/country-codes';

angular.module('settings').controller('MySubscriptionCtrl', ["$scope", "$translate", "$filter", "restManager", "authRequestDialog", "alertDialog", "user", function($scope, $translate, $filter, restManager, authRequestDialog, alertDialog, user) {
    $scope.showForm = false;
    $scope.showError = false;
    $scope.forms = {};

    $scope.userFirstName = user.first_name || "";
    $scope.thumbnail = user.thumbnail || null;

    _.assign($scope, {
        topbar_context:  {
            tabView: 'general',
            title: $translate.instant('SETTINGS.TOPBAR_SETTINGS.MY_SUBSCRIPTION'),
            landscapeMode: false,
            view: 'home'
        },
        vatValidateStatus: 'default'
    });

    $scope.paymentOptions = [
        {value: "sdd", name: $translate.instant('SETTINGS.MY_SUBSCRIPTION.SSD')},
        {value: "manual", name: $translate.instant('SETTINGS.MY_SUBSCRIPTION.MANUAL')},
        {value: "creditcard_token", name: $translate.instant('SETTINGS.MY_SUBSCRIPTION.CREDITCARD_TOKEN')}
    ];

    var loadData = function(billingData){
        $scope.billingData =  _.cloneDeep(billingData);
        $scope.remoteBillingData = _.cloneDeep(billingData);
        $scope.showForm = true;
        $scope.showError = false;

        var country = $scope.billingData.address_country;
        $scope.billingData._addressCountry =  _.get($scope, ['countryCodesDict', country], undefined);

        var remoteCountry = $scope.remoteBillingData.address_country;
        $scope.remoteBillingData._addressCountry =  _.get($scope, ['countryCodesDict', remoteCountry], undefined);
    };

    var reloadRemoteData = function(){
        restManager.getOne('billing_data').then(function(billingData){
            loadData(billingData);
        })
        .catch(function(error) {
            if(_.get(error, ['data', 'error', 'message', 'owner_data'])) {
                $scope.billingData = error.data.error.message.owner_data;
            }

            $scope.showForm = false;
            $scope.showError = true;
        });
    };

    reloadRemoteData();

    $scope.countryCodes = _.map(countryCodes, function(val, key) { return { code: key, name: val.name }; });
    $scope.countryCodesDict = _.keyBy($scope.countryCodes, 'code');
    $scope.filterCountry = function(searchText) {
        return $filter('filter')($scope.countryCodes, searchText);
    };

    $scope.goToTab = function(id) {
        $scope.topbar_context.tabView = id;
        $scope.tabIndexCopy = $scope.selectedTabIndex;
        if (id === 'billing_data') {
            $scope.topbar_context.saveButton = {
                label: $translate.instant('SETTINGS.MY_SUBSCRIPTION.SAVE'),
                action: $scope.savePreferences,
                disabled: function() {
                    return false;
                },
                visible: function() {
                    return true;
                }
            };
        } else {
            $scope.topbar_context.saveButton = null;
        }
    };

    $scope.savePreferences = function() {
        if(_.get($scope, ['forms', 'billingForm', '$valid'], false)) {
            var billingData = _.cloneDeep($scope.billingData);

            authRequestDialog.show({billingData: billingData}).then(function(result) {
                if(result) {
                    var data = _.cloneDeep($scope.billingData);
                    data.address_country =  _.get(data, '_addressCountry.code') || null;
                    delete data._addressCountry;

                    restManager.put('billing_data', null, data).then(function(){
                        alertDialog.show($translate.instant('SETTINGS.MY_SUBSCRIPTION.SAVED'));
                        reloadRemoteData();
                    }, function(error) {
                        alertDialog.show($translate.instant('SETTINGS.MY_SUBSCRIPTION.SAVE_ERROR'));
                    });
                }
            });
        } else {
            alertDialog.show($translate.instant('SETTINGS.MY_SUBSCRIPTION.INVALID_FORM'));
        }
    };
    $scope.goToTab('dashboard');

}]);

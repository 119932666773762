import './app';
import './modules';

import './ajs-downgrade';
import {AppModule} from "../src/app/app.module";
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {
    provideGlobalGridOptions
} from 'ag-grid-community';

import {
    AllEnterpriseModule,
    LicenseManager,
    ModuleRegistry
} from 'ag-grid-enterprise';

import { enableProdMode } from '@angular/core';

const envConfig = require('tilby.env.config');
const { licenseKeys } = require('app/tilby.properties.json');

async function bootstrap() {
    if(window.cordova) {
        //Wait for cordova bootstrap to finish
        await new Promise((resolve, reject) => {
            document.addEventListener('deviceready', resolve);
        });

        /* Disable Android back button */
        document.addEventListener("backbutton", function(e) { e.preventDefault(); }, false);
    }
    // Bootstrap angularJS now
    platformBrowserDynamic().bootstrapModule(AppModule);

    // Bootstrap ag-grid
    ModuleRegistry.registerModules([AllEnterpriseModule]);

    // Mark all grids as using legacy themes
    provideGlobalGridOptions({
        theme: "legacy",
    });

    LicenseManager.setLicenseKey(licenseKeys.agGrid);
}

if(!['unstable', 'local'].includes(envConfig.environment)) {
    enableProdMode();
}

bootstrap();

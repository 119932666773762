import _ from 'lodash';
import moment from 'moment-timezone';

import {
    Component,
    Renderer2,
    OnInit,
    inject
} from '@angular/core';

import {
    BookingShiftsFields,
    BookingShiftsFormGroup,
    PriceList
} from './settings-shifts-form';

import { ShiftsFormService } from './services';

import {
    errorsLogger,
    restManager
} from "app/ajs-upgraded-providers";

import {
    ConfigurationManagerService,
    EntityManagerService,
    ModuleEvents,
    ToolbarEventsService
} from 'src/app/core';

import {
    AlertDialogService,
    ConfirmDialogService,
    OpenDialogsService
} from 'src/app/dialogs';

import { TranslateService } from "@ngx-translate/core";
import { BookingShifts, RoomRestrictions, Rooms } from 'tilby-models';
import { CustomFormGroup } from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';


@Component({
  selector: 'app-settings-shifts',
  templateUrl: './settings-shifts.component.html',
  styleUrls: ['./settings-shifts.component.scss'],
  providers: [ShiftsFormService, OnDestroyService]
})
export class SettingsShiftsComponent implements OnInit {
    private readonly alertDialogService = inject(AlertDialogService);
    private readonly configurationManagerService = inject(ConfigurationManagerService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly entityManagerService = inject(EntityManagerService);
    private readonly errorsLoggerService = inject(errorsLogger);
    private readonly onDestroyService = inject(OnDestroyService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly renderer = inject(Renderer2);
    private readonly restManagerService = inject(restManager);
    private readonly shiftFormService = inject(ShiftsFormService);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly translate = inject(TranslateService);

    public filterList = '';
    public filterToggle = false;
    public firstEntry = false;
    public isNewEntryInProgress = false;
    public isProcessing = false;
    public priceLists: PriceList[] = [];
    public rooms: Rooms[] = [];
    public selected = false;
    public selectedShift?: BookingShiftsFields;
    public shiftForm?: CustomFormGroup<BookingShiftsFormGroup>;
    public shiftsList: Array<BookingShiftsFields> = [];
    public weekDays: Array<{id: string; name: string;}> = [];

    async ngOnInit() {
        this.createToolbarButtons();
        this.setModuleTitle();
        this.toolbarEventsService.events.pipe(this.onDestroyService.takeUntilDestroy).subscribe(e => this.callbackToToolbarClick(e));
        try {
            this.rooms = await this.entityManagerService.rooms.fetchCollectionOffline();
        } catch (err) {
            this.errorsLoggerService.log(err);
            this.rooms = [];
        }

        try {
            this.shiftsList = await this.restManagerService.getList("/booking_shifts", { pagination: false });
        } catch (err) {
            this.errorsLoggerService.err(err);
            this.shiftsList = [];
        }

        this.priceLists = [
            {
                index: -1,
                fieldName: this.translate.instant('SETTINGS.SHIFTS.FORM.DEFAULT_PRICELIST'),
                value: this.translate.instant('SETTINGS.SHIFTS.FORM.DEFAULT_PRICELIST'),
                hidden: false
            },
            ...Array.from({ length: 10 }, (_, i) => ({
                index: (i + 1),
                fieldName: `price${i+1}`,
                value: this.configurationManagerService.getPreference(`price_list_${i+1}_name`) || `${this.translate.instant('SETTINGS.SHIFTS.PRICE_LIST_START')} ${i+1}`,
                hidden: !!this.configurationManagerService.getPreference(`price_list_${i+1}_hide`)
            }))
        ];

        this.weekDays = _.zipWith(moment.localeData('en').weekdaysMin(), moment.weekdays(false), function(id, name) {
            return {
                id: id,
                name: _.upperFirst(name)
            };
        });
    }

    createToolbarButtons() {
        this.toolbarEventsService.buttons$.next({
            barButtons: [],
            panelButtons: []
        });
    }

    callbackToToolbarClick(event: Partial<ModuleEvents> & { id?: number }) {}

    setModuleTitle() {
        this.toolbarEventsService.moduleTitle.next('SHIFTS');
    }

    private async unsaveShift(shift: BookingShiftsFields) {
        const confirm = await this.confirmDialogService.openDialog({ data: {
                messageLabel: 'SETTINGS.SHIFTS.EDITS_NOT_SAVED',
                confirmLabel: 'SETTINGS.SHIFTS.CONFIRM_LABEL',
                cancelLabel: 'SETTINGS.SHIFTS.CANCEL_LABEL'
            }
        });

        if (confirm) {
            this.shiftForm?.markAsPristine();
            this.selectShift(shift, false);
        }
    }

    public selectShift(shift: BookingShiftsFields, isFormDirty = false) {
        if(isFormDirty) {
            this.unsaveShift(shift);
        } else {
            const _shift = structuredClone(shift);
            _shift.start_time = _shift.start_time ? moment(_shift.start_time, 'HH:mm:ss').format("HH:mm").toString() : moment('00:00', 'HH:mm').format("HH:mm").toString();
            _shift.end_time = _shift.end_time ? moment(_shift.end_time, 'HH:mm:ss').seconds(0).format("HH:mm").toString() : moment('23:59', 'HH:mm').format("HH:mm").toString();
            _shift.room_restrictions = _shift.room_restrictions?.map((room_restriction: any) => room_restriction.room_id);
            _shift.default_pricelist = _shift.default_pricelist ? _shift.default_pricelist : -1;
            this.selectedShift = _shift;
            if(!this.selected) {
                this.shiftForm = this.shiftFormService.createForm(_shift, this.priceLists, this.rooms, this.weekDays);
            } else {
                this.shiftFormService.updateForm(_shift);
            }
        }
        this.selected = true;
    }

    private async unsaveWhenAddNew() {
        const confirm = await this.confirmDialogService.openDialog({ data: {
                messageLabel: 'SETTINGS.SHIFTS.EDITS_NOT_SAVED',
                confirmLabel: 'SETTINGS.SHIFTS.CONFIRM_LABEL',
                cancelLabel: 'SETTINGS.SHIFTS.CANCEL_LABEL'
            }
        });

        if (confirm) {
            this.shiftForm?.markAsPristine();
            this.addNewShiftProceed();
        }
    }

    public addNewShifts(isFormDirty = false) {
        if(isFormDirty) {
            this.unsaveWhenAddNew();
        }
        if(!this.shiftForm?.dirty && !this.isNewEntryInProgress) {
            this.addNewShiftProceed();
        }
    }

    private addNewShiftProceed() {
        const newShift: BookingShiftsFields = {
            isNew: true,
            name: this.translate.instant('SETTINGS.SHIFTS.NEW_SHIFTS'),
            start_period: new Date().toISOString(),
            end_period: new Date().toISOString(),
            start_time: '06:00',
            end_time: '14:00',
            default_duration: 60,
            default_pricelist: -1,
            instore_seats_limit: undefined,
            online_seats_limit: undefined,
            room_restrictions: [],
            weekdays_period: []
        };
        this.filterList = '';
        this.filterToggle = false;
        const newLen = this.shiftsList.push(newShift);
        this.shiftsList = [...this.shiftsList];
        this.selectShift(this.shiftsList[newLen - 1]);
        this.shiftForm?.markAsDirty();
        this.isNewEntryInProgress = true;
    }

    public isSelected(shift: BookingShiftsFields) {
        return shift.id === this.selectedShift?.id;
    }

    private formattedPeriod(period: string | undefined) {
        return period ? moment(period).format('YYYY-MM-DD') : '';
    }

    private lockProcessing() {
        this.isProcessing = true;
    }

    private unlockProcessing() {
        this.isProcessing = false;
        this.isNewEntryInProgress = false;
    }

    public async save() {
        if (!this.selectedShift) {
            return;
        }

        this.lockProcessing();

        const _shiftForm = this.shiftForm?.value.generalForm;
        const cloneSelectedShift = structuredClone(this.selectedShift);

        const shiftToSave: BookingShifts = {
            ..._shiftForm,
            start_period: this.formattedPeriod(_shiftForm.start_period),
            end_period: this.formattedPeriod(_shiftForm.end_period),
            default_pricelist: _shiftForm.default_pricelist === -1 ? null : _shiftForm.default_pricelist,
            room_restrictions: _shiftForm.room_restrictions.map((r_r: RoomRestrictions) => ({ room_id: r_r })),
        };

        if(shiftToSave.weekdays_period?.length === 0) {
            shiftToSave.weekdays_period = ['Mo','Tu','We','Th','Fr','Sa','Su'];
        }

        if (!cloneSelectedShift.isNew && cloneSelectedShift.id) {
            shiftToSave.id = cloneSelectedShift.id;
        }

        const saveFunction = cloneSelectedShift.isNew ? 'postOneOnline' : 'putOneOnline';

        try {
            const result = await this.entityManagerService.bookingShifts[saveFunction](shiftToSave)
            let shiftToSelect: BookingShiftsFields | undefined = undefined;

            if (cloneSelectedShift.isNew) {
                const lastIndex = this.shiftsList.length - 1;
                shiftToSelect = this.shiftsList[lastIndex];
            } else {
                shiftToSelect = this.shiftsList.find((shift: BookingShiftsFields) => shift.id === result.id);
            }

            if (shiftToSelect) {
                shiftToSelect.isNew = false;
                Object.assign(shiftToSelect, result);
                this.selectShift(shiftToSelect);
            }

            this.shiftForm?.markAsPristine();
            this.openDialogsService.openSnackBarTilby('SETTINGS.SHIFTS.SAVE_SUCCESSFUL', 'MISC.OK', { duration: 10000, horizontalPosition: 'left', verticalPosition: 'bottom' });
        } catch (err) {
            this.alertDialogService.openDialog({
                data: {
                    messageLabel: 'SETTINGS.SHIFTS.SAVE_FAILED',
                    confirmLabel: 'SETTINGS.SHIFTS.ALERT_CONFIRM_LABEL'
                }
            });
        } finally {
            this.unlockProcessing();
        }
    }

    public async delete() {
        if (!this.selectedShift) {
            return;
        }

        const _selectedShift = this.selectedShift;

        const confirm = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'SETTINGS.SHIFTS.DELETE_CONFIRM',
                confirmLabel: 'SETTINGS.SHIFTS.CONFIRM_LABEL',
                cancelLabel: 'SETTINGS.SHIFTS.CANCEL_LABEL'
            }
        });

        if (!confirm) {
            return;
        }

        this.lockProcessing();

        try {
            if (_selectedShift.id) {
                await this.entityManagerService.bookingShifts.deleteOneOnline(_selectedShift.id);
                const indexOfshiftToDelete = this.shiftsList.findIndex(shift => shift.id === _selectedShift.id);

                if (indexOfshiftToDelete !== -1) {
                    this.shiftsList.splice(indexOfshiftToDelete, 1);
                }
            } else {
                this.shiftsList.pop();
            }

            this.shiftsList = [...this.shiftsList];
            this.shiftForm?.markAsPristine();
            this.isNewEntryInProgress = false;
            this.filterList = '';
            this.selected = false;

            if (this.firstEntry) {
                this.firstEntry = false;
            }
        } catch (err) {
            this.errorsLoggerService.err(err);

            this.alertDialogService.openDialog({
                data: {
                    messageLabel: 'SETTINGS.SHIFTS.DELETE_FAILED',
                    confirmLabel: 'SETTINGS.SHIFTS.ALERT_CONFIRM_LABEL'
                }
            });
        } finally {
            this.unlockProcessing();
        }
    }

    public getWeekdaysPeriod(weekdays_period: Array<BookingShifts.WeekdaysPeriodEnum> | undefined) {
        let outString: string = '';
        if(weekdays_period) {
            if (weekdays_period.length === 0 || weekdays_period.length === 7) {
                outString = this.translate.instant('SETTINGS.SHIFTS.ALL_DAYS');
            } else {
                const outArray: Array<string> = [];
                weekdays_period.forEach((day: BookingShifts.WeekdaysPeriodEnum) => {
                    switch (day) {
                        case 'Mo': outArray.push(moment.weekdaysShort(true, 0)); break;
                        case 'Tu': outArray.push(moment.weekdaysShort(true, 1)); break;
                        case 'We': outArray.push(moment.weekdaysShort(true, 2)); break;
                        case 'Th': outArray.push(moment.weekdaysShort(true, 3)); break;
                        case 'Fr': outArray.push(moment.weekdaysShort(true, 4)); break;
                        case 'Sa': outArray.push(moment.weekdaysShort(true, 5)); break;
                        case 'Su': outArray.push(moment.weekdaysShort(true, 6)); break;
                    }
                });
                outString = outArray.join(', ');
            }
        }

        return outString;
    }

    private removeSeconds(time: string) {
        const tmp = time.split(':');
        return `${tmp[0]}:${tmp[1]}`;
    }

    public getTime(shift: BookingShiftsFields) {
        return (shift.start_time && shift.end_time) ? `${this.removeSeconds(shift.start_time)}-${this.removeSeconds(shift.end_time)}`: '';
    }

    public filterShifts() {
        this.filterToggle = !this.filterToggle;
        if(this.filterToggle) {
            setTimeout(() => this.renderer.selectRootElement("input[name=search]").focus(), 0);
        } else {
            this.filterList = '';
        }
    }

}

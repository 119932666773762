import angular from 'angular';
import _ from 'lodash';
import { SclDictionary } from 'app/libs/SclDictionary';

angular.module('stock').factory('addEditMovement', ["$mdDialog", function($mdDialog) {
    addEditMovementController.$inject = ["$scope", "$mdDialog", "$filter", "$timeout", "$translate", "movement", "params", "restManager", "barcodeManager", "confirmDialog", "entityManager", "util"];
    function addEditMovementController($scope, $mdDialog, $filter, $timeout, $translate, movement, params, restManager, barcodeManager, confirmDialog, entityManager, util) {
        if (!_.isObject(params)) {
            params = {};
        }

        if(_.get(movement, 'id')) {
            $scope.title = movement.name;
        } else if(params.productionMode) {
            $scope.title = $translate.instant('STOCK.CAUSES.PRODUCTION');
        } else {
            $scope.title = $translate.instant('STOCK.NEW_MOVEMENT.NEW_MOVEMENT');
        }

        $scope.types = {
            load: $translate.instant('STOCK.ADD_EDIT_MOVEMENT.LOAD'),
            unload: $translate.instant('STOCK.ADD_EDIT_MOVEMENT.UNLOAD'),
            setup: $translate.instant('STOCK.ADD_EDIT_MOVEMENT.SETUP')
        };

        $scope.documentTypes = {
            none: $translate.instant('STOCK.ADD_EDIT_MOVEMENT.NONE'),
            invoice: $translate.instant('STOCK.ADD_EDIT_MOVEMENT.INVOICE'),
            ddt: $translate.instant('STOCK.ADD_EDIT_MOVEMENT.DDT'),
            ddtIn: $translate.instant('STOCK.ADD_EDIT_MOVEMENT.DDT_IN'),
            order: $translate.instant('STOCK.ADD_EDIT_MOVEMENT.ORDER'),
            bv: $translate.instant('STOCK.ADD_EDIT_MOVEMENT.BV')
        };

        var loadCauses = {
            buy: $translate.instant('STOCK.CAUSES.BUY'),
            refund: $translate.instant('STOCK.CAUSES.REFUND'),
            replacement: $translate.instant('STOCK.CAUSES.REPLACEMENT'),
            production: $translate.instant('STOCK.CAUSES.PRODUCTION'),
            transfer: $translate.instant('STOCK.CAUSES.TRANSFER'),
            positive_adjustment: $translate.instant('STOCK.CAUSES.POSITIVE_ADJUSTMENT')
        };

        var unloadCauses = {
            sale: $translate.instant('STOCK.CAUSES.SALE'),
            internal_use: $translate.instant('STOCK.CAUSES.INTERNAL_USE'),
            damage: $translate.instant('STOCK.CAUSES.DAMAGE'),
            theft: $translate.instant('STOCK.CAUSES.THEFT'),
            waste: $translate.instant('STOCK.CAUSES.WASTE'),
            supplier_return: $translate.instant('STOCK.CAUSES.SUPPLIER_RETURN'),
            transfer: $translate.instant('STOCK.CAUSES.TRANSFER'),
            replacement: $translate.instant('STOCK.CAUSES.REPLACEMENT'),
            production: $translate.instant('STOCK.CAUSES.PRODUCTION'),
            negative_adjustment: $translate.instant('STOCK.CAUSES.NEGATIVE_ADJUSTMENT')
        };

        var causes = {
            move: $translate.instant('STOCK.CAUSES.MOVE'), //Legacy unload cause
            unknown: $translate.instant('STOCK.CAUSES.UNKNOWN'), //Shouldn't happen
            bom_production: $translate.instant('STOCK.CAUSES.BOM_PRODUCTION') //Manual production case (not selectable)
        };

        _.assign(causes, loadCauses);
        _.assign(causes, unloadCauses);

        $scope.causesInput = {};

        $scope.isFirstAdd = false;

        $scope.entryTypes = {
            raw_material: $translate.instant('STOCK.ADD_EDIT_MOVEMENT.RAW_MATERIAL'),
            item: $translate.instant('STOCK.ADD_EDIT_MOVEMENT.ITEM')
        };

        var transformMovementData = function() {
            _.assign($scope.movement, {
                $date: new Date($scope.movement.date),
                $type: $scope.types[$scope.movement.type],
                $entryType: $scope.entryTypes[$scope.movement.entry_type],
            });

            if ($scope.movement.load_cause) {
                $scope.movement.$causeText = causes[$scope.movement.load_cause] || causes['unknown'];
            } else if ($scope.movement.unload_cause) {
                $scope.movement.$causeText = causes[$scope.movement.unload_cause] || causes['unknown'];
            }
        };

        var checkMovementQuantity = function() {
            $scope.movementForm.quantity.$setValidity('invalid', !(_.includes(['load', 'unload'], $scope.movement.type) && !$scope.movement.quantity));
        };

        var restoreEntryBindings = function() {
            if($scope.movement.supplier_id) {
                $scope.movement.$supplier = params.dictSuppliers.get($scope.movement.supplier_id) || null;
            }
            
            switch($scope.movement.entry_type) {
                case "raw_material":
                    $scope.movement.$entry = params.dictMaterials.get($scope.movement.raw_material_id);
                break;
                case "item":
                    $scope.movement.$entry = params.dictItems.get($scope.movement.item_id);
                break;
            }

            if ($scope.movement.combination_id) {
                $scope.movement.$combinations = parseCombinations($scope.movement.$entry);
                $scope.movement.$selectedCombination = _.find($scope.movement.$combinations, { id: $scope.movement.combination_id });
            }
        };

        $scope.filterSuppliers = function(searchText) {
            return $filter('filter')(params.suppliers, searchText);
        };

        $scope.parseItemOptionsValues = function(item) {
            var optionsValues = [];

            for (var i = 1; i <= 5; i++) {
                if (item["option" + i + "_name"] && !_.isEmpty(item["option" + i + "_value"])) {
                    optionsValues.push(item["option" + i + "_value"]);
                }
            }

            if (!_.isEmpty(optionsValues)) {
                return optionsValues.join(', ');
            } else {
                return null;
            }
        };

        var parseCombinations = function(item) {
            var combinations = [];

            _.forEach(item.combinations, function(comb) {
                combinations.push({
                    combinationRef: comb,
                    name: _.map(comb.combination_values, 'variation_value').join(', '),
                    id: comb.id,
                    barcode: !_.isEmpty(comb.barcodes) ? _.head(comb.barcodes).barcode : undefined
                });
            });

            if (!_.isEmpty(combinations)) {
                return combinations;
            } else {
                return null;
            }
        };

        if (!movement || _.isEmpty(movement)) {
            $scope.dialogMode = 'add';
            $scope.movement = {};
            
            if(params.productionMode) {
                $scope.dialogMode = 'production';
                
                entityManager.items.fetchCollectionOffline({ stock_type: 'bom', manual_production: true }).then(function(results) {
                    params.items = _.sortBy(results, 'name');
                    params.dictItems = new SclDictionary();

                    _.forEach(results, function(item) {
                        params.dictItems.set(item.id, item);
                    });
                });

                _.assign($scope.movement, {
                    $cause: 'bom_production',
                    entry_type: 'item',
                    type: 'load'
                });
            } else if (params.type) { //Happens when this isn't the first movement in the transaction
                _.assign($scope.movement, {
                    type: params.type,
                    $type: $scope.types[params.type],
                    $cause: params.cause,
                    $causeText: causes[params.cause],
                    supplier_order: params.supplierOrder,
                });

                if (params.supplier) {
                    _.assign($scope.movement, {
                        supplier_id: params.supplier.id,
                        supplier_name: params.supplier.name
                    });
                }
            } else {
                $scope.isFirstAdd = true;
            }

            $scope.movement.$date = params.date ? new Date(params.date) : new Date();
            
            if (!params.date) {
                $scope.movement.$date.setSeconds(0);
                $scope.movement.$date.setMilliseconds(0);
            }

            if (params.operator) {
                if (params.operator.first_name && params.operator.last_name) {
                    $scope.movement.operator_fullname = params.operator.first_name + " " + params.operator.last_name;
                    $scope.movement.operator_name = $scope.movement.operator_fullname;
                } else {
                    $scope.movement.operator_username = params.operator.username;
                    $scope.movement.operator_name = params.operator.username;
                }
                $scope.movement.operator_id = params.operator.id;
            }
        } else if (movement.id) {
            $scope.dialogMode = 'view';
            $scope.movement = _.cloneDeep(movement);
            transformMovementData();
        } else {
            $scope.dialogMode = 'edit';
            $scope.movement = _.cloneDeep(movement);
            transformMovementData();
            restoreEntryBindings();
        }

        $scope.isViewMode = function() {
            return $scope.dialogMode === 'view';
        };

        $scope.isEditMode = function() {
            return $scope.dialogMode === 'edit';
        };

        $scope.isAddMode = function() {
            return $scope.dialogMode === 'add';
        };

        $scope.isFirstAddMode = function() {
            return $scope.dialogMode === 'add' && $scope.isFirstAdd;
        };

        $scope.isProductionMode = function() {
            return $scope.dialogMode === 'production';
        };

        $scope.onMovementTypeChange = function() {
            delete $scope.movement.$cause;
            switch ($scope.movement.type) {
                case 'load':
                    $scope.causesInput = loadCauses;
                    break;
                case 'unload':
                    $scope.causesInput = unloadCauses;
                    break;
                case 'setup':
                    $scope.causesInput = null;
                    break;
                default:
                    break;
            }
            checkMovementQuantity();
        };

        $scope.onCombinationChange = function() {
            if ($scope.movement.$selectedCombination.barcode) {
                $scope.movement.barcode = $scope.movement.$selectedCombination.barcode;
            }
        };

        $scope.onEntryTypeChange = function() {
            delete $scope.movement.$entry;
            delete $scope.movement.name;
            delete $scope.movement.raw_material_id;
            delete $scope.movement.item_id;
            delete $scope.movement.$selectedCombination;
            delete $scope.movement.$combinations;
        };

        $scope.onBarcodeFocusRemove = function() {
            $timeout(function() {
                $scope.barcodeHasFocus = false;
            }, 350);
        };

        $scope.filterEntries = function(searchText) {
            var dataset = $scope.movement.entry_type === 'raw_material' ? params.rawMaterials : params.items;
            return _.isEmpty(searchText) ? dataset : $filter('filter')(dataset, searchText);
        };

        $scope.getEntryUnit = function() {
            var unit = _.get($scope.movement, ["$entry", "unit"]);
            return _.isEmpty(unit) ? undefined : " (" + unit + ")";
        };

        $scope.selectEntry = function() {
            delete $scope.movement.$selectedCombination;
            delete $scope.movement.$combinations;
            delete $scope.movement.options_values;
            delete $scope.movement.barcode;
            
            var entry = $scope.movement.$entry;
            
            if (entry) {
                if (entry.default_supplier_id && !$scope.movement.$supplier && $scope.isFirstAdd) {
                    $scope.movement.$supplier = params.dictSuppliers.get(entry.default_supplier_id) || null;
                }

                switch ($scope.movement.entry_type) {
                    case 'raw_material':
                        break;
                    case 'item':
                        $scope.movement.$combinations = parseCombinations(entry);

                        $scope.movement.options_values = $scope.parseItemOptionsValues(entry);
                        if (!_.isEmpty(entry.barcodes)) {
                            $scope.movement.barcode = _.head(entry.barcodes).barcode;
                        }

                        if($scope.isProductionMode()) {
                            $scope.productionPreview = getProductionPreview();
                        }
                        break;
                    default:
                        break;
                }
            }
        };

        $scope.onMovementQuantityChange = function() {
            if($scope.isProductionMode()) {
                $scope.productionPreview = getProductionPreview();
            }

            checkMovementQuantity();
        };

        var getProductionPreview = function() {
            var result = [];

            var recursiveBomScan = function(bomComponents, quantity) {
                _.forEach(bomComponents, function(bomComponent) {
                    if(bomComponent.component_item_id || bomComponent.component_raw_material_id) {
                        result.push({ id: bomComponent.id, name: bomComponent.name, quantity: util.round(bomComponent.quantity * quantity, 4) });
                    } else {
                        recursiveBomScan(bomComponent.bom_components, quantity * bomComponent.quantity);
                    }
                });
            };
            
            if($scope.movement.$entry) {
                recursiveBomScan($scope.movement.$entry.bom_components, $scope.movement.quantity || 0);
            }

            return result;
        };

        $scope.searchByBarcode = async () => {
            const barcodeInput = $scope.movement.barcode;

            const searchData = await barcodeManager.searchBarcodeItem(barcodeInput);
            const found = searchData.data[0];

            if(found) {
                var itemBarcode = params.dictItems.get(found.item_id);
                if (_.get(itemBarcode, "stock_type") === 'simple') {
                    _.assign($scope.movement, {
                        $entry: itemBarcode,
                        $combinations: parseCombinations(itemBarcode),
                        entry_type: 'item',
                        options_values: $scope.parseItemOptionsValues(itemBarcode)
                    });

                    if (found.quantity) {
                        $scope.movement.quantity = found.quantity;
                        $scope.movement.barcode = found.barcode;
                    }
                    if (found.combination_id) {
                        $scope.movement.$selectedCombination = _.find($scope.movement.$combinations, { id: found.combination_id });
                    }
                    if (itemBarcode.default_supplier_id && !$scope.movement.$supplier && $scope.isFirstAdd) {
                        $scope.movement.$supplier = params.dictSuppliers.get($scope.movement.supplier_id) || null;
                    }
                }
            }
        };

        var cleanupMovement = function(movement) {
            delete movement.$cause;
            delete movement.$causeText;
            delete movement.$combinations;
            delete movement.$selectedCombination;
            delete movement.$date;
            delete movement.$entry;
            delete movement.$entryType;
            delete movement.$type;
            delete movement.operator_name;
        };

        var compileEntryData = function(movement) {
            _.assign(movement, {
                name: movement.$entry.name,
                unit: movement.$entry.unit
            });

            switch (movement.entry_type) {
                case 'raw_material':
                    _.assign(movement, {
                        raw_material_id: movement.$entry.id
                    });
                    break;
                case 'item':
                    _.assign(movement, {
                        item_id: movement.$entry.id,
                        code: movement.$entry.code
                    });
                    break;
                default:
                    break;
            }
        };

        var compileCombinationData = function(movement) {
            if (movement.$combinations && movement.$selectedCombination) {
                movement.combination_id = movement.$selectedCombination.id;
                movement.combination = movement.$selectedCombination.name;
            }
        };

        var compileMovementDate = function(movement) {
            movement.date = new Date(movement.$date).toISOString();
        };

        $scope.confirm = function() {
            var movementToSend;

            if ($scope.isViewMode()) {
                if ($scope.movement.notes !== movement.notes) {
                    var tmpMovement = _.cloneDeep(movement);

                    tmpMovement.notes = $scope.movement.notes;

                    $scope.sendingMovement = true;

                    restManager.put("stock_movements", tmpMovement.id, tmpMovement).then(function(result) {
                        $mdDialog.hide(result);
                    }).finally(function() {
                        $scope.sendingMovement = false;
                    });
                } else {
                    $mdDialog.hide(movement);
                }
            } else {
                var editMovement = function(){
                    delete $scope.movement.combination_id;
                    delete $scope.movement.combination;

                    compileMovementDate(movementToSend);
                    compileEntryData(movementToSend);
                    compileCombinationData(movementToSend);
                    cleanupMovement(movementToSend);

                    $mdDialog.hide(movementToSend);
                };

                var addMovement = function() {
                    if (movementToSend.type === 'load') {
                        movementToSend.load_cause = movementToSend.$cause;
                    } else if (movementToSend.type === 'unload') {
                        movementToSend.unload_cause = movementToSend.$cause;
                    }

                    if (movementToSend.$supplier) {
                        _.assign(movementToSend, {
                            supplier_name: _.get(movementToSend, ["$supplier", "name"]),
                            supplier_id: _.get(movementToSend, ["$supplier", "id"])
                        });
                    }

                    if($scope.isFirstAdd) {
                        if(!_.isEmpty(movementToSend.$docType) && movementToSend.$docType !== 'none') {
                            movementToSend.supplier_order = $scope.documentTypes[movementToSend.$docType] + (_.isEmpty(movementToSend.supplier_order) ? '' : '-' + movementToSend.supplier_order);
                        }
                    }

                    compileMovementDate(movementToSend);
                    compileEntryData(movementToSend);
                    compileCombinationData(movementToSend);
                    cleanupMovement(movementToSend);

                    if($scope.isProductionMode()) {
                        $scope.sendingMovement = true;

                        restManager.post("stock_movements", movementToSend).then(function(result) {
                            $mdDialog.hide(result);
                        }).finally(function() {
                            $scope.sendingMovement = false;
                        });
                    } else {
                        $mdDialog.hide(movementToSend);
                    }

                };

                movementToSend = _.cloneDeep($scope.movement);
                var currentSupplier = _.get(movementToSend, ['$supplier', 'id'], movementToSend.supplier_id);

                if(movementToSend.type !== "unload" && movementToSend.$entry.default_supplier_id && movementToSend.$entry.default_supplier_id !== currentSupplier) {
                    var defaultSupplier = movementToSend.$entry.default_supplier_id ? _.find(params.suppliers, function(supp) {
                        return supp.id === movementToSend.$entry.default_supplier_id;
                    }) : null;

                    confirmDialog.show($translate.instant('STOCK.ADD_EDIT_MOVEMENT.SUPPLIER_WARNING', {supplier: _.get(defaultSupplier, ['name'], "diverso")}), { multiple: true }).then(function(answer){
                        if(answer) {
                            if($scope.isAddMode()) {
                                addMovement();
                            } else {
                                editMovement();
                            }
                        }
                    });
                } else {
                    if($scope.isAddMode() || $scope.isProductionMode()) {
                        addMovement();
                    } else {
                        editMovement();
                    }
                }
            }
        };

        $scope.duplicate = function(){
            $mdDialog.hide([$scope.movement, true]);
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };
    }

    var addEditMovement = {
        show: function(movement, params) {
            return $mdDialog.show({
                controller: addEditMovementController,
                template: require('./add-edit-movement.html'),
                locals: {
                    movement: movement,
                    params: params
                }
            });
        }
    };

    return addEditMovement;
}]);

import { CommonModule } from "@angular/common";
import { Component, HostBinding, Input, OnInit } from "@angular/core";
import { FloorType } from "src/app/core";
import { Rooms } from "tilby-models";

@Component({
    selector: 'app-booking-room',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './booking-room.component.html',
    styleUrls: ['./booking-room.component.scss']
})
export class BookingRoomComponent implements OnInit {
    private _roomFloor: FloorType = 'parquet';
    @HostBinding('style.--roomWidthInitial') roomWidthInitial = '2000px';
    @HostBinding('style.--roomHeightInitial') roomHeightInitial = '800px';
    @HostBinding('style.--roomWidth') @Input() roomWidth = '2000px';
    @HostBinding('style.--roomHeight') @Input() roomHeight = '800px';
    @HostBinding('style.--roomBackground') roomBackground = `url(assets/images/tables/floor/${this.roomFloor}.png)`;

    @Input() room?: Rooms;

    @Input() set roomFloor(floor: FloorType) {
        this._roomFloor = floor;
        this.roomBackground = `url(assets/images/tables/floor/${floor}.png)`;
    }

    get roomFloor() {
        return this._roomFloor;
    }

    ngOnInit() {
        this.roomWidthInitial = this.roomWidth;
        this.roomHeightInitial = this.roomHeight;
    }
}

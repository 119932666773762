import {
    Component,
    inject,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';

import {
    ConfirmDialogService,
    OpenDialogsService
} from "src/app/dialogs";

import {FormGroup} from '@angular/forms';
import {SuppliersFields, SuppliersForm} from "../../suppliers-form.model";
import {$state} from 'app/ajs-upgraded-providers';
import {GridService} from "../../../../shared/components/grid/grid.service";
import {EntityManagerService} from "src/app/core/services/entity/entity-manager.service";
import {ToolbarEventsService} from "../../../../core/services/toolbar-events/toolbar-events.service";
import {ITALY} from "../../../../shared/utils";
import { countryToCode } from "src/app/core/constants";
import {ToolbarEventsContextService} from "../../../../core/services/toolbar-events/toolbar-events-context.service";
import {SupplierFormService} from "../../services/supplier-form.service";
import { Suppliers } from 'tilby-models';

@Component({
    selector: 'suppliers-details',
    templateUrl: './suppliers-details.component.html',
    styleUrls: ['./suppliers-details.component.scss']
})
export class SuppliersDetailsComponent implements OnInit, OnDestroy {
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly gridService = inject(GridService);
    private readonly entityManager = inject(EntityManagerService);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly toolbarEventsContextService = inject(ToolbarEventsContextService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly supplierFormService = inject(SupplierFormService);
    private readonly state = inject($state);

    public validators = this.supplierFormService.validators;
    public suppliersForm?: FormGroup<SuppliersForm>;
    @Input() supplier?: SuppliersFields;
    private isNew = false;
    public Italy = ITALY;

    ngOnInit(): void {
        this.suppliersForm = this.supplierFormService.createForm(this.supplier);
        //this.isNewOrEdit = !this.supplier;
        //-------- NEW GENERIC TOOLBAR - START ----///


        /**
         * Create buttons for the toolbar
         */
        this.isNew = this.state.$current.name.split('.').pop() === 'new';

        const createToolbarButtons = () => {
            this.toolbarEventsContextService.backButton$.next({
                isIt: () => true,
                name: 'arrow_back',
                icon: () => 'arrow_back',
                click: () => this.back()
            });
            this.toolbarEventsService.buttons$.next({
                barButtons: [
                ],
                panelButtons: [
                ]
            });
            this.toolbarEventsContextService.buttons$.next({
                barButtons: [
                    {
                        isIt: () => true,
                        name: 'save',
                        icon: () => 'check',
                        click: _ => this.onSubmit(this.suppliersForm)
                    },
                    {
                        isIt: () => !!((this.suppliersForm?.valid) && !this.isNew),
                        name: 'delete',
                        icon: () => 'delete',
                        click: _ => this.openDeleteDialog()
                    },
                ],
                panelButtons: []
            });
        }
        createToolbarButtons();
//-------- NEW GENERIC TOOLBAR - END ----///
        this.contextToolbarLabelChange();
    }

    ngOnDestroy(): void {
    }

    contextToolbarLabelChange() {
        const {name = ''} = this.supplier || {};
        this.toolbarEventsContextService.label = this.isNew
            ? 'SUPPLIERS.DETAILS.NEW_SUPPLIER'
            : name;
    }

    get name() {
        return this.suppliersForm?.controls.name;
    }

    get vat_code() {
        return this.suppliersForm?.controls.vat_code;
    }

    get phone1() {
        return this.suppliersForm?.controls.phone1;
    }

    get phone2() {
        return this.suppliersForm?.controls.phone2;
    }

    get fax() {
        return this.suppliersForm?.controls.fax;
    }

    get email1() {
        return this.suppliersForm?.controls.email1;
    }

    get email2() {
        return this.suppliersForm?.controls.email2;
    }

    get email_pec() {
        return this.suppliersForm?.controls.email_pec;
    }

    get website() {
        return this.suppliersForm?.controls.website;
    }

    get address_street() {
        return this.suppliersForm?.controls.address_street;
    }

    get address_number() {
        return this.suppliersForm?.controls.address_number;
    }

    get address_zip() {
        return this.suppliersForm?.controls.address_zip;
    }

    get address_city() {
        return this.suppliersForm?.controls.address_city;
    }

    get address_prov() {
        return this.suppliersForm?.controls.address_prov;
    }

    get country() {
        return this.suppliersForm?.controls.country;
    }

    async onSubmit(form?: FormGroup<SuppliersForm>) {
        if (this.suppliersForm?.invalid) {
            this.suppliersForm?.markAllAsTouched();
        }

        if (form?.valid) {
            const {...supplierForm} = <SuppliersFields>form.value;
            if(supplierForm.country) {
                supplierForm.country = countryToCode('country', supplierForm.country) || undefined;
            }
            if (supplierForm.conto_vendita) supplierForm.name = `[CV] ${this.name?.value}`;
            else supplierForm.name = this.name?.value?.split('[CV] ').pop() || '';
            // TODO: crudService
            let savedSupplier: Suppliers;

            if (supplierForm.id) {
                savedSupplier = await this.entityManager.suppliers.putOneOnline(supplierForm);
            } else {
                savedSupplier = await this.entityManager.suppliers.postOneOnline(supplierForm);
            }

            // this.gridService.tableProperties.gridIstance.api.refreshServerSideStore({purge: false});
            // this.isNewOrEdit = false;
            this.contextToolbarLabelChange();
            this.state.go(`app.new.suppliers.details`, {supplierId: savedSupplier.id, isNew:true});
            this.openDialogsService.openSnackBarTilby('TOAST.SAVED.MESSAGE', 'TOAST.SAVED.ACTION',{duration:3000});

        }
    }

    deleteRecord() {
        this.state.go('app.new.suppliers',{remove: this.supplier?.id});
        // from<Promise<Suppliers>>(this.entityManager.suppliers.deleteOneOnline(this.supplier.id).then(({results}) => results)).subscribe(r => this.state.go('app.new.suppliers',{remove: this.supplier.id}));
    }

    back() {
        if (this.supplier) this.gridService.suppliers$.flash.next(this.supplier);
        this.state.go("app.new.suppliers");
    }

    check() {
        this.onSubmit(this.suppliersForm);
    }

    async openDeleteDialog() {
        const res = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'DIALOG.SUPPLIER_DELETE.TITLE',
                confirmLabel: 'DIALOG.SUPPLIER_DELETE.CONFIRM',
                cancelLabel: 'DIALOG.SUPPLIER_DELETE.CANCEL'
            }
        });

        if(res) {
            this.deleteRecord();
        }
    }
}

import { Injectable, OnDestroy } from '@angular/core';
import {Module, ModuleEvents, SearchBarXQuery, ToolbarButtons} from "./toolbar-events.model";
import {BehaviorSubject, Subject} from 'rxjs';
import { SearchBarComponent } from '../../components';
import { TopbarTablesTotalComponent } from 'src/app/features/tables/components/topbar-tables-total';
import { BookingTopbarComponent } from 'src/app/features/bookings/components/booking-topbar';

export type CentralToolbarComponent = (typeof SearchBarComponent) | (typeof TopbarTablesTotalComponent) | (typeof BookingTopbarComponent);

/**
 * Service for managing toolbar events and state.
 * [toolbar documentation](https://scloby.atlassian.net/wiki/spaces/DT/pages/2408382468/Toolbar)
 */
@Injectable({
  providedIn: 'root'
})
export class ToolbarEventsService implements OnDestroy{
    //Contiene i subject di tutti i moduli (e quindi gli eventi toolbar)
    centralToolbarComponent$= new BehaviorSubject<CentralToolbarComponent|undefined>(SearchBarComponent);
    searchBarValue$ =new BehaviorSubject<string>('');
    searchBarIcon$ =new BehaviorSubject<string>('tune');
    searchBarAction$ =new BehaviorSubject<object | undefined>(undefined);
    searchBarIconFilled$ =new BehaviorSubject<string>('');
    searchBarActionFilled$ =new BehaviorSubject<object | undefined>(undefined);
    searchBarXQuery:SearchBarXQuery={
        customers: ['company_name','first_name','last_name','email','email_pec','mobile','phone','vat_code','tax_code','fidelity'],
        history_sales: ['sale_customer.first_name','sale_customer.last_name','sale_customer.company_name','seller_name','name','sale_documents.printer_name','sale_items.name'],
        history_cash_movements:['id', 'date', 'type', 'description', 'payment_method_name', 'card_circuit_name', 'amount', 'sale_id', 'account', 'created_at', 'updated_at'],
    };
    showButtonsBar$ =new BehaviorSubject<boolean>(true);
    module = new Module();
    events = new Subject<Partial<ModuleEvents>&{id?:number}>();
    buttons$ = new BehaviorSubject<ToolbarButtons>({panelButtons:[],barButtons:[]});
    moduleTitle = new Subject<string>();

  constructor() {
      this.events.subscribe();
  }

  ngOnDestroy() {
      this.events.unsubscribe()
  }
}

import angular from 'angular';
import _ from 'lodash';

angular.module('application').filter('duration', function() {
    return function(input, arg) {
        if(input) {
            var duration = new Date() - new Date(input);
            var durationHours = Math.abs(duration) / 3600000;

            var hours = _.floor(durationHours);
            var minutes = _.padStart(_.floor((durationHours - hours) * 60), 2, '0');

            var durationString = duration < 0 ? '-' : '';

            if (hours > 0) {
                durationString += hours + ':' + minutes + '\'';
            } else {
                durationString += minutes + '\'';
            }

            return durationString;
        } else {
            return undefined;
        }
    };
});

import {
    Injectable,
    Injector,
    inject,
} from "@angular/core";

import { EntityName } from "./entity.model";
import { util } from "app/ajs-upgraded-providers";

import {
    EntityManagerService
} from "src/app/core";

@Injectable({
    providedIn: 'root'
})
export class StartupQueriesService {
    private injector = inject(Injector);

    /**
     * Retrieves the startup query for the provided entity name.
     *
     * @param {EntityName} entityName - The name of the entity.
     * @return {object} The startup query object.
     */
    public getStartupQuery(entityName: EntityName | keyof EntityManagerService) {
        switch (entityName) {
            case 'bookings': {
                const dayStartTime = this.injector.get(util).getDayStartTime() as Date;

                //Return range from the start of the week (use Sunday to be sure) to 30 days in the future
                const bookingStart = new Date(dayStartTime);
                const bookingEnd = new Date(dayStartTime);

                bookingStart.setDate(dayStartTime.getDate() - (dayStartTime.getDay() || 7));
                bookingEnd.setDate(dayStartTime.getDate() + 30);

                return  {
                    booked_for_since: bookingStart.toISOString(),
                    booked_for_max: bookingEnd.toISOString()
                };
            }
            case 'items': {
                return { on_sale: true };
            }
            case 'sales': {
                return { status: 'open' };
            }
            case 'sale_transactions': case 'saleTransactions': {
                return { 'sale.status': 'open' };
            }
            case 'stock': {
                return { item_on_sale: true };
            }
            default: {
                return {};
            }
        }
    }

    /**
     * Retrieves the default pagination information.
     *
     * @param {EntityName} entityName - The name of the entity.
     * @return {object} The pagination information for the entity.
     */
    public getPaginationInfo(entityName: EntityName | keyof EntityManagerService) {
        switch (entityName) {
            case 'items':
                return 10000;
            case 'customers':
                return 5000;
            case 'stock':
                return 10000;
            default:
                break;
        }
    }
}
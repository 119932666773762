import { EpsonUtils } from 'src/app/shared/epson-utils';

import {
    EpsonAlign,
    EpsonBarcode,
    EpsonColor,
    EpsonCut,
    EpsonDrawer,
    EpsonFont,
    EpsonHRI,
    EpsonLevel,
    EpsonPulse,
    EpsonSymbol,
    NFSendResult,
    NFThermalPrinter,
    PrintSettings
} from 'src/app/shared/model/nf-thermal-printer.model';

import {
    compareObjects
} from 'src/app/shared/utils';

import {
    XMLNode
} from 'src/app/shared/XmlNode';

interface NetworkSettings {
    ipAddress: string;
    port: number;
}

interface EPosDriverConfig {
    line_spacing?: number;
    eReceiptMode?: boolean;
}

/**
 * This class implements the NFThermalPrinter interface for Epson ePOS printers.
 */
export class EPosDriver implements NFThermalPrinter {
    private networkSettings: NetworkSettings;
    private printSettings: PrintSettings;
    private lastPrintSettings: PrintSettings;
    private ePosDoc: XMLNode;
    private eReceiptMode: boolean;

    /**
     * @constructor
     * @param {string} ipAddress - The IP address of the printer.
     * @param {number} [port=8008] - The port number of the printer.
     * @param {EPosDriverConfig} [configuration] - Optional configuration settings.
     */
    constructor(ipAddress: string, port: number = 8008, configuration?: EPosDriverConfig) {
        this.networkSettings = {
            ipAddress: ipAddress,
            port: port
        };

        //Default style settings
        this.printSettings = {
            dw: false,
            dh: false,
            reverse: false,
            height: 1,
            width: 1,
            ul: false,
            em: false,
            linespc: configuration?.line_spacing ?? 80,
            color: EpsonColor.COLOR_1,
            align: EpsonAlign.LEFT
        };

        this.lastPrintSettings = Object.assign({}, this.printSettings, { linespc: null });

        this.ePosDoc = new XMLNode("epos-print", {
            xmlns: "http://www.epson-pos.com/schemas/2011/03/epos-print"
        });

        this.eReceiptMode = !!(configuration?.eReceiptMode);
    }

     /**
     * Gets the printer endpoint URL.
     * @param {NetworkSettings} target - The network settings of the printer.
     * @returns {string} The printer endpoint URL.
     */
    private static getPrinterEndpoint(target: NetworkSettings): string {
        return `http://${target.ipAddress}/cgi-bin/epos/service.cgi?devid=local_printer`;
    }

     /**
     * Adds a SOAP envelope to the command.
     * @param {string} command - The command to be wrapped in a SOAP envelope.
     * @returns {string} The SOAP envelope with the command.
     */
    private static addSoapEnvelope(command: string): string {
        return `<?xml version="1.0" encoding="utf-8"?><s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/"><s:Body>${command}</s:Body></s:Envelope>`;
    }

    /**
     * Sends a POST request to the printer.
     * @param {string} url - The URL of the printer endpoint.
     * @param {string} data - The data to be sent in the POST request.
     * @param {object} [options] - Optional settings for the request.
     * @param {number} [options.timeout] - Optional timeout for the request.
     * @throws {number} If the response status is not ok.
     * @throws {any} If the parsed response has success as false
     * @returns {Promise<void>}
     */
    private static async sendPostRequest(url: string, data: string, options?: { timeout?: number }) {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/xml',
            },
            body: data,
            signal: options?.timeout ? AbortSignal.timeout(options?.timeout) : undefined
        });

        if (!response.ok) {
            throw response.status;
        }

        const responseText = await response.text();

        const responseParsed = EpsonUtils.parseEpsonXml({
            data: responseText,
            status: response.status
        });

        if (responseParsed?.result?.success !== true) {
            throw responseParsed?.status;
        }
    }

     /**
     * Sends a request to the printer.
     * @param {NetworkSettings} target - The network settings of the printer.
     * @param {string} command - The command to be sent to the printer.
     * @returns {Promise<void>}
     */
    private static async sendRequest(target: NetworkSettings, command: string) {
        if (target.ipAddress === '0.0.0.0') {
            return;
        }

        const printerEndpoint = this.getPrinterEndpoint(target);
        const soapCommand = this.addSoapEnvelope(command);

        await this.sendPostRequest(printerEndpoint, soapCommand, { timeout: 30000 }).catch(() => {
            throw 'CONNECTION_ERROR';
        });
    }

    /**
     * Adds text to the print document.
     * @param {string} text - The text to be added.
     */
    public addText(text: string) {
        if (this.eReceiptMode) {
            return;
        }

        this.ePosDoc.appendChild(new XMLNode("text", compareObjects(this.printSettings, this.lastPrintSettings), text));
        this.lastPrintSettings = { ...this.printSettings };
    }

    /**
     * Sets the text to double width and/or height.
     * @param {boolean} [dw] - If true, sets double width.
     * @param {boolean} [dh] - If true, sets double height.
     */
    public addTextDouble(dw?: boolean, dh?: boolean) {
        this.printSettings.dw = typeof dw === 'boolean' ? dw : this.printSettings.dw;
        this.printSettings.dh = typeof dh === 'boolean' ? dh : this.printSettings.dh;
    }

    /**
     * Sets the text size.
     * @param {number} [w] - The width of the text (1-8).
     * @param {number} [h] - The height of the text (1-8).
     */
    public addTextSize(w?: number, h?: number) {
        this.printSettings.width = w && Number.isInteger(w) && (w >= 1 && w <= 8) ? w : this.printSettings.width;
        this.printSettings.height = h && Number.isInteger(h) && (h >= 1 && h <= 8) ? h : this.printSettings.height;
    }

    /**
     * Sets the text alignment.
     * @param {EpsonAlign} align - The alignment to be set.
     */
    public addTextAlign(align: EpsonAlign) {
        if ([EpsonAlign.LEFT, EpsonAlign.CENTER, EpsonAlign.RIGHT].includes(align)) {
            this.printSettings.align = align;
        }
    }

    /**
     * Sets the line spacing.
     * @param {number} linespc - The line spacing value.
     */
    public addTextLineSpace(linespc: number) {
        this.printSettings.linespc = linespc;
    }

    /**
     * Sets the text style (reverse, underline, emphasis, color).
     * @param {boolean} [reverse] - If true, sets reverse text.
     * @param {boolean} [ul] - If true, sets underline.
     * @param {boolean} [em] - If true, sets emphasis.
     * @param {EpsonColor} [color] - The color of the text.
     */
    public addTextStyle(reverse?: boolean, ul?: boolean, em?: boolean, color?: EpsonColor) {
        this.printSettings.reverse = typeof reverse === 'boolean' ? reverse : this.printSettings.reverse;
        this.printSettings.ul = typeof ul === 'boolean' ? ul : this.printSettings.ul;
        this.printSettings.em = typeof em === 'boolean' ? em : this.printSettings.em;
        this.printSettings.color = (Object.values(EpsonColor).includes(color!) ? color : EpsonColor.COLOR_1) as EpsonColor;
    }

    /**
     * Adds a logo to the print document.
     * @param {number} key1 - The first key of the logo.
     * @param {number} key2 - The second key of the logo.
     */
    public addLogo(key1: number, key2: number) {
        if (this.eReceiptMode) {
            return;
        }
        this.ePosDoc.appendChild(new XMLNode("logo", {
            key1: (key1 >= 0 && key1 <= 255) ? key1 : 32,
            key2: (key2 >= 0 && key2 <= 255) ? key2 : 32
        }));
    }

    /**
     * Adds a barcode to the print document.
     * @param {string} data - The data to be encoded in the barcode.
     * @param {EpsonBarcode} type - The type of barcode.
     * @param {EpsonHRI} [hri] - The human-readable interpretation of the barcode.
     * @param {EpsonFont} [font] - The font of the barcode.
     * @param {number} [width] - The width of the barcode.
     * @param {number} [height] - The height of the barcode.
     */
    public addBarcode(data: string, type: EpsonBarcode, hri?: EpsonHRI, font?: EpsonFont, width?: number, height?: number) {
        if (this.eReceiptMode) {
            return;
        }

        this.ePosDoc.appendChild(new XMLNode("barcode", { type: type, hri: hri, font: font, width: width, height: height }, data));
    }

    /**
     * Adds a symbol to the print document.
     * @param {string} data - The data to be encoded in the symbol.
     * @param {EpsonSymbol} type - The type of symbol.
     * @param {EpsonLevel} [level] - The error correction level of the symbol.
     * @param {number} [width] - The width of the symbol.
     * @param {number} [height] - The height of the symbol.
     * @param {number} [size] - The size of the symbol.
     */
    addSymbol(data: string, type: EpsonSymbol, level?: EpsonLevel, width?: number, height?: number, size?: number) {
        if (this.eReceiptMode) {
            return;
        }

        this.ePosDoc.appendChild(new XMLNode("symbol", {
            type: type,
            level: level,
            width: width,
            height: height,
            size: size
        }, data));
    }

    /**
     * Adds a cut command to the print document.
     * @param {EpsonCut} [type] - The type of cut.
     */
    public addCut(type?: EpsonCut) {
        if (this.eReceiptMode) {
            return;
        }

        this.ePosDoc.appendChild(new XMLNode("text", undefined, '\r\n'));

        this.ePosDoc.appendChild(new XMLNode("cut", {
            type: Object.values(EpsonCut).includes(type!) ? type : EpsonCut.FEED
        }));
    }

    /**
     * Adds a pulse command to the print document.
     * @param {EpsonDrawer} [drawer] - The drawer to be pulsed.
     * @param {EpsonPulse} [time] - The pulse time.
     */
    public addPulse(drawer?: EpsonDrawer, time?: EpsonPulse) {
        this.ePosDoc.appendChild(new XMLNode("pulse", {
            drawer: Object.values(EpsonDrawer).includes(drawer!) ? drawer : EpsonDrawer.DRAWER_1,
            time: Object.values(EpsonPulse).includes(time!) ? time : EpsonPulse.PULSE_100
        }));
    }

    /**
     * Sends the print document to the printer.
     * @returns {Promise<NFSendResult>} The result of the send operation.
     */
    public async send() {
        return EPosDriver.sendRequest(this.networkSettings, this.ePosDoc.toString()).then(
            () => <NFSendResult>'PRINTED',
            (err: NFSendResult) => this.eReceiptMode ? 'PRINTED' : err
        );
    }

    /**
     * Attempts to connect to a printer and returns its connection status.
     * @param {string} ip - The IP address of the printer.
     * @param {number} port - The port number of the printer.
     * @returns {Promise<{ip: string; connected: boolean}>} An object containing the printer IP and connection status
     */
    public static async connectAttempt(ip: string, port: number) {
        if (ip === '0.0.0.0') {
            return { ip: ip, connected: true };
        }

        const testDocument = this.addSoapEnvelope("<epos-print xmlns=\"http://www.epson-pos.com/schemas/2011/03/epos-print\"></epos-print>");
        const endpoint = this.getPrinterEndpoint({ ipAddress: ip, port: port });

        let connected = false;

        try {
            await this.sendPostRequest(endpoint, testDocument, { timeout: 1000 });
            connected = true;
        } catch (error) {
            //do nothing
        }

        return { ip: ip, connected: connected };
    }
}
import angular from 'angular';
import _ from 'lodash';

angular.module('application').factory('printerErrorOrders', ["$mdDialog", function($mdDialog) {

    printerErrorOrdersController.$inject = ["$scope", "$mdDialog", "results", "orderItemsCategories", "categories"];
    function printerErrorOrdersController($scope, $mdDialog, results, orderItemsCategories, categories) {
        $scope.categories = _.keyBy(categories, 'id');
        $scope.results = [];
        $scope.hasError = printerErrorOrders.hasError(results);

        var getCategoryNameList = function(idList) {
            var nameList = [];
            _.forEach(idList, function(id) {
                if($scope.categories[id]) {
                    nameList.push($scope.categories[id].name);
                }
            });

            return _.join(nameList, ', ');
        };

        var handledCategories = [];

        _.forEach(results, function(result) {
            var catIdList = _.map(result.printer.categories, 'id');
            handledCategories = _.concat(handledCategories, catIdList);

            if(result.result !== "EMPTY_ORDER") {
                $scope.results.push({
                    status: result.result,
                    printerName: result.printer.name,
                    categoriesList: getCategoryNameList(catIdList)
                });
            }
        });

        handledCategories = _.uniq(handledCategories);

        var printedCategories = _.intersection(handledCategories, orderItemsCategories);

        $scope.notPrintedCategories = getCategoryNameList(_.difference(orderItemsCategories, printedCategories));

        $scope.answer = function() {
            $mdDialog.hide();
        };
    }

    var printerErrorOrders = {
        show: function(results, orderItemsCategories) {
            return $mdDialog.show({
                    controller: printerErrorOrdersController,
                    template: require('./printer-error-orders.html'),
                    locals: {
                        results: results,
                        orderItemsCategories: orderItemsCategories
                    },
                    resolve: {
                        categories: ["entityManager", function(entityManager) {
                            return entityManager.categories.fetchCollectionOffline();
                        }]
                    }
                });
        },
        hasError: function(results){
            return _.find(results, function(res){
                return res.result === 'CONNECTION_ERROR' || res.result === 'CONNECTION_TIMEDOUT';
            }) ? true : false;
        }
    };

    return printerErrorOrders;
}]);
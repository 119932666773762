<tilby-table-element
    #tableElement
    tilbyGestures (longPress)="onLongPress()"
    class="tw-absolute"
    cdkDragBoundary=".room-boundary" cdkDrag
    (cdkDragEnded)="onDragEnd($event)"
    (cdkDragStarted)="onDragStart($event)"
    [iconsPath]="'assets/images/tables/icons/'"
    [shapesPath]="'assets/images/tables/shapes/'"
    [furnituresPath]="'assets/images/tables/furnitures/'"
    [billLockForEditMode]="billLockForEditMode()"
    [cdkDragDisabled]="!isEditMode()"
    [cdkDragFreeDragPosition]="defaultDragPosition"
    [class.example-box]="isEditMode()"
    [class.tw-cursor-pointer]="!isEditMode()"
    [color]="color()"
    [covers]="isTableMultiple ? (table.covers || 0) : (sale0?.covers || table.covers || 0)"
    [tableMultipleBusyCovers]="tableMultipleBusyCovers"
    [isTableMultipleOrSingleWithMultipleSales]="isTableMultiple"
    [exitsSent]="exitSents"
    [exitsTotalInUse]="maxExitsNumber"
    [finalAmount]="sale0?.final_amount"
    [minutes]="minutes"
    [ngClass]="{'tw-pointer-events-none tw-cursor-not-allowed':billLockForEditMode()}"
    [tableBookingWarning]="tableBookingWarning"
    [tableHanging]="tableHanging"
    [table]="table"
    [saleNameToShow]="isTableMultiple || (sale0?.name=='Comanda')?undefined:sale0?.name"
    [disabledToggleShowAmount]="disabledToggleShowAmount"
    (click)="onClick($event)"
></tilby-table-element>


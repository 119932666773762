import angular from 'angular';
import _ from 'lodash';

angular.module('digitalPayments').factory('ingenico17', ingenico17);

ingenico17.$inject = ["$q", "$rootScope", "waitDialog", "$interval", "$translate", "checkManager", "Ingenico17Driver"];

function ingenico17($q, $rootScope, waitDialog, $interval, $translate, checkManager, Ingenico17Driver) {
    var getDriverMessage = function(message) {
        switch(message) {
            case "CONN_TIMEDOUT":
            case "CONNECTION_ERROR":
            case "PAY_TIMEDOUT":
                return $translate.instant('DIGITAL_PAYMENTS.INGENICO_17.' + message);
            default:
                return null;
        }
    };

    var ingenico = {
        payment: function(amount, options) {
            let paymentMethod = options.paymentMethod;

            var cancelPayment = function() {
                Ingenico17Driver.cancelPayment();
            };

            var d = $q.defer();

            if (!paymentMethod.bundle_name) {
                d.reject($translate.instant('DIGITAL_PAYMENTS.COMMON.BUNDLE_NAME_NOT_FOUND'));
                return d.promise;
            }

            var paymentTimeout = _.toInteger(checkManager.getPreference('cashregister.ingenico_17.payment_timeout')) || 60000;
            Ingenico17Driver.init(paymentMethod.bundle_name, paymentMethod.schema_name, { timeout: paymentTimeout });

            var dialogDefer = $q.defer();

            var state = {};

            var intervalPromise = $interval(function() {
                var pluginStatus = Ingenico17Driver.getStatus();

                switch(pluginStatus.status) {
                    case 'CONNECTING':
                        state.message = $translate.instant('DIGITAL_PAYMENTS.INGENICO_17.CONNECTING_POS');
                    break;
                    case 'PAYING':
                        var remainingTime =  _.floor((paymentTimeout - _.toInteger(new Date() - pluginStatus.connectedAt)) / 1000);

                        _.assign(state, {
                            message: $translate.instant('DIGITAL_PAYMENTS.INGENICO_17.COMPLETE_ON_POS'),
                            cancelAction: {
                                disabled: remainingTime > 0,
                                label: (remainingTime > 0) ? $translate.instant('DIGITAL_PAYMENTS.INGENICO_17.WAIT', { time: remainingTime }) : $translate.instant('DIGITAL_PAYMENTS.INGENICO_17.CANCEL_PAYMENT'),
                                callback: cancelPayment
                            }
                        });

                    break;
                    default:
                    break;
                }

                $rootScope.$broadcast("wait-dialog:update-state", state);
            }, 500, 150 , false);

            waitDialog.show({ message: $translate.instant('DIGITAL_PAYMENTS.INGENICO_17.CONNECTING_POS'), promise: dialogDefer.promise });

            Ingenico17Driver.performPayment(amount, function(result) {
                dialogDefer.resolve();
                // resolve to print and store sale on closed
                var returnObject = {
                    acquirer_id : result.acquirer_id,
                    acquirer_name : result.acquirer_name,
                    card_circuit_name: result.acquirer_name,
                    payment_data: result.receipt,
                    tail: result.receipt,
                    unclaimed: false
                };
                d.resolve(returnObject);
                $interval.cancel(intervalPromise);
            }, function(error) {
                dialogDefer.reject();
                $interval.cancel(intervalPromise);
                d.reject(error === 'USER_CANCELED' ? null : $translate.instant('DIGITAL_PAYMENTS.INGENICO_17.ERROR_DURING_PAYMENTS', {value: (getDriverMessage(error) || error) }));
            });
            return d.promise;
        }
    };

    return ingenico;
}
import { 
    Directive, 
    ElementRef, 
    Injector, 
} from '@angular/core';

import {UpgradeComponent} from "@angular/upgrade/static";
import angular from 'angular';

// AngularJs component con ui-view (per utilizzare ui-router)
class RouterOutletWrapper {}

let routerOutletWrapperTwo = {
    controller: RouterOutletWrapper,
    template: `<div class="ui-view" name="two" style="height:100%"/>`
};

angular
    .module('TilbyApp')
    .component('routerOutletWrapperTwo', routerOutletWrapperTwo);

// UpgradeComponent per Angular con ui-view Upgradato (per utilizzare ui-router in contesto Angular)
@Directive({
    standalone:true,
    selector: '[appUpgradeUiViewTwo]',
    host: {style:  'height: 100%'}
})
export class UpgradeUiViewTwoDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef, injector: Injector) {
        super('routerOutletWrapperTwo', elementRef, injector);
    }
}
import angular from 'angular';

angular.module('settings').directive('topbarSettings', function() {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            context: "="
        },
        template: require('./topbar-settings.html'),
        controller: ["$scope", "$element", "$attrs", "$mdSidenav", "connection", "offlineDialog", function($scope, $element, $attrs, $mdSidenav, connection, offlineDialog) {
            $scope.isConnectionOffline = function() {
                return connection.isOffline();
            };

            $scope.openOfflineDialog = function() {
                offlineDialog.show();
            };

            $scope.openLeftMenu = function() {
                $mdSidenav('left').toggle();
            };
        }]
    };
});

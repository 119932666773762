import angular from 'angular';

angular.module("application").constant("fontFamily", [
    {
        "category": "Sans Serif",
        "family": "ABeeZee"
    },
    {
        "category": "Sans Serif",
        "family": "Abel"
    },
    {
        "category": "Serif",
        "family": "Abhaya Libre"
    },
    {
        "category": "Display",
        "family": "Abril Fatface"
    },
    {
        "category": "Sans Serif",
        "family": "Aclonica"
    },
    {
        "category": "Sans Serif",
        "family": "Acme"
    },
    {
        "category": "Sans Serif",
        "family": "Actor"
    },
    {
        "category": "Serif",
        "family": "Adamina"
    },
    {
        "category": "Sans Serif",
        "family": "Advent Pro"
    },
    {
        "category": "Handwriting",
        "family": "Aguafina Script"
    },
    {
        "category": "Display",
        "family": "Akaya Kanadaka"
    },
    {
        "category": "Display",
        "family": "Akaya Telivigala"
    },
    {
        "category": "Display",
        "family": "Akronim"
    },
    {
        "category": "Handwriting",
        "family": "Aladin"
    },
    {
        "category": "Sans Serif",
        "family": "Alata"
    },
    {
        "category": "Sans Serif",
        "family": "Alatsi"
    },
    {
        "category": "Sans Serif",
        "family": "Aldrich"
    },
    {
        "category": "Sans Serif",
        "family": "Alef"
    },
    {
        "category": "Serif",
        "family": "Alegreya"
    },
    {
        "category": "Serif",
        "family": "Alegreya SC"
    },
    {
        "category": "Sans Serif",
        "family": "Alegreya Sans"
    },
    {
        "category": "Sans Serif",
        "family": "Alegreya Sans SC"
    },
    {
        "category": "Serif",
        "family": "Aleo"
    },
    {
        "category": "Handwriting",
        "family": "Alex Brush"
    },
    {
        "category": "Display",
        "family": "Alfa Slab One"
    },
    {
        "category": "Serif",
        "family": "Alice"
    },
    {
        "category": "Serif",
        "family": "Alike"
    },
    {
        "category": "Serif",
        "family": "Alike Angular"
    },
    {
        "category": "Display",
        "family": "Allan"
    },
    {
        "category": "Sans Serif",
        "family": "Allerta"
    },
    {
        "category": "Sans Serif",
        "family": "Allerta Stencil"
    },
    {
        "category": "Handwriting",
        "family": "Allison"
    },
    {
        "category": "Handwriting",
        "family": "Allura"
    },
    {
        "category": "Sans Serif",
        "family": "Almarai"
    },
    {
        "category": "Serif",
        "family": "Almendra"
    },
    {
        "category": "Display",
        "family": "Almendra Display"
    },
    {
        "category": "Serif",
        "family": "Almendra SC"
    },
    {
        "category": "Sans Serif",
        "family": "Alumni Sans"
    },
    {
        "category": "Display",
        "family": "Amarante"
    },
    {
        "category": "Sans Serif",
        "family": "Amaranth"
    },
    {
        "category": "Handwriting",
        "family": "Amatic SC"
    },
    {
        "category": "Serif",
        "family": "Amethysta"
    },
    {
        "category": "Sans Serif",
        "family": "Amiko"
    },
    {
        "category": "Serif",
        "family": "Amiri"
    },
    {
        "category": "Handwriting",
        "family": "Amita"
    },
    {
        "category": "Sans Serif",
        "family": "Anaheim"
    },
    {
        "category": "Serif",
        "family": "Andada Pro"
    },
    {
        "category": "Sans Serif",
        "family": "Andika"
    },
    {
        "category": "Sans Serif",
        "family": "Andika New Basic"
    },
    {
        "category": "Display",
        "family": "Angkor"
    },
    {
        "category": "Handwriting",
        "family": "Annie Use Your Telescope"
    },
    {
        "category": "Monospace",
        "family": "Anonymous Pro"
    },
    {
        "category": "Sans Serif",
        "family": "Antic"
    },
    {
        "category": "Serif",
        "family": "Antic Didone"
    },
    {
        "category": "Serif",
        "family": "Antic Slab"
    },
    {
        "category": "Sans Serif",
        "family": "Anton"
    },
    {
        "category": "Sans Serif",
        "family": "Antonio"
    },
    {
        "category": "Serif",
        "family": "Arapey"
    },
    {
        "category": "Display",
        "family": "Arbutus"
    },
    {
        "category": "Serif",
        "family": "Arbutus Slab"
    },
    {
        "category": "Handwriting",
        "family": "Architects Daughter"
    },
    {
        "category": "Sans Serif",
        "family": "Archivo"
    },
    {
        "category": "Sans Serif",
        "family": "Archivo Black"
    },
    {
        "category": "Sans Serif",
        "family": "Archivo Narrow"
    },
    {
        "category": "Serif",
        "family": "Aref Ruqaa"
    },
    {
        "category": "Display",
        "family": "Arima Madurai"
    },
    {
        "category": "Sans Serif",
        "family": "Arimo"
    },
    {
        "category": "Handwriting",
        "family": "Arizonia"
    },
    {
        "category": "Sans Serif",
        "family": "Armata"
    },
    {
        "category": "Sans Serif",
        "family": "Arsenal"
    },
    {
        "category": "Serif",
        "family": "Artifika"
    },
    {
        "category": "Serif",
        "family": "Arvo"
    },
    {
        "category": "Sans Serif",
        "family": "Arya"
    },
    {
        "category": "Sans Serif",
        "family": "Asap"
    },
    {
        "category": "Sans Serif",
        "family": "Asap Condensed"
    },
    {
        "category": "Serif",
        "family": "Asar"
    },
    {
        "category": "Display",
        "family": "Asset"
    },
    {
        "category": "Sans Serif",
        "family": "Assistant"
    },
    {
        "category": "Display",
        "family": "Astloch"
    },
    {
        "category": "Sans Serif",
        "family": "Asul"
    },
    {
        "category": "Sans Serif",
        "family": "Athiti"
    },
    {
        "category": "Sans Serif",
        "family": "Atkinson Hyperlegible"
    },
    {
        "category": "Display",
        "family": "Atma"
    },
    {
        "category": "Display",
        "family": "Atomic Age"
    },
    {
        "category": "Display",
        "family": "Aubrey"
    },
    {
        "category": "Display",
        "family": "Audiowide"
    },
    {
        "category": "Display",
        "family": "Autour One"
    },
    {
        "category": "Serif",
        "family": "Average"
    },
    {
        "category": "Sans Serif",
        "family": "Average Sans"
    },
    {
        "category": "Display",
        "family": "Averia Gruesa Libre"
    },
    {
        "category": "Display",
        "family": "Averia Libre"
    },
    {
        "category": "Display",
        "family": "Averia Sans Libre"
    },
    {
        "category": "Display",
        "family": "Averia Serif Libre"
    },
    {
        "category": "Monospace",
        "family": "Azeret Mono"
    },
    {
        "category": "Sans Serif",
        "family": "B612"
    },
    {
        "category": "Monospace",
        "family": "B612 Mono"
    },
    {
        "category": "Handwriting",
        "family": "Bad Script"
    },
    {
        "category": "Display",
        "family": "Bahiana"
    },
    {
        "category": "Display",
        "family": "Bahianita"
    },
    {
        "category": "Sans Serif",
        "family": "Bai Jamjuree"
    },
    {
        "category": "Handwriting",
        "family": "Ballet"
    },
    {
        "category": "Display",
        "family": "Baloo 2"
    },
    {
        "category": "Display",
        "family": "Baloo Bhai 2"
    },
    {
        "category": "Display",
        "family": "Baloo Bhaina 2"
    },
    {
        "category": "Display",
        "family": "Baloo Chettan 2"
    },
    {
        "category": "Display",
        "family": "Baloo Da 2"
    },
    {
        "category": "Display",
        "family": "Baloo Paaji 2"
    },
    {
        "category": "Display",
        "family": "Baloo Tamma 2"
    },
    {
        "category": "Display",
        "family": "Baloo Tammudu 2"
    },
    {
        "category": "Display",
        "family": "Baloo Thambi 2"
    },
    {
        "category": "Display",
        "family": "Balsamiq Sans"
    },
    {
        "category": "Serif",
        "family": "Balthazar"
    },
    {
        "category": "Display",
        "family": "Bangers"
    },
    {
        "category": "Sans Serif",
        "family": "Barlow"
    },
    {
        "category": "Sans Serif",
        "family": "Barlow Condensed"
    },
    {
        "category": "Sans Serif",
        "family": "Barlow Semi Condensed"
    },
    {
        "category": "Display",
        "family": "Barriecito"
    },
    {
        "category": "Display",
        "family": "Barrio"
    },
    {
        "category": "Sans Serif",
        "family": "Basic"
    },
    {
        "category": "Serif",
        "family": "Baskervville"
    },
    {
        "category": "Display",
        "family": "Battambang"
    },
    {
        "category": "Display",
        "family": "Baumans"
    },
    {
        "category": "Display",
        "family": "Bayon"
    },
    {
        "category": "Sans Serif",
        "family": "Be Vietnam"
    },
    {
        "category": "Display",
        "family": "Bebas Neue"
    },
    {
        "category": "Serif",
        "family": "Belgrano"
    },
    {
        "category": "Serif",
        "family": "Bellefair"
    },
    {
        "category": "Sans Serif",
        "family": "Belleza"
    },
    {
        "category": "Display",
        "family": "Bellota"
    },
    {
        "category": "Display",
        "family": "Bellota Text"
    },
    {
        "category": "Sans Serif",
        "family": "BenchNine"
    },
    {
        "category": "Serif",
        "family": "Benne"
    },
    {
        "category": "Serif",
        "family": "Bentham"
    },
    {
        "category": "Handwriting",
        "family": "Berkshire Swash"
    },
    {
        "category": "Serif",
        "family": "Besley"
    },
    {
        "category": "Handwriting",
        "family": "Beth Ellen"
    },
    {
        "category": "Display",
        "family": "Bevan"
    },
    {
        "category": "Display",
        "family": "Big Shoulders Display"
    },
    {
        "category": "Display",
        "family": "Big Shoulders Inline Display"
    },
    {
        "category": "Display",
        "family": "Big Shoulders Inline Text"
    },
    {
        "category": "Display",
        "family": "Big Shoulders Stencil Display"
    },
    {
        "category": "Display",
        "family": "Big Shoulders Stencil Text"
    },
    {
        "category": "Display",
        "family": "Big Shoulders Text"
    },
    {
        "category": "Display",
        "family": "Bigelow Rules"
    },
    {
        "category": "Display",
        "family": "Bigshot One"
    },
    {
        "category": "Handwriting",
        "family": "Bilbo"
    },
    {
        "category": "Handwriting",
        "family": "Bilbo Swash Caps"
    },
    {
        "category": "Serif",
        "family": "BioRhyme"
    },
    {
        "category": "Serif",
        "family": "BioRhyme Expanded"
    },
    {
        "category": "Sans Serif",
        "family": "Biryani"
    },
    {
        "category": "Serif",
        "family": "Bitter"
    },
    {
        "category": "Sans Serif",
        "family": "Black And White Picture"
    },
    {
        "category": "Sans Serif",
        "family": "Black Han Sans"
    },
    {
        "category": "Display",
        "family": "Black Ops One"
    },
    {
        "category": "Sans Serif",
        "family": "Blinker"
    },
    {
        "category": "Serif",
        "family": "Bodoni Moda"
    },
    {
        "category": "Display",
        "family": "Bokor"
    },
    {
        "category": "Serif",
        "family": "Bona Nova"
    },
    {
        "category": "Handwriting",
        "family": "Bonbon"
    },
    {
        "category": "Display",
        "family": "Boogaloo"
    },
    {
        "category": "Display",
        "family": "Bowlby One"
    },
    {
        "category": "Display",
        "family": "Bowlby One SC"
    },
    {
        "category": "Serif",
        "family": "Brawler"
    },
    {
        "category": "Serif",
        "family": "Bree Serif"
    },
    {
        "category": "Serif",
        "family": "Brygada 1918"
    },
    {
        "category": "Display",
        "family": "Bubblegum Sans"
    },
    {
        "category": "Sans Serif",
        "family": "Bubbler One"
    },
    {
        "category": "Display",
        "family": "Buda"
    },
    {
        "category": "Serif",
        "family": "Buenard"
    },
    {
        "category": "Display",
        "family": "Bungee"
    },
    {
        "category": "Display",
        "family": "Bungee Hairline"
    },
    {
        "category": "Display",
        "family": "Bungee Inline"
    },
    {
        "category": "Display",
        "family": "Bungee Outline"
    },
    {
        "category": "Display",
        "family": "Bungee Shade"
    },
    {
        "category": "Display",
        "family": "Butcherman"
    },
    {
        "category": "Handwriting",
        "family": "Butterfly Kids"
    },
    {
        "category": "Sans Serif",
        "family": "Cabin"
    },
    {
        "category": "Sans Serif",
        "family": "Cabin Condensed"
    },
    {
        "category": "Display",
        "family": "Cabin Sketch"
    },
    {
        "category": "Display",
        "family": "Caesar Dressing"
    },
    {
        "category": "Sans Serif",
        "family": "Cagliostro"
    },
    {
        "category": "Sans Serif",
        "family": "Cairo"
    },
    {
        "category": "Serif",
        "family": "Caladea"
    },
    {
        "category": "Display",
        "family": "Calistoga"
    },
    {
        "category": "Handwriting",
        "family": "Calligraffitti"
    },
    {
        "category": "Sans Serif",
        "family": "Cambay"
    },
    {
        "category": "Serif",
        "family": "Cambo"
    },
    {
        "category": "Sans Serif",
        "family": "Candal"
    },
    {
        "category": "Sans Serif",
        "family": "Cantarell"
    },
    {
        "category": "Serif",
        "family": "Cantata One"
    },
    {
        "category": "Sans Serif",
        "family": "Cantora One"
    },
    {
        "category": "Sans Serif",
        "family": "Capriola"
    },
    {
        "category": "Serif",
        "family": "Cardo"
    },
    {
        "category": "Sans Serif",
        "family": "Carme"
    },
    {
        "category": "Sans Serif",
        "family": "Carrois Gothic"
    },
    {
        "category": "Sans Serif",
        "family": "Carrois Gothic SC"
    },
    {
        "category": "Display",
        "family": "Carter One"
    },
    {
        "category": "Serif",
        "family": "Castoro"
    },
    {
        "category": "Sans Serif",
        "family": "Catamaran"
    },
    {
        "category": "Serif",
        "family": "Caudex"
    },
    {
        "category": "Handwriting",
        "family": "Caveat"
    },
    {
        "category": "Handwriting",
        "family": "Caveat Brush"
    },
    {
        "category": "Handwriting",
        "family": "Cedarville Cursive"
    },
    {
        "category": "Display",
        "family": "Ceviche One"
    },
    {
        "category": "Sans Serif",
        "family": "Chakra Petch"
    },
    {
        "category": "Sans Serif",
        "family": "Changa"
    },
    {
        "category": "Display",
        "family": "Changa One"
    },
    {
        "category": "Display",
        "family": "Chango"
    },
    {
        "category": "Handwriting",
        "family": "Charm"
    },
    {
        "category": "Handwriting",
        "family": "Charmonman"
    },
    {
        "category": "Sans Serif",
        "family": "Chathura"
    },
    {
        "category": "Sans Serif",
        "family": "Chau Philomene One"
    },
    {
        "category": "Display",
        "family": "Chela One"
    },
    {
        "category": "Display",
        "family": "Chelsea Market"
    },
    {
        "category": "Display",
        "family": "Chenla"
    },
    {
        "category": "Display",
        "family": "Cherry Cream Soda"
    },
    {
        "category": "Display",
        "family": "Cherry Swash"
    },
    {
        "category": "Display",
        "family": "Chewy"
    },
    {
        "category": "Display",
        "family": "Chicle"
    },
    {
        "category": "Handwriting",
        "family": "Chilanka"
    },
    {
        "category": "Sans Serif",
        "family": "Chivo"
    },
    {
        "category": "Display",
        "family": "Chonburi"
    },
    {
        "category": "Serif",
        "family": "Cinzel"
    },
    {
        "category": "Display",
        "family": "Cinzel Decorative"
    },
    {
        "category": "Handwriting",
        "family": "Clicker Script"
    },
    {
        "category": "Display",
        "family": "Coda"
    },
    {
        "category": "Sans Serif",
        "family": "Coda Caption"
    },
    {
        "category": "Display",
        "family": "Codystar"
    },
    {
        "category": "Display",
        "family": "Coiny"
    },
    {
        "category": "Display",
        "family": "Combo"
    },
    {
        "category": "Display",
        "family": "Comfortaa"
    },
    {
        "category": "Handwriting",
        "family": "Comic Neue"
    },
    {
        "category": "Handwriting",
        "family": "Coming Soon"
    },
    {
        "category": "Sans Serif",
        "family": "Commissioner"
    },
    {
        "category": "Display",
        "family": "Concert One"
    },
    {
        "category": "Handwriting",
        "family": "Condiment"
    },
    {
        "category": "Display",
        "family": "Content"
    },
    {
        "category": "Display",
        "family": "Contrail One"
    },
    {
        "category": "Sans Serif",
        "family": "Convergence"
    },
    {
        "category": "Handwriting",
        "family": "Cookie"
    },
    {
        "category": "Serif",
        "family": "Copse"
    },
    {
        "category": "Display",
        "family": "Corben"
    },
    {
        "category": "Serif",
        "family": "Cormorant"
    },
    {
        "category": "Serif",
        "family": "Cormorant Garamond"
    },
    {
        "category": "Serif",
        "family": "Cormorant Infant"
    },
    {
        "category": "Serif",
        "family": "Cormorant SC"
    },
    {
        "category": "Serif",
        "family": "Cormorant Unicase"
    },
    {
        "category": "Serif",
        "family": "Cormorant Upright"
    },
    {
        "category": "Handwriting",
        "family": "Courgette"
    },
    {
        "category": "Monospace",
        "family": "Courier Prime"
    },
    {
        "category": "Monospace",
        "family": "Cousine"
    },
    {
        "category": "Serif",
        "family": "Coustard"
    },
    {
        "category": "Handwriting",
        "family": "Covered By Your Grace"
    },
    {
        "category": "Handwriting",
        "family": "Crafty Girls"
    },
    {
        "category": "Display",
        "family": "Creepster"
    },
    {
        "category": "Serif",
        "family": "Crete Round"
    },
    {
        "category": "Serif",
        "family": "Crimson Pro"
    },
    {
        "category": "Serif",
        "family": "Crimson Text"
    },
    {
        "category": "Display",
        "family": "Croissant One"
    },
    {
        "category": "Display",
        "family": "Crushed"
    },
    {
        "category": "Sans Serif",
        "family": "Cuprum"
    },
    {
        "category": "Display",
        "family": "Cute Font"
    },
    {
        "category": "Serif",
        "family": "Cutive"
    },
    {
        "category": "Monospace",
        "family": "Cutive Mono"
    },
    {
        "category": "Monospace",
        "family": "DM Mono"
    },
    {
        "category": "Sans Serif",
        "family": "DM Sans"
    },
    {
        "category": "Serif",
        "family": "DM Serif Display"
    },
    {
        "category": "Serif",
        "family": "DM Serif Text"
    },
    {
        "category": "Handwriting",
        "family": "Damion"
    },
    {
        "category": "Handwriting",
        "family": "Dancing Script"
    },
    {
        "category": "Display",
        "family": "Dangrek"
    },
    {
        "category": "Sans Serif",
        "family": "Darker Grotesque"
    },
    {
        "category": "Serif",
        "family": "David Libre"
    },
    {
        "category": "Handwriting",
        "family": "Dawning of a New Day"
    },
    {
        "category": "Sans Serif",
        "family": "Days One"
    },
    {
        "category": "Handwriting",
        "family": "Dekko"
    },
    {
        "category": "Display",
        "family": "Dela Gothic One"
    },
    {
        "category": "Handwriting",
        "family": "Delius"
    },
    {
        "category": "Handwriting",
        "family": "Delius Swash Caps"
    },
    {
        "category": "Handwriting",
        "family": "Delius Unicase"
    },
    {
        "category": "Serif",
        "family": "Della Respira"
    },
    {
        "category": "Sans Serif",
        "family": "Denk One"
    },
    {
        "category": "Handwriting",
        "family": "Devonshire"
    },
    {
        "category": "Sans Serif",
        "family": "Dhurjati"
    },
    {
        "category": "Sans Serif",
        "family": "Didact Gothic"
    },
    {
        "category": "Display",
        "family": "Diplomata"
    },
    {
        "category": "Display",
        "family": "Diplomata SC"
    },
    {
        "category": "Sans Serif",
        "family": "Do Hyeon"
    },
    {
        "category": "Handwriting",
        "family": "Dokdo"
    },
    {
        "category": "Serif",
        "family": "Domine"
    },
    {
        "category": "Serif",
        "family": "Donegal One"
    },
    {
        "category": "Sans Serif",
        "family": "Doppio One"
    },
    {
        "category": "Sans Serif",
        "family": "Dorsa"
    },
    {
        "category": "Sans Serif",
        "family": "Dosis"
    },
    {
        "category": "Sans Serif",
        "family": "DotGothic16"
    },
    {
        "category": "Handwriting",
        "family": "Dr Sugiyama"
    },
    {
        "category": "Sans Serif",
        "family": "Duru Sans"
    },
    {
        "category": "Display",
        "family": "Dynalight"
    },
    {
        "category": "Serif",
        "family": "EB Garamond"
    },
    {
        "category": "Handwriting",
        "family": "Eagle Lake"
    },
    {
        "category": "Handwriting",
        "family": "East Sea Dokdo"
    },
    {
        "category": "Display",
        "family": "Eater"
    },
    {
        "category": "Sans Serif",
        "family": "Economica"
    },
    {
        "category": "Serif",
        "family": "Eczar"
    },
    {
        "category": "Sans Serif",
        "family": "El Messiri"
    },
    {
        "category": "Sans Serif",
        "family": "Electrolize"
    },
    {
        "category": "Display",
        "family": "Elsie"
    },
    {
        "category": "Display",
        "family": "Elsie Swash Caps"
    },
    {
        "category": "Display",
        "family": "Emblema One"
    },
    {
        "category": "Display",
        "family": "Emilys Candy"
    },
    {
        "category": "Sans Serif",
        "family": "Encode Sans"
    },
    {
        "category": "Sans Serif",
        "family": "Encode Sans Condensed"
    },
    {
        "category": "Sans Serif",
        "family": "Encode Sans Expanded"
    },
    {
        "category": "Sans Serif",
        "family": "Encode Sans SC"
    },
    {
        "category": "Sans Serif",
        "family": "Encode Sans Semi Condensed"
    },
    {
        "category": "Sans Serif",
        "family": "Encode Sans Semi Expanded"
    },
    {
        "category": "Handwriting",
        "family": "Engagement"
    },
    {
        "category": "Sans Serif",
        "family": "Englebert"
    },
    {
        "category": "Serif",
        "family": "Enriqueta"
    },
    {
        "category": "Sans Serif",
        "family": "Epilogue"
    },
    {
        "category": "Display",
        "family": "Erica One"
    },
    {
        "category": "Serif",
        "family": "Esteban"
    },
    {
        "category": "Handwriting",
        "family": "Euphoria Script"
    },
    {
        "category": "Display",
        "family": "Ewert"
    },
    {
        "category": "Sans Serif",
        "family": "Exo"
    },
    {
        "category": "Sans Serif",
        "family": "Exo 2"
    },
    {
        "category": "Display",
        "family": "Expletus Sans"
    },
    {
        "category": "Sans Serif",
        "family": "Fahkwang"
    },
    {
        "category": "Serif",
        "family": "Fanwood Text"
    },
    {
        "category": "Sans Serif",
        "family": "Farro"
    },
    {
        "category": "Display",
        "family": "Farsan"
    },
    {
        "category": "Display",
        "family": "Fascinate"
    },
    {
        "category": "Display",
        "family": "Fascinate Inline"
    },
    {
        "category": "Display",
        "family": "Faster One"
    },
    {
        "category": "Serif",
        "family": "Fasthand"
    },
    {
        "category": "Serif",
        "family": "Fauna One"
    },
    {
        "category": "Serif",
        "family": "Faustina"
    },
    {
        "category": "Display",
        "family": "Federant"
    },
    {
        "category": "Sans Serif",
        "family": "Federo"
    },
    {
        "category": "Handwriting",
        "family": "Felipa"
    },
    {
        "category": "Serif",
        "family": "Fenix"
    },
    {
        "category": "Handwriting",
        "family": "Festive"
    },
    {
        "category": "Display",
        "family": "Finger Paint"
    },
    {
        "category": "Monospace",
        "family": "Fira Code"
    },
    {
        "category": "Monospace",
        "family": "Fira Mono"
    },
    {
        "category": "Sans Serif",
        "family": "Fira Sans"
    },
    {
        "category": "Sans Serif",
        "family": "Fira Sans Condensed"
    },
    {
        "category": "Sans Serif",
        "family": "Fira Sans Extra Condensed"
    },
    {
        "category": "Sans Serif",
        "family": "Fjalla One"
    },
    {
        "category": "Serif",
        "family": "Fjord One"
    },
    {
        "category": "Display",
        "family": "Flamenco"
    },
    {
        "category": "Display",
        "family": "Flavors"
    },
    {
        "category": "Handwriting",
        "family": "Fondamento"
    },
    {
        "category": "Display",
        "family": "Fontdiner Swanky"
    },
    {
        "category": "Display",
        "family": "Forum"
    },
    {
        "category": "Sans Serif",
        "family": "Francois One"
    },
    {
        "category": "Serif",
        "family": "Frank Ruhl Libre"
    },
    {
        "category": "Serif",
        "family": "Fraunces"
    },
    {
        "category": "Display",
        "family": "Freckle Face"
    },
    {
        "category": "Display",
        "family": "Fredericka the Great"
    },
    {
        "category": "Display",
        "family": "Fredoka One"
    },
    {
        "category": "Display",
        "family": "Freehand"
    },
    {
        "category": "Sans Serif",
        "family": "Fresca"
    },
    {
        "category": "Display",
        "family": "Frijole"
    },
    {
        "category": "Display",
        "family": "Fruktur"
    },
    {
        "category": "Display",
        "family": "Fugaz One"
    },
    {
        "category": "Handwriting",
        "family": "Fuggles"
    },
    {
        "category": "Serif",
        "family": "GFS Didot"
    },
    {
        "category": "Sans Serif",
        "family": "GFS Neohellenic"
    },
    {
        "category": "Serif",
        "family": "Gabriela"
    },
    {
        "category": "Handwriting",
        "family": "Gaegu"
    },
    {
        "category": "Sans Serif",
        "family": "Gafata"
    },
    {
        "category": "Display",
        "family": "Galada"
    },
    {
        "category": "Sans Serif",
        "family": "Galdeano"
    },
    {
        "category": "Display",
        "family": "Galindo"
    },
    {
        "category": "Handwriting",
        "family": "Gamja Flower"
    },
    {
        "category": "Sans Serif",
        "family": "Gayathri"
    },
    {
        "category": "Serif",
        "family": "Gelasio"
    },
    {
        "category": "Serif",
        "family": "Gentium Basic"
    },
    {
        "category": "Serif",
        "family": "Gentium Book Basic"
    },
    {
        "category": "Sans Serif",
        "family": "Geo"
    },
    {
        "category": "Sans Serif",
        "family": "Georama"
    },
    {
        "category": "Display",
        "family": "Geostar"
    },
    {
        "category": "Display",
        "family": "Geostar Fill"
    },
    {
        "category": "Display",
        "family": "Germania One"
    },
    {
        "category": "Sans Serif",
        "family": "Gidugu"
    },
    {
        "category": "Serif",
        "family": "Gilda Display"
    },
    {
        "category": "Display",
        "family": "Girassol"
    },
    {
        "category": "Handwriting",
        "family": "Give You Glory"
    },
    {
        "category": "Display",
        "family": "Glass Antiqua"
    },
    {
        "category": "Serif",
        "family": "Glegoo"
    },
    {
        "category": "Handwriting",
        "family": "Gloria Hallelujah"
    },
    {
        "category": "Sans Serif",
        "family": "Glory"
    },
    {
        "category": "Display",
        "family": "Goblin One"
    },
    {
        "category": "Handwriting",
        "family": "Gochi Hand"
    },
    {
        "category": "Display",
        "family": "Goldman"
    },
    {
        "category": "Display",
        "family": "Gorditas"
    },
    {
        "category": "Sans Serif",
        "family": "Gothic A1"
    },
    {
        "category": "Sans Serif",
        "family": "Gotu"
    },
    {
        "category": "Serif",
        "family": "Goudy Bookletter 1911"
    },
    {
        "category": "Serif",
        "family": "Gowun Batang"
    },
    {
        "category": "Sans Serif",
        "family": "Gowun Dodum"
    },
    {
        "category": "Display",
        "family": "Graduate"
    },
    {
        "category": "Handwriting",
        "family": "Grand Hotel"
    },
    {
        "category": "Display",
        "family": "Grandstander"
    },
    {
        "category": "Display",
        "family": "Gravitas One"
    },
    {
        "category": "Handwriting",
        "family": "Great Vibes"
    },
    {
        "category": "Serif",
        "family": "Grenze"
    },
    {
        "category": "Display",
        "family": "Grenze Gotisch"
    },
    {
        "category": "Display",
        "family": "Griffy"
    },
    {
        "category": "Display",
        "family": "Gruppo"
    },
    {
        "category": "Sans Serif",
        "family": "Gudea"
    },
    {
        "category": "Display",
        "family": "Gugi"
    },
    {
        "category": "Serif",
        "family": "Gupter"
    },
    {
        "category": "Serif",
        "family": "Gurajada"
    },
    {
        "category": "Serif",
        "family": "Habibi"
    },
    {
        "category": "Handwriting",
        "family": "Hachi Maru Pop"
    },
    {
        "category": "Serif",
        "family": "Hahmlet"
    },
    {
        "category": "Serif",
        "family": "Halant"
    },
    {
        "category": "Sans Serif",
        "family": "Hammersmith One"
    },
    {
        "category": "Display",
        "family": "Hanalei"
    },
    {
        "category": "Display",
        "family": "Hanalei Fill"
    },
    {
        "category": "Handwriting",
        "family": "Handlee"
    },
    {
        "category": "Serif",
        "family": "Hanuman"
    },
    {
        "category": "Display",
        "family": "Happy Monkey"
    },
    {
        "category": "Sans Serif",
        "family": "Harmattan"
    },
    {
        "category": "Serif",
        "family": "Headland One"
    },
    {
        "category": "Sans Serif",
        "family": "Heebo"
    },
    {
        "category": "Display",
        "family": "Henny Penny"
    },
    {
        "category": "Serif",
        "family": "Hepta Slab"
    },
    {
        "category": "Handwriting",
        "family": "Herr Von Muellerhoff"
    },
    {
        "category": "Handwriting",
        "family": "Hi Melody"
    },
    {
        "category": "Serif",
        "family": "Hina Mincho"
    },
    {
        "category": "Sans Serif",
        "family": "Hind"
    },
    {
        "category": "Sans Serif",
        "family": "Hind Guntur"
    },
    {
        "category": "Sans Serif",
        "family": "Hind Madurai"
    },
    {
        "category": "Sans Serif",
        "family": "Hind Siliguri"
    },
    {
        "category": "Sans Serif",
        "family": "Hind Vadodara"
    },
    {
        "category": "Serif",
        "family": "Holtwood One SC"
    },
    {
        "category": "Handwriting",
        "family": "Homemade Apple"
    },
    {
        "category": "Sans Serif",
        "family": "Homenaje"
    },
    {
        "category": "Monospace",
        "family": "IBM Plex Mono"
    },
    {
        "category": "Sans Serif",
        "family": "IBM Plex Sans"
    },
    {
        "category": "Sans Serif",
        "family": "IBM Plex Sans Arabic"
    },
    {
        "category": "Sans Serif",
        "family": "IBM Plex Sans Condensed"
    },
    {
        "category": "Sans Serif",
        "family": "IBM Plex Sans Devanagari"
    },
    {
        "category": "Sans Serif",
        "family": "IBM Plex Sans Hebrew"
    },
    {
        "category": "Sans Serif",
        "family": "IBM Plex Sans KR"
    },
    {
        "category": "Sans Serif",
        "family": "IBM Plex Sans Thai"
    },
    {
        "category": "Sans Serif",
        "family": "IBM Plex Sans Thai Looped"
    },
    {
        "category": "Serif",
        "family": "IBM Plex Serif"
    },
    {
        "category": "Serif",
        "family": "IM Fell DW Pica"
    },
    {
        "category": "Serif",
        "family": "IM Fell DW Pica SC"
    },
    {
        "category": "Serif",
        "family": "IM Fell Double Pica"
    },
    {
        "category": "Serif",
        "family": "IM Fell Double Pica SC"
    },
    {
        "category": "Serif",
        "family": "IM Fell English"
    },
    {
        "category": "Serif",
        "family": "IM Fell English SC"
    },
    {
        "category": "Serif",
        "family": "IM Fell French Canon"
    },
    {
        "category": "Serif",
        "family": "IM Fell French Canon SC"
    },
    {
        "category": "Serif",
        "family": "IM Fell Great Primer"
    },
    {
        "category": "Serif",
        "family": "IM Fell Great Primer SC"
    },
    {
        "category": "Serif",
        "family": "Ibarra Real Nova"
    },
    {
        "category": "Display",
        "family": "Iceberg"
    },
    {
        "category": "Display",
        "family": "Iceland"
    },
    {
        "category": "Serif",
        "family": "Imbue"
    },
    {
        "category": "Sans Serif",
        "family": "Imprima"
    },
    {
        "category": "Monospace",
        "family": "Inconsolata"
    },
    {
        "category": "Sans Serif",
        "family": "Inder"
    },
    {
        "category": "Handwriting",
        "family": "Indie Flower"
    },
    {
        "category": "Serif",
        "family": "Inika"
    },
    {
        "category": "Serif",
        "family": "Inknut Antiqua"
    },
    {
        "category": "Sans Serif",
        "family": "Inria Sans"
    },
    {
        "category": "Serif",
        "family": "Inria Serif"
    },
    {
        "category": "Sans Serif",
        "family": "Inter"
    },
    {
        "category": "Display",
        "family": "Irish Grover"
    },
    {
        "category": "Sans Serif",
        "family": "Istok Web"
    },
    {
        "category": "Serif",
        "family": "Italiana"
    },
    {
        "category": "Handwriting",
        "family": "Italianno"
    },
    {
        "category": "Handwriting",
        "family": "Itim"
    },
    {
        "category": "Serif",
        "family": "Jacques Francois"
    },
    {
        "category": "Display",
        "family": "Jacques Francois Shadow"
    },
    {
        "category": "Sans Serif",
        "family": "Jaldi"
    },
    {
        "category": "Monospace",
        "family": "JetBrains Mono"
    },
    {
        "category": "Handwriting",
        "family": "Jim Nightshade"
    },
    {
        "category": "Sans Serif",
        "family": "Jockey One"
    },
    {
        "category": "Display",
        "family": "Jolly Lodger"
    },
    {
        "category": "Display",
        "family": "Jomhuria"
    },
    {
        "category": "Serif",
        "family": "Jomolhari"
    },
    {
        "category": "Sans Serif",
        "family": "Josefin Sans"
    },
    {
        "category": "Serif",
        "family": "Josefin Slab"
    },
    {
        "category": "Sans Serif",
        "family": "Jost"
    },
    {
        "category": "Display",
        "family": "Joti One"
    },
    {
        "category": "Sans Serif",
        "family": "Jua"
    },
    {
        "category": "Serif",
        "family": "Judson"
    },
    {
        "category": "Handwriting",
        "family": "Julee"
    },
    {
        "category": "Sans Serif",
        "family": "Julius Sans One"
    },
    {
        "category": "Serif",
        "family": "Junge"
    },
    {
        "category": "Sans Serif",
        "family": "Jura"
    },
    {
        "category": "Handwriting",
        "family": "Just Another Hand"
    },
    {
        "category": "Handwriting",
        "family": "Just Me Again Down Here"
    },
    {
        "category": "Sans Serif",
        "family": "K2D"
    },
    {
        "category": "Serif",
        "family": "Kadwa"
    },
    {
        "category": "Serif",
        "family": "Kaisei Decol"
    },
    {
        "category": "Serif",
        "family": "Kaisei HarunoUmi"
    },
    {
        "category": "Serif",
        "family": "Kaisei Opti"
    },
    {
        "category": "Serif",
        "family": "Kaisei Tokumin"
    },
    {
        "category": "Handwriting",
        "family": "Kalam"
    },
    {
        "category": "Serif",
        "family": "Kameron"
    },
    {
        "category": "Sans Serif",
        "family": "Kanit"
    },
    {
        "category": "Sans Serif",
        "family": "Kantumruy"
    },
    {
        "category": "Display",
        "family": "Karantina"
    },
    {
        "category": "Sans Serif",
        "family": "Karla"
    },
    {
        "category": "Serif",
        "family": "Karma"
    },
    {
        "category": "Display",
        "family": "Katibeh"
    },
    {
        "category": "Handwriting",
        "family": "Kaushan Script"
    },
    {
        "category": "Handwriting",
        "family": "Kavivanar"
    },
    {
        "category": "Display",
        "family": "Kavoon"
    },
    {
        "category": "Display",
        "family": "Kdam Thmor"
    },
    {
        "category": "Display",
        "family": "Keania One"
    },
    {
        "category": "Display",
        "family": "Kelly Slab"
    },
    {
        "category": "Display",
        "family": "Kenia"
    },
    {
        "category": "Sans Serif",
        "family": "Khand"
    },
    {
        "category": "Display",
        "family": "Khmer"
    },
    {
        "category": "Sans Serif",
        "family": "Khula"
    },
    {
        "category": "Display",
        "family": "Kirang Haerang"
    },
    {
        "category": "Sans Serif",
        "family": "Kite One"
    },
    {
        "category": "Serif",
        "family": "Kiwi Maru"
    },
    {
        "category": "Handwriting",
        "family": "Klee One"
    },
    {
        "category": "Display",
        "family": "Knewave"
    },
    {
        "category": "Sans Serif",
        "family": "KoHo"
    },
    {
        "category": "Sans Serif",
        "family": "Kodchasan"
    },
    {
        "category": "Sans Serif",
        "family": "Kosugi"
    },
    {
        "category": "Sans Serif",
        "family": "Kosugi Maru"
    },
    {
        "category": "Serif",
        "family": "Kotta One"
    },
    {
        "category": "Display",
        "family": "Koulen"
    },
    {
        "category": "Display",
        "family": "Kranky"
    },
    {
        "category": "Serif",
        "family": "Kreon"
    },
    {
        "category": "Handwriting",
        "family": "Kristi"
    },
    {
        "category": "Sans Serif",
        "family": "Krona One"
    },
    {
        "category": "Sans Serif",
        "family": "Krub"
    },
    {
        "category": "Sans Serif",
        "family": "Kufam"
    },
    {
        "category": "Sans Serif",
        "family": "Kulim Park"
    },
    {
        "category": "Display",
        "family": "Kumar One"
    },
    {
        "category": "Display",
        "family": "Kumar One Outline"
    },
    {
        "category": "Sans Serif",
        "family": "Kumbh Sans"
    },
    {
        "category": "Serif",
        "family": "Kurale"
    },
    {
        "category": "Handwriting",
        "family": "La Belle Aurore"
    },
    {
        "category": "Display",
        "family": "Lacquer"
    },
    {
        "category": "Sans Serif",
        "family": "Laila"
    },
    {
        "category": "Handwriting",
        "family": "Lakki Reddy"
    },
    {
        "category": "Display",
        "family": "Lalezar"
    },
    {
        "category": "Display",
        "family": "Lancelot"
    },
    {
        "category": "Display",
        "family": "Langar"
    },
    {
        "category": "Handwriting",
        "family": "Lateef"
    },
    {
        "category": "Sans Serif",
        "family": "Lato"
    },
    {
        "category": "Handwriting",
        "family": "League Script"
    },
    {
        "category": "Handwriting",
        "family": "Leckerli One"
    },
    {
        "category": "Serif",
        "family": "Ledger"
    },
    {
        "category": "Sans Serif",
        "family": "Lekton"
    },
    {
        "category": "Display",
        "family": "Lemon"
    },
    {
        "category": "Display",
        "family": "Lemonada"
    },
    {
        "category": "Sans Serif",
        "family": "Lexend"
    },
    {
        "category": "Sans Serif",
        "family": "Lexend Deca"
    },
    {
        "category": "Sans Serif",
        "family": "Lexend Exa"
    },
    {
        "category": "Sans Serif",
        "family": "Lexend Giga"
    },
    {
        "category": "Sans Serif",
        "family": "Lexend Mega"
    },
    {
        "category": "Sans Serif",
        "family": "Lexend Peta"
    },
    {
        "category": "Sans Serif",
        "family": "Lexend Tera"
    },
    {
        "category": "Sans Serif",
        "family": "Lexend Zetta"
    },
    {
        "category": "Display",
        "family": "Libre Barcode 128"
    },
    {
        "category": "Display",
        "family": "Libre Barcode 128 Text"
    },
    {
        "category": "Display",
        "family": "Libre Barcode 39"
    },
    {
        "category": "Display",
        "family": "Libre Barcode 39 Extended"
    },
    {
        "category": "Display",
        "family": "Libre Barcode 39 Extended Text"
    },
    {
        "category": "Display",
        "family": "Libre Barcode 39 Text"
    },
    {
        "category": "Display",
        "family": "Libre Barcode EAN13 Text"
    },
    {
        "category": "Serif",
        "family": "Libre Baskerville"
    },
    {
        "category": "Serif",
        "family": "Libre Caslon Display"
    },
    {
        "category": "Serif",
        "family": "Libre Caslon Text"
    },
    {
        "category": "Sans Serif",
        "family": "Libre Franklin"
    },
    {
        "category": "Display",
        "family": "Life Savers"
    },
    {
        "category": "Display",
        "family": "Lilita One"
    },
    {
        "category": "Display",
        "family": "Lily Script One"
    },
    {
        "category": "Display",
        "family": "Limelight"
    },
    {
        "category": "Serif",
        "family": "Linden Hill"
    },
    {
        "category": "Serif",
        "family": "Literata"
    },
    {
        "category": "Handwriting",
        "family": "Liu Jian Mao Cao"
    },
    {
        "category": "Sans Serif",
        "family": "Livvic"
    },
    {
        "category": "Display",
        "family": "Lobster"
    },
    {
        "category": "Display",
        "family": "Lobster Two"
    },
    {
        "category": "Display",
        "family": "Londrina Outline"
    },
    {
        "category": "Display",
        "family": "Londrina Shadow"
    },
    {
        "category": "Display",
        "family": "Londrina Sketch"
    },
    {
        "category": "Display",
        "family": "Londrina Solid"
    },
    {
        "category": "Handwriting",
        "family": "Long Cang"
    },
    {
        "category": "Serif",
        "family": "Lora"
    },
    {
        "category": "Display",
        "family": "Love Ya Like A Sister"
    },
    {
        "category": "Handwriting",
        "family": "Loved by the King"
    },
    {
        "category": "Handwriting",
        "family": "Lovers Quarrel"
    },
    {
        "category": "Display",
        "family": "Luckiest Guy"
    },
    {
        "category": "Serif",
        "family": "Lusitana"
    },
    {
        "category": "Serif",
        "family": "Lustria"
    },
    {
        "category": "Sans Serif",
        "family": "M PLUS 1p"
    },
    {
        "category": "Sans Serif",
        "family": "M PLUS Rounded 1c"
    },
    {
        "category": "Handwriting",
        "family": "Ma Shan Zheng"
    },
    {
        "category": "Display",
        "family": "Macondo"
    },
    {
        "category": "Display",
        "family": "Macondo Swash Caps"
    },
    {
        "category": "Sans Serif",
        "family": "Mada"
    },
    {
        "category": "Sans Serif",
        "family": "Magra"
    },
    {
        "category": "Display",
        "family": "Maiden Orange"
    },
    {
        "category": "Serif",
        "family": "Maitree"
    },
    {
        "category": "Monospace",
        "family": "Major Mono Display"
    },
    {
        "category": "Sans Serif",
        "family": "Mako"
    },
    {
        "category": "Handwriting",
        "family": "Mali"
    },
    {
        "category": "Sans Serif",
        "family": "Mallanna"
    },
    {
        "category": "Sans Serif",
        "family": "Mandali"
    },
    {
        "category": "Sans Serif",
        "family": "Manjari"
    },
    {
        "category": "Sans Serif",
        "family": "Manrope"
    },
    {
        "category": "Handwriting",
        "family": "Mansalva"
    },
    {
        "category": "Serif",
        "family": "Manuale"
    },
    {
        "category": "Serif",
        "family": "Marcellus"
    },
    {
        "category": "Serif",
        "family": "Marcellus SC"
    },
    {
        "category": "Handwriting",
        "family": "Marck Script"
    },
    {
        "category": "Display",
        "family": "Margarine"
    },
    {
        "category": "Serif",
        "family": "Markazi Text"
    },
    {
        "category": "Serif",
        "family": "Marko One"
    },
    {
        "category": "Sans Serif",
        "family": "Marmelad"
    },
    {
        "category": "Serif",
        "family": "Martel"
    },
    {
        "category": "Sans Serif",
        "family": "Martel Sans"
    },
    {
        "category": "Sans Serif",
        "family": "Marvel"
    },
    {
        "category": "Serif",
        "family": "Mate"
    },
    {
        "category": "Serif",
        "family": "Mate SC"
    },
    {
        "category": "Sans Serif",
        "family": "Maven Pro"
    },
    {
        "category": "Display",
        "family": "McLaren"
    },
    {
        "category": "Handwriting",
        "family": "Meddon"
    },
    {
        "category": "Display",
        "family": "MedievalSharp"
    },
    {
        "category": "Display",
        "family": "Medula One"
    },
    {
        "category": "Sans Serif",
        "family": "Meera Inimai"
    },
    {
        "category": "Display",
        "family": "Megrim"
    },
    {
        "category": "Handwriting",
        "family": "Meie Script"
    },
    {
        "category": "Handwriting",
        "family": "Merienda"
    },
    {
        "category": "Handwriting",
        "family": "Merienda One"
    },
    {
        "category": "Serif",
        "family": "Merriweather"
    },
    {
        "category": "Sans Serif",
        "family": "Merriweather Sans"
    },
    {
        "category": "Display",
        "family": "Metal"
    },
    {
        "category": "Display",
        "family": "Metal Mania"
    },
    {
        "category": "Display",
        "family": "Metamorphous"
    },
    {
        "category": "Sans Serif",
        "family": "Metrophobic"
    },
    {
        "category": "Sans Serif",
        "family": "Michroma"
    },
    {
        "category": "Display",
        "family": "Milonga"
    },
    {
        "category": "Display",
        "family": "Miltonian"
    },
    {
        "category": "Display",
        "family": "Miltonian Tattoo"
    },
    {
        "category": "Sans Serif",
        "family": "Mina"
    },
    {
        "category": "Display",
        "family": "Miniver"
    },
    {
        "category": "Sans Serif",
        "family": "Miriam Libre"
    },
    {
        "category": "Display",
        "family": "Mirza"
    },
    {
        "category": "Handwriting",
        "family": "Miss Fajardose"
    },
    {
        "category": "Sans Serif",
        "family": "Mitr"
    },
    {
        "category": "Display",
        "family": "Modak"
    },
    {
        "category": "Display",
        "family": "Modern Antiqua"
    },
    {
        "category": "Display",
        "family": "Mogra"
    },
    {
        "category": "Sans Serif",
        "family": "Molengo"
    },
    {
        "category": "Handwriting",
        "family": "Molle"
    },
    {
        "category": "Sans Serif",
        "family": "Monda"
    },
    {
        "category": "Display",
        "family": "Monofett"
    },
    {
        "category": "Display",
        "family": "Monoton"
    },
    {
        "category": "Handwriting",
        "family": "Monsieur La Doulaise"
    },
    {
        "category": "Serif",
        "family": "Montaga"
    },
    {
        "category": "Handwriting",
        "family": "MonteCarlo"
    },
    {
        "category": "Handwriting",
        "family": "Montez"
    },
    {
        "category": "Sans Serif",
        "family": "Montserrat"
    },
    {
        "category": "Sans Serif",
        "family": "Montserrat Alternates"
    },
    {
        "category": "Sans Serif",
        "family": "Montserrat Subrayada"
    },
    {
        "category": "Display",
        "family": "Moul"
    },
    {
        "category": "Display",
        "family": "Moulpali"
    },
    {
        "category": "Display",
        "family": "Mountains of Christmas"
    },
    {
        "category": "Sans Serif",
        "family": "Mouse Memoirs"
    },
    {
        "category": "Handwriting",
        "family": "Mr Bedfort"
    },
    {
        "category": "Handwriting",
        "family": "Mr Dafoe"
    },
    {
        "category": "Handwriting",
        "family": "Mr De Haviland"
    },
    {
        "category": "Handwriting",
        "family": "Mrs Saint Delafield"
    },
    {
        "category": "Handwriting",
        "family": "Mrs Sheppards"
    },
    {
        "category": "Sans Serif",
        "family": "Mukta"
    },
    {
        "category": "Sans Serif",
        "family": "Mukta Mahee"
    },
    {
        "category": "Sans Serif",
        "family": "Mukta Malar"
    },
    {
        "category": "Sans Serif",
        "family": "Mukta Vaani"
    },
    {
        "category": "Sans Serif",
        "family": "Mulish"
    },
    {
        "category": "Display",
        "family": "MuseoModerno"
    },
    {
        "category": "Display",
        "family": "Mystery Quest"
    },
    {
        "category": "Sans Serif",
        "family": "NTR"
    },
    {
        "category": "Handwriting",
        "family": "Nanum Brush Script"
    },
    {
        "category": "Sans Serif",
        "family": "Nanum Gothic"
    },
    {
        "category": "Monospace",
        "family": "Nanum Gothic Coding"
    },
    {
        "category": "Serif",
        "family": "Nanum Myeongjo"
    },
    {
        "category": "Handwriting",
        "family": "Nanum Pen Script"
    },
    {
        "category": "Handwriting",
        "family": "Nerko One"
    },
    {
        "category": "Handwriting",
        "family": "Neucha"
    },
    {
        "category": "Serif",
        "family": "Neuton"
    },
    {
        "category": "Display",
        "family": "New Rocker"
    },
    {
        "category": "Serif",
        "family": "New Tegomin"
    },
    {
        "category": "Sans Serif",
        "family": "News Cycle"
    },
    {
        "category": "Serif",
        "family": "Newsreader"
    },
    {
        "category": "Handwriting",
        "family": "Niconne"
    },
    {
        "category": "Sans Serif",
        "family": "Niramit"
    },
    {
        "category": "Display",
        "family": "Nixie One"
    },
    {
        "category": "Sans Serif",
        "family": "Nobile"
    },
    {
        "category": "Serif",
        "family": "Nokora"
    },
    {
        "category": "Handwriting",
        "family": "Norican"
    },
    {
        "category": "Display",
        "family": "Nosifer"
    },
    {
        "category": "Sans Serif",
        "family": "Notable"
    },
    {
        "category": "Handwriting",
        "family": "Nothing You Could Do"
    },
    {
        "category": "Serif",
        "family": "Noticia Text"
    },
    {
        "category": "Sans Serif",
        "family": "Noto Sans"
    },
    {
        "category": "Sans Serif",
        "family": "Noto Sans HK"
    },
    {
        "category": "Sans Serif",
        "family": "Noto Sans JP"
    },
    {
        "category": "Sans Serif",
        "family": "Noto Sans KR"
    },
    {
        "category": "Sans Serif",
        "family": "Noto Sans SC"
    },
    {
        "category": "Sans Serif",
        "family": "Noto Sans TC"
    },
    {
        "category": "Serif",
        "family": "Noto Serif"
    },
    {
        "category": "Serif",
        "family": "Noto Serif JP"
    },
    {
        "category": "Serif",
        "family": "Noto Serif KR"
    },
    {
        "category": "Serif",
        "family": "Noto Serif SC"
    },
    {
        "category": "Serif",
        "family": "Noto Serif TC"
    },
    {
        "category": "Display",
        "family": "Nova Cut"
    },
    {
        "category": "Display",
        "family": "Nova Flat"
    },
    {
        "category": "Monospace",
        "family": "Nova Mono"
    },
    {
        "category": "Display",
        "family": "Nova Oval"
    },
    {
        "category": "Display",
        "family": "Nova Round"
    },
    {
        "category": "Display",
        "family": "Nova Script"
    },
    {
        "category": "Display",
        "family": "Nova Slim"
    },
    {
        "category": "Display",
        "family": "Nova Square"
    },
    {
        "category": "Sans Serif",
        "family": "Numans"
    },
    {
        "category": "Sans Serif",
        "family": "Nunito"
    },
    {
        "category": "Sans Serif",
        "family": "Nunito Sans"
    },
    {
        "category": "Display",
        "family": "Odibee Sans"
    },
    {
        "category": "Display",
        "family": "Odor Mean Chey"
    },
    {
        "category": "Display",
        "family": "Offside"
    },
    {
        "category": "Display",
        "family": "Oi"
    },
    {
        "category": "Serif",
        "family": "Old Standard TT"
    },
    {
        "category": "Display",
        "family": "Oldenburg"
    },
    {
        "category": "Display",
        "family": "Oleo Script"
    },
    {
        "category": "Display",
        "family": "Oleo Script Swash Caps"
    },
    {
        "category": "Sans Serif",
        "family": "Open Sans"
    },
    {
        "category": "Sans Serif",
        "family": "Open Sans Condensed"
    },
    {
        "category": "Serif",
        "family": "Oranienbaum"
    },
    {
        "category": "Sans Serif",
        "family": "Orbitron"
    },
    {
        "category": "Display",
        "family": "Oregano"
    },
    {
        "category": "Display",
        "family": "Orelega One"
    },
    {
        "category": "Sans Serif",
        "family": "Orienta"
    },
    {
        "category": "Display",
        "family": "Original Surfer"
    },
    {
        "category": "Sans Serif",
        "family": "Oswald"
    },
    {
        "category": "Sans Serif",
        "family": "Otomanopee One"
    },
    {
        "category": "Handwriting",
        "family": "Over the Rainbow"
    },
    {
        "category": "Display",
        "family": "Overlock"
    },
    {
        "category": "Display",
        "family": "Overlock SC"
    },
    {
        "category": "Sans Serif",
        "family": "Overpass"
    },
    {
        "category": "Monospace",
        "family": "Overpass Mono"
    },
    {
        "category": "Serif",
        "family": "Ovo"
    },
    {
        "category": "Display",
        "family": "Oxanium"
    },
    {
        "category": "Sans Serif",
        "family": "Oxygen"
    },
    {
        "category": "Monospace",
        "family": "Oxygen Mono"
    },
    {
        "category": "Monospace",
        "family": "PT Mono"
    },
    {
        "category": "Sans Serif",
        "family": "PT Sans"
    },
    {
        "category": "Sans Serif",
        "family": "PT Sans Caption"
    },
    {
        "category": "Sans Serif",
        "family": "PT Sans Narrow"
    },
    {
        "category": "Serif",
        "family": "PT Serif"
    },
    {
        "category": "Serif",
        "family": "PT Serif Caption"
    },
    {
        "category": "Handwriting",
        "family": "Pacifico"
    },
    {
        "category": "Sans Serif",
        "family": "Padauk"
    },
    {
        "category": "Sans Serif",
        "family": "Palanquin"
    },
    {
        "category": "Sans Serif",
        "family": "Palanquin Dark"
    },
    {
        "category": "Display",
        "family": "Palette Mosaic"
    },
    {
        "category": "Handwriting",
        "family": "Pangolin"
    },
    {
        "category": "Display",
        "family": "Paprika"
    },
    {
        "category": "Handwriting",
        "family": "Parisienne"
    },
    {
        "category": "Display",
        "family": "Passero One"
    },
    {
        "category": "Display",
        "family": "Passion One"
    },
    {
        "category": "Sans Serif",
        "family": "Pathway Gothic One"
    },
    {
        "category": "Handwriting",
        "family": "Patrick Hand"
    },
    {
        "category": "Handwriting",
        "family": "Patrick Hand SC"
    },
    {
        "category": "Sans Serif",
        "family": "Pattaya"
    },
    {
        "category": "Display",
        "family": "Patua One"
    },
    {
        "category": "Sans Serif",
        "family": "Pavanam"
    },
    {
        "category": "Sans Serif",
        "family": "Paytone One"
    },
    {
        "category": "Serif",
        "family": "Peddana"
    },
    {
        "category": "Display",
        "family": "Peralta"
    },
    {
        "category": "Handwriting",
        "family": "Permanent Marker"
    },
    {
        "category": "Handwriting",
        "family": "Petit Formal Script"
    },
    {
        "category": "Serif",
        "family": "Petrona"
    },
    {
        "category": "Sans Serif",
        "family": "Philosopher"
    },
    {
        "category": "Serif",
        "family": "Piazzolla"
    },
    {
        "category": "Display",
        "family": "Piedra"
    },
    {
        "category": "Handwriting",
        "family": "Pinyon Script"
    },
    {
        "category": "Display",
        "family": "Pirata One"
    },
    {
        "category": "Display",
        "family": "Plaster"
    },
    {
        "category": "Sans Serif",
        "family": "Play"
    },
    {
        "category": "Display",
        "family": "Playball"
    },
    {
        "category": "Serif",
        "family": "Playfair Display"
    },
    {
        "category": "Serif",
        "family": "Playfair Display SC"
    },
    {
        "category": "Serif",
        "family": "Podkova"
    },
    {
        "category": "Display",
        "family": "Poiret One"
    },
    {
        "category": "Display",
        "family": "Poller One"
    },
    {
        "category": "Serif",
        "family": "Poly"
    },
    {
        "category": "Display",
        "family": "Pompiere"
    },
    {
        "category": "Sans Serif",
        "family": "Pontano Sans"
    },
    {
        "category": "Display",
        "family": "Poor Story"
    },
    {
        "category": "Sans Serif",
        "family": "Poppins"
    },
    {
        "category": "Sans Serif",
        "family": "Port Lligat Sans"
    },
    {
        "category": "Serif",
        "family": "Port Lligat Slab"
    },
    {
        "category": "Display",
        "family": "Potta One"
    },
    {
        "category": "Sans Serif",
        "family": "Pragati Narrow"
    },
    {
        "category": "Serif",
        "family": "Prata"
    },
    {
        "category": "Display",
        "family": "Preahvihear"
    },
    {
        "category": "Display",
        "family": "Press Start 2P"
    },
    {
        "category": "Serif",
        "family": "Pridi"
    },
    {
        "category": "Handwriting",
        "family": "Princess Sofia"
    },
    {
        "category": "Serif",
        "family": "Prociono"
    },
    {
        "category": "Sans Serif",
        "family": "Prompt"
    },
    {
        "category": "Display",
        "family": "Prosto One"
    },
    {
        "category": "Sans Serif",
        "family": "Proza Libre"
    },
    {
        "category": "Sans Serif",
        "family": "Public Sans"
    },
    {
        "category": "Sans Serif",
        "family": "Puritan"
    },
    {
        "category": "Display",
        "family": "Purple Purse"
    },
    {
        "category": "Sans Serif",
        "family": "Qahiri"
    },
    {
        "category": "Serif",
        "family": "Quando"
    },
    {
        "category": "Sans Serif",
        "family": "Quantico"
    },
    {
        "category": "Serif",
        "family": "Quattrocento"
    },
    {
        "category": "Sans Serif",
        "family": "Quattrocento Sans"
    },
    {
        "category": "Sans Serif",
        "family": "Questrial"
    },
    {
        "category": "Sans Serif",
        "family": "Quicksand"
    },
    {
        "category": "Handwriting",
        "family": "Quintessential"
    },
    {
        "category": "Handwriting",
        "family": "Qwigley"
    },
    {
        "category": "Display",
        "family": "Racing Sans One"
    },
    {
        "category": "Serif",
        "family": "Radley"
    },
    {
        "category": "Sans Serif",
        "family": "Rajdhani"
    },
    {
        "category": "Display",
        "family": "Rakkas"
    },
    {
        "category": "Sans Serif",
        "family": "Raleway"
    },
    {
        "category": "Display",
        "family": "Raleway Dots"
    },
    {
        "category": "Sans Serif",
        "family": "Ramabhadra"
    },
    {
        "category": "Serif",
        "family": "Ramaraja"
    },
    {
        "category": "Sans Serif",
        "family": "Rambla"
    },
    {
        "category": "Display",
        "family": "Rammetto One"
    },
    {
        "category": "Display",
        "family": "Rampart One"
    },
    {
        "category": "Display",
        "family": "Ranchers"
    },
    {
        "category": "Handwriting",
        "family": "Rancho"
    },
    {
        "category": "Display",
        "family": "Ranga"
    },
    {
        "category": "Serif",
        "family": "Rasa"
    },
    {
        "category": "Sans Serif",
        "family": "Rationale"
    },
    {
        "category": "Display",
        "family": "Ravi Prakash"
    },
    {
        "category": "Sans Serif",
        "family": "Recursive"
    },
    {
        "category": "Sans Serif",
        "family": "Red Hat Display"
    },
    {
        "category": "Sans Serif",
        "family": "Red Hat Text"
    },
    {
        "category": "Display",
        "family": "Red Rose"
    },
    {
        "category": "Handwriting",
        "family": "Redressed"
    },
    {
        "category": "Sans Serif",
        "family": "Reem Kufi"
    },
    {
        "category": "Handwriting",
        "family": "Reenie Beanie"
    },
    {
        "category": "Display",
        "family": "Reggae One"
    },
    {
        "category": "Display",
        "family": "Revalia"
    },
    {
        "category": "Serif",
        "family": "Rhodium Libre"
    },
    {
        "category": "Display",
        "family": "Ribeye"
    },
    {
        "category": "Display",
        "family": "Ribeye Marrow"
    },
    {
        "category": "Display",
        "family": "Righteous"
    },
    {
        "category": "Display",
        "family": "Risque"
    },
    {
        "category": "Sans Serif",
        "family": "Roboto"
    },
    {
        "category": "Sans Serif",
        "family": "Roboto Condensed"
    },
    {
        "category": "Monospace",
        "family": "Roboto Mono"
    },
    {
        "category": "Serif",
        "family": "Roboto Slab"
    },
    {
        "category": "Handwriting",
        "family": "Rochester"
    },
    {
        "category": "Handwriting",
        "family": "Rock Salt"
    },
    {
        "category": "Sans Serif",
        "family": "RocknRoll One"
    },
    {
        "category": "Serif",
        "family": "Rokkitt"
    },
    {
        "category": "Handwriting",
        "family": "Romanesco"
    },
    {
        "category": "Sans Serif",
        "family": "Ropa Sans"
    },
    {
        "category": "Sans Serif",
        "family": "Rosario"
    },
    {
        "category": "Serif",
        "family": "Rosarivo"
    },
    {
        "category": "Handwriting",
        "family": "Rouge Script"
    },
    {
        "category": "Display",
        "family": "Rowdies"
    },
    {
        "category": "Serif",
        "family": "Rozha One"
    },
    {
        "category": "Sans Serif",
        "family": "Rubik"
    },
    {
        "category": "Sans Serif",
        "family": "Rubik Mono One"
    },
    {
        "category": "Sans Serif",
        "family": "Ruda"
    },
    {
        "category": "Serif",
        "family": "Rufina"
    },
    {
        "category": "Handwriting",
        "family": "Ruge Boogie"
    },
    {
        "category": "Sans Serif",
        "family": "Ruluko"
    },
    {
        "category": "Sans Serif",
        "family": "Rum Raisin"
    },
    {
        "category": "Display",
        "family": "Ruslan Display"
    },
    {
        "category": "Sans Serif",
        "family": "Russo One"
    },
    {
        "category": "Handwriting",
        "family": "Ruthie"
    },
    {
        "category": "Display",
        "family": "Rye"
    },
    {
        "category": "Serif",
        "family": "STIX Two Text"
    },
    {
        "category": "Handwriting",
        "family": "Sacramento"
    },
    {
        "category": "Serif",
        "family": "Sahitya"
    },
    {
        "category": "Display",
        "family": "Sail"
    },
    {
        "category": "Sans Serif",
        "family": "Saira"
    },
    {
        "category": "Sans Serif",
        "family": "Saira Condensed"
    },
    {
        "category": "Sans Serif",
        "family": "Saira Extra Condensed"
    },
    {
        "category": "Sans Serif",
        "family": "Saira Semi Condensed"
    },
    {
        "category": "Display",
        "family": "Saira Stencil One"
    },
    {
        "category": "Display",
        "family": "Salsa"
    },
    {
        "category": "Serif",
        "family": "Sanchez"
    },
    {
        "category": "Display",
        "family": "Sancreek"
    },
    {
        "category": "Sans Serif",
        "family": "Sansita"
    },
    {
        "category": "Display",
        "family": "Sansita Swashed"
    },
    {
        "category": "Sans Serif",
        "family": "Sarabun"
    },
    {
        "category": "Sans Serif",
        "family": "Sarala"
    },
    {
        "category": "Display",
        "family": "Sarina"
    },
    {
        "category": "Sans Serif",
        "family": "Sarpanch"
    },
    {
        "category": "Handwriting",
        "family": "Satisfy"
    },
    {
        "category": "Sans Serif",
        "family": "Sawarabi Gothic"
    },
    {
        "category": "Sans Serif",
        "family": "Sawarabi Mincho"
    },
    {
        "category": "Sans Serif",
        "family": "Scada"
    },
    {
        "category": "Serif",
        "family": "Scheherazade"
    },
    {
        "category": "Handwriting",
        "family": "Schoolbell"
    },
    {
        "category": "Serif",
        "family": "Scope One"
    },
    {
        "category": "Display",
        "family": "Seaweed Script"
    },
    {
        "category": "Sans Serif",
        "family": "Secular One"
    },
    {
        "category": "Handwriting",
        "family": "Sedgwick Ave"
    },
    {
        "category": "Handwriting",
        "family": "Sedgwick Ave Display"
    },
    {
        "category": "Sans Serif",
        "family": "Sen"
    },
    {
        "category": "Display",
        "family": "Sevillana"
    },
    {
        "category": "Sans Serif",
        "family": "Seymour One"
    },
    {
        "category": "Handwriting",
        "family": "Shadows Into Light"
    },
    {
        "category": "Handwriting",
        "family": "Shadows Into Light Two"
    },
    {
        "category": "Sans Serif",
        "family": "Shanti"
    },
    {
        "category": "Display",
        "family": "Share"
    },
    {
        "category": "Sans Serif",
        "family": "Share Tech"
    },
    {
        "category": "Monospace",
        "family": "Share Tech Mono"
    },
    {
        "category": "Serif",
        "family": "Shippori Mincho"
    },
    {
        "category": "Serif",
        "family": "Shippori Mincho B1"
    },
    {
        "category": "Display",
        "family": "Shojumaru"
    },
    {
        "category": "Handwriting",
        "family": "Short Stack"
    },
    {
        "category": "Display",
        "family": "Shrikhand"
    },
    {
        "category": "Display",
        "family": "Siemreap"
    },
    {
        "category": "Display",
        "family": "Sigmar One"
    },
    {
        "category": "Sans Serif",
        "family": "Signika"
    },
    {
        "category": "Sans Serif",
        "family": "Signika Negative"
    },
    {
        "category": "Display",
        "family": "Simonetta"
    },
    {
        "category": "Display",
        "family": "Single Day"
    },
    {
        "category": "Sans Serif",
        "family": "Sintony"
    },
    {
        "category": "Display",
        "family": "Sirin Stencil"
    },
    {
        "category": "Sans Serif",
        "family": "Six Caps"
    },
    {
        "category": "Display",
        "family": "Skranji"
    },
    {
        "category": "Serif",
        "family": "Slabo 13px"
    },
    {
        "category": "Serif",
        "family": "Slabo 27px"
    },
    {
        "category": "Display",
        "family": "Slackey"
    },
    {
        "category": "Display",
        "family": "Smokum"
    },
    {
        "category": "Display",
        "family": "Smythe"
    },
    {
        "category": "Display",
        "family": "Sniglet"
    },
    {
        "category": "Sans Serif",
        "family": "Snippet"
    },
    {
        "category": "Display",
        "family": "Snowburst One"
    },
    {
        "category": "Display",
        "family": "Sofadi One"
    },
    {
        "category": "Handwriting",
        "family": "Sofia"
    },
    {
        "category": "Serif",
        "family": "Solway"
    },
    {
        "category": "Serif",
        "family": "Song Myung"
    },
    {
        "category": "Display",
        "family": "Sonsie One"
    },
    {
        "category": "Sans Serif",
        "family": "Sora"
    },
    {
        "category": "Serif",
        "family": "Sorts Mill Goudy"
    },
    {
        "category": "Monospace",
        "family": "Source Code Pro"
    },
    {
        "category": "Sans Serif",
        "family": "Source Sans Pro"
    },
    {
        "category": "Serif",
        "family": "Source Serif Pro"
    },
    {
        "category": "Sans Serif",
        "family": "Space Grotesk"
    },
    {
        "category": "Monospace",
        "family": "Space Mono"
    },
    {
        "category": "Sans Serif",
        "family": "Spartan"
    },
    {
        "category": "Display",
        "family": "Special Elite"
    },
    {
        "category": "Serif",
        "family": "Spectral"
    },
    {
        "category": "Serif",
        "family": "Spectral SC"
    },
    {
        "category": "Display",
        "family": "Spicy Rice"
    },
    {
        "category": "Sans Serif",
        "family": "Spinnaker"
    },
    {
        "category": "Display",
        "family": "Spirax"
    },
    {
        "category": "Display",
        "family": "Squada One"
    },
    {
        "category": "Serif",
        "family": "Sree Krushnadevaraya"
    },
    {
        "category": "Handwriting",
        "family": "Sriracha"
    },
    {
        "category": "Display",
        "family": "Srisakdi"
    },
    {
        "category": "Display",
        "family": "Staatliches"
    },
    {
        "category": "Handwriting",
        "family": "Stalemate"
    },
    {
        "category": "Display",
        "family": "Stalinist One"
    },
    {
        "category": "Display",
        "family": "Stardos Stencil"
    },
    {
        "category": "Sans Serif",
        "family": "Stick"
    },
    {
        "category": "Display",
        "family": "Stint Ultra Condensed"
    },
    {
        "category": "Display",
        "family": "Stint Ultra Expanded"
    },
    {
        "category": "Serif",
        "family": "Stoke"
    },
    {
        "category": "Sans Serif",
        "family": "Strait"
    },
    {
        "category": "Handwriting",
        "family": "Style Script"
    },
    {
        "category": "Sans Serif",
        "family": "Stylish"
    },
    {
        "category": "Handwriting",
        "family": "Sue Ellen Francisco"
    },
    {
        "category": "Serif",
        "family": "Suez One"
    },
    {
        "category": "Sans Serif",
        "family": "Sulphur Point"
    },
    {
        "category": "Serif",
        "family": "Sumana"
    },
    {
        "category": "Sans Serif",
        "family": "Sunflower"
    },
    {
        "category": "Handwriting",
        "family": "Sunshiney"
    },
    {
        "category": "Display",
        "family": "Supermercado One"
    },
    {
        "category": "Serif",
        "family": "Sura"
    },
    {
        "category": "Serif",
        "family": "Suranna"
    },
    {
        "category": "Serif",
        "family": "Suravaram"
    },
    {
        "category": "Display",
        "family": "Suwannaphum"
    },
    {
        "category": "Handwriting",
        "family": "Swanky and Moo Moo"
    },
    {
        "category": "Sans Serif",
        "family": "Syncopate"
    },
    {
        "category": "Sans Serif",
        "family": "Syne"
    },
    {
        "category": "Monospace",
        "family": "Syne Mono"
    },
    {
        "category": "Display",
        "family": "Syne Tactile"
    },
    {
        "category": "Sans Serif",
        "family": "Tajawal"
    },
    {
        "category": "Handwriting",
        "family": "Tangerine"
    },
    {
        "category": "Display",
        "family": "Taprom"
    },
    {
        "category": "Sans Serif",
        "family": "Tauri"
    },
    {
        "category": "Serif",
        "family": "Taviraj"
    },
    {
        "category": "Sans Serif",
        "family": "Teko"
    },
    {
        "category": "Sans Serif",
        "family": "Telex"
    },
    {
        "category": "Sans Serif",
        "family": "Tenali Ramakrishna"
    },
    {
        "category": "Sans Serif",
        "family": "Tenor Sans"
    },
    {
        "category": "Sans Serif",
        "family": "Text Me One"
    },
    {
        "category": "Serif",
        "family": "Texturina"
    },
    {
        "category": "Sans Serif",
        "family": "Thasadith"
    },
    {
        "category": "Handwriting",
        "family": "The Girl Next Door"
    },
    {
        "category": "Serif",
        "family": "Tienne"
    },
    {
        "category": "Handwriting",
        "family": "Tillana"
    },
    {
        "category": "Sans Serif",
        "family": "Timmana"
    },
    {
        "category": "Serif",
        "family": "Tinos"
    },
    {
        "category": "Display",
        "family": "Titan One"
    },
    {
        "category": "Sans Serif",
        "family": "Titillium Web"
    },
    {
        "category": "Sans Serif",
        "family": "Tomorrow"
    },
    {
        "category": "Display",
        "family": "Tourney"
    },
    {
        "category": "Display",
        "family": "Trade Winds"
    },
    {
        "category": "Display",
        "family": "Train One"
    },
    {
        "category": "Serif",
        "family": "Trirong"
    },
    {
        "category": "Sans Serif",
        "family": "Trispace"
    },
    {
        "category": "Serif",
        "family": "Trocchi"
    },
    {
        "category": "Display",
        "family": "Trochut"
    },
    {
        "category": "Sans Serif",
        "family": "Truculenta"
    },
    {
        "category": "Serif",
        "family": "Trykker"
    },
    {
        "category": "Display",
        "family": "Tulpen One"
    },
    {
        "category": "Display",
        "family": "Turret Road"
    },
    {
        "category": "Sans Serif",
        "family": "Ubuntu"
    },
    {
        "category": "Sans Serif",
        "family": "Ubuntu Condensed"
    },
    {
        "category": "Monospace",
        "family": "Ubuntu Mono"
    },
    {
        "category": "Serif",
        "family": "Uchen"
    },
    {
        "category": "Serif",
        "family": "Ultra"
    },
    {
        "category": "Display",
        "family": "Uncial Antiqua"
    },
    {
        "category": "Display",
        "family": "Underdog"
    },
    {
        "category": "Display",
        "family": "Unica One"
    },
    {
        "category": "Display",
        "family": "UnifrakturCook"
    },
    {
        "category": "Display",
        "family": "UnifrakturMaguntia"
    },
    {
        "category": "Display",
        "family": "Unkempt"
    },
    {
        "category": "Display",
        "family": "Unlock"
    },
    {
        "category": "Serif",
        "family": "Unna"
    },
    {
        "category": "Sans Serif",
        "family": "Urbanist"
    },
    {
        "category": "Monospace",
        "family": "VT323"
    },
    {
        "category": "Display",
        "family": "Vampiro One"
    },
    {
        "category": "Sans Serif",
        "family": "Varela"
    },
    {
        "category": "Sans Serif",
        "family": "Varela Round"
    },
    {
        "category": "Sans Serif",
        "family": "Varta"
    },
    {
        "category": "Display",
        "family": "Vast Shadow"
    },
    {
        "category": "Serif",
        "family": "Vesper Libre"
    },
    {
        "category": "Display",
        "family": "Viaoda Libre"
    },
    {
        "category": "Display",
        "family": "Vibes"
    },
    {
        "category": "Handwriting",
        "family": "Vibur"
    },
    {
        "category": "Serif",
        "family": "Vidaloka"
    },
    {
        "category": "Sans Serif",
        "family": "Viga"
    },
    {
        "category": "Display",
        "family": "Voces"
    },
    {
        "category": "Serif",
        "family": "Volkhov"
    },
    {
        "category": "Serif",
        "family": "Vollkorn"
    },
    {
        "category": "Serif",
        "family": "Vollkorn SC"
    },
    {
        "category": "Sans Serif",
        "family": "Voltaire"
    },
    {
        "category": "Handwriting",
        "family": "Waiting for the Sunrise"
    },
    {
        "category": "Display",
        "family": "Wallpoet"
    },
    {
        "category": "Handwriting",
        "family": "Walter Turncoat"
    },
    {
        "category": "Display",
        "family": "Warnes"
    },
    {
        "category": "Display",
        "family": "Wellfleet"
    },
    {
        "category": "Sans Serif",
        "family": "Wendy One"
    },
    {
        "category": "Handwriting",
        "family": "WindSong"
    },
    {
        "category": "Sans Serif",
        "family": "Wire One"
    },
    {
        "category": "Sans Serif",
        "family": "Work Sans"
    },
    {
        "category": "Monospace",
        "family": "Xanh Mono"
    },
    {
        "category": "Sans Serif",
        "family": "Yanone Kaffeesatz"
    },
    {
        "category": "Sans Serif",
        "family": "Yantramanav"
    },
    {
        "category": "Display",
        "family": "Yatra One"
    },
    {
        "category": "Handwriting",
        "family": "Yellowtail"
    },
    {
        "category": "Display",
        "family": "Yeon Sung"
    },
    {
        "category": "Display",
        "family": "Yeseva One"
    },
    {
        "category": "Handwriting",
        "family": "Yesteryear"
    },
    {
        "category": "Handwriting",
        "family": "Yomogi"
    },
    {
        "category": "Serif",
        "family": "Yrsa"
    },
    {
        "category": "Sans Serif",
        "family": "Yusei Magic"
    },
    {
        "category": "Display",
        "family": "ZCOOL KuaiLe"
    },
    {
        "category": "Display",
        "family": "ZCOOL QingKe HuangYou"
    },
    {
        "category": "Serif",
        "family": "ZCOOL XiaoWei"
    },
    {
        "category": "Display",
        "family": "Zen Dots"
    },
    {
        "category": "Display",
        "family": "Zen Loop"
    },
    {
        "category": "Display",
        "family": "Zen Tokyo Zoo"
    },
    {
        "category": "Handwriting",
        "family": "Zeyada"
    },
    {
        "category": "Handwriting",
        "family": "Zhi Mang Xing"
    },
    {
        "category": "Serif",
        "family": "Zilla Slab"
    },
    {
        "category": "Display",
        "family": "Zilla Slab Highlight"
    }
]);

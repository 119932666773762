import angular from 'angular';

angular.module('application').directive('paginationNavigator', [function() {
	return {
		restrict: 'E',
		replace: true,
		scope: {
			first: "&",
			previous: "&",
			next: "&",
			last: "&",
			currentPage: "=",
			numPages: "=",
			itemsPerPage: "=",
			numCurrentItems: "=",
			numTotalItems: "=",
			perPage: "=",
			recordType: "@",
			perPageChange: "&",
		},
		template: require('./pagination-navigator.html'),
		controller: ["$scope", function($scope) {	
			
			$scope.goToFirstPage = function() {
        		$scope.first();
          	};

          	$scope.goToLastPage = function() {
           		$scope.last();
          	};

           	 $scope.decrementPage = function() {
	            $scope.previous();
		    };

		    $scope.incrementPage = function() {
	            $scope.next();
		    };

		    $scope.firstItem = function() {
		    	var n = $scope.currentPage * $scope.itemsPerPage + 1;
		    	if(isNaN(n)) {
		    		return "";
		    	} else {
		    		return n;
		    	}
			};
			
			$scope.changeResultsPerPage = function(perPage){
				$scope.perPageChange({perPage: perPage});
			};

		    $scope.lastItem = function() {
		    	var n = $scope.currentPage * $scope.itemsPerPage + $scope.numCurrentItems;
		    	if(isNaN(n)) {
		    		return "";
		    	} else {
		    		return n;
		    	}
		    };
        }],

		//to see if functions are passed
        link: function(scope,elem,attrs) {

	    	scope.hasFirst = function() {
	    		return angular.isDefined(attrs.first);
	      	};

	      	scope.hasLast = function() {
	      		return angular.isDefined(attrs.last);
	      	};

	      	scope.hasNext = function() {
	      		return angular.isDefined(attrs.next);
	      	};

	      	scope.hasPrevious = function() {
	      		return angular.isDefined(attrs.previous);
	      	};
	    }

    };
}]);

import angular from 'angular';
import _ from 'lodash';

angular.module('items').controller('VatRateManagementCtrl', ["$scope", "$translate", "vatRates", "entityManager", "confirmDialog", "alertDialog", "checkManager", function($scope, $translate, vatRates, entityManager, confirmDialog, alertDialog, checkManager) {
    $scope.topbar_context = {
        showcaseView: 'crudviewmode',
        back_label: $translate.instant('ITEMS.VAT_RATE.PRODUCTS'),
        current_label: $translate.instant('ITEMS.VAT_RATE.VATS')
    };

    $scope.saveInProgress = false;

    $scope.vatRates = _.sortBy(vatRates, 'index');
    $scope.pristineVatRate = null;
    $scope.country = checkManager.getShopCountry();

    var determineMinAvailableId = function() {
        var vatsById = _.keyBy($scope.vatRates, 'id');
        var currentIds = _(vatsById).map('id').value();
        var candidateIds = _.range(1, _.max(currentIds) + 2);

        return _.find(candidateIds, function(id) {
            return _.isNil(vatsById[id]);
        });
    };

    $scope.vatCodeList = [
        {
            code: null,
            description: $translate.instant('ITEMS.VAT_RATE.VAT_LIST.NULL')
        },
        {
            code: 'N1',
            description: $translate.instant('ITEMS.VAT_RATE.VAT_LIST.N1')
        },
        {
            code: 'N2.1',
            description: $translate.instant('ITEMS.VAT_RATE.VAT_LIST.N2_1')
        },
        {
            code: 'N2.2',
            description: $translate.instant('ITEMS.VAT_RATE.VAT_LIST.N2_2')
        },
        {
            code: 'N3.1',
            description: $translate.instant('ITEMS.VAT_RATE.VAT_LIST.N3_1')
        },
        {
            code: 'N3.2',
            description: $translate.instant('ITEMS.VAT_RATE.VAT_LIST.N3_2')
        },
        {
            code: 'N3.3',
            description: $translate.instant('ITEMS.VAT_RATE.VAT_LIST.N3_3')
        },
        {
            code: 'N3.4',
            description: $translate.instant('ITEMS.VAT_RATE.VAT_LIST.N3_4')
        },
        {
            code: 'N3.5',
            description: $translate.instant('ITEMS.VAT_RATE.VAT_LIST.N3_5')
        },
        {
            code: 'N3.6',
            description: $translate.instant('ITEMS.VAT_RATE.VAT_LIST.N3_6')
        },
        {
            code: 'N4',
            description: $translate.instant('ITEMS.VAT_RATE.VAT_LIST.N4')
        },
        {
            code: 'N5',
            description: $translate.instant('ITEMS.VAT_RATE.VAT_LIST.N5')
        },
        {
            code: 'N6.1',
            description: $translate.instant('ITEMS.VAT_RATE.VAT_LIST.N6_1')
        },
        {
            code: 'N6.2',
            description: $translate.instant('ITEMS.VAT_RATE.VAT_LIST.N6_2')
        },
        {
            code: 'N6.3',
            description: $translate.instant('ITEMS.VAT_RATE.VAT_LIST.N6_3')
        },
        {
            code: 'N6.4',
            description: $translate.instant('ITEMS.VAT_RATE.VAT_LIST.N6_4')
        },
        {
            code: 'N6.5',
            description: $translate.instant('ITEMS.VAT_RATE.VAT_LIST.N6_5')
        },
        {
            code: 'N6.6',
            description: $translate.instant('ITEMS.VAT_RATE.VAT_LIST.N6_6')
        },
        {
            code: 'N6.7',
            description: $translate.instant('ITEMS.VAT_RATE.VAT_LIST.N6_7')
        },
        {
            code: 'N6.8',
            description: $translate.instant('ITEMS.VAT_RATE.VAT_LIST.N6_8')
        },
        {
            code: 'N6.9',
            description: $translate.instant('ITEMS.VAT_RATE.VAT_LIST.N6_9')
        },
        {
            code: 'N7',
            description: $translate.instant('ITEMS.VAT_RATE.VAT_LIST.N7')
        }
    ];

    $scope.canSave = function() {
        return !_.isEqual($scope.vatRate, $scope.pristineVatRate);
    };

    $scope.afterSelectVatRate = function() {
        $scope.vatRate = _.clone($scope.pristineVatRate);
    };

    // The first vatRate is selected by default, can be changed to undefined and {}
    var selectDefaultVatRate = function() {
        $scope.pristineVatRate = _.isEmpty($scope.vatRates) ? {} : _.head($scope.vatRates);

        $scope.afterSelectVatRate();
    };

    selectDefaultVatRate();

    const isMagoEnabled = () => {
        const integrations_mago_publish_sales = checkManager.getPreference('integrations.mago.publish_sales');
        const integrations_mago_publish_customers = checkManager.getPreference('integrations.mago.publish_customers');
        return integrations_mago_publish_sales || integrations_mago_publish_customers;
    };

    $scope.deleteVatRate = function() {
        if(isMagoEnabled()) {
            alertDialog.show($translate.instant('ITEMS.NO_EDIT_WITH_MAGO_ENABLED'));
            return;
        }

        if (!$scope.vatRate.id) {
            return;
        }

        confirmDialog.show($translate.instant('ITEMS.VAT_RATE.WANT_TO_DELETE')).then(function(answer) {
            if (answer) {
                entityManager.vat.deleteOneOnline($scope.vatRate.id, true).then(function(success) {
                    _.remove($scope.vatRates, {
                        id: success
                    });

                    selectDefaultVatRate();
                }, function(error) {
                    alertDialog.show($translate.instant('ITEMS.VAT_RATE.ERROR_WHILE_DELETING'));
                });
            }
        });
    };

    $scope.addNewVatRate = function() {
        if(isMagoEnabled()) {
            alertDialog.show($translate.instant('ITEMS.NO_EDIT_WITH_MAGO_ENABLED'));
            return;
        }
        // Fields are now blank, vatRate is shown on cashregister/orders by default
        $scope.pristineVatRate = {
            display: true
        };
        $scope.afterSelectVatRate();
    };

    $scope.saveEdit = function() {
        if(isMagoEnabled()) {
            alertDialog.show($translate.instant('ITEMS.NO_EDIT_WITH_MAGO_ENABLED'));
            return;
        }

        if (!$scope.saveInProgress) {
            if ($scope.vatRate.value !== 0) {
                $scope.vatRate.code = null;
            }

            // Send to the BE
            var entityToBE = _.clone($scope.vatRate);

            if (entityToBE.id) {
                // Do a PUT
                $scope.saveInProgress = true;
                entityManager.vat.putOneOnline(entityToBE).then(function(success) {
                    angular.copy(success, $scope.pristineVatRate);
                    $scope.afterSelectVatRate();
                }, function(error) {
                    // Show error dialog
                    alertDialog.show($translate.instant('ITEMS.VAT_RATE.ERROR_TRY_LATER'));
                }).finally(function() {
                    $scope.saveInProgress = false;
                });
            } else {
                // Do a POST
                entityToBE.id = determineMinAvailableId();

                $scope.saveInProgress = true;
                entityManager.vat.postOneOnline(entityToBE).then(function(success) {
                    $scope.vatRates.push(success);
                    $scope.pristineVatRate = success;
                    $scope.afterSelectVatRate();
                }, function(error) {
                    // Show error dialog
                    alertDialog.show($translate.instant('ITEMS.VAT_RATE.ERROR_TRY_LATER'));
                }).finally(function() {
                    $scope.saveInProgress = false;
                });
            }
        }
    };

    $scope.abortEdit = function() {
        if ($scope.vatRate.id) {
            // Update the (form) view
            $scope.afterSelectVatRate();
        } else {
            selectDefaultVatRate();
        }
    };
}]);

import angular from 'angular';
import {EntityManagerService} from "../../../src/app/core/services";

angular.module('promotionsNew', ['application']);

angular.module('promotionsNew').config(["$stateProvider", function($stateProvider: any) {
    // $translatePartialLoaderProvider.addPart('promotions_new');

    $stateProvider.state('app.new.promotions', {
        url: '/promotions',
        component:"promotionsShowcase",
        params: {
          remove: {dynamic: true, value: null}
        }
    });

    $stateProvider.state('app.new.promotions.showcase', {
        url: '/showcase',
        redirectTo: 'app.new.promotions',
    });

    $stateProvider.state('app.new.promotions.details', {
        url: '/details/:promotionId',
        template: '<promotions-details [promotion]="$resolve.promotion" [categories]="$resolve.categories" [channels]="$resolve.channels" [chainShops]="$resolve.chainShops" [suppliers]="$resolve.suppliers" [itemsWithSku]="$resolve.itemsWithSku"></promotions-details>',
        params: {
            isNew: {dynamic: true, value: null}
        },
        resolve: {
            promotion: ["entityManager", "$stateParams", async function(entityManager: EntityManagerService, $stateParams: any) {
                return {...(await entityManager.promotions.fetchOneOnline($stateParams.promotionId)), ...($stateParams.isNew && {isNew: true})};
            }],
            suppliers: ["entityManager", async function(entityManager: EntityManagerService) {
                return await entityManager.suppliers.fetchCollectionOffline({});
            }],
            categories: ["entityManager", async function(entityManager: EntityManagerService) {
                return await entityManager.categories.fetchCollectionOffline({});
            }],
            channels: ["entityManager", async function(entityManager: EntityManagerService) {
                return await entityManager.channels.fetchCollectionOffline({});
            }],
            chainShops: ["restManager", async function(restManager: any) {
                return await restManager.getList("chain_shops", { pagination: false });
            }],
            itemsWithSku: ["entityManager", async function(entityManager: EntityManagerService) {
                return await entityManager.items.fetchCollectionOffline({});
                // const items = await entityManager.items.fetchCollectionOffline();
                // console.log('Promotion NEW -> items', items);
                // const itemsWithSku = items.filter(item => item.sku);
                // console.log('Promotion NEW -> itemsWithSku', itemsWithSku);
                // return itemsWithSku;
            }],
        }
    });

    $stateProvider.state('app.new.promotions.new', {
        url: '/new',
        template: '<promotions-details [promotion]="$resolve.promotion" [categories]="$resolve.categories" [channels]="$resolve.channels" [chainShops]="$resolve.chainShops" [suppliers]="$resolve.suppliers" [itemsWithSku]="$resolve.itemsWithSku"></promotions-details>',
        resolve: {
            promotion: function() {
                return {
                    isNew: true,
                    active: true,
                    exclude_other_promotions: true,
                    fidelity_rule: 'none',
                    require_all_items: true
                };
            },
            suppliers: ["entityManager", async function(entityManager: EntityManagerService) {
                return await entityManager.suppliers.fetchCollectionOffline({});
            }],
            categories: ["entityManager", async function(entityManager: EntityManagerService) {
                return await entityManager.categories.fetchCollectionOffline({});
            }],
            channels: ["entityManager", async function(entityManager: EntityManagerService) {
                return await entityManager.channels.fetchCollectionOffline({});
            }],
            chainShops: ["restManager", async function(restManager: any) {
                return await restManager.getList("chain_shops", { pagination: false });
            }],
            itemsWithSku: ["entityManager", async function(entityManager: EntityManagerService) {
                return await entityManager.items.fetchCollectionOffline({});
                // const items = await entityManager.items.fetchCollectionOffline();
                // console.log('Promotion NEW -> items', items);
                // const itemsWithSku = items.filter(item => item.sku);
                // console.log('Promotion NEW -> itemsWithSku', itemsWithSku);
                // return itemsWithSku;
            }],
        }
    });

}]);

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {AgGridModule} from "ag-grid-angular";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatMenuModule} from "@angular/material/menu";
import {MatCardModule} from "@angular/material/card";
import {MatListModule} from "@angular/material/list";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatTabsModule} from "@angular/material/tabs";
import { MatSnackBarModule} from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {PipesModule} from "../pipes/pipes.module";
import {TrackVisibilityDirective} from "../directives/track-visibility.directive";
import {
    BarcodeComponent,
    BarcodeScannerComponent, CustomGridCheckboxAllComponent,
    GridClickableButtonComponent, GridClickableGiftcardButtonComponent, GridClickableHistorySalesButtonComponent, GridClickablePromotionButtonComponent, GridClickableTypeButtonComponent,
    GridClientSideComponent, GridSaveUserPreferenceComponent,
    GridServerSideComponent, GridStatusBarComponent, GridClickableCashMovementsButtonComponent
} from "./components";
import {MatRadioModule} from "@angular/material/radio";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import { GridClickableButtonStaticTokenComponent } from './components/grid/grid-clickable-button-static-token/grid-clickable-button-static-token.component';
import {AutocompleteFilteredPipe, SassDirective} from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { GridClickableButtonFileImporterComponent } from './components/grid/grid-clickable-button-file-importer/grid-clickable-button-file-importer.component';
import { GridClickableButtonFileExporterComponent } from './components/grid/grid-clickable-button-file-exporter/grid-clickable-button-file-exporter.component';

const sharedComponents = [
    GridServerSideComponent,
    GridClientSideComponent,
    GridClickableButtonComponent,
    GridClickableGiftcardButtonComponent,
    GridClickablePromotionButtonComponent,
    GridSaveUserPreferenceComponent,
    GridClickableTypeButtonComponent,
    GridClickableHistorySalesButtonComponent,
    BarcodeScannerComponent,
    BarcodeComponent,
    CustomGridCheckboxAllComponent,
    GridStatusBarComponent,
    GridClickableButtonStaticTokenComponent,
    GridClickableCashMovementsButtonComponent,
    GridClickableButtonFileImporterComponent,
    GridClickableButtonFileExporterComponent
];
const directives = [TrackVisibilityDirective];

const sharedMaterialModules = [
    AgGridModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatDatepickerModule,
    MatInputModule,
    MatCardModule,
    MatProgressBarModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatTabsModule,
    MatCheckboxModule,
    MatMenuModule,
    MatRadioModule,
    MatAutocompleteModule,
    TranslateModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    SassDirective,
    AutocompleteFilteredPipe
];

@NgModule({
    declarations: [...sharedComponents, ...directives],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ...sharedMaterialModules,
        PipesModule,
        MatRadioModule,
        TranslateModule,
    ],
    providers: [],
    exports: [...sharedComponents, ...sharedMaterialModules]
})
export class SharedModule { }

import angular from 'angular';
import _ from 'lodash';
import { validate as validateUuid } from 'uuid';

angular.module('digitalPayments').factory('spiaggeIt', spiaggeIt);

spiaggeIt.$inject = ["$rootScope", "checkManager", "spiaggeItPayments", "spiaggeItApi", "bedzzleCommon", "entityManager"];

function spiaggeIt($rootScope, checkManager, spiaggeItPayments, spiaggeItApi, bedzzleCommon, entityManager) {
    var spiaggeIt = {
        payment: async (amount, options) => {
            let currentSale = options.sale;
            let folio;

            try {
                folio = await spiaggeItPayments.show({ amount: amount });
            } catch(error) {
                throw (_.isObject(error) ? 'CANCELED' : error);
            }

            if(!folio) {
                throw 'MISSING_FOLIO';
            }

            let transaction = await bedzzleCommon.buildTransaction(folio, currentSale, { disableQuantityRounding: true });

            try {
                let response = await spiaggeItApi.createTransaction(transaction);

                if(response.data.folioId) {
                    folio.folioId = response.data.folioId;
                    transaction.folioId = response.data.folioId;
                }

                var responseString = '';
                try { responseString = JSON.stringify(transaction); } catch (e) { }

                return {
                    acquirer_name: 'Spiagge.it',
                    payment_data: responseString,
                    tail: bedzzleCommon.buildTail(folio, currentSale, 'spiagge_it'),
                    unclaimed: true
                };
            } catch(error) {
                switch (error.status) {
                    case -1:
                        throw "SPIAGGE_IT_OFFLINE";
                    case 400:
                    case 401:
                        throw "SPIAGGE_IT_UNABLE_TO_COMPLETE_TRANSACTION";
                    default:
                        throw "UNKNOWN_ERROR";
                }
            }
        },
        refund: async(amount, options) => {
            let currentSale = options.sale;
            let refundResult = {};

            if(currentSale) {
                //Make sure that the referenced sale had a Spiagge.it payment and in that case perform the rollback
                let parentSaleId = currentSale.sale_parent_id || currentSale.sale_parent_uuid;

                if(parentSaleId) {
                    let parentSale;

                    if(validateUuid(parentSaleId)) {
                        let results = await entityManager.sales.fetchCollectionOnline({ uuid: parentSaleId });
                        parentSale = _.head(results);
                    } else {
                        parentSale = await entityManager.sales.fetchOneOfflineFirst(parentSaleId);
                    }

                    if(parentSale) {
                        let spiaggePayment = parentSale.payments.find((payment) => payment.payment_method_type_id === 29);

                        if(spiaggePayment) {
                            try {
                                refundResult = await spiaggeItApi.rollbackTransaction($rootScope.userActiveSession.shop.id, parentSale.uuid);
                            } catch(err) {
                                refundResult = err?.data?.error?.message;
                            }
                        }
                    }
                }
            }

            return {
                acquirer_name: 'Spiagge.it',
                payment_data: JSON.stringify(refundResult),
                unclaimed: true
            };
        },
        isEnabled: function() {
            return checkManager.getSetting('spiagge_it.property_id') ? true : false;
        },
        printFailHook: async (sale, printerDocumentData) => {
            let spiaggeItPayments = _.filter(sale.payments, { payment_method_type_id: 29 });

            if (!_.isEmpty(spiaggeItPayments)) {
                let rollbackStatus = 'SPIAGGE_IT_ROLLBACK_KO';

                try {
                    await spiaggeItApi.rollbackTransaction($rootScope.userActiveSession.shop.id, sale.uuid);
                    rollbackStatus = 'SPIAGGE_IT_ROLLBACK_OK';
                } catch(err) {}

                throw rollbackStatus;
            }
        }
    };

    return spiaggeIt;
}
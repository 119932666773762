import angular from 'angular';
import _ from 'lodash';
import moment from 'moment-timezone';

angular.module('digitalPayments').factory('eatsready', eatsready);

eatsready.$inject = ["$q", "eatsreadyPayments", "eatsreadyApi"];

function eatsready($q, eatsreadyPayments, eatsreadyApi) {
    var buildTail = function(response) {
        if(response) {
            var tailLines = [];
            tailLines.push(_.pad('EATSREADY', 46));
            tailLines.push("");
            if(response.customer && response.customer.displayName ) {
                tailLines.push("NAME:   " + response.customer.displayName);
            }
            tailLines.push("STATUS: " + response.order.status);
            tailLines.push("AMOUNT: " + _.toNumber(response.order.amount / 100).toFixed(2));
            if(response.restaurant) {
                tailLines.push("SHOP:   " + response.restaurant.name);
            }
            tailLines.push("TID:    " + response.order.id);
            tailLines.push("UUID:    " + response.order.uuid);
            tailLines.push("DATE:   " + moment(response.order.status_delivered).format('l LT'));
            tailLines.push("");
            return tailLines.join('\n');
        } else {
            return "";
        }
    };

    var eatsready = {
        payment: function(amount, options) {
            var d = $q.defer();
            let paymentMethod = options.paymentMethod;

            if(paymentMethod.unclaimed) {
                eatsreadyPayments.show({amount: _.round(amount * 100)}).then(function(payment) {
                    console.log(payment);
                    if(payment) {
                        eatsreadyApi.acceptPayment(payment).then(function(response) {
                            var responseString = '';
                            try { responseString = JSON.stringify(response); } catch(e) {}

                            d.resolve({
                                acquirer_name : "EatsReady",
                                payment_data: responseString,
                                tail: buildTail(response),
                                unclaimed: true
                            });
                        }, function(error) {
                            switch(error.status) {
                                case -1:
                                    d.reject("EATSREADY_OFFLINE");
                                break;
                                case 400:
                                case 401:
                                    d.reject("EATSREADY_UNABLE_TO_ACCEPT_PAYMENT");
                                break;
                                case 403:
                                case 404:
                                    d.reject("EATSREADY_PAYMENT_NOT_FOUND");
                                break;
                                default:
                                    d.reject("UNKNOWN_ERROR");
                                break;
                            }
                        });
                    }
                }, function(error) {
                    d.reject(_.isObject(error) ? 'CANCELED' : error);
                });
            } else {
                d.reject("EATSREADY_PAYMENT_IS_CLAIMED");
            }

            return d.promise;
        },
        isEnabled: function() {
            return true; //for now we only need this for the printFail hook, which will determine if actions are necessary
        },
        printFailHook: function(sale, printerDocumentData) {
            var d = $q.defer();
            var eatsreadyPayments = _.filter(sale.payments, { payment_method_type_id: 20 });

            if(!_.isEmpty(eatsreadyPayments)) {
                d.reject('EATSREADY_PRINT_FAILED');
            } else {
                d.resolve();
            }

            return d.promise;
        }
    };

    return eatsready;
}
import angular from 'angular';
import { BedzzleCommonFolio, BedzzleCommonTransaction } from 'app/modules/digital-payments/service/bedzzle/bedzzle-common';

export class BedzzleApiService {
    constructor(
        private restManager: any
    ) {
    }

    public setupShop(propertyId: string): Promise<any> {
        return this.restManager.post('bedzzle/setup', { propertyId: propertyId });
    }

    public getRoomFolios(): Promise<BedzzleCommonFolio[]> {
        return this.restManager.getList('bedzzle/room_folios');
    }

    public getMiscSalesFolios(): Promise<BedzzleCommonFolio[]> {
        return this.restManager.getList('bedzzle/misc_sales_folios');
    }

    public createTransaction(transactionData: BedzzleCommonTransaction) {
        return this.restManager.post('bedzzle/transactions', transactionData);
    }

    public rollbackTransaction(outletId: string, orderId: string) {
        return this.restManager.deleteOne('bedzzle/transactions', null, { orderId: orderId, outletId: outletId });
    }
};

BedzzleApiService.$inject = ['restManager'];

angular.module('application').service('bedzzleApi', BedzzleApiService);
<tilby-booking-table-element
    #tableElement
    class="tw-absolute"
    cdkDrag
    [cdkDragDisabled]="true"
    [cdkDragFreeDragPosition]="defaultDragPosition"
    [shapesPath]="'assets/images/tables/shapes/'"
    [furnituresPath]="'assets/images/tables/furnitures/'"
    [color]="isTablesSelected ? 'green' : 'grey'"
    [covers]="isTableMultiple ? (table.covers || 0) : (sale0?.covers || table.covers || 0)"
    [table]="table"
    [bookingInfo]="bookingInfo"
    (click)="onClick($event)"
></tilby-booking-table-element>

import moment from 'moment-timezone';

export type EpsonHttpResult = {
    success: boolean;
    code: string;
    status: number;
}

export type EpsonHttpAdditionalInfo = Record<string, string | null>;

export class EpsonUtils {
    public static getPrinterEndpoint(ipAddress: string, useSSL: boolean) {
        return `http${useSSL ? 's' : ''}://${ipAddress}/cgi-bin/fpmate.cgi`;
    }

    public static addSoapEnvelope(command: string) {
        return `<?xml version="1.0" encoding="utf-8"?><s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/"><s:Body>${command}</s:Body></s:Envelope>`;
    }

    public static getDirectIOCommand(command: string, data: string) {
        return `<printerCommand><directIO command="${command}" data="${data}" /></printerCommand>`;
    }


    public static parseCommercialDocumentDate(documentContent: string) {
        const date = documentContent.match(/(\d{2}-\d{2}-\d{4} \d{2}:\d{2})/);

        return date ? moment(date[0], 'DD-MM-YYYY HH:mm').toISOString() : null;
    }

    public static cleanupSaleDocument(documentContent?: string) {
        let saleDoc = documentContent;

        if(!saleDoc) {
            return null;
        }

        if (saleDoc.includes('?????')) {
            saleDoc = saleDoc.replace(/\?/g, ' ');
        }

        //Epson printers start each line of the document with 3 tabs.
        saleDoc = saleDoc.replace(/^[\t]{3}/gm, '');

        return saleDoc;
    }

    public static parseEpsonXml(responseData: { data: string, status: number }) {
        const parser = new DOMParser();
        //TODO: check the following line as it seems to cause issues with some Epson ePos printers.
        const text = `${responseData.data}`.replace(/ >/g, ' &gt;').replace(/ </g, ' &lt;');
        const xmlDoc = parser.parseFromString(text, "text/xml");

        const res = xmlDoc.getElementsByTagName('response');

        if (!res.length) {
            return {
                responseText: responseData.data,
                status: responseData.status
            };
        }

        const result: EpsonHttpResult = {
            success: /^(1|true)$/.test(res[0].getAttribute('success') || ''),
            code: res[0].getAttribute('code')!,
            status: parseInt(res[0].getAttribute('status') || '') || 0
        };

        let addInfo: EpsonHttpAdditionalInfo = {};

        const resAdd = res[0].getElementsByTagName('addInfo');

        if (resAdd.length) {
            const list = resAdd[0].getElementsByTagName('elementList');
            const tagNames = list[0].childNodes[0].nodeValue?.split(',') || [];

            for (let tagName of tagNames) {
                const node = resAdd[0].getElementsByTagName(tagName)[0];
                const nodeChild = node.childNodes[0];

                if (nodeChild) {
                    addInfo[tagName] = nodeChild.nodeValue;
                }
            }
        }

        return {
            result: result,
            addInfo: addInfo
        };
    }
}

import { AfterViewInit, Component, Input, OnDestroy, OnInit, computed, effect, inject } from '@angular/core';
import { ConnectionService, EntityManagerService, ToolbarEventsService } from 'src/app/core/services';
import { CurrentSection, ItemsEstended, PromotionsFields, PromotionsForm, PromotionsSearchItemsForm, PromotionType, StatusItemsSource } from '../promotions-form';
import { GridService } from 'src/app/shared/components/grid/grid.service';
import { ToolbarEventsContextService } from 'src/app/core/services/toolbar-events/toolbar-events-context.service';
import { ConfirmDialogService, OpenDialogsService } from 'src/app/dialogs';
import { $state } from 'app/ajs-upgraded-providers';
import { Categories, Channels, Suppliers } from 'tilby-models';
import { fromEvent } from 'rxjs';
import { UtilsPromotionsService } from '../../services';
import { CustomFormGroup } from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import { DevLogger } from 'src/app/shared/dev-logger';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';
import { TilbyDatePipe } from '@tilby/tilby-ui-lib/pipes/tilby-date';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
    selector: 'promotions-details',
    templateUrl: './promotions-details.component.html',
    styleUrls: ['./promotions-details.component.scss'],
    providers: [OnDestroyService]
  })
export class PromotionsDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly gridService = inject(GridService);
    private readonly entityManager = inject(EntityManagerService);
    private readonly toolbarEventsService = inject(ToolbarEventsService);
    private readonly toolbarEventsContextService = inject(ToolbarEventsContextService);
    private readonly confirmDialogService = inject(ConfirmDialogService);
    private readonly openDialogsService = inject(OpenDialogsService);
    private readonly connectionService = inject(ConnectionService);
    private readonly onDestroyService = inject(OnDestroyService);
    private readonly state = inject($state);
    private readonly utilsPromotionsService = inject(UtilsPromotionsService);
    private readonly tilbyDatePipe = inject(TilbyDatePipe);

    promotionForm!: CustomFormGroup<PromotionsForm>;
    promotionItemsSearchForm!: CustomFormGroup<PromotionsSearchItemsForm>;
    @Input() categories?: Array<Categories>;
    @Input() chainShops?: Array<any>;
    @Input() channels?: Array<Channels>;
    @Input() promotion?: PromotionsFields;
    @Input() suppliers?: Array<Suppliers>;
    private isNew = false;

    protected currentSection = computed(() => this.utilsPromotionsService.currentSection());
    private showConfirm = computed(() => this.utilsPromotionsService.currentSection() !== 'required-items');
    protected showTabs = computed(() => this.utilsPromotionsService.currentSection() !== 'required-items');

    statusItemsSource: StatusItemsSource = {
        itemsSourceAdd: [],
        itemsSourceRemoved: [],
        requiredItemsSourceAdd: [],
        requiredItemsSourceRemoved: []
    };
    constructor() {
        this.log('constructor');
        this.log('currentSection', this.currentSection());
        this.utilsPromotionsService.currentSection.set('general');
        effect(() => this.toolbarEventsContextService.isContextTabs.set(this.showTabs()), { allowSignalWrites: true });
    }

    // START - LIFECYCLE
    async ngOnInit() {
        this.log('OnInit');
        this.promotionForm = await this.utilsPromotionsService.createFormDetails(this.promotion, this.channels || []);
        this.promotionFormSubscriptions();
        // this.log('Input promotion', this.promotion);
        // this.log('Input categories', this.categories);
        // this.log('Input chainShops', this.chainShops);
        // this.log('Input channels', this.channels);
        // this.log('Input suppliers', this.suppliers);

        //-------- NEW GENERIC TOOLBAR - START ----///
        /**
         * Create buttons for the toolbar
         */
        this.isNew = this.state.$current.name.split('.').pop() === 'new';
        this.createToolbarButtons();
        //-------- NEW GENERIC TOOLBAR - END ----///
        this.toolbarEventsService.showButtonsBar$.next(true);
        this.toolbarEventsContextService.showToolbarContext$.next(true);
        this.contextToolbarLabelChange();
    }

    ngAfterViewInit() {
        // this.promotionForm = this.utilsPromotionsService.createFormDetails(this.promotion, this.channels || []);
        // this.promotionFormSubscriptions();
        //this.utilsPromotionsService.parsePromotionsData(this.promotion);
    }

    ngOnDestroy(): void {
        this.toolbarEventsService.showButtonsBar$.next(true);
        this.toolbarEventsContextService.showToolbarContext$.next(false);
    }
    // END - LIFECYCLE

    private createToolbarButtons() {
        this.toolbarEventsContextService.backButton$.next({
            isIt: () => true,
            name: 'arrow_back',
            icon: () => 'arrow_back',
            click: () => this.back()
        });

        this.toolbarEventsService.buttons$.next({
            barButtons: [
                {
                    isIt: () => this.connectionService.isOffline(),
                    name: 'cloud_off',
                    icon: () => 'cloud_off',
                    click: _ => null
                }
            ],
            panelButtons: []
        });

        this.toolbarEventsContextService.buttons$.next({
            barButtons: [
                {
                    isIt: () => this.showConfirm(),
                    name: 'save',
                    icon: () => 'check',
                    click: _ => this.onSubmit(this.promotionForm)
                },
                // {
                //     isIt: () => !!((this.promotionForm?.valid) && !this.isNew),
                //     name: 'delete',
                //     icon: () => 'delete',
                //     click: _ => this.openDeleteDialog()
                // },
            ],
            panelButtons: []
        });
    }

    private promotionFormSubscriptions() {
        this.promotion_type.valueChanges.pipe(this.onDestroyService.takeUntilDestroy).subscribe( (promotion_type: PromotionType) => {
            this.utilsPromotionsService.$promotion_type.set(promotion_type);
            switch (promotion_type) {
                case 'discount_perc': {
                    this.perc_discount.customProps.inputType = 'number';
                    this.base_pricelist.customProps.inputType = 'hidden';
                    this.quantity_threshold.customProps.inputType = 'hidden';
                    this.apply_below_threshold.customProps.inputType = 'hidden';
                    this.multiples_only.customProps.inputType = 'hidden';
                    this.force_pricelist_apply.customProps.inputType = 'hidden';
                } break;
                case 'discount_fix': {
                    this.perc_discount.customProps.inputType = 'hidden';
                    this.base_pricelist.customProps.inputType = 'select';
                    this.quantity_threshold.customProps.inputType = 'number';
                    this.apply_below_threshold.customProps.inputType = 'checkbox';
                    this.multiples_only.customProps.inputType = 'checkbox';
                    this.force_pricelist_apply.customProps.inputType = 'checkbox';
                } break;
            }
        });
        this.perc_discount.valueChanges.pipe(this.onDestroyService.takeUntilDestroy).subscribe((perc_discount) => {
            this.utilsPromotionsService.$perc_discount.set(perc_discount);
        });
        this.from_date?.valueChanges.pipe(this.onDestroyService.takeUntilDestroy).subscribe((from_date) => {
            this.log('from_date', from_date);
            this.to_date!.customProps.inputConstraint!.minDate = from_date;
        });

        setTimeout(() => this.listenRequiredItemsClick());
    }

    private listenRequiredItemsClick() {
        const requiredItemsControllerElement = document.getElementById('required_items_controller');
        const input = requiredItemsControllerElement?.querySelector('input');
        if (requiredItemsControllerElement && input) {
            fromEvent(input, 'focus').pipe(this.onDestroyService.takeUntilDestroy).subscribe($event => input.blur());
            fromEvent(requiredItemsControllerElement, 'click')
                .pipe(this.onDestroyService.takeUntilDestroy)
                .subscribe(($event) => {
                    $event.preventDefault();
                    this.log('REQUIRED_ITEMS_CONTROLLER', $event);
                    this.switchSection('required-items');
                });
        }
    }

    contextToolbarLabelChange() {
        const {name = ''} = this.promotion || {};
        this.toolbarEventsContextService.label = this.isNew ? 'PROMOTIONS.DETAILS.NEW_PROMOTION' : name;
    }

    parseWeekDays(weekdays_period: number[]) {
        return weekdays_period.map(day => {
            switch (day) {
                case 0: return 'Su';
                case 1: return 'Mo';
                case 2: return 'Tu';
                case 3: return 'We';
                case 4: return 'Th';
                case 5: return 'Fr';
                case 6: return 'Sa';
            }
        });
    }

    preparePromotionForm(formValue: any): PromotionsFields {
        this.log("formValue", formValue);
        const parseFormValue = {...formValue};
        parseFormValue.items = this.utilsPromotionsService.selectedItems.map((item: ItemsEstended) => ({
            item_sku: item.sku,
            id: item._prevId,
            discounted_price: parseFormValue.promotion_type === 'discount_fix' ? item._discountedPrice : null,
        }));

        parseFormValue.required_items = this.utilsPromotionsService.requiredItems.map((item: ItemsEstended) => ({
            item_sku: item.sku,
            id: item._prevReqId,
            quantity: item._quantity,
        }));

        if(formValue.promotion_type === 'discount_perc') {
            delete parseFormValue.base_pricelist;
            delete parseFormValue.quantity_threshold;
            delete parseFormValue.apply_below_threshold;
            delete parseFormValue.multiples_only;
            delete parseFormValue.force_pricelist_apply;
        } else {
            delete parseFormValue.perc_discount;
        }

        this.log("parseFormValue", parseFormValue);
        return {
            ...parseFormValue,
            from_date: parseFormValue.from_date ? this.tilbyDatePipe.transform(parseFormValue.from_date, 'yyyy-MM-dd') : this.tilbyDatePipe.transform(new Date(), 'yyyy-MM-dd'),
            to_date: parseFormValue.to_date ? this.tilbyDatePipe.transform(parseFormValue.to_date, 'yyyy-MM-dd') : null,
            start_time: `${parseFormValue.start_time}:00`,
            end_time: `${parseFormValue.end_time}:59`,
            weekdays_period: this.parseWeekDays(parseFormValue.weekdays_period),
            customer_types: parseFormValue.customer_types === 'none' ? null : parseFormValue.customer_types,
        }
    }

    async onSubmit(form?: CustomFormGroup<PromotionsForm>) {
        this.log("onSubmit", form, this.promotionForm);
        if (this.promotionForm?.invalid) {
            this.promotionForm?.markAllAsTouched();
        }

        if (form?.valid) {
            const promotionsForm = this.preparePromotionForm(form.value);
            promotionsForm.name = this.name?.value || '';
            promotionsForm.id = this.isNew ? undefined : this.promotion?.id;

            this.log("promotionsForm", promotionsForm);

            let promotionSave;
            if (promotionsForm.id) {
                promotionSave = await this.entityManager.promotions.putOneOnline(promotionsForm);
            } else {
                promotionSave = await this.entityManager.promotions.postOneOnline(promotionsForm);
            }

            if(promotionSave) {
                this.contextToolbarLabelChange();
                this.state.go(`app.new.promotions.details`, { promotionId: promotionSave.id, isNew: promotionsForm.id ? true : false });
                this.promotionForm.markAsPristine();
                this.openDialogsService.openSnackBarTilby('TOAST.SAVED.MESSAGE', 'TOAST.SAVED.ACTION',{duration:3000});
                this.state.go(`app.new.promotions`);
            }
        }
    }

    async back() {
        if(this.currentSection() === 'required-items') {
            this.required_items.setValue(this.utilsPromotionsService.requiredItems.length);
            this.promotionForm.markAsDirty();
            this.switchSection('general');
        } else {
            if(!this.promotion?.isNew) {
                if(this.promotionForm.pristine) {
                    if (this.promotion) this.gridService.promotions$.flash.next(this.utilsPromotionsService.onePromotionFormatter(this.promotion));
                    this.state.go("app.new.promotions");
                    return;
                }
            }

            const confirm = await this.confirmDialogService.openDialog({
                data: {
                    messageLabel: 'PROMOTIONS.DETAILS.EDITS_NOT_SAVED',
                    confirmLabel: 'APPLICATION.CONFIRM_DIALOG.YES',
                    cancelLabel: 'APPLICATION.CONFIRM_DIALOG.NO'
                }
            });
            if (confirm) {
                //if (this.promotion) this.gridService.promotions$.flash.next(this.utilsPromotionsService.onePromotionFormatter(this.promotion));
                this.state.go("app.new.promotions");
            }
        }
    }

    // check() {
    //     this.onSubmit(this.promotionForm);
    // }

    deleteRecord() {
        this.state.go('app.new.promotions', { remove: this.promotion?.id });
        // from<Promise<ChainPromotions>>(this.entityManager.promotions.deleteOneOnline(this.promotions.id).then(({results}) => results)).subscribe(r => this.state.go('app.new.promotions',{remove: this.promotions.id}));
    }

    async openDeleteDialog() {
        const confirm = await this.confirmDialogService.openDialog({
            data: {
                messageLabel: 'DIALOG.SUPPLIER_DELETE.TITLE',
                confirmLabel: 'DIALOG.SUPPLIER_DELETE.CONFIRM',
                cancelLabel: 'DIALOG.SUPPLIER_DELETE.CANCEL'
            }
        });

        if (confirm) {
            this.deleteRecord();
        }
    }

    protected switchSection(section: CurrentSection) {
        this.log('switch to section:', section);
        this.utilsPromotionsService.currentSection.set(section);
        this.log('currentSection', this.currentSection());

    }

    selectedTabChanged(event: MatTabChangeEvent) {
        console.log("click tab", event.tab);
        console.log("click origin", event.tab.origin);
        console.log('Index: ' + event.index);
        switch (event.index) {
            case 0:
                this.switchSection('general');
                break;
            case 1:
                this.switchSection('items');
                break;
        }
    }

    updatePromotionFormMarkAsDirty($event: any) {
        this.log('--- updateSource', $event);
        this.promotionForm.markAsDirty();
    }

    //GET FORM DETAILS
    get name() {
        return this.promotionForm?.controls.name;
    }
    get from_date() {
        return this.promotionForm?.controls.from_date;
    }
    get to_date() {
        return this.promotionForm?.controls.to_date;
    }
    get promotion_type() {
        return this.promotionForm?.controls.promotion_type;
    }
    get perc_discount() {
         return this.promotionForm?.controls.perc_discount;
     }
    get base_pricelist() {
        return this.promotionForm?.controls.base_pricelist;
    }
    get quantity_threshold() {
        return this.promotionForm?.controls.quantity_threshold;
    }
    get apply_below_threshold() {
        return this.promotionForm?.controls.apply_below_threshold;
    }
    get multiples_only() {
        return this.promotionForm?.controls.multiples_only;
    }
    get force_pricelist_apply() {
        return this.promotionForm?.controls.force_pricelist_apply;
    }
    get required_items() {
        return this.promotionForm?.controls.required_items;
    }

    private log(...args: any[]) {
        DevLogger.log('PROMOTIONS_DETAILS_COMPONENT', ...args);
    }
}

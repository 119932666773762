import { keyBy } from "src/app/shared/utils";

export type PaymentMethodTypeClass = "cash" | "digital" | "prepaid" | "ticket" | "pms";

export type PaymentMethodType = {
	id: number;
	name: string;
	class?: PaymentMethodTypeClass
}

export const paymentMethodTypes: PaymentMethodType[] = [
	{
		"id": 1,
		"name": "Contanti",
		"class": "cash"
	}, {
		"id": 2,
		"name": "Non riscosso"
	}, {
		"id": 3,
		"name": "Assegni"
	}, {
		"id": 4,
		"name": "Pagamento elettronico manuale",
		"class": "digital"
	}, {
		"id": 5,
		"name": "Pagamento elettronico generico",
		"class": "digital"
	}, {
		"id": 6,
		"name": "Ticket",
		"class": "ticket"
	}, {
		"id": 8,
		"name": "Bonifico"
	}, {
		"id": 10,
		"name": "Buono multiuso"
	}, {
		"id": 11,
		"name": "POS Ingenico mobile",
		"class": "digital"
	}, {
		"id": 13,
		"name": "POS Sumup",
		"class": "digital"
	}, {
		"id": 14,
		"name": "POS Protocollo 17",
		"class": "digital"
	}, {
		"id": 16,
		"name": "Credito prepagato",
		"class": "prepaid"
	}, {
		"id": 17,
		"name": "Canali"
	}, {
		"id": 18,
		"name": "Satispay",
		"class": "digital"
	}, {
		"id": 19,
		"name": "Cassetto Cashmatic",
		"class": "cash"
	}, {
		"id": 20,
		"name": "EatsReady",
		"class": "ticket"
	}, {
		"id": 21,
		"name": "Cassetto Cashlogy",
		"class": "cash"
	}, {
		"id": 22,
		"name": "PMS Bedzzle (Non riscosso)",
		"class": "pms"
	}, {
		"id": 23,
		"name": "PMS Beddy (Non riscosso)",
		"class": "pms"
	}, {
		"id": 24,
		"name": "Fattura differita (Non riscosso)"
	}, {
		"id": 25,
		"name": "Ricevuta SSN (Non riscosso)"
	}, {
		"id": 26,
		"name": "Sconto a pagare"
	}, {
		"id": 27,
		"name": "POS Verifone Ocius",
		"class": "digital"
	}, {
		"id": 28,
		"name": "PMS Lean (Non riscosso)",
		"class": "pms"
	}, {
		"id": 29,
		"name": "Spiagge.it (Non riscosso)",
		"class": "pms"
	},  {
		"id": 30,
		"name": "POS Mobile Nexi",
		"class": "digital"
	}, {
		"id": 31,
		"name": "POS Protocollo ZVT",
		"class": "digital"
	}, {
		"id": 32,
		"name": "Cassetto Cima",
		"class": "cash"
	}, {
		"id": 33,
		"name": "Gift Card"
	}, {
		"id": 34,
		"name": "Coverflex",
		"class": "digital"
	}, {
		"id": 35,
		"name": "POS Nexo",
		"class": "digital"
	}, {
		"id": 36,
		"name": "PMS generico Zucchetti",
		"class": "pms"
	}, {
		"id": 37,
		"name": "POS Redsys",
		"class": "digital"
	}, {
        "id": 38,
        "name": "Cassetto VNE",
        "class": "cash"
    }, {
        "id": 39,
        "name": "PMS Campgest",
        "class": "pms"
    }, {
        "id": 40,
        "name": "Cassetto Glory",
        "class": "cash"
    },{
        "id": 41,
        "name": "PMS Wellby",
        "class": "pms"
    }, {
        "id": 42,
        "name": "POS Argentea",
        "class": "digital"
    }
];

export const paymentMethodTypesById = keyBy(paymentMethodTypes, (m) => m.id);
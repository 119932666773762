import angular from 'angular';
import _ from 'lodash';

angular.module('fidelity').controller('FidelitySetupCtrl', ["$scope", "user", "$translate", "$filter", "util", "restManager", "entityManager", function($scope, user, $translate, $filter, util, restManager, entityManager) {
    $scope.topbar_context = {};
    $scope.user = user;

    $scope.topbar_context.mode = "setup";

    $scope.campaigns = [];
    $scope.rules = [];
    $scope.rulesMap = {};
    $scope.prizes = [];
    $scope.prizesMap = {};

    $scope.isCampaignsView = function() {
        return $scope.topbar_context.view === "campaigns";
    };

    $scope.isRulesView = function() {
        return $scope.topbar_context.view === "rules";
    };

    $scope.getPrizeType = function(prize) {
        var prizeType;

        switch(prize.type) {
            case 'discount_fix':
                prizeType = $translate.instant('FIDELITY.PRIZES.PRIZE_DIALOG.DISCOUNT', { value: $filter('sclCurrency')(prize.discount_amount) });
            break;
            case 'discount_perc':
                prizeType = $translate.instant('FIDELITY.PRIZES.PRIZE_DIALOG.DISCOUNT', { value: util.round(prize.discount_amount) + '%' });
            break;
            case 'gift':
                prizeType = $translate.instant('FIDELITY.PRIZES.PRIZE_DIALOG.GIFT', { value: _.get($scope.itemsMap, [prize.item_sku, 'name'], '') });
            break;
            default: break;
        }

        return prizeType;
    };

    entityManager.campaigns.fetchCollectionOnline({}).then(function(result) {
        $scope.campaigns = result || [];
    });

    restManager.getList("rules", { pagination: false }).then(function(result) {
        $scope.rules = result || [];
        $scope.rulesMap = _.keyBy($scope.rules, 'id');
    });

    restManager.getList("prizes", { pagination: false }).then(function(result) {
        $scope.prizes = result || [];
        $scope.prizesMap = _.keyBy($scope.prizes, 'id');
        $scope.prizesByCampaign = _.groupBy($scope.prizes, 'campaign_id');

        _.forEach($scope.prizes, function(prize) {
            prize._typeString = $scope.getPrizeType(prize);
        });
    });

    entityManager.items.fetchCollectionOffline().then(function(result) {
        $scope.items = _(result).filter(function(item) { return item.sku; }).sortBy('name').value();
        $scope.itemsMap = _.keyBy($scope.items, 'sku');
    });
}]);

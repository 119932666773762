import angular from 'angular';
import * as async from 'async';
import $ from 'jquery';
import _ from 'lodash';

angular.module('application').factory('ImagesManager', ["$q", "restManager", function($q, restManager) {
    var imagesManager = {
        localLoadNewImage: function(fileHandle, maxImageWidth, maxImageHeight) {
            var d = $q.defer();

            // processo: controlla che sia immagine, apri immagine -->str, comprimi (eventuale) (str --> str), due out (str --> str,bin)
            if (!checkIsAnImage(fileHandle)) {
                d.reject("NOT_AN_IMAGE");
            } else {
                var imageNewName = [fileHandle.name, Math.random().toString(36).substr(2, 20)].join('_');
                var extension = "." + _.last(fileHandle.name.split("."));

                openImageAsURL(fileHandle).then(function(dataAsURL) {
                    resizeImage(dataAsURL, maxImageWidth, maxImageHeight).then(function(resized) {
                        d.resolve({
                            name: imageNewName + (resized.newExtension || extension),
                            image_url: resized.data,
                            data: convertToUint8(resized.data),
                            uploaded: false
                        });
                    }, function(reason) {
                        d.reject("IMAGE_COMPRESSION_FAILED");
                    });
                }, function(reason) {
                    d.reject("IMAGE_OPEN_FAILED");
                });
            }

            return d.promise;
        },

        getImage: function(name) {
            var d = $q.defer();

            restManager.getOne("media", name).then(function(resultAPI) {
                d.resolve(resultAPI.destinationUrl);
            }, function(reason) {
                d.reject("IMAGE_MEDIA_CALL_FAILED");
            });

            return d.promise;
        },

        saveAllImages: function(images) {
            var d = $q.defer();

            _.remove(images, function(image) { return image.deleted; });

            async.eachSeries(_.filter(images, { uploaded: false }), function(image, cb) {
                uploadImage(image).then(function(result) {
                    cb(null);
                }, function(error) {
                    cb(error);
                });
            }, function(err, res) {                
                if(err) {
                    d.reject(err);
                } else {
                    d.resolve();
                }
            });

            return d.promise;
        },

        markToDelete: function(image) {
            image.deleted = true;
        }
    };

    var uploadImage = function(image) {
        var d = $q.defer();

        restManager.getOne("media", image.name).then(function(resultAPI) {
            $.ajax({
                url: resultAPI.presignedUrl,
                type: 'PUT',
                data: image.data,
                contentType: '',
                processData: false
            }).done(function(resultUpload) {
                _.assign(image, {
                    image_url: resultAPI.destinationUrl,
                    uploaded: true,
                    data: []
                });

                d.resolve();
            }).fail(function() {
                _.assign(image, {
                    image_url: '',
                    data: []
                });

                d.reject("IMAGE_UPLOAD_FAILED");
            });
        }, function(reason) {
            d.reject("failed call to media endpoint");
        });

        return d.promise;
    };

    var resizeImage = function(dataAsURL, maxImageWidth, maxImageHeight) {
        var d = $q.defer();
        var img = new Image();

        img.onload = function() {
            if (img.height < maxImageHeight && img.width < maxImageWidth) {
                d.resolve({ data: dataAsURL });
            }

            var scaleDownFactor = Math.max(img.height / maxImageHeight, img.width / maxImageWidth);

            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');

            canvas.width = img.width / scaleDownFactor;
            canvas.height = img.height / scaleDownFactor;

            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            var imageScaled = canvas.toDataURL();

            d.resolve({ data: imageScaled, newExtension: ".png" });
        };

        img.onerror = function() {
            d.reject("error opening image");
        };

        img.src = dataAsURL;

        return d.promise;
    };

    var openImageAsURL = function(imageHandle) {
        var d = $q.defer();
        var fr = new FileReader();

        fr.onload = function(e) {
            var result = e.target.result;
            d.resolve(result);
        };

        fr.onerror = function(e) {
            d.reject("error opening file");
        };

        fr.readAsDataURL(imageHandle);

        return d.promise;
    };

    var checkIsAnImage = function(fileHandle) {
        switch (fileHandle.type) {
            case "image/jpeg":
            case "image/png":
            case "image/gif":
            case "image/bmp":
                return true;
            default:
                return false;
        }
    };

    var convertToUint8 = function(data) {
        var BASE64_MARKER = ';base64,';
        var base64Index = data.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        var base64 = data.substring(base64Index);
        var raw = window.atob(base64);
        var rawLength = raw.length;
        var array = new Uint8Array(new ArrayBuffer(rawLength));

        for (var i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }
        return array;
    };

    return imagesManager;
}]);
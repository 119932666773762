import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment-timezone';

angular.module('cashregister').factory('beddyPayments', beddyPayments);

beddyPayments.$inject = ["$mdDialog", "$timeout", "$translate", "$filter", "beddyApi", "checkManager", "util"];

function beddyPayments($mdDialog, $timeout, $translate, $filter, beddyApi, checkManager, util) {
    beddyPaymentsController.$inject = ["$scope", "options"];

    function beddyPaymentsController($scope, options) {
        Object.assign($scope,  {
            amountToPay: util.round(options.amount),
            fetchingFolios: false,
            isOffline: false,
            needsSetup: false,
            operationInProgress: false,
            propertyId: '',
            selectedFolio: null,
            showAmount: !(checkManager.getPreference('pms_payments.hide_total_amount')),
            showBalance: !(checkManager.getPreference('pms_payments.hide_balance')),
            visibleFolios: []
        });

        let folios = [];

        $scope.$on("connection:changed", function(event, data) {
            $scope.isOffline = data.status === 'offline';
        });

        $scope.pairShop = async () => {
            if(!_.isEmpty($scope.propertyId)) {
                $scope.operationInProgress = true;
                $scope.message = $translate.instant('CASHREGISTER.BEDDY_PAYMENTS.ENABLING');

                try {
                    await beddyApi.setupShop($scope.propertyId);

                    $scope.message = $translate.instant('CASHREGISTER.BEDDY_PAYMENTS.ENABLE_SUCCESSFUL');
                    $scope.needsSetup = false;

                    $timeout($scope.deleteMessage, 1500);
                    fetchFolios();
                } catch(error) {
                    $('#BeddyPropertyIdField').trigger('focus');

                    switch(_.get(error, 'status')) {
                        case -1:
                            $scope.message = '';
                            break;
                        case 404: case 403:
                            $scope.message = $translate.instant('CASHREGISTER.BEDDY_PAYMENTS.INVALID_PROPERTY_ID');
                            break;
                        default:
                            $scope.message = $translate.instant('CASHREGISTER.BEDDY_PAYMENTS.ENABLE_FAILED');
                            break;
                    }
                } finally {
                    $scope.operationInProgress = false;
                }
            }
        };

        $scope.deleteMessage = function() {
            $scope.message = '';
        };

        $scope.isMiscSale = (folio) => _.isNil(folio.stayId);

        $scope.parseBeddyDate = (date) => moment(date, 'YYYY-MM-DD').format('L');

        $scope.performSearch = function() {
            $scope.visibleFolios = folios;

            _.chain($scope.searchText).words().forEach((word) => {
                $scope.visibleFolios = $filter('filter')($scope.visibleFolios, word);
            }).value();

            if(!$scope.visibleFolios.find((folio) => folio === $scope.selectedFolio)) {
                $scope.selectedFolio = null;
            }
        };

        const fetchFolios = async () => {
            if(!$scope.$$destroyed) {
                $scope.fetchingFolios = true;

                try {
                    let results = await beddyApi.getRoomFolios();
                    folios = _.sortBy(results.folios, 'keyDoorCode');

                    if(!checkManager.getPreference('beddy.disable_rooms_filter')) {
                        folios = folios.filter((folio) => {
                            if(folio.checkInStatus === undefined) {
                                return true;
                            }

                            return (folio.checkInStatus === 1 && folio.checkOutStatus === 0);
                        });
                    }
                    
                    $scope.visibleFolios = folios;
                } catch(error) {
                    if(_.isObject(error)) {
                        switch(error.status) {
                            case -1:
                                $timeout(fetchFolios, 1000);
                            break;
                            case 403:
                                $scope.needsSetup = true;
                            break;
                            default:
                                if(_.get(error, 'data.error.message.error_code') === 'CONNECTION_ERROR') {
                                    $mdDialog.cancel('BEDDY_OFFLINE');
                                } else {
                                    $mdDialog.cancel('UNKNOWN_ERROR');
                                }
                            break;
                        }
                    } else {
                        $mdDialog.cancel('UNKNOWN_ERROR');
                    }
                } finally {
                    $scope.fetchingFolios = false;
                }
            }
        };

        if (!_.isObject(options)) {
            options = {};
        }

        $scope.cancel = function() {
            $mdDialog.cancel('CANCELED');
        };

        $scope.selectFolio = function(folio) {
            $scope.selectedFolio = folio;
        };

        $scope.confirm = function() {
            if($scope.selectedFolio) {
                $mdDialog.hide($scope.selectedFolio);
            }
        };

        $timeout(function() {
            fetchFolios();
        });
    }

    const beddyPaymentsDialog = {
        show: function(options) {
            return $mdDialog.show({
                controller: beddyPaymentsController,
                template: require('./beddy-payments.html'),
                locals: {
                    options: options
                }
            });
        }
    };

    return beddyPaymentsDialog;
}

import angular from 'angular';

angular.module('application').directive('sclTab', function() {
    return function(scope, element, attrs) {
        element.on("keydown keypress", function(event) {
            if (event.which === 9) {
                scope.$apply(function() {
                    scope.$eval(attrs.sclTab, {
                        'event': event
                    });
                });
            }
        });
    };
});
import angular from 'angular';
import _ from 'lodash';

function kioskCustomerSelectionCtrl($scope, $translate, $state, kioskSettings, kioskUtils, barcodeManager, restManager, alertDialog, checkManager, migrationHelper) {
    const ActiveSale = migrationHelper.getActiveSale();

    if(kioskSettings['customer_selection.enabled'] === false) {
        kioskUtils.skipModule();
    } else {
        var buttonTextColor = kioskSettings['general.base_button_text_color'] || '#fff';
        var buttonBackgroundColor = kioskSettings['general.base_button_bgcolor'] || '#666';
        var baseTextColor = kioskSettings['general.base_text_color'] || '#000';
        var zoomLevel = kioskSettings['general.zoom_level'] || 1.5;

        _.assign($scope, {
            sale: ActiveSale,
            barcodeManager: barcodeManager,
            headerParams: {
                bannerUrl: kioskSettings['customer_selection.banner_url'],
                bannerBgColor: kioskSettings['general.header_background_color'],
                bannerHeight: kioskSettings['general.header_height'],
                logoUrl: _.toString(kioskSettings['general.media_url_logo']),
                zoomLevel: zoomLevel,
            },
            customerAssistantEnabled: kioskSettings['general.customer_assistant'],
            title: kioskUtils.getLabelText(kioskSettings, 'customer_selection.title_label', 'KIOSK.CUSTOMER_SELECTION.TITLE'),
            totalLabel: kioskUtils.getLabelText(kioskSettings, 'cart.total_label', 'KIOSK.TOTAL'),
            backButtonLabel: kioskUtils.getLabelText(kioskSettings, 'general.back_button_label', 'KIOSK.BACK'),
            iconStyle: { color: buttonTextColor },
            buttonStyle: {
                color: buttonTextColor,
                'background-color': buttonBackgroundColor
            },
            customerSelectionStyle: {
                'zoom': zoomLevel,
                color: baseTextColor
            },
            mainText: kioskUtils.getLabelText(kioskSettings, 'customer_selection.main_text', 'KIOSK.CUSTOMER_SELECTION.MAIN_TEXT'),
            mainTextInvoice: kioskUtils.getLabelText(kioskSettings, 'customer_selection.main_text_invoice', 'KIOSK.CUSTOMER_SELECTION.MAIN_TEXT_INVOICE'),
            country: checkManager.getShopCountry(),
            createLabel: kioskUtils.getLabelText(kioskSettings, 'customer_selection.create_label', 'KIOSK.CUSTOMER_SELECTION.CREATE'),
            guestLabel: kioskUtils.getLabelText(kioskSettings, 'customer_selection.guest_label', 'KIOSK.CUSTOMER_SELECTION.GUEST'),
            canCreateCustomer: kioskSettings['customer_selection.creation_enabled'] ? true : false,
            backgroundUrl: kioskSettings['customer_selection.background_url']
        });

        if($scope.backgroundUrl) {
            $scope.mediaType = kioskUtils.getMediaType($scope.backgroundUrl);
        }

        $scope.help = () => {
            // @toDO
            console.debug('AIUTO Da completare');
        };

        $scope.next = function() {
            $scope.$parent.enableTimeouts();
            $state.go(kioskUtils.getNextModule());
        };

        $scope.back = function() {
            $scope.sale.removeCustomer();
            $scope.$parent.enableTimeouts();
            $state.go(kioskUtils.getPreviousModule());
        };

        $scope.isInvoice = _.includes(['generic_invoice', 'invoice', 'e_invoice'], _.get(ActiveSale, ['printerDocumentData', 'document_type', 'id']));

        $scope.canUseGuest = function() {
            return !$scope.isInvoice && !kioskSettings['customer_selection.mandatory'];
        };

        var confirmCustomer = function() {
            if($scope.isInvoice) {
                restManager.post('/sales/e_invoice/check', _.cloneDeep(ActiveSale.currentSale)).then(function(result) {
                    $scope.next();
                }, function(error) {
                    alertDialog.show(ActiveSale.currentSale.customer_tax_code ? $translate.instant('KIOSK.CUSTOMER_SELECTION.SIMPLIFIED_INVOICE_ERROR') : $translate.instant('KIOSK.CUSTOMER_SELECTION.INVOICE_ERROR')).finally(function() {
                        $scope.back();
                    });
                });
            } else {
                $scope.next();
            }
        };

        $scope.$on("barcodeManager:Customer", function(event, data) {
            if (data.customer) {
                ActiveSale.addCustomer(data.customer).then(function() {
                    confirmCustomer();
                });
            } else if(data.fidelity) {
                alertDialog.show($translate.instant('CASHREGISTER.SHOWCASE.NO_CLIENT_WITH_THIS_FIDELITY'));
            } else if(data.taxCode) {
                ActiveSale.addTaxCode(data.taxCode);
                confirmCustomer();
            }
        });

        $scope.sale.removeCustomer();
    }
}

kioskCustomerSelectionCtrl.$inject = ["$scope", "$translate", "$state", "kioskSettings", "kioskUtils", "barcodeManager", "restManager", "alertDialog", "checkManager", "migrationHelper"];

angular.module('kiosk').controller('KioskCustomerSelectionCtrl', kioskCustomerSelectionCtrl);

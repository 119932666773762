import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import { v4 as generateUuid } from 'uuid';

angular.module('promotions').factory('labelsEditorDialog', ["$mdDialog", "$translate", "checkManager", "itemLabelsGenerator", function($mdDialog, $translate, checkManager, itemLabelsGenerator) {
    logoEditorController.$inject = ["$scope"];
    function logoEditorController($scope) {
        var mockPromotion =  {
            name: "Promozione di prova",
            from_date: "2020-01-01",
            to_date: "2020-12-31",
            start_time: "00:00:00",
            end_time: "23:59:59",
            base_pricelist: 1,
            items: [
                {
                    id: 85,
                    name: "Prodotto di prova",
                    order_name: "Descrizione breve",
                    description: "Descrizione lunga",
                    price1: 5.00,
                    discounted_price: 2.00,
                }
            ]
        };

        $scope.addTemplate = function() {
            var newTemplate = _.head(itemLabelsGenerator.getDefaultTemplates());
            _.assign(newTemplate, {
                name: $translate.instant('PROMOTIONS.LABELS_EDITOR_DIALOG.NEW_TEMPLATE'),
                id: generateUuid()
            });

            $scope.templates.push(newTemplate);
            $scope.template = newTemplate;
            $scope.onTemplateChange();
        };

        $scope.confirm = function() {
            $scope.loadTemplate();

            var templatesToSave = angular.copy($scope.templates);

            _.forEach(templatesToSave, function(template) {
                delete template.$$mdSelectId;
            });

            checkManager.setShopPreference('promotions.label_templates', JSON.stringify(templatesToSave));
            $mdDialog.hide();
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        if(checkManager.getPreference('promotions.label_templates')) {
            try {
                $scope.templates = JSON.parse(checkManager.getPreference('promotions.label_templates'));
            } catch(e) {
                $scope.templates = null;
            }
        }

        if(_.isNil($scope.templates)) {
            $scope.templates = itemLabelsGenerator.getDefaultTemplates();
        }

        $scope.template = _.head($scope.templates);

        var updateTemplateInput = function() {
            $scope.labelsTemplateInput = JSON.stringify($scope.template, null, 4);
        };

        var generatePreview = function() {
            var previewData = itemLabelsGenerator.generateLabel(_.get($scope, 'template.data'), mockPromotion, 1, _.head(mockPromotion.items));
            $('#editorPreview').html(previewData);
            $('#editorPreview > svg').css({
                border: '1px solid',
                height: $scope.template.data.height,
                width: $scope.template.data.width
            });
        };

        $scope.onTemplateChange = function() {
            updateTemplateInput();
            generatePreview();
        };

        $scope.loadTemplate = function() {
            try {
                var template = JSON.parse($scope.labelsTemplateInput);
                angular.copy(template, $scope.template);
                $scope.onTemplateChange();
            } catch(e) {

            }
        };

        $scope.onTemplateChange();
    }

    var logoEditor = {
        show: function() {
            return $mdDialog.show({
                controller: logoEditorController,
                template: require('./labels-editor-dialog.html'),
                onComplete: function($scope) {
                    $scope.onTemplateChange();
                }
            });
        }
    };

    return logoEditor;
}]);

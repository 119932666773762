import angular from 'angular';
import { iot, mqtt5 } from "aws-crt/dist.browser/browser";
import { AWS_CREDENTIALS, EVENTS_IOT, GetAPi, IotEmitter, IotError, MessagingConfig, Provider } from "./iot-models";
import { IotMessageManager } from "./iot-message-manager";
import { WebsocketSigv4Config } from 'aws-crt/dist.browser/browser/aws_iot_mqtt5';
import { ConfigurationManagerService } from 'src/app/core/services/configuration-manager';
import { Subscription, asyncScheduler } from 'rxjs';
import { EnvironmentConfig } from 'src/environments/environment-config';
const localStorageIot = "local_storage_iot";

export class IotConnectionManager {
    private messagingConfig?: MessagingConfig;
    private client?: mqtt5.Mqtt5Client;
    private AWS_IOT_ENDPOINT?: string;
    private connectionExpire?: Date;
    private topic: string;
    private iotRefresher: Subscription | undefined;
    private provider: Provider;
    private configConnection?: WebsocketSigv4Config;

    constructor(
        private $rootScope: any,
        private $window: any,
        private errorsLogger: any,
        private restManager: any,
        private iotMessageManager: IotMessageManager,
        private checkManager: ConfigurationManagerService
    ) {
        this.topic = (EnvironmentConfig.environment === 'local') ? 'local_shop' : 'shop';
        this.provider = new Provider();
    }

    private logger(...args: any[]) {
        this.errorsLogger.debug('[IotConnectionManager]: ', ...args);
    }

    private logger_error(...args: any[]) {
        this.errorsLogger.err('[IotConnectionManager]: ', ...args);
    }

    /**
     * Logs an event using errorsLogger.sendReport
     * @param {*} message
     * @returns
     */
    private logMessagingEvent = (message: string, type: any, send_anyway: boolean) => {
        if (send_anyway == true) {
            this.errorsLogger.sendReport({
                type: type,
                content: message
            });
            return;
        }
        if (this.checkManager.getSetting("messaging.iot_send_report")) {
            this.errorsLogger.sendReport({
                type: type,
                content: message
            });
            return;
        }
    };
    /**
     * @description try to reset connection after 10 seconds. If connection fails
     * @param force_new_connection 
     */
    async close_and_reopen_connection(force_new_connection: boolean, time_reconnect: number) {
        try {
            if (this.client) {
                this.client.stop();
            }
        } catch (error) {
            // if it gives error no problem. this.connect will generare a new istance
        }
        asyncScheduler.schedule(() => this.connect(force_new_connection), time_reconnect);
    }

    bootstrap(MessagingConfig: MessagingConfig) {
        this.messagingConfig = MessagingConfig;
        this.iotMessageManager.bootstrap(MessagingConfig);
    }

    getOne: GetAPi = async (entityName, entityID, queryString) => {
        return this.restManager.getOne(entityName, entityID, queryString);
    }
    private emitter: IotEmitter = (event_name: EVENTS_IOT, message: any) => {
        this.$rootScope.$emit(event_name, { message });
    }
    /**
     * @description if fetch cloud fails => EVENTS_IOT.iot_need_login is launched. This event starts chron to retry connection
     * @returns 
     */
    private async fetch_credentials_node(): Promise<AWS_CREDENTIALS> {
        try {
            let res: AWS_CREDENTIALS = await this.getOne('notifications/login', undefined, undefined)
            this.connectionExpire = new Date(res.expiration);
            this.AWS_IOT_ENDPOINT = res.endpoint_iot;
            this.$window.localStorage.setItem(localStorageIot, JSON.stringify(res));
            this.logger("fetch_credentials_node");
            this.provider.refreshCredentialsFromApi(res);
            this.provider.refreshCredentials();
            if (this.configConnection) {
                this.configConnection.credentialsProvider = this.provider;
                this.configConnection.region = res.region_iot;
            } else {
                this.configConnection = {
                    credentialsProvider: this.provider,
                    region: res.region_iot
                }
            }
            return res;

        } catch (err: any) {
            if (err?.status !== -1 && err?.status !== 403 && err != "MISSING_OAUTH_DATA") {
                this.logMessagingEvent(`Error in login ${JSON.stringify(err)}`, 'iot-login', true);
            } else {
                this.logger('can not get credentials', err);
            }

            this.emitter(EVENTS_IOT.iot_need_login, { message: 'login failed' });
            throw new IotError('Error fetching cloud', err);
        }
    }

    private async fetch_credentials_local(force_new_connection: boolean, force_refresh: boolean): Promise<AWS_CREDENTIALS> {
        try {
            if (force_new_connection === true) {
                throw 'no_credentials'
            }
            let credentials: string = this.$window.localStorage.getItem(localStorageIot);
            this.logger("fetch_credentials_local");
            // this.logger("local credentials", credentials);
            if (credentials !== null && credentials !== undefined) {
                let res: AWS_CREDENTIALS = JSON.parse(credentials);
                let exp = new Date(res.expiration);
                // this is set to understand
                let iot_refresher_timer = parseInt(this.checkManager.getSetting("messaging.iot_refresher_timer") || '') || (60 * 14 * 1000);
                if ((isNaN(exp.getTime())) || exp.getTime() < Date.now() + iot_refresher_timer || res.shop_name !== this.messagingConfig?.shop) {
                    throw 'no_credentials';
                } else {
                    if (force_refresh == true) {
                        this.connectionExpire = new Date(res.expiration);
                        this.AWS_IOT_ENDPOINT = res.endpoint_iot;
                        this.logger("refreshed_local_credentials")
                        this.provider.refreshCredentialsFromApi(res);
                        this.provider.refreshCredentials();
                        if (this.configConnection) {
                            this.configConnection.credentialsProvider = this.provider;
                            this.configConnection.region = res.region_iot;
                        } else {
                            this.configConnection = {
                                credentialsProvider: this.provider,
                                region: res.region_iot
                            }
                        }
                    }
                    return res;
                }
            } else {
                throw 'no_credentials'
            }
        } catch (error) {
            this.logger('login local failed', error);
            throw 'no_credentials'
        }
    }
    private async getCredentials() {
        await this.fetch_credentials_local(false, false).catch(async (err) => {
            await this.fetch_credentials_node();
            this.logger("refreshedCredentials", this.provider.credentials);
            return "ok"
        });

        this.refreshCredentials();
    }
    private refreshCredentials() {
        if (this.iotRefresher) {
            this.iotRefresher.unsubscribe();
            this.iotRefresher = undefined;
        }
        let iot_refresher_timer = parseInt(this.checkManager.getSetting("messaging.iot_refresher_timer") || '') || (60 * 14 * 1000);
        this.iotRefresher = asyncScheduler.schedule(() => this.getCredentials(), iot_refresher_timer);
    }
    async connect(force_new_connection: boolean): Promise<boolean> {
        try {

            await this.fetch_credentials_local(force_new_connection, true).catch(async (err) => {
                return await this.fetch_credentials_node();
            });
            this.logger("CONNECTION EXPIRES: ", this.connectionExpire);

            let builder = iot.AwsIotMqtt5ClientConfigBuilder.newWebsocketMqttBuilderWithSigv4Auth(this.AWS_IOT_ENDPOINT!, this.configConnection!);
            this.client = new mqtt5.Mqtt5Client(builder.build());
            this.client.on('connectionSuccess', (session_present) => {
                this.client!.subscribe({
                    subscriptions: [
                        { qos: mqtt5.QoS.AtMostOnce, topicFilter: `${this.topic}/${this.messagingConfig!.shop}` }
                    ]
                });
            });
            this.client.on('connectionFailure', (e) => {
                this.logger_error('connectionFailure', e);
            });
            this.client.on('attemptingConnect', (e) => this.logger("attemptingConnect", e));
            this.client.on('disconnection', (e) => this.logger_error("disconnection", e));
            this.client.on('info', (e) => this.logger("info", e));
            this.client.on('error', (e) => this.logger_error("error", e));
            this.client.on('stopped', (e) => this.logger("stopped", e));
            this.client.on('messageReceived', (message) => this.iotMessageManager.messageIot(message));
            this.client.start();
            this.refreshCredentials();
            return true;
        } catch (error) {
            // log error and send event to retry login. This will happen for ever every 10 seconds
            if (error instanceof IotError) {
                this.logger_error(error);
                throw new IotError('Failing connection', error);
            } else {
                this.logger_error('error login iot', error);
                this.emitter(EVENTS_IOT.iot_need_login, {});
                return false;
            }
        };
    }
}

IotConnectionManager.$inject = ['$rootScope', '$window', 'errorsLogger', 'restManager', 'IotMessageManager', 'checkManager'];
angular.module('core').service('IotConnectionManager', IotConnectionManager);

<app-grid-client-side
    #appGridComponent
    [tableData]="tableData[0]"
    [fixedButtons]="fixedButtons"
    [idField]="'id'"
    feature="file_importer"
></app-grid-client-side>

@if(showProgressSpinner){    
    <div class="mat-progress-spinner-background">
        <mat-progress-spinner class="custom-spinner" [mode]="'indeterminate'" [value]="50" [diameter]="200" strokeWidth="3"></mat-progress-spinner>
    </div>
}
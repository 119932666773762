<ng-container>
    <button mat-icon-button [matMenuTriggerFor]="$any(menu)" (click)="preventSelection($event)"
            aria-label="Example icon-button with a menu"
            class="horizontal-menu-element">
        <mat-icon>more_horiz</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="goToDetails($event)">{{'PROMOTIONS.SHOWCASE.PROMOTION_DETAILS' | translate}}</button>
        <button mat-menu-item (click)="togglePromotion($event)">{{'PROMOTIONS.SHOWCASE.TOGGLE_PROMOTION' | translate}}</button>
        <button mat-menu-item (click)="duplicatePromotion($event)">{{'PROMOTIONS.SHOWCASE.DUPLICATE_PROMOTION' | translate}}</button>
        <button mat-menu-item (click)="generateLabels($event)" [disabled]="hideGenerateLabels()">{{'PROMOTIONS.SHOWCASE.GENERATE_LABELS' | translate}}</button>
        <button mat-menu-item (click)="deletePromotion($event)">{{'PROMOTIONS.SHOWCASE.DELETE' | translate}}</button>
    </mat-menu>
</ng-container>

import { Component, EventEmitter, Input, OnInit, Output, computed, effect, inject, AfterViewInit, ViewChild } from '@angular/core';
import { CustomFormGroup } from '@tilby/tilby-ui-lib/components/tilby-magic-form';
import { Categories, Channels, Suppliers } from 'tilby-models';
import { ItemsEstended, PromotionsFields, PromotionsSearchItemsForm, TableItemsPromotion, StatusItemsSource } from '../promotions-form';
import { UtilsPromotionsService } from '../../services';
import { TilbyMagicFormComponent } from "@tilby/tilby-ui-lib/components/tilby-magic-form";
import { DevLogger } from 'src/app/shared/dev-logger';
import { debounceTime, distinctUntilChanged, skip } from 'rxjs';
import { OnDestroyService } from 'src/app/core/services/on-destroy.service';
import { CommonModule } from '@angular/common';
import { MatTable, MatTableDataSource, MatTableModule } from "@angular/material/table";
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { TilbyCurrencyPipe } from "@tilby/tilby-ui-lib/pipes/tilby-currency";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { FormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MathUtils } from 'src/app/shared/utils';

type ColumnDef = {
    columnDef: string;
    header: string;
    cell: (element: TableItemsPromotion) => string | number;
    changeCell: (event: string | number, element: TableItemsPromotion) => void;
    showInModeADD: boolean;
    showInModeREMOVE: boolean;
};
//-----

@Component({
    selector: 'promotions-items',
    templateUrl: './promotions-items.component.html',
    styleUrl: './promotions-items.component.scss',
    imports: [
        CommonModule,
        TilbyMagicFormComponent,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        TilbyCurrencyPipe,
        MatIconModule,
        TranslateModule,
        MatButtonModule,
        MatInputModule,
        FormsModule,
    ],
    standalone: true,
    providers: [OnDestroyService]
})
export class PromotionsItemsComponent implements OnInit, AfterViewInit {
    private readonly utilsPromotionsService = inject(UtilsPromotionsService);
    private readonly onDestroyService = inject(OnDestroyService);
    private readonly translate = inject(TranslateService);
    private readonly tilbyCurrencyPipe = inject(TilbyCurrencyPipe);

    @Input() categories?: Array<Categories>;
    @Input() chainShops?: Array<any>;
    @Input() channels?: Array<Channels>;
    @Input() promotion?: PromotionsFields;
    @Input() suppliers?: Array<Suppliers>;
    @Output() formDetailsMarkAsDirty = new EventEmitter<any>();
    promotionItemsSearchForm!: CustomFormGroup<PromotionsSearchItemsForm>;
    sourceAdd: Array<ItemsEstended> = [];
    sourceRemoved: Array<ItemsEstended> = [];
    currentSection = computed(() => this.utilsPromotionsService.currentSection());

    @ViewChild(MatTable) tableAdd!: MatTable<TableItemsPromotion>;
    @ViewChild(MatTable) tableRemove!: MatTable<TableItemsPromotion>;
    @ViewChild(MatSort) sortAdd!: MatSort;
    @ViewChild(MatSort) sortRemove!: MatSort;
    @ViewChild(MatPaginator) paginatorAdd!: MatPaginator;
    @ViewChild(MatPaginator) paginatorRemove!: MatPaginator;
    ELEMENT_DATA_ADD: Array<TableItemsPromotion> = [];
    ELEMENT_DATA_REMOVE: Array<TableItemsPromotion> = [];
    dataSourceAdd: MatTableDataSource<TableItemsPromotion> = new MatTableDataSource(this.ELEMENT_DATA_ADD);
    dataSourceRemove: MatTableDataSource<TableItemsPromotion> = new MatTableDataSource(this.ELEMENT_DATA_REMOVE);
    displayedColumnsAdd: Array<string> = [];
    displayedColumnsRemove: Array<string> = [];
    columns: Array<ColumnDef> = [];
    $promotion_type = computed(() => this.utilsPromotionsService.$promotion_type());
    $perc_discount = computed(() => this.utilsPromotionsService.$perc_discount());

    constructor() {
        this.log('constructor');
        effect(() => {
            if(this.currentSection() !== 'general') {
                this.columns = this.getColumns();
                const displayedColumnsAdd = this.columns.filter(c => c.showInModeADD).map(c => c.columnDef);
                this.displayedColumnsAdd = [...displayedColumnsAdd, 'action'];
                const displayedColumnsRemove = this.columns.filter(c => c.showInModeREMOVE).map(c => c.columnDef);
                this.displayedColumnsRemove = [...displayedColumnsRemove, 'action'];
            }
        });
    }

    ngOnInit(): void {
        this.log('OnInit');

        this.utilsPromotionsService.currentStatusSource$.subscribe(
            (status: StatusItemsSource) => {
                switch(this.currentSection()) {
                    case 'items':
                    case 'general':
                        this.sourceAdd = status.itemsSourceAdd;
                        this.sourceRemoved = status.itemsSourceRemoved;
                    break;
                    case 'required-items':
                        this.sourceAdd = status.requiredItemsSourceAdd;
                        this.sourceRemoved = status.requiredItemsSourceRemoved;
                    break;
                }
                this.updateFiltederSource();
                this.updateDataSourceAdd();
                this.updateDataSourceRemove();
                this.formDetailsMarkAsDirty.emit();
            }
        );

        this.promotionItemsSearchForm = this.utilsPromotionsService.createFormSearchItems(this.categories || [], this.suppliers || [], this.channels || []);
        this.itemsFormSubscriptions();
        this.initStatus();
    }

    ngAfterViewInit() {
        this.log('ngAfterViewInit');
        this.log('tableAdd', this.tableAdd);
        this.log('tableRemove', this.tableRemove);
        this.dataSourceAdd.paginator = this.paginatorAdd;
        this.dataSourceRemove.paginator = this.paginatorRemove;
        this.dataSourceAdd.sort = this.sortAdd;
        this.dataSourceRemove.sort = this.sortRemove;
    }

    initStatus() {
        this.log('initStatus');
        this.utilsPromotionsService.applyStatusSource();
    }

    private reduceFilteredSource(a: Array<ItemsEstended>, b: Array<ItemsEstended>) {
        const out: Array<ItemsEstended> = [];
        a.forEach((item: ItemsEstended) => {
            b.forEach((filteredItem: ItemsEstended) => {
                if(filteredItem.sku === item.sku) {
                    out.push(item);
                }
            });
        });

        return out;
    }


    updateFiltederSource() {
        const filteredSearch = this.utilsPromotionsService.filteredSearch;
        const hasFilteredSearch = filteredSearch.length > 0;

        const filteredSupplier = this.utilsPromotionsService.filteredSupplier;
        const hasFilteredSupplier = filteredSupplier.length > 0;

        const filteredBrand = this.utilsPromotionsService.filteredBrand;
        const hasFilteredBrand = filteredBrand.length > 0;

        const filteredCategory = this.utilsPromotionsService.filteredCategory;
        const hasFilteredCategory = filteredCategory.length > 0;

        const filteredChannel = this.utilsPromotionsService.filteredChannel;
        const hasFilteredChannel = filteredChannel.length > 0;

        if(hasFilteredSearch) {
            this.sourceAdd = this.reduceFilteredSource(this.sourceAdd, filteredSearch);
        }
        if(hasFilteredSupplier) {
            this.sourceAdd = this.reduceFilteredSource(this.sourceAdd, filteredSupplier);
        }
        if(hasFilteredBrand) {
            this.sourceAdd = this.reduceFilteredSource(this.sourceAdd, filteredBrand);
        }
        if(hasFilteredCategory) {
            this.sourceAdd = this.reduceFilteredSource(this.sourceAdd, filteredCategory);
        }
        if(hasFilteredChannel) {
            this.sourceAdd = this.reduceFilteredSource(this.sourceAdd, filteredChannel);
        }
        this.utilsPromotionsService.filteredSource = this.sourceAdd;
    }

    itemsFormSubscriptions() {
        this.search.valueChanges
            .pipe(
                skip(1),
                debounceTime(1000),
                distinctUntilChanged(),
                this.onDestroyService.takeUntilDestroy
            )
            .subscribe((textSearch: string) => {
                this.log('textSearch', textSearch);
                this.utilsPromotionsService.filterSearch(textSearch);
                this.utilsPromotionsService.applyStatusSource();
            });
        this.supplier.valueChanges
            .pipe(
                skip(1),
                debounceTime(1000),
                distinctUntilChanged(),
                this.onDestroyService.takeUntilDestroy
            )
            .subscribe((supplierName: string) => {
                this.log('supplierName', supplierName);
                const supplierId = this.suppliers?.find(sup => sup.name === supplierName)?.id || 0;
                this.utilsPromotionsService.filterSuppliers(supplierId);
                this.utilsPromotionsService.applyStatusSource();
            });
        this.brand.valueChanges
            .pipe(
                skip(1),
                debounceTime(1000),
                distinctUntilChanged(),
                this.onDestroyService.takeUntilDestroy
            )
            .subscribe((brand: string) => {
                this.log('brand', brand);
                this.utilsPromotionsService.filterBrand(brand);
                this.utilsPromotionsService.applyStatusSource();
            });
        this.category.valueChanges
            .pipe(
                skip(1),
                debounceTime(1000),
                distinctUntilChanged(),
                this.onDestroyService.takeUntilDestroy
            )
            .subscribe((categoryName: string) => {
                this.log('categoryId', categoryName);
                const categoryId = this.categories?.find(cat => cat.name === categoryName)?.id || 0;
                this.utilsPromotionsService.filterCategory(categoryId);
                this.utilsPromotionsService.applyStatusSource();
            });
        this.channel.valueChanges
            .pipe(
                skip(1),
                debounceTime(1000),
                distinctUntilChanged(),
                this.onDestroyService.takeUntilDestroy
            )
            .subscribe((channelId: string) => {
                this.log('channelId', channelId);
                this.utilsPromotionsService.filterChannels(channelId);
                this.utilsPromotionsService.applyStatusSource();
            });
    }

    protected log(...args: any[]) {
        DevLogger.log(`ITEMS_COMPONENT in ${this.currentSection()}`, ...args);
    }
    // GET FORM SEARCH
    private get search() {
        return this.promotionItemsSearchForm?.controls.search;
    }
    private get supplier() {
        return this.promotionItemsSearchForm?.controls.supplier;
    }
    private get brand() {
        return this.promotionItemsSearchForm?.controls.brand;
    }
    private get category() {
        return this.promotionItemsSearchForm?.controls.category;
    }
    private get channel() {
        return this.promotionItemsSearchForm?.controls.channel;
    }

    getColumns() {
        this.log('getColumns');
        const sku = this.translate.instant('PROMOTIONS.DETAILS.SKU');
        const name = this.translate.instant('PROMOTIONS.DETAILS.NAME');
        const cost = this.translate.instant('PROMOTIONS.DETAILS.COST');
        const price = this.translate.instant('PROMOTIONS.DETAILS.PRICE');
        const discountedPrice = this.translate.instant('PROMOTIONS.DETAILS.DISCOUNTED_PRICE');
        const quantity = this.translate.instant('PROMOTIONS.DETAILS.QUANTITY');

        const columnDefs: Array<ColumnDef> = [
            {
              columnDef: 'sku',
              header: sku,
              cell: (element: TableItemsPromotion) => `${element.sku}`,
              changeCell: (event: string | number, element: TableItemsPromotion) => {
                console.log("changeCell", event);
              },
              showInModeADD: true,
              showInModeREMOVE: true
            },
            {
              columnDef: 'name',
              header: name,
              cell: (element: TableItemsPromotion) => `${element.name}`,
              changeCell: (event: string | number, element: TableItemsPromotion) => {
                console.log("changeCell", event);
              },
              showInModeADD: true,
              showInModeREMOVE: true
            },
            {
              columnDef: 'cost',
              header: cost,
              cell: (element: TableItemsPromotion) => element.cost ? `(${this.tilbyCurrencyPipe.transform(element.cost)})` : '',
              changeCell: (event: string | number, element: TableItemsPromotion) => {
                console.log("changeCell", event);
              },
              showInModeADD: true,
              showInModeREMOVE: this.currentSection() === 'items' ? true : false
            },
            {
              columnDef: 'price',
              header: price,
              cell: (element: TableItemsPromotion) => `${this.tilbyCurrencyPipe.transform(element.price || 0)}`,
              changeCell: (event: string | number, element: TableItemsPromotion) => {
                console.log("changeCell", event);
              },
              showInModeADD: true,
              showInModeREMOVE: this.currentSection() === 'items' ? true : false
            },
            {
              columnDef: 'discountedPrice',
              header: discountedPrice,
              cell: (element: TableItemsPromotion) => {
                  const discountPrice = this.getDiscountedPrice(element);
                    if(this.$promotion_type() === 'discount_perc') {
                        return `${this.tilbyCurrencyPipe.transform(discountPrice)}`;
                    } else {
                        return discountPrice;
                    }
              },
              changeCell: (event: string | number, element: TableItemsPromotion) => {
                this.log("changeCell value", event);
                this.log("changeCell type", typeof event);
                element.discountPrice = Number(event || 0);
                this.utilsPromotionsService.updateDiscountPrice(element.sku, element.discountPrice);
              },
              showInModeADD: false,
              showInModeREMOVE: this.currentSection() === 'items' ? true : false
            },
            {
              columnDef: 'quantity',
              header: quantity,
              cell: (element: TableItemsPromotion) => element.quantity || 0,
              changeCell: (event: string | number, element: TableItemsPromotion) => {
                this.log("changeCell", event);
                element.quantity = Number(event);
                this.utilsPromotionsService.updateQuantity(element.sku, element.quantity);
              },
              showInModeADD: false,
              showInModeREMOVE: this.currentSection() === 'required-items' ? true : false
            }
        ];

        return columnDefs;
    }

    getDiscountedPrice(el: TableItemsPromotion) {
        const item = this.utilsPromotionsService.itemsBySku[el.sku];
        if(item) {
            if(this.$promotion_type() === 'discount_perc') {
                const percDiscount = this.$perc_discount() || 100;
                return MathUtils.round(item.price1 - MathUtils.round(item.price1 * percDiscount / 100));
            } else {
                return item._discountedPrice || 0;
            }
        }

        this.log('item not found', el);
        return 0;
    }

    addRow(row: TableItemsPromotion) {
        this.log('add row', row);
        this.utilsPromotionsService.addRow(row);
    }

    removeRow(row: TableItemsPromotion) {
        this.log('remove row', row);
        this.utilsPromotionsService.removeRow(row);
    }

    addAll() {
        this.log('add all');
        this.utilsPromotionsService.addAll();
    }

    removeAll() {
        this.log('remove all');
        this.utilsPromotionsService.removeAll();
    }

    transformItemsEstendedToTableItemsPromotion(items: Array<ItemsEstended>): Array<TableItemsPromotion> {
        return items && items.length ? items.map(i => ({
            id: i.id || 0,
            sku: i.sku || '',
            name: i.name,
            cost: i.cost || 0,
            price: i.price1,
            quantity: i._quantity || 0
        })) : [];
    }

    updateDataSourceAdd() {
        this.log('updateDataSourceAdd', this.sourceAdd);
        const itemsIN = this.transformItemsEstendedToTableItemsPromotion(this.sourceAdd);
        this.log('itemsIN length', itemsIN.length);
        this.ELEMENT_DATA_ADD = itemsIN;
        this.dataSourceAdd.data = this.ELEMENT_DATA_ADD;
    }

    updateDataSourceRemove() {
        this.log('updateDataSourceRemove', this.sourceRemoved);
        const itemsIN = this.transformItemsEstendedToTableItemsPromotion(this.sourceRemoved);
        this.log('itemsIN length', itemsIN.length);
        this.ELEMENT_DATA_REMOVE = itemsIN;
        this.dataSourceRemove.data = this.ELEMENT_DATA_REMOVE;
    }

}

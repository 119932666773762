import angular from 'angular';
import _ from 'lodash';

angular.module('kiosk').factory('addVariationsDialog', ["$mdDialog", "$translate", "$rootScope", function($mdDialog, $translate, $rootScope) {

    addVariationsDialogController.$inject = ["$scope", "params", "$mdDialog"];
    function addVariationsDialogController($scope, params, $mdDialog) {
        if(!_.isObject(params)) {
            params = {
                name: $translate.instant('KIOSK.ADD_VARIATIONS_DIALOG.ADD_VARIATIONS')
            };
        }

        _.assign($scope, {
            name: params.name,
            variations: []
        });

        if(params.variations) {
            $scope.variations = _.cloneDeep(params.variations);

            // Add 'no choice' option for non required variations
            _.forEach($scope.variations, function(el) {
                //Sort variation values by index
                el.variation_values = _.sortBy(el.variation_values, 'index');

                if (!el.required) {
                    el.variation_values.unshift({
                        value: $translate.instant('KIOSK.ADD_VARIATIONS_DIALOG.NONE'),
                        id: null
                    });
                }
            });
        }

        $scope.enableTimeouts = function() {
            $rootScope.enableTimeouts();
        };

        $scope.cancel = function() {
            $mdDialog.cancel();
        };

        $scope.proceed = function() {
            var variationsSelected = [];

            _.forEach($scope.variations, function(variation) {
                if (variation.variation_value_id) {
                    var found = _.find(variation.variation_values, { id: variation.variation_value_id });

                    variationsSelected.push({
                        name: variation.name,
                        value: found.value,
                        price_difference: found.price_difference,
                        linked_item_uuid: found.linked_item_uuid,
                        variation_id: variation.id,
                        variation_value_id: variation.variation_value_id
                    });
                }
            });

            $mdDialog.hide({ variations: variationsSelected });
        };

        $scope.$on('kiosk:standby', function(event) {
            $mdDialog.cancel();
        });
    }

    var addVariations = {
        show: function(params) {
            return $mdDialog.show({
                controller: addVariationsDialogController,
                template: require('./add-variations.html'),
                locals: {
                    params: params
                },
            });
        }
    };

    return addVariations;
}]);

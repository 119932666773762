import angular from 'angular';
import _ from 'lodash';

angular.module('customers', ['application']).config(["$stateProvider", "$translatePartialLoaderProvider", function($stateProvider: any, $translatePartialLoaderProvider: any) {
    $translatePartialLoaderProvider.addPart('customers');

    $stateProvider.state('app.new', {
        abstract: true,
        views: {
            "appContent@app": {
                template: '<new-sidenav></new-sidenav>',
            },
        },
    });

    $stateProvider.state('app.new.customers', {
        url: '/customers',
        component:"customersShowcase",
        params: {
            remove: {dynamic: true, value: null}
        }
    });

    $stateProvider.state('app.new.customers.showcase', {
        url: '/showcase',
        redirectTo: 'app.new.customers'
    });


    $stateProvider.state('app.new.customers.details', {
        url: '/details/:customerId',
        template: '<customers-details [customer]="$resolve.customer" [payment-methods]="$resolve.paymentMethods" [campaigns]="$resolve.campaigns"></customers-details>',
        params: {
            isNew: {dynamic: true, value: null}
        },
        resolve: {
            customer: ["entityManager", "$stateParams", async function(entityManager: any, $stateParams: any) {
                return {...(await entityManager.customers.fetchOneOnline($stateParams.customerId)),...($stateParams.isNew&&{isNew:true})};
            }],
            paymentMethods: ["entityManager", function(entityManager: any) {
                return entityManager.paymentMethods.fetchCollectionOffline();
            }],
            campaigns: ["entityManager", async function(entityManager: any) {
                    return await entityManager.campaigns.fetchCollectionOnline();
            }]
        }
    });

    $stateProvider.state('app.new.customers.new', {
        url: '/new',
        template: '<customers-details [customer]="$resolve.customer" [payment-methods]="$resolve.paymentMethods"></customers-details>',
        params: {
            phoneNumber: {dynamic: true, value: null}
        },
        resolve: {
            customer: function() {
                return undefined;
            },
            paymentMethods: ["entityManager", function(entityManager: any) {
                return entityManager.paymentMethods.fetchCollectionOffline();
            }]
        }
    });
}]);
